import {extractFormErrorsFromResponse, putAll, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {put, call} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'translations';
import {formHelpers} from 'utils/forms';
import {showBeError} from 'utils/show-be-error';

import {orderAction, UpdateOrdersTypesByIdsAction} from '../action';

function* execute({payload: {values, orderIds}}: UpdateOrdersTypesByIdsAction): SagaIterator {
    yield* put(formHelpers.startSubmit('bulkEditOrdersTypes'));

    const {ordersTypes: {orderTypeIds}, editType} = values;
    const response = yield* call(
        editType === 'add' ? Api.addOrderTypesSubset : Api.removeOrderTypesForSubset,
        {orderTypeIds, orderIds},
    );

    if (response.isSuccess) {
        yield* put(formHelpers.stopSubmit('bulkEditOrdersTypes'));
        yield* put(orderAction.deselectAll());
        yield* put(orderAction.resetBulkEditOrderIds());
    } else {
        yield putAll(showBeError(response, t('orders/ListActions')('bulkUpdateOrder')));
        yield* put(formHelpers.stopSubmit('bulkEditOrdersTypes', extractFormErrorsFromResponse(response)));
    }
    yield* put(orderAction.getOrders(opt({action: 'refresh'})));
}

export function* updateOrdersTypesByIdsSaga(): SagaIterator {
    yield takeLatestF('order/UPDATE_ORDERS_TYPES', execute);
}
