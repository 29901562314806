import {GenericFormState, NullableKeys} from '@fl/cmsch-fe-library';
import * as O from 'optics-ts';
import {pipe} from 'ts-opt';

import {Action} from 'app/actions';
import {CFormReducer} from 'app/form-state';

import {AnimalEditFormValues, initialAnimalEditFormValues} from '../components/AnimalEditForm/animal-edit-form-values';

type AnimalEditFormState = GenericFormState<AnimalEditFormValues>;

const editAnimalStateO = O.optic<AnimalEditFormState>();
const editAnimalValuesO = O.optic<NullableKeys<AnimalEditFormValues>>();

export const animalsFormReducer: CFormReducer = {
    animalEdit: (state: AnimalEditFormState, action: Action): AnimalEditFormState => {
        switch (action.type) {
            case 'animals/REFRESH_FATHER_SUCCESS': {
                const {animal} = action.payload;
                const newValues = pipe(
                    state.values ?? initialAnimalEditFormValues,
                    O.set(editAnimalValuesO.prop('fathersSampleId'))(animal.sampleId),
                    O.set(editAnimalValuesO.prop('fathersRegistry'))(animal.registry),
                );

                return O.set(editAnimalStateO.prop('values'))(newValues)(state);
            }

            default:
                return state;
        }
    },
};
