import {animalNameMaxLength} from 'api/gen/AnimalName';
import {breedMaxLength} from 'api/gen/Breed';
import {earTagRegexGen} from 'api/gen/EarTag';
import {lineRegistryRegexGen} from 'api/gen/LineRegistry';
import {t, tCommon} from 'translations';
import {PaternityValidator} from 'utils/paternity-validator';
import {Errors} from 'utils/validator';

import {AnimalEditFormValues} from './animal-edit-form-values';

const te = t('animals/AnimalEditForm');

interface ValidateParams {
    initialSampleId: string;
}

export const validate = ({initialSampleId}: ValidateParams) =>
    (values: AnimalEditFormValues): Errors<AnimalEditFormValues> => {
        const validator = new PaternityValidator(values);

        validator.nonEmpty('breed', te('breed'));
        validator.maxStringLength('breed', breedMaxLength, te('breed'));
        validator.pattern('fathersRegistry', lineRegistryRegexGen(), te('fathersRegistry'));
        validator.pattern('fathersSampleId', earTagRegexGen(), te('fathersSampleId'));
        validator.pattern('mothersSampleId', earTagRegexGen(), te('mothersSampleId'));
        validator.maxStringLength('name', animalNameMaxLength, te('name'));
        validator.pattern('registry', lineRegistryRegexGen(), te('registry'));
        validator.nonEmpty('sex', te('sex'));

        if (initialSampleId !== values.sampleId) {
            validator.nonEmpty('sampleId', tCommon('earTag'));
            validator.pattern('sampleId', earTagRegexGen(), tCommon('earTag'));
        }

        return validator.generateErrorsObject();
    };
