import {takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {put, call} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {Errors} from 'api/gen/Errors';
import {formHelpers} from 'utils/forms';

import {ValidateLaboratoryNumberAction} from '../action';

function* execute({payload: {orderId, laboratoryNumber}}: ValidateLaboratoryNumberAction): SagaIterator {
    const response = yield* call(Api.validateLaboratoryNumber, {laboratoryNumber, orderId});

    if (response.isSuccess) {
        yield* put(formHelpers.stopAsyncValidation('editOrder'));
    } else {
        const errors = response.data as Errors;
        yield* put(formHelpers.stopAsyncValidation('editOrder', {laboratoryNumber: errors[0].message}));
    }
}

export function* validateLaboratoryNumberSaga(): SagaIterator {
    yield takeLatestF('order/VALIDATE_LABORATORY_NUMBER', execute);
}
