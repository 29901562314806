import React, {FC, memo, ReactNode} from 'react';

import styles from './styles.sass';

interface Props {
    children?: ReactNode;
}

/**
 * `<PageHeading />` is a main page heading. It is design to work well inside `<MainPanel />`
 * component.
 */
const PageHeadingBase: FC<Props> = props => {
    const {children} = props;

    return (
        <div
            className={styles.pageHeading}
            data-test-id="screen-header"
        >
            {children}
        </div>
    );
};

export const PageHeading = memo(PageHeadingBase);
