import React, {FC, memo} from 'react';

import {FooterButtons} from 'common/buttons';
import {useOurTranslation} from 'translations';
import {useForm} from 'utils/forms';

import {validate} from './validations';
import {BulkEditOrderEmailFormValues, bulkEditOrderEmailInitialValues, bulkEditOrderEmailFormName} from './values';

interface Props {
    onClose(): void;
    onSubmit(values: BulkEditOrderEmailFormValues): void;
}

const BulkEditOrderEmailFormBase: FC<Props> = props => {
    const {
        onClose,
        onSubmit,
    } = props;

    const {t} = useOurTranslation('common');

    const {Form, Fields} = useForm({
        form: bulkEditOrderEmailFormName,
        destroyOnUnmount: true,
        forceUnregisterOnUnmount: true,
        initialValues: bulkEditOrderEmailInitialValues,
        validate,
        onSubmit,
    });

    return (
        <div data-test-id="bulk-edit-order-email-form">
            <Form>
                <Fields.Input
                    name="email"
                    label={t('email')}
                    type="email"
                    isRequired
                />
                <FooterButtons
                    onCancel={onClose}
                    submitTitle={t('edit')}
                />
            </Form>
        </div>
    );
};

export const BulkEditOrderEmailForm = memo(BulkEditOrderEmailFormBase);
