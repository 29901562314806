import {NullableKeys} from '@fl/cmsch-fe-library';

import {Date} from 'api/gen/Date';
import {SexType} from 'api/gen/SexType';

export const animalDetailsInitialValues: AnimalDetailsFormSectionValues = {
    id: null,
    sampleId: null,
    name: null,
    sex: null,
    breed: null,
    registry: null,
    dob: null,
    fathersSampleId: null,
    fathersRegistry: null,
    mothersSampleId: null,
    twin: null,
    isEditing: false,
    fullName: null,
};

export type AnimalDetailsFormSectionValues = NullableKeys<{
    id: number,
    sampleId: string,
    name: string,
    sex: SexType,
    breed: string,
    registry: string,
    dob: Date,
    fathersSampleId: string,
    fathersRegistry: string,
    mothersSampleId: string,
    twin: boolean,
    isEditing: boolean,
    fullName: string,
}>;
