// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.75.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.75.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-animals-components-AnimalDetail-___styles__microsatelliteSection___vBVyl{margin-bottom:2em;overflow-x:auto}.src-modules-animals-components-AnimalDetail-___styles__microsatelliteCaption___kIIE7{font-size:150%;font-weight:700;margin-bottom:.2em}.src-modules-animals-components-AnimalDetail-___styles__haplotypeCouple___EPJeI{width:44em;text-wrap:none}.src-modules-animals-components-AnimalDetail-___styles__haplotypeCouple___EPJeI:not(:last-child){margin-bottom:1em}.src-modules-animals-components-AnimalDetail-___styles__haplotype___rEZKA{margin:0;font-size:90%;white-space:nowrap}.src-modules-animals-components-AnimalDetail-___styles__standardTable___KFXUs:first-of-type tr:first-of-type th,.src-modules-animals-components-AnimalDetail-___styles__standardTable___KFXUs:first-of-type tr:first-of-type td{border-top:none}.src-modules-animals-components-AnimalDetail-___styles__standardTable___KFXUs tr>th{width:40%}\n", "",{"version":3,"sources":["webpack://./src/modules/animals/components/AnimalDetail/styles.sass"],"names":[],"mappings":"AAAA,sFAAuB,iBAAA,CAAkB,eAAA,CAAgB,sFAAuB,cAAA,CAAe,eAAA,CAAiB,kBAAA,CAAmB,gFAAiB,UAAA,CAAW,cAAA,CAAe,iGAAkC,iBAAA,CAAkB,0EAAlO,QAAA,CAAsP,aAAA,CAAc,kBAAA,CAAmB,gOAAkG,eAAA,CAAgB,oFAAqB,SAAA","sourcesContent":[".microsatelliteSection{margin-bottom:2em;overflow-x:auto}.microsatelliteCaption{font-size:150%;font-weight:bold;margin-bottom:.2em}.haplotypeCouple{width:44em;text-wrap:none}.haplotypeCouple:not(:last-child){margin-bottom:1em}.haplotype{margin:0;font-size:90%;white-space:nowrap}.standardTable:first-of-type tr:first-of-type th,.standardTable:first-of-type tr:first-of-type td{border-top:none}.standardTable tr>th{width:40%}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"microsatelliteSection": "src-modules-animals-components-AnimalDetail-___styles__microsatelliteSection___vBVyl",
	"microsatelliteCaption": "src-modules-animals-components-AnimalDetail-___styles__microsatelliteCaption___kIIE7",
	"haplotypeCouple": "src-modules-animals-components-AnimalDetail-___styles__haplotypeCouple___EPJeI",
	"haplotype": "src-modules-animals-components-AnimalDetail-___styles__haplotype___rEZKA",
	"standardTable": "src-modules-animals-components-AnimalDetail-___styles__standardTable___KFXUs"
};
export default ___CSS_LOADER_EXPORT___;
