import React, {FC, memo} from 'react';

interface Props{
    data: string;
}

const ErrorMessageBase: FC<Props> = ({data}) => (
    <div
        className="alert alert-danger alert-dismissible fade show"
        role="alert"
    >
        {data}
    </div>
);

export const ErrorMessage = memo(ErrorMessageBase);
