// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.75.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.75.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-animals-components-DataCell-___styles__laboratoryNote___PeR3x{margin-top:5px;cursor:default;display:flex}.src-modules-animals-components-DataCell-___styles__laboratoryNote___PeR3x svg{transition:.3s ease;fill:#719041!important}.ant-table-cell-row-hover .src-modules-animals-components-DataCell-___styles__laboratoryNote___PeR3x svg{fill:#fff!important}\n", "",{"version":3,"sources":["webpack://./src/modules/animals/components/DataCell/styles.sass"],"names":[],"mappings":"AAAA,2EAAgB,cAAA,CAAe,cAAA,CAAe,YAAA,CAAa,+EAAoB,mBAAA,CAAoB,sBAAA,CAAwB,yGAAuD,mBAAA","sourcesContent":[".laboratoryNote{margin-top:5px;cursor:default;display:flex}.laboratoryNote svg{transition:.3s ease;fill:#719041 !important}:global(.ant-table-cell-row-hover) .laboratoryNote svg{fill:#fff !important}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"laboratoryNote": "src-modules-animals-components-DataCell-___styles__laboratoryNote___PeR3x"
};
export default ___CSS_LOADER_EXPORT___;
