import React, {Component, ReactNode} from 'react';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';

import {userAction} from '../model';

interface DispatchProps {
    logout(): void;
}

type Props = DispatchProps;

class LogoutBase extends Component<Props> {
    public componentDidMount(): void {
        const {logout} = this.props;

        logout();
    }

    public render(): ReactNode {
        return <div />;
    }
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    logout: (): void => void dispatch(userAction.logout()),
});

export const Logout = connect(null, mapDispatchToProps)(LogoutBase);
