import {takeLatestF, putAll} from '@fl/cmsch-fe-library';
import * as routerActions from 'redux-first-history';
import {SagaIterator} from 'redux-saga';
import {put, call} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {appAction} from 'app/model/action';
import {t} from 'translations';
import {showBeError} from 'utils/show-be-error';

import {animalsAction, GetAnimal} from '../action';

function* execute({payload: {animalId, redirectNotFound}}: GetAnimal): SagaIterator {
    yield* put(appAction.addLoadingContent('getAnimal'));
    const response = yield* call(Api.getAnimal, {animalId});

    if (response.isSuccess) {
        yield* put(animalsAction.getAnimalSuccess(response.data));
    } else {
        if (response.isNotFound && redirectNotFound) {
            yield* put(routerActions.replace('/not-found'));
        }
        yield putAll(showBeError(response, t('animals/sagaMessages')('animalError')));
    }
    yield* put(appAction.removeLoadingContent('getAnimal'));
}

export function* getAnimalSaga(): SagaIterator {
    yield takeLatestF('animals/GET_ANIMAL', execute);
}
