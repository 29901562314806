import {extractFormErrorsFromResponse, putAll, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {put, call} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'translations';
import {formHelpers} from 'utils/forms';
import {showBeError} from 'utils/show-be-error';

import {editMailTemplateFormName} from '../../components/MailTemplateForm/mail-template-form-values';
import {dialsAction, UpdateMailTemplateAction} from '../action';

function* execute({payload: {id, mailTemplate}}: UpdateMailTemplateAction): SagaIterator {
    yield* put(formHelpers.startSubmit(editMailTemplateFormName));

    const response = yield* call(Api.updateEmailTemplate, mailTemplate, {emailTemplateId: id});

    if (response.isSuccess) {
        yield* put(formHelpers.stopSubmit(editMailTemplateFormName));
        yield* put(dialsAction.updateMailTemplateSuccess());
        yield* put(dialsAction.getMailTemplates());
        yield* put(dialsAction.setModalVisibility('mailTemplate', false));
    } else {
        yield* put(formHelpers.stopSubmit(editMailTemplateFormName, extractFormErrorsFromResponse(response)));
        yield putAll(showBeError(response, t('dials/mailTemplates')('editTitle')));
    }
}

export function* updateMailTemplateSaga(): SagaIterator {
    yield takeLatestF('dials/UPDATE_MAIL_TEMPLATE', execute);
}
