import {TableDataParams, TableApiParams} from '@fl/cmsch-fe-library';

import {TableFilter} from 'api/gen/TableFilter';
import {convertTableDataParamsToDto} from 'utils/tables';

import {AnimalsTableRow} from '../components/AnimalsTable/table-row';
import {OriginInconsistenciesTableRow} from '../components/OriginInconsistenciesTable/table-row';

export const convertAnimalsTableDataParamsToDto = (
    tableDataParams: TableDataParams<AnimalsTableRow>,
): TableApiParams<TableFilter> =>
    convertTableDataParamsToDto(
        {
            tableDataParams,
            sortFieldTranslations: [['data', 'laboratoryNote'], ['parenthood', 'paternity,maternity']],
            fulltextFilterFields: ['earTag', 'lineRegistry', 'laboratoryNumber', 'fathersLineRegistry'],
        },
    );

export const convertOriginInconsisitenciesTableDataParamsToDto = (
    tableDataParams: TableDataParams<OriginInconsistenciesTableRow>,
): TableApiParams<TableFilter> =>
    convertTableDataParamsToDto({
        tableDataParams,
        sortFieldTranslations: [['data', 'laboratoryNote'], ['parenthood', 'paternity,maternity']],
        fulltextFilterFields: [
            'earTag',
            'laboratoryNumber',
            'fatherEarTag',
            'fatherLinReg',
            'motherEarTag',
            'eskotFatherEarTag',
            'eskotFatherLinReg',
            'eskotMotherEarTag',
            'customerName',
        ],
    },
    );
