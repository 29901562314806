// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { ChipId, ChipIdSchema } from 'api/gen/ChipId';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { GlobalEarTag, GlobalEarTagSchema } from 'api/gen/GlobalEarTag';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { LabNumber, LabNumberSchema } from 'api/gen/LabNumber';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { LineRegistry, LineRegistrySchema } from 'api/gen/LineRegistry';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { ParentMatch, ParentMatchSchema } from 'api/gen/ParentMatch';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveLong, PositiveLongSchema } from 'api/gen/PositiveLong';

const requiredPart = t.interface({
  chipId: t.union([ChipIdSchema, t.null]),
  earTag: GlobalEarTagSchema,
  exportable: t.boolean,
  fathersLaboratoryNumber: t.union([LabNumberSchema, t.null]),
  fathersLineRegistry: t.union([LineRegistrySchema, t.null]),
  id: PositiveLongSchema,
  importedFromGermany: t.boolean,
  laboratoryNote: t.union([t.string, t.null]),
  laboratoryNumber: t.union([LabNumberSchema, t.null]),
  lineRegistry: t.union([LineRegistrySchema, t.null]),
  maternity: t.union([ParentMatchSchema, t.null]),
  mothersEarTag: t.union([GlobalEarTagSchema, t.null]),
  mothersLaboratoryNumber: t.union([LabNumberSchema, t.null]),
  paternity: t.union([ParentMatchSchema, t.null]),
});

export const AnimalSchema = excess(requiredPart);

export interface Animal extends t.TypeOf<typeof AnimalSchema> {}
