import {putAll, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'translations';
import {showBeError} from 'utils/show-be-error';

import {HideBreederInfoAction} from '../action';

function* execute(_action: HideBreederInfoAction): SagaIterator {
    const response = yield* call(Api.hideSideBox);

    if (!response.isSuccess) {
        yield putAll(showBeError(response, t('dials/screenList')('breederInfoCaption')));
    }
}

export function* hideSideBoxSaga(): SagaIterator {
    yield takeLatestF('dials/HIDE_BREEDER_INFO', execute);
}
