// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { OrderTypeCode, OrderTypeCodeSchema } from 'api/gen/OrderTypeCode';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { OrderTypeExtraCharge, OrderTypeExtraChargeSchema } from 'api/gen/OrderTypeExtraCharge';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { OrderTypePrice, OrderTypePriceSchema } from 'api/gen/OrderTypePrice';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveLong, PositiveLongSchema } from 'api/gen/PositiveLong';

const requiredPart = t.interface({
  code: OrderTypeCodeSchema,
  extraCharge: OrderTypeExtraChargeSchema,
  id: PositiveLongSchema,
  price: OrderTypePriceSchema,
  value: t.string,
});

export const OrderTypeItemSchema = excess(requiredPart);

export interface OrderTypeItem extends t.TypeOf<typeof OrderTypeItemSchema> {}
