import {downloadFileFromResponse, putAll, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'translations';
import {showBeError} from 'utils/show-be-error';

import {ExportCatalogueListsByIdsAction, orderAction} from '../action';
import {isActionForced} from './is-action-forced';
import {getSelectedOrders} from './selectors';

function* execute({payload: {force}}: ExportCatalogueListsByIdsAction): SagaIterator {
    const orderIds = yield* select(getSelectedOrders);
    yield* put(orderAction.setListActionActive('exportCatalogueLists', true));

    const response = yield* call(Api.exportCatalogListsSubset, {orderIds, force});

    if (response.isSuccess) {
        yield* call(downloadFileFromResponse, response);
    } else {
        if (force) {
            yield putAll(showBeError(response, t('orders/ListActions')('exportCatalogueLists')));
        } else {
            const isForced = yield* call(isActionForced, response);

            if (isForced) {
                yield* put(orderAction.exportCatalogueListsByIds(true));
            }
        }
    }
    yield* put(orderAction.setListActionActive('exportCatalogueLists', false));
    yield* put(orderAction.resetDownloadWithoutForceFailedResult());
}

export function* exportCatalogueListsByIdsSaga(): SagaIterator {
    yield takeLatestF('order/EXPORT_CATALOGUE_LISTS_BY_IDS', execute);
}
