import React from 'react';
import {createRoot} from 'react-dom/client';

import {App} from 'app';

const getAppElement = (): HTMLElement => {
    const element = document.getElementById('app');

    if (!element) throw new Error('app element not found');

    return element;
};

const renderApplication = (): void => {
    createRoot(getAppElement()).render(<App />);
};

renderApplication();
