import {downloadFileFromResponse, putAll, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'translations';
import {showBeError} from 'utils/show-be-error';

import {ExportOrderProtocolAction} from '../action';

function* execute({payload: {orderId}}: ExportOrderProtocolAction): SagaIterator {
    const response = yield* call(Api.getProtocol, {orderId});

    if (response.isSuccess) {
        yield* call(downloadFileFromResponse, response);
    } else {
        yield putAll(showBeError(response, t('orders/TableRowActions')('protocol')));
    }
}

export function* exportOrderProtocolSaga(): SagaIterator {
    yield takeLatestF('order/EXPORT_ORDER_PROTOCOL', execute);
}
