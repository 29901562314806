// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { Address, AddressSchema } from 'api/gen/Address';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { AnimalName, AnimalNameSchema } from 'api/gen/AnimalName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Breed, BreedSchema } from 'api/gen/Breed';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { CustomerName, CustomerNameSchema } from 'api/gen/CustomerName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Date, DateSchema } from 'api/gen/Date';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { GlobalEarTag, GlobalEarTagSchema } from 'api/gen/GlobalEarTag';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { LabNumber, LabNumberSchema } from 'api/gen/LabNumber';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { LineRegistry, LineRegistrySchema } from 'api/gen/LineRegistry';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveLong, PositiveLongSchema } from 'api/gen/PositiveLong';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { SexType, SexTypeSchema } from 'api/gen/SexType';

const requiredPart = t.interface({
  address: AddressSchema,
  breed: t.union([BreedSchema, t.null]),
  customerName: CustomerNameSchema,
  dateOfBirth: t.union([DateSchema, t.null]),
  displayLaboratoryNote: t.boolean,
  fathersBreed: t.union([BreedSchema, t.null]),
  fathersDateOfBirth: t.union([DateSchema, t.null]),
  fathersLaboratoryNumber: t.union([LabNumberSchema, t.null]),
  fathersRegistry: t.union([LineRegistrySchema, t.null]),
  fathersSampleId: t.union([GlobalEarTagSchema, t.null]),
  mothersBreed: t.union([BreedSchema, t.null]),
  mothersDateOfBirth: t.union([DateSchema, t.null]),
  mothersLaboratoryNumber: t.union([LabNumberSchema, t.null]),
  mothersSampleId: t.union([GlobalEarTagSchema, t.null]),
  name: t.union([AnimalNameSchema, t.null]),
  registry: t.union([LineRegistrySchema, t.null]),
  sampleId: GlobalEarTagSchema,
  sampleTypeId: PositiveLongSchema,
  sex: t.union([SexTypeSchema, t.null]),
  testResultTypeIds: t.union([t.array(PositiveLongSchema), t.null]),
  verificationOutcomeTypeId: t.union([PositiveLongSchema, t.null]),
});

export const OrderDetailsForProtocolSchema = excess(requiredPart);

export interface OrderDetailsForProtocol extends t.TypeOf<typeof OrderDetailsForProtocolSchema> {}
