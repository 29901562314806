import React, {FC, memo} from 'react';

interface Props {
    image: string;
    title?: string;
}

const SvgImageBase: FC<Props> = props => {
    const {image, title} = props;

    return (
        <span
            title={title}
            // eslint-disable-next-line react/no-danger, @typescript-eslint/naming-convention
            dangerouslySetInnerHTML={{__html: image}}
        />
    );
};

export const SvgImage = memo(SvgImageBase);
