// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.75.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.75.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-layout-components-main-panel-___styles__mainPanel___CXpwN{padding:0;padding:0 .5rem .5rem;box-shadow:0 2px 6px #0006;overflow-x:clip}@media (min-width: 768px){.src-modules-layout-components-main-panel-___styles__mainPanel___CXpwN{padding:0 1.5rem 1.5rem}}\n", "",{"version":3,"sources":["webpack://./src/modules/layout/components/main-panel/styles.sass"],"names":[],"mappings":"AAAA,uEAAA,SAAA,CAAA,qBAAA,CAAiD,0BAAA,CAAsC,eAAA,CAAgB,0BAAyB,uEAAhI,uBAAA,CAAA","sourcesContent":[".mainPanel{padding:0;padding:0 .5rem .5rem .5rem;box-shadow:0 2px 6px 0 rgba(0,0,0,.4);overflow-x:clip}@media(min-width: 768px){.mainPanel{padding:0 1.5rem 1.5rem 1.5rem}}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainPanel": "src-modules-layout-components-main-panel-___styles__mainPanel___CXpwN"
};
export default ___CSS_LOADER_EXPORT___;
