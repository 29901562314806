import {downloadFileFromResponse, putAll, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'translations';
import {showBeError} from 'utils/show-be-error';

import {ExportCdcbByIdsAction, orderAction} from '../action';
import {getSelectedOrders} from './selectors';

function* execute(_action: ExportCdcbByIdsAction): SagaIterator {
    const orderIds = yield* select(getSelectedOrders);
    yield* put(orderAction.setListActionActive('exportCdcb', true));

    const response = yield* call(Api.getCDCBExportSubset, {orderIds});

    if (response.isSuccess) {
        yield* call(downloadFileFromResponse, response);
    } else {
        yield putAll(showBeError(response, t('orders/ListActions')('exportCdcb')));
    }
    yield* put(orderAction.setListActionActive('exportCdcb', false));
}

export function* exportCdcbByIdsSaga(): SagaIterator {
    yield takeLatestF('order/EXPORT_CDCB_BY_IDS', execute);
}
