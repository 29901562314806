import {putAll, showSuccess, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {t} from 'translations';
import {showBeError} from 'utils/show-be-error';

import {DeleteOrdersByIdsAction, orderAction} from '../action';
import {orderApi} from '../api';
import {getSelectedOrders} from './selectors';

const te = t('orders/ListActions');

function* execute(_: DeleteOrdersByIdsAction): SagaIterator {
    const orderIds = yield* select(getSelectedOrders);
    yield* put(orderAction.setListActionActive('deleteOrders', true));

    const response = yield* call(orderApi.deleteOrderSubset, orderIds);

    if (response.isSuccess) {
        yield* put(orderAction.getOrders(opt({action: 'refresh'})));
        yield* put(showSuccess(te('deleteOrders'), t('orders/sagas')('success')));
    } else {
        yield putAll(showBeError(response, te('deleteOrders')));
    }
    yield* put(orderAction.setListActionActive('deleteOrders', false));
}

export function* deleteOrdersByIdsSaga(): SagaIterator {
    yield takeLatestF('order/DELETE_ORDERS_BY_IDS', execute);
}
