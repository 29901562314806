import {ActionSettings, ToolbarButton, mergeObjects, TableDataRequest} from '@fl/cmsch-fe-library';
import React, {FC, memo, useCallback, useMemo} from 'react';
import {Opt, isEmpty} from 'ts-opt';

import {simpleAnimalsSelector} from 'animals/model/selector';
import {Animal} from 'api/gen/Animal';
import {useOurTranslation} from 'translations';
import {ConnectedTable} from 'utils/tables';

import {DataCell} from '../DataCell';
import {MaternityDot} from '../MaternityDot';
import {PaternityDot} from '../PaternityDot';
import {getColumns} from './columns';
import {AnimalsTableRow} from './table-row';

interface Props {
    selectedAnimalIds: Array<number>;
    isRoleLaborer: boolean;
    isRoleBreedingUnion: boolean;
    getAnimals(tableDataRequest: Opt<TableDataRequest<Animal>>): void;
    setSelectedAnimals(animalIds: Array<number>): void;
    goTo(path: string): void;
    exportAnimal(animalId: number): void;
    exportMicrosatellites(animalIds: Array<number>): void;
}

const isRowRemovable = (_id: number, row: Animal): boolean => row.chipId === null;
const isExportDisabled = (_id: number, row: Animal): boolean => !row.exportable;

const pathnamesForRefresh = [/\/animals\/|animals-list\/\d/];
const AnimalsTableBase: FC<Props> = props => {
    const {
        selectedAnimalIds,
        isRoleBreedingUnion,
        isRoleLaborer,
        getAnimals,
        goTo,
        setSelectedAnimals,
        exportAnimal,
        exportMicrosatellites,
    } = props;

    const {t, tCommon} = useOurTranslation('animals/AnimalsTable');
    const rowMapper = useCallback((rowData: Animal, _index: number): AnimalsTableRow => mergeObjects(rowData, {
        data: isRoleLaborer
            ? <DataCell laboratoryNote={rowData.laboratoryNote} /> : null,
        parenthood: isRoleLaborer
            ? (
                <span>
                    <PaternityDot paternity={rowData.paternity} />
                    <MaternityDot maternity={rowData.maternity} />
                </span>
            ) : null,
    }), [isRoleLaborer]);

    const actionSettings = useMemo(
        (): ActionSettings<AnimalsTableRow> => ({
            removable: isRowRemovable,
            selectedIds: !isRoleBreedingUnion ? selectedAnimalIds : undefined,
            onSelectedIdsChange: !isRoleBreedingUnion ? setSelectedAnimals : undefined,
            extraActions: [
                {
                    id: 'detail',
                    title: t('rowActions.detail'),
                    icon: 'infoCircleOutlined',
                    href: (_id: number, row: AnimalsTableRow): string => `/animals/${row.id}`,
                },
                {
                    id: 'edit',
                    title: t('rowActions.edit'),
                    icon: 'editOutlined',
                    href: (_id: number, row: AnimalsTableRow): string => `/animals/${row.id}/edit`,
                    hidden: isRoleBreedingUnion,
                },
                {
                    id: 'export',
                    title: t('rowActions.export'),
                    icon: 'fileTextOutlined',
                    callback: exportAnimal,
                    hidden: isRoleBreedingUnion,
                    disabled: isExportDisabled,
                    disabledTitle: (): string => t('rowActions.exportUnavailable'),
                },
            ],
        }),
        [isRoleBreedingUnion, selectedAnimalIds, setSelectedAnimals, t, exportAnimal],
    );

    const compareAnimals = useCallback(() => {
        goTo(`/animal-comparison/${selectedAnimalIds.join(',')}`);
        setSelectedAnimals([]);
    }, [goTo, selectedAnimalIds, setSelectedAnimals]);

    const headerButtons = useMemo(
        (): Array<ToolbarButton> => [
            {
                title: `${t('actions.compare')} (${selectedAnimalIds.length})`,
                disabled: isEmpty(selectedAnimalIds),
                hidden: isRoleBreedingUnion,
                visuals: 'primary',
                onClick: compareAnimals,
                testId: 'compare',
            },
            {
                title: `${t('actions.exportMicrosatellites')} (${selectedAnimalIds.length})`,
                icon: 'exportOutlined',
                disabled: isEmpty(selectedAnimalIds),
                hidden: isRoleBreedingUnion,
                visuals: 'primary',
                onClick: (): void => {
                    exportMicrosatellites(selectedAnimalIds);
                    setSelectedAnimals([]);
                },
                testId: 'export-microsatellites',
            },
            {
                title: `${t('actions.deselectAll')} (${selectedAnimalIds.length})`,
                icon: 'closeOutlined',
                disabled: isEmpty(selectedAnimalIds),
                hidden: isRoleBreedingUnion,
                visuals: 'default',
                onClick: (): void => {
                    setSelectedAnimals([]);
                },
                testId: 'deselect-all',
            },
        ],
        [t, selectedAnimalIds, isRoleBreedingUnion, compareAnimals, exportMicrosatellites, setSelectedAnimals],
    );

    return (
        <ConnectedTable
            tableId="animals"
            columns={getColumns(isRoleLaborer, t, tCommon)}
            rowDataToTableRowMapper={rowMapper}
            rowDataPageSelector={simpleAnimalsSelector.animalsPage}
            onFetchTableData={getAnimals}
            actionSettings={actionSettings}
            pathnamesForRefresh={pathnamesForRefresh}
            rowActionsOnRight
            headerButtons={headerButtons}
            paginationPosition="both"
            showFulltextFilter
        />
    );
};

export const AnimalsTable = memo(AnimalsTableBase);
