import {takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {Errors} from 'api/gen/Errors';
import {formHelpers} from 'utils/forms';

import {ValidateSnpNameAction} from '../actions';

function* execute({payload}: ValidateSnpNameAction): SagaIterator {
    const response = yield* call(Api.validateSnp, {name: payload});
    const expectedOKStatus = 204;

    if (response.status === expectedOKStatus) {
        yield* put(formHelpers.stopAsyncValidation('createSnp'));
    } else {
        const errors = response.data as Errors;
        yield* put(formHelpers.stopAsyncValidation('createSnp', {name: errors[0].message}));
    }
}

export function* validateNameSaga(): SagaIterator {
    yield takeLatestF('snpManagement/VALIDATE_NAME', execute);
}
