// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.75.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.75.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-animals-components-PaternityDot-___styles__stateIcon___TlecY{margin-left:5px;cursor:default}.src-modules-animals-components-PaternityDot-___styles__stateIcon___TlecY svg g path{stroke:#000000b0!important}.src-modules-animals-components-PaternityDot-___styles__paternityPlain___mSKB3{position:relative;top:-2px}.src-modules-animals-components-PaternityDot-___styles__paternityNone___agNsi{visibility:hidden}.src-modules-animals-components-PaternityDot-___styles__paternityMissing___tngBj circle{fill:gray!important}.src-modules-animals-components-PaternityDot-___styles__paternityMatches___XL5Rm circle{fill:#00bd00!important}.src-modules-animals-components-PaternityDot-___styles__paternityMismatches___bM_ej circle{fill:#ff2727!important}\n", "",{"version":3,"sources":["webpack://./src/modules/animals/components/PaternityDot/styles.sass"],"names":[],"mappings":"AAAA,0EAAW,eAAA,CAAgB,cAAA,CAAe,qFAAsB,0BAAA,CAA0C,+EAAgB,iBAAA,CAAkB,QAAA,CAAS,8EAAe,iBAAA,CAAkB,wFAAyB,mBAAA,CAAqB,wFAAyB,sBAAA,CAAwB,2FAA4B,sBAAA","sourcesContent":[".stateIcon{margin-left:5px;cursor:default}.stateIcon svg g path{stroke:rgba(0,0,0,.6901960784) !important}.paternityPlain{position:relative;top:-2px}.paternityNone{visibility:hidden}.paternityMissing circle{fill:gray !important}.paternityMatches circle{fill:#00bd00 !important}.paternityMismatches circle{fill:#ff2727 !important}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stateIcon": "src-modules-animals-components-PaternityDot-___styles__stateIcon___TlecY",
	"paternityPlain": "src-modules-animals-components-PaternityDot-___styles__paternityPlain___mSKB3",
	"paternityNone": "src-modules-animals-components-PaternityDot-___styles__paternityNone___agNsi",
	"paternityMissing": "src-modules-animals-components-PaternityDot-___styles__paternityMissing___tngBj",
	"paternityMatches": "src-modules-animals-components-PaternityDot-___styles__paternityMatches___XL5Rm",
	"paternityMismatches": "src-modules-animals-components-PaternityDot-___styles__paternityMismatches___bM_ej"
};
export default ___CSS_LOADER_EXPORT___;
