import {debounce} from 'lodash/fp';
import React, {FC, memo, useCallback, useMemo} from 'react';
import {Opt} from 'ts-opt';

import {CodeTableItem} from 'api/gen/CodeTableItem';
import {CodeTableValidation} from 'api/gen/CodeTableValidation';
import {Modal} from 'common/layout';
import {debounceTime} from 'constants/general';
import {useOurTranslation} from 'translations';

import {TestResultForm} from '../TestResultForm';

interface Props {
    visible: boolean;
    testResultIdBeingEdited: Opt<number>;
    onSubmitEdit(testResult: CodeTableItem): void;
    onSubmitCreate(testResult: CodeTableItem): void;
    onCancel(): void;
    validateTestResultType(data: CodeTableValidation): void;
}
const TestResultsModalBase: FC<Props> = props => {
    const {
        visible,
        testResultIdBeingEdited,
        onSubmitEdit,
        onSubmitCreate,
        onCancel,
        validateTestResultType,
    } = props;

    const {t} = useOurTranslation('dials/testResults');

    const validateTestResultTypeDebounced = useMemo(() =>
        debounce(debounceTime)(validateTestResultType)
    , [validateTestResultType]);

    const validateValue = useCallback((value: Opt<string>) => {
        const idToExclude = testResultIdBeingEdited.orNull();
        value.onSome(x => validateTestResultTypeDebounced({value: x, idToExclude}));
    }, [testResultIdBeingEdited, validateTestResultTypeDebounced]);

    return (
        <Modal
            title={t(testResultIdBeingEdited.nonEmpty ? 'editTitle' : 'createTitle')}
            visible={visible}
            onCancel={onCancel}
            footer={null}
        >
            <TestResultForm
                onSubmit={testResultIdBeingEdited.nonEmpty ? onSubmitEdit : onSubmitCreate}
                isEdit={testResultIdBeingEdited.nonEmpty}
                onCancel={onCancel}
                onChangeValue={validateValue}
            />
        </Modal>
    );
};

export const TestResultsModal = memo(TestResultsModalBase);
