import * as O from 'optics-ts';
import {compose} from 'redux';
import {opt} from 'ts-opt';

import {Action} from 'app/actions';

import {initialOtherState, OtherState} from './state';

const otherStateO = O.optic<OtherState>();

// eslint-disable-next-line max-lines-per-function
export const otherReducer = (state: OtherState = initialOtherState, action: Action): OtherState => {
    switch (action.type) {
        case 'other/UPDATE_CATALOG_LISTS': {
            return compose(
                O.set(otherStateO.prop('catalogListsUpdated'))(false),
                O.set(otherStateO.prop('catalogListsUpdateInProgress'))(true),
            )(state);
        }

        case 'other/UPDATE_CATALOG_LISTS_SUCCESS': {
            return compose(
                O.set(otherStateO.prop('catalogListsUpdated'))(true),
                O.set(otherStateO.prop('catalogListsUpdateInProgress'))(false),
            )(state);
        }

        case 'other/UPDATE_CATALOG_LISTS_ERROR': {
            return compose(
                O.set(otherStateO.prop('catalogListsUpdated'))(false),
                O.set(otherStateO.prop('catalogListsUpdateInProgress'))(false),
            )(state);
        }

        case 'other/SYNCHRONIZE_ANIMALS': {
            return compose(
                O.set(otherStateO.prop('animalsSychronizeStarted'))(false),
                O.set(otherStateO.prop('animalsSynchronizationInProgress'))(true),
            )(state);
        }

        case 'other/SYNCHRONIZE_ANIMALS_SUCCESS': {
            return compose(
                O.set(otherStateO.prop('animalsSychronizeStarted'))(true),
                O.set(otherStateO.prop('animalsSynchronizationInProgress'))(false),
            )(state);
        }

        case 'other/SYNCHRONIZE_ANIMALS_ERROR': {
            return compose(
                O.set(otherStateO.prop('animalsSychronizeStarted'))(false),
                O.set(otherStateO.prop('animalsSynchronizationInProgress'))(false),
            )(state);
        }

        case 'other/UPDATE_PATERNITY': {
            return compose(
                O.set(otherStateO.prop('paternityUpdateStarted'))(false),
                O.set(otherStateO.prop('paternityUpdateInProgress'))(true),
            )(state);
        }

        case 'other/UPDATE_PATERNITY_SUCCESS': {
            return compose(
                O.set(otherStateO.prop('paternityUpdateStarted'))(true),
                O.set(otherStateO.prop('paternityUpdateInProgress'))(false),
            )(state);
        }

        case 'other/UPDATE_PATERNITY_ERROR': {
            return compose(
                O.set(otherStateO.prop('paternityUpdateStarted'))(false),
                O.set(otherStateO.prop('paternityUpdateInProgress'))(false),
            )(state);
        }

        case 'other/GET_APP_INFO_SUCCESS': {
            const {appInfo} = action.payload;

            return O.set(otherStateO.prop('appInfo'))(opt(appInfo))(state);
        }

        default:
            return state;
    }
};
