import {takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {put, call, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {formHelpers} from 'utils/forms';

import {GetLaboratoryNumberBySampleIdAction, orderAction} from '../action';

function* execute({payload: {sampleId, parent}}: GetLaboratoryNumberBySampleIdAction): SagaIterator {
    const response = yield* call(Api.findAnimal, {sampleId, registry: null});
    yield* put(orderAction.isValidating(false));

    if (response.isSuccess) {
        const {data: {laboratoryNumber, registry}} = response;

        yield* put(orderAction.getLaboratoryNumberBySampleIdSuccess(parent, laboratoryNumber));
        if (parent === 'father') {
            yield* put(formHelpers.changeValueInSection('newOrder', 'animalDetails', 'fathersRegistry', registry));
        }
    } else {
        yield* put(orderAction.getLaboratoryNumberBySampleIdSuccess(parent, null));
        if (parent === 'father') {
            const newOrderFormValues = yield* select(formHelpers.formValues('newOrder'));
            const registry = newOrderFormValues.prop('animalDetails').prop('fathersRegistry').orNull();
            yield* put(orderAction.validateAnimal(sampleId, registry, 'father'));
        }
    }
}

export function* checkParentLaboratoryNumberSaga(): SagaIterator {
    yield takeLatestF('order/GET_LABORATORY_NUMBER_BY_SAMPLE_ID', execute);
}
