/* eslint-disable max-lines-per-function */
import {classNames, Nullable, Option, TypedFormSectionInjectedPropsUnsafe} from '@fl/cmsch-fe-library';
import {identity, isNil, join} from 'lodash/fp';
import React, {FC, memo, useMemo} from 'react';
import {Opt, pipe, filter, opt} from 'ts-opt';

import {LastOrderUserDetails} from 'api/gen/LastOrderUserDetails';
import {OrderCustomerDetails} from 'api/gen/OrderCustomerDetails';
import {User} from 'api/gen/User';
import {useOurTranslation} from 'translations';

import {AddressFormSection, AddressFormSectionOuterProps} from '../AddressFormSection';
import {UserDetailsFormSectionValues} from './user-details-form-section-values';

const getCustomerOption = (customer: OrderCustomerDetails, index: number): Option<number> => {
    const lastPart: string = pipe(
        [customer.firstname, customer.lastname],
        filter(identity),
        join(' '),
        (s: string): string => s === '' ? s : `(${s})`,
    );

    return {
        label: `${customer.customerName} ${lastPart}`,
        value: index,
    };
};

const getUserOption = (x: User): Option<number> => ({
    label: `${x.id} - ${[x.firstName, x.lastName].join(' ')} (${x.email})`,
    value: x.id,
});

export interface UserDetailsFormSectionOuterProps {
    customers: Array<OrderCustomerDetails>;
    showCreatedBy: boolean;
    breeders: Nullable<Array<User>>;
    userDetailsFromCIN: Nullable<LastOrderUserDetails>;
    noCin: boolean;
    inWizard: boolean;
    showAllInputs: Nullable<boolean>;
    disableAddress?: Nullable<boolean>;
    hasSameAddresses: Nullable<boolean>;
    caption: string;
    isIdRequired?: boolean;
    isPhoneRequired?: boolean;
    onCinClick(value: Opt<string>): void;
    prefillFromExisting(value: Opt<number>): void;
}

type Props = UserDetailsFormSectionOuterProps & TypedFormSectionInjectedPropsUnsafe<UserDetailsFormSectionValues>;

const UserDetailsFormSectionBase: FC<Props> = props => {
    const {
        Fields,
        customers,
        showCreatedBy,
        breeders,
        userDetailsFromCIN,
        noCin,
        inWizard,
        showAllInputs,
        disableAddress,
        hasSameAddresses,
        isIdRequired = false,
        isPhoneRequired = true,
        onCinClick,
        prefillFromExisting,
        caption,
    } = props;

    const {t} = useOurTranslation('orders/UserDetailsForm');

    const isVisible = useMemo(
        () => userDetailsFromCIN !== null || Boolean(showAllInputs) || noCin,
        [userDetailsFromCIN, showAllInputs, noCin],
    );

    const formRowClassnames = classNames('row', !isVisible && 'd-none');
    const customersOptions = useMemo(() => customers.map(getCustomerOption), [customers]);
    const breedersOptions = useMemo(() => opt(breeders).mapIn(getUserOption).orElse([]), [breeders]);
    const disabledAddress = useMemo(() => isNil(disableAddress) ? false : disableAddress, [disableAddress]);

    const AddressSectionField = Fields.genSection<'address', AddressFormSectionOuterProps>();
    const BillingAddressSectionField = Fields.genSection<'billingAddress', AddressFormSectionOuterProps>();

    const address: AddressFormSectionOuterProps = {
        legend: 'address',
        disabledAddress,
    };

    const billingAddress: AddressFormSectionOuterProps = {
        legend: 'billingAddress',
        disabledAddress,
    };

    return (
        <fieldset>
            {inWizard && <legend data-test-id="legend">{caption}</legend>}
            <div className="row">
                <div className={classNames('col-12', showCreatedBy && 'col-md-6')}>
                    <Fields.NumberSelect
                        name="prefillName"
                        label={t('prefillName')}
                        placeholder={t('prefillNamePlaceholder')}
                        onFieldChange={prefillFromExisting}
                        options={customersOptions}
                    />
                </div>
                <div className="col-12 col-md-6">
                    {showCreatedBy && (
                        <Fields.NumberSelect
                            name="id"
                            label={t('id')}
                            options={breedersOptions}
                            isRequired={isIdRequired}
                        />
                    )}
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-md-6">
                    <Fields.Input
                        name="cin"
                        label={t('cin')}
                        type="text"
                        placeholder={t('cinPlaceholder')}
                        onAction={onCinClick}
                    />
                    <div className={classNames(!inWizard && 'd-none')}>
                        <Fields.Checkbox
                            name="noCin"
                            label={t('noCin')}
                        />
                    </div>
                </div>

                <div className={classNames('col-12 col-md-6', !isVisible && 'd-none')}>
                    <Fields.Input
                        name="vatNumber"
                        label={t('vatNumber')}
                        type="text"
                        placeholder={t('vatNumberPlaceholder')}
                    />
                </div>
            </div>

            <div className={formRowClassnames}>
                <div className="col-12">
                    <Fields.Input
                        name="customerName"
                        label={t('customerName')}
                        type="text"
                        placeholder={t('customerNamePlaceholder')}
                        isRequired
                    />
                </div>
            </div>

            <div className={formRowClassnames}>
                <div className="col-12 col-md-6">
                    <Fields.Input
                        name="firstname"
                        label={t('firstname')}
                        type="text"
                        isRequired
                    />
                </div>

                <div className="col-12 col-md-6">
                    <Fields.Input
                        name="lastname"
                        label={t('lastname')}
                        type="text"
                        isRequired
                    />
                </div>
            </div>

            <div className={formRowClassnames}>
                <div className="col-12 col-md-6">
                    <Fields.Input
                        name="email"
                        label={t('email')}
                        type="email"
                        isRequired
                    />
                </div>

                <div className="col-12 col-md-6">
                    <Fields.Input
                        name="phone"
                        label={t('phone')}
                        type="tel"
                        isRequired={isPhoneRequired}
                    />
                </div>
            </div>

            <div className={classNames(!isVisible && 'd-none')}>
                <AddressSectionField
                    name="address"
                    Component={AddressFormSection}
                    props={address}
                />
            </div>

            <div className={formRowClassnames}>
                <div className="col-12">
                    <Fields.Checkbox
                        name="hasSameAddresses"
                        label={t('hasSameAddresses')}
                    />
                </div>
            </div>

            {!hasSameAddresses && (
                <div className={classNames(!isVisible && 'd-none')}>
                    <BillingAddressSectionField
                        name="billingAddress"
                        Component={AddressFormSection}
                        props={billingAddress}
                    />
                </div>
            )}
        </fieldset>
    );
};

export const UserDetailsFormSection = memo(UserDetailsFormSectionBase);
