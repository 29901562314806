import React, {FC, memo} from 'react';

import {FooterButtons} from 'common/buttons';
import {useOurTranslation} from 'translations';
import {useForm} from 'utils/forms';

import {CategoryFormValues, categoryTableName} from './category-form-types';
import {validate} from './validations';

const initialValues: CategoryFormValues = {
    name: null,
};

interface Props {
    isEdit?: boolean;
    onCancel?(): void;
    onSubmit(values: CategoryFormValues): void;
}

const CreateCategoryFormBase: FC<Props> = props => {
    const {isEdit, onCancel, onSubmit} = props;
    const {t} = useOurTranslation('snpManagement/SnpTable');

    const {Form, Fields, valid, pristine} = useForm({
        form: categoryTableName,
        initialValues,
        destroyOnUnmount: true,
        validate,
        onSubmit,
    });

    return (
        <Form>
            <div className="row">
                <div className="col-12">
                    <Fields.Input
                        name="name"
                        type="text"
                        label={t('formTranslations.name')}
                        isRequired
                    />
                </div>
            </div>
            <FooterButtons
                editing={isEdit}
                disabledSubmit={!valid || pristine}
                onCancel={onCancel}
            />
        </Form>
    );
};

export const CreateCategoryForm = memo(CreateCategoryFormBase);
