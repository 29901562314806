import React from 'react';
import {Route} from 'react-router-dom';

import {PaternityCheck} from './screens';

export const paternityCheckRoutesWithoutFetching: Array<RegExp> = [/^\/paternity-check$/];

export const paternityCheckRoutes = [
    (
        <Route
            exact
            path="/paternity-check"
            component={PaternityCheck}
            key="paternity-check"
        />
    ),
];
