import {extractFormErrorsFromResponse, putAll, showBeError, takeLatestF} from '@fl/cmsch-fe-library';
import * as routerActions from 'redux-first-history';
import {SagaIterator} from 'redux-saga';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'translations';
import {simpleUserSelector} from 'user/model/selector';
import {formHelpers} from 'utils/forms';

import {orderAction, UpdateOrderAction} from '../action';

// eslint-disable-next-line max-lines-per-function
function* execute(action: UpdateOrderAction): SagaIterator {
    const {order, orderId, nextUrl} = action.payload;

    if (order.orderTypeIds === null || order.sampleTypeId === null) {
        return;
    }

    yield* put(formHelpers.startSubmit('editOrder'));
    const currentUser = yield* select(simpleUserSelector.currentUser);

    const userRole = currentUser.map(x => x.role.name).orNull();
    const isBreeder = userRole === 'ROLE_BREEDER';
    const {
        barcode,
        isolated,
        isolationDate,
        laboratoryNote,
        laboratoryNumber,
        orderTypeIds,
        sampleDeliveredDate,
        sampleTypeId,
    } = order;

    const response = isBreeder
        ? yield* call(Api.addOrderTypesToOrder, orderTypeIds, {orderId})
        : yield* call(Api.updateOrder, {
            barcode,
            isolated,
            isolationDate,
            laboratoryNote,
            laboratoryNumber,
            orderTypeIds,
            sampleDeliveredDate,
            sampleTypeId,
        }, {orderId});

    if (response.isSuccess) {
        yield* put(formHelpers.stopSubmit('editOrder'));
        yield* put(orderAction.getOrder(orderId));
        if (nextUrl) {
            yield* put(routerActions.push(nextUrl));
        }
    } else {
        yield putAll(showBeError(response, t('orders/EditForm')('title')));
        yield* put(formHelpers.stopSubmit('editOrder', extractFormErrorsFromResponse(response)));
    }
}

export function* updateOrderSaga(): SagaIterator {
    yield takeLatestF('order/UPDATE_ORDER', execute);
}
