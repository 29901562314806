import React, {Fragment, FC, memo} from 'react';

import {OrderUserDetails} from 'api/gen/OrderUserDetails';
import {AddressView} from 'orders/components/OrderDetailsView/address-view';
import {Props} from 'orders/components/OrderDetailsView/index';
import {useOurTranslation} from 'translations';

const formatCinVatNumber = (customer: OrderUserDetails): string =>
    !customer.cin && !customer.vatNumber
        ? '-'
        : `${customer.cin ?? '-'}, ${customer.vatNumber ?? '-'}`;

const UserDetailsBase: FC<Pick<Props, 'order'>> = ({order}) => {
    const {t} = useOurTranslation('orders/OrderDetails');
    const {customer} = order;

    return (
        <Fragment>
            <thead className="thead-light">
                <tr>
                    <th colSpan={2}>{t('customerData')}</th>
                </tr>
            </thead>

            <tbody>
                <tr>
                    <th>{t('customerName')}</th>
                    <td>{customer.customerName}</td>
                </tr>
                <tr>
                    <th>{t('email')}</th>
                    <td>{customer.email}</td>
                </tr>
                <tr>
                    <th>{t('phone')}</th>
                    <td>{customer.phone}</td>
                </tr>
                <tr>
                    <th>{t('cinVatNumber')}</th>
                    <td>
                        {formatCinVatNumber(customer)}
                    </td>
                </tr>
                <tr>
                    <th>{t('firstAndLastName')}</th>
                    <td>
                        {customer.firstname} {customer.lastname}
                    </td>
                </tr>
                <tr>
                    <th>{t('address')}</th>
                    <td>
                        <AddressView data={customer.address} />
                    </td>
                </tr>
                <tr>
                    <th>{t('billingAddress')}</th>
                    <td>
                        <AddressView data={customer.billingAddress} />
                    </td>
                </tr>
            </tbody>
        </Fragment>
    );
};

export const UserDetails = memo(UserDetailsBase);
