import {Button} from '@fl/cmsch-fe-library';
import * as Sentry from '@sentry/react';
import React, {Component, ErrorInfo, ReactElement, ReactNode} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';

import {logger} from 'app/sentry-logger';
import {Card} from 'common/layout';

import styles from './styles.sass';

interface OwnProps {
    children: ReactNode;
}

interface State {
    hasError: boolean;
}

type Props = OwnProps & WithTranslation<'common'>;

class ErrorBoundaryBase extends Component<Props, State> {
    public state: State = {
        hasError: false,
    };

    public static getDerivedStateFromError(_: Error): State {
        // Update state so the next render will show the fallback UI.
        return {hasError: true};
    }

    public componentDidCatch(error: Error, _errorInfo: ErrorInfo): void {
        logger.logErrorAndConsole(error);
    }

    public render(): ReactNode {
        const {children} = this.props;

        return (
            <Sentry.ErrorBoundary fallback={this.getSentryFallback()}>
                {children}
            </Sentry.ErrorBoundary>
        );
    }

    private readonly getSentryFallback = (): ReactElement => {
        const {t} = this.props;

        return (
            <div className={styles.errorFallbackScreen}>
                <div className={styles.errorFallbackScreenCard}>
                    <Card>
                        <h2>{t('unexpectedError')}</h2>
                        <p>{t('unexpectedErrorOccured')}<br />{t('problemLogged')}<br />{t('sorryForIncovenience')}</p>
                        <Button
                            visuals="primary"
                            onClick={this.handleRestartClick}
                        >
                            {t('restartApp')}
                        </Button>
                    </Card>
                </div>
            </div>
        );
    };

    private handleRestartClick(): void {
        window.location.reload();
    }
}

export const ErrorBoundary = withTranslation('common')(ErrorBoundaryBase);
