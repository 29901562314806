import {ErrorResponse, ErrorsFromBE, takeLatestF} from '@fl/cmsch-fe-library';
import * as O from 'optics-ts';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'translations';
import {formHelpers} from 'utils/forms';
import {Errors} from 'utils/validator';

import {AnimalDetailsFormSectionValues} from '../../components/AnimalDetailsForm/animal-details-form-section-values';
import {AnimalsDetailsTableType} from '../../components/NewBulkForm/new-bulk-order-form-values';
import {newOrderFormName, NewOrderFormValues} from '../../components/NewForm/new-order-form-values';
import {ValidateAnimal} from '../../types/validate-animal-type';
import {idFromBeSuggestion, transformAnimalDetailsToForm} from '../../utils/transform-animal-details';
import {orderAction, ValidateAnimalAction} from '../action';
import {simpleFormSelector, simpleOrdersSelector} from '../selector';

// TODO: Errors does not support null values
interface AnimalDetailsAsyncErrors {
    sampleId?: string;
    registry?: string;
    fathersRegistry?: string;
    motherSampleId?: string;
}
export interface NewOrderAsyncErrors {
    animalDetails?: AnimalDetailsAsyncErrors;
}

const animalDetailsO = O.optic<NewOrderAsyncErrors>()
    .prop('animalDetails')
    .valueOr<AnimalDetailsAsyncErrors>({
        sampleId: undefined,
        fathersRegistry: undefined,
        motherSampleId: undefined,
        registry: undefined,
    });

export function buildFormErrors(
    response: ErrorResponse,
    type: ValidateAnimal,
    sampleIdAction: boolean,
    oldErrors: NewOrderAsyncErrors,
): Errors<NewOrderFormValues> {
    const errors = response.data as ErrorsFromBE;

    if (type === 'child') {
        return O.set(animalDetailsO.prop(sampleIdAction ? 'sampleId' : 'registry'))(errors[0].message)(oldErrors);
    } else if (type === 'father') {
        return O.set(animalDetailsO.prop('fathersRegistry'))(errors[0].message)(oldErrors);
    } else {
        return O.set(animalDetailsO.prop('motherSampleId'))(errors[0].message)(oldErrors);
    }
}

export function removeFormError(
    type: ValidateAnimal,
    sampleIdAction: boolean,
    oldErrors: NewOrderAsyncErrors,
): Errors<NewOrderFormValues> {
    if (type === 'child') {
        return O.set(animalDetailsO.prop(sampleIdAction ? 'sampleId' : 'registry'))(undefined)(oldErrors);
    } else if (type === 'father') {
        return O.set(animalDetailsO.prop('fathersRegistry'))(undefined)(oldErrors);
    } else {
        return O.set(animalDetailsO.prop('motherSampleId'))(undefined)(oldErrors);
    }
}

function* setRegistryError(errors: NewOrderAsyncErrors): SagaIterator {
    yield* put(formHelpers.stopAsyncValidation(
        'newOrder',
        O.set(animalDetailsO.prop('registry'))(t('orders/newBulkOrder')('editRegistryErrMsg'),
        )(errors)));
}

function* setEarTagError(errors: NewOrderAsyncErrors): SagaIterator {
    yield* put(formHelpers.stopAsyncValidation(
        'newOrder',
        O.set(animalDetailsO.prop('sampleId'))(t('orders/newBulkOrder')('editEarTagErrMsg'))(errors)));
}
function* setAnimal(editingAnimal: AnimalsDetailsTableType): SagaIterator {
    yield* put(formHelpers.change(
        'newOrder',
        'animalDetails',
        transformAnimalDetailsToForm(editingAnimal),
    ));
}

// eslint-disable-next-line complexity, max-lines-per-function
function* execute(action: ValidateAnimalAction): SagaIterator {
    const {registry, sampleId, type} = action.payload;
    const barcode = (yield* select(simpleFormSelector.orderBarcode)) ?? undefined;
    const {isAnimalDetailEditing} = yield* select(simpleOrdersSelector.orders);
    const actualAnimal = yield* select(simpleFormSelector.newOrderAnimal);
    const animalsUnsafe = yield* select(simpleFormSelector.newOrderBulkAnimalsDetails);

    const isChild = type === 'child';
    const sampleIdAction = isChild && registry === null;
    const sampleRegistryAction = isChild && sampleId === null;

    if (sampleIdAction) {
        yield* put(orderAction.setNewOrderEarNumberWarning(null));
        yield* put(orderAction.resetParentsAlreadyAnalyzed());
    }

    const asyncErrors = yield* select(simpleFormSelector.newOrderAsyncErrors);
    const animalAsyncErrors =
        asyncErrors.orUndef()?.animalDetails as Errors<AnimalDetailsFormSectionValues> | undefined;

    const errors: NewOrderAsyncErrors = {animalDetails: animalAsyncErrors};

    const animals = opt(animalsUnsafe).orElse([]);
    if (isAnimalDetailEditing && isChild) {
        const [editingAnimal] = animals.filter(a => a.isEditing);
        const animalsHasSameEarTag = editingAnimal.earTag === actualAnimal?.sampleId;
        const animalsHasSameRegistry = editingAnimal.lineRegistry === actualAnimal?.registry;
        const animalsHasSameId = editingAnimal.id === actualAnimal?.id;
        const someOtherAnimalHasSameRegistry = animals.some(
            a => a.lineRegistry === actualAnimal?.registry && a.id !== actualAnimal.id,
        );
        const someOtherAnimalHasSameEarTag = animals.some(
            a => a.earTag === actualAnimal?.sampleId && a.id !== actualAnimal.id,
        );

        if (animalsHasSameId) {
            yield* put(orderAction.setIsNewOrderAnimalValid(true));
            yield* put(formHelpers.stopAsyncValidation('newOrder', removeFormError(type, sampleIdAction, errors)));
            yield* put(orderAction.isValidating(false));
            if (sampleIdAction) {
                if (someOtherAnimalHasSameEarTag) {
                    yield* put(orderAction.setIsNewOrderAnimalValid(!sampleIdAction));

                    return yield* call(setEarTagError, errors);
                }
                if (animalsHasSameEarTag) return yield* call(setAnimal, editingAnimal);
            }
            if (sampleRegistryAction) {
                if (someOtherAnimalHasSameRegistry) return yield* call(setRegistryError, errors);
                if (animalsHasSameRegistry) return;
            }
        } else {
            yield* put(orderAction.isValidating(false));
            const actualAnimalIsFromServer = actualAnimal?.id === idFromBeSuggestion;
            if (sampleIdAction && animalsHasSameEarTag) {
                if (!actualAnimalIsFromServer) {
                    yield* put(orderAction.setIsNewOrderAnimalValid(!sampleIdAction));

                    return yield* call(setEarTagError, errors);
                }

                return yield* call(setAnimal, editingAnimal);
            }
            if (sampleIdAction && someOtherAnimalHasSameEarTag) {
                yield* put(orderAction.setIsNewOrderAnimalValid(!sampleIdAction));

                return yield* call(setEarTagError, errors);
            }
            if (sampleRegistryAction) {
                if (someOtherAnimalHasSameRegistry) return yield* call(setRegistryError, errors);

                return;
            }
        }
    }
    if (!isAnimalDetailEditing && animals.length > 0 && isChild) {
        const sameEarTagAlredyExist = animals.some(a => a.earTag === actualAnimal?.sampleId);
        yield* put(orderAction.isValidating(false));
        if (sampleIdAction && sameEarTagAlredyExist) return yield* call(setEarTagError, errors);

        const sameRegistryAlredyExist = animals.some(a => a.lineRegistry === actualAnimal?.registry);
        if (sampleRegistryAction && sameRegistryAlredyExist) return yield* call(setRegistryError, errors);
    }

    const response = yield* call(Api.validateOrderData, {
        earTag: sampleId,
        validateExistingOrder: type === 'child',
        chipId: null,
        laboratoryNumber: null,
        lineRegistry: registry,
    });

    if (response.isSuccess) {
        if (sampleIdAction) yield* put(orderAction.animalsSearch(sampleId, registry, barcode));
        yield* put(formHelpers.stopAsyncValidation('newOrder', removeFormError(type, sampleIdAction, errors)));
        if (sampleIdAction) yield* put(orderAction.setIsNewOrderAnimalValid(true));
        if (sampleRegistryAction) yield* put(orderAction.isValidating(false));
    } else {
        yield* put(orderAction.isValidating(false));
        yield* put(formHelpers.stopAsyncValidation(
            'newOrder',
            buildFormErrors(response, type, sampleIdAction, errors),
        ));
        if (sampleIdAction) {
            yield* put(orderAction.setIsNewOrderAnimalValid(false));
            yield* put(formHelpers.setTouchedFieldsInSection(newOrderFormName, 'animalDetails', ['sampleId']));
        }
    }
}

export function* validateAnimalSaga(): SagaIterator {
    yield takeLatestF('order/VALIDATE_ANIMAL', execute);
}
