import {
    convertTableDataParamsToSimpleDto,
    extractBetweenDatesFilterPart,
    extractMultiSelectNumberFilterPart,
    extractContainsStringFilterPart,
    extractBooleanFilterPart,
    extractEqualsSelectFilterPart,
    TableDataParams,
} from '@fl/cmsch-fe-library';
import {opt} from 'ts-opt';

import {ParentMatch} from 'api/gen/ParentMatch';
import {SexType} from 'api/gen/SexType';

import {OrderTableType} from '../components/OrdersTable/order-table-type';
import {OrderTableParams} from '../types/order-table-params';

export const getParams = (tableDataParams: TableDataParams<OrderTableType>): OrderTableParams =>
    convertTableDataParamsToSimpleDto(tableDataParams, filterState => ({
        chipId: opt(extractContainsStringFilterPart(filterState.chipId)).map(String).orNull(),
        breed: extractContainsStringFilterPart(filterState.breed),
        createdDateFrom: extractBetweenDatesFilterPart(filterState.createdTs)?.from ?? null,
        createdDateTo: extractBetweenDatesFilterPart(filterState.createdTs)?.to ?? null,
        customerName: extractContainsStringFilterPart(filterState.customerName),
        earTag: extractContainsStringFilterPart(filterState.earTag),
        hasCatalogList: extractBooleanFilterPart(filterState.hasCatalogList),
        isolated: extractBooleanFilterPart(filterState.isolated),
        isolationDateFrom: extractBetweenDatesFilterPart(filterState.isolationDate)?.from ?? null,
        isolationDateTo: extractBetweenDatesFilterPart(filterState.isolationDate)?.to ?? null,
        laboratoryNumber: extractContainsStringFilterPart(filterState.laboratoryNumber),
        maternity: extractEqualsSelectFilterPart<ParentMatch>(filterState.maternity),
        orderTypeIds: extractMultiSelectNumberFilterPart(filterState.orderTypes),
        paternity: extractEqualsSelectFilterPart<ParentMatch>(filterState.paternity),
        protocolGeneratedDateFrom: extractBetweenDatesFilterPart(filterState.protocolGeneratedDate)?.from ?? null,
        protocolGeneratedDateTo: extractBetweenDatesFilterPart(filterState.protocolGeneratedDate)?.to ?? null,
        sampleDeliveredDateFrom: extractBetweenDatesFilterPart(filterState.sampleDeliveredDate)?.from ?? null,
        sampleDeliveredDateTo: extractBetweenDatesFilterPart(filterState.sampleDeliveredDate)?.to ?? null,
        sampleTypeIds: extractMultiSelectNumberFilterPart(filterState.sampleTypeId),
        sex: extractEqualsSelectFilterPart<SexType>(filterState.sex),
        isBilled: extractBooleanFilterPart(filterState.isBilled),
    }));
