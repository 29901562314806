import {ParenthoodVerification} from 'api/gen/ParenthoodVerification';
import {SampleIdCouple} from 'api/gen/SampleIdCouple';

export const paternityCheckAction = {
    checkPaternity: (data: SampleIdCouple) => ({
        type: 'paternityCheck/CHECK_PATERNITY',
        payload: {data},
    }) as const,
    checkPaternitySuccess: (result: ParenthoodVerification) => ({
        type: 'paternityCheck/CHECK_PATERNITY_SUCCESS',
        payload: {result},
    }) as const,
    resetResults: () => ({
        type: 'paternityCheck/RESET_RESULTS',
    }) as const,
};

export type CheckPaternityAction = ReturnType<typeof paternityCheckAction.checkPaternity>;
export type CheckPaternitySuccessAction = ReturnType<typeof paternityCheckAction.checkPaternitySuccess>;
export type ResetResultsAction = ReturnType<typeof paternityCheckAction.resetResults>;

export type PaternityCheckAction =
    | CheckPaternityAction
    | CheckPaternitySuccessAction
    | ResetResultsAction
;
