import {putAll, takeLatestF} from '@fl/cmsch-fe-library';
import * as routerActions from 'redux-first-history';
import {SagaIterator} from 'redux-saga';
import {put, call} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'translations';
import {showBeError} from 'utils/show-be-error';

import {DeleteOrderAction} from '../action';

function* execute({payload: {orderId}}: DeleteOrderAction): SagaIterator {
    const response = yield* call(Api.deleteOrder, {orderId});

    if (response.isSuccess) {
        yield* put(routerActions.push('/orders'));
    } else {
        yield putAll(showBeError(response, t('orders/DetailActions')('deleteOrder')));
    }
}

export function* deleteOrderSaga(): SagaIterator {
    yield takeLatestF('order/DELETE_ORDER', execute);
}
