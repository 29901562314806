import React, {FC, memo} from 'react';

import {FooterButtons} from 'common/buttons';
import {useOurTranslation} from 'translations';
import {useForm} from 'utils/forms';

import {
    BillingBaseExportOptionsFormValues,
    billingBaseExportOptionsFormName,
} from './billing-base-export-options-form-values';
import {validate} from './validations';

const initialValues: BillingBaseExportOptionsFormValues = {
    billingDate: null,
};

interface Props {
    onClose(): void;
    onSubmit(values: BillingBaseExportOptionsFormValues): void;
}

const BillingBaseExportOptionsFormBase: FC<Props> = props => {
    const {
        onClose,
        onSubmit,
    } = props;

    const {t} = useOurTranslation('orders/BillingBaseExportOptionsForm');

    const {Form, Fields, valid, submitting, pristine} = useForm({
        form: billingBaseExportOptionsFormName,
        initialValues,
        destroyOnUnmount: true,
        validate,
        onSubmit,
    });

    return (
        <Form>
            <div className="row">
                <div className="col-12">
                    <Fields.DateInput
                        name="billingDate"
                        label={t('billingDate')}
                    />
                </div>
            </div>
            <FooterButtons
                disabledSubmit={!valid || pristine || submitting}
                submitTitle={t('submit')}
                onCancel={onClose}
            />
        </Form>
    );
};

export const BillingBaseExportOptionsForm = memo(BillingBaseExportOptionsFormBase);
