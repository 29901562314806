import {takeLatestF, putAll} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {optEmptyArray} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {showSuccessSimple} from 'notifications';
import {t} from 'translations';
import {showBeError} from 'utils/show-be-error';

import {RegenerateSnpAction, snpActions} from '../actions';
import {simpleSnpManagementSelector} from '../selector';

const te = t('snpManagement/SnpTable');

function* execute(_action: RegenerateSnpAction): SagaIterator {
    const snpIds = yield* select(simpleSnpManagementSelector.selected);
    const response = yield* call(Api.rerunImportOfAnimals, {snpIds: optEmptyArray(snpIds).orNull()});

    if (!response.isSuccess) {
        yield putAll(showBeError(response, te('tableHeaders.title')));
    } else {
        yield* put(showSuccessSimple({message: te('requestMessages.dataRegenerationStarted')}));
    }
    yield* put(snpActions.selectIds([]));
}

export function* regenerateSaga(): SagaIterator {
    yield takeLatestF('snpManagement/REGENERATE_SNP', execute);
}
