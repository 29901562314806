import React from 'react';
import {Route} from 'react-router-dom';

import {List} from './screens';

export const parentSearchRoutesWithoutFetching: Array<RegExp> = [/^\/parent-search$/];

export const parentSearchRoutes = [
    (
        <Route
            exact
            path="/parent-search"
            component={List}
            key="parent-search"
        />
    ),
];
