// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { AssociationType, AssociationTypeSchema } from 'api/gen/AssociationType';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { DateTime, DateTimeSchema } from 'api/gen/DateTime';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { FileKey, FileKeySchema } from 'api/gen/FileKey';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveInteger, PositiveIntegerSchema } from 'api/gen/PositiveInteger';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveLong, PositiveLongSchema } from 'api/gen/PositiveLong';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { WholeName, WholeNameSchema } from 'api/gen/WholeName';

const requiredPart = t.interface({
  animalsCount: PositiveIntegerSchema,
  associationType: t.union([AssociationTypeSchema, t.null]),
  created: DateTimeSchema,
  createdBy: WholeNameSchema,
  fileKey: t.union([FileKeySchema, t.null]),
  fileSize: t.union([PositiveLongSchema, t.null]),
  id: PositiveLongSchema,
});

export const GenotypeExportSchema = excess(requiredPart);

export interface GenotypeExport extends t.TypeOf<typeof GenotypeExportSchema> {}
