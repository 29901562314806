// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// A name of a user role
// Example: "ROLE_BREEDER"

export const roleNameMinLength = 1;
export const roleNameMaxLength = 255;

export const RoleNameSchema = t.refinement(t.string, n => n.length >= roleNameMinLength && n.length <= roleNameMaxLength, 'RoleName');

export type RoleName = string;
