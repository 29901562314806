// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.75.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.75.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-layout-components-page-heading-___styles__pageHeading___Y5lnz{display:flex;justify-content:center;align-items:center;margin:0 -1.5rem 1.5rem;height:3em;background-color:#719041;opacity:.9}.src-modules-layout-components-page-heading-___styles__pageHeading___Y5lnz h1{color:#fff!important;margin-bottom:0}\n", "",{"version":3,"sources":["webpack://./src/modules/layout/components/page-heading/styles.sass"],"names":[],"mappings":"AAAA,2EAAa,YAAA,CAAa,sBAAA,CAAuB,kBAAA,CAAjD,uBAAA,CAAoG,UAAA,CAAW,wBAAA,CAAyB,UAAA,CAAW,8EAAgB,oBAAA,CAAsB,eAAA","sourcesContent":[".pageHeading{display:flex;justify-content:center;align-items:center;margin:0 -1.5rem 1.5rem -1.5rem;height:3em;background-color:#719041;opacity:.9}.pageHeading h1{color:#fff !important;margin-bottom:0}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageHeading": "src-modules-layout-components-page-heading-___styles__pageHeading___Y5lnz"
};
export default ___CSS_LOADER_EXPORT___;
