// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { NewOrderAnimalInBulk, NewOrderAnimalInBulkSchema } from 'api/gen/NewOrderAnimalInBulk';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { NewOrderUserDetails, NewOrderUserDetailsSchema } from 'api/gen/NewOrderUserDetails';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveLong, PositiveLongSchema } from 'api/gen/PositiveLong';

const requiredPart = t.interface({
  animals: t.array(NewOrderAnimalInBulkSchema),
  orderTypeIds: t.array(PositiveLongSchema),
  sampleTypeId: PositiveLongSchema,
  userDetails: NewOrderUserDetailsSchema,
});

export const NewOrderInBulkSchema = excess(requiredPart);

export interface NewOrderInBulk extends t.TypeOf<typeof NewOrderInBulkSchema> {}
