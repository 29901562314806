import {orderTypeCodeMaxLength} from 'api/gen/OrderTypeCode';
import {orderTypeExtraChargeMaximum} from 'api/gen/OrderTypeExtraCharge';
import {orderTypePriceMaximum} from 'api/gen/OrderTypePrice';
import {t} from 'translations';
import {PaternityValidator} from 'utils/paternity-validator';
import {Errors} from 'utils/validator';

import {OrderTypeFormValues} from './order-type-form-values';

const te = t('dials/orderTypes');

export const validate = (values: OrderTypeFormValues): Errors<OrderTypeFormValues> => {
    const validator = new PaternityValidator(values);

    validator.nonEmpty('value', te('description'));
    validator.nonEmpty('code', te('code'));
    validator.maxStringLength('code', orderTypeCodeMaxLength, te('code'));
    validator.nonEmpty('price', te('price'));
    if (values.price) {
        validator.minNumber('price', te('price'), 0);
        validator.maxNumber('price', te('price'), orderTypePriceMaximum);
    }
    validator.nonEmpty('extraCharge', te('extraCharge'));
    if (values.extraCharge) {
        validator.minNumber('extraCharge', te('extraCharge'), 0);
        validator.maxNumber('extraCharge', te('extraCharge'), orderTypeExtraChargeMaximum);
    }

    return validator.generateErrorsObject();
};
