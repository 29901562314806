import {customerNameMaxLength, customerNameMinLength} from 'api/gen/CustomerName';
import {emailRegexGen} from 'api/gen/Email';
import {firstNameMaxLength, firstNameMinLength} from 'api/gen/FirstName';
import {lastNameMaxLength, lastNameMinLength} from 'api/gen/LastName';
import {phoneMaxLength, phoneMinLength} from 'api/gen/Phone';
import {plemdatEmailMaxLength, plemdatEmailMinLength} from 'api/gen/PlemdatEmail';
import {vatNumberRegexGen} from 'api/gen/VatNumber';
import {t} from 'translations';
import {PaternityValidator} from 'utils/paternity-validator';
import {Errors} from 'utils/validator';

import {validateAddress} from '../AddressFormSection/validations';
import {UserDetailsFormSectionValues} from './user-details-form-section-values';

type FormType = 'new' | 'edit' | 'bulk';
const te = t('orders/UserDetailsForm');

// eslint-disable-next-line max-lines-per-function
export const validateUserDetails = (
    values: UserDetailsFormSectionValues,
    formType: FormType,
    isIdRequired = false,
    isPhoneRequired = true,
): Errors<UserDetailsFormSectionValues> => {
    const validator = new PaternityValidator(values);

    validator.nonEmpty('firstname', te('firstname'));
    validator.minStringLength('firstname', firstNameMinLength, te('firstname'));
    validator.maxStringLength('firstname', firstNameMaxLength, te('firstname'));
    validator.nonEmpty('lastname', te('lastname'));
    validator.minStringLength('lastname', lastNameMinLength, te('lastname'));
    validator.maxStringLength('lastname', lastNameMaxLength, te('lastname'));
    validator.nonEmpty('email', te('email'));
    validator.pattern('email', emailRegexGen(), te('email'));
    validator.minStringLength('email', plemdatEmailMinLength, te('email'));
    validator.maxStringLength('email', plemdatEmailMaxLength, te('email'));
    if (isPhoneRequired) {
        validator.nonEmpty('phone', te('phone'));
        validator.minStringLength('phone', phoneMinLength, te('phone'));
        validator.maxStringLength('phone', phoneMaxLength, te('phone'));
    }
    validator.nonEmpty('customerName', te('customerName'));
    validator.minStringLength('customerName', customerNameMinLength, te('customerName'));
    validator.maxStringLength('customerName', customerNameMaxLength, te('customerName'));
    validator.pattern('vatNumber', vatNumberRegexGen(), te('vatNumber'));
    validator.cin('cin', te('cin'));

    if (formType === 'bulk') {
        validator.cinBulkEdit('cin', 'prefillName', te('cin'), te('prefillName'));
    } else if (formType === 'new') {
        validator.cinUserDetails('cin', 'noCin', te('cin'), te('noCin'));
    }

    if (isIdRequired) {
        validator.nonEmpty('id', te('id'));
    }

    const errors = validator.generateErrorsObject();
    if (values.address) {
        errors.address = validateAddress(values.address);
    }

    if (!values.hasSameAddresses && values.billingAddress) {
        errors.billingAddress = validateAddress(values.billingAddress);
    }

    return errors;
};
