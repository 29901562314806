import {FC, useEffect} from 'react';

import {history} from '../../store';

export const ScrollToTop: FC = () => {
    useEffect(() => {
        const unlisten = history.listen(() => {
            window.scrollTo(0, 0);
        });

        return (): void => {
            unlisten();
        };
    }, []);

    return null;
};
