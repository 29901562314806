import {TableDataParams} from '@fl/cmsch-fe-library';

import {State} from 'app/state';

import {OrderTableType} from '../../components/OrdersTable/order-table-type';

export const getOrderTableParams = (state: State): TableDataParams<OrderTableType> | undefined=>
    state.tables.tableStates.orders?.tableDataParams;

export const getSelectedOrders = (state: State): Array<number> =>
    state.orders.selected;
