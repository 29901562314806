import {putAll, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {put, call} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {appAction} from 'app/model/action';
import {t} from 'translations';
import {showBeError} from 'utils/show-be-error';

import {GetDataForGenotypingRequestAction, orderAction} from '../action';

function* execute({payload: {orderId}}: GetDataForGenotypingRequestAction): SagaIterator {
    yield* put(appAction.addLoadingContent('getDataForGenotypingExport'));
    const response = yield* call(Api.getDataForGenotypingRequest, {orderId});

    if (response.isSuccess) {
        yield* put(orderAction.getDataForGenotypingRequestSuccess(response.data));
    } else {
        yield putAll(showBeError(response, t('orders/sagas')('getDataForGenotypingRequest')));
    }
    yield* put(appAction.removeLoadingContent('getDataForGenotypingExport'));
}

export function* getDataForGenotypingRequestSaga(): SagaIterator {
    yield takeLatestF('order/GET_DATA_FOR_GENOTYPING_REQUEST', execute);
}
