import {downloadFileFromResponse, putAll, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {call, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'translations';
import {showBeError} from 'utils/show-be-error';

import {convertOriginInconsisitenciesTableDataParamsToDto} from '../../utils/conver-table-data-params-to-dto';
import {ExportOriginInconsistenciesToCSV} from '../action';
import {simpleAnimalsSelector} from '../selector';

function* execute(_action: ExportOriginInconsistenciesToCSV): SagaIterator {
    const tableDataParams = opt(yield* select(simpleAnimalsSelector.originInconsistenciesParams))
        .orCrash('table data params not loaded');
    const {filters} = convertOriginInconsisitenciesTableDataParamsToDto(tableDataParams);
    const response = yield* call(Api.getCsvWithOriginInconsistencies, filters);

    if (response.isSuccess) {
        yield* call(downloadFileFromResponse, response);
    } else {
        yield putAll(showBeError(response, t('animals/OriginInconsistenciesTable')('messages.excelError')));
    }
}

export function* exportOriginInconsistenciesToCSVSaga(): SagaIterator {
    yield takeLatestF('animals/EXPORT_ORIGIN_INCONSISTENCIES_TO_CSV', execute);
}
