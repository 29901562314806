import React, {FC, memo} from 'react';

import {PageHeading, Container, MainPanel} from 'layout';
import {useOurTranslation} from 'translations';

import {SnpPage} from '../components/snp-page';

const SnpManagementBase: FC = () => {
    const {t} = useOurTranslation('snpManagement/SnpTable');

    return (
        <Container>
            <MainPanel>
                <div className="row">
                    <div className="col-12">
                        <PageHeading>
                            <h1>{t('tableHeaders.title')}</h1>
                        </PageHeading>
                    </div>
                </div>
                <SnpPage />
            </MainPanel>
        </Container>
    );
};

export const SnpManagement = memo(SnpManagementBase);
