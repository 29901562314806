import {AppInfo} from 'api/gen/AppInfo';

export const otherAction = {
    updateCatalogLists: () => ({
        type: 'other/UPDATE_CATALOG_LISTS',
    }) as const,

    updateCatalogListsSuccess: () => ({
        type: 'other/UPDATE_CATALOG_LISTS_SUCCESS',
    }) as const,

    updateCatalogListsError: () => ({
        type: 'other/UPDATE_CATALOG_LISTS_ERROR',
    }) as const,

    synchronizeAnimals: () => ({
        type: 'other/SYNCHRONIZE_ANIMALS',
    }) as const,

    synchronizeAnimalsSuccess: () => ({
        type: 'other/SYNCHRONIZE_ANIMALS_SUCCESS',
    }) as const,

    synchronizeAnimalsError: () => ({
        type: 'other/SYNCHRONIZE_ANIMALS_ERROR',
    }) as const,

    updatePaternity: () => ({
        type: 'other/UPDATE_PATERNITY',
    }) as const,

    updatePaternitySuccess: () => ({
        type: 'other/UPDATE_PATERNITY_SUCCESS',
    }) as const,

    updatePaternityError: () => ({
        type: 'other/UPDATE_PATERNITY_ERROR',
    }) as const,
    getAppInfo: () => ({
        type: 'other/GET_APP_INFO',
    }) as const,
    getAppInfoSuccess: (appInfo: AppInfo) => ({
        type: 'other/GET_APP_INFO_SUCCESS',
        payload: {appInfo},
    }) as const,
};

export type UpdateCatalogListsAction = ReturnType<typeof otherAction.updateCatalogLists>;
export type UpdateCatalogListsSuccessAction = ReturnType<typeof otherAction.updateCatalogListsSuccess>;
export type UpdateCatalogListsErrorAction = ReturnType<typeof otherAction.updateCatalogListsError>;
export type SynchronizeAnimalsAction = ReturnType<typeof otherAction.synchronizeAnimals>;
export type SynchronizeAnimalsSuccessAction = ReturnType<typeof otherAction.synchronizeAnimalsSuccess>;
export type SynchronizeAnimalsErrorAction = ReturnType<typeof otherAction.synchronizeAnimalsError>;
export type UpdatePaternityAction = ReturnType<typeof otherAction.updatePaternity>;
export type UpdatePaternitySuccessAction = ReturnType<typeof otherAction.updatePaternitySuccess>;
export type UpdatePaternityErrorAction = ReturnType<typeof otherAction.updatePaternityError>;
export type GetAppInfoAction = ReturnType<typeof otherAction.getAppInfo>;
export type GetAppInfoSuccessAction = ReturnType<typeof otherAction.getAppInfoSuccess>;

export type OtherAction =
    | UpdateCatalogListsAction
    | UpdateCatalogListsSuccessAction
    | UpdateCatalogListsErrorAction
    | SynchronizeAnimalsAction
    | SynchronizeAnimalsSuccessAction
    | SynchronizeAnimalsErrorAction
    | UpdatePaternityAction
    | UpdatePaternitySuccessAction
    | UpdatePaternityErrorAction
    | GetAppInfoAction
    | GetAppInfoSuccessAction
;
