import * as O from 'optics-ts';

import {FileImportAction} from './action';
import {FileImportState, initialFileImportState} from './state';

const fileImportStateO = O.optic<FileImportState>();

export const fileImportReducer = (
    state: FileImportState = initialFileImportState,
    action: FileImportAction,
): FileImportState => {
    switch (action.type) {
        case 'fileImport/SET_LOADING':
            return O.set(fileImportStateO.prop('isLoading'))(action.payload.isLoading)(state);
        default:
            return state;
    }
};
