import {FilterDefinition, Options, TableColumn} from '@fl/cmsch-fe-library';

import {getParenthoodOptions} from 'animals/utils/parenthood-options';
import {TFunction} from 'translations';
import {getSexOptions} from 'utils/get-sex-options';

import {formatOrderTypesField} from './format-order-types-field';
import {OrderTableType} from './order-table-type';

interface CreateColumnsOptions {
    orderTypes: Options<number>;
    sampleTypes: Options<number>;
    isSampleIdLink: boolean;
    isRoleBreeder: boolean;
    isRoleLaborer: boolean;
    isRoleAssociation: boolean;
    areGenotypeExportColumnsVisible: boolean;
    t: TFunction<'orders/OrdersTable'>;
    tCommon: TFunction<'common'>;
    getAnimalUrl(value: number, row: OrderTableType): string;
}

// eslint-disable-next-line max-lines-per-function
const getDataFilters = (
    isRoleBreeder: boolean,
    isRoleLaborer: boolean,
    isRoleAssociation: boolean,
    t: TFunction<'orders/OrdersTable'>,
): Array<FilterDefinition<OrderTableType>> => {
    const filters: Array<FilterDefinition<OrderTableType>> = [];

    if (!isRoleBreeder) {
        filters.push({
            type: 'boolean',
            field: 'isBilled',
            label: t('stateFullyBilled'),
        });
    }
    if (isRoleLaborer || isRoleAssociation) {
        filters.push(
            {
                type: 'select',
                field: 'paternity',
                label: t('columnHeaders.paternity'),
                options: getParenthoodOptions(),
            },
            {
                type: 'select',
                field: 'maternity',
                label: t('columnHeaders.maternity'),
                options: getParenthoodOptions(),
            },
        );
    }

    return filters;
};

const createGenotypeExportColumns = (t: TFunction<'orders/OrdersTable'>): Array<TableColumn<OrderTableType>> => [
    {
        field: 'genotypeExportGeneratedDate',
        type: 'date',
        dateFormat: 'date',
        column: t('columnHeaders.genotypeExportGeneratedDate'),
        tooltip: t('columnHeadersTooltip.genotypeExportGeneratedDate'),
        width: 90,
        verticalName: true,
        filterTypes: ['null'],
    },
];

// eslint-disable-next-line max-lines-per-function
export const createColumns = ({
    orderTypes,
    sampleTypes,
    isSampleIdLink,
    isRoleBreeder,
    isRoleLaborer,
    isRoleAssociation,
    areGenotypeExportColumnsVisible,
    t,
    tCommon,
    getAnimalUrl,
}: CreateColumnsOptions): Array<TableColumn<OrderTableType>> => [
    {
        field: 'data',
        type: 'reactNode',
        width: 144,
        filters: getDataFilters(isRoleBreeder, isRoleLaborer, isRoleAssociation, t),
        sortable: false,
    },
    {
        field: 'earTag',
        column: t('columnHeaders.sampleId'),
        tooltip: t('columnHeadersTooltip.sampleId'),
        type: isSampleIdLink ? 'hyperlink' : 'string',
        valueToUrl: getAnimalUrl,
        filterTypes: ['betweenEarTags'],
        fulltextFilterEnabled: true,
        width: 130,
    },
    {
        field: 'createdTs',
        column: t('columnHeaders.createdTs'),
        tooltip: t('columnHeadersTooltip.createdTs'),
        type: 'date',
        dateFormat: 'datetime',
        dontShowTime: true,
        verticalName: true,
        filterTypes: ['null'],
        width: 90,
    },
    {
        field: 'protocolGeneratedDate',
        column: t('columnHeaders.protocolGeneratedDate'),
        tooltip: t('columnHeadersTooltip.protocolGeneratedDate'),
        type: 'date',
        dateFormat: 'date',
        verticalName: true,
        width: 90,
        filterTypes: ['null'],
    },
    {
        field: 'sampleTypeId',
        column: t('columnHeaders.sampleType'),
        tooltip: t('columnHeadersTooltip.sampleType'),
        type: 'option',
        options: sampleTypes,
        filterTypes: ['multiSelectNumber'],
        width: 130,
    },
    {
        field: 'isolationDate',
        column: t('columnHeaders.isolationDate'),
        tooltip: t('columnHeadersTooltip.isolationDate'),
        type: 'date',
        dateFormat: 'date',
        verticalName: true,
        width: 90,
        filterTypes: ['null'],
    },
    {
        field: 'isolated',
        column: t('columnHeaders.isolated'),
        tooltip: t('columnHeadersTooltip.isolated'),
        type: 'optionalBoolean',
        verticalName: true,
        width: 58,
        filterTypes: ['null'],
    },
    {
        field: 'laboratoryNumber',
        column: t('columnHeaders.laboratoryNumber'),
        tooltip: t('columnHeadersTooltip.laboratoryNumber'),
        type: 'string',
        verticalName: true,
        fulltextFilterEnabled: true,
        width: 92,
        filterTypes: ['null'],
    },
    {
        field: 'breed',
        column: t('columnHeaders.breed'),
        tooltip: t('columnHeadersTooltip.breed'),
        type: 'string',
        verticalName: true,
        fulltextFilterEnabled: true,
        width: 68,
        filterTypes: ['null'],
    },
    {
        field: 'sex',
        column: t('columnHeaders.sex'),
        tooltip: t('columnHeadersTooltip.sex'),
        type: 'option',
        options: getSexOptions(),
        verticalName: true,
        width: 58,
        filterTypes: ['null'],
    },
    {
        field: 'chipId',
        column: t('columnHeaders.chipId'),
        tooltip: t('columnHeadersTooltip.chipId'),
        type: 'string',
        fulltextFilterEnabled: true,
        width: 122,
        filterTypes: ['null'],
    },
    ...areGenotypeExportColumnsVisible ? createGenotypeExportColumns(t) : [],
    {
        field: 'customerName',
        column: t('columnHeaders.customerName'),
        tooltip: t('columnHeadersTooltip.customerName'),
        type: 'string',
        fulltextFilterEnabled: true,
        width: 200,
    },
    {
        field: 'email',
        column: tCommon('email'),
        tooltip: t('columnHeadersTooltip.email'),
        type: 'string',
        width: 200,
        visible: false,
    },
    {
        field: 'orderTypeIds',
        column: t('columnHeaders.orderTypes'),
        tooltip: t('columnHeadersTooltip.orderTypes'),
        type: 'array',
        filterTypes: ['multiSelectNumber'],
        options: orderTypes,
        verticalName: true,
        width: 232,
        formatValue: formatOrderTypesField,
    },
    {
        field: 'sampleDeliveredDate',
        column: t('columnHeaders.sampleDeliveredDate'),
        tooltip: t('columnHeadersTooltip.sampleDeliveredDate'),
        type: 'date',
        dateFormat: 'date',
        verticalName: true,
        width: 90,
        filterTypes: ['null'],
    },
    {
        field: 'hasCatalogList',
        column: t('columnHeaders.catalogList'),
        tooltip: t('columnHeadersTooltip.catalogList'),
        type: 'boolean',
        verticalName: true,
        width: 58,
    },
];
