// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { Haplotype, HaplotypeSchema } from 'api/gen/Haplotype';

const requiredPart = t.interface({
  first: HaplotypeSchema,
  second: HaplotypeSchema,
});

export const HaplotypeCoupleSchema = excess(requiredPart);

export interface HaplotypeCouple extends t.TypeOf<typeof HaplotypeCoupleSchema> {}
