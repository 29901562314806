// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// A phone number
// Example: "+ 420 754 314 369"

export const phoneMinLength = 1;
export const phoneMaxLength = 255;

export const PhoneSchema = t.refinement(t.string, n => n.length >= phoneMinLength && n.length <= phoneMaxLength, 'Phone');

export type Phone = string;
