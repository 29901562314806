// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { Point, PointSchema } from 'api/gen/Point';

const requiredPart = t.interface({
  points: t.array(PointSchema),
});

export const GraphDataSchema = excess(requiredPart);

export interface GraphData extends t.TypeOf<typeof GraphDataSchema> {}
