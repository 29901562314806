import {debounce} from 'lodash/fp';
import React, {FC, memo, useCallback, useMemo} from 'react';
import {Opt} from 'ts-opt';

import {CodeTableItem} from 'api/gen/CodeTableItem';
import {CodeTableValidation} from 'api/gen/CodeTableValidation';
import {Modal} from 'common/layout';
import {useOurTranslation} from 'translations';

import {debounceTime} from '../../../../constants/general';
import {SampleTypeForm} from '../SampleTypeForm';

interface Props {
    visible: boolean;
    sampleTypeIdBeingEdited: Opt<number>;
    onSubmitEdit(sampleType: CodeTableItem): void;
    onSubmitCreate(sampleType: CodeTableItem): void;
    onCancel(): void;
    validateSampleType(data: CodeTableValidation): void;
}

const SampleTypesModalBase: FC<Props> = props => {
    const {
        visible,
        sampleTypeIdBeingEdited,
        onSubmitEdit,
        onSubmitCreate,
        onCancel,
        validateSampleType,
    } = props;

    const {t} = useOurTranslation('dials/sampleTypes');

    const validateSampleTypeDebounced = useMemo(() =>
        debounce(debounceTime)(validateSampleType)
    , [validateSampleType]);

    const validateValue = useCallback((value: Opt<string>) => {
        const idToExclude = sampleTypeIdBeingEdited.orNull();
        value.onSome(x => validateSampleTypeDebounced({value: x, idToExclude}));
    }, [sampleTypeIdBeingEdited, validateSampleTypeDebounced]);

    return (
        <Modal
            title={t(sampleTypeIdBeingEdited.isEmpty ? 'createTitle' : 'editTitle')}
            visible={visible}
            onCancel={onCancel}
            footer={null}
        >
            <SampleTypeForm
                onSubmit={sampleTypeIdBeingEdited.isEmpty ? onSubmitCreate : onSubmitEdit}
                isEdit={sampleTypeIdBeingEdited.nonEmpty}
                onCancel={onCancel}
                onChangeValue={validateValue}
            />
        </Modal>
    );
};

export const SampleTypesModal = memo(SampleTypesModalBase);
