// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';
import {StringPatternSchema} from '@fl/cmsch-fe-library';


// A barcode
// Example: "31001706105182"

export const barcodeRegexGen = (): RegExp => new RegExp("^[A-Z0-9]+$");

export const barcodeMinLength = 1;
export const barcodeMaxLength = 255;

export const BarcodeSchema = t.refinement(StringPatternSchema<Barcode>(barcodeRegexGen()), n => n.length >= barcodeMinLength && n.length <= barcodeMaxLength, 'Barcode');

export type Barcode = string;
