import {debounceF} from '@fl/cmsch-fe-library';
import {isEmpty} from 'lodash/fp';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {put, select} from 'typed-redux-saga';

import {transformFormToAnimalDetails} from 'orders/utils/transform-animal-details';

import {orderAction} from '../action';
import {simpleFormSelector} from '../selector';

const sumbitDebounceTime = 200;

function* execute(): SagaIterator {
    const barcode = yield* select(simpleFormSelector.orderBarcode);
    const addedAnimal = yield* select(simpleFormSelector.newOrderAnimal);
    const transformedAnimal = transformFormToAnimalDetails(opt(addedAnimal), opt(barcode));

    if (isEmpty(addedAnimal?.sampleId)) return;
    yield* put(orderAction.addAnimalToBulkOrderAnimalsDetails(transformedAnimal));
    yield* put(orderAction.resetIsEditing());
}

export function* addAnimalsToBulkOrderSaga(): SagaIterator {
    yield debounceF(sumbitDebounceTime, 'order/ADD_ANIMAL_TO_BULK_ORDER', execute);
}
