import {Nullable} from '@fl/cmsch-fe-library';
import React, {FC, memo, useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {Opt} from 'ts-opt';

import {OrderCustomerDetails} from 'api/gen/OrderCustomerDetails';
import {User} from 'api/gen/User';
import {FooterButtons} from 'common/buttons';
import {useOurTranslation} from 'translations';
import {useForm} from 'utils/forms';

import {simpleOrdersSelector} from '../../model';
import {UserDetailsFormSection, UserDetailsFormSectionOuterProps} from '../UserDetailsFormSection';
import {userDetailsInitialValues} from '../UserDetailsFormSection/user-details-form-section-values';
import {EditUserDetailsFormValues, editUserDetailsFormName} from './edit-user-details-form-values';
import {validate} from './validations';

const initialValues: EditUserDetailsFormValues = {
    userDetails: userDetailsInitialValues,
};

interface Props {
    customers: Array<OrderCustomerDetails>;
    hasSameAddresses: Nullable<boolean>;
    showCreatedBy: boolean;
    breeders: Nullable<Array<User>>;
    isIdRequired: boolean;
    isPhoneRequired: boolean;
    onCancel(): void;
    onPrefillFromExisting(value: OrderCustomerDetails): void;
    onGetUserLastOrder(cin: string): void;
    onSubmit(values: EditUserDetailsFormValues): void;
}

const EditUserDetailsFormBase: FC<Props> = props => {
    const {
        hasSameAddresses,
        customers,
        showCreatedBy,
        breeders,
        isIdRequired,
        isPhoneRequired,
        onPrefillFromExisting,
        onGetUserLastOrder,
        onSubmit,
        onCancel,
    } = props;

    const isCinFetching = useSelector(simpleOrdersSelector.isCinFetching);

    const validateFn = useMemo(() => validate({isIdRequired, isPhoneRequired}), [isIdRequired, isPhoneRequired]);
    const {Form, Fields, pristine, submitting, renderErrors} = useForm({
        form: editUserDetailsFormName,
        initialValues,
        validate: validateFn,
        onSubmit,
    });

    const prefillFromExisting = useCallback((value: Opt<number>) => {
        onPrefillFromExisting(customers[value.orCrash('prefilled values not available')]);
    }, [customers, onPrefillFromExisting]);

    const onCinClick = useCallback((value: Opt<string>) => {
        value.onSome(onGetUserLastOrder);
    }, [onGetUserLastOrder]);

    const UserDetailSectionField = Fields.genSection<'userDetails', UserDetailsFormSectionOuterProps>();
    const {t} = useOurTranslation('orders/UserDetailsForm');
    const userDetailsProps = {
        customers,
        showCreatedBy,
        breeders,
        userDetailsFromCIN: null,
        noCin: false,
        inWizard: false,
        showAllInputs: true,
        hasSameAddresses,
        isIdRequired,
        isPhoneRequired,
        onCinClick,
        prefillFromExisting,
        caption: t('stepCaption'),
    };

    return (
        <Form>
            {renderErrors()}

            <UserDetailSectionField
                name="userDetails"
                Component={UserDetailsFormSection}
                props={userDetailsProps}
            />

            <FooterButtons
                editing
                disabledSubmit={pristine || submitting || isCinFetching}
                disabledCancel={pristine || submitting}
                onCancel={onCancel}
            />
        </Form>
    );
};

export const EditUserDetailsForm = memo(EditUserDetailsFormBase);
