import {putAll, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'translations';
import {showBeError} from 'utils/show-be-error';

import {CancelSubsidiesByIdsAction, orderAction} from '../action';
import {getSelectedOrders} from './selectors';

function* execute(_action: CancelSubsidiesByIdsAction): SagaIterator {
    const orderIds = yield* select(getSelectedOrders);
    yield* put(orderAction.setListActionActive('cancelSubsidy', true));

    const response = yield* call(Api.cancelSubsidySubset, {orderIds});

    if (response.isSuccess) {
        yield* put(orderAction.getOrders(opt({action: 'refresh'})));
    } else {
        yield putAll(showBeError(response, t('orders/ListActions')('cancelSubsidy')));
    }
    yield* put(orderAction.setListActionActive('cancelSubsidy', false));
}

export function* cancelSubsidiesByIdsSaga(): SagaIterator {
    yield takeLatestF('order/CANCEL_SUBSIDIES_BY_IDS', execute);
}
