import {takeLatestF} from '@fl/cmsch-fe-library';
import {toPairs} from 'lodash/fp';
import {SagaIterator} from 'redux-saga';
import {isEmpty, opt} from 'ts-opt';
import {put, select} from 'typed-redux-saga';

import {AnimalDetailsFormSectionValues} from 'orders/components/AnimalDetailsForm/animal-details-form-section-values';
import {transformFormToAnimalDetails} from 'orders/utils/transform-animal-details';
import {formHelpers} from 'utils/forms';

import {orderAction} from '../action';
import {simpleFormSelector} from '../selector';

type ErrorPair = [string, string | undefined];

function* execute(): SagaIterator {
    const syncErrors = yield* select(simpleFormSelector.newOrderSyncErrors);
    const asyncErrors = yield* select(simpleFormSelector.newOrderAsyncErrors);
    // TODO: make typed version to fl-utils, https://redmine.favorlogic.com/issues/16308
    const syncErrorsKeys = Object.keys(
        syncErrors.prop('animalDetails').orElse({}),
    ) as Array<keyof AnimalDetailsFormSectionValues>;

    // TODO: improve types (toPairs is from lodash, cast at the end is not safe),
    // https://redmine.favorlogic.com/issues/16308
    const asyncErrorsKeys = asyncErrors.prop('animalDetails')
        .map(toPairs)
        .filterIn((x: ErrorPair) => x[1] !== undefined)
        .mapIn(x => x[0])
        .orElse([]) as Array<keyof AnimalDetailsFormSectionValues>;

    const errorsKeys = [...syncErrorsKeys, ...asyncErrorsKeys];
    if (!isEmpty(errorsKeys)) {
        yield* put(formHelpers.setTouchedFieldsInSection('newOrder', 'animalDetails', errorsKeys));
    } else {
        yield* put(orderAction.setModalVisibility('addAnimal', false));
        yield* put(orderAction.addAnimalToBulkOrder());
    }

    const barcode = yield* select(simpleFormSelector.orderBarcode);
    const addedAnimal = yield* select(simpleFormSelector.newOrderAnimal);
    const transformedAnimal = transformFormToAnimalDetails(opt(addedAnimal), opt(barcode));

    if (isEmpty(addedAnimal?.sampleId)) return;
    yield* put(orderAction.addAnimalToBulkOrderAnimalsDetails(transformedAnimal));
    yield* put(orderAction.resetIsEditing());
}

export function* addAnimalsToBulkOrderWithValidationSaga(): SagaIterator {
    yield takeLatestF('order/ADD_ANIMAL_TO_BULK_ORDER_WITH_VALIDATION', execute);
}
