import {Nullable, Table, TableColumn} from '@fl/cmsch-fe-library';
import {SizeType} from 'antd/lib/config-provider/SizeContext';
import React, {FC, ReactNode, useMemo} from 'react';
import {opt} from 'ts-opt';

import {NullableAllelesCouple} from 'api/gen/NullableAllelesCouple';
import {Qtl} from 'api/gen/Qtl';
import {useOurTranslation} from 'translations';

interface QtlRow {
    id: number;
    snpName: string;
    type: string;
    result: ReactNode;
}

interface Props {
    qtls: Array<Qtl>;
    size?: SizeType;
    noScrollX?: boolean;
    resetAnimalQtls(): void;
}

const snpWidth = 100;
const snpWidthDefault = 92;

const getFirstChar = (x: Nullable<string>): string => opt(x).headIn().orElse('-');
const getFirstCharsOfCouple = (alleleCouple: NullableAllelesCouple): string =>
    `${getFirstChar(alleleCouple.allele1Top)}/${getFirstChar(alleleCouple.allele2Top)}`;
export const getTooltip = (allelesCouples: Array<NullableAllelesCouple>): string =>
    allelesCouples.map(getFirstCharsOfCouple).join(', ');

export const QtlsTable: FC<Props> = props => {
    const {qtls, size, noScrollX, resetAnimalQtls} = props;
    const {t} = useOurTranslation('animals/QtlsTable');

    const columns = useMemo((): Array<TableColumn<QtlRow>> => [
        {
            field: 'snpName',
            type: 'reactNode',
            column: t('columnHeaders.snpName'),
            width: size === 'small' ? snpWidth : snpWidthDefault,
        },
        {
            field: 'type',
            type: 'reactNode',
            column: t('columnHeaders.type'),
            width: 92,
        },
        {
            field: 'result',
            type: 'reactNode',
            column: t('columnHeaders.result'),
            tooltip: t('columnHeadersTooltip.result'),
            tooltipIcon: true,
            width: 92,
        },
    ], [size, t]);

    const rows = useMemo((): Array<QtlRow> =>
        qtls.map((qtl, index) => ({
            id: index,
            snpName: qtl.snpName,
            type: t(`typeOptions.${qtl.type}`),
            result: <span title={getTooltip(qtl.allelesCouples || [])}>
                {t(`resultOptions.${qtl.result}`)}
            </span>,
        })), [qtls, t]);

    return (
        <Table
            columns={columns}
            rows={rows}
            tableId="qtlsTable"
            size={size || 'middle'}
            scroll={noScrollX ? undefined : {x: true}}
            onUnmount={resetAnimalQtls}
        />
    );
};
