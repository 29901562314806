/* eslint-disable @typescript-eslint/naming-convention,no-underscore-dangle */
type Environment = 'development' | 'production';

declare const __API__: string;
declare const __ENV__: Environment;
declare const __DISABLE_IMMUTABILITY_CHECK__: boolean;
declare const __DISABLE_SENTRY__: boolean;
declare const __VERSION__: string;
declare const __FE_LIBRARY_VERSION__: string;

export class Config {
    public static readonly apiUrl: string = __API__;

    public static readonly isDevelopment: boolean = __ENV__ === 'development';

    public static readonly environment: Environment = __ENV__;

    public static readonly disableImmutabilityCheck: boolean = __DISABLE_IMMUTABILITY_CHECK__;

    public static readonly disableSentry: boolean = __DISABLE_SENTRY__;

    public static readonly version: string = __VERSION__;

    public static readonly feLibraryVersion: string = __FE_LIBRARY_VERSION__;
}
