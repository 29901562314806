// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveLong, PositiveLongSchema } from 'api/gen/PositiveLong';

const requiredPart = t.interface({
  id: PositiveLongSchema,
  value: t.string,
});

export const CodeTableItemSchema = excess(requiredPart);

export interface CodeTableItem extends t.TypeOf<typeof CodeTableItemSchema> {}
