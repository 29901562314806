import {Order} from 'api/gen/Order';
import {OrderDetails} from 'api/gen/OrderDetails';

// specs:
// za "readyToBilling" ji muzes povazovat pokud v objednavce je alespon 1 OT,
// ktery - ma readyToBilling = true a soucasne jeste nebyl vyfakturovany

// beware - readyForBilling are also marked orders which has been already billed!
export const isReadyForBillingOrder = (order: Order): boolean =>
    order.orderTypes.some(x => x.readyForBilling && !x.exportDate);

export const isReadyForBillingOrderDetails = (order: OrderDetails): boolean =>
    order.orderTypes?.some(x => x.readyForBilling && !x.exportDateTime) || false;
