// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// Example: FRIESIAN_HORNLESSNESS

export const QtlTypeSchema = t.keyof({
  FRIESIAN_HORNLESSNESS: null,
  CELTIC_HORNLESSNESS: null,
  BLAD: null,
  DWARFISM_HOLSTEIN: null,
  DWARFISM_CHAROL: null,
  RED_FACTOR: null,
  KAPPA_CASEIN: null,
  BETA_CASEIN: null,
  DUMPS: null,
  MULEFOOT: null,
  CARDIOMYOPATHY_HOLSTEIN_RED: null,
  HH1: null,
  HH3: null,
  HH4: null,
  HH5: null,
  HH6: null,
  HH7: null,
  HH12: null,
  MANNOSIDOSIS: null,
  MOTTLING_MICROPHTHALMIA: null,
  ATAXIA: null,
  BD1: null,
  M_C313Y: null,
  M_E226X: null,
  M_E291X: null,
  M_MT419: null,
  M_Q204: null,
  M_F94L: null,
  M_L64B: null,
  M_S105C: null,
  M_NT821: null,
  NH2: null,
  RETINITIS_PIGMENTOSA: null,
  CHEDIAK_HIGASHI_SYNDROME: null,
  ICHTYOSIS_CONGENITA: null,
  JH1: null,
  MH2: null,
  SHORT_TAIL_SPERM: null,
  SWISS_BROWN_ALBINISM: null,
  DILUTION_GENE_LIGHTER: null,
  DILUTION_GENE_VERY_LIGHT: null,
  EHLERS_DANLOS_SYNDROME: null,
  CHOLESTEROL_DEFICIENCY: null,
  FACTOR_X_DEFICIENCY: null,
});

export type QtlType = t.TypeOf<typeof QtlTypeSchema>;
