// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// Example: HUNGARIAN

export const OrderImportTypeSchema = t.keyof({
  HUNGARIAN: null,
  PLEMDAT: null,
  CZECH: null,
});

export type OrderImportType = t.TypeOf<typeof OrderImportTypeSchema>;
