import {Nullable} from '@fl/cmsch-fe-library';
import {isNull} from 'lodash/fp';
import * as O from 'optics-ts';
import React, {FC, useCallback, useEffect} from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps, withRouter} from 'react-router';
import {Dispatch} from 'redux';
import * as routerActions from 'redux-first-history';
import {Opt, pipe} from 'ts-opt';

import {animalsAction} from 'animals';
import {CodeTableItem} from 'api/gen/CodeTableItem';
import {OrderDetails} from 'api/gen/OrderDetails';
import {OrderDetailsForProtocol} from 'api/gen/OrderDetailsForProtocol';
import {Qtl} from 'api/gen/Qtl';
import {SampleIdTrinity} from 'api/gen/SampleIdTrinity';
import {VerificationOutcome} from 'api/gen/VerificationOutcome';
import {Action} from 'app/actions';
import {State} from 'app/state';
import {dialsAction} from 'dials';
import {Container, MainPanel, PageHeading} from 'layout';
import {useOurTranslation} from 'translations';
import {useUser} from 'user';
import {codeTableItemsToOptions} from 'utils/code-table-items-to-options';
import {formHelpers} from 'utils/forms';

import {DetailTable} from '../components/DetailTable';
import {EditUserAndOrderView} from '../components/EditUserAndOrderView';
import {GenerateProtocolActions} from '../components/GenerateProtocolActions';
import {GenerateProtocolForm} from '../components/GenerateProtocolForm';
import {GenerateProtocolFormValues} from '../components/GenerateProtocolForm/generate-protocol-form-values';
import {orderAction} from '../model';

const verificationOutcomeTypeIdO = O.optic<OrderDetailsForProtocol>().prop('verificationOutcomeTypeId');

interface StateProps {
    order: Opt<OrderDetails>;
    animalQtls: Nullable<Array<Qtl>>;
    protocolData: Opt<OrderDetailsForProtocol>;
    sampleTypes: Opt<Array<CodeTableItem>>;
    verificationOutcomeTypes: Opt<Array<VerificationOutcome>>;
    testResultTypes: Opt<Array<CodeTableItem>>;
    fathersVerificationOutcome: Opt<number>;
    mothersVerificationOutcome: Opt<number>;
    parentsVerificationOutcome: Opt<number>;
    parentsVerificationError: Opt<string>;
    generateProtocolFormValues: Opt<GenerateProtocolFormValues>;
}

interface DispatchProps {
    getOrder(orderId: number): void;
    goBack(): void;
    getAnimalQtls(animalId: number): void;
    getVerificationOutcomes(): void;
    getTestResults(): void;
    generateProtocol(orderId: number, data: GenerateProtocolFormValues): void;
    verifyPaternity(data: SampleIdTrinity): void;
    refreshMother(sampleId: string): void;
    refreshFather(sampleId: Nullable<string>, registry: Nullable<string>): void;
    resetValidationOutcomes(): void;
    initializeGenerateProtocolFormValues(protocolData: OrderDetailsForProtocol): void;
    resetGenerateProtocolForm(): void;
    resetAnimalQtls(): void;
}

interface Match {
    id: string;
}

type Props = StateProps & DispatchProps & RouteComponentProps<Match>;

const GenerateProtocolBase: FC<Props> = props => {
    const {
        order,
        match: {params: {id}},
        animalQtls,
        protocolData,
        sampleTypes,
        verificationOutcomeTypes,
        testResultTypes,
        fathersVerificationOutcome,
        mothersVerificationOutcome,
        parentsVerificationOutcome,
        parentsVerificationError,
        generateProtocolFormValues,
        getOrder,
        goBack,
        getAnimalQtls,
        getVerificationOutcomes,
        getTestResults,
        generateProtocol,
        verifyPaternity,
        refreshMother,
        refreshFather,
        resetValidationOutcomes,
        initializeGenerateProtocolFormValues,
        resetGenerateProtocolForm,
        resetAnimalQtls,
    } = props;

    const {isRoleBreeder, isRoleBreedingUnion, isRoleLaborer} = useUser();
    const {t} = useOurTranslation('orders/screenDetail');
    const orderId = Number(id);

    useEffect(() => {
        getOrder(orderId);
    }, [getOrder, orderId]);

    useEffect(() => {
        order.filter(_ => isRoleLaborer || isRoleBreedingUnion).prop('animalId').onSome(getAnimalQtls);
    }, [getAnimalQtls, isRoleBreedingUnion, isRoleLaborer, order]);

    useEffect(() => {
        if (isRoleLaborer) getVerificationOutcomes();
    }, [getVerificationOutcomes, isRoleLaborer]);

    useEffect(() => {
        if (isRoleLaborer) getTestResults();
    }, [getTestResults, isRoleLaborer]);

    useEffect(() => {
        protocolData.onSome(initializeGenerateProtocolFormValues);
    }, [initializeGenerateProtocolFormValues, protocolData]);

    const onGenerateProtocolSubmit = useCallback((values: GenerateProtocolFormValues) => {
        generateProtocol(orderId, values);
    }, [generateProtocol, orderId]);

    const dataForGenerateProtocolLoaded =
        protocolData.zip4(verificationOutcomeTypes, testResultTypes, sampleTypes).nonEmpty;
    const sampleId = generateProtocolFormValues.prop('sampleId').orNull();
    const fathersSampleId = generateProtocolFormValues.prop('fathersSampleId').orNull();
    const mothersSampleId = generateProtocolFormValues.prop('mothersSampleId').orNull();
    const fathersLaboratoryNumber = generateProtocolFormValues.prop('fathersLaboratoryNumber').orElse('-');
    const mothersLaboratoryNumber = generateProtocolFormValues.prop('mothersLaboratoryNumber').orElse('-');

    return (
        <Container>
            {order.map(x => (
                <MainPanel key={x.id}>
                    <div className="row">
                        <div className="col-12">
                            <PageHeading>
                                <h1>{t('title')} {x.sampleId}</h1>
                            </PageHeading>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-lg-5 col-xl-4 order-lg-2">
                            <GenerateProtocolActions
                                onGoBackClick={goBack}
                            />
                            {(isRoleLaborer || isRoleBreedingUnion) && (
                                <DetailTable
                                    animalQtls={animalQtls || []}
                                    resetAnimalQtls={resetAnimalQtls}
                                />
                            )}
                        </div>
                        <div className="col-12 col-lg-7 col-xl-8 order-lg-1">
                            <div>
                                {!isRoleBreeder && !isRoleBreedingUnion && dataForGenerateProtocolLoaded && (
                                    <GenerateProtocolForm
                                        fathersVerificationOutcome={fathersVerificationOutcome}
                                        mothersVerificationOutcome={mothersVerificationOutcome}
                                        parentsVerificationOutcome={parentsVerificationOutcome}
                                        parentsVerificationError={parentsVerificationError}
                                        laboratoryNumber={x.laboratoryNumber ?? null}
                                        sampleId={sampleId}
                                        fathersSampleId={fathersSampleId}
                                        mothersSampleId={mothersSampleId}
                                        fathersLaboratoryNumber={fathersLaboratoryNumber}
                                        mothersLaboratoryNumber={mothersLaboratoryNumber}
                                        onSubmit={onGenerateProtocolSubmit}
                                        onCancel={resetGenerateProtocolForm}
                                        onVerifyPaternity={verifyPaternity}
                                        sampleTypes={codeTableItemsToOptions(sampleTypes)}
                                        onRefreshMother={refreshMother}
                                        onRefreshFather={refreshFather}
                                        verificationOutcomeTypes={codeTableItemsToOptions(verificationOutcomeTypes)}
                                        testResultTypes={codeTableItemsToOptions(testResultTypes)}
                                        onResetValidationOutcomes={resetValidationOutcomes}
                                    />
                                )}
                            </div>
                            <EditUserAndOrderView
                                order={x}
                                identifier="editOrder"
                                hideDataDetails
                            />
                        </div>
                    </div>
                </MainPanel>
            )).orNull()}
        </Container>
    );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    getOrder: (orderId: number): Action => dispatch(orderAction.getOrder(orderId)),
    goBack: (): void => void dispatch(routerActions.goBack()),
    getAnimalQtls: (animalId: number): Action => dispatch(animalsAction.getAnimalQtls(animalId)),
    getVerificationOutcomes: (): Action => dispatch(dialsAction.getVerificationOutcomes()),
    getTestResults: (): Action => dispatch(dialsAction.getTestResults()),
    generateProtocol: (orderId: number, data: GenerateProtocolFormValues): Action =>
        dispatch(orderAction.generateProtocol(orderId, data)),
    verifyPaternity: (data: SampleIdTrinity): Action => dispatch(orderAction.verifyPaternity(data)),
    refreshMother: (sampleId: string): Action => dispatch(orderAction.refreshMother(sampleId)),
    refreshFather: (sampleId: Nullable<string>, registry: Nullable<string>): Action =>
        dispatch(orderAction.refreshFather(sampleId, registry)),
    resetValidationOutcomes: (): Action => dispatch(orderAction.resetValidationOutcomes()),
    initializeGenerateProtocolFormValues: (protocolData: OrderDetailsForProtocol): Action =>
        dispatch(formHelpers.initialize(
            'generateProtocol',
            O.modify(verificationOutcomeTypeIdO)(x => isNull(x) ? -1 : x)(protocolData),
        )),
    resetGenerateProtocolForm: (): Action => dispatch(formHelpers.reset('generateProtocol')),
    resetAnimalQtls: (): void => void dispatch(animalsAction.resetAnimalQtls()),
});

const mapStateToProps = (state: State): StateProps => ({
    order: state.orders.current,
    animalQtls: state.animals.animalQtls,
    protocolData: state.orders.protocolData,
    sampleTypes: state.dials.sampleTypes,
    verificationOutcomeTypes: state.dials.verificationOutcomes,
    testResultTypes: state.dials.testResults,
    fathersVerificationOutcome: state.orders.fathersVerificationOutcome,
    mothersVerificationOutcome: state.orders.mothersVerificationOutcome,
    parentsVerificationOutcome: state.orders.parentsVerificationOutcome,
    parentsVerificationError: state.orders.parentsVerificationError,
    generateProtocolFormValues: formHelpers.formValues('generateProtocol')(state),
});

export const GenerateProtocol = pipe(
    GenerateProtocolBase,
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
);
