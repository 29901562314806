// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.75.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.75.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-animals-components-MaternityDot-___styles__stateIcon___R9tm0{margin-left:5px;cursor:default}.src-modules-animals-components-MaternityDot-___styles__stateIcon___R9tm0 svg g path{stroke:#000000b0!important}.src-modules-animals-components-MaternityDot-___styles__maternityPlain___xV8WA{position:relative;top:-2px}.src-modules-animals-components-MaternityDot-___styles__maternityNone___aH8G4{visibility:hidden}.src-modules-animals-components-MaternityDot-___styles__maternityMissing___VH4vY{fill:gray!important}.src-modules-animals-components-MaternityDot-___styles__maternityMatches___Efwf_ circle{fill:#00bd00!important}.src-modules-animals-components-MaternityDot-___styles__maternityMismatches___gTCf1 circle{fill:#ff2727!important}\n", "",{"version":3,"sources":["webpack://./src/modules/animals/components/MaternityDot/styles.sass"],"names":[],"mappings":"AAAA,0EAAW,eAAA,CAAgB,cAAA,CAAe,qFAAsB,0BAAA,CAA0C,+EAAgB,iBAAA,CAAkB,QAAA,CAAS,8EAAe,iBAAA,CAAkB,iFAAkB,mBAAA,CAAqB,wFAAyB,sBAAA,CAAwB,2FAA4B,sBAAA","sourcesContent":[".stateIcon{margin-left:5px;cursor:default}.stateIcon svg g path{stroke:rgba(0,0,0,.6901960784) !important}.maternityPlain{position:relative;top:-2px}.maternityNone{visibility:hidden}.maternityMissing{fill:gray !important}.maternityMatches circle{fill:#00bd00 !important}.maternityMismatches circle{fill:#ff2727 !important}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stateIcon": "src-modules-animals-components-MaternityDot-___styles__stateIcon___R9tm0",
	"maternityPlain": "src-modules-animals-components-MaternityDot-___styles__maternityPlain___xV8WA",
	"maternityNone": "src-modules-animals-components-MaternityDot-___styles__maternityNone___aH8G4",
	"maternityMissing": "src-modules-animals-components-MaternityDot-___styles__maternityMissing___VH4vY",
	"maternityMatches": "src-modules-animals-components-MaternityDot-___styles__maternityMatches___Efwf_",
	"maternityMismatches": "src-modules-animals-components-MaternityDot-___styles__maternityMismatches___gTCf1"
};
export default ___CSS_LOADER_EXPORT___;
