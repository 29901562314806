import {ErrorResponse, extractAllBeErrorMessages} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {put, select, take} from 'typed-redux-saga';

import {simpleOrdersSelector} from 'orders/model/selector';

import {orderAction} from '../action';

export function* isActionForced(response: ErrorResponse<unknown>): SagaIterator<boolean> {
    const errors = extractAllBeErrorMessages(response.data);

    yield* put(orderAction.downloadWithoutForceFailed(errors));
    yield* put(orderAction.setModalVisibility('forceConfirm', true));
    yield* take('order/DOWNLOAD_WITHOUT_FORCE_RESOLVE');
    yield* put(orderAction.setModalVisibility('forceConfirm', false));

    return yield* select(simpleOrdersSelector.forceDownloadConfirmed);
}
