// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';
import {StringPatternSchema} from '@fl/cmsch-fe-library';


// An ear tag in a less strict form.
// Example: "CZ000326549931"

export const globalEarTagRegexGen = (): RegExp => new RegExp("^[\\w\\Q!-.*'()\\E]+$");

export const globalEarTagMinLength = 1;
export const globalEarTagMaxLength = 255;

export const GlobalEarTagSchema = t.refinement(StringPatternSchema<GlobalEarTag>(globalEarTagRegexGen()), n => n.length >= globalEarTagMinLength && n.length <= globalEarTagMaxLength, 'GlobalEarTag');

export type GlobalEarTag = string;
