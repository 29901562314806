import React, {FC, useCallback} from 'react';

import {ActionButton} from 'common/buttons';
import {SideMenu} from 'layout';
import {useOurTranslation} from 'translations';

interface Props {
    animalId: number;
    goBack(): void;
    goTo(path: string): void;
}

export const AnimalEditActions: FC<Props> = props => {
    const {goBack, goTo, animalId} = props;
    const {t} = useOurTranslation('animals/AnimalEditActions');

    const goToDetail = useCallback(() => {
        goTo(`/animals/${animalId}`);
    }, [animalId, goTo]);

    return (
        <SideMenu title={t('title')}>
            <ActionButton
                icon="infoCircleOutlined"
                onClick={goToDetail}
            >
                {t('detail')}
            </ActionButton>
            <ActionButton
                onClick={goBack}
                type="goBack"
            >
                {t('back')}
            </ActionButton>
        </SideMenu>
    );
};
