import {putAll, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {put, call} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {appAction} from 'app/model/action';
import {t} from 'translations';
import {showBeError} from 'utils/show-be-error';

import {dialsAction, GetOrderTypesAction} from '../action';

function* execute(_action: GetOrderTypesAction): SagaIterator {
    yield* put(appAction.addLoadingContent('orderType'));

    const response = yield* call(Api.getOrderTypes);

    if (response.isSuccess) {
        yield* put(dialsAction.getOrderTypesSuccess(response.data));
    } else {
        yield putAll(showBeError(response, t('dials/screenList')('orderTypesCaption')));
    }

    yield* put(appAction.removeLoadingContent('orderType'));
}

export function* getOrderTypesSaga(): SagaIterator {
    yield takeLatestF('dials/GET_ORDER_TYPES', execute);
}
