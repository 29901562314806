import {putAll, showSuccess, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'translations';
import {showBeError} from 'utils/show-be-error';

import {otherAction, SynchronizeAnimalsAction} from '../action';

const te = t('other/screenOther');

function* execute(_action: SynchronizeAnimalsAction): SagaIterator {
    const response = yield* call(Api.synchronizeAnimals);

    if (response.isSuccess) {
        yield* put(otherAction.synchronizeAnimalsSuccess());
        yield* put(showSuccess(te('synchronizeAnimalsTitle'), te('synchronizeAnimalsSuccess')));
    } else {
        yield putAll(showBeError(response, te('synchronizeAnimalsTitle')));
        yield* put(otherAction.synchronizeAnimalsError());
    }
}

export function* synchronizeAnimalsSaga(): SagaIterator {
    yield takeLatestF('other/SYNCHRONIZE_ANIMALS', execute);
}
