// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// A city name
// Example: "Hradištko"

export const cityMinLength = 1;
export const cityMaxLength = 255;

export const CitySchema = t.refinement(t.string, n => n.length >= cityMinLength && n.length <= cityMaxLength, 'City');

export type City = string;
