// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { Breed, BreedSchema } from 'api/gen/Breed';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { ChipId, ChipIdSchema } from 'api/gen/ChipId';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { CustomerName, CustomerNameSchema } from 'api/gen/CustomerName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Date, DateSchema } from 'api/gen/Date';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { DateTime, DateTimeSchema } from 'api/gen/DateTime';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Email, EmailSchema } from 'api/gen/Email';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { GlobalEarTag, GlobalEarTagSchema } from 'api/gen/GlobalEarTag';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { LabNumber, LabNumberSchema } from 'api/gen/LabNumber';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { OrderTypeInOrder, OrderTypeInOrderSchema } from 'api/gen/OrderTypeInOrder';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { ParentMatch, ParentMatchSchema } from 'api/gen/ParentMatch';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveLong, PositiveLongSchema } from 'api/gen/PositiveLong';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { SexType, SexTypeSchema } from 'api/gen/SexType';

const requiredPart = t.interface({
  animalId: PositiveLongSchema,
  breed: t.union([BreedSchema, t.null]),
  catalogListUrl: t.union([t.string, t.null]),
  chipId: t.union([ChipIdSchema, t.null]),
  createdTs: t.union([DateTimeSchema, t.null]),
  customerName: CustomerNameSchema,
  customerNote: t.union([t.string, t.null]),
  earTag: GlobalEarTagSchema,
  email: EmailSchema,
  exportedByAssociation: t.boolean,
  genotypeExportGeneratedDate: t.union([DateSchema, t.null]),
  hasCatalogList: t.boolean,
  id: PositiveLongSchema,
  isolated: t.union([t.boolean, t.null]),
  isolationDate: t.union([DateSchema, t.null]),
  laboratoryNote: t.union([t.string, t.null]),
  laboratoryNumber: t.union([LabNumberSchema, t.null]),
  maternity: t.union([ParentMatchSchema, t.null]),
  orderTypes: t.array(OrderTypeInOrderSchema),
  paternity: t.union([ParentMatchSchema, t.null]),
  protocolGeneratedDate: t.union([DateSchema, t.null]),
  sampleDeliveredDate: t.union([DateSchema, t.null]),
  sampleTypeId: PositiveLongSchema,
  sex: t.union([SexTypeSchema, t.null]),
});

export const OrderSchema = excess(requiredPart);

export interface Order extends t.TypeOf<typeof OrderSchema> {}
