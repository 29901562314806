// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveOrZeroInteger, PositiveOrZeroIntegerSchema } from 'api/gen/PositiveOrZeroInteger';

const requiredPart = t.interface({
  numOfImportedOrders: PositiveOrZeroIntegerSchema,
  numOfProcessedLines: PositiveOrZeroIntegerSchema,
  skippedSampleIds: t.array(t.string),
});

export const ImportedOrdersSchema = excess(requiredPart);

export interface ImportedOrders extends t.TypeOf<typeof ImportedOrdersSchema> {}
