import {putAll, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {showSuccessSimple} from 'notifications';
import {t} from 'translations';
import {showBeError} from 'utils/show-be-error';

import {CreateSnpCategoryAction, snpActions} from '../actions';

const te = t('snpManagement/SnpTable');

function* execute({payload: {name}}: CreateSnpCategoryAction): SagaIterator {
    const response = yield* call(Api.createCategory, {name});

    if (!response.isSuccess) {
        yield putAll(showBeError(response, te(`tableHeaders.title`)));
    } else {
        yield* put(showSuccessSimple({message: te('requestMessages.categoryCreated')}));
        yield* put(snpActions.setShowCreateSnpCategory());
        yield* put(snpActions.loadSnp(opt({action: 'refresh'})));
        yield* put(snpActions.loadCategories());
    }
}

export function* createCategorySaga(): SagaIterator {
    yield takeLatestF('snpManagement/CREATE_CATEGORY', execute);
}
