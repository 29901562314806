import {EarTag} from 'api/gen/EarTag';
import {ParentSearchType} from 'parentSearch/types/parent-search-type';
import {PotentialSecondParent} from 'parentSearch/types/potential-second-parent';

export const parentSearchFormName = 'parentSearch';

export interface ParentSearchFormValues {
    childSampleId: EarTag;
    parentSampleIds: Array<PotentialSecondParent>;
    searchType: ParentSearchType;
}
