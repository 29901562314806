import {isEmpty} from 'lodash/fp';

import {earTagRegexGen} from 'api/gen/EarTag';
import {PotentialSecondParent} from 'parentSearch/types/potential-second-parent';
import {t, tCommon} from 'translations';
import {PaternityValidator} from 'utils/paternity-validator';
import {Errors} from 'utils/validator';

import {ParentSearchFormValues} from './parent-search-form-values';

const te = t('parentSearch/Form');

const validatePotentialSecondParents = (values: PotentialSecondParent): Errors<PotentialSecondParent> => {
    const validator = new PaternityValidator(values);

    validator.pattern('sampleId', earTagRegexGen(), tCommon('earTag'));

    return validator.generateErrorsObject();
};

export const validate = (values: ParentSearchFormValues): Errors<ParentSearchFormValues> => {
    const {searchType, parentSampleIds} = values;
    const validator = new PaternityValidator(values);

    validator.nonEmpty('childSampleId', tCommon('earTag'));
    validator.pattern('childSampleId', earTagRegexGen(), tCommon('earTag'));

    if (searchType === 'PARENTS') {
        const errors = validator.generateErrorsObject();
        errors.parentSampleIds = parentSampleIds.map(validatePotentialSecondParents);
        if (isEmpty(parentSampleIds.filter(x => x.sampleId))) {
            errors.parentSampleIds = [{sampleId: te('atLeastOneParent')}];
        }

        return errors;
    }

    return validator.generateErrorsObject();
};
