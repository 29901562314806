import {classNames} from '@fl/cmsch-fe-library';
import React, {FC, memo} from 'react';

import {Ant} from 'common/ant';
import {ICONS} from 'common/icons';
import {useOurTranslation} from 'translations';

import styles from './styles.sass';

const FooterBase: FC = () => {
    const {t, tCommon} = useOurTranslation('layout/footer');

    return (
        <footer className={styles.footer}>
            <div className={classNames('w-100 d-flex justify-content-center py-3', styles.footerContent)}>
                <div className="h-100 w-100">
                    <Ant.Row>
                        <Ant.Col xs={20} >
                            <div className={classNames('d-flex pl-3 h-100 align-items-center', styles.footerText)}>
                                <div>{t('text')}</div>
                            </div>
                        </Ant.Col>
                        <Ant.Col xs={4}>
                            <div className="d-flex flex-row-reverse pr-3">
                                <a
                                    href="https://cs-cz.facebook.com/cmsch.cz/"
                                    className={classNames(styles.facebook, 'd-flex', 'align-items-center')}
                                    title={`${tCommon('company')} ${tCommon('onFacebook')}`}
                                >
                                    {ICONS.facebookFilled}
                                </a>
                            </div>
                        </Ant.Col>
                    </Ant.Row>
                </div>
            </div>
        </footer>
    );
};

export const Footer = memo(FooterBase);
