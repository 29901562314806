import {none, Opt} from 'ts-opt';

import {AppInfo} from 'api/gen/AppInfo';

export interface OtherState {
    catalogListsUpdated: boolean;
    catalogListsUpdateInProgress: boolean;
    animalsSychronizeStarted: boolean;
    animalsSynchronizationInProgress: boolean;
    paternityUpdateStarted: boolean;
    paternityUpdateInProgress: boolean;
    appInfo: Opt<AppInfo>;
}

export const initialOtherState: OtherState = {
    catalogListsUpdated: false,
    catalogListsUpdateInProgress: false,
    animalsSychronizeStarted: false,
    animalsSynchronizationInProgress: false,
    paternityUpdateStarted: false,
    paternityUpdateInProgress: false,
    appInfo: none,
};
