import {putAll, showSuccess, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'translations';
import {showBeError} from 'utils/show-be-error';

import {ProcessGenotypeExportsAction} from '../action';

function* execute(_action: ProcessGenotypeExportsAction): SagaIterator {
    const response = yield* call(Api.processGenotypeExports);

    if (response.isSuccess) {
        yield* put(showSuccess(
            t('genotypeExports/screenList')('title'),
            t('genotypeExports/GenotypesTable')('processSuccess'),
        ));
    } else {
        yield putAll(showBeError(response, t('genotypeExports/screenList')('title')));
    }
}

export function* processGenotypeExportsSaga(): SagaIterator {
    yield takeLatestF('genotypesExports/PROCESS_GENOTYPE_EXPORTS', execute);
}
