import React, {FC, useCallback} from 'react';
import {useDispatch} from 'react-redux';

import {IconName} from 'common/icons';

import {orderAction} from '../../model';
import {Dropdown} from './dropdown';
import {Option} from './dropdown-options';

interface Props {
    filterSize: number;
    selectedSize: number;
    title: string;
    icon: IconName;
    loading?: boolean;
    enableConfirmFilter?: boolean;
    confirmFilterMessage?: string;
    ignoreMaxLimit?: boolean;
    onFilterClick(excludeSelected: boolean): void;
    onSelectedClick(): void;
}

export const ListAction: FC<Props> = props => {
    const {
        filterSize,
        selectedSize,
        title,
        icon,
        loading,
        enableConfirmFilter,
        confirmFilterMessage,
        ignoreMaxLimit,
        onFilterClick,
        onSelectedClick,
    } = props;

    const dispatch = useDispatch();
    const handleFilterClick = useCallback((withoutSelected: boolean) => {
        if (enableConfirmFilter) {
            dispatch(orderAction.showConfirmDialog({
                title,
                message: confirmFilterMessage,
                onConfirm: () => onFilterClick(withoutSelected),
            }));
        } else {
            onFilterClick(withoutSelected);
        }
    }, [enableConfirmFilter, confirmFilterMessage, title, onFilterClick, dispatch]);

    const onOption = useCallback((option: string): void => {
        switch (option) {
            case Option.filter:
                return handleFilterClick(false);
            case Option.filterWithoutSelected:
                return handleFilterClick(true);
            case Option.selected:
                return onSelectedClick();
        }
    }, [handleFilterClick, onSelectedClick]);

    return (
        <Dropdown
            filterSize={filterSize}
            selectedSize={selectedSize}
            icon={icon}
            title={title}
            loading={loading}
            onOption={onOption}
            ignoreMaxLimit={ignoreMaxLimit}
        />
    );
};
