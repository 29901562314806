// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { Date, DateSchema } from 'api/gen/Date';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveLong, PositiveLongSchema } from 'api/gen/PositiveLong';

const requiredPart = t.interface({
  exportDate: t.union([DateSchema, t.null]),
  id: PositiveLongSchema,
  readyForBilling: t.boolean,
});

export const OrderTypeInOrderSchema = excess(requiredPart);

export interface OrderTypeInOrder extends t.TypeOf<typeof OrderTypeInOrderSchema> {}
