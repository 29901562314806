// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';
import {StringPatternSchema} from '@fl/cmsch-fe-library';


// A chip id.
// Example: "202939000051"

export const chipIdRegexGen = (): RegExp => new RegExp("^\\d{1,20}$");

export const ChipIdSchema = StringPatternSchema<ChipId>(chipIdRegexGen());

export type ChipId = string;
