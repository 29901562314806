import {Opt, none} from 'ts-opt';

import {AnimalDetails} from 'api/gen/AnimalDetails';
import {CategoryDetails} from 'api/gen/CategoryDetails';
import {MatchedMicrosatellite} from 'api/gen/MatchedMicrosatellite';
import {Measurements} from 'api/gen/Measurements';
import {PagedModelAnimal} from 'api/gen/PagedModelAnimal';
import {PagedModelOriginInconsistency} from 'api/gen/PagedModelOriginInconsistency';
import {PositiveLong} from 'api/gen/PositiveLong';
import {Qtl} from 'api/gen/Qtl';

import {AnimalsLoadingId} from '../types/animals-loading-id';

export interface AnimalsState {
    animalsPage: Opt<PagedModelAnimal>;
    originInconsistenciesPage: Opt<PagedModelOriginInconsistency>;
    categories: Array<CategoryDetails> | null;
    animalQtls: Array<Qtl> | null;
    animal: AnimalDetails | null;
    microsatellites: Array<MatchedMicrosatellite> | null;
    measurements: Measurements | null;
    selectedAnimalIds: Array<PositiveLong>;
    inconsistenciesActionActive: Array<number>;
    selectedOriginInconsistencyIds: Array<number>;
    loading: Array<AnimalsLoadingId>;
}

export const initialAnimalsState: AnimalsState = {
    animalsPage: none,
    originInconsistenciesPage: none,
    categories: null,
    animalQtls: null,
    animal: null,
    microsatellites: null,
    measurements: null,
    selectedAnimalIds: [],
    inconsistenciesActionActive: [],
    selectedOriginInconsistencyIds: [],
    loading: [],
};
