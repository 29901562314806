import i18next, {InitOptions} from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';

import {Config} from 'app/config';

import {translations} from './translations';

const options: InitOptions = {
    lng: 'CS',
    fallbackLng: 'EN',
    resources: translations,
    debug: Config.isDevelopment,
    interpolation: {
        escapeValue: false, // not needed for react
    },
    react: {
        bindI18n: 'languageChanged loaded',
        bindI18nStore: 'added removed',
        nsMode: 'default',
    },
    defaultNS: 'common',
};

// eslint-disable-next-line import/no-named-as-default-member
void i18next
    .use(LanguageDetector)
    .use(initReactI18next)
    .init(options);

export const i18n = i18next;
