import {extractFormErrorsFromResponse, putAll, showSuccess, takeLatestF} from '@fl/cmsch-fe-library';
import {isEmpty} from 'lodash/fp';
import {SagaIterator} from 'redux-saga';
import {put, call} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {transformFormToAddress} from 'orders/utils/transform-address';
import {t} from 'translations';
import {formHelpers} from 'utils/forms';
import {showBeError} from 'utils/show-be-error';

import {UpdateCustomerAction} from '../action';

const te = t('orders/sagas');

// eslint-disable-next-line max-lines-per-function
function* execute(action: UpdateCustomerAction): SagaIterator {
    const {orderId, customer} = action.payload;

    if (!customer.userDetails) return;

    const {
        address,
        billingAddress,
        customerName,
        vatNumber,
        email,
        firstname,
        cin,
        id,
        lastname,
        phone,
        hasSameAddresses,
    } = customer.userDetails;

    const parsedAddress = transformFormToAddress(address);
    const parsedBillingAddress = hasSameAddresses ? parsedAddress : transformFormToAddress(billingAddress);

    if (!parsedAddress || !customerName || !email || !firstname || !lastname || id === null || !parsedBillingAddress) {
        return;
    }

    yield* put(formHelpers.startSubmit('editOrderCustomer'));

    const response = yield* call(Api.updateCustomer, {
        address: parsedAddress,
        billingAddress: parsedBillingAddress,
        cin,
        customerName,
        email,
        firstname,
        id,
        lastname,
        phone,
        vatNumber,
    }, {orderId});

    if (response.isSuccess) {
        yield* put(formHelpers.stopSubmit('editOrderCustomer'));
        yield* put(showSuccess(te('updateCustomer'), te('success')));
        yield* put(formHelpers.initialize('editOrderCustomer', {userDetails: customer.userDetails}));
    } else {
        const formErrors = extractFormErrorsFromResponse(response);
        if (isEmpty(formErrors)) {
            yield putAll(showBeError(response, te('updateCustomer')));
            yield* put(formHelpers.stopSubmit('editOrderCustomer'));
        } else {
            yield* put(formHelpers.stopSubmit('editOrderCustomer', formErrors));
        }
    }
}

export function* updateCustomerSaga(): SagaIterator {
    yield takeLatestF('order/UPDATE_CUSTOMER', execute);
}
