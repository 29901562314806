import {classNames} from '@fl/cmsch-fe-library';
import React, {Fragment, FC, memo, PropsWithChildren, useEffect} from 'react';

import {Loader} from 'common/layout';

import styles from './styles.sass';

interface Props {
    routesWithoutFetching: Array<RegExp>;
    isLoading: boolean;
    isUrlChanged: boolean;
    changeLoadersUrl(): void;
}

const SitesBase: FC<PropsWithChildren<Props>> = props => {
    const {isLoading, isUrlChanged, routesWithoutFetching, children, changeLoadersUrl} = props;

    useEffect(() => {
        const isRouteWithoutFetching = routesWithoutFetching.some(x => x.test(location.pathname));
        if (isRouteWithoutFetching) {
            changeLoadersUrl();
        }
    }, [changeLoadersUrl, routesWithoutFetching]);

    return (
        <Fragment>
            <div className={classNames('position-relative', 'w-100', (isLoading || isUrlChanged) && styles.loading)}>
                <div className={styles.childrenWrapper}>{children}</div>
            </div>
            {isLoading && (
                <Loader
                    show
                    size="large"
                    noBackground
                    centerPosition
                />
            )}
        </Fragment>
    );
};

export const SitesWrapper = memo(SitesBase);
