import React, {FC, memo} from 'react';

import {Ant} from 'common/ant';
import {TFunction} from 'translations';

import {ImportScreenType} from '../../types/import-screen-type';
import {StepIcon} from '../step-icon';

interface Props {
    importType: ImportScreenType;
    t: TFunction<'fileImport/screenImport'>;
}

const FirstStepBase: FC<Props> = ({t, importType}) => (
    <Ant.Row>
        <Ant.Col
            xs={24}
            md={3}
            lg={2}
        >
            <StepIcon stepNumber={1} />
        </Ant.Col>
        <Ant.Col
            xs={24}
            md={18}
            lg={20}
        >
            <div className="d-flex h-100 align-items-center">
                <div>
                    {t(importType === ImportScreenType.importChips ? 'chipImportText' : 'importText')}
                </div>
            </div>
        </Ant.Col>
    </Ant.Row>
);

export const FirstStep = memo(FirstStepBase);
