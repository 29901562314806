// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.75.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.75.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-orders-components-list-action-___styles__listAction___XJLNk{margin:.2em 0!important}.src-modules-orders-components-list-action-___styles__listAction___XJLNk .ant-btn .anticon{display:inline-flex!important;align-items:center}.src-modules-orders-components-list-action-___styles__listAction___XJLNk .ant-btn-loading{color:#fff!important;background:#719041!important;border-color:#719041!important}.src-modules-orders-components-list-action-___styles__listActionOptions___T_1yO{box-shadow:0 2px 6px #0003}.src-modules-orders-components-list-action-___styles__listActionOptions___T_1yO .ant-menu-item:not(.ant-menu-item-disabled):hover{color:#e1f1c7;background-color:#719041}\n", "",{"version":3,"sources":["webpack://./src/modules/orders/components/list-action/styles.sass"],"names":[],"mappings":"AAAA,yEAAA,uBAAA,CAA4C,2FAAsC,6BAAA,CAA+B,kBAAA,CAAmB,0FAAqC,oBAAA,CAAsB,4BAAA,CAA8B,8BAAA,CAAgC,gFAAmB,0BAAA,CAAsC,kIAA6E,aAAA,CAAc,wBAAA","sourcesContent":[".listAction{margin:.2em 0 .2em 0 !important}.listAction :global .ant-btn .anticon{display:inline-flex !important;align-items:center}.listAction :global .ant-btn-loading{color:#fff !important;background:#719041 !important;border-color:#719041 !important}.listActionOptions{box-shadow:0 2px 6px 0 rgba(0,0,0,.2)}.listActionOptions :global .ant-menu-item:not(.ant-menu-item-disabled):hover{color:#e1f1c7;background-color:#719041}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listAction": "src-modules-orders-components-list-action-___styles__listAction___XJLNk",
	"listActionOptions": "src-modules-orders-components-list-action-___styles__listActionOptions___T_1yO"
};
export default ___CSS_LOADER_EXPORT___;
