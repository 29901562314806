import {Options} from '@fl/cmsch-fe-library';
import React, {FC, memo} from 'react';
import {useSelector} from 'react-redux';

import {SexType} from 'api/gen/SexType';
import {FooterButtons} from 'common/buttons';
import {newBulkOrderFormValuesSelector} from 'orders/model';
import {useOurTranslation} from 'translations';
import {useForm} from 'utils/forms';

import {
    newBulkOrderFormName,
    NewBulkOrderFormValues,
    newBulkOrderInitialValues,
} from '../NewBulkForm/new-bulk-order-form-values';
import {SelectedAnimalsTable} from '../NewOrderSelectedAnimalsTable';

interface Props {
    animalSexes: Options<SexType>;
    sampleTypes: Options<number>;
    orderTypes: Options<number>;
    onPreviousClick(): void;
    onSubmit(values: NewBulkOrderFormValues): void;
}

const BulkOrderConfirmationFormBase: FC<Props> = props => {
    const {
        sampleTypes,
        orderTypes,
        onSubmit,
        onPreviousClick,
    } = props;

    const {t} = useOurTranslation('orders/OrderConfirmationForm');

    const {Form, submitting, renderErrors} = useForm({
        form: newBulkOrderFormName,
        destroyOnUnmount: false,
        forceUnregisterOnUnmount: true,
        keepDirtyOnReinitialize: true,
        initialValues: newBulkOrderInitialValues,
        onSubmit,
    });
    const formValues = useSelector(newBulkOrderFormValuesSelector).orElse(newBulkOrderInitialValues);

    const {
        userDetails,
        orderTypeIds,
        sampleTypeId,
    } = formValues;

    const selectedSampleType = sampleTypes.find(x => x.value === sampleTypeId)?.label;
    const selectedOrderTypes = orderTypeIds?.map(x => orderTypes.find(y => y.value === x)?.label) || [];
    const cellHiddenOnMD = 'd-table-cell d-md-none';
    const cellShownOnMD = 'd-none d-md-table-cell';

    return (
        <Form>
            {renderErrors()}

            <fieldset>
                <legend>{t('caption')}</legend>

                <div className="row">
                    <div className="col-12">
                        <h5>{t('orderData')}</h5>

                        <table className="table">
                            <tbody>
                                <tr>
                                    <th className={cellShownOnMD}>{t('sampleType')}</th>
                                    <td className={cellShownOnMD}>{selectedSampleType}</td>

                                </tr>
                                <tr>
                                    <th className={cellHiddenOnMD}>{t('sampleType')}</th>
                                    <td className={cellHiddenOnMD}>{selectedSampleType}</td>
                                </tr>

                                <tr>
                                    <th>{t('orderTypes')}</th>
                                    <td colSpan={5}>
                                        {selectedOrderTypes.map(type =>
                                            <span key={type}>{type}<br /></span>)}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <h5>{t('customerData')}</h5>

                        <table className="table">
                            <tbody>
                                <tr>
                                    <th>{t('customerName')}</th>
                                    <td>{userDetails?.customerName}</td>
                                </tr>
                                <tr>
                                    <th>{t('firstAndLastName')}</th>
                                    <td>{userDetails?.firstname} {userDetails?.lastname}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <h5>{t('animalData')}</h5>
                        <SelectedAnimalsTable isSummary />
                    </div>
                </div>

            </fieldset>

            <FooterButtons
                cancelTitle={t('back')}
                onCancel={onPreviousClick}
                submitTitle={t('submit')}
                submitting={submitting}
            />
        </Form>
    );
};

export const BulkOrderConfirmationForm = memo(BulkOrderConfirmationFormBase);
