import {extractFormErrorsFromResponse, putAll, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'translations';
import {formHelpers} from 'utils/forms';
import {showBeError} from 'utils/show-be-error';

import {parentSearchAction, SearchParentAction} from '../action';

function* execute({payload: {data}}: SearchParentAction): SagaIterator {
    const {childSampleId, parentSampleIds, searchType} = data;
    const parentEarTags = searchType !== 'PARENTS' ? []
        : parentSampleIds.filter(x => x.sampleId).map(x => x.sampleId) as Array<string>;

    yield* put(formHelpers.startSubmit('parentSearch'));

    const response = yield* call(Api.searchParent, {parentEarTags}, {earTag: childSampleId});

    if (response.isSuccess) {
        yield* put(parentSearchAction.searchParentSuccess(response.data));
        yield* put(formHelpers.stopSubmit('parentSearch'));
    } else {
        yield* put(formHelpers.stopSubmit('parentSearch', extractFormErrorsFromResponse(response)));
        yield putAll(showBeError(response, t('parentSearch/List')('title')));
    }
}

export function* parentSearchSaga(): SagaIterator {
    yield takeLatestF('parentSearch/PARENT_SEARCH', execute);
}
