// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.75.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.75.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-file-import-components-import-dropzone-___styles__dropboxContainer___YKJml{width:100%;background-color:#fff;opacity:.9;border:dashed #bfd2b5}\n", "",{"version":3,"sources":["webpack://./src/modules/file-import/components/import-dropzone/styles.sass"],"names":[],"mappings":"AAAA,wFAAkB,UAAA,CAAW,qBAAA,CAAsB,UAAA,CAAW,qBAAA","sourcesContent":[".dropboxContainer{width:100%;background-color:#fff;opacity:.9;border:dashed #bfd2b5}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropboxContainer": "src-modules-file-import-components-import-dropzone-___styles__dropboxContainer___YKJml"
};
export default ___CSS_LOADER_EXPORT___;
