export const animalsTranslations = {
    CS: {
        'animals/screenList': {
            title: 'Správa zvířat',
        },
        'animals/QtlsTable': {
            columnHeaders: {
                snpName: 'Název SNP',
                result: 'Výsledky',
                type: 'Typ',
            },
            typeOptions: {
                FRIESIAN_HORNLESSNESS: 'Frízská bezrohost',
                CELTIC_HORNLESSNESS: 'Keltská bezrohost',
                BLAD: 'BLAD',
                DWARFISM_HOLSTEIN: 'Dwarfismus (holst)',
                DWARFISM_CHAROL: 'Dwarfismus (charol)',
                RED_FACTOR: 'Red factor',
                KAPPA_CASEIN: 'Kappa kasein',
                BETA_CASEIN: 'Beta kasein',
                DUMPS: 'Dumps',
                MULEFOOT: 'Mulefoot',
                CARDIOMYOPATHY_HOLSTEIN_RED: 'Kadriomyopatie (holst červený)',
                HH1: 'HH1',
                HH3: 'HH3',
                HH4: 'HH4',
                HH5: 'HH5',
                HH6: 'HH6',
                HH7: 'HH7',
                HH12: 'HH12',
                MANNOSIDOSIS: 'Mannosidosis alpha / angus',
                MOTTLING_MICROPHTHALMIA: 'Strakatost / mikroftalmie',
                ATAXIA: 'Ataxie',
                BD1: 'BD1',
                M_C313Y: 'M. c313y',
                M_E226X: 'M. E226x',
                M_E291X: 'M. E291x',
                M_MT419: 'M. mt419',
                M_Q204: 'M. Q204',
                M_F94L: 'M. F94L',
                M_L64B: 'M. L 64B',
                M_S105C: 'M. S 105C',
                M_NT821: 'M. nt821',
                NH2: 'NH2 - Normand',
                RETINITIS_PIGMENTOSA: 'Retinitis pigmentosa 1 (normand)',
                CHEDIAK_HIGASHI_SYNDROME: 'Chediak-Higashi syndrome',
                ICHTYOSIS_CONGENITA: 'Ichtyosis congenita',
                JH1: 'JH1',
                MH2: 'MH2',
                SHORT_TAIL_SPERM: 'Krátký ocásek spermií',
                SWISS_BROWN_ALBINISM: 'Albinismus Swiss Brown',
                DILUTION_GENE_LIGHTER: 'Dilution gen - zesvětlené do béžové',
                DILUTION_GENE_VERY_LIGHT: 'Dilution gen - velmi světlé, krémová, šedá',
                EHLERS_DANLOS_SYNDROME: 'Ehlers-Danlos syndrome',
                CHOLESTEROL_DEFICIENCY: 'Cholesterolová deficience',
                FACTOR_X_DEFICIENCY: 'Faktor X deficiency',
            },
            resultOptions: {
                NORMAL: '*NORMAL',
                CARRIER: '*Carrier',
                FREE: '*Free',
                AFFECTED: '*Affected',
                MISSING: '-/-',
                MOTTLED: '*Mottled',
                MANTLED: '*Mantled',
                OK: '*OK',
                ALBINO: 'Albino',
                NON_DILUTED: '*Non diluted',
                DILUTED: '*Diluted',
                CREAM: '*Cream',
                HH1T: '*HH1T',
                HH1C: '*HH1C',
                HH3T: '*HH3T',
                HH3C: '*HH3C',
                HH4T: '*HH4T',
                HH4C: '*HH4C',
                HH5T: '*HH5T',
                HH5C: '*HH5C',
                HH6T: '*HH6T',
                HH6C: '*HH6C',
                HH7T: '*HH7T',
                HH12T: '*HH12T',
                MH2T: '*MH2T',
                AA: '*AA',
                AB: '*AB',
                AE: '*AE',
                BB: '*BB',
                BE: '*BE',
                EE: '*EE',
                A1A1: '*A1A1',
                A1A2: '*A1A2',
                A2A2: '*A2A2',
                BL: '*BL',
                BM: '*BM',
                BD: '*BD',
                CD: '*CD',
                BD1_NN: '*BD1 NN',
                BD1_NC: '*BD1 NC',
                PP: '*p/p',
                PC_P: '*Pc/p',
                PC_PC: '*Pc/Pc',
                PF_P: '*Pf/p"',
                PF_PF: '*Pf/Pf',
                POSITIVE: '*+/+',
                MH_POSITIVE: '*Mh/+',
                MH_MH: '*Mh/Mh',
                F_POSITIVE: '*F/+',
                F_F: '*F/F',
                WT_WT: '*WT/WT',
                WT_DEL11: '*WT/del11',
                DEL11_DEL11: '*del11/del11',
                DD_RED: '*DD - RED faktor negativní',
                DE_RED: '*D/e - RED přenáší gen pro recesivní červenou',
                DE_HETEROZYGOTE_RED: 'D/e – RED faktor heterozygot',
                DE_RED_INDIFFERENT: 'D/e – RED faktor heterozygot (nelze odlišit genotyp e a +)',
                D_PLUS_RED: '*D/+ - RED faktor heterozygot, přítomna divoká alela',
                PLUS_PLUS_RED: '*+/+ - RED faktor pozitivní, přítomna divoká alela',
                E_PLUS_RED: 'e/+ - RED faktor pozitivní',
                EE_RED: 'e/e - RED faktor pozitivní',
                EE_RED_INDIFFERENT: 'e/e – RED faktor pozitivní (nelze odlišit genotyp e a +)',
                NH2T: '*NH2T',
                TL: '*TL',
                TM: '*TM',
                TD: '*TD',
            },
            columnHeadersTooltip: {
                result: 'Pro zobrazení SNP markerů najeďte myší na vybraný výsledek.',
            },
        },
        'animals/AnimalDetail': {
            laboratoryNumber: 'Laboratorní číslo',
            mothersLaboratoryNumber: 'Laboratorní číslo matky',
            fathersLaboratoryNumber: 'Laboratorní číslo otce',
            dob: 'Datum narození',
            breed: 'Plemeno',
            sex: 'Pohlaví',
            name: 'Jméno',
            registry: 'Registr',
            chipId: 'ID čipu',
            twin: 'Je z dvojčat?',
            fatherData: 'Údaje o otci',
            fathersRegistry: 'Registr',
            motherData: 'Údaje o matce',
            microsatellites: 'Mikrosatelity',
            searchMicrosatellites: 'Vyhledat mikrosatelity',
            saveMicrosatellites: 'Stáhnout TXT',
        },
        'animals/screenDetail': {
            title: 'Detail zvířete',
            animalData: 'Údaje o zvířeti',
            animalsData: 'Údaje o zvířatech',
        },
        'animals/AnimalDetailActions': {
            edit: 'Upravit',
            exportMothersGenotype: 'Export genotypu matky',
            exportFathersGenotype: 'Export genotypu otce',
            noMother: 'Matka není v databázi zvířat nebo nebyla zadána',
            noFather: 'Otec není v databázi zvířat nebo nebyl zadán.',
            deleteAnimal: 'Odstranit zvíře',
            orderDetail: 'Zobrazit detail objednávky',
            noOrder: 'Zvíře nemá přiřazenou žádnou objednávku',
            back: 'Zpět',
        },
        'animals/screenComparison': {
            title: 'Srovnání zvířat',
            resultsTitle: 'Výsledky',
            back: 'Zpět',
        },
        'animals/ComparisonForm': {
            categories: 'Kategorie',
            selectCategory: 'Vyberte kategorii',
            filterCategories: 'Filtrovat',
            allCategories: 'Vybrat všechny kategorie',
        },
        'animals/screenEdit': {
            title: 'Detail zvířete',
            editData: 'Upravit údaje o zvířeti',
        },
        'animals/AnimalEditActions': {
            title: 'Správa zvířat',
            detail: 'Detail',
            back: 'Zpět',
        },
        'animals/AnimalsTable': {
            columnHeaders: {
                importedFromGermany: 'Imp. z Něm.',
                parenthood: 'Rodičovství',
                laboratoryNumber: 'Lab. č.',
                chipId: 'ID čipu',
                lineRegistry: 'Registr',
                fathersLineRegistry: 'Registr otce',
                fathersLaboratoryNumber: 'Lab. č. otce',
                mothersEarTag: 'UZ matky',
                mothersLaboratoryNumber: 'Lab. č. matky',
                maternity: 'Matka',
                paternity: 'Otec',
            },
            columnHeadersTooltip: {
                importedFromGermany: 'Importováno z Německa',
                parenthood: 'Rodičovství',
                laboratoryNumber: 'Laboratorní číslo',
                chipId: 'ID čipu',
                lineRegistry: 'Registr',
                fathersLineRegistry: 'Registr otce',
                fathersLaboratoryNumber: 'Laboratorní číslo otce',
                mothersEarTag: 'Ušní známka matky',
                mothersLaboratoryNumber: 'Laboratorní číslo matky',
            },
            rowActions: {
                detail: 'Zobrazit detail zvířete',
                edit: 'Upravit údaje o zvířeti',
                export: 'Exportovat',
                exportUnavailable: 'Export není k dispozici',
            },
            messages: {
                deleteError: 'Nepodařilo se smazat zvíře',
                deleteSuccess: 'Zvíře bylo úspešně odstraněno',
                deleteTitle: 'Odstranění zvířete',
                exportError: 'Nepodařilo se exportovat zvíře',
                exportMicrosatellitesError: 'Nepodařilo se exportovat mikrosatelity',
            },
            actions: {
                compare: 'Porovnat',
                exportMicrosatellites: 'Export mikrosatelitů',
                deselectAll: 'Zrušit výběr',
            },
        },
        'animals/ComparisonResult': {
            noData: 'Nic nenalezeno',
            snpName: 'Název snp',
        },
        'animals/AnimalEditForm': {
            animalData: 'Údaje o zvířeti',
            dob: 'Datum narození',
            name: 'Jméno',
            sex: 'Pohlaví',
            sexPlaceholder: 'Vyberte pohlaví',
            breed: 'Plemeno',
            registry: 'Registr',
            fathersSampleId: 'Ušní číslo otce',
            fathersRegistry: 'Registr otce',
            mothersSampleId: 'Ušní číslo matky',
            chipId: 'ID čipu',
            twin: 'Je z dvojčat?',
            submitButton: 'Uložit',
        },
        'animals/PaternityDot': {
            paternity: {
                missing: 'Chybí otec',
                matches: 'Otec se shoduje',
                mismatches: 'Otec se neshoduje',
            },
        },
        'animals/MaternityDot': {
            maternity: {
                missing: 'Chybí matka',
                matches: 'Matka se shoduje',
                mismatches: 'Matka se neshoduje',
            },
        },
        'animals/OriginInconsistencies': {
            title: 'Zobrazení nekonzistentních původů',
        },
        'animals/OriginInconsistenciesTable': {
            columnHeaders: {
                IGOrigin: 'Informace v iGenetice',
                eSkotRec: 'Informace v eSkotu',
                active: 'Aktivní',
                laboratoryNumber: 'Lab. č.',
                motherEarTag: 'UZ matky',
                fatherEarTag: 'UZ otce',
                fatherLinReg: 'Lin-Reg otce',
                maternity: 'Matka',
                paternity: 'Otec',
                customerName: 'Chovatel',
                createdAt: 'Datum vzniku',
                correctOrigin: 'Správně',
                reported: 'Dat. odesl. form.',
                parenthood: 'Rodičovství',
                dismissedFromIAR: 'Vyřazeno',
                hasProtocol: 'Protokol',
            },
            columnHeadersTooltip: {
                active: 'Aktivní',
                laboratoryNumber: 'Laboratorní číslo',
                motherEarTag: 'Ušní známka matky',
                fatherEarTag: 'Ušní známka otce',
                fatherLinReg: 'Linie a registr otce',
                customerName: 'Chovatel',
                createdAt: 'Datum vzniku',
                correctOrigin: 'Správně',
                reported: 'Datum odeslání formuláře',
                parenthood: 'Rodičovství',
                dismissedFromIAR: 'Vyřazeno z IZR',
                hasProtocol: 'Má protokol',
            },
            tableActions: {
                confirm: 'Přijmout změny z eSkot.cz',
                inactive: 'Případ není aktivní',
                sendEmail: 'Odeslat email chovateli',
                exportPDF: 'Stáhnout žádost o doplnění původů',
                notYetSent: 'E-mail pro chovatele zatím nebyl odeslán',
                cantExportPDF: 'Případ nebyl vyřešen odesláním emailu chovateli',
                toCSV: 'Export do CSV',
                accepted: 'Změny přijaty',
                reported: 'Email pro uživatele byl odeslán',
                markInactive: 'Označit jako neaktivní',
                markActive: 'Označit jako aktivní',
                cannotMark: 'Případ není možné aktivovat',
                noDataFromEskot: 'Data v eSkotu neexistují',
                noDataFromPaternity: 'Data v iGenetice neexistují',
                otherActionActive: 'Počkejte, než se dokončí probíhající akce.',
                confirmAcceptChange:
                    'Opravdu chcete přijmout změny z eSkot.cz pro zvíře {{UZ}}?\n\nZměny:\n{{changes}}',
                confirmSendEmail: 'Opravdu chcete odeslat email chovateli pro zvíře {{UZ}}?',
                confirmMarkAsInactive: 'Opravdu chcete označit případ pro zvíře {{UZ}} jako neaktivní?',
                confirmMarkAsActive: 'Opravdu chcete označit případ pro zvíře {{UZ}} jako aktivní?',
                willBeReplaced: 'Údaj {{old}} bude nahrazen za {{new}}.',
                willBeSetTo: 'Údaj {{data}} bude nastaven na {{new}}.',
                dataMatch: 'Data z eSkotu a iGenetiky se shodují.',
            },
            messages: {
                changeAccepted: 'Změna akceptována',
                changeError: 'Nepodařilo se přijmout změnu',
                emailSent: 'Email odeslán',
                tableError: 'Nepodařilo se načíst data',
                emailError: 'Nepodařilo se odeslat email',
                inactiveError: 'Akce se nezdařila',
                inactiveSuccess: 'Akce proběhla úspěšně',
                reportError: 'Nepodařilo se stáhnout žádost o doplnění původů',
                excelError: 'Export do CSV se nepodařil',
                reactivationFailed: 'Případ nebyl reaktivován. Zvíře {{UZ}} má nastaven správný původ.',
            },
        },
        'animals/sagaMessages': {
            exportGenotypeError: 'Nepodařilo se exportovat genotyp',
            qtlsError: 'Nepodařilo se načíst Qtl',
            categoriesError: 'Nepodařilo se načíst kategorie',
            microsatellitesError: 'Nepodařilo se načíst mikrosatelity',
            fatherError: 'Nepodařilo se načíst otce',
            measurementsError: 'Nepodařilo se načíst měření',
            animalError: 'Nepodařilo se načíst zvíře',
            updateAnimalError: 'Nepodařilo se uložit zvíře',
            searchChipsError: 'Nepodařilo se najít čipy',
        },
    },
    EN: {
        'animals/screenList': {
            title: 'Administration of animals',
        },
        'animals/QtlsTable': {
            columnHeaders: {
                snpName: 'SNP name',
                result: 'Results',
                type: 'Type',
            },
            typeOptions: {
                FRIESIAN_HORNLESSNESS: 'Friesian hornlessness',
                CELTIC_HORNLESSNESS: 'Celtic hornlessness',
                BLAD: 'BLAD',
                DWARFISM_HOLSTEIN: 'Dwarfism (holstein)',
                DWARFISM_CHAROL: 'Dwarfism (charol)',
                RED_FACTOR: 'Red factor',
                KAPPA_CASEIN: 'Kappa kasein',
                BETA_CASEIN: 'Beta kasein',
                DUMPS: 'Dumps',
                MULEFOOT: 'Mulefoot',
                CARDIOMYOPATHY_HOLSTEIN_RED: 'Cadriomyopathy (holstein red)',
                HH1: 'HH1',
                HH3: 'HH3',
                HH4: 'HH4',
                HH5: 'HH5',
                HH6: 'HH6',
                HH7: 'HH7',
                HH12: 'HH12',
                MANNOSIDOSIS: 'Mannosidosis alpha / angus',
                MOTTLING_MICROPHTHALMIA: 'Mottling / microphthalmia',
                ATAXIA: 'Ataxia',
                BD1: 'BD1',
                M_C313Y: 'M. c313y',
                M_E226X: 'M. E226x',
                M_E291X: 'M. E291x',
                M_MT419: 'M. mt419',
                M_Q204: 'M. Q204',
                M_F94L: 'M. F94L',
                M_L64B: 'M. L 64B',
                M_S105C: 'M. S 105C',
                M_NT821: 'M. nt821',
                NH2: 'NH2 - Normand',
                RETINITIS_PIGMENTOSA: 'Retinitis pigmentosa 1 (normand)',
                CHEDIAK_HIGASHI_SYNDROME: 'Chediak-Higashi syndrome',
                ICHTYOSIS_CONGENITA: 'Ichtyosis congenita',
                JH1: 'JH1',
                MH2: 'MH2',
                SHORT_TAIL_SPERM: 'Short tail sperm',
                SWISS_BROWN_ALBINISM: 'Swiss Brown Albinism',
                DILUTION_GENE_LIGHTER: 'Dilution gen - lightened to beige',
                DILUTION_GENE_VERY_LIGHT: 'Dilution gen - very light, cream, gray',
                EHLERS_DANLOS_SYNDROME: 'Ehlers-Danlos syndrome',
                CHOLESTEROL_DEFICIENCY: 'Cholesterol deficiency',
                FACTOR_X_DEFICIENCY: 'Faktor X deficiency',
            },
            resultOptions: {
                NORMAL: '*NORMAL',
                CARRIER: '*Carrier',
                FREE: '*Free',
                AFFECTED: '*Affected',
                MISSING: '-/-',
                MOTTLED: '*Mottled',
                MANTLED: '*Mantled',
                OK: '*OK',
                ALBINO: 'Albino',
                NON_DILUTED: '*Non diluted',
                DILUTED: '*Diluted',
                CREAM: '*Cream',
                HH1T: '*HH1T',
                HH1C: '*HH1C',
                HH3T: '*HH3T',
                HH3C: '*HH3C',
                HH4T: '*HH4T',
                HH4C: '*HH4C',
                HH5T: '*HH5T',
                HH5C: '*HH5C',
                HH6T: '*HH6T',
                HH6C: '*HH6C',
                HH7T: '*HH7T',
                HH12T: '*HH12T',
                MH2T: '*MH2T',
                AA: '*AA',
                AB: '*AB',
                AE: '*AE',
                BB: '*BB',
                BE: '*BE',
                EE: '*EE',
                A1A1: '*A1A1',
                A1A2: '*A1A2',
                A2A2: '*A2A2',
                BL: '*BL',
                BM: '*BM',
                BD: '*BD',
                CD: '*CD',
                BD1_NN: '*BD1 NN',
                BD1_NC: '*BD1 NC',
                PP: '*p/p',
                PC_P: '*Pc/p',
                PC_PC: '*Pc/Pc',
                PF_P: '*Pf/p"',
                PF_PF: '*Pf/Pf',
                POSITIVE: '*+/+',
                MH_POSITIVE: '*Mh/+',
                MH_MH: '*Mh/Mh',
                F_POSITIVE: '*F/+',
                F_F: '*F/F',
                WT_WT: '*WT/WT',
                WT_DEL11: '*WT/del11',
                DEL11_DEL11: '*del11/del11',
                DD_RED: '*DD - RED factor negative',
                DE_RED: '*D/e - RED transmits the gene for recessive red',
                DE_HETEROZYGOTE_RED: 'D/e – RED factor heterozygote',
                DE_RED_INDIFFERENT: 'D/e – RED factor heterozygote (cannot differentiate genotype e and +)',
                D_PLUS_RED: '*D/+ - RED factor heterozygote, wild allele present',
                PLUS_PLUS_RED: '*+/+ - RED factor positive, wild allele present',
                E_PLUS_RED: 'e/+ - RED factor positive',
                EE_RED: 'e/e - RED factor positive',
                EE_RED_INDIFFERENT: 'e/e – RED factor positive (cannot differentiate genotype e and +)',
                NH2T: '*NH2T',
                TL: '*TL',
                TM: '*TM',
                TD: '*TD',
            },
            columnHeadersTooltip: {
                result: 'Hover over a result to display its SNP markers',
            },
        },
        'animals/AnimalDetail': {
            laboratoryNumber: 'Laboratory number',
            mothersLaboratoryNumber: 'Mother\'s laboratory number',
            fathersLaboratoryNumber: 'Father\'s laboratory number',
            dob: 'Date of birth',
            breed: 'Breed',
            sex: 'Sex',
            name: 'Name',
            registry: 'Registry',
            chipId: 'Chip ID',
            twin: 'is from twins?',
            fatherData: 'Father data',
            fathersRegistry: 'Registry',
            motherData: 'Mother data',
            microsatellites: 'Microsatellites',
            searchMicrosatellites: 'Search microsatellites',
            saveMicrosatellites: 'Download TXT',
        },
        'animals/screenDetail': {
            title: 'Animal Details',
            animalData: 'Animal data',
            animalsData: 'Animals data',
        },
        'animals/AnimalDetailActions': {
            edit: 'Edit',
            exportMothersGenotype: 'Export mother\'s genotype',
            exportFathersGenotype: 'Export father\'s genotype',
            noMother: 'The mather is not in the animal database or has not been entered.',
            noFather: 'The father is not in the animal database or has not been entered.',
            deleteAnimal: 'Delete animal',
            orderDetail: 'Show order detail',
            noOrder: 'The animal has no order assigned.',
            back: 'Back',
        },
        'animals/screenComparison': {
            title: 'Animals comparison',
            resultsTitle: 'Results',
            back: 'Back',
        },
        'animals/ComparisonForm': {
            categories: 'Categories',
            selectCategory: 'Select category',
            filterCategories: 'Filter',
            allCategories: 'Select all categories',
        },
        'animals/screenEdit': {
            title: 'Animal Details',
            editData: 'Edit animal details',
        },
        'animals/AnimalEditActions': {
            title: 'Administration of animals',
            detail: 'Detail',
            back: 'Back',
        },
        'animals/AnimalsTable': {
            columnHeaders: {
                importedFromGermany: 'Imp. from Ger.',
                parenthood: 'Parenthood',
                laboratoryNumber: 'Lab. n.',
                chipId: 'Chip ID',
                lineRegistry: 'Registry',
                fathersLineRegistry: 'F. registry',
                fathersLaboratoryNumber: 'Fat. LN',
                mothersEarTag: 'Moth. EN',
                mothersLaboratoryNumber: 'Moth. LN',
                maternity: 'Mother',
                paternity: 'Father',
            },
            columnHeadersTooltip: {
                importedFromGermany: 'Imported from Germany',
                parenthood: 'Parenthood',
                laboratoryNumber: 'Laboratory number',
                chipId: 'Chip ID',
                lineRegistry: 'Registry',
                fathersLineRegistry: 'Father\'s registry',
                fathersLaboratoryNumber: 'Father\'s laboratory number',
                mothersEarTag: 'Mother\'s ear number',
                mothersLaboratoryNumber: 'Mother\'s laboratory number',
            },
            rowActions: {
                detail: 'Display animal details',
                edit: 'Edit animal details',
                export: 'Export',
                exportUnavailable: 'Export unavailable',
            },
            messages: {
                deleteError: 'Failed to delete animal',
                deleteSuccess: 'Animal was deleted succesfully',
                deleteTitle: 'Animal deletion',
                exportError: 'Failed to export animal',
                exportMicrosatellitesError: 'Failed to export microsatellites',
            },
            actions: {
                compare: 'Compare',
                exportMicrosatellites: 'Export microsatellites',
                deselectAll: 'Deselect all',
            },
        },
        'animals/ComparisonResult': {
            noData: 'No data',
            snpName: 'SNP name',
        },
        'animals/AnimalEditForm': {
            animalData: 'Animal data',
            dob: 'Date of birth',
            name: 'Name',
            sex: 'Sex',
            sexPlaceholder: 'Select sex',
            breed: 'Breed',
            registry: 'Registry',
            fathersSampleId: 'Father\'s ear number',
            fathersRegistry: 'Father\'s registry',
            mothersSampleId: 'Mother\'s ear number',
            chipId: 'Chip ID',
            twin: 'is from twins?',
            submitButton: 'Save',
        },
        'animals/PaternityDot': {
            paternity: {
                missing: 'Father is missing',
                matches: 'Father matches',
                mismatches: 'Father mismatches',
            },
        },
        'animals/MaternityDot': {
            maternity: {
                missing: 'Mother is missing',
                matches: 'Mother matches',
                mismatches: 'Mother mismatches',
            },
        },
        'animals/OriginInconsistencies': {
            title: 'Display of origin inconsistencies',
        },
        'animals/OriginInconsistenciesTable': {
            columnHeaders: {
                IGOrigin: 'Information in iGenetics',
                eSkotRec: 'Information in eSkot',
                active: 'Active',
                laboratoryNumber: 'Lab. n.',
                motherEarTag: 'Moth. EN',
                fatherEarTag: 'Fath. EN',
                fatherLinReg: 'Fath. Lin-Reg',
                maternity: 'Mother',
                paternity: 'Father',
                customerName: 'Customer name',
                createdAt: 'Created at',
                correctOrigin: 'Correctly',
                reported: 'Dat. form. subm.',
                parenthood: 'Parenthood',
                dismissedFromIAR: 'Dismissed',
                hasProtocol: 'Protocol',
            },
            columnHeadersTooltip: {
                active: 'Active',
                laboratoryNumber: 'Laboratory number',
                motherEarTag: 'Mother\'s ear number',
                fatherEarTag: 'Father\'s ear number',
                fatherLinReg: 'Father\'s line and registry',
                customerName: 'Customer name',
                createdAt: 'Created at',
                correctOrigin: 'Correctly',
                reported: 'Date of form submittion',
                parenthood: 'Parenthood',
                dismissedFromIAR: 'Dismissed from IAR',
                hasProtocol: 'Has protocol',
            },
            tableActions: {
                confirm: 'Accept changes from eSkot.cz',
                inactive: 'The case is not active',
                sendEmail: 'Send an email to the breeder',
                exportPDF: 'Download the request to fill in origins',
                notYetSent: 'User email has not been sent yet',
                cantExportPDF: 'The case was not resolved by sending an email to the breeder',
                toCSV: 'Export to Excel',
                accepted: 'Changes accepted',
                reported: 'User email has been sent',
                noDataFromEskot: 'Data does not exist in eSkot',
                noDataFromPaternity: 'Data does not exist in iGenetika',
                markInactive: 'Mark as inactive',
                markActive: 'Mark as active',
                cannotMark: 'Cannot mark case',
                otherActionActive: 'Wait for the ongoing action to complete.',
                confirmAcceptChange:
                    'Are you sure you want to accept changes from eSkot.cz for animal {{UZ}}? Changes: {{changes}}',
                confirmSendEmail: 'Are you sure you want to send an email to breeder for animal {{UZ}}?',
                confirmMarkAsInactive: 'Are you sure you want to mark this case as inactive for animal {{UZ}}?',
                confirmMarkAsActive: 'Are you sure you want to mark this case as active for animal {{UZ}}?',
                willBeReplaced: '{{data}} {{old}} will be replaced by {{new}}.',
                willBeSetTo: '{{data}} will be set to {{new}}.',
                dataMatch: 'eSkot and iGenetika data match.',
            },
            messages: {
                changeAccepted: 'Change accepted',
                changeError: 'Failed to accept changes',
                emailSent: 'Email sent',
                tableError: 'Failed to load data',
                emailError: 'Failed to send email',
                inactiveError: 'The action failed',
                inactiveSuccess: 'The action was successful',
                reportError: 'Failed to download origin request',
                excelError: 'Export to Excel failed',
                reactivationFailed: 'Record was not reactivated. Animal {UZ} has the correct origin set.',
            },
        },
        'animals/sagaMessages': {
            exportGenotypeError: 'Failed to export genotype',
            qtlsError: 'Failed to load Qtl',
            categoriesError: 'Failed to load categories',
            microsatellitesError: 'Failed to load microsatellites',
            fatherError: 'Failed to load father',
            measurementsError: 'Failed to load measurements',
            animalError: 'Failed to load animal',
            updateAnimalError: 'Failed to save animal',
            searchChipsError: 'Failed to find chips',
        },
    },
};
