import {Button} from '@fl/cmsch-fe-library';
import React, {FC, memo, ReactNode} from 'react';

import {Ant} from 'common/ant';
import {doubleGutter, gutter} from 'constants/general';
import {Panel, PanelTitleWithImage} from 'layout';
import {useOurTranslation} from 'translations';
import {useForm} from 'utils/forms';

import {ParentSearchType} from '../../types/parent-search-type';
import {
    PotentialSecondParentsFieldArray,
    PotentialSecondParentsFieldArrayProps,
} from '../PotentialSecondParentsFieldArray';
import {ParentSearchFormValues, parentSearchFormName} from './parent-search-form-values';
import {validate} from './validations';

import calfSvg from './calf.svg';
import parentSvg from './parent.svg';

const initialValues: ParentSearchFormValues = {
    childSampleId: '',
    searchType: 'UNKNOWN',
    parentSampleIds: [{sampleId: ''}],
};

const calfTitle = (title: string): ReactNode => (
    <PanelTitleWithImage
        title={title}
        image={calfSvg}
    />
);
const parentTitle = (title: string): ReactNode => (
    <PanelTitleWithImage
        title={title}
        image={parentSvg}
    />
);

interface Props {
    isParentsTypeSelected: boolean;
    parentFieldsCount: number;
    onReset(): void;
    onSubmit(valus: ParentSearchFormValues): void;
}

// eslint-disable-next-line max-lines-per-function
const ParentSearchFormBase: FC<Props> = props => {
    const {
        isParentsTypeSelected,
        parentFieldsCount,
        onSubmit,
        onReset,
    } = props;

    const {t, tCommon} = useOurTranslation('parentSearch/Form');

    const {Form, Fields, valid, pristine, submitting, error} = useForm({
        form: parentSearchFormName,
        initialValues,
        destroyOnUnmount: true,
        validate,
        onSubmit,
    });

    const PotentialSecondParentsField = Fields.genFieldArray<
        'parentSampleIds',
        PotentialSecondParentsFieldArrayProps
    >();

    const ParentSearchTypeRadio = Fields.genRadio<ParentSearchType>();

    return (
        <Form>
            <Ant.Row
                gutter={[gutter, doubleGutter]}
                justify="end"
            >
                <Ant.Col xs={24}>
                    {t('secondParentData')}:
                </Ant.Col>
                <Ant.Col
                    xs={24}
                    md={12}
                >
                    <Panel
                        title={calfTitle(t('calf'))}
                        fullWidth
                        fullHeight
                    >
                        <Fields.Input
                            name="childSampleId"
                            label={tCommon('earTag')}
                            type="text"
                            placeholder={tCommon('earTagPlaceholder')}
                            disabled={submitting}
                            isRequired
                        />
                    </Panel>
                </Ant.Col>
                <Ant.Col
                    xs={24}
                    md={12}
                >
                    <Panel
                        title={parentTitle(t('parent'))}
                        fullWidth
                        fullHeight
                    >
                        <div className="row radio-list">
                            <div className="col-12 radio-item">
                                <ParentSearchTypeRadio
                                    name="searchType"
                                    radioValue="UNKNOWN"
                                    label={t('unknown')}
                                    disabled={submitting}
                                />
                            </div>

                            <div className="col-12 radio-item">
                                <ParentSearchTypeRadio
                                    name="searchType"
                                    radioValue="PARENTS"
                                    label={t('potentialParent')}
                                    disabled={submitting}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 mt-1">
                                {isParentsTypeSelected && (
                                    <PotentialSecondParentsField
                                        Component={PotentialSecondParentsFieldArray}
                                        name="parentSampleIds"
                                        props={{error, disabled: submitting, parentFieldsCount}}
                                    />
                                )}
                            </div>
                        </div>
                    </Panel>
                </Ant.Col>
                <Ant.Col
                    xs={24}
                    md={6}
                >
                    <Button
                        disabled={!valid || pristine || submitting}
                        type="submit"
                        visuals="primary"
                        icon="searchOutlined"
                        block
                        loading={submitting}
                    >
                        {tCommon('search')}
                    </Button>
                </Ant.Col>
                <Ant.Col
                    xs={24}
                    md={6}
                >
                    <Button
                        disabled={pristine || submitting}
                        type="button"
                        visuals="default"
                        icon="deleteOutlined"
                        block
                        loading={submitting}
                        onClick={onReset}
                    >
                        {tCommon('resetButton')}
                    </Button>
                </Ant.Col>
            </Ant.Row>
        </Form>
    );
};

export const ParentSearchForm = memo(ParentSearchFormBase);
