import {Nullable} from '@fl/cmsch-fe-library';
import {useCallback} from 'react';
import {Opt, testRe} from 'ts-opt';

import {cinRegexGen} from '../api/gen/Cin';

export const useOnCinClick = (
    userLastOrder: (cin: Nullable<string>) => void,
): (value: Opt<string>) => void =>
    useCallback((
        value: Opt<string>,
    ) => {
        value.filter(testRe(cinRegexGen()))
            .onSome(userLastOrder);
    }, [userLastOrder]);
