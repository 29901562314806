import {TypedFieldArrayInjectedProps, Button} from '@fl/cmsch-fe-library';
import React, {Component, Fragment, ReactNode} from 'react';

import {Ant} from 'common/ant';
import {doubleGutter, gutter} from 'constants/general';
import {ErrorMessage} from 'status-messages';
import {tCommon} from 'translations';

import {ParentSearchFormValues} from '../ParentSearchForm/parent-search-form-values';
import {PotentialSecondParentActions} from '../PotentialSecondParentActions';

const minimumToAllowRemove = 2;

export interface PotentialSecondParentsFieldArrayProps {
    error: string;
    disabled: boolean;
    parentFieldsCount: number;
}

export class PotentialSecondParentsFieldArray extends Component<
        TypedFieldArrayInjectedProps<
            ParentSearchFormValues,
            'parentSampleIds'
        > &
        PotentialSecondParentsFieldArrayProps> {
    public render(): ReactNode {
        const {Fields, fields, error, disabled, parentFieldsCount} = this.props;

        return (
            <Fragment>
                {error && <ErrorMessage data={error} />}
                <Ant.Row
                    gutter={[gutter, doubleGutter]}
                    justify="end"
                >
                    {fields.map((item, id) => (
                        <Ant.Col
                            key={item}
                            xs={24}
                        >
                            <Ant.Row gutter={gutter}>
                                <Ant.Col
                                    xs={20}
                                    sm={21}
                                    xl={22}
                                >
                                    <Fields.Input
                                        // @ts-expect-error TODO repair typing of FieldArray
                                        name={`${item}.sampleId`}
                                        type="text"
                                        placeholder={tCommon('earTagPlaceholder')}
                                        disabled={disabled}
                                    />
                                </Ant.Col>
                                <Ant.Col
                                    xs={4}
                                    sm={3}
                                    xl={2}
                                >
                                    <PotentialSecondParentActions
                                        id={id}
                                        onRemove={this.onRemove}
                                        disabled={disabled || parentFieldsCount < minimumToAllowRemove}
                                    />
                                </Ant.Col>
                            </Ant.Row>
                        </Ant.Col>
                    ))}
                    <Ant.Col
                        xs={24}
                        lg={10}
                    >
                        <Button
                            onClick={this.onAddNewLine}
                            type="button"
                            visuals="primary"
                            icon="plusOutlined"
                            block
                            disabled={disabled}
                        >
                            {tCommon('addAnother')}
                        </Button>
                    </Ant.Col>
                </Ant.Row>
            </Fragment>
        );
    }

    private readonly onAddNewLine = (): void => {
        const {fields} = this.props;

        fields.push({sampleId: ''});
    };

    private readonly onRemove = (id: number): void => {
        const {fields} = this.props;

        fields.remove(id);
    };
}
