import {Button} from '@fl/cmsch-fe-library';
import React, {FC, memo, useEffect} from 'react';

import {LoaderWrapper} from 'common/layout';
import {useOurTranslation} from 'translations';
import {useForm} from 'utils/forms';

import {BreederInfoFormValues, breederInfoFormName} from './breeder-info-form-values';
import {validate} from './validations';

const initialValues: BreederInfoFormValues = {
    caption: null,
    content: null,
    show: null,
};

interface Props {
    loading: boolean;
    onGetBreederInfoConfiguration(): void;
    onSubmit(values: BreederInfoFormValues): void;
}

const BreederInfoFormBase: FC<Props> = props => {
    const {
        onGetBreederInfoConfiguration,
        onSubmit,
        loading,
    } = props;

    const {t} = useOurTranslation('dials/breederInfoForm');

    const {Form, Fields, valid, pristine, submitting, renderErrors} = useForm({
        form: breederInfoFormName,
        initialValues,
        validate,
        onSubmit,
    });

    useEffect(() => {
        onGetBreederInfoConfiguration();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <LoaderWrapper
            loading={loading}
            showContentWhileLoading
        >
            <Form>
                {renderErrors()}

                <div className="row">
                    <div className="col-12">
                        <Fields.Input
                            name="caption"
                            type="text"
                            label={t('caption')}
                            isRequired
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <Fields.TextEditor
                            key="content"
                            name="content"
                            id="content"
                            label={t('content')}
                            isRequired
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <Fields.Checkbox
                            name="show"
                            label={t('show')}
                        />
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-12">
                        <Button
                            type="submit"
                            visuals="primary"
                            block
                            disabled={!valid || pristine || submitting}
                        >
                            {t('save')}
                        </Button>
                    </div>
                </div>
            </Form>
        </LoaderWrapper>
    );
};

export const BreederInfoForm = memo(BreederInfoFormBase);
