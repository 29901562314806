import {
    extractFormErrorsFromResponse,
    Nullable,
    putAll,
    showSuccess,
    showError,
    takeLatestF,
} from '@fl/cmsch-fe-library';
import * as routerActions from 'redux-first-history';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {NewOrder} from 'api/gen/NewOrder';
import {transformFormToAddress} from 'orders/utils/transform-address';
import {t} from 'translations';
import {formHelpers} from 'utils/forms';
import {showBeError} from 'utils/show-be-error';

import {NewOrderFormValues} from '../../components/NewForm/new-order-form-values';
import {CreateOrderAction, orderAction} from '../action';

// eslint-disable-next-line max-lines-per-function
const parseValues = (values: NewOrderFormValues): Nullable<NewOrder> => {
    const {animalDetails, barcode, customerNote, orderTypeIds, sampleTypeId, userDetails} = values;
    if (animalDetails && orderTypeIds !== null && sampleTypeId !== null && userDetails) {
        const {
            breed,
            dob,
            fathersRegistry,
            fathersSampleId,
            mothersSampleId,
            name,
            registry,
            sampleId,
            sex,
            twin,
            fullName,
        } = animalDetails;
        const {
            address,
            billingAddress,
            customerName,
            vatNumber,
            email,
            firstname,
            id,
            lastname,
            phone,
            cin,
            hasSameAddresses,
        } = userDetails;
        const addressParsed = transformFormToAddress(address);
        const billingAddressParsed = billingAddress && !hasSameAddresses
            ? transformFormToAddress(billingAddress)
            : addressParsed;
        if (
            breed
            && sampleId
            && sex
            && customerName
            && email
            && firstname
            && lastname
            && phone
            && addressParsed
            && billingAddressParsed
        ) {
            return {
                animalDetails: {
                    breed,
                    dob,
                    earTag: sampleId,
                    fatherEarTag: fathersSampleId,
                    fatherLinReg: fathersRegistry,
                    lineRegistry: registry,
                    motherEarTag: mothersSampleId,
                    name,
                    sex,
                    twin: Boolean(twin),
                    fullName,
                },
                barcode,
                customerNote,
                orderTypeIds,
                sampleTypeId,
                userDetails: {
                    address: addressParsed,
                    billingAddress: billingAddressParsed,
                    customerName,
                    cin,
                    email,
                    firstName: firstname,
                    id,
                    lastName: lastname,
                    phone,
                    vatNumber,
                },
            };
        }
    }

    return null;
};

const te = t('orders/ListActions');

function* execute(action: CreateOrderAction): SagaIterator {
    const {values} = action.payload;

    const order = parseValues(values);
    if (!order) {
        yield* put(showError(
            t('orders/sagas')('missingDataTitle'),
            t('orders/sagas')('missingData'),
        ));

        return;
    }

    yield* put(formHelpers.startSubmit('newOrder'));

    const response = yield* call(Api.createOrder, order);

    if (response.isSuccess) {
        yield* put(showSuccess(te('createNew'), t('orders/sagas')('success')));
        yield* put(formHelpers.stopSubmit('newOrder'));
        yield* put(orderAction.setUserDetailsFromCIN(null));
        yield* put(formHelpers.reset('newOrder'));
        yield* put(routerActions.push('/'));
    } else {
        yield putAll(showBeError(response, te('createNew')));
        yield* put(formHelpers.stopSubmit('newOrder', extractFormErrorsFromResponse(response)));
    }
}

export function* createOrderSaga(): SagaIterator {
    yield takeLatestF('order/CREATE_ORDER', execute);
}
