import {Options, FooterButtons} from '@fl/cmsch-fe-library';
import React, {FC, memo, useMemo} from 'react';

import {useOurTranslation} from 'translations';
import {useForm} from 'utils/forms';

import {OrderTypesFormSection, OrderTypesFormSectionOuterProps} from './order-types-form-section';
import {
    bulkEditTypesFormName,
    OrdersTypesFormValues,
    OrderTypesActionType,
    orderTypesInitialValues,
} from './order-types-form-values';
import {validate} from './validations';

const initialValues: OrdersTypesFormValues = {
    ordersTypes: orderTypesInitialValues,
    editType: 'add',
};

interface Props {
    orderTypes: Options<number>;
    onClose(): void;
    onSubmit(values: OrdersTypesFormValues): void;
}

const BulkOrderTypesFormBase: FC<Props> = props => {
    const {
        orderTypes,
        onClose,
        onSubmit,
    } = props;

    const {t} = useOurTranslation('common');

    const {Form, Fields} = useForm({
        form: bulkEditTypesFormName,
        destroyOnUnmount: true,
        forceUnregisterOnUnmount: true,
        initialValues,
        validate,
        onSubmit,
    });

    const OrderTypesSectionField = Fields.genSection<'ordersTypes', OrderTypesFormSectionOuterProps>();
    const orderTypesProps = useMemo(() => ({
        orderTypes,
    }), [orderTypes]);

    const editTypeOptions = useMemo((): Options<OrderTypesActionType> => [
        {value: 'add', label: t('add')},
        {value: 'remove', label: t('remove')},
    ], [t]);

    return (
        <div data-test-id="bulk-edit-types-order-form">
            <Form>
                <Fields.StringRadioGroup
                    name="editType"
                    label={t('editType')}
                    options={editTypeOptions}
                    layout="horizontal"
                />
                <OrderTypesSectionField
                    name="ordersTypes"
                    Component={OrderTypesFormSection}
                    props={orderTypesProps}
                />

                <FooterButtons
                    onCancel={onClose}
                    submitTitle={t('edit')}
                />
            </Form>
        </div>
    );
};

export const BulkOrderTypesForm = memo(BulkOrderTypesFormBase);
