import {putAll, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {showSuccessSimple} from 'notifications';
import {t} from 'translations';
import {showBeError} from 'utils/show-be-error';

import {animalsAction, SendOriginInconsistencyReport} from '../action';

const translateMessage = t('animals/OriginInconsistenciesTable');

function* execute({payload: {originInconsistencyId}}: SendOriginInconsistencyReport): SagaIterator {
    yield* put(animalsAction.setInconsistenciesActionActive(originInconsistencyId, true));

    const response = yield* call(Api.setCorrectOriginToIGenetika, {originInconsistencyId});

    if (response.isSuccess) {
        yield* put(showSuccessSimple({message: translateMessage('messages.emailSent')}));
        yield* put(animalsAction.getOriginInconsistencies(opt({action: 'refresh'})));
    } else {
        yield putAll(showBeError(response, translateMessage('messages.emailError')));
    }

    yield* put(animalsAction.setInconsistenciesActionActive(originInconsistencyId, false));
}

export function* sendOriginInconsistencyReportSaga(): SagaIterator {
    yield takeLatestF('animals/SEND_ORIGIN_INCONSISTENCY_REPORT', execute);
}
