import {classNames} from '@fl/cmsch-fe-library';
import React, {FC, memo} from 'react';

import {SvgImage} from 'images';

import styles from './styles.sass';

interface Props {
    image: string;
    title: string;
}

const PanelTitleWithImageBase: FC<Props> = props => {
    const {image, title} = props;

    return (
        <div className="d-flex justify-content-center">
            <span className={classNames('d-inline-block mr-3', styles.imageSmall)}>
                <SvgImage
                    image={image}
                />
            </span>
            <span className="d-flex align-items-center">
                {title}
            </span>
        </div>
    );
};

export const PanelTitleWithImage = memo(PanelTitleWithImageBase);
