// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';
import {StringPatternSchema} from '@fl/cmsch-fe-library';


// Ear tag is unique 14 characters long animal's sign consisting from 2-letter country code and 12 numeric characters.
// Example: "CZ000326649931"

export const earTagRegexGen = (): RegExp => new RegExp("^([A-Z]{2})((?!000000000000)[0-9]{12})$");

export const EarTagSchema = StringPatternSchema<EarTag>(earTagRegexGen());

export type EarTag = string;
