// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { ParentMatch, ParentMatchSchema } from 'api/gen/ParentMatch';

const requiredPart = t.interface({
  maternitySettings: t.union([ParentMatchSchema, t.null]),
  paternitySettings: t.union([ParentMatchSchema, t.null]),
  value: t.string,
});

export const NewVerificationOutcomeSchema = excess(requiredPart);

export interface NewVerificationOutcome extends t.TypeOf<typeof NewVerificationOutcomeSchema> {}
