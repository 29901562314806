import {debounce} from 'lodash/fp';
import React, {FC, memo, useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {Opt} from 'ts-opt';

import {CategoryDetails} from 'api/gen/CategoryDetails';
import {FooterButtons} from 'common/buttons';
import {debounceTime} from 'constants/general';
import {useOurTranslation} from 'translations';
import {useForm} from 'utils/forms';

import {snpActions} from '../../model';
import {CreateSnpFormValues, createSnpFormName} from './create-snp-form-values';
import {validate} from './validations';

const initialValues: CreateSnpFormValues = {
    name: null,
    categoryId: null,
};

interface Props {
    isEdit?: boolean;
    categories: Array<CategoryDetails>;
    onCancel?(): void;
    onSubmit(values: CreateSnpFormValues): void;
}

const CreateSnpFormBase: FC<Props> = props => {
    const {isEdit, categories, onCancel, onSubmit} = props;
    const {t} = useOurTranslation('snpManagement/SnpTable');
    const dispatch = useDispatch();

    const {Form, Fields, valid, pristine} = useForm({
        form: createSnpFormName,
        initialValues,
        destroyOnUnmount: true,
        validate,
        onSubmit,
    });

    const optionsCategories = categories.map(category => ({
        label: category.name,
        value: category.id,
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedNameCall = useCallback(
        debounce(debounceTime, (name: string) => dispatch(snpActions.validateSnpName(name))),
        [dispatch],
    );

    const validateValue = useCallback((value: Opt<string>) => value.onSome(debouncedNameCall), [debouncedNameCall]);

    return (
        <Form>
            <div className="row">
                <div className="col-12">
                    <Fields.Input
                        name="name"
                        type="text"
                        label={t('formTranslations.snpName')}
                        isRequired
                        onFieldChange={validateValue}
                    />

                </div>
            </div>
            <div className="row">
                <div className="col-12 positi">
                    <Fields.NumberSelect
                        name="categoryId"
                        label={t('formTranslations.name')}
                        options={optionsCategories}
                        isRequired
                        searchable={false}
                        clearable={false}
                    />
                </div>
            </div>
            <FooterButtons
                editing={isEdit}
                disabledSubmit={!valid || pristine}
                onCancel={onCancel}
            />
        </Form>
    );
};

export const CreateSnpForm = memo(CreateSnpFormBase);
