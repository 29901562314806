import {join, map} from 'lodash/fp';
import {pipe} from 'ts-opt';

import {Haplotype} from 'api/gen/Haplotype';
import {HaplotypeCouple} from 'api/gen/HaplotypeCouple';
import {MatchedMicrosatellite} from 'api/gen/MatchedMicrosatellite';

const getHaplotypeItem = (x: Haplotype): string => `${x.value} ${x.alleles}`;

const getHaplotypeCouple = (x: HaplotypeCouple): string =>
    `${getHaplotypeItem(x.first)}\n${getHaplotypeItem(x.second)}\n`;

const getHaplotypeCouples = (x: Array<HaplotypeCouple> | null): string =>
    pipe(
        x,
        map(getHaplotypeCouple),
        join('\n'),
    );

const generateMicrosatellitesText = (microsatellites: Array<MatchedMicrosatellite> | null): string =>
    pipe(
        microsatellites,
        map(x => `${x.microsatellite}\n${getHaplotypeCouples(x.haplotypeCouples)}`),
        join('\n'),
    );

export const downloadMicrosatellites = (
    microsatellites: Array<MatchedMicrosatellite> | null,
    filename: string,
): void => {
    const text = generateMicrosatellitesText(microsatellites);
    const elem = window.document.createElement('a');
    const blob = new Blob([text], {type: 'text/plain'});

    elem.href = window.URL.createObjectURL(blob);
    elem.download = filename;
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);
};
