import {Button} from '@fl/cmsch-fe-library';
import React, {FC, memo, useCallback} from 'react';

import {tCommon} from 'translations';

interface Props {
    id: number;
    disabled: boolean;
    onRemove(id: number): void;
}

const PotentialSecondParentActionsBase: FC<Props> = props => {
    const {id, disabled, onRemove} = props;

    const handleRemove = useCallback(() => onRemove(id), [id, onRemove]);

    return (
        <Button
            onClick={handleRemove}
            type="button"
            danger
            visuals="primary"
            icon="deleteFilled"
            disabled={disabled}
            tooltip={tCommon('delete')}
            block
        />
    );
};

export const PotentialSecondParentActions = memo(PotentialSecondParentActionsBase);
