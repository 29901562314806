import {classNames, formatProbability} from '@fl/cmsch-fe-library';
import React, {FC, memo} from 'react';

import {ParenthoodVerification} from 'api/gen/ParenthoodVerification';
import {useOurTranslation} from 'translations';

import {PaternityCheckTable} from '../PaternityCheckTable';

import styles from './styles.sass';

interface Props {
    result: ParenthoodVerification;
}

const PaternityCheckResultBase: FC<Props> = props => {
    const {result} = props;

    const {t} = useOurTranslation('paternityCheck/PaternityCheckResult');
    const accordClasses = classNames(
        'col-12',
        'col-md-3',
        styles.resultsheading,
        styles.roundcornerstop,
        'd-flex',
        'align-items-center',
        'justify-content-center',
    );
    const partNumberClasses = classNames(
        'col-12',
        'col-md-4',
        styles.resultsheading,
        styles.roundcornerstop,
        'd-flex',
        'align-items-center',
        'justify-content-center',
    );

    return (
        <div className="w-100 px-3">
            <div className="row d-flex justify-content-between">
                <div className={accordClasses}>
                    <div
                        className="d-inline-block my-2 mr-2"
                    >
                        {t('accord')}:
                    </div>
                    <div className={classNames('d-inline-block font-weight-bold', styles.font120)}>
                        {formatProbability(result.probability)}%
                    </div>
                </div>
                <div className={partNumberClasses}>
                    <div
                        className="d-inline-block my-2 mr-2"
                    >
                        {t('parentNumber')}:
                    </div>
                    <div className={classNames('d-inline-block font-weight-bold', styles.font120)}>
                        {result.parentSampleId}
                    </div>
                </div>
                <div className={accordClasses}>
                    <div
                        className="d-inline-block my-2 mr-2"
                    >
                        {t('processedGenesNumber')}:
                    </div>
                    <div className={classNames('d-inline-block font-weight-bold', styles.font120)}>
                        {result.numOfProcessedGenes}
                    </div>
                </div>
            </div>
            <div className={classNames('row', styles.resultswrapper, 'py-3', styles.roundcornersbottom, 'p-3')}>
                <div className="w-100">
                    <div className="mb-3">
                        {t('differences')}:
                    </div>
                    {result.differences && <PaternityCheckTable differences={result.differences} />}
                </div>
            </div>
        </div>
    );
};

export const PaternityCheckResult = memo(PaternityCheckResultBase);
