import {t} from 'translations';
import {PaternityValidator} from 'utils/paternity-validator';
import {Errors} from 'utils/validator';

import {TestResultFormValues} from './test-result-form-values';

export const validate = (values: TestResultFormValues): Errors<TestResultFormValues> => {
    const validator = new PaternityValidator(values);

    validator.nonEmpty('value', t('dials/testResults')('value'));

    return validator.generateErrorsObject();
};
