// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// The description of the e-mail template.
// Example: "Email informuje chovatele o aktualizacích jeho objednávek"

export const emailTemplateDescriptionMinLength = 1;
export const emailTemplateDescriptionMaxLength = 255;

export const EmailTemplateDescriptionSchema = t.refinement(t.string, n => n.length >= emailTemplateDescriptionMinLength && n.length <= emailTemplateDescriptionMaxLength, 'EmailTemplateDescription');

export type EmailTemplateDescription = string;
