import {Nullable} from '@fl/cmsch-fe-library';
import {isUndefined, startsWith} from 'lodash/fp';
import React, {FC, useEffect} from 'react';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {Opt} from 'ts-opt';

import {CodeTableItem} from 'api/gen/CodeTableItem';
import {LastOrderUserDetails} from 'api/gen/LastOrderUserDetails';
import {OrderAnimalDetails} from 'api/gen/OrderAnimalDetails';
import {OrderCustomerDetails} from 'api/gen/OrderCustomerDetails';
import {User} from 'api/gen/User';
import {Action} from 'app/actions';
import {State} from 'app/state';
import {dialsAction} from 'dials';
import {SideMenu, Container, Panel, MainPanel, PageHeading} from 'layout';
import {useOurTranslation} from 'translations';
import {useUser} from 'user';
import {codeTableItemsToOptions} from 'utils/code-table-items-to-options';
import {formHelpers} from 'utils/forms';

import {NewForm} from '../components/NewForm';
import {newOrderInitialValues, NewOrderFormValues} from '../components/NewForm/new-order-form-values';
import {orderAction} from '../model';
import {Parent} from '../types/parent';
import {ValidateAnimal} from '../types/validate-animal-type';
import {transformBEAnimalDetailsToForm} from '../utils/transform-animal-details';

interface StateProps {
    breeders: Opt<Array<User>>;
    customers: Opt<Array<OrderCustomerDetails>>;
    motherAlreadyAnalyzed: boolean;
    fatherAlreadyAnalyzed: boolean;
    newOrderFormValues: Opt<NewOrderFormValues>;
    orderTypes: Opt<Array<CodeTableItem>>;
    sampleTypes: Opt<Array<CodeTableItem>>;
    foundAnimal: Opt<OrderAnimalDetails>;
    userDetailsFromCIN: Opt<LastOrderUserDetails>;
    isHolstein: boolean;
    isNewOrderAnimalValid: boolean;
    newOrderEarNumberWarning: Opt<string>;
}

interface DispatchProps {
    createOrder(values: NewOrderFormValues): void;
    getUserLastOrder(cin: Nullable<string>): void;
    validateAnimal(sampleId: Nullable<string>, registry: Nullable<string>, type: ValidateAnimal): void;
    getSampleTypes(): void;
    getOrderTypes(): void;
    getLaboratoryNumberBySampleId(sampleId: string, parent: Parent): void;
    prefillUserDetailsFromExistingOrder(user: OrderCustomerDetails): void;
    getOrdersCustomers(): void;
    getBreeders(): void;
    prefillByHolstein(sampleTypeId: Nullable<number>, orderTypeIds: Array<number>): void;
    setAnimalDetailsFormValues(animal: OrderAnimalDetails): void;
    resetAnimal(): void;
    initializeNewOrderForm(): void;
    checkParentRegistry(registry: string): void;
}

type Props = StateProps & DispatchProps;

const holsteinsSampleTypeId = 4;
/* eslint-disable-next-line no-magic-numbers */
const holsteinsOrderTypesIds = [1, 4];

const getHolsteinInitialValues = (
    orderTypes: Opt<Array<CodeTableItem>>,
    sampleTypes: Opt<Array<CodeTableItem>>,
): {sampleTypeId: Nullable<number>, orderTypeIds: Array<number>} => {
    const holsteinsSampleType = sampleTypes.orUndef()?.find(type => type.id === holsteinsSampleTypeId);
    const holsteinsOrderTypes = orderTypes.orElse([]).filter(type => holsteinsOrderTypesIds.includes(type.id));

    return {
        sampleTypeId: isUndefined(holsteinsSampleType) ? null : holsteinsSampleType.id,
        orderTypeIds: holsteinsOrderTypes.map(x => x.id),
    };
};

const NewBase: FC<Props> = props => {
    const {
        breeders,
        customers,
        fatherAlreadyAnalyzed,
        newOrderFormValues,
        motherAlreadyAnalyzed,
        orderTypes,
        sampleTypes,
        foundAnimal,
        userDetailsFromCIN,
        isHolstein,
        isNewOrderAnimalValid,
        newOrderEarNumberWarning,
        getLaboratoryNumberBySampleId,
        validateAnimal,
        getBreeders,
        getUserLastOrder,
        getSampleTypes,
        getOrderTypes,
        getOrdersCustomers,
        prefillUserDetailsFromExistingOrder,
        createOrder,
        prefillByHolstein,
        setAnimalDetailsFormValues,
        resetAnimal,
        initializeNewOrderForm,
        checkParentRegistry,
    } = props;

    const {t} = useOurTranslation('orders/screenNew');

    const {isRoleLaborer} = useUser();

    const hasSameAddresses = newOrderFormValues.prop('userDetails').prop('hasSameAddresses').orFalse();
    const noCin = newOrderFormValues.prop('userDetails').prop('noCin').orFalse();

    useEffect(() => {
        resetAnimal();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        getSampleTypes();
    }, [getSampleTypes]);
    useEffect(() => {
        getOrderTypes();
    }, [getOrderTypes]);
    useEffect(() => {
        getOrdersCustomers();
    }, [getOrdersCustomers]);
    useEffect(() => {
        if (isHolstein) {
            const {sampleTypeId, orderTypeIds} = getHolsteinInitialValues(orderTypes, sampleTypes);
            prefillByHolstein(sampleTypeId, orderTypeIds);
        }
    }, [isHolstein, orderTypes, prefillByHolstein, sampleTypes]);
    useEffect(() => {
        foundAnimal.onBoth(setAnimalDetailsFormValues, initializeNewOrderForm);
    }, [foundAnimal, initializeNewOrderForm, setAnimalDetailsFormValues]);

    return (
        <Container>
            <MainPanel>
                <div className="row">
                    <div className="col-12">
                        <PageHeading>
                            <h1>{t('caption')}</h1>
                        </PageHeading>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-lg-9 order-2 order-lg-1">
                        <Panel>
                            <NewForm
                                formValues={newOrderFormValues.orElse(newOrderInitialValues)}
                                sampleTypes={codeTableItemsToOptions(sampleTypes)}
                                orderTypes={codeTableItemsToOptions(orderTypes)}
                                onCreateOrder={createOrder}
                                hasSameAddresses={hasSameAddresses}
                                onGetUserLastOrder={getUserLastOrder}
                                onValidateAnimal={validateAnimal}
                                onGetLaboratoryNumberBySampleId={getLaboratoryNumberBySampleId}
                                motherAlreadyAnalyzed={motherAlreadyAnalyzed}
                                fatherAlreadyAnalyzed={fatherAlreadyAnalyzed}
                                onPrefillUserDetailsFromExistingOrder={prefillUserDetailsFromExistingOrder}
                                customers={customers.orElse([])}
                                showCreatedBy={isRoleLaborer}
                                onGetBreeders={getBreeders}
                                breeders={breeders.orElse([])}
                                userDetailsFromCIN={userDetailsFromCIN.orNull()}
                                noCin={noCin}
                                isNewOrderAnimalValid={isNewOrderAnimalValid}
                                newOrderEarNumberWarning={newOrderEarNumberWarning}
                                onCheckParentRegistry={checkParentRegistry}
                            />
                        </Panel>
                    </div>
                    <div className="col-12 col-lg-3 order-1 order-lg-2 mb-4 mb-lg-0">
                        <SideMenu title={t('menuCaption')}>
                            <div>{t('description.one')}</div>
                            <div>{t('description.two')}</div>
                            <div>{t('description.three')}</div>
                            <div>{t('description.four')}</div>
                        </SideMenu>
                    </div>
                </div>
            </MainPanel>
        </Container>
    );
};

const getIsHolstein = (breed: Opt<string>): boolean => breed.map(startsWith('H')).orFalse();

const mapStateToProps = (state: State): StateProps => ({
    breeders: state.orders.breeders,
    customers: state.orders.customers,
    motherAlreadyAnalyzed: state.orders.motherAlreadyAnalyzed,
    fatherAlreadyAnalyzed: state.orders.fatherAlreadyAnalyzed,
    newOrderFormValues: formHelpers.formValues('newOrder')(state),
    orderTypes: state.dials.orderTypes,
    sampleTypes: state.dials.sampleTypes,
    foundAnimal: state.orders.foundAnimal,
    userDetailsFromCIN: state.orders.userDetailsFromCIN,
    isHolstein: getIsHolstein(formHelpers.formValues('newOrder')(state).prop('animalDetails').prop('breed')),
    isNewOrderAnimalValid: state.orders.isNewOrderAnimalValid,
    newOrderEarNumberWarning: state.orders.newOrderEarNumberWarning,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    createOrder: (values: NewOrderFormValues): Action => dispatch(orderAction.createOrder(values)),
    getUserLastOrder: (cin: Nullable<string>): Action =>
        dispatch(orderAction.getUserLastOrder(cin, 'newOrder')),
    validateAnimal: (sampleId: Nullable<string>, registry: Nullable<string>, type: ValidateAnimal): Action =>
        dispatch(orderAction.validateAnimal(sampleId, registry, type)),
    getSampleTypes: (): Action => dispatch(dialsAction.getSampleTypes()),
    getOrderTypes: (): Action => dispatch(dialsAction.getOrderTypes()),
    getLaboratoryNumberBySampleId: (sampleId: Nullable<string>, parent: Parent): Action =>
        dispatch(orderAction.getLaboratoryNumberBySampleId(sampleId, parent)),
    prefillUserDetailsFromExistingOrder: (user: OrderCustomerDetails): void => {
        const {address, cin, customerName, email, firstname, lastname, phone, vatNumber} = user;
        dispatch(orderAction.setUserDetailsFormValue(user));
        dispatch(orderAction.setUserDetailsFromCIN({
            address,
            billingAddress: null,
            cin,
            customerName,
            email,
            firstName: firstname,
            id: null,
            lastName: lastname,
            phone,
            vatNumber,
        }));
    },
    getOrdersCustomers: (): Action => dispatch(orderAction.getOrdersCustomers()),
    getBreeders: (): Action => dispatch(orderAction.getBreeders()),
    prefillByHolstein: (sampleTypeId: Nullable<number>, orderTypeIds: Array<number>): Action =>
        dispatch(orderAction.prefillByHolstein(sampleTypeId, orderTypeIds)),
    setAnimalDetailsFormValues: (animal: OrderAnimalDetails): Action =>
        dispatch(formHelpers.change('newOrder', 'animalDetails', transformBEAnimalDetailsToForm(animal))),
    resetAnimal: (): void => {
        dispatch(orderAction.animalsSearchSuccess(null));
        dispatch(orderAction.setIsNewOrderAnimalValid(false));
        dispatch(orderAction.setNewOrderEarNumberWarning(null));
        dispatch(orderAction.resetParentsAlreadyAnalyzed());
    },
    initializeNewOrderForm: (): Action =>
        dispatch(formHelpers.initialize(
            'newOrder',
            newOrderInitialValues,
        )),
    checkParentRegistry: (registry: string): Action => dispatch(orderAction.checkParentRegistry(registry)),
});

export const New = connect(mapStateToProps, mapDispatchToProps)(NewBase);
