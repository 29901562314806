// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { YearMonth, YearMonthSchema } from 'api/gen/YearMonth';

const requiredPart = t.interface({
  percentage: t.number,
  yearMonth: YearMonthSchema,
});

export const PointSchema = excess(requiredPart);

export interface Point extends t.TypeOf<typeof PointSchema> {}
