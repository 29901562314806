// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveLong, PositiveLongSchema } from 'api/gen/PositiveLong';

const requiredPart = t.interface({
  code: t.union([t.string, t.null]),
  idToExclude: t.union([PositiveLongSchema, t.null]),
  value: t.union([t.string, t.null]),
});

export const OrderTypeValidationSchema = excess(requiredPart);

export interface OrderTypeValidation extends t.TypeOf<typeof OrderTypeValidationSchema> {}
