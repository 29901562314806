import {classNames} from '@fl/cmsch-fe-library';
import {isNull} from 'lodash/fp';
import React, {FC} from 'react';

import {ParentMatch} from 'api/gen/ParentMatch';
import {SvgIcon} from 'icons';
import {useOurTranslation} from 'translations';

import {getLowercaseParentMatch} from '../../utils/get-lowercase-parent-match';

import styles from './styles.sass';

import dotSvg from './dot.svg';

interface Props {
    paternity: ParentMatch | null;
}

export const PaternityDot: FC<Props> = props => {
    const {paternity} = props;
    const {t} = useOurTranslation('animals/PaternityDot');
    const iconProps = classNames(
        styles.stateIcon,
        paternity === 'MATCHES' && styles.paternityMatches,
        paternity === 'MISMATCHES' && styles.paternityMismatches,
        paternity === 'MISSING' && styles.paternityMissing,
        isNull(paternity) && styles.paternityNone,
        styles.paternityPlain,
    );

    return (
        <span
            data-test-id={`paternity-dot-${paternity ?? 'none'}`}
            className={iconProps}
            title={paternity ? t(`paternity.${getLowercaseParentMatch(paternity)}`) : ''}
        >
            <SvgIcon icon={dotSvg} />
        </span>
    );
};
