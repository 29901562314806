import {classNames} from '@fl/cmsch-fe-library';
import {isEmpty} from 'lodash/fp';
import React, {FC, useCallback, memo} from 'react';
import {FileRejection, useDropzone} from 'react-dropzone';

import {Ant} from 'common/ant';
import {LoaderWrapper} from 'common/layout';
import {TFunction} from 'translations';

import {ImportScreenType} from '../../types/import-screen-type';

import styles from './styles.sass';

// eslint-disable-next-line no-magic-numbers
const maxFileSize = 300 * 1024 * 1024; // 300 MiB

interface Props {
    importType: ImportScreenType;
    isLoading: boolean;

    t: TFunction<'fileImport/screenImport'>;
    sendImport(data: File, importType?: ImportScreenType): void;
    showErrorMessage(message: string): void;
}

const ImportDropzoneBase: FC<Props> = props => {
    const {
        sendImport,
        showErrorMessage,
        isLoading,
        t,
        importType,
    } = props;

    const onDrop = useCallback((acceptedFiles: Array<File>, fileRejections: Array<FileRejection>) => {
        if (!isEmpty(acceptedFiles)) {
            try {
                sendImport(acceptedFiles[0], importType);
            } catch (err) {
                showErrorMessage(t('fileReadingErrorMessage'));
            }
        }

        const rejectedFiles = fileRejections.map(x => x.file);

        if (!isEmpty(rejectedFiles)) {
            showErrorMessage(t('rejectedMessage'));
            // eslint-disable-next-line no-console
            console.error(rejectedFiles);
        }
    }, [sendImport, showErrorMessage, t, importType]);

    const {getInputProps, getRootProps} = useDropzone({multiple: false, onDrop, maxSize: maxFileSize});
    const inputProps = getInputProps();
    const rootProps = getRootProps();

    return (
        <Ant.Row justify="center">
            <Ant.Col
                xs={24}
                md={18}
                lg={20}
            >
                <div
                    {...rootProps} // eslint-disable-line react/jsx-props-no-spreading
                    className={classNames(styles.dropboxContainer, 'my-4')}
                >
                    <input
                        {...inputProps} // eslint-disable-line react/jsx-props-no-spreading
                    />
                    <LoaderWrapper loading={isLoading}>
                        <div className="py-5 px-1 text-center">
                            <Ant.Row>
                                <Ant.Col xs={24}>
                                    {t('loadFile')}
                                </Ant.Col>
                                <Ant.Col xs={24}>
                                    <h4 className="mt-5">
                                        {t('fileFormats')}
                                    </h4>
                                </Ant.Col>
                                <Ant.Col xs={24}>
                                    <h4>
                                        {t('maxSize')}
                                    </h4>
                                </Ant.Col>
                            </Ant.Row>
                        </div>
                    </LoaderWrapper>
                </div>
            </Ant.Col>
        </Ant.Row>
    );
};

export const ImportDropzone = memo(ImportDropzoneBase);
