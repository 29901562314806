import {takeLatestF, ErrorsFromBE, ErrorResponse} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {put, call} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {formHelpers} from 'utils/forms';
import {Errors} from 'utils/validator';

import {NewOrderFormValues} from '../../components/NewForm/new-order-form-values';
import {idFromBeSuggestion} from '../../utils/transform-animal-details';
import {AnimalsSearchAction, orderAction} from '../action';

function buildFormErrors(response: ErrorResponse): Errors<NewOrderFormValues> {
    const errors = response.data as ErrorsFromBE;

    return {animalDetails: {sampleId: errors[0].message}};
}

const getErrorMessage = (response: ErrorResponse): string => {
    const errors = response.data as ErrorsFromBE;

    return errors[0].message;
};

function* execute(action: AnimalsSearchAction): SagaIterator {
    const {registry, sampleId, barcode} = action.payload;

    const response = yield* call(Api.findAnimal, {sampleId, registry});
    yield* put(orderAction.isValidating(false));

    if (response.isSuccess) {
        yield* put(orderAction.animalsSearchSuccess(response.data));
        yield* put(formHelpers.stopAsyncValidation('newOrder'));
    } else {
        yield* put(orderAction.animalsSearchSuccess(null));
        if (response.isNotFound || response.isMisdirectedRequest) {
            yield* put(orderAction.setNewOrderEarNumberWarning(getErrorMessage(response)));
            yield* put(formHelpers.stopAsyncValidation('newOrder'));
            yield* put(formHelpers.resetSection('newOrder', 'animalDetails'));
            yield* put(formHelpers.changeValueInSection(
                'newOrder', 'animalDetails', 'sampleId', sampleId,
            ));
            yield* put(formHelpers.changeValueInSection(
                'newOrder', 'animalDetails', 'id', idFromBeSuggestion,
            ));
            yield* put(formHelpers.changeValueInSection(
                'newOrder', 'animalDetails', 'isEditing', false,
            ));
            if (barcode) {
                yield* put(formHelpers.change('newOrder', 'barcode', barcode));
            }
        } else {
            yield* put(formHelpers.stopAsyncValidation('newOrder', buildFormErrors(response)));
        }
    }
}

export function* searchAnimalsSaga(): SagaIterator {
    yield takeLatestF('order/ANIMALS_SEARCH', execute);
}
