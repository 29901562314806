// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.75.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.75.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-icons-components-svg-icon-___styles__svgIcon___JhA63{vertical-align:middle}.src-modules-icons-components-svg-icon-___styles__svgIcon___JhA63 svg{width:1.1rem;height:1.1rem}.src-modules-icons-components-svg-icon-___styles__svgIconLarge___zRUGj svg{width:2.1rem;height:2.1rem}.src-modules-icons-components-svg-icon-___styles__svgIconDisabled___pdmg3 svg{stroke:#ddd!important}.src-modules-icons-components-svg-icon-___styles__circleIcon___FSECN svg{width:3em;height:3em}\n", "",{"version":3,"sources":["webpack://./src/modules/icons/components/svg-icon/styles.sass"],"names":[],"mappings":"AAAA,kEAAS,qBAAA,CAAsB,sEAAa,YAAA,CAAa,aAAA,CAAc,2EAAkB,YAAA,CAAa,aAAA,CAAc,8EAAqB,qBAAA,CAAuB,yEAAgB,SAAA,CAAU,UAAA","sourcesContent":[".svgIcon{vertical-align:middle}.svgIcon svg{width:1.1rem;height:1.1rem}.svgIconLarge svg{width:2.1rem;height:2.1rem}.svgIconDisabled svg{stroke:#ddd !important}.circleIcon svg{width:3em;height:3em}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"svgIcon": "src-modules-icons-components-svg-icon-___styles__svgIcon___JhA63",
	"svgIconLarge": "src-modules-icons-components-svg-icon-___styles__svgIconLarge___zRUGj",
	"svgIconDisabled": "src-modules-icons-components-svg-icon-___styles__svgIconDisabled___pdmg3",
	"circleIcon": "src-modules-icons-components-svg-icon-___styles__circleIcon___FSECN"
};
export default ___CSS_LOADER_EXPORT___;
