import React, {FC} from 'react';
import {Link} from 'react-router-dom';

import {Ant} from 'common/ant';
import {Container, MainPanel} from 'layout';
import {useOurTranslation} from 'translations';

export const NotFound: FC = _ => {
    const {t} = useOurTranslation('common');

    return (
        <Container>
            <MainPanel>
                <div className="py-3">
                    <Ant.Empty description={t('pageNotFound')} >
                        <Link to="/" >{t('goToHomepage')}</Link>
                    </Ant.Empty>
                </div>
            </MainPanel>
        </Container>
    );
};
