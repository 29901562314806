// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { ParentMatch, ParentMatchSchema } from 'api/gen/ParentMatch';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveInteger, PositiveIntegerSchema } from 'api/gen/PositiveInteger';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveLong, PositiveLongSchema } from 'api/gen/PositiveLong';

const requiredPart = t.interface({
  id: PositiveLongSchema,
  index: PositiveIntegerSchema,
  maternitySettings: t.union([ParentMatchSchema, t.null]),
  paternitySettings: t.union([ParentMatchSchema, t.null]),
  value: t.string,
});

export const VerificationOutcomeSchema = excess(requiredPart);

export interface VerificationOutcome extends t.TypeOf<typeof VerificationOutcomeSchema> {}
