import {takeLatestF, putAll, downloadFileFromResponse} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'translations';
import {showBeError} from 'utils/show-be-error';

import {ExportGenotype} from '../action';

function* execute({payload: {animalId}}: ExportGenotype): SagaIterator {
    const response = yield* call(Api.getComputedGenotypes, {animalId});

    if (response.isSuccess) {
        yield* call(downloadFileFromResponse, response);
    } else {
        yield putAll(showBeError(response, t('animals/sagaMessages')('exportGenotypeError')));
    }
}

export function* exportGenotypeSaga(): SagaIterator {
    yield takeLatestF('animals/EXPORT_GENOTYPE', execute);
}
