import {putAll, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'translations';
import {showBeError} from 'utils/show-be-error';

import {DeleteProtocolAction, orderAction} from '../action';

function* execute({payload: {orderId}}: DeleteProtocolAction): SagaIterator {
    const response = yield* call(Api.deleteProtocol, {orderId});

    if (response.isSuccess) {
        yield* put(orderAction.getOrder(orderId));
    } else {
        yield putAll(showBeError(response, t('orders/DetailActions')('deleteProtocol')));
    }
}

export function* deleteProtocolSaga(): SagaIterator {
    yield takeLatestF('order/DELETE_PROTOCOL', execute);
}
