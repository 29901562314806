import {ItemType} from 'antd/lib/menu/hooks/useItems';
import React from 'react';
import {Link} from 'react-router-dom';

import {IconName, ICONS} from 'common/icons';
import {MenuItem} from 'layout/types/menu-item';
import {RoleName} from 'user/types/role-names';

interface RenderSubItemsProps {
    label: string;
    path: string;
    subItems: Array<MenuItem>;
    userRole: RoleName;
    icon?: IconName;
    onSubmenuClick(path: string): void;
}

const buildSubItems = ({label, onSubmenuClick, path, subItems, userRole, icon}: RenderSubItemsProps): ItemType => ({
    key: `mainItem/${path}`,
    icon: icon && ICONS[icon],
    label,
    onTitleClick: (): void => onSubmenuClick(path),
    children: subItems.filter(x => x.permissions.includes(userRole) && !x.hidden)
        .map(x => ({
            key: x.path,
            icon: x.icon && ICONS[x.icon],
            label: <Link to={x.path}>{x.label}</Link>,
        })),
});

export const buildMenuItems = (
    menuItems: Array<MenuItem>,
    userRole: RoleName,
    onSubmenuClick: (path: string) => void,
): Array<ItemType> =>
    menuItems.filter(x => x.permissions.includes(userRole) && !x.hidden)
        .map(x => x.subItems?.length
            ? buildSubItems({
                label: x.label,
                path: x.path,
                subItems: x.subItems,
                onSubmenuClick,
                icon: x.icon,
                userRole,
            })
            : {
                key: x.path,
                icon: x.icon && ICONS[x.icon],
                label: <Link to={x.path}>{x.label}</Link>,
            },
        );
