import {Options} from '@fl/cmsch-fe-library';

import {CorrectOrigin} from 'api/gen/CorrectOrigin';
import {tCommon} from 'translations';

export const getCorrectOriginOptions = (): Options<CorrectOrigin> => [
    {
        value: 'IGENETIKA',
        label: tCommon('correctOrigin.igenetika'),
    },
    {
        value: 'ESKOT',
        label: tCommon('correctOrigin.eskot'),
    },
];
