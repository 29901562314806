import {Opt} from 'ts-opt';

import {UserDetails} from 'api/gen/UserDetails';

export const userAction = {
    getCurrentUser: () => ({
        type: 'user/GET_CURRENT_USER',
    }) as const,
    getCurrentUserSuccess: (user: UserDetails) => ({
        type: 'user/GET_CURRENT_USER_SUCCESS',
        payload: {user},
    }) as const,
    logout: () => ({
        type: 'logout/LOGOUT',
    }) as const,
    setPreviousAndCurrentPathname: (previous: Opt<string>, current: Opt<string>) => ({
        type: 'user/SET_PREVIOUS_AND_CURRENT_PATHNAME',
        payload: {previous, current},
    }) as const,
};

export type GetCurrentUserAction = ReturnType<typeof userAction.getCurrentUser>;
export type GetCurrentUserSuccessAction = ReturnType<typeof userAction.getCurrentUserSuccess>;
export type LogoutAction = ReturnType<typeof userAction.logout>;
export type SetPreviousAndCurrentPathnameAction = ReturnType<typeof userAction.setPreviousAndCurrentPathname>;

export type UserAction =
    | GetCurrentUserAction
    | GetCurrentUserSuccessAction
    | LogoutAction
    | SetPreviousAndCurrentPathnameAction
;
