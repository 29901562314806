import {NullableKeys} from '@fl/cmsch-fe-library';

export const bulkEditOrderEmailFormName = 'bulkEditOrderEmail';

export type BulkEditOrderEmailFormValues = NullableKeys<{
    email: string,
}>;

export const bulkEditOrderEmailInitialValues: BulkEditOrderEmailFormValues = {
    email: null,
};
