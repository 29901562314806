import {putAll, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {put, call} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'translations';
import {showBeError} from 'utils/show-be-error';

import {animalsAction, RefreshFather} from '../action';

function* execute({payload: {params}}: RefreshFather): SagaIterator {
    yield* put(animalsAction.setLoading('refreshFatherInProgress', true));

    const response = yield* call(Api.findAnimal, params);

    if (response.isSuccess) {
        yield* put(animalsAction.refreshFatherSuccess(response.data));
    } else {
        yield putAll(showBeError(response, t('animals/sagaMessages')('fatherError')));
    }
    yield* put(animalsAction.setLoading('refreshFatherInProgress', false));
}

export function* refreshFatherSaga(): SagaIterator {
    yield takeLatestF('animals/REFRESH_FATHER', execute);
}
