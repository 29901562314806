import {TableDataRequest} from '@fl/cmsch-fe-library';
import React, {FC} from 'react';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {Opt} from 'ts-opt';

import {GenotypeExport} from 'api/gen/GenotypeExport';
import {Action} from 'app/actions';
import {GenotypeExportsTable} from 'genotypeExports/components/GenotypeExportsTable';
import {Container, MainPanel, PageHeading} from 'layout';
import {useOurTranslation} from 'translations';
import {useUser} from 'user';

import {genotypeExportsAction} from '../model';

interface DispatchProps {
    getGenotypeExports(tableDataRequest: Opt<TableDataRequest<GenotypeExport>>): void;
    processGenotypeExports(): void;
    exportGenotypeExport(id: number): void;
}

const ListBase: FC<DispatchProps> = props => {
    const {
        getGenotypeExports,
        processGenotypeExports,
        exportGenotypeExport,
    } = props;

    const {isMeatAssociation, isHolsteinAssociation} = useUser();
    const {t} = useOurTranslation('genotypeExports/screenList');

    return (
        <Container>
            <MainPanel>
                <div className="row">
                    <div className="col-12">
                        <PageHeading>
                            <h1>{t('title')}</h1>
                        </PageHeading>
                    </div>
                </div>

                <GenotypeExportsTable
                    proccessButtonEnabled={!isMeatAssociation && !isHolsteinAssociation}
                    getGenotypeExports={getGenotypeExports}
                    exportGenotypeExport={exportGenotypeExport}
                    processGenotypeExports={processGenotypeExports}
                />
            </MainPanel>
        </Container>
    );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    getGenotypeExports: (tableDataRequest: Opt<TableDataRequest<GenotypeExport>>): Action =>
        dispatch(genotypeExportsAction.getGenotypeExports(tableDataRequest)),
    processGenotypeExports: (): Action => dispatch(genotypeExportsAction.processGenotypeExports()),
    exportGenotypeExport: (id: number): Action => dispatch(genotypeExportsAction.exportGenotypeExport(id)),
});

export const List = connect(null, mapDispatchToProps)(ListBase);
