import {t} from 'translations';
import {PaternityValidator} from 'utils/paternity-validator';
import {Errors} from 'utils/validator';

import {validateUserDetails} from '../UserDetailsFormSection/validations';
import {NewBulkOrderFormValues} from './new-bulk-order-form-values';

const te = t('orders/OrderDetailsForm');

export const validate = (values: NewBulkOrderFormValues): Errors<NewBulkOrderFormValues> => {
    const validator = new PaternityValidator(values);

    validator.nonEmpty('sampleTypeId', te('sampleTypeId'));
    validator.nonEmpty('orderTypeIds', te('orderTypeIds'));

    const errors = validator.generateErrorsObject();

    if (values.userDetails) {
        errors.userDetails = validateUserDetails(values.userDetails, 'new');
    }

    return errors;
};
