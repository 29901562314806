import {ItemType} from 'antd/lib/menu/hooks/useItems';
import React, {FC, useCallback, useMemo} from 'react';

import {AntMenu} from 'common/ant';
import {TFunction} from 'translations';
import {formatNumberByCurrentLanguage} from 'utils/format-number-by-current-language';

export enum Option {
    filter = 'filter',
    filterWithoutSelected = 'filterWithoutSelected',
    selected = 'selected',
}

const maxRecords = 2000;

interface Props {
    filtered: number;
    selected: number;
    ignoreMaxLimit?: boolean;
    t: TFunction<'orders/ListActions'>;
    onOption(option: string): void;
}

const disabledTitle = (noRecordsLabel: string, overLimitLabel: string, overLimitRecords: boolean): string =>
    overLimitRecords ? overLimitLabel : noRecordsLabel;

export const DropdownOptions: FC<Props> = ({t, filtered, selected, ignoreMaxLimit, onOption}) => {
    const onClick = useCallback((value: {key: string | number}): void => {
        onOption(value.key.toString());
    }, [onOption]);

    const menuItems = useMemo((): Array<ItemType> => {
        const filteredOverLimit = filtered > maxRecords && !ignoreMaxLimit;
        const selectedOverLimit = selected > maxRecords && !ignoreMaxLimit;
        const filteredDisabled = filtered === 0 || filteredOverLimit;
        const selectedDisabled = selected === 0 || selectedOverLimit;
        const filteredWithoutSelectValue = filtered - selected;
        const filteredWithoutSelectedOverLimit = filteredWithoutSelectValue > maxRecords && !ignoreMaxLimit;
        const filteredWithoutSelectedDisabled = filteredWithoutSelectValue === 0 || filteredWithoutSelectedOverLimit;

        const numberFormatFn = formatNumberByCurrentLanguage()(0);

        return [
            {
                key: Option.filter,
                disabled: filteredDisabled,
                title: filteredDisabled
                    ? disabledTitle(t('noFiltered'), t('maxRecords'), filteredOverLimit) : undefined,
                label: t('filter', {total: numberFormatFn(filtered)}),
            },
            {
                key: Option.filterWithoutSelected,
                disabled: filteredWithoutSelectedDisabled,
                title: filteredWithoutSelectedDisabled
                    ? disabledTitle(t('noFiltered'), t('maxRecords'), filteredWithoutSelectedOverLimit) : undefined,
                label: t('filterWithoutSelected', {total: numberFormatFn(filteredWithoutSelectValue)}),
            },
            {
                key: Option.selected,
                disabled: selectedDisabled,
                title: selectedDisabled
                    ? disabledTitle(t('noSelected'), t('maxRecords'), selectedOverLimit) : undefined,
                label: t('selected', {total: numberFormatFn(selected)}),
            },
        ];
    }, [filtered, selected, ignoreMaxLimit, t]);

    return (
        <AntMenu
            onClick={onClick}
            items={menuItems}
        />
    );
};
