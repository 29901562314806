import {ItemType} from 'antd/lib/menu/hooks/useItems';
import React from 'react';
import {Link} from 'react-router-dom';

import {ICONS} from 'common/icons';
import {t} from 'translations';

export const buildUserSubMenu = (fullName: string): ItemType => ({
    key: 'user',
    icon: ICONS.userIcon,
    label: fullName,
    children: [
        {
            key: 'logout',
            icon: ICONS.logOutOutlined,
            label: <Link to="/logout">{t('layout/mainMenu')('logout')}</Link>,
        },
    ],
});
