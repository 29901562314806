// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.75.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.75.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n", "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"green": "#719041",
	"greenDark": "#57693b",
	"greenGaudy": "#b0d881",
	"greyDark": "#464646",
	"red": "#dc3545",
	"black": "#000",
	"white": "#fff",
	"primary": "#719041",
	"secondary": "#e1f1c7",
	"light": "#f7ffeb"
};
export default ___CSS_LOADER_EXPORT___;
