import {putAll, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {put, call} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'translations';
import {showBeError} from 'utils/show-be-error';

import {DeleteBillingBaseAction, orderAction} from '../action';

function* execute({payload: {orderId, orderTypeId}}: DeleteBillingBaseAction): SagaIterator {
    const response = yield* call(Api.deleteInvoicingDocuments, {orderId, orderTypeId});

    if (response.isSuccess) {
        yield* put(orderAction.getOrder(orderId));
    } else {
        yield putAll(showBeError(response, t('orders/ListActions')('deleteBillingBase')));
    }
}

export function* deleteBillingBaseSaga(): SagaIterator {
    yield takeLatestF('order/DELETE_BILLING_BASE', execute);
}
