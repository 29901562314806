// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.75.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.75.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-layout-components-footer-___styles__footer___nr0Ez{position:absolute;width:100%;bottom:0}.src-modules-layout-components-footer-___styles__footerContent___v2OXS{background-color:#fff;border-top:5px solid #719041;border-bottom:1px solid #719041}@media (min-width: 992px){.src-modules-layout-components-footer-___styles__footerContent___v2OXS{border-bottom:5px solid #719041}}@media (max-width: 575px){.src-modules-layout-components-footer-___styles__footerText___jNKCQ{font-size:.9em}}@media (min-width: 576px){.src-modules-layout-components-footer-___styles__footerText___jNKCQ{font-size:1em}}@media (min-width: 992px){.src-modules-layout-components-footer-___styles__footerText___jNKCQ{font-size:.9em}}.src-modules-layout-components-footer-___styles__facebook___iBkKT{color:#3b579d;font-size:37px}.src-modules-layout-components-footer-___styles__facebook___iBkKT:hover{color:#719041}.src-modules-layout-components-footer-___styles__facebook___iBkKT span{display:flex}\n", "",{"version":3,"sources":["webpack://./src/modules/layout/components/footer/styles.sass"],"names":[],"mappings":"AAAA,gEAAQ,iBAAA,CAAkB,UAAA,CAAW,QAAA,CAAS,uEAAe,qBAAA,CAAsB,4BAAA,CAA6B,+BAAA,CAAgC,0BAAyB,uEAAe,+BAAA,CAAA,CAAiC,0BAAyB,oEAAY,cAAA,CAAA,CAAgB,0BAAyB,oEAAY,aAAA,CAAA,CAAe,0BAAyB,oEAAY,cAAA,CAAA,CAAgB,kEAAU,aAAA,CAAc,cAAA,CAAe,wEAAgB,aAAA,CAAc,uEAAe,YAAA","sourcesContent":[".footer{position:absolute;width:100%;bottom:0}.footerContent{background-color:#fff;border-top:5px solid #719041;border-bottom:1px solid #719041}@media(min-width: 992px){.footerContent{border-bottom:5px solid #719041}}@media(max-width: 575px){.footerText{font-size:.9em}}@media(min-width: 576px){.footerText{font-size:1em}}@media(min-width: 992px){.footerText{font-size:.9em}}.facebook{color:#3b579d;font-size:37px}.facebook:hover{color:#719041}.facebook span{display:flex}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "src-modules-layout-components-footer-___styles__footer___nr0Ez",
	"footerContent": "src-modules-layout-components-footer-___styles__footerContent___v2OXS",
	"footerText": "src-modules-layout-components-footer-___styles__footerText___jNKCQ",
	"facebook": "src-modules-layout-components-footer-___styles__facebook___iBkKT"
};
export default ___CSS_LOADER_EXPORT___;
