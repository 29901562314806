import {classNames} from '@fl/cmsch-fe-library';
import {isNull} from 'lodash/fp';
import React, {FC} from 'react';

import {ParentMatch} from 'api/gen/ParentMatch';
import {SvgIcon} from 'icons';
import {useOurTranslation} from 'translations';

import {getLowercaseParentMatch} from '../../utils/get-lowercase-parent-match';

import styles from './styles.sass';

import dotSvg from './dot.svg';

interface Props {
    maternity: ParentMatch | null;
}

export const MaternityDot: FC<Props> = props => {
    const {maternity} = props;
    const {t} = useOurTranslation('animals/MaternityDot');
    const iconProps = classNames(
        styles.stateIcon,
        maternity === 'MATCHES' && styles.maternityMatches,
        maternity === 'MISMATCHES' && styles.maternityMismatches,
        maternity === 'MISSING' && styles.maternityMissing,
        isNull(maternity) && styles.maternityNone,
        styles.maternityPlain,
    );

    return (
        <span
            data-test-id={`maternity-dot-${maternity ?? 'none'}`}
            className={iconProps}
            title={maternity ? t(`maternity.${getLowercaseParentMatch(maternity)}`) : ''}
        >
            <SvgIcon icon={dotSvg} />
        </span>
    );
};
