import {Button} from '@fl/cmsch-fe-library';
import React, {Fragment, FC, memo, useCallback} from 'react';

import {Modal} from 'common/layout';
import {useOurTranslation} from 'translations';

interface Props {
    visible: boolean;
    errors: Array<string>;
    downloadWithoutForceResolve(forceDownloadConfirmed: boolean): void;
}

const ForceDownloadModalBase: FC<Props> = props => {
    const {visible, errors, downloadWithoutForceResolve} = props;

    const {t, tCommon} = useOurTranslation('orders/ForceDownloadModal');

    const onConfirm = useCallback(() =>
        downloadWithoutForceResolve(true)
    , [downloadWithoutForceResolve]);

    const onCancel = useCallback(() =>
        downloadWithoutForceResolve(false)
    , [downloadWithoutForceResolve]);

    const footer = (
        <Fragment>
            <Button
                key="back"
                onClick={onCancel}
            >
                {tCommon('cancel')}
            </Button>
            <Button
                key="submit"
                visuals="primary"
                onClick={onConfirm}
            >
                {t('forceDownload')}
            </Button>
        </Fragment>
    );

    return (
        <Modal
            title={t('caption')}
            visible={visible}
            onCancel={onCancel}
            footer={footer}
        >
            <ul className="mt-1 mb-3">
                {errors.map(x => <li key={x}>{x}</li>)}
            </ul>
        </Modal>
    );
};

export const ForceDownloadModal = memo(ForceDownloadModalBase);
