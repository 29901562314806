import {takeLatestF, showSuccess, putAll} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'translations';
import {showBeError} from 'utils/show-be-error';

import {otherAction, UpdatePaternityAction} from '../action';

const te = t('other/screenOther');

function* execute(_action: UpdatePaternityAction): SagaIterator {
    const response = yield* call(Api.synchronizeParenthood);

    if (response.isSuccess) {
        yield* put(otherAction.updatePaternitySuccess());
        yield* put(showSuccess(te('updatePaternityTitle'), te('updatePaternitySuccess')));
    } else {
        yield putAll(showBeError(response, te('updatePaternityTitle')));
        yield* put(otherAction.updatePaternityError());
    }
}

export function* updatePaternitySaga(): SagaIterator {
    yield takeLatestF('other/UPDATE_PATERNITY', execute);
}
