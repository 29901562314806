// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// Example: MATCHES

export const ParentMatchSchema = t.keyof({
  MATCHES: null,
  MISMATCHES: null,
  MISSING: null,
});

export type ParentMatch = t.TypeOf<typeof ParentMatchSchema>;
