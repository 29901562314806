import {acceptOriginInconsistencySaga} from './accept-origin-inconsistency-saga';
import {deleteAnimalSaga} from './delete-animal-saga';
import {exportAnimalSaga} from './export-animal-saga';
import {exportGenotypeSaga} from './export-genotype-saga';
import {exportMicrosatelliteSaga} from './export-microsatellites-saga';
import {exportOriginInconsistenciesToCSVSaga} from './export-origin-inconsistencies-to-csv-saga';
import {exportOriginInconsistencyReportSaga} from './export-origin-inconsistency-report-saga';
import {getAnimalQtlsSaga} from './get-animal-qtls-saga';
import {getAnimalSaga} from './get-animal-saga';
import {getAnimalsSaga} from './get-animals-saga';
import {getCategoriesSaga} from './get-categories-saga';
import {getMeasurementsSaga} from './get-measurements-saga';
import {getMicrosatellitesSaga} from './get-microsatellites-saga';
import {getOriginInconsistenciesSaga} from './get-origin-inconsistencies-saga';
import {markAsActiveSaga} from './mark-as-active-saga';
import {markAsInactiveSaga} from './mark-as-inactive-saga';
import {refreshFatherSaga} from './refresh-father-saga';
import {sendOriginInconsistencyReportSaga} from './send-origin-inconsistency-report-saga';
import {updateAnimalSaga} from './update-animal-saga';

export const animalsSagas = {
    getCategoriesSaga,
    deleteAnimalSaga,
    exportAnimalSaga,
    getAnimalsSaga,
    getOriginInconsistenciesSaga,
    exportOriginInconsistencyReportSaga,
    exportOriginInconsistenciesToCSVSaga,
    getAnimalQtlsSaga,
    acceptOriginInconsistencySaga,
    sendOriginInconsistencyReportSaga,
    getAnimalSaga,
    getMeasurementsSaga,
    getMicrosatellitesSaga,
    updateAnimalSaga,
    refreshFatherSaga,
    exportGenotypeSaga,
    exportMicrosatelliteSaga,
    markAsInactiveSaga,
    markAsActiveSaga,
};
