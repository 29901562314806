import {FooterButtons} from '@fl/cmsch-fe-library';
import moment from 'moment';
import React, {FC, memo, useCallback, useMemo} from 'react';
import {Opt, testRe} from 'ts-opt';

import {FindAnimalQueryParams} from 'api/gen/Api';
import {earTagRegexGen} from 'api/gen/EarTag';
import {lineRegistryRegexGen} from 'api/gen/LineRegistry';
import {useOurTranslation} from 'translations';
import {useForm} from 'utils/forms';
import {getSexOptions} from 'utils/get-sex-options';

import {AnimalEditFormValues, animalEditFormName} from './animal-edit-form-values';
import {validate} from './validations';

interface Props {
    initialValues: AnimalEditFormValues;
    refreshFatherInProgress: boolean;
    onRefreshFather(params: FindAnimalQueryParams): void;
    onCancel(): void;
    onSubmit(values: AnimalEditFormValues): void;
}

// eslint-disable-next-line max-lines-per-function
const AnimalEditFormBase: FC<Props> = props => {
    const {
        initialValues,
        onRefreshFather,
        onSubmit,
        onCancel,
        refreshFatherInProgress,
    } = props;

    const {t, tCommon} = useOurTranslation('animals/AnimalEditForm');

    const validateFn = useMemo(
        () => validate({initialSampleId: initialValues.sampleId}),
        [initialValues.sampleId],
    );
    const {Form, Fields, pristine, submitting, renderErrors} = useForm({
        form: animalEditFormName,
        initialValues,
        destroyOnUnmount: true,
        validate: validateFn,
        onSubmit,
    });

    const refreshFatherSampleId = useCallback((value: Opt<string>) => {
        value
            .filter(testRe(earTagRegexGen()))
            .onSome(x => onRefreshFather({sampleId: x, registry: null}));
    }, [onRefreshFather]);

    const refreshFatherRegistry = useCallback((value: Opt<string>) => {
        value
            .filter(testRe(lineRegistryRegexGen()))
            .onSome(x => onRefreshFather({registry: x, sampleId: null}));
    }, [onRefreshFather]);

    return (
        <Form>
            {renderErrors()}

            <fieldset>
                <legend>{t('animalData')}</legend>
                <div className="row">
                    <div className="col-12 col-md-6">
                        <Fields.Input
                            name="sampleId"
                            label={tCommon('earTag')}
                            type="text"
                            placeholder={tCommon('earTagPlaceholder')}
                            isRequired
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-md-6">
                        <Fields.DateInput
                            name="dob"
                            label={t('dob')}
                            placeholder={tCommon('dateOfBirthPlaceholder')}
                            maxDate={moment().endOf('day')}
                            dontIncludeFuture
                            clearable
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-md-6">
                        <Fields.Input
                            name="name"
                            label={t('name')}
                            type="text"
                        />
                    </div>

                    <div className="col-12 col-md-6">
                        <Fields.StringSelect
                            name="sex"
                            label={t('sex')}
                            placeholder={t('sexPlaceholder')}
                            options={getSexOptions()}
                            isRequired
                            clearable={false}
                            searchable={false}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-md-6">
                        <Fields.Input
                            name="breed"
                            label={t('breed')}
                            type="text"
                            isRequired
                        />
                    </div>

                    <div className="col-12 col-md-6">
                        <Fields.Input
                            name="registry"
                            label={t('registry')}
                            placeholder={tCommon('registryPlaceholder')}
                            type="text"
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-md-6">
                        <Fields.Input
                            name="fathersSampleId"
                            label={t('fathersSampleId')}
                            type="text"
                            placeholder={tCommon('earTagPlaceholder')}
                            onFieldChange={refreshFatherSampleId}
                        />
                    </div>

                    <div className="col-12 col-md-6">
                        <Fields.Input
                            name="fathersRegistry"
                            label={t('fathersRegistry')}
                            type="text"
                            onFieldChange={refreshFatherRegistry}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-md-6">
                        <Fields.Input
                            name="mothersSampleId"
                            label={t('mothersSampleId')}
                            type="text"
                            placeholder={tCommon('earTagPlaceholder')}
                        />

                        <Fields.Checkbox
                            name="twin"
                            label={t('twin')}
                        />
                    </div>
                    <div className="col-12 col-md-6">
                        <Fields.Textarea
                            name="laboratoryNote"
                            label={tCommon('note')}
                        />
                    </div>
                </div>
            </fieldset>

            <FooterButtons
                onCancel={onCancel}
                editing
                disabledSubmit={pristine || submitting || refreshFatherInProgress}
                disabledCancel={pristine || submitting}
            />
        </Form>
    );
};

export const AnimalEditForm = memo(AnimalEditFormBase);
