import {showError, forkRestartingOnError} from '@fl/cmsch-fe-library';
import {each, flatten, flow, isFunction, isObject, map, tap, toPairs} from 'lodash/fp';
import {Saga, SagaIterator} from 'redux-saga';
import {all, put} from 'typed-redux-saga';

import {animalsSagas} from 'animals';
import {logger} from 'app/sentry-logger';
import {dialsSagas} from 'dials';
import {fileImportSagas} from 'file-import';
import {exportGenotypesSagas} from 'genotypeExports';
import {orderSagas} from 'orders';
import {otherSagas} from 'other';
import {parentSearchSagas} from 'parentSearch';
import {paternityCheckSagas} from 'paternityCheck';
import {snpManagementSagas} from 'snp-management';
import {tCommon} from 'translations';
import {userSagas} from 'user';
import {tablesSagas} from 'utils/tables';

import {appSagas} from './model/sagas';

function* handleSagaError(_sagaName: string, error: Error): SagaIterator {
    logger.logError(error);
    yield* put(showError(tCommon('errorInApp'), tCommon('sorryForIncovenience')));
}

const validateInput = each(obj => {
    if (!isObject(obj)) {
        throw new Error(`Expected an array of objects, but found an item of type ${typeof obj}.`);
    }
});

const validateNameSagaPair = ([name, saga]: [string, Saga]): void => {
    if (!isFunction(saga)) throw new Error(`The key ${name} is not a saga.`);
};

const process = flow([
    tap(validateInput),
    map(toPairs),
    flatten,
    tap(each(validateNameSagaPair)),
    map(([, saga]) => forkRestartingOnError(saga, handleSagaError)),
]);

const sagas = [
    orderSagas,
    userSagas,
    animalsSagas,
    dialsSagas,
    fileImportSagas,
    parentSearchSagas,
    paternityCheckSagas,
    snpManagementSagas,
    otherSagas,
    exportGenotypesSagas,
    tablesSagas,
    appSagas,
];

export default function* combineSagas(): SagaIterator {
    yield* all(process(sagas));
}
