import {takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {logger} from 'app/sentry-logger';

import {LogoutAction} from '../action';

function* execute(_action: LogoutAction): SagaIterator {
    logger.setAnonymousUser();
    const response = yield* call(Api.logout);

    if (response.isSuccess) {
        window.location.href = response.data.logoutUri;
    }
}

export function* logoutSaga(): SagaIterator {
    yield takeLatestF('logout/LOGOUT', execute);
}
