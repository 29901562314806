import {TableDataParams} from '@fl/cmsch-fe-library';
import {opt} from 'ts-opt';

import {OrderTableType} from '../components/OrdersTable/order-table-type';
import {ExportOrdersParams} from '../types/export-orders-params';
import {getParams} from './get-params';

const handleParameter = <T>(parameter: T | null | undefined): T | null =>
    opt(parameter).orNull();

// eslint-disable-next-line max-lines-per-function
export const getExportParams = (
    tableDataParams?: TableDataParams<OrderTableType>,
    force?: boolean,
    excludedOrderIds?: Array<number>,
): ExportOrdersParams => {
    const params = tableDataParams ? getParams(tableDataParams) : null;

    return {
        sort: params?.sort ?? [],
        fulltextFilter: handleParameter(params?.fulltextFilter),
        breed: handleParameter(params?.breed),
        chipId: handleParameter(params?.chipId),
        createdDateFrom: handleParameter(params?.createdDateFrom),
        createdDateTo: handleParameter(params?.createdDateTo),
        customerName: handleParameter(params?.customerName),
        earTag: handleParameter(params?.earTag),
        hasCatalogList: handleParameter(params?.hasCatalogList),
        isolated: handleParameter(params?.isolated),
        isolationDateFrom: handleParameter(params?.isolationDateFrom),
        isolationDateTo: handleParameter(params?.isolationDateTo),
        laboratoryNumber: handleParameter(params?.laboratoryNumber),
        maternity: handleParameter(params?.maternity),
        orderTypeIds: handleParameter(params?.orderTypeIds),
        paternity: handleParameter(params?.paternity),
        protocolGeneratedDateFrom: handleParameter(params?.protocolGeneratedDateFrom),
        protocolGeneratedDateTo: handleParameter(params?.protocolGeneratedDateTo),
        sampleDeliveredDateFrom: handleParameter(params?.sampleDeliveredDateFrom),
        sampleDeliveredDateTo: handleParameter(params?.sampleDeliveredDateTo),
        sampleTypeIds: handleParameter(params?.sampleTypeIds),
        sex: handleParameter(params?.sex),
        isBilled: handleParameter(params?.isBilled),
        force: handleParameter(force),
        excludedOrderIds: handleParameter(excludedOrderIds),
    };
};
