// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { PageMetadata, PageMetadataSchema } from 'api/gen/PageMetadata';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { SnpView, SnpViewSchema } from 'api/gen/SnpView';

const requiredPart = t.interface({
  content: t.union([t.array(SnpViewSchema), t.null]),
  page: t.union([PageMetadataSchema, t.null]),
});

export const PagedModelSnpViewSchema = excess(requiredPart);

export interface PagedModelSnpView extends t.TypeOf<typeof PagedModelSnpViewSchema> {}
