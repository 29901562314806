import {TableColumn} from '@fl/cmsch-fe-library';

import {TFunction} from 'translations';

import {getParenthoodOptions} from '../../utils/parenthood-options';
import {AnimalsTableRow} from './table-row';

// eslint-disable-next-line max-lines-per-function
export const getColumns = (
    isRoleLaborer: boolean,
    t: TFunction<'animals/AnimalsTable'>,
    tCommon: TFunction<'common'>,
): Array<TableColumn<AnimalsTableRow>> => {
    const columns: Array<TableColumn<AnimalsTableRow>> = [
        {
            field: 'data',
            column: tCommon('note'),
            tooltip: tCommon('note'),
            type: 'reactNode',
            locked: true,
            width: 57,
            verticalName: true,
            sortable: false,
            filters: [
                {
                    field: 'laboratoryNote',
                    type: 'containsString',
                    label: '',
                },
            ],
        },
        {
            field: 'importedFromGermany',
            column: t('columnHeaders.importedFromGermany'),
            tooltip: t('columnHeadersTooltip.importedFromGermany'),
            type: 'boolean',
            locked: true,
            width: 57,
            verticalName: true,
        },
        {
            field: 'parenthood',
            column: t('columnHeaders.parenthood'),
            tooltip: t('columnHeadersTooltip.parenthood'),
            type: 'reactNode',
            locked: true,
            width: 63,
            verticalName: true,
            filters: [
                {
                    type: 'select',
                    field: 'paternity',
                    label: t('columnHeaders.paternity'),
                    options: getParenthoodOptions(),
                },
                {
                    type: 'select',
                    field: 'maternity',
                    label: t('columnHeaders.maternity'),
                    options: getParenthoodOptions(),
                },
            ],
        },
        {
            field: 'earTag',
            column: tCommon('earTag'),
            tooltip: tCommon('earTag'),
            type: 'string',
            filterTypes: ['betweenEarTags'],
            width: 160,
        },
        {
            field: 'laboratoryNumber',
            column: t('columnHeaders.laboratoryNumber'),
            tooltip: t('columnHeadersTooltip.laboratoryNumber'),
            type: 'string',
            width: 117,
            filterTypes: ['null'],
        },
        {
            field: 'chipId',
            column: t('columnHeaders.chipId'),
            tooltip: t('columnHeadersTooltip.chipId'),
            type: 'string',
            width: 124,
            filterTypes: ['null'],
        },
        {
            field: 'lineRegistry',
            column: t('columnHeaders.lineRegistry'),
            tooltip: t('columnHeadersTooltip.lineRegistry'),
            type: 'string',
            width: 118,
            filterTypes: ['null'],
        },
        {
            field: 'fathersLineRegistry',
            column: t('columnHeaders.fathersLineRegistry'),
            tooltip: t('columnHeadersTooltip.fathersLineRegistry'),
            type: 'string',
            width: 150,
            filterTypes: ['null'],
        },
        {
            field: 'fathersLaboratoryNumber',
            column: t('columnHeaders.fathersLaboratoryNumber'),
            tooltip: t('columnHeadersTooltip.fathersLaboratoryNumber'),
            type: 'string',
            width: 150,
            filterTypes: ['null'],
        },
        {
            field: 'mothersEarTag',
            column: t('columnHeaders.mothersEarTag'),
            tooltip: t('columnHeadersTooltip.mothersEarTag'),
            type: 'string',
            filterTypes: ['betweenEarTags', 'null'],
            width: 147,
        },
        {
            field: 'mothersLaboratoryNumber',
            column: t('columnHeaders.mothersLaboratoryNumber'),
            tooltip: t('columnHeadersTooltip.mothersLaboratoryNumber'),
            type: 'string',
            width: 154,
            filterTypes: ['null'],
        },
    ];

    return isRoleLaborer ? columns : columns.filter(x => x.field !== 'data');
};
