import {barcodeRegexGen} from 'api/gen/Barcode';
import {labNumberMaxLength, labNumberRegexGen} from 'api/gen/LabNumber';
import {t} from 'translations';
import {PaternityValidator} from 'utils/paternity-validator';
import {Errors} from 'utils/validator';

import {OrderEditFormValues} from './order-edit-form-values';

const te = t('orders/EditForm');

export const validate = (values: OrderEditFormValues): Errors<OrderEditFormValues> => {
    const validator = new PaternityValidator(values);

    validator.nonEmpty('orderTypeIds', te('orderTypeIds'));
    validator.nonEmpty('sampleTypeId', te('sampleTypeId'));
    validator.pattern('barcode', barcodeRegexGen(), te('barcode'));
    validator.pattern('laboratoryNumber', labNumberRegexGen(), te('laboratoryNumber'));
    validator.maxStringLength('laboratoryNumber', labNumberMaxLength, te('laboratoryNumber'));

    return validator.generateErrorsObject();
};
