// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { NullableAllelesCouple, NullableAllelesCoupleSchema } from 'api/gen/NullableAllelesCouple';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { QtlResultType, QtlResultTypeSchema } from 'api/gen/QtlResultType';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { QtlType, QtlTypeSchema } from 'api/gen/QtlType';

const requiredPart = t.interface({
  allelesCouples: t.union([t.array(NullableAllelesCoupleSchema), t.null]),
  result: QtlResultTypeSchema,
  snpName: t.string,
  type: QtlTypeSchema,
});

export const QtlSchema = excess(requiredPart);

export interface Qtl extends t.TypeOf<typeof QtlSchema> {}
