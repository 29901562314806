// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';
import {StringPatternSchema} from '@fl/cmsch-fe-library';


// The VAT identification number.
// Example: "CZ7709272978"

export const vatNumberRegexGen = (): RegExp => new RegExp("^(CZ|SK)[0-9]{8,10}$");

export const VatNumberSchema = StringPatternSchema<VatNumber>(vatNumberRegexGen());

export type VatNumber = string;
