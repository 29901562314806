import {setItem} from '@fl/cmsch-fe-library';
import * as O from 'optics-ts';

import {AnimalsAction} from './action';
import {AnimalsState, initialAnimalsState} from './state';

const animalsStateO = O.optic<AnimalsState>();

// eslint-disable-next-line max-lines-per-function
export const animalsReducer = (
    state: AnimalsState = initialAnimalsState,
    action: AnimalsAction,
): AnimalsState => {
    switch (action.type) {
        case 'animals/GET_CATEGORIES_SUCCESS': {
            const {categories} = action.payload;

            return O.set(animalsStateO.prop('categories'))(categories)(state);
        }

        case 'animals/GET_ANIMALS_SUCCESS': {
            const {animalsPage} = action.payload;

            return O.set(animalsStateO.prop('animalsPage'))(animalsPage)(state);
        }

        case 'animals/GET_ORIGIN_INCONSISTENCIES_SUCCESS': {
            const {originInconsistencies} = action.payload;

            return O.set(animalsStateO.prop('originInconsistenciesPage'))(originInconsistencies)(state);
        }

        case 'animals/GET_ANIMAL_QTLS_SUCCESS': {
            const {animalQtls} = action.payload;

            return O.set(animalsStateO.prop('animalQtls'))(animalQtls)(state);
        }

        case 'animals/RESET_ANIMAL_QTLS': {
            return O.set(animalsStateO.prop('animalQtls'))(null)(state);
        }

        case 'animals/GET_ANIMAL': {
            return O.set(animalsStateO.prop('microsatellites'))(null)(state);
        }

        case 'animals/GET_ANIMAL_SUCCESS': {
            const {animal} = action.payload;

            return O.set(animalsStateO.prop('animal'))(animal)(state);
        }

        case 'animals/GET_MEASUREMENTS_SUCCESS': {
            const {measurements} = action.payload;

            return O.set(animalsStateO.prop('measurements'))(measurements)(state);
        }

        case 'animals/RESET_MEASUREMENTS_AND_CATEGORIES': {
            return O.pipe(
                state,
                O.set(animalsStateO.prop('measurements'))(null),
                O.set(animalsStateO.prop('categories'))(null),
            );
        }

        case 'animals/GET_MICROSATELLITES_SUCCESS': {
            const {microsatellites} = action.payload;

            return O.set(animalsStateO.prop('microsatellites'))(microsatellites)(state);
        }

        case 'animals/RESET_ANIMAL': {
            return O.set(animalsStateO.prop('animal'))(null)(state);
        }

        case 'animals/SET_SELECTED_ANIMALS': {
            const {animalIds} = action.payload;

            return O.set(animalsStateO.prop('selectedAnimalIds'))(animalIds)(state);
        }

        case 'animals/SET_INCONSISTENCIES_ACTION_ACTIVE': {
            const {active, id} = action.payload;

            return O.modify(animalsStateO.prop('inconsistenciesActionActive'))(setItem(active)(id))(state);
        }

        case 'animals/SET_SELECTED_ORIGIN_INCONSISTENCY_IDS': {
            const {selectedIds} = action.payload;

            return O.set(animalsStateO.prop('selectedOriginInconsistencyIds'))(selectedIds)(state);
        }
        case 'animals/SET_LOADING': {
            const {id, loading} = action.payload;

            return O.modify(animalsStateO.prop('loading'))(setItem(loading)(id))(state);
        }

        default:
            return state;
    }
};
