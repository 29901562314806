import {putAll, showSuccess, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'translations';
import {showBeError} from 'utils/show-be-error';

import {IsolateByIdsAction, orderAction} from '../action';
import {getSelectedOrders} from './selectors';

const te = t('orders/ListActions');

function* execute(_action: IsolateByIdsAction): SagaIterator {
    const orderIds = yield* select(getSelectedOrders);
    yield* put(orderAction.setListActionActive('massIsolation', true));

    const response = yield* call(Api.isolateSubsetOfOrders, {orderIds});

    if (response.isSuccess) {
        yield* put(orderAction.getOrders(opt({action: 'refresh'})));
        yield* put(showSuccess(te('massIsolation'), t('orders/sagas')('success')));
    } else {
        yield putAll(showBeError(response, te('massIsolation')));
    }
    yield* put(orderAction.setListActionActive('massIsolation', false));
}

export function* isolateOrdersByIdsSaga(): SagaIterator {
    yield takeLatestF('order/ISOLATE_BY_IDS', execute);
}
