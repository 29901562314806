import {ActionSettings, Table, TableColumn, Button} from '@fl/cmsch-fe-library';
import React, {Fragment, FC, memo, useCallback, useEffect, useMemo} from 'react';
import {find, Opt} from 'ts-opt';

import {CodeTableValidation} from 'api/gen/CodeTableValidation';
import {NewVerificationOutcome} from 'api/gen/NewVerificationOutcome';
import {VerificationOutcome} from 'api/gen/VerificationOutcome';
import {DialsModals} from 'dials/types/dials-modals';
import {useOurTranslation} from 'translations';
import {parenthoodValues} from 'types/parenthood-values';

import {VerificationOutcomesModal} from './verification-outcomes-modal';

interface Props {
    loading: boolean;
    modalsVisibility: Array<DialsModals>;
    verificationOutcomes: Opt<Array<VerificationOutcome>>;
    verificationOutcomeIdBeingEdited: Opt<number>;
    toggleModalVisibility(id: DialsModals): void;
    getVerificationOutcomes(): void;
    startEditingVerificationOutcome(verificationOutcome: VerificationOutcome): void;
    updateVerificationOutcome(id: number, verificationOutcome: NewVerificationOutcome): void;
    verificationOutcomeEditingCancelled(): void;
    createVerificationOutcome(verificationOutcome: NewVerificationOutcome): void;
    validateVerificationOutcomeType(data: CodeTableValidation): void;
    resetVerificationOutcomes(): void;
}

const VerificationOutcomesBase: FC<Props> = props => {
    const {
        loading,
        modalsVisibility,
        verificationOutcomes,
        verificationOutcomeIdBeingEdited,
        toggleModalVisibility,
        getVerificationOutcomes,
        startEditingVerificationOutcome,
        updateVerificationOutcome,
        verificationOutcomeEditingCancelled,
        createVerificationOutcome,
        validateVerificationOutcomeType,
        resetVerificationOutcomes,
    } = props;

    useEffect(() => {
        getVerificationOutcomes();
    }, [getVerificationOutcomes]);

    const {t} = useOurTranslation('dials/verificationOutcomes');

    const toggleVerificationOutcomeModalVisibility = useCallback(() =>
        toggleModalVisibility('verificationOutcome')
    , [toggleModalVisibility]);

    const onEdit = useCallback((id: number) =>
        verificationOutcomes.chain(find(x => x.id === id))
            .onSome(verificationOutcomeToEdit => {
                startEditingVerificationOutcome(verificationOutcomeToEdit);
                toggleVerificationOutcomeModalVisibility();
            })
    , [verificationOutcomes, startEditingVerificationOutcome, toggleVerificationOutcomeModalVisibility]);

    const onEditCancel = useCallback(() => {
        verificationOutcomeEditingCancelled();
        toggleVerificationOutcomeModalVisibility();
    }, [verificationOutcomeEditingCancelled, toggleVerificationOutcomeModalVisibility]);

    const onUpdate = useCallback((data: NewVerificationOutcome) => {
        verificationOutcomeIdBeingEdited.onSome(x => updateVerificationOutcome(x, data));
    }, [verificationOutcomeIdBeingEdited, updateVerificationOutcome]);

    const columns = useMemo((): Array<TableColumn<VerificationOutcome>> => [
        {
            field: 'value',
            type: 'string',
            column: t('name'),
            tooltip: t('name'),
            disableFilter: true,
            width: 922,
        },
        {
            field: 'paternitySettings',
            type: 'option',
            column: t('paternitySettings'),
            tooltip: t('paternitySettings'),
            options: parenthoodValues.map(v => ({
                value: v,
                label: t(`paternityValues.${v}`),
            })),
            width: 80,
            disableFilter: true,
        },
        {
            field: 'maternitySettings',
            type: 'option',
            column: t('maternitySettings'),
            tooltip: t('maternitySettings'),
            options: parenthoodValues.map(v => ({
                value: v,
                label: t(`maternityValues.${v}`),
            })),
            width: 80,
            disableFilter: true,
        },
    ], [t]);

    const actionSettings = useMemo((): ActionSettings<VerificationOutcome> => ({
        onEdit,
    }), [onEdit]);

    return (
        <Fragment>
            <div className="d-flex justify-content-end mb-2">
                <Button
                    visuals="primary"
                    icon="plusOutlined"
                    onClick={toggleVerificationOutcomeModalVisibility}
                >
                    {t('createNew')}
                </Button>
            </div>
            <Table
                tableId="orderTypes"
                columns={columns}
                rows={verificationOutcomes.orElse([])}
                actionSettings={actionSettings}
                loading={loading}
                scroll={{x: true}}
                onUnmount={resetVerificationOutcomes}
            />
            <VerificationOutcomesModal
                visible={modalsVisibility.includes('verificationOutcome')}
                verificationOutcomeIdBeingEdited={verificationOutcomeIdBeingEdited}
                onSubmitEdit={onUpdate}
                onSubmitCreate={createVerificationOutcome}
                onCancel={verificationOutcomeIdBeingEdited.isEmpty
                    ? toggleVerificationOutcomeModalVisibility
                    : onEditCancel}
                validateVerificationOutcomeType={validateVerificationOutcomeType}
            />
        </Fragment>
    );
};

export const VerificationOutcomes = memo(VerificationOutcomesBase);
