// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';
import {StringPatternSchema} from '@fl/cmsch-fe-library';


// A laboratory number of animal's sample.
// Example: "2110179H"

export const labNumberRegexGen = (): RegExp => new RegExp("^([0-9]+[A-Z]+)$");

export const labNumberMinLength = 1;
export const labNumberMaxLength = 255;

export const LabNumberSchema = t.refinement(StringPatternSchema<LabNumber>(labNumberRegexGen()), n => n.length >= labNumberMinLength && n.length <= labNumberMaxLength, 'LabNumber');

export type LabNumber = string;
