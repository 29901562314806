// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { GlobalEarTag, GlobalEarTagSchema } from 'api/gen/GlobalEarTag';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { ParenthoodVerificationDifference, ParenthoodVerificationDifferenceSchema } from 'api/gen/ParenthoodVerificationDifference';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveOrZeroInteger, PositiveOrZeroIntegerSchema } from 'api/gen/PositiveOrZeroInteger';

const requiredPart = t.interface({
  differences: t.union([t.array(ParenthoodVerificationDifferenceSchema), t.null]),
  numOfProcessedGenes: PositiveOrZeroIntegerSchema,
  parentSampleId: GlobalEarTagSchema,
  probability: t.number,
});

export const ParenthoodVerificationSchema = excess(requiredPart);

export interface ParenthoodVerification extends t.TypeOf<typeof ParenthoodVerificationSchema> {}
