// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// Example: ADENINE

export const NucleobaseTypeSchema = t.keyof({
  ADENINE: null,
  CYTOSINE: null,
  GUANINE: null,
  THYMINE: null,
  INSERTION: null,
  DELETION: null,
});

export type NucleobaseType = t.TypeOf<typeof NucleobaseTypeSchema>;
