import {usePrevious} from '@fl/cmsch-fe-library';
import React, {Fragment, FC, memo, useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useLocation} from 'react-router';
import * as routerActions from 'redux-first-history';
import {opt} from 'ts-opt';

import {AntLayout} from 'common/ant';
import {userAction} from 'user';

import {MainMenu} from '.';

import styles from './styles.sass';

const MenuAppWrapperBase: FC = () => {
    const [isSiderActive, setIsSiderActive] = useState<boolean>(false);
    const [isSideMenuCollapsed, setIsSideMenuCollapsed] = useState<boolean>(true);
    const {pathname} = useLocation();
    const previousPathname = usePrevious(pathname);

    const handleSideMenuCollapse = useCallback(() => {
        setIsSideMenuCollapsed(true);
    }, []);

    const dispatch = useDispatch();
    const goTo = useCallback((path: string) => dispatch(routerActions.push(path)), [dispatch]);

    useEffect(() => {
        if (previousPathname !== pathname) {
            dispatch(userAction.setPreviousAndCurrentPathname(opt(previousPathname), opt(pathname)));
        }
    }, [dispatch, pathname, previousPathname]);

    return (
        <Fragment>
            <AntLayout.Sider
                width="270px"
                breakpoint="sm"
                collapsedWidth="0"
                collapsed={isSideMenuCollapsed}
                onBreakpoint={setIsSiderActive}
                onCollapse={setIsSideMenuCollapsed}
                className={isSiderActive ? styles.siderActive : 'd-none'}
            >
                {isSiderActive && <MainMenu
                    isSideMenu
                    isCollapsed={isSideMenuCollapsed}
                    onMenuItemClick={handleSideMenuCollapse}
                    goTo={goTo}
                />}
            </AntLayout.Sider>
            {isSiderActive && !isSideMenuCollapsed && (
                <div
                    className="menuAppWrapperSiderModalBackdrop"
                    onClick={handleSideMenuCollapse}
                />
            )}
            {!isSiderActive && (
                <MainMenu
                    goTo={goTo}
                />
            )}
        </Fragment>
    );
};

export const MenuAppWrapper = memo(MenuAppWrapperBase);
