import React from 'react';
import {Route} from 'react-router-dom';

import {Logout} from './screens';

export const userRoutesWithoutFetching: Array<RegExp> = [];

export const userRoutes = [
    (
        <Route
            path="/logout"
            component={Logout}
            exact
            key="logout"
        />
    ),
];
