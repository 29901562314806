import {putAll, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'translations';
import {showBeError} from 'utils/show-be-error';

import {ExportBillingBaseByIdsAction, orderAction} from '../action';
import {isActionForced} from './is-action-forced';
import {getSelectedOrders} from './selectors';

function* execute({payload: {billingDate, force}}: ExportBillingBaseByIdsAction): SagaIterator {
    const orderIds = yield* select(getSelectedOrders);
    yield* put(orderAction.setListActionActive('billingBase', true));

    const response = yield* call(Api.createInvoicingDocumentsSubset, {billingDate, force, orderIds});

    if (response.isSuccess) {
        yield* put(orderAction.exportBillingBaseSuccess(response.data));
        yield* put(orderAction.setModalVisibility('downloadBillingBase', true));
        yield* put(orderAction.getOrders(opt({action: 'refresh'})));
    } else {
        if (force) {
            yield putAll(showBeError(response, t('orders/ListActions')('billingBase')));
        } else {
            const isForced = yield* call(isActionForced, response);
            if (isForced) {
                yield* put(orderAction.exportBillingBaseByIds(billingDate, true));
            }
        }
    }
    yield* put(orderAction.setListActionActive('billingBase', false));
    yield* put(orderAction.resetDownloadWithoutForceFailedResult());
}

export function* exportBillingBaseByIdsSaga(): SagaIterator {
    yield takeLatestF('order/BILLING_BASE_EXPORT_BY_IDS', execute);
}
