// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { AnimalName, AnimalNameSchema } from 'api/gen/AnimalName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Breed, BreedSchema } from 'api/gen/Breed';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { ChipId, ChipIdSchema } from 'api/gen/ChipId';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Date, DateSchema } from 'api/gen/Date';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { GlobalEarTag, GlobalEarTagSchema } from 'api/gen/GlobalEarTag';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { LabNumber, LabNumberSchema } from 'api/gen/LabNumber';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { LineRegistry, LineRegistrySchema } from 'api/gen/LineRegistry';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveLong, PositiveLongSchema } from 'api/gen/PositiveLong';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { SexType, SexTypeSchema } from 'api/gen/SexType';

const requiredPart = t.interface({
  breed: t.union([BreedSchema, t.null]),
  chipId: t.union([ChipIdSchema, t.null]),
  dob: t.union([DateSchema, t.null]),
  exportable: t.boolean,
  fathersId: t.union([PositiveLongSchema, t.null]),
  fathersLaboratoryNumber: t.union([LabNumberSchema, t.null]),
  fathersRegistry: t.union([LineRegistrySchema, t.null]),
  fathersSampleId: t.union([GlobalEarTagSchema, t.null]),
  id: PositiveLongSchema,
  laboratoryNote: t.union([t.string, t.null]),
  laboratoryNumber: t.union([LabNumberSchema, t.null]),
  mothersFatherSampleId: t.union([GlobalEarTagSchema, t.null]),
  mothersId: t.union([PositiveLongSchema, t.null]),
  mothersLaboratoryNumber: t.union([LabNumberSchema, t.null]),
  mothersSampleId: t.union([GlobalEarTagSchema, t.null]),
  name: t.union([AnimalNameSchema, t.null]),
  orderId: t.union([PositiveLongSchema, t.null]),
  registry: t.union([LineRegistrySchema, t.null]),
  sampleId: GlobalEarTagSchema,
  sex: t.union([SexTypeSchema, t.null]),
  twin: t.boolean,
});

export const AnimalDetailsSchema = excess(requiredPart);

export interface AnimalDetails extends t.TypeOf<typeof AnimalDetailsSchema> {}
