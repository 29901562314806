import {Button} from '@fl/cmsch-fe-library';
import React, {FC, memo} from 'react';

import {TFunction} from 'translations';

interface Props {
    t: TFunction<'orders/newBulkOrder'>;
    handleModalOpen(): void;
}

const AddAnimalButtonBase: FC<Props> = props => {
    const {
        handleModalOpen,
        t,
    } = props;

    return (
        <div className="row justify-content-end" >
            <div className="col-12 d-flex justify-content-end">
                <Button
                    size="middle"
                    visuals="primary"
                    bottomMargin
                    tooltip={t('addOrders')}
                    onClick={handleModalOpen}
                    icon="plusOutlined"
                >
                    {t('addOrders')}
                </Button>
            </div>
        </div>
    );
};

export const AddAnimalButton = memo(AddAnimalButtonBase);
