import {TypedFormSectionInjectedPropsUnsafe} from '@fl/cmsch-fe-library';
import React, {FC, memo} from 'react';

import {useOurTranslation} from 'translations';

import {AddressFormSectionValues} from '../../types/address-form-section-values';
import {AddressType} from '../../types/address-type';

export interface AddressFormSectionOuterProps {
    legend: AddressType;
    disabledAddress: boolean;
}

type Props = AddressFormSectionOuterProps & TypedFormSectionInjectedPropsUnsafe<AddressFormSectionValues>;

const AddressFormSectionBase: FC<Props> = props => {
    const {Fields, legend, disabledAddress} = props;

    const {t} = useOurTranslation('common');

    return (
        <fieldset>
            <legend className="h5">{t(`address.${legend}`)}</legend>

            <div className="row">
                <div className="col-12 col-md-6">
                    <Fields.Input
                        name="street"
                        label={t('address.street')}
                        type="text"
                        disabled={disabledAddress}
                        isRequired
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-md-6">
                    <Fields.Input
                        name="zip"
                        label={t('address.zip')}
                        type="text"
                        disabled={disabledAddress}
                        isRequired
                    />
                </div>

                <div className="col-12 col-md-6">
                    <Fields.Input
                        name="city"
                        label={t('address.city')}
                        type="text"
                        disabled={disabledAddress}
                        isRequired
                    />
                </div>
            </div>
        </fieldset>
    );
};

export const AddressFormSection = memo(AddressFormSectionBase);
