// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// A generic breed of an animal
// Example: "H100"

export const breedMinLength = 1;
export const breedMaxLength = 255;

export const BreedSchema = t.refinement(t.string, n => n.length >= breedMinLength && n.length <= breedMaxLength, 'Breed');

export type Breed = string;
