import {debounce} from 'lodash/fp';
import React, {FC, memo, useCallback, useMemo} from 'react';
import {Opt} from 'ts-opt';

import {CodeTableValidation} from 'api/gen/CodeTableValidation';
import {NewVerificationOutcome} from 'api/gen/NewVerificationOutcome';
import {Modal} from 'common/layout';
import {debounceTime} from 'constants/general';
import {useOurTranslation} from 'translations';

import {VerificationOutcomeForm} from '../VerificationOutcomeForm';

interface Props {
    visible: boolean;
    verificationOutcomeIdBeingEdited: Opt<number>;
    onSubmitEdit(verificationOutcome: NewVerificationOutcome): void;
    onSubmitCreate(verificationOutcome: NewVerificationOutcome): void;
    onCancel(): void;
    validateVerificationOutcomeType(data: CodeTableValidation): void;
}

const VerificationOutcomesModalBase: FC<Props> = props => {
    const {
        visible,
        verificationOutcomeIdBeingEdited,
        onSubmitEdit,
        onSubmitCreate,
        onCancel,
        validateVerificationOutcomeType,
    } = props;

    const {t} = useOurTranslation('dials/verificationOutcomes');

    const validateVerificationOutcomeTypeDebounced = useMemo(() =>
        debounce(debounceTime)(validateVerificationOutcomeType)
    , [validateVerificationOutcomeType]);

    const validateValue = useCallback((value: Opt<string>) => {
        const idToExclude = verificationOutcomeIdBeingEdited.orNull();
        value.onSome(x => validateVerificationOutcomeTypeDebounced({value: x, idToExclude}));
    }, [validateVerificationOutcomeTypeDebounced, verificationOutcomeIdBeingEdited]);

    return (
        <Modal
            title={verificationOutcomeIdBeingEdited.isEmpty ? t('createTitle') : t('editTitle')}
            visible={visible}
            onCancel={onCancel}
            footer={null}
        >
            {verificationOutcomeIdBeingEdited.isEmpty
                ? (
                    <VerificationOutcomeForm
                        onSubmit={onSubmitCreate}
                        onCancel={onCancel}
                        onChangeValue={validateValue}
                    />
                )
                : (
                    <VerificationOutcomeForm
                        onSubmit={onSubmitEdit}
                        onCancel={onCancel}
                        isEdit
                        onChangeValue={validateValue}
                    />
                )}
        </Modal>
    );
};

export const VerificationOutcomesModal = memo(VerificationOutcomesModalBase);
