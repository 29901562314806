import {showBeError as _showBeError, ErrorOptions, Response} from '@fl/cmsch-fe-library';
import {Action} from 'redux';

import {t} from 'translations';

type Options = Omit<ErrorOptions, 'getMessage'>;

const translate = t('beErrors');

export const showBeError = (
    response: Response<unknown>,
    title: string,
    options?: Options,
): Action | Array<Action> =>
    _showBeError(response, title, {
        getMessage: translate,
        ...options,
    });
