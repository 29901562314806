import {classNames} from '@fl/cmsch-fe-library';
import React, {FC, memo} from 'react';

import {SvgIcon} from 'icons';

import styles from './styles.sass';

import circleSvg from '../circle-plain.svg';

interface Props {
    stepNumber: number;
}

const StepIconBase: FC<Props> = props => {
    const {stepNumber} = props;

    return (
        <div className="justify-content-center d-flex d-md-block mb-3 mb-md-0">
            <div className="position-relative">
                <div className={classNames(styles.stepnumberPosition, 'position-absolute')}>
                    <h2>{stepNumber}</h2>
                </div>
                <SvgIcon
                    icon={circleSvg}
                    circle
                />
            </div>
        </div>
    );
};

export const StepIcon = memo(StepIconBase);
