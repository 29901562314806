import {downloadFileFromResponse, putAll, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'translations';
import {showBeError} from 'utils/show-be-error';

import {getOrderTableParamsDto} from '../../utils/get-order-table-params-dto';
import {ExportProtocolsByFilterAction, orderAction} from '../action';
import {isActionForced} from './is-action-forced';
import {getSelectedOrders} from './selectors';

function* execute({payload: {force, withoutSelected}}: ExportProtocolsByFilterAction): SagaIterator {
    const {filters} = yield* call(getOrderTableParamsDto);
    const excludedOrderIds = withoutSelected ? yield* select(getSelectedOrders) : null;
    const response = yield* call(Api.getProtocols, {filters, excludedOrderIds}, {force});

    if (response.isSuccess) {
        yield* call(downloadFileFromResponse, response);
    } else {
        if (force) {
            yield putAll(showBeError(response, t('orders/ListActions')('protocols')));
        } else {
            const isForced = yield* call(isActionForced, response);

            if (isForced) {
                yield* put(orderAction.exportProtocolsByFilter(true, withoutSelected));
            }
        }
    }
    yield* put(orderAction.resetDownloadWithoutForceFailedResult());
}

export function* exportProtocolsByFilterSaga(): SagaIterator {
    yield takeLatestF('order/EXPORT_PROTOCOLS_BY_FILTER', execute);
}
