// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveLong, PositiveLongSchema } from 'api/gen/PositiveLong';

const requiredPart = t.interface({
  idToExclude: t.union([PositiveLongSchema, t.null]),
  value: t.string,
});

export const CodeTableValidationSchema = excess(requiredPart);

export interface CodeTableValidation extends t.TypeOf<typeof CodeTableValidationSchema> {}
