import {extractFormErrorsFromResponse, putAll, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {put, call} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {parseBulkEditOrderFormValues} from 'orders/utils/parse-bulk-edit-order-form-values';
import {t} from 'translations';
import {formHelpers} from 'utils/forms';
import {showBeError} from 'utils/show-be-error';

import {orderAction, UpdateOrdersCustomersByIdsAction} from '../action';

function* execute({payload: {orderIds, values}}: UpdateOrdersCustomersByIdsAction): SagaIterator {
    const updateCustomerDetails = parseBulkEditOrderFormValues(values);

    if (!updateCustomerDetails) {
        return;
    }

    yield* put(formHelpers.startSubmit('bulkEditOrder'));
    yield* put(orderAction.setListActionActive('bulkUpdateOrder', true));

    const response = yield* call(Api.updateCustomersSubset, updateCustomerDetails, {orderIds});

    if (response.isSuccess) {
        yield* put(formHelpers.stopSubmit('bulkEditOrder'));
        yield* put(orderAction.resetBulkEditOrderIds());
        yield* put(orderAction.deselectAll());
        yield* put(orderAction.setUserDetailsFromCIN(null));
    } else {
        yield putAll(showBeError(response, t('orders/ListActions')('bulkUpdateOrder')));
        yield* put(formHelpers.stopSubmit('bulkEditOrder', extractFormErrorsFromResponse(response)));
    }
    yield* put(orderAction.setListActionActive('bulkUpdateOrder', false));
    yield* put(orderAction.getOrders(opt({action: 'refresh'})));
}

export function* updateOrdersCustomersByIdsSaga(): SagaIterator {
    yield takeLatestF('order/UPDATE_ORDER_CUSTOMERS_BY_IDS', execute);
}
