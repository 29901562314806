import {NullableKeys} from '@fl/cmsch-fe-library';

export const createOrderTypeFormName = 'createOrderType';
export const editOrderTypeFormName = 'editOrderType';

export type OrderTypeFormValues = NullableKeys<{
    value: string,
    code: string,
    price: number,
    extraCharge: number,
}>;

export const initialOrderTypeFormValues: OrderTypeFormValues = {
    code: null,
    extraCharge: 0,
    value: null,
    price: 0,
};
