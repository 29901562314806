import {Options} from '@fl/cmsch-fe-library';
import {Opt} from 'ts-opt';

import {OrderDetails} from 'api/gen/OrderDetails';
import {OrderTypeItem} from 'api/gen/OrderTypeItem';
import {codeTableItemsToOptions} from 'utils/code-table-items-to-options';

const isInOrder = (orderTypeId: number, order: OrderDetails): boolean =>
    order.orderTypes?.some(ot => ot.orderTypeId === orderTypeId) ?? false;

export const getOrderTypesOptions = (
    orderTypes: Opt<Array<OrderTypeItem>>,
    isBreeder: boolean,
    order: OrderDetails,
): Options<number> => {
    const options = codeTableItemsToOptions(orderTypes);

    if (isBreeder) {
        options.forEach(option => {
            option.disabled = isInOrder(option.value, order);
        });
    }

    return options;
};
