import {chipIdRegexGen} from 'api/gen/ChipId';
import {t} from 'translations';
import {PaternityValidator} from 'utils/paternity-validator';
import {Errors} from 'utils/validator';

import {SampleSheetFormValues} from './sample-sheet-form-values';

const te = t('orders/SampleSheetForm');

export const validate = (values: SampleSheetFormValues): Errors<SampleSheetFormValues> => {
    const validator = new PaternityValidator(values);

    validator.nonEmpty('chipId', te('chipId'));
    validator.pattern('chipId', chipIdRegexGen(), te('chipId'));

    return validator.generateErrorsObject();
};
