import {Nullable} from '@fl/cmsch-fe-library';
import React, {FC, memo, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {Modal} from 'common/layout';
import {TFunction, useOurTranslation} from 'translations';
import {getSexOptions} from 'utils/get-sex-options';

import {orderAction} from '../../model/action';
import {simpleOrdersSelector} from '../../model/selector';
import {Parent} from '../../types/parent';
import {ValidateAnimal} from '../../types/validate-animal-type';
import {AnimalDetailsForm} from '../AnimalDetailsForm';

interface Props {
    modalOpen: boolean;
    td: TFunction<'orders/newBulkOrder'>;
    handleCancel(): void;
}

const AnimalsDetailsModalBase: FC<Props> = props => {
    const {
        modalOpen,
        handleCancel,
        td,
    } = props;

    const dispatch = useDispatch();
    const motherAlreadyAnalyzed = useSelector(simpleOrdersSelector.motherAlreadyAnalyzed);
    const fatherAlreadyAnalyzed = useSelector(simpleOrdersSelector.fatherAlreadyAnalyzed);
    const isNewOrderAnimalValid = useSelector(simpleOrdersSelector.isNewOrderAnimalValid);
    const newOrderEarNumberWarning = useSelector(simpleOrdersSelector.newOrderEarNumberWarning);
    const isAnimalDetailEditing = useSelector(simpleOrdersSelector.isAnimalDetailEditing);

    const handleFormSubmit = useCallback(() => {
        dispatch(orderAction.addAnimalToBulkOrderWithValidation());
    }, [dispatch]);

    const {t} = useOurTranslation('orders/AnimalDetailsForm');

    const validateAnimal = useCallback(
        (sampleId: Nullable<string>, registry: Nullable<string>, type: ValidateAnimal) => {
            dispatch(orderAction.validateAnimal(sampleId, registry, type));
        }, [dispatch],
    );

    const getLaboratoryNumberBySampleId = useCallback((sampleId: Nullable<string>, parent: Parent) => {
        dispatch(orderAction.getLaboratoryNumberBySampleId(sampleId, parent));
    }, [dispatch]);

    const checkParentRegistry = useCallback((registry: string) => {
        dispatch(orderAction.checkParentRegistry(registry));
    }, [dispatch]);

    const animalSexes = getSexOptions();

    return (
        <Modal
            title={t('simpleCaption')}
            visible={modalOpen}
            onCancel={handleCancel}
            footer={null}
            width={700}
        >
            <AnimalDetailsForm
                onValidateAnimal={validateAnimal}
                onGetLaboratoryNumberBySampleId={getLaboratoryNumberBySampleId}
                onSubmit={handleFormSubmit}
                motherAlreadyAnalyzed={motherAlreadyAnalyzed}
                fatherAlreadyAnalyzed={fatherAlreadyAnalyzed}
                animalSexes={animalSexes}
                isNewOrderAnimalValid={isNewOrderAnimalValid}
                newOrderEarNumberWarning={newOrderEarNumberWarning}
                onCheckParentRegistry={checkParentRegistry}
                submitTitle={isAnimalDetailEditing ? td('edit') : t('add')}
                isBulkOrder
            />
        </Modal>
    );
};

export const AnimalsDetailsModal = memo(AnimalsDetailsModalBase);
