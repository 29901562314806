// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


const requiredPart = t.interface({
  childAlleles: t.string,
  parentAlleles: t.string,
  snpName: t.string,
});

export const ParenthoodVerificationDifferenceSchema = excess(requiredPart);

export interface ParenthoodVerificationDifference extends t.TypeOf<typeof ParenthoodVerificationDifferenceSchema> {}
