// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// Example: NORMAL

export const QtlResultTypeSchema = t.keyof({
  NORMAL: null,
  CARRIER: null,
  FREE: null,
  AFFECTED: null,
  MISSING: null,
  MOTTLED: null,
  MANTLED: null,
  OK: null,
  ALBINO: null,
  NON_DILUTED: null,
  DILUTED: null,
  CREAM: null,
  HH1T: null,
  HH1C: null,
  HH3T: null,
  HH3C: null,
  HH4T: null,
  HH4C: null,
  HH5T: null,
  HH5C: null,
  HH6T: null,
  HH6C: null,
  HH7T: null,
  HH12T: null,
  MH2T: null,
  AA: null,
  AB: null,
  AE: null,
  BB: null,
  BE: null,
  EE: null,
  A1A1: null,
  A1A2: null,
  A2A2: null,
  BL: null,
  BM: null,
  BD: null,
  CD: null,
  BD1_NN: null,
  BD1_NC: null,
  PP: null,
  PC_P: null,
  PC_PC: null,
  PF_P: null,
  PF_PF: null,
  POSITIVE: null,
  MH_POSITIVE: null,
  MH_MH: null,
  F_POSITIVE: null,
  F_F: null,
  WT_WT: null,
  WT_DEL11: null,
  DEL11_DEL11: null,
  DD_RED: null,
  DE_RED: null,
  DE_HETEROZYGOTE_RED: null,
  DE_RED_INDIFFERENT: null,
  D_PLUS_RED: null,
  PLUS_PLUS_RED: null,
  E_PLUS_RED: null,
  EE_RED: null,
  EE_RED_INDIFFERENT: null,
  NH2T: null,
  TL: null,
  TM: null,
  TD: null,
});

export type QtlResultType = t.TypeOf<typeof QtlResultTypeSchema>;
