import {takeLatestF, putAll} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {put, call} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {showSuccessSimple} from 'notifications';
import {t} from 'translations';
import {showBeError} from 'utils/show-be-error';

import {SendOrdersUpdatesAction, importAction} from '../action';
import {translate} from './utils';

function* execute({payload: {data}}: SendOrdersUpdatesAction): SagaIterator {
    yield* put(importAction.setLoading(true));

    const response = yield* call(Api.importOrderTypes, data);

    yield* put(importAction.setLoading(false));

    if (response.isSuccess) {
        yield* put(showSuccessSimple({message: translate('importOrdersUpdatesSuccess')}));
    } else {
        yield putAll(showBeError(response, t('fileImport/screenImport')('titleOrdersUpdates')));
    }
}

export function* sendOrdersUpdatesSaga(): SagaIterator {
    yield takeLatestF('fileImport/SEND_ORDERS_UPDATES', execute);
}
