import {Nullable} from '@fl/cmsch-fe-library';
import React, {FC, memo, useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Opt, testRe} from 'ts-opt';

import {cinRegexGen} from 'api/gen/Cin';
import {LastOrderUserDetails} from 'api/gen/LastOrderUserDetails';
import {OrderCustomerDetails} from 'api/gen/OrderCustomerDetails';
import {FooterButtons} from 'common/buttons';
import {useOurTranslation} from 'translations';
import {useUser} from 'user';
import {useForm} from 'utils/forms';

import {orderAction, simpleOrdersSelector} from '../../model';
import {UserDetailsFormSection, UserDetailsFormSectionOuterProps} from '../UserDetailsFormSection';
import {userDetailsInitialValues} from '../UserDetailsFormSection/user-details-form-section-values';
import {BulkEditOrderFormValues, bulkEditOrderFormName} from './bulk-edit-order-form-values';
import {validate} from './validations';

const initialValues: BulkEditOrderFormValues = {
    userDetails: userDetailsInitialValues,
};

interface Props {
    hasSameAddresses: boolean;
    customers: Array<OrderCustomerDetails>;
    userDetailsFromCIN: Nullable<LastOrderUserDetails>;
    disableAddress: boolean;
    onPrefillFromExisting(value: OrderCustomerDetails): void;
    onGetUserLastOrder(cin: Nullable<string>): void;
    onClose(): void;
    onSubmit(values: BulkEditOrderFormValues): void;
}

const EditUserDetailsForm: FC<Props> = props => {
    const {
        hasSameAddresses,
        customers,
        userDetailsFromCIN,
        disableAddress,
        onPrefillFromExisting,
        onGetUserLastOrder,
        onClose,
        onSubmit,
    } = props;

    const {Form, Fields, submitting, asyncValidating} = useForm({
        form: bulkEditOrderFormName,
        destroyOnUnmount: false,
        forceUnregisterOnUnmount: true,
        keepDirtyOnReinitialize: true,
        initialValues,
        enableReinitialize: true,
        validate,
        onSubmit,
    });

    const prefillFromExisting = useCallback((value: Opt<number>) => {
        onPrefillFromExisting(customers[value.orCrash('prefilled values not available')]);
    }, [customers, onPrefillFromExisting]);

    const dispatch = useDispatch();
    const {isRoleLaborer} = useUser();
    const breeders = useSelector(simpleOrdersSelector.breeders);

    const getBreeders = useCallback(() => {
        dispatch(orderAction.getBreeders());
    }, [dispatch]);

    useEffect(() => {
        if (isRoleLaborer && breeders.isEmpty) getBreeders();
    }, [breeders.isEmpty, getBreeders, isRoleLaborer]);

    const onCinClick = useCallback((value: Opt<string>) => {
        value
            .filter(testRe(cinRegexGen()))
            .onSome(onGetUserLastOrder);
    }, [onGetUserLastOrder]);

    const UserDetailSectionField = Fields.genSection<'userDetails', UserDetailsFormSectionOuterProps>();
    const {t} = useOurTranslation('orders/UserDetailsForm');
    const userDetailsProps = {
        customers,
        showCreatedBy: true,
        breeders: breeders.orNull(),
        userDetailsFromCIN,
        noCin: false,
        inWizard: false,
        showAllInputs: false,
        hasSameAddresses,
        disableAddress,
        isIdRequired: true,
        isPhoneRequired: false,
        onCinClick,
        prefillFromExisting,
        caption: t('stepCaption'),
    };

    return (
        <div data-test-id="bulk-edit-order-form">
            <Form>
                <UserDetailSectionField
                    name="userDetails"
                    Component={UserDetailsFormSection}
                    props={userDetailsProps}
                />

                <FooterButtons
                    disabledSubmit={submitting || Boolean(asyncValidating)}
                    onCancel={onClose}
                    editing
                />
            </Form>
        </div>
    );
};

export const BulkEditOrderForm = memo(EditUserDetailsForm);
