import {GenericFormState} from '@fl/cmsch-fe-library';
import * as O from 'optics-ts';

import {Action} from 'app/actions';
import {CFormReducer} from 'app/form-state';

import {BreederInfoFormValues, breederInfoFormName} from '../components/BreederInfoForm/breeder-info-form-values';

type BreederInfoFormState = GenericFormState<BreederInfoFormValues>;

const breederInfoFormStateO = O.optic<BreederInfoFormState>();

export const dialsFormReducer: CFormReducer = {
    [breederInfoFormName]: (state: BreederInfoFormState, action: Action): BreederInfoFormState => {
        switch (action.type) {
            case 'dials/GET_BREEDER_INFO_CONFIGURATION_SUCCESS': {
                const {breederInfoConfiguration} = action.payload;

                return O.set(breederInfoFormStateO.prop('values'))(breederInfoConfiguration)(state);
            }

            default:
                return state;
        }
    },
};
