// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.75.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.75.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-orders-components-DetailTable-___styles__table___yHewD{overflow:auto;max-height:600px}.src-modules-orders-components-DetailTable-___styles__bottomMarginTbody___jfDJl tbody>tr:last-of-type>th{display:block;margin-bottom:1rem}\n", "",{"version":3,"sources":["webpack://./src/modules/orders/components/DetailTable/styles.sass"],"names":[],"mappings":"AAAA,oEAAO,aAAA,CAAc,gBAAA,CAAiB,yGAA4C,aAAA,CAAc,kBAAA","sourcesContent":[".table{overflow:auto;max-height:600px}.bottomMarginTbody tbody>tr:last-of-type>th{display:block;margin-bottom:1rem}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": "src-modules-orders-components-DetailTable-___styles__table___yHewD",
	"bottomMarginTbody": "src-modules-orders-components-DetailTable-___styles__bottomMarginTbody___jfDJl"
};
export default ___CSS_LOADER_EXPORT___;
