import {putAll, showSuccess, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {t} from 'translations';
import {showBeError} from 'utils/show-be-error';

import {getExportParams} from '../../utils/get-export-params';
import {DeleteOrdersByFilterAction, orderAction} from '../action';
import {orderApi} from '../api';
import {getOrderTableParams, getSelectedOrders} from './selectors';

const te = t('orders/ListActions');

function* execute({payload: {withoutSelected}}: DeleteOrdersByFilterAction): SagaIterator {
    const tableDataParams = yield* select(getOrderTableParams);
    const excludedOrderIds = withoutSelected ? yield* select(getSelectedOrders) : undefined;
    const exportParams = getExportParams(tableDataParams, undefined, excludedOrderIds);
    yield* put(orderAction.setListActionActive('deleteOrders', true));

    const response = yield* call(orderApi.deleteOrders, exportParams);

    if (response.isSuccess) {
        yield* put(orderAction.getOrders(opt({action: 'refresh'})));
        yield* put(showSuccess(te('deleteOrders'), t('orders/sagas')('success')));
    } else {
        yield putAll(showBeError(response, te('deleteOrders')));
    }
    yield* put(orderAction.setListActionActive('deleteOrders', false));
}

export function* deleteOrdersByFilterSaga(): SagaIterator {
    yield takeLatestF('order/DELETE_ORDERS_BY_FILTER', execute);
}
