// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { EmailTemplateDescription, EmailTemplateDescriptionSchema } from 'api/gen/EmailTemplateDescription';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { EmailTemplateSubject, EmailTemplateSubjectSchema } from 'api/gen/EmailTemplateSubject';

const requiredPart = t.interface({
  body: t.string,
  description: EmailTemplateDescriptionSchema,
  subject: EmailTemplateSubjectSchema,
});

export const EmailTemplateUpdateSchema = excess(requiredPart);

export interface EmailTemplateUpdate extends t.TypeOf<typeof EmailTemplateUpdateSchema> {}
