import {t} from 'translations';
import {PaternityValidator} from 'utils/paternity-validator';
import {Errors} from 'utils/validator';

import {BreederInfoFormValues} from './breeder-info-form-values';

const captionMaxLength = 25;

const te = t('dials/breederInfoForm');

export const validate = (values: BreederInfoFormValues): Errors<BreederInfoFormValues> => {
    const validator = new PaternityValidator(values);

    validator.nonEmpty('caption', te('caption'));
    validator.maxStringLength('caption', captionMaxLength, te('caption'));
    validator.nonEmpty('content', te('content'));

    return validator.generateErrorsObject();
};
