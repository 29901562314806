import React, {FC, useCallback, useEffect} from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps, withRouter} from 'react-router';
import {Dispatch} from 'redux';
import * as routerActions from 'redux-first-history';
import {Opt, pipe} from 'ts-opt';

import {OrderDetails} from 'api/gen/OrderDetails';
import {OrderDetailsForGenotypingRequest} from 'api/gen/OrderDetailsForGenotypingRequest';
import {Action} from 'app/actions';
import {State} from 'app/state';
import {Container, MainPanel, PageHeading} from 'layout';
import {useOurTranslation} from 'translations';
import {useUser} from 'user';
import {formHelpers} from 'utils/forms';

import {EditUserAndOrderView} from '../components/EditUserAndOrderView';
import {GenerateProtocolFormValues} from '../components/GenerateProtocolForm/generate-protocol-form-values';
import {GenerateRequestActions} from '../components/GenerateRequestActions';
import {GenerateRequestForm} from '../components/GenerateRequestForm';
import {GenerateRequestFormValues} from '../components/GenerateRequestForm/generate-request-form-values';
import {orderAction} from '../model';

interface StateProps {
    order: Opt<OrderDetails>;
    requestData: Opt<OrderDetailsForGenotypingRequest>;
    generateProtocolFormValues: Opt<GenerateProtocolFormValues>;
}

interface DispatchProps {
    getOrder(orderId: number): void;
    goBack(): void;
    generateGenotypingRequest(orderId: number, data: GenerateRequestFormValues): void;
    getDataForGenotypingRequest(orderId: number): void;
    initializeGenerateRequestFormValues(requestData: OrderDetailsForGenotypingRequest): void;
    resetGenerateRequestForm(): void;
}

interface Match {
    id: string;
}

type Props = StateProps & DispatchProps & RouteComponentProps<Match>;

const GenerateRequestBase: FC<Props> = props => {
    const {
        order: orderOpt,
        requestData,
        match: {params: {id}},
        getOrder,
        goBack,
        generateGenotypingRequest,
        getDataForGenotypingRequest,
        initializeGenerateRequestFormValues,
        resetGenerateRequestForm,
    } = props;

    const {isRoleBreeder, isRoleBreedingUnion, isRoleLaborer} = useUser();
    const {t} = useOurTranslation('orders/screenDetail');
    const orderId = Number(id);

    useEffect(() => {
        getOrder(orderId);
    }, [getOrder, orderId]);

    useEffect(() => {
        if (isRoleLaborer || isRoleBreeder) getDataForGenotypingRequest(orderId);
    }, [getDataForGenotypingRequest, isRoleBreeder, isRoleLaborer, orderId]);

    useEffect(() => {
        requestData.map(initializeGenerateRequestFormValues);
    }, [initializeGenerateRequestFormValues, requestData]);

    const onGenerateRequestSubmit = useCallback((values: GenerateRequestFormValues) => {
        generateGenotypingRequest(orderId, values);
    }, [generateGenotypingRequest, orderId]);

    return (
        <Container>
            {orderOpt.map(order => (
                <MainPanel key={order.id}>
                    <div className="row">
                        <div className="col-12">
                            <PageHeading>
                                <h1>{t('title')} {order.sampleId}</h1>
                            </PageHeading>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-lg-5 col-xl-4 order-lg-2">
                            <GenerateRequestActions
                                onGoBackClick={goBack}
                            />
                        </div>
                        <div className="col-12 col-lg-7 col-xl-8 order-lg-1">
                            <div>
                                {!isRoleBreedingUnion && requestData.nonEmpty && (
                                    <GenerateRequestForm
                                        onSubmit={onGenerateRequestSubmit}
                                        onCancel={resetGenerateRequestForm}
                                    />
                                )}
                            </div>
                            <EditUserAndOrderView
                                order={order}
                                identifier="editOrder"
                            />
                        </div>
                    </div>
                </MainPanel>
            )).orNull()}
        </Container>
    );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    getOrder: (orderId: number): Action => dispatch(orderAction.getOrder(orderId)),
    goBack: (): void => void dispatch(routerActions.goBack()),
    generateGenotypingRequest: (orderId: number, data: GenerateRequestFormValues): Action =>
        dispatch(orderAction.generateGenotypingRequest(orderId, data)),
    getDataForGenotypingRequest: (orderId: number): Action =>
        dispatch(orderAction.getDataForGenotypingRequest(orderId)),
    initializeGenerateRequestFormValues: (requestData: OrderDetailsForGenotypingRequest): Action =>
        dispatch(formHelpers.initialize('generateRequest', requestData)),
    resetGenerateRequestForm: (): Action => dispatch(formHelpers.reset('generateRequest')),
});

const mapStateToProps = (state: State): StateProps => ({
    order: state.orders.current,
    requestData: state.orders.requestData,
    generateProtocolFormValues: formHelpers.formValues('generateProtocol')(state),
});

export const GenerateRequest = pipe(
    GenerateRequestBase,
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
);
