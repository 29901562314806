import {ActionSettings, ToolbarButton, Options, TableDataRequest} from '@fl/cmsch-fe-library';
import {isEmpty} from 'lodash/fp';
import React, {Fragment, FC, useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Opt} from 'ts-opt';

import {SnpView} from 'api/gen/SnpView';
import {Action} from 'app/actions';
import {snpActions} from 'snp-management/model';
import {simpleSnpManagementSelector} from 'snp-management/model/selector';
import {useOurTranslation} from 'translations';
import {ConnectedTable} from 'utils/tables';

import {SnpModal} from '../snp-modal';
import {columns} from './columns';

export const SnpPage: FC = () => {
    const tableIsLoading = useSelector(simpleSnpManagementSelector.tableIsLoading);
    const selected = useSelector(simpleSnpManagementSelector.selected);
    const categories = useSelector(simpleSnpManagementSelector.categories);

    const dispatch = useDispatch();

    const load = useCallback((tableDataRequest: Opt<TableDataRequest<SnpView>>): Action =>
        dispatch(snpActions.loadSnp(tableDataRequest)), [dispatch]);

    const {t} = useOurTranslation('snpManagement/SnpTable');

    const categoriesOptions: Options<number> = categories.orElse([]).map(x => ({value: x.id, label: x.name}));

    const actionSettings: ActionSettings<SnpView> = useMemo(
        () => ({
            selectedIds: selected,
            onSelectedIdsChange: (ids: Array<number>): Action => dispatch(snpActions.selectIds(ids)),
            confirmRowRemove: true,
        }), [dispatch, selected],
    );
    const onRegenereateClick = useCallback(()=> {
        dispatch(snpActions.regenerateSnp());
    }, [dispatch]);

    const headerButtons = useMemo(
        (): Array<ToolbarButton> => {
            const noSelection = isEmpty(selected);
            const numberOfSelected = selected.length;

            return [
                {
                    icon: 'retweetOutlined',
                    loading: tableIsLoading,
                    title: t('tableHeaders.regenerate'),
                    visuals: 'primary',
                    confirmClick: true,
                    confirmTitle: noSelection
                        ? t('tableHeaders.regenerateTitleNoSelected')
                        : t('tableHeaders.regenerateTitle', {numberOfSelected}),
                    onClick: onRegenereateClick,
                    testId: 'snp-regenerate-button',
                },
                {
                    disabled: false,
                    icon: 'plusOutlined',
                    loading: tableIsLoading,
                    onClick: (): Action => dispatch(snpActions.setShowCreateSnp()),
                    title: t('tableHeaders.addSnp'),
                    visuals: 'primary',
                    testId: 'snp-create-record-button',
                },
                {
                    disabled: false,
                    icon: 'plusOutlined',
                    loading: tableIsLoading,
                    onClick: (): Action => dispatch(snpActions.setShowCreateSnpCategory()),
                    title: t('tableHeaders.addCategory'),
                    visuals: 'primary',
                    testId: 'snp-create-category-button',
                },
                {
                    disabled: noSelection,
                    icon: 'closeOutlined',
                    loading: tableIsLoading,
                    onClick: (): Action => dispatch(snpActions.deselectAll()),
                    title: `${t('tableHeaders.removeSelected')} (${selected.length})`,
                    visuals: 'primary',
                    testId: 'snp-cancel-selection-button',
                },
            ];
        }, [tableIsLoading, t, selected, onRegenereateClick, dispatch],
    );

    return (
        <Fragment>
            <ConnectedTable
                tableId="snpManagement"
                columns={columns(t, categoriesOptions)}
                rowDataPageSelector={simpleSnpManagementSelector.pageTableData}
                onFetchTableData={load}
                actionSettings={actionSettings}
                headerButtons={headerButtons}
                rowActionsOnRight
            />
            <SnpModal />
        </Fragment>
    );
};
