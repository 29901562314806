import {formatSize, Options, TableColumn} from '@fl/cmsch-fe-library';

import {AssociationType} from 'api/gen/AssociationType';
import {GenotypeExport} from 'api/gen/GenotypeExport';
import {TFunction} from 'translations';

const getAssociationTypeOptions = (t: TFunction<'genotypeExports/GenotypesTable'>): Options<AssociationType> => [
    {label: t('associationTypes.holstein'), value: 'HOLSTEIN'},
    {label: t('associationTypes.meat'), value: 'MEAT'},
];

const formatFileSize = (value: number): string => value ? formatSize(value) : '';

const createLaborerColumns = (t: TFunction<'genotypeExports/GenotypesTable'>): Array<TableColumn<GenotypeExport>> => [
    {
        field: 'associationType',
        type: 'option',
        column: t('columnHeaders.association'),
        tooltip: t('columnHeadersTooltip.association'),
        filterTypes: ['multiSelectString', 'null'],
        options: getAssociationTypeOptions(t),
        width: 128,
    },
];

// eslint-disable-next-line max-lines-per-function
export const createColumns = (
    t: TFunction<'genotypeExports/GenotypesTable'>,
    isRoleLaborer: boolean,
): Array<TableColumn<GenotypeExport>> => [
    {
        field: 'created',
        type: 'date',
        dateFormat: 'datetime',
        column: t('columnHeaders.created'),
        tooltip: t('columnHeadersTooltip.created'),
        width: 181,
    },
    {
        field: 'createdBy',
        type: 'string',
        column: t('columnHeaders.createdBy'),
        tooltip: t('columnHeadersTooltip.createdBy'),
        width: 233,
    },
    {
        field: 'fileKey',
        type: 'string',
        column: t('columnHeaders.fileKey'),
        tooltip: t('columnHeadersTooltip.fileKey'),
        width: 302,
        filterTypes: ['null'],
    },
    {
        field: 'fileSize',
        type: 'number',
        column: t('columnHeaders.fileSize'),
        tooltip: t('columnHeadersTooltip.fileSize'),
        formatValue: formatFileSize,
        width: 97,
        filterTypes: ['null'],
    },
    {
        field: 'animalsCount',
        type: 'number',
        column: t('columnHeaders.animalsCount'),
        tooltip: t('columnHeadersTooltip.animalsCount'),
        width: 68,
        verticalName: true,
    },
    ...isRoleLaborer ? createLaborerColumns(t) : [],
];
