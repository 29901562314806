// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// Example: CZECH_FLECKVIEH

export const AssociationTypeSchema = t.keyof({
  CZECH_FLECKVIEH: null,
  HOLSTEIN: null,
  MEAT: null,
  DIARY: null,
  NORMAN: null,
  OTHER: null,
  UNDEFINED: null,
});

export type AssociationType = t.TypeOf<typeof AssociationTypeSchema>;
