// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { GlobalEarTag, GlobalEarTagSchema } from 'api/gen/GlobalEarTag';

const requiredPart = t.interface({
  childSampleId: GlobalEarTagSchema,
  fathersSampleId: GlobalEarTagSchema,
  mothersSampleId: GlobalEarTagSchema,
});

export const SampleIdTrinitySchema = excess(requiredPart);

export interface SampleIdTrinity extends t.TypeOf<typeof SampleIdTrinitySchema> {}
