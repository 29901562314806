import {LoadingType} from '../types/loading-type';

export interface AppState {
    loadingContent: Array<LoadingType>;
    localLoadingContent: Array<LoadingType>;
    urlForLoader: string;
}

export const initialAppState: AppState = {
    loadingContent: [],
    localLoadingContent: [],
    urlForLoader: '',
};
