import {Nullable} from '@fl/cmsch-fe-library';

import {OrderUserDetails} from 'api/gen/OrderUserDetails';
import {BulkEditOrderFormValues} from 'orders/components/BulkEditOrderForm/bulk-edit-order-form-values';

import {AddressFormSectionValues} from '../types/address-form-section-values';
import {transformFormToAddress} from './transform-address';

export const hasInitialValues = (address: Nullable<AddressFormSectionValues> | undefined): boolean =>
    address?.street === '' || address?.zip === '' || address?.city === '';

export const parseBulkEditOrderFormValues = (values: BulkEditOrderFormValues): Nullable<OrderUserDetails> => {
    const {userDetails} = values;
    const address = transformFormToAddress(userDetails?.address);
    const billingAddress = transformFormToAddress(userDetails?.billingAddress);
    if (
        address
        && userDetails?.customerName
        && userDetails.email
        && userDetails.firstname
        && userDetails.lastname
        && userDetails.id
    ) {
        return {
            id: userDetails.id,
            address,
            billingAddress: billingAddress && !hasInitialValues(userDetails.billingAddress)
                ? billingAddress
                : address,
            customerName: userDetails.customerName,
            vatNumber: userDetails.vatNumber,
            email: userDetails.email,
            firstname: userDetails.firstname,
            cin: userDetails.cin,
            lastname: userDetails.lastname,
            phone: userDetails.phone,
        };
    } else {
        return null;
    }
};
