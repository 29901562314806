// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { ChipId, ChipIdSchema } from 'api/gen/ChipId';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { GlobalEarTag, GlobalEarTagSchema } from 'api/gen/GlobalEarTag';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { LabNumber, LabNumberSchema } from 'api/gen/LabNumber';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { LineRegistry, LineRegistrySchema } from 'api/gen/LineRegistry';

const requiredPart = t.interface({
  chipId: t.union([ChipIdSchema, t.null]),
  earTag: t.union([GlobalEarTagSchema, t.null]),
  laboratoryNumber: t.union([LabNumberSchema, t.null]),
  lineRegistry: t.union([LineRegistrySchema, t.null]),
  validateExistingOrder: t.boolean,
});

export const OrderValidationRequestSchema = excess(requiredPart);

export interface OrderValidationRequest extends t.TypeOf<typeof OrderValidationRequestSchema> {}
