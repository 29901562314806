// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// The code of the e-mail template.
// Example: "ORDER_TYPE_UPDATE"

export const emailTemplateCodeMinLength = 1;
export const emailTemplateCodeMaxLength = 255;

export const EmailTemplateCodeSchema = t.refinement(t.string, n => n.length >= emailTemplateCodeMinLength && n.length <= emailTemplateCodeMaxLength, 'EmailTemplateCode');

export type EmailTemplateCode = string;
