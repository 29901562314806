import {NullableKeys} from '@fl/cmsch-fe-library';

import {PositiveLong} from 'api/gen/PositiveLong';

export const createSnpFormName = 'createSnp';

export type CreateSnpFormValues = NullableKeys<{
    name: string,
    categoryId: PositiveLong,
}>;
