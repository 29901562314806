import React from 'react';
import {Route} from 'react-router-dom';

import {Other} from './screens';

export const otherRoutesWithoutFetching: Array<RegExp> = [/^\/other$/, /^\/administration\/other$/];

export const otherRoutes = [
    (
        <Route
            exact
            path="/other"
            component={Other}
            key="other"
        />
    ),
    (
        <Route
            exact
            path="/administration/other"
            component={Other}
            key="other"
        />
    ),
];
