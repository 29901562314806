// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


const requiredPart = t.interface({
  snpMeasurements: t.array(t.string),
  snpName: t.string,
});

export const MeasurementSchema = excess(requiredPart);

export interface Measurement extends t.TypeOf<typeof MeasurementSchema> {}
