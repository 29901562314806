/* eslint-disable import/no-unused-modules */
import {LoadingType} from 'app/types/loading-type';

export const appAction = {
    addLoadingContent: (id: LoadingType) => ({
        type: 'app/ADD_LOADING_CONTENT',
        payload: id,
    }) as const,
    addLoadingContentSuccess: (id: LoadingType) => ({
        type: 'app/ADD_LOADING_CONTENT_SUCCESS',
        payload: id,
    }) as const,
    removeLoadingContent: (id: LoadingType) => ({
        type: 'app/REMOVE_LOADING_CONTENT',
        payload: id,
    }) as const,
    changeLoadersUrl: (url: string) => ({
        type: 'app/CHANGE_LOADERS_URL',
        payload: url,
    }) as const,
    clearLoadingContent: () => ({
        type: 'app/CLEAR_LOADING_CONTENT',
    }) as const,
};

export type AddLoadingContentAction = ReturnType<typeof appAction.addLoadingContent>;
export type AddLoadingContentSuccessAction = ReturnType<typeof appAction.addLoadingContentSuccess>;
export type RemoveLoadingContentAction = ReturnType<typeof appAction.removeLoadingContent>;
export type ChangeLoadersUrlAction = ReturnType<typeof appAction.changeLoadersUrl>;
export type ClearLoadingContentAction = ReturnType<typeof appAction.clearLoadingContent>;

export type AppAction =
    | AddLoadingContentAction
    | ClearLoadingContentAction
    | AddLoadingContentSuccessAction
    | RemoveLoadingContentAction
    | ChangeLoadersUrlAction
;
