import * as O from 'optics-ts';

import {Action} from 'app/actions';

import {GenotypeExportsState, initialGenotypeExportsState} from './state';

const genotypeExportsStateO = O.optic<GenotypeExportsState>();

export const genotypeExportsReducer = (
    state: GenotypeExportsState = initialGenotypeExportsState,
    action: Action,
): GenotypeExportsState => {
    switch (action.type) {
        case 'genotypesExports/GET_GENOTYPE_EXPORTS_SUCCESS': {
            const {pageGenotypeExports} = action.payload;

            return O.set(genotypeExportsStateO.prop('pageGenotypeExports'))(pageGenotypeExports)(state);
        }

        default:
            return state;
    }
};
