// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { AnimalFullName, AnimalFullNameSchema } from 'api/gen/AnimalFullName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { AnimalName, AnimalNameSchema } from 'api/gen/AnimalName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Breed, BreedSchema } from 'api/gen/Breed';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Date, DateSchema } from 'api/gen/Date';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { GlobalEarTag, GlobalEarTagSchema } from 'api/gen/GlobalEarTag';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { LabNumber, LabNumberSchema } from 'api/gen/LabNumber';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { LineRegistry, LineRegistrySchema } from 'api/gen/LineRegistry';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { SexType, SexTypeSchema } from 'api/gen/SexType';

const requiredPart = t.interface({
  breed: t.union([BreedSchema, t.null]),
  dob: t.union([DateSchema, t.null]),
  fathersLaboratoryNumber: t.union([LabNumberSchema, t.null]),
  fathersRegistry: t.union([LineRegistrySchema, t.null]),
  fathersSampleId: t.union([GlobalEarTagSchema, t.null]),
  fullName: t.union([AnimalFullNameSchema, t.null]),
  hasMeasurements: t.boolean,
  laboratoryNumber: t.union([LabNumberSchema, t.null]),
  mothersLaboratoryNumber: t.union([LabNumberSchema, t.null]),
  mothersSampleId: t.union([GlobalEarTagSchema, t.null]),
  name: t.union([AnimalNameSchema, t.null]),
  registry: t.union([LineRegistrySchema, t.null]),
  sampleId: GlobalEarTagSchema,
  sex: t.union([SexTypeSchema, t.null]),
  twin: t.union([t.boolean, t.null]),
});

export const OrderAnimalDetailsSchema = excess(requiredPart);

export interface OrderAnimalDetails extends t.TypeOf<typeof OrderAnimalDetailsSchema> {}
