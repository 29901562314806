import React from 'react';
import {Route} from 'react-router-dom';

import {List} from './screens';

export const dialsRoutesWithoutFetching: Array<RegExp> = [];

export const dialsRoutes = [
    (
        <Route
            exact
            path="/dials"
            component={List}
            key="dials-list"
        />
    ),
    (
        <Route
            exact
            path="/administration/dials"
            component={List}
            key="dials-list"
        />
    ),
];
