import {barcodeRegexGen} from 'api/gen/Barcode';
import {t} from 'translations';
import {PaternityValidator} from 'utils/paternity-validator';
import {Errors} from 'utils/validator';

import {FindOrderFormValues} from './find-order-form-values';

export const validate = (values: FindOrderFormValues): Errors<FindOrderFormValues> => {
    const validator = new PaternityValidator(values);

    validator.pattern('barcode', barcodeRegexGen(), t('orders/FindOrderForm')('barcode'));

    return validator.generateErrorsObject();
};
