import {Table, TableColumn, zipWithIndex} from '@fl/cmsch-fe-library';
import {concat, fromPairs, isEmpty} from 'lodash/fp';
import React, {FC, useMemo, useState, useEffect} from 'react';
import {pipe, map} from 'ts-opt';

import {Measurement} from 'api/gen/Measurement';
import {Measurements} from 'api/gen/Measurements';
import {Loader} from 'common/layout';
import {TFunction, useOurTranslation} from 'translations';

interface MeasurementRow {
    [key: string]: string | number;
    id: number;
    snpName: string;
}

const getTableColumns = (
    sampleIds: Array<string>,
    t: TFunction<'animals/ComparisonResult'>,
): Array<TableColumn<MeasurementRow>> =>
    pipe(
        sampleIds,
        zipWithIndex,
        map(([id, index]: [string, number]): TableColumn<MeasurementRow> => ({
            column: id,
            field: `measurement${index}`,
            type: 'reactNode',
            width: 352,
        })),
        concat<TableColumn<MeasurementRow>>({
            column: t('snpName'),
            field: 'snpName',
            type: 'reactNode',
            width: 352,
        }),
    );

const convertMeasurementsToRow = (snpMeasurements: Array<string>): Record<string, string> =>
    pipe(
        snpMeasurements,
        zipWithIndex,
        map(([measurement, index]: [string, number]) => [`measurement${index}`, measurement]),
        fromPairs,
    );

const getTableRows = (measurements: Array<Measurement>): Array<MeasurementRow> =>
    pipe(
        measurements,
        zipWithIndex,
        map(([m, idx]: [Measurement, number]): MeasurementRow => ({
            id: idx,
            snpName: m.snpName,
            ...convertMeasurementsToRow(m.snpMeasurements),
        })),
    );

interface Props {
    measurements: Measurements;
    resetMeasurementsAndCategories(): void;
}

export const ComparisonResult: FC<Props> = props => {
    const {measurements, resetMeasurementsAndCategories} = props;
    const [isLoading, setIsLoading] = useState(true);
    const {t} = useOurTranslation('animals/ComparisonResult');
    const columns = useMemo(() =>
        getTableColumns(measurements.sampleIds, t),
    [measurements, t]);
    const rows = useMemo(() =>
        getTableRows(measurements.measurements),
    [measurements]);

    useEffect(() => {
        if (!isEmpty(measurements.measurements)) {
            setIsLoading(false);
        }
    }, [measurements.measurements]);

    return isLoading
        ? (
            <Loader
                show={isLoading}
            />
        )
        : (
            <Table
                columns={columns}
                rows={rows}
                tableId="comparisonResult"
                size="small"
                onUnmount={resetMeasurementsAndCategories}
            />
        );
};
