import React, {FC, useCallback, useEffect} from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps} from 'react-router';
import {Dispatch} from 'redux';
import * as routerActions from 'redux-first-history';
import {pipe} from 'ts-opt';

import {AnimalDetails} from 'api/gen/AnimalDetails';
import {FindAnimalQueryParams} from 'api/gen/Api';
import {State} from 'app/state';
import {Container, MainPanel, PageHeading, Panel} from 'layout';
import {useOurTranslation} from 'translations';
import {formHelpers} from 'utils/forms';

import {AnimalEditActions} from '../components/AnimalEditActions';
import {AnimalEditForm} from '../components/AnimalEditForm';
import {animalsAction} from '../model';

interface StateProps {
    animal: AnimalDetails | null;
    refreshFatherInProgress: boolean;
}

interface DispatchProps {
    getAnimal(animalId: number): void;
    updateAnimal(animalId: number): void;
    refreshFather(params: FindAnimalQueryParams): void;
    resetAnimal(): void;
    goBack(): void;
    goTo(path: string): void;
    resetEditForm(): void;
}

interface Match {
    id: string;
}

type Props = StateProps & DispatchProps & RouteComponentProps<Match>;

const EditBase: FC<Props> = props => {
    const {
        animal,
        refreshFather,
        goBack,
        goTo,
        updateAnimal,
        getAnimal,
        resetAnimal,
        match,
        resetEditForm,
        refreshFatherInProgress,
    } = props;
    const {t} = useOurTranslation('animals/screenEdit');
    const animalId = parseInt(match.params.id, 10);

    useEffect(() => {
        resetAnimal();
        getAnimal(animalId);

        return (): void => {
            resetAnimal();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onSubmit = useCallback(() => {
        updateAnimal(animalId);
    }, [updateAnimal, animalId]);

    return (
        <Container>
            <MainPanel>
                <div className="row">
                    <div className="col-12">
                        <PageHeading>
                            <h1>{t('title')}</h1>
                        </PageHeading>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-md-3 order-md-2 mb-4 mb-md-0">
                        <AnimalEditActions
                            animalId={animalId}
                            goBack={goBack}
                            goTo={goTo}
                        />
                    </div>

                    <div className="col-12 col-md-9 order-md-1">
                        <Panel title={t('editData')}>
                            {animal && (
                                <AnimalEditForm
                                    initialValues={animal}
                                    onSubmit={onSubmit}
                                    onRefreshFather={refreshFather}
                                    onCancel={resetEditForm}
                                    refreshFatherInProgress={refreshFatherInProgress}
                                />
                            )}
                        </Panel>
                    </div>
                </div>
            </MainPanel>
        </Container>
    );
};

const mapStateToProps = (state: State): StateProps => ({
    animal: state.animals.animal,
    refreshFatherInProgress: state.animals.loading.includes('refreshFatherInProgress'),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    goBack: (): void => void dispatch(routerActions.goBack()),
    goTo: (path: string): void => void dispatch(routerActions.push(path)),
    getAnimal: (animalId: number): void => void dispatch(animalsAction.getAnimal(animalId, true)),
    updateAnimal: (animalId: number): void => void dispatch(animalsAction.updateAnimal(animalId)),
    refreshFather: (params: FindAnimalQueryParams): void => void dispatch(animalsAction.refreshFather(params)),
    resetAnimal: (): void => void dispatch(animalsAction.resetAnimal()),
    resetEditForm: (): void => void dispatch(formHelpers.reset('animalEdit')),
});

export const Edit = pipe(
    EditBase,
    connect(mapStateToProps, mapDispatchToProps),
);
