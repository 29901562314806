// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// Example: MALE

export const SexTypeSchema = t.keyof({
  MALE: null,
  FEMALE: null,
});

export type SexType = t.TypeOf<typeof SexTypeSchema>;
