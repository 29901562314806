import {putAll, takeLeadingF} from '@fl/cmsch-fe-library';
import {findKey, upperCase} from 'lodash/fp';
import {SagaIterator} from 'redux-saga';
import {put, call, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {LanguageSchema} from 'api/gen/Language';
import {appAction} from 'app/model/action';
import {simpleAppSelector} from 'app/model/selector';
import {t} from 'translations';
import {simpleUserSelector} from 'user/model/selector';
import {languageEnum} from 'user/utils/language-enum';
import {languageStore} from 'utils/language-store';
import {showBeError} from 'utils/show-be-error';

import {GetCurrentUserAction, userAction} from '../action';

function* execute(_action: GetCurrentUserAction): SagaIterator {
    const currentUser = yield* select(simpleUserSelector.currentUser);
    const loading = yield* select(simpleAppSelector.loadingContent);
    if (currentUser.isEmpty && !loading.includes('currentUser')) {
        yield* put(appAction.addLoadingContent('currentUser'));

        const response = yield* call(Api.getUserDetails);

        if (response.isSuccess) {
            const {data} = response;
            const {language} = data;

            yield* put(userAction.getCurrentUserSuccess(data));

            const lang = upperCase(findKey(val => val === language, languageEnum) || '');
            if (lang && LanguageSchema.is(lang)) {
                languageStore.set(lang);
            } else {
                // eslint-disable-next-line no-console
                console.error(`Invalid language '${language}'.`, languageEnum);
            }
            yield* put(appAction.removeLoadingContent('currentUser'));
        } else {
            yield putAll(showBeError(response, t('user/sagas')('getCurrentUser')));
        }
    }
}

export function* getCurrentUserSaga(): SagaIterator {
    yield takeLeadingF('user/GET_CURRENT_USER', execute);
}
