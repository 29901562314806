import {Nullable} from '@fl/cmsch-fe-library';
import React, {FC, memo} from 'react';

import {LastOrderUserDetails} from 'api/gen/LastOrderUserDetails';
import {OrderCustomerDetails} from 'api/gen/OrderCustomerDetails';
import {User} from 'api/gen/User';
import {FooterButtons} from 'common/buttons';
import {useOurTranslation} from 'translations';
import {useForm} from 'utils/forms';

import {useOnCinClick} from '../../../../hooks/use-on-cin-click';
import {usePrefillFromExisting} from '../../../../hooks/use-prefill-from-existing';
import {newOrderInitialValues, NewOrderFormValues, newOrderFormName} from '../NewForm/new-order-form-values';
import {validate} from '../NewForm/validations';
import {UserDetailsFormSection, UserDetailsFormSectionOuterProps} from '../UserDetailsFormSection';

interface Props {
    hasSameAddresses: boolean;
    customers: Array<OrderCustomerDetails>;
    showCreatedBy: boolean;
    breeders: Array<User>;
    userDetailsFromCIN: Nullable<LastOrderUserDetails>;
    noCin: boolean;
    caption: string;
    onPreviousClick(): void;
    onPrefillFromExisting(value: OrderCustomerDetails): void;
    onGetUserLastOrder(cin: Nullable<string>): void;
    onSubmit(values: NewOrderFormValues): void;
}

const NewUserDetailsFormBase: FC<Props> = props => {
    const {
        hasSameAddresses,
        customers,
        showCreatedBy,
        breeders,
        userDetailsFromCIN,
        noCin,
        onPreviousClick,
        onPrefillFromExisting,
        onGetUserLastOrder,
        onSubmit,
        caption,
    } = props;

    const {t} = useOurTranslation('orders/UserDetailsForm');

    const {Form, Fields} = useForm({
        form: newOrderFormName,
        enableReinitialize: true,
        destroyOnUnmount: false,
        forceUnregisterOnUnmount: true,
        keepDirtyOnReinitialize: true,
        initialValues: newOrderInitialValues,
        validate,
        onSubmit,
    });

    const UserDetailSectionField = Fields.genSection<'userDetails', UserDetailsFormSectionOuterProps>();
    const userDetailsProps = {
        customers,
        showCreatedBy,
        breeders,
        userDetailsFromCIN,
        noCin,
        inWizard: true,
        showAllInputs: false,
        disableAddress: false,
        hasSameAddresses,
        onCinClick: useOnCinClick(onGetUserLastOrder),
        prefillFromExisting: usePrefillFromExisting(customers, onPrefillFromExisting),
        caption,
    };

    return (
        <div data-test-id="new-orders-form-3">
            <Form>
                <UserDetailSectionField
                    name="userDetails"
                    Component={UserDetailsFormSection}
                    props={userDetailsProps}
                />

                <FooterButtons
                    cancelTitle={t('previous')}
                    onCancel={onPreviousClick}
                    submitTitle={t('next')}
                />
            </Form>
        </div>
    );
};

export const NewUserDetailsForm = memo(NewUserDetailsFormBase);
