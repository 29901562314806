import {Nullable, Options} from '@fl/cmsch-fe-library';
import {debounce} from 'lodash/fp';
import React, {FC, memo, useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Opt, testRe} from 'ts-opt';

import {earTagRegexGen} from 'api/gen/EarTag';
import {lineRegistryRegexGen} from 'api/gen/LineRegistry';
import {SexType} from 'api/gen/SexType';
import {FooterButtons} from 'common/buttons';
import {debounceTime} from 'constants/general';
import {useOurTranslation} from 'translations';
import {useForm} from 'utils/forms';

import {orderAction, simpleOrdersSelector} from '../../model';
import {Parent} from '../../types/parent';
import {ValidateAnimal} from '../../types/validate-animal-type';
import {AnimalDetailsFormSection, AnimalDetailsFormSectionProps} from '../AnimalDetailsFormSection';
import {newOrderInitialValues, NewOrderFormValues, newOrderFormName} from '../NewForm/new-order-form-values';
import {validate} from '../NewForm/validations';

interface Props {
    animalSexes: Options<SexType>;
    motherAlreadyAnalyzed: boolean;
    fatherAlreadyAnalyzed: boolean;
    isNewOrderAnimalValid: boolean;
    newOrderEarNumberWarning: Opt<string>;
    isBulkOrder?: boolean;
    submitTitle?: string;
    caption?: string;
    onValidateAnimal(sampleId: Nullable<string>, registry: Nullable<string>, type: ValidateAnimal): void;
    onGetLaboratoryNumberBySampleId(sampleId: Nullable<string>, parent: Parent): void;
    onCheckParentRegistry(registry: string): void;
    onSubmit(values: NewOrderFormValues): void;
}

// eslint-disable-next-line max-lines-per-function
const AnimalDetailsFormBase: FC<Props> = props => {
    const {
        animalSexes,
        motherAlreadyAnalyzed,
        fatherAlreadyAnalyzed,
        isNewOrderAnimalValid,
        newOrderEarNumberWarning,
        onValidateAnimal,
        onSubmit,
        onGetLaboratoryNumberBySampleId,
        onCheckParentRegistry,
        submitTitle,
        isBulkOrder,
        caption,
    } = props;

    const {Form, Fields, pristine, renderErrors} = useForm({
        form: newOrderFormName,
        enableReinitialize: false,
        destroyOnUnmount: false,
        forceUnregisterOnUnmount: true,
        initialValues: newOrderInitialValues,
        validate,
        onSubmit,
    });

    const {t, tCommon} = useOurTranslation('orders/AnimalDetailsForm');
    const {t: tb} = useOurTranslation('orders/OrderDetailsForm');
    const isValidating = useSelector(simpleOrdersSelector.isValidating);
    const dispatch = useDispatch();

    const setIsValidating = useCallback((isValidaing: boolean): void => {
        dispatch(orderAction.isValidating(isValidaing));
    }, [dispatch]);

    const onSampleIdAction = useCallback((sampleId: Opt<string>) => {
        setIsValidating(true);
        sampleId
            .filter(testRe(earTagRegexGen()))
            .onSome(x => onValidateAnimal(x, null, 'child'))
            .onNone(() => setIsValidating(false));
    }, [setIsValidating, onValidateAnimal]);

    const validateDebounced = useMemo(() =>
        debounce(debounceTime, onValidateAnimal),
    [onValidateAnimal]);

    const onRegistryChange = useCallback((registry: Opt<string>) => {
        setIsValidating(true);
        registry
            .filter(testRe(lineRegistryRegexGen()))
            .onSome(x => validateDebounced(null, x, 'child'))
            .onNone(() => setIsValidating(false));
    }, [setIsValidating, validateDebounced]);

    const parentSampleIdChangeDebounce = useMemo(() =>
        debounce(debounceTime, onGetLaboratoryNumberBySampleId),
    [onGetLaboratoryNumberBySampleId]);

    const onFathersSampleIdChange = useCallback((value: Opt<string>) => {
        setIsValidating(true);
        value
            .filter(testRe(earTagRegexGen()))
            .onSome(x => parentSampleIdChangeDebounce(x, 'father'))
            .onNone(() => setIsValidating(false));
    }, [parentSampleIdChangeDebounce, setIsValidating]);

    const onMothersSampleIdChange = useCallback((value: Opt<string>) => {
        setIsValidating(true);
        value
            .filter(testRe(earTagRegexGen()))
            .onSome(x => parentSampleIdChangeDebounce(x, 'mother'))
            .onNone(() => setIsValidating(false));
    }, [parentSampleIdChangeDebounce, setIsValidating]);

    const checkParentRegistryDebounce = useMemo(() =>
        debounce(debounceTime, onCheckParentRegistry),
    [onCheckParentRegistry]);

    const onFathersRegistryChange = useCallback((value: Opt<string>) => {
        setIsValidating(true);
        value
            .filter(testRe(lineRegistryRegexGen()))
            .onSome(checkParentRegistryDebounce)
            .onNone(() => setIsValidating(false));
    }, [checkParentRegistryDebounce, setIsValidating]);

    const AnimalDetailsSectionField = Fields.genSection<'animalDetails', AnimalDetailsFormSectionProps>();
    const animalDetailsProps = {
        animalSexes,
        fatherAlreadyAnalyzed,
        motherAlreadyAnalyzed,
        isNewOrderAnimalValid,
        newOrderEarNumberWarning,
        t,
        tCommon,
        onSampleIdAction,
        onRegistryChange,
        onFathersSampleIdChange,
        onMothersSampleIdChange,
        onFathersRegistryChange,
    };

    return (
        <div data-test-id="new-orders-form">
            <Form>
                {renderErrors()}

                {!isBulkOrder && <legend data-test-id="legend">{caption ?? t('caption')}</legend>}
                {isBulkOrder && (
                    <fieldset>
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <Fields.Input
                                    name="barcode"
                                    label={tb('barcode')}
                                    type="text"
                                />
                            </div>
                        </div>
                    </fieldset>
                )}
                <AnimalDetailsSectionField
                    name="animalDetails"
                    Component={AnimalDetailsFormSection}
                    props={animalDetailsProps}
                />

                <FooterButtons
                    pristine={pristine}
                    submitTitle={submitTitle ?? t('submit')}
                    withoutCancel
                    disabledSubmit={isValidating}
                    submitTooltip={isValidating ? t('submitDisabled') : undefined}
                />
            </Form>
        </div>
    );
};

export const AnimalDetailsForm = memo(AnimalDetailsFormBase);
