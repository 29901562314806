import {getAppInfoSaga} from './get-app-info-saga';
import {synchronizeAnimalsSaga} from './synchronize-animals-saga';
import {updateCatalogListsSaga} from './update-catalog-lists-saga';
import {updatePaternitySaga} from './update-paternity-saga';

export const otherSagas = [
    updateCatalogListsSaga,
    synchronizeAnimalsSaga,
    updatePaternitySaga,
    getAppInfoSaga,
];
