// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.75.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.75.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-layout-components-container-___styles__containerMargin___QWMBP{margin-top:2.5rem!important;margin-bottom:5.5rem!important}@media (max-width: 480px){.src-modules-layout-components-container-___styles__containerMargin___QWMBP{padding:0!important}}.src-modules-layout-components-container-___styles__maxLgWidth___pJWRw{max-width:1200px!important}@media (min-width: 1620px){.src-modules-layout-components-container-___styles__maxXxlWidth___tip9K{width:100%!important;max-width:1950px!important}}\n", "",{"version":3,"sources":["webpack://./src/modules/layout/components/container/styles.sass"],"names":[],"mappings":"AAAA,4EAAiB,2BAAA,CAA6B,8BAAA,CAAgC,0BAAyB,4EAAvG,mBAAA,CAAA,CAA8I,uEAAY,0BAAA,CAA4B,2BAA0B,wEAAa,oBAAA,CAAsB,0BAAA,CAAA","sourcesContent":[".containerMargin{margin-top:2.5rem !important;margin-bottom:5.5rem !important}@media(max-width: 480px){.containerMargin{padding:0 !important}}.maxLgWidth{max-width:1200px !important}@media(min-width: 1620px){.maxXxlWidth{width:100% !important;max-width:1950px !important}}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerMargin": "src-modules-layout-components-container-___styles__containerMargin___QWMBP",
	"maxLgWidth": "src-modules-layout-components-container-___styles__maxLgWidth___pJWRw",
	"maxXxlWidth": "src-modules-layout-components-container-___styles__maxXxlWidth___tip9K"
};
export default ___CSS_LOADER_EXPORT___;
