// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { GraphData, GraphDataSchema } from 'api/gen/GraphData';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Insemination, InseminationSchema } from 'api/gen/Insemination';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Pregnancy, PregnancySchema } from 'api/gen/Pregnancy';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { YearMonth, YearMonthSchema } from 'api/gen/YearMonth';

const requiredPart = t.interface({
  fleckviehInsemination: InseminationSchema,
  graph: GraphDataSchema,
  holsteinInsemination: InseminationSchema,
  inseminationPeriod: YearMonthSchema,
  pregnancy: PregnancySchema,
  pregnancyPeriod: YearMonthSchema,
});

export const ReproductionStatisticsSchema = excess(requiredPart);

export interface ReproductionStatistics extends t.TypeOf<typeof ReproductionStatisticsSchema> {}
