import {NullableKeys} from '@fl/cmsch-fe-library';

import {Date} from 'api/gen/Date';
import {SexType} from 'api/gen/SexType';

import {AddressFormSectionValues} from '../../types/address-form-section-values';

export const generateProtocolFormName = 'generateProtocol';

export type GenerateProtocolFormValues = NullableKeys<{
    sampleId: string,
    sex: SexType,
    name: string,
    breed: string,
    dateOfBirth: Date,
    registry: string,
    sampleTypeId: number,
    displayLaboratoryNote: boolean,
    mothersSampleId: string,
    mothersLaboratoryNumber: string,
    mothersBreed: string,
    mothersDateOfBirth: Date,
    fathersSampleId: string,
    fathersLaboratoryNumber: string,
    fathersBreed: string,
    fathersRegistry: string,
    fathersDateOfBirth: Date,
    verificationOutcomeTypeId: number,
    testResultTypeIds: Array<number>,
    customerName: string,
    address: AddressFormSectionValues,
}>;

export const generateProtocolInitialValues: GenerateProtocolFormValues = {
    address: null,
    breed: null,
    customerName: null,
    dateOfBirth: null,
    displayLaboratoryNote: null,
    fathersBreed: null,
    fathersDateOfBirth: null,
    fathersLaboratoryNumber: null,
    fathersRegistry: null,
    fathersSampleId: null,
    mothersBreed: null,
    mothersDateOfBirth: null,
    mothersLaboratoryNumber: null,
    mothersSampleId: null,
    name: null,
    registry: null,
    sampleId: null,
    sampleTypeId: null,
    sex: null,
    testResultTypeIds: null,
    verificationOutcomeTypeId: -1,
};
