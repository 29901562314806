import {activateNewBulkOrderEditFormSaga} from './activate-new-bulk-order-edit-form-saga';
import {addAnimalsToBulkOrderSaga} from './add-animal-to-bulk-order-saga';
import {addAnimalsToBulkOrderWithValidationSaga} from './add-animal-to-bulk-order-with-validation-saga';
import {cancelOrdersBillingByFilterSaga} from './cancel-orders-billing-by-filter-saga';
import {cancelOrdersBillingByIdsSaga} from './cancel-orders-billing-by-ids-saga';
import {cancelSubsidiesByFilterSaga} from './cancel-subsidies-by-filter-saga';
import {cancelSubsidiesByIdsSaga} from './cancel-subsidies-by-ids-saga';
import {checkParentLaboratoryNumberSaga} from './check-parent-laboratory-number-saga';
import {checkParentRegistrySaga} from './check-parent-registry-saga';
import {closeOrdersForBillingByFilterSaga} from './close-orders-for-billing-by-filter-saga';
import {closeOrdersForBillingByIdsSaga} from './close-orders-for-billing-by-ids-saga';
import {createBulkOrderSaga} from './create-bulk-order-saga';
import {createGenotypeExportsByFilterSaga} from './create-genotype-export-by-filter-saga';
import {createGenotypeExportByIdsSaga} from './create-genotype-export-by-ids-saga';
import {createOrderSaga} from './create-order-saga';
import {deleteBillingBaseSaga} from './delete-billing-base-saga';
import {deleteOrderSaga} from './delete-order-saga';
import {deleteOrdersByFilterSaga} from './delete-orders-by-filter-saga';
import {deleteOrdersByIdsSaga} from './delete-orders-by-ids-saga';
import {deleteProtocolSaga} from './delete-protocol-saga';
import {downloadBillingBasesSaga} from './download-billing-bases-saga';
import {exportBillingBaseByFilterSaga} from './export-billing-base-by-filter-saga';
import {exportBillingBaseByIdsSaga} from './export-billing-base-by-ids-saga';
import {exportByAssociationByFilterSaga} from './export-by-association-by-filter-saga';
import {exportByAssociationByIdsSaga} from './export-by-association-by-ids-saga';
import {exportCatalogueListsByFilterSaga} from './export-catalogue-lists-by-filter-saga';
import {exportCatalogueListsByIdsSaga} from './export-catalogue-lists-by-ids-saga';
import {exportCdcbByFilterSaga} from './export-cdcb-by-filter-saga';
import {exportCdcbByIdsSaga} from './export-cdcb-by-ids-saga';
import {exportForGermanyByFilterSaga} from './export-for-germany-by-filter-saga';
import {exportForGermanyByIdsSaga} from './export-for-germany-by-ids-saga';
import {exportOrderProtocolSaga} from './export-order-protocol-saga';
import {exportOrdersByFilterSaga} from './export-orders-by-filter-saga';
import {exportOrdersByIdsSaga} from './export-orders-by-ids-saga';
import {exportProtocolsByFilterSaga} from './export-protocols-by-filter-saga';
import {exportProtocolsByIdsSaga} from './export-protocols-by-ids-saga';
import {generateGenotypingRequestSaga} from './generate-genotyping-request-saga';
import {generateProtocolSaga} from './generate-protocol-saga';
import {generateSampleSheetByFilterSaga} from './generate-sample-sheet-by-filter-saga';
import {generateSampleSheetByIdsSaga} from './generate-sample-sheet-by-ids-saga';
import {getBreedersSaga} from './get-breeders-saga';
import {getDataForGenotypingRequestSaga} from './get-data-for-genotyping-request-saga';
import {getDataForProtocolSaga} from './get-data-for-protocol-saga';
import {getOrderSaga} from './get-order-saga';
import {getOrdersCustomersSaga} from './get-orders-customers-saga';
import {getOrdersSaga} from './get-orders-saga';
import {getUserLastOrderSaga} from './get-user-last-order-saga';
import {isolateOrdersByFilterSaga} from './isolate-orders-by-filter-saga';
import {isolateOrdersByIdsSaga} from './isolate-orders-by-ids-saga';
import {refreshFatherSaga} from './refresh-father-saga';
import {refreshMotherSaga} from './refresh-mother-saga';
import {searchAnimalsSaga} from './search-animals-saga';
import {searchOrdersSaga} from './search-orders-saga';
import {updateCustomerSaga} from './update-customer-saga';
import {updateCustomersEmailByFilterSaga} from './update-customers-email-by-filter';
import {updateCustomersEmailByIdsSaga} from './update-customers-email-by-ids';
import {updateOrderSaga} from './update-order-saga';
import {updateOrderCustomersByFilterSaga} from './update-orders-customers-by-filter-saga';
import {updateOrdersCustomersByIdsSaga} from './update-orders-customers-by-ids-saga';
import {updateOrdersTypesByFilterSaga} from './update-orders-types-by-filter-saga';
import {updateOrdersTypesByIdsSaga} from './update-orders-types-by-ids-saga';
import {validateAnimalSaga} from './validate-animal-saga';
import {validateChipIdSaga} from './validate-chipid-saga';
import {validateLaboratoryNumberSaga} from './validate-laboratory-number-saga';
import {verifyPaternitySaga} from './verify-paternity-saga';

export const orderSagas = [
    exportBillingBaseByFilterSaga,
    activateNewBulkOrderEditFormSaga,
    addAnimalsToBulkOrderSaga,
    addAnimalsToBulkOrderWithValidationSaga,
    exportBillingBaseByIdsSaga,
    checkParentLaboratoryNumberSaga,
    checkParentRegistrySaga,
    searchAnimalsSaga,
    createOrderSaga,
    deleteBillingBaseSaga,
    deleteOrderSaga,
    deleteProtocolSaga,
    downloadBillingBasesSaga,
    generateProtocolSaga,
    generateGenotypingRequestSaga,
    getOrderSaga,
    getOrdersCustomersSaga,
    getUserLastOrderSaga,
    getDataForProtocolSaga,
    getDataForGenotypingRequestSaga,
    refreshFatherSaga,
    refreshMotherSaga,
    updateOrderSaga,
    validateChipIdSaga,
    validateAnimalSaga,
    verifyPaternitySaga,
    closeOrdersForBillingByIdsSaga,
    closeOrdersForBillingByFilterSaga,
    updateCustomerSaga,
    getBreedersSaga,
    cancelSubsidiesByFilterSaga,
    cancelSubsidiesByIdsSaga,
    searchOrdersSaga,
    updateOrdersCustomersByIdsSaga,
    updateOrderCustomersByFilterSaga,
    validateLaboratoryNumberSaga,
    getOrdersSaga,
    exportOrderProtocolSaga,
    exportOrdersByIdsSaga,
    exportOrdersByFilterSaga,
    exportByAssociationByIdsSaga,
    exportByAssociationByFilterSaga,
    exportForGermanyByIdsSaga,
    exportForGermanyByFilterSaga,
    exportCatalogueListsByIdsSaga,
    exportCatalogueListsByFilterSaga,
    exportProtocolsByIdsSaga,
    exportProtocolsByFilterSaga,
    isolateOrdersByIdsSaga,
    isolateOrdersByFilterSaga,
    exportCdcbByIdsSaga,
    exportCdcbByFilterSaga,
    generateSampleSheetByIdsSaga,
    generateSampleSheetByFilterSaga,
    createGenotypeExportByIdsSaga,
    createGenotypeExportsByFilterSaga,
    cancelOrdersBillingByFilterSaga,
    cancelOrdersBillingByIdsSaga,
    updateOrdersTypesByIdsSaga,
    updateOrdersTypesByFilterSaga,
    createBulkOrderSaga,
    deleteOrdersByFilterSaga,
    deleteOrdersByIdsSaga,
    updateCustomersEmailByFilterSaga,
    updateCustomersEmailByIdsSaga,
];
