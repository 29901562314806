import {Options} from '@fl/cmsch-fe-library';
import React, {FC, memo, useMemo} from 'react';
import {Opt} from 'ts-opt';

import {FooterButtons} from 'common/buttons';
import {useOurTranslation} from 'translations';
import {parenthoodValues, ParenthoodValues} from 'types/parenthood-values';
import {useForm} from 'utils/forms';

import {validate} from './validations';
import {
    createVerificationOutcomeFormName,
    editVerificationOutcomeFormName,
    initialVerificationOutcomeFormValues,
    VerificationOutcomeFormValues,
} from './verification-outcome-form-values';

interface Props {
    isEdit?: boolean;
    onCancel?(): void;
    onSubmit(values: VerificationOutcomeFormValues): void;
    onChangeValue(value: Opt<string>): void;
}

const VerificationOutcomeFormBase: FC<Props> = props => {
    const {
        isEdit,
        onCancel,
        onSubmit,
        onChangeValue,
    } = props;

    const {t} = useOurTranslation('dials/verificationOutcomes');

    const {Form, Fields, valid, pristine, submitting, renderErrors} = useForm({
        form: isEdit ? editVerificationOutcomeFormName : createVerificationOutcomeFormName,
        initialValues: initialVerificationOutcomeFormValues,
        destroyOnUnmount: true,
        validate,
        onSubmit,
    });

    const paternityOptions = useMemo((): Options<ParenthoodValues> => parenthoodValues.map(v => ({
        value: v,
        label: t(`paternityValues.${v}`),
    })), [t]);
    const maternityOptions = useMemo((): Options<ParenthoodValues> => parenthoodValues.map(v => ({
        value: v,
        label: t(`maternityValues.${v}`),
    })), [t]);

    return (
        <Form>
            {renderErrors()}
            <div className="row">
                <div className="col-12">
                    <Fields.Input
                        name="value"
                        type="text"
                        label={t('name')}
                        onFieldChange={onChangeValue}
                        isRequired
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <Fields.StringSelect
                        name="paternitySettings"
                        options={paternityOptions}
                        label={t('paternitySettings')}
                        clearable
                        searchable={false}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <Fields.StringSelect
                        name="maternitySettings"
                        options={maternityOptions}
                        label={t('maternitySettings')}
                        clearable
                        searchable={false}
                    />
                </div>
            </div>
            <FooterButtons
                editing={isEdit}
                disabledSubmit={!valid || pristine || submitting}
                onCancel={onCancel}
            />
        </Form>
    );
};

export const VerificationOutcomeForm = memo(VerificationOutcomeFormBase);
