// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { FileKey, FileKeySchema } from 'api/gen/FileKey';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveOrZeroInteger, PositiveOrZeroIntegerSchema } from 'api/gen/PositiveOrZeroInteger';

const requiredPart = t.interface({
  fileName: FileKeySchema,
  numOfImportedEarTags: PositiveOrZeroIntegerSchema,
  numOfSkippedLines: PositiveOrZeroIntegerSchema,
});

export const ChipImportOutcomeSchema = excess(requiredPart);

export interface ChipImportOutcome extends t.TypeOf<typeof ChipImportOutcomeSchema> {}
