import {barcodeMaxLength, barcodeRegexGen} from 'api/gen/Barcode';
import {t} from 'translations';
import {PaternityValidator} from 'utils/paternity-validator';
import {Errors} from 'utils/validator';

import {validateAnimalDetails} from '../AnimalDetailsForm/validations';
import {validateUserDetails} from '../UserDetailsFormSection/validations';
import {NewOrderFormValues} from './new-order-form-values';

const te = t('orders/OrderDetailsForm');

export const validate = (values: NewOrderFormValues): Errors<NewOrderFormValues> => {
    const validator = new PaternityValidator(values);

    validator.pattern('barcode', barcodeRegexGen(), te('barcode'));
    validator.maxStringLength('barcode', barcodeMaxLength, te('barcode'));
    validator.nonEmpty('sampleTypeId', te('sampleTypeId'));
    validator.nonEmpty('orderTypeIds', te('orderTypeIds'));

    const errors = validator.generateErrorsObject();

    if (values.animalDetails) {
        errors.animalDetails = validateAnimalDetails(values.animalDetails);
    }

    if (values.userDetails) {
        errors.userDetails = validateUserDetails(values.userDetails, 'new');
    }

    return errors;
};
