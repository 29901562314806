import {ParentSearchResultDetails} from 'api/gen/ParentSearchResultDetails';

import {ParentSearchFormValues} from '../components/ParentSearchForm/parent-search-form-values';

export const parentSearchAction = {
    searchParent: (data: ParentSearchFormValues) => ({
        type: 'parentSearch/PARENT_SEARCH',
        payload: {data},
    }) as const,
    searchParentSuccess: (result: Array<ParentSearchResultDetails>) => ({
        type: 'parentSearch/PARENT_SEARCH_SUCCESS',
        payload: {result},
    }) as const,
    resetResults: () => ({
        type: 'parentSearch/RESET_RESULTS',
    }) as const,
};

export type SearchParentAction = ReturnType<typeof parentSearchAction.searchParent>;
export type SearchParentSuccessAction = ReturnType<typeof parentSearchAction.searchParentSuccess>;
export type ResetResultsAction = ReturnType<typeof parentSearchAction.resetResults>;

export type ParentSearchAction =
    | SearchParentAction
    | SearchParentSuccessAction
    | ResetResultsAction
;
