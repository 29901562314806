// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { City, CitySchema } from 'api/gen/City';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Street, StreetSchema } from 'api/gen/Street';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { ZipNumber, ZipNumberSchema } from 'api/gen/ZipNumber';

const requiredPart = t.interface({
  city: CitySchema,
  street: StreetSchema,
  zip: ZipNumberSchema,
});

export const AddressSchema = excess(requiredPart);

export interface Address extends t.TypeOf<typeof AddressSchema> {}
