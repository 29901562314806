export type OrderTypesActionType = 'add' | 'remove';

export interface OrdersTypesFormValues {
    editType: OrderTypesActionType;
    ordersTypes: OrderTypeSectionValues;
}

export const bulkEditTypesFormName = 'bulkEditOrdersTypes';

export const orderTypesInitialValues: OrderTypeSectionValues = {
    orderTypeIds: [],
};

export interface OrderTypeSectionValues {
    orderTypeIds: Array<number>;
}
