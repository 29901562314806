import {none, Opt} from 'ts-opt';

import {PagedModelGenotypeExport} from 'api/gen/PagedModelGenotypeExport';

export interface GenotypeExportsState {
    pageGenotypeExports: Opt<PagedModelGenotypeExport>;
}

export const initialGenotypeExportsState: GenotypeExportsState = {
    pageGenotypeExports: none,
};
