import {none, Opt} from 'ts-opt';

import {ParenthoodVerification} from 'api/gen/ParenthoodVerification';

export interface PaternityCheckState {
    result: Opt<ParenthoodVerification>;
}

export const initialPaternityCheckState: PaternityCheckState = {
    result: none,
};
