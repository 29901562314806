import {Options} from '@fl/cmsch-fe-library';
import React, {FC, memo} from 'react';

import {SexType} from 'api/gen/SexType';
import {FooterButtons} from 'common/buttons';
import {useOurTranslation} from 'translations';
import {useForm} from 'utils/forms';
import {translateYesNo} from 'utils/translate-yes-no';

import {newOrderInitialValues, NewOrderFormValues, newOrderFormName} from '../NewForm/new-order-form-values';

interface Props {
    formValues: NewOrderFormValues;
    animalSexes: Options<SexType>;
    sampleTypes: Options<number>;
    orderTypes: Options<number>;
    onPreviousClick(): void;
    onSubmit(values: NewOrderFormValues): void;
}

// eslint-disable-next-line max-lines-per-function
const OrderConfirmationFormBase: FC<Props> = props => {
    const {
        formValues,
        animalSexes,
        sampleTypes,
        orderTypes,
        onSubmit,
        onPreviousClick,
    } = props;

    const {t, tCommon} = useOurTranslation('orders/OrderConfirmationForm');

    const {Form, submitting, renderErrors} = useForm({
        form: newOrderFormName,
        destroyOnUnmount: false,
        forceUnregisterOnUnmount: true,
        keepDirtyOnReinitialize: true,
        initialValues: newOrderInitialValues,
        onSubmit,
    });

    const {
        userDetails,
        animalDetails,
        orderTypeIds,
        sampleTypeId,
        barcode,
        customerNote,
    } = formValues;

    const selectedSampleType = sampleTypes.find(x => x.value === sampleTypeId)?.label;
    const selectedOrderTypes = orderTypeIds?.map(x => orderTypes.find(y => y.value === x)?.label) || [];
    const selectedSex = animalSexes.find(x => x.value === animalDetails?.sex)?.label;
    const cellHiddenOnMD = 'd-table-cell d-md-none';
    const cellShownOnMD = 'd-none d-md-table-cell';

    return (
        <div data-test-id="new-orders-form-4">
            <Form>
                {renderErrors()}

                <fieldset>
                    <legend data-test-id="legend">{t('caption')}</legend>

                    <div className="row">
                        <div className="col-12">
                            <h5>{t('animalData')}</h5>

                            <table className="table">
                                <tbody>
                                    <tr>
                                        <th>{tCommon('earTag')}</th>
                                        <td colSpan={3}>{animalDetails?.sampleId}</td>
                                    </tr>

                                    <tr>
                                        <th>{t('name')}</th>
                                        <td>{animalDetails?.name}</td>
                                        <th className={cellShownOnMD}>{t('sex')}</th>
                                        <td className={cellShownOnMD}>{selectedSex}</td>
                                    </tr>
                                    <tr>
                                        <th className={cellHiddenOnMD}>{t('sex')}</th>
                                        <td className={cellHiddenOnMD}>{selectedSex}</td>
                                    </tr>
                                    <tr>
                                        <th>{t('breed')}</th>
                                        <td>{animalDetails?.breed}</td>
                                        <th className={cellShownOnMD}>{t('registry')}</th>
                                        <td className={cellShownOnMD}>{animalDetails?.registry}</td>
                                    </tr>
                                    <tr>
                                        <th className={cellHiddenOnMD}>{t('registry')}</th>
                                        <td className={cellHiddenOnMD}>
                                            {animalDetails?.registry}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>{t('fathersSampleId')}</th>
                                        <td>{animalDetails?.fathersSampleId}</td>
                                        <th className={cellShownOnMD}>{t('fathersRegistry')}</th>
                                        <td className={cellShownOnMD}>
                                            {animalDetails?.fathersRegistry}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className={cellHiddenOnMD}>{t('fathersRegistry')}</th>
                                        <td className={cellHiddenOnMD}>
                                            {animalDetails?.fathersRegistry}
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>{t('mothersSampleId')}</th>
                                        <td colSpan={3}>{animalDetails?.mothersSampleId}</td>
                                    </tr>

                                    <tr>
                                        <th>{t('twin')}</th>
                                        <td colSpan={3}>{translateYesNo(animalDetails?.twin ?? false)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <h5>{t('orderData')}</h5>

                            <table className="table">
                                <tbody>
                                    <tr>
                                        <th>{t('barcode')}</th>
                                        <td>{barcode}</td>
                                        <th className={cellShownOnMD}>{t('sampleType')}</th>
                                        <td className={cellShownOnMD}>{selectedSampleType}</td>

                                    </tr>
                                    <tr>
                                        <th className={cellHiddenOnMD}>{t('sampleType')}</th>
                                        <td className={cellHiddenOnMD}>{selectedSampleType}</td>
                                    </tr>

                                    <tr>
                                        <th>{t('orderTypes')}</th>
                                        <td colSpan={5}>
                                            {selectedOrderTypes.map(type =>
                                                <span key={type}>{type}<br /></span>)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>{t('note')}</th>
                                        <td colSpan={5}>{customerNote}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <h5>{t('customerData')}</h5>

                            <table className="table">
                                <tbody>
                                    <tr>
                                        <th>{t('customerName')}</th>
                                        <td>{userDetails?.customerName}</td>
                                    </tr>
                                    <tr>
                                        <th>{t('firstAndLastName')}</th>
                                        <td>{userDetails?.firstname} {userDetails?.lastname}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </fieldset>

                <FooterButtons
                    cancelTitle={t('back')}
                    onCancel={onPreviousClick}
                    submitTitle={t('submit')}
                    submitting={submitting}
                />
            </Form>
        </div>
    );
};

export const OrderConfirmationForm = memo(OrderConfirmationFormBase);
