import {Opt, none} from 'ts-opt';

import {CategoryDetails} from 'api/gen/CategoryDetails';
import {PagedModelSnpView} from 'api/gen/PagedModelSnpView';

export interface SnpState {
    isCreateSnpVisible: boolean;
    isCreateCategoryVisible: boolean;
    categories: Opt<Array<CategoryDetails>>;
    selected: Array<number>;
    pageTableData: Opt<PagedModelSnpView>;
    tableIsLoading: boolean;
}

export const initialSnpState: SnpState = {
    isCreateSnpVisible: false,
    isCreateCategoryVisible: false,
    categories: none,
    selected: [],
    pageTableData: none,
    tableIsLoading: false,
};
