import {isNonEmpty} from 'fp-ts/Array';
import {createSelector} from 'reselect';

import {State} from 'app/state';

import {LoadingType} from '../types/loading-type';

export const simpleAppSelector = {
    loadingContent: ({app}: State): Array<string> => app.loadingContent,
    localLoadingContent: ({app}: State): Array<LoadingType> => app.localLoadingContent,
    actualRouterPath: ({router}: State): string => router.location?.pathname ?? '',
};

export const loadingSelector = createSelector(
    (state: State) => state.app.loadingContent,
    isNonEmpty,
);

export const loadingUserSelector = createSelector(
    (state: State) => state.app.loadingContent,
    (content: Array<LoadingType>) => content.includes('currentUser'),
);

export const urlWasChanged = createSelector(
    (state: State) => state,
    (state: State) => state.app.urlForLoader !== state.router.location?.pathname,
);
