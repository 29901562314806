import React, {FC, useCallback} from 'react';

import {AnimalDetails} from 'api/gen/AnimalDetails';
import {ActionButton} from 'common/buttons';
import {SideMenu} from 'layout';
import {useOurTranslation} from 'translations';

interface Props {
    animal: AnimalDetails;
    isRoleBreedingUnion: boolean;
    isRoleLaborer: boolean;
    deleteAnimalInProgress: boolean;
    exportGenotypes(animalId: number): void;
    goBack(): void;
    goTo(path: string): void;
    deleteAnimal(animalId: number): void;
}

export const AnimalDetailActions: FC<Props> = props => {
    const {
        animal,
        isRoleBreedingUnion,
        isRoleLaborer,
        deleteAnimalInProgress,
        exportGenotypes,
        goBack,
        goTo,
        deleteAnimal,
    } = props;

    const {t} = useOurTranslation('animals/AnimalDetailActions');

    const onMothersGenotypeExport = useCallback(() => {
        if (animal.mothersId !== null) exportGenotypes(animal.mothersId);
    }, [animal, exportGenotypes]);

    const onFathersGenotypeExport = useCallback(() => {
        if (animal.fathersId !== null) exportGenotypes(animal.fathersId);
    }, [animal, exportGenotypes]);

    const goToEdit = useCallback(() => {
        goTo(`/animals/${animal.id}/edit`);
    }, [animal, goTo]);

    const onAnimalDelete = useCallback(() => {
        deleteAnimal(animal.id);
    }, [animal.id, deleteAnimal]);

    const showOrderDetail = isRoleLaborer || isRoleBreedingUnion;

    return (
        <SideMenu title="Správa zvířat">
            {!isRoleBreedingUnion && (
                <ActionButton
                    icon="editOutlined"
                    onClick={goToEdit}
                >
                    {t('edit')}
                </ActionButton>
            )}
            {isRoleLaborer && (
                <ActionButton
                    onClick={onMothersGenotypeExport}
                    disabled={animal.mothersId === null}
                    disabledTooltip={t('noMother')}
                    icon="exportOutlined"
                >
                    {t('exportMothersGenotype')}
                </ActionButton>
            )}
            {isRoleLaborer && (
                <ActionButton
                    onClick={onFathersGenotypeExport}
                    disabled={animal.fathersId === null}
                    disabledTooltip={t('noFather')}
                    icon="exportOutlined"
                >
                    {t('exportFathersGenotype')}
                </ActionButton>
            )}
            {showOrderDetail && (
                <ActionButton
                    disabled={animal.orderId === null}
                    disabledTooltip={t('noOrder')}
                    icon="fileDoneOutlined"
                    linkTo={animal.orderId ? `/orders/${animal.orderId}` : undefined}
                >
                    {t('orderDetail')}
                </ActionButton>
            )}
            {!isRoleBreedingUnion && (
                <ActionButton
                    icon="deleteOutlined"
                    onClick={onAnimalDelete}
                    type="delete"
                    loading={deleteAnimalInProgress}
                >
                    {t('deleteAnimal')}
                </ActionButton>
            )}
            <ActionButton
                onClick={goBack}
                type="goBack"
            >
                {t('back')}
            </ActionButton>
        </SideMenu>
    );
};
