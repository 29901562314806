import {extractFormErrorsFromResponse, putAll, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {put, call} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'translations';
import {formHelpers} from 'utils/forms';
import {showBeError} from 'utils/show-be-error';

import {
    editVerificationOutcomeFormName,
} from '../../components/VerificationOutcomeForm/verification-outcome-form-values';
import {dialsAction, UpdateVerificationOutcomeAction} from '../action';

function* execute({payload: {id, verificationOutcome}}: UpdateVerificationOutcomeAction): SagaIterator {
    const response = yield* call(
        Api.updateVerificationOutcomeType,
        verificationOutcome,
        {verificationOutcomeTypeId: id},
    );

    if (response.isSuccess) {
        yield* put(formHelpers.stopSubmit(editVerificationOutcomeFormName));
        yield* put(dialsAction.updateVerificationOutcomeSuccess());
        yield* put(dialsAction.getVerificationOutcomes());
        yield* put(dialsAction.setModalVisibility('verificationOutcome', false));
    } else {
        yield* put(formHelpers.stopSubmit(
            editVerificationOutcomeFormName,
            extractFormErrorsFromResponse(response),
        ));
        yield putAll(showBeError(response, t('dials/verificationOutcomes')('editTitle')));
    }
}

export function* updateVerificationOutcomeSaga(): SagaIterator {
    yield takeLatestF('dials/UPDATE_VERIFICATION_OUTCOME', execute);
}
