import React, {FC, Fragment, memo, useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {isEmpty} from 'ts-opt';

import {FooterButtons} from 'common/buttons';
import {useOurTranslation} from 'translations';
import {useForm} from 'utils/forms';

import {orderAction} from '../../model/action';
import {simpleFormSelector, simpleOrdersSelector} from '../../model/selector';
import {AnimalsDetailsModal} from '../AnimalsDetailsModal';
import {
    newBulkOrderFormName,
    NewBulkOrderFormValues,
    newBulkOrderInitialValues,
} from '../NewBulkForm/new-bulk-order-form-values';
import {validate} from '../NewBulkForm/validations';
import {SelectedAnimalsTable} from '../NewOrderSelectedAnimalsTable';
import {AddAnimalButton} from './add-animal';

interface Props {
    onPreviousClick(): void;
    onSubmit(values: NewBulkOrderFormValues): void;
}

const AnimalsDetailsFormBase: FC<Props> = props => {
    const {
        onSubmit,
        onPreviousClick,
    } = props;

    const {t} = useOurTranslation('orders/AnimalDetailsForm');
    const {t: td} = useOurTranslation('orders/newBulkOrder');

    const {Form, renderErrors} = useForm({
        form: newBulkOrderFormName,
        destroyOnUnmount: false,
        forceUnregisterOnUnmount: true,
        keepDirtyOnReinitialize: true,
        initialValues: newBulkOrderInitialValues,
        validate,
        onSubmit,
    });

    const animals = useSelector(simpleFormSelector.newOrderBulkAnimalsDetails);
    const modalOpen = useSelector(simpleOrdersSelector.addAnimalModalOpen);
    const dispatch = useDispatch();

    const disableSubmit = useMemo(() => isEmpty(animals), [animals]);

    const setModalOpen = useCallback(() => {
        dispatch(orderAction.setModalVisibility('addAnimal', true));
    }, [dispatch]);

    const handleModalOpen = useCallback(() => {
        setModalOpen();
        dispatch(orderAction.clearBulkOrderModalData());
    }, [dispatch, setModalOpen]);

    const closeModal = useCallback(() => {
        dispatch(orderAction.setModalVisibility('addAnimal', false));
    }, [dispatch]);

    const handleCancel = useCallback(() => {
        closeModal();
        dispatch(orderAction.resetIsEditing());
    }, [closeModal, dispatch]);

    return (
        <Fragment>
            <AnimalsDetailsModal
                modalOpen={modalOpen}
                handleCancel={handleCancel}
                td={td}
            />
            <Form>
                {renderErrors()}
                <legend>{td('stepThreeCaption')}</legend>
                <AddAnimalButton
                    t={td}
                    handleModalOpen={handleModalOpen}
                />
                <SelectedAnimalsTable handleModalOpen={setModalOpen} />
                <FooterButtons
                    cancelTitle={td('back')}
                    onCancel={onPreviousClick}
                    submitTitle={t('submit')}
                    disabledSubmit={disableSubmit}
                    submitTooltip={disableSubmit ? td('animalsDataErrMsg') : undefined}
                />
            </Form>
        </Fragment>
    );
};

export const AnimalsDetailsForm = memo(AnimalsDetailsFormBase);
