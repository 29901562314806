import {formatDatetime} from '@fl/cmsch-fe-library';
import React, {FC, memo, useEffect} from 'react';
import {Opt} from 'ts-opt';

import {AppInfo} from 'api/gen/AppInfo';
import {Config} from 'app/config';
import {Ant} from 'common/ant';
import {Loader, LoaderWrapper} from 'common/layout';
import {MainPanel, PageHeading} from 'layout';
import {useOurTranslation} from 'translations';

import {AppInfoRow} from '../app-info-row';

interface Props {
    appInfo: Opt<AppInfo>;
    loading: boolean;
    getAppInfo(): void;
}

const AboutAppBase: FC<Props> = props => {
    const {appInfo, loading, getAppInfo} = props;

    const {t} = useOurTranslation('other/AppInfo');

    useEffect(() => {
        getAppInfo();
    }, [getAppInfo]);

    return (
        <MainPanel>
            <div className="row">
                <div
                    className="col-12"
                >
                    <PageHeading>
                        <h1>{t('title')}</h1>
                    </PageHeading>
                </div>
            </div>

            {appInfo.map(applicationInfo => (
                <LoaderWrapper
                    key="app-info"
                    loading={loading}
                >
                    <div className="mx-2 mx-sm-0 mb-2 mb-sm-0">
                        <Ant.Divider orientation="left">
                            {t('frontend')}
                        </Ant.Divider>
                        <AppInfoRow
                            rowName={t('version')}
                            rowValue={Config.version}
                        />
                        <AppInfoRow
                            rowName={`${t('library')} cmsch-fe-library`}
                            rowValue={Config.feLibraryVersion}
                        />
                        <Ant.Divider orientation="left">
                            {t('backend')}
                        </Ant.Divider>
                        <AppInfoRow
                            rowName={t('version')}
                            rowValue={applicationInfo.appVersion}
                        />
                        <AppInfoRow
                            rowName={t('dbVersion')}
                            rowValue={applicationInfo.dbVersion}
                        />
                        {applicationInfo.libraries?.map(library => (
                            <AppInfoRow
                                key={library.name}
                                rowName={`${t('library')} ${library.name}`}
                                rowValue={library.version}
                            />
                        ))}
                        <Ant.Divider orientation="left">
                            {t('deployment')}
                        </Ant.Divider>
                        <AppInfoRow
                            rowName={t('deployDate')}
                            rowValue={formatDatetime('datetime')(applicationInfo.buildTs).orElse('-')}
                        />
                    </div>
                </LoaderWrapper>
            )).orElse(loading
                ? (
                    <Loader
                        show
                        size="large"
                    />
                )
                : <Ant.Empty description={t('noData')} />)}
        </MainPanel>
    );
};

export const AboutApp = memo(AboutAppBase);
