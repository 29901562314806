import {OrderImportType} from 'api/gen/OrderImportType';

export const importAction = {
    sendImportedFile: (data: File) => ({
        type: 'fileImport/SEND_IMPORTED_FILE',
        payload: {data},
    }) as const,
    sendOrdersImport: (data: File, type: OrderImportType) => ({
        type: 'fileImport/SEND_ORDERS_IMPORT',
        payload: {data, type},
    }) as const,
    sendOrdersUpdates: (data: File) => ({
        type: 'fileImport/SEND_ORDERS_UPDATES',
        payload: {data},
    }) as const,
    setLoading: (isLoading: boolean) => ({
        type: 'fileImport/SET_LOADING',
        payload: {isLoading},
    }) as const,
};

export type SendImportedFileAction = ReturnType<typeof importAction.sendImportedFile>;
export type SendOrdersImportAction = ReturnType<typeof importAction.sendOrdersImport>;
export type SendOrdersUpdatesAction = ReturnType<typeof importAction.sendOrdersUpdates>;
export type SetLoadingAction = ReturnType<typeof importAction.setLoading>;

export type FileImportAction =
    | SendImportedFileAction
    | SendOrdersImportAction
    | SendOrdersUpdatesAction
    | SetLoadingAction
    ;
