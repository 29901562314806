import React, {FC, memo, useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Opt} from 'ts-opt';

import {SideBox} from 'api/gen/SideBox';
import {State} from 'app/state';
import {dialsAction} from 'dials/model/action';
import {useOurTranslation} from 'translations';

import {Sidebox} from '../sidebox';

const BreederInfoSideboxBase: FC = () => {
    const breederInfo: Opt<SideBox> = useSelector((state: State) => state.dials.breederInfo);
    const dispatch = useDispatch();
    const getBreederInfo = useCallback(() =>
        dispatch(dialsAction.getBreederInfo())
    , [dispatch]);
    const hideBreederInfo = useCallback(() =>
        dispatch(dialsAction.hideBreederInfo())
    , [dispatch]);

    useEffect(() => {
        if (breederInfo.isEmpty) {
            getBreederInfo();
        }
    }, [breederInfo.isEmpty, getBreederInfo]);

    const {t} = useOurTranslation('dials/breederInfo');

    return breederInfo.filter(x => x.show).map(x => (
        <Sidebox
            key={x.caption}
            text={x.content}
            caption={x.caption}
            onHide={hideBreederInfo}
            t={t}
        />
    )).orNull();
};

export const BreederInfoSidebox = memo(BreederInfoSideboxBase);
