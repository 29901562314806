// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { TableFiltersExcludingOrderIds, TableFiltersExcludingOrderIdsSchema } from 'api/gen/TableFiltersExcludingOrderIds';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { UpdateEmail, UpdateEmailSchema } from 'api/gen/UpdateEmail';

const requiredPart = t.interface({
  emailDetails: t.union([UpdateEmailSchema, t.null]),
  tableFilters: t.union([TableFiltersExcludingOrderIdsSchema, t.null]),
});

export const UpdateOrdersEmailSchema = excess(requiredPart);

export interface UpdateOrdersEmail extends t.TypeOf<typeof UpdateOrdersEmailSchema> {}
