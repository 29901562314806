// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { DateTime, DateTimeSchema } from 'api/gen/DateTime';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { FileKey, FileKeySchema } from 'api/gen/FileKey';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveLong, PositiveLongSchema } from 'api/gen/PositiveLong';

const requiredPart = t.interface({
  billingBasisCsvKey: t.union([FileKeySchema, t.null]),
  billingBasisMeatAssociationCsvKey: t.union([FileKeySchema, t.null]),
  billingBasisPdfKey: t.union([FileKeySchema, t.null]),
  exportDateTime: t.union([DateTimeSchema, t.null]),
  orderTypeId: PositiveLongSchema,
  readyForBilling: t.boolean,
});

export const OrderOrderTypeSchema = excess(requiredPart);

export interface OrderOrderType extends t.TypeOf<typeof OrderOrderTypeSchema> {}
