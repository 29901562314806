import {putAll, takeLatestF, todayDate} from '@fl/cmsch-fe-library';
import {isNull} from 'lodash/fp';
import * as routerActions from 'redux-first-history';
import {SagaIterator} from 'redux-saga';
import {put, call} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {appAction} from 'app/model/action';
import {t} from 'translations';
import {formHelpers} from 'utils/forms';
import {showBeError} from 'utils/show-be-error';

import {GetOrderAction, orderAction} from '../action';

// eslint-disable-next-line max-lines-per-function
function* execute({payload: {orderId, params}}: GetOrderAction): SagaIterator {
    const {setSampleDeliveredDate, redirectNotFound} = params ?? {};
    yield* put(appAction.addLoadingContent('getOrder'));
    const response = yield* call(Api.getOrderDetails, {orderId});

    if (response.isSuccess) {
        yield* put(orderAction.getOrderSuccess(response.data));

        const {
            laboratoryNumber,
            barcode,
            sampleDeliveredDate,
            isolationDate,
            isolated,
            laboratoryNote,
            orderTypes,
            sampleTypeId,
        } = response.data;

        yield* put(formHelpers.initialize('editOrder', {
            laboratoryNumber,
            barcode,
            sampleDeliveredDate,
            isolationDate,
            isolated,
            laboratoryNote,
            orderTypeIds: orderTypes?.map(x => x.orderTypeId) || null,
            sampleTypeId,
        }));

        if (setSampleDeliveredDate && isNull(sampleDeliveredDate)) {
            yield* put(formHelpers.change('editOrder', 'sampleDeliveredDate', todayDate()));
        }
    } else {
        if (response.isNotFound && redirectNotFound) {
            yield* put(routerActions.replace('/not-found'));
        }
        yield putAll(showBeError(response, t('orders/sagas')('getOrder')));
    }
    yield* put(appAction.removeLoadingContent('getOrder'));
}

export function* getOrderSaga(): SagaIterator {
    yield takeLatestF('order/GET_ORDER', execute);
}
