import * as O from 'optics-ts';
import {none, opt} from 'ts-opt';

import {Action} from 'app/actions';

import {initialParentSearchState, ParentSearchState} from './state';

const parentSearchStateO = O.optic<ParentSearchState>();

export const parentSearchReducer = (
    state: ParentSearchState = initialParentSearchState,
    action: Action,
): ParentSearchState => {
    switch (action.type) {
        case 'parentSearch/PARENT_SEARCH_SUCCESS': {
            const {result} = action.payload;

            return O.set(parentSearchStateO.prop('result'))(opt(result))(state);
        }

        case 'parentSearch/RESET_RESULTS': {
            return O.set(parentSearchStateO.prop('result'))(none)(state);
        }

        default:
            return state;
    }
};
