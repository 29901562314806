import {classNames} from '@fl/cmsch-fe-library';
import moment from 'moment';
import React, {FC, memo} from 'react';

import {FooterButtons} from 'common/buttons';
import {Panel} from 'layout';
import {useOurTranslation} from 'translations';
import {useForm} from 'utils/forms';
import {getSexOptions} from 'utils/get-sex-options';

import {
    GenerateRequestFormValues,
    generateRequestFormName,
    generateRequestInitialValues,
} from './generate-request-form-values';
import {validate} from './validations';

import styles from './styles.sass';

interface Props {
    onCancel(): void;
    onSubmit(value: GenerateRequestFormValues): void;
}

// eslint-disable-next-line max-lines-per-function
const GenerateRequestFormBase: FC<Props> = props => {
    const {
        onSubmit,
        onCancel,
    } = props;

    const {t, tCommon} = useOurTranslation('orders/GenerateRequestForm');

    const {Form, Fields, valid, submitting, pristine, renderErrors} = useForm({
        form: generateRequestFormName,
        initialValues: generateRequestInitialValues,
        validate,
        onSubmit,
    });

    return (
        <Panel
            title={t('title')}
            extraBottomSpace
        >
            <Form>
                <div className={classNames('grid-form', styles.generateRequestForm)}>
                    {renderErrors()}

                    <fieldset>
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <Fields.Input
                                    name="sampleId"
                                    label={tCommon('earTag')}
                                    placeholder={tCommon('earTagPlaceholder')}
                                    type="text"
                                    disabled
                                />
                            </div>
                            <div className="col-12 col-md-6">
                                <Fields.StringSelect
                                    name="sex"
                                    label={t('sex')}
                                    options={getSexOptions()}
                                    isRequired
                                    placeholder={t('sex')}
                                    clearable={false}
                                    searchable={false}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 col-md-6">
                                <Fields.Input
                                    name="breed"
                                    label={t('breed')}
                                    type="text"
                                />
                            </div>
                            <div className="col-12 col-md-6">
                                <Fields.DateInput
                                    name="dateOfBirth"
                                    label={t('dateOfBirth')}
                                    placeholder={tCommon('dateOfBirthPlaceholder')}
                                    maxDate={moment().endOf('day')}
                                    clearable
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 col-md-6">
                                <Fields.Input
                                    name="barcode"
                                    label={t('barcode')}
                                    type="text"
                                />
                            </div>
                            <div className="col-12 col-md-6">
                                <Fields.Input
                                    name="fathersBreed"
                                    label={t('fathersBreed')}
                                    type="text"
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 col-md-6">
                                <Fields.Input
                                    name="fathersRegistry"
                                    label={t('fathersRegistry')}
                                    type="text"
                                />
                            </div>
                            <div className="col-12 col-md-6">
                                <Fields.Input
                                    name="mothersSampleId"
                                    label={t('mothersSampleId')}
                                    type="text"
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 col-md-6">
                                <Fields.Input
                                    name="mothersFatherRegistry"
                                    label={t('mothersFatherRegistry')}
                                    placeholder={tCommon('registryPlaceholder')}
                                    type="text"
                                />
                            </div>
                            <div className="col-12 col-md-6">
                                <Fields.Input
                                    name="mothersFatherBreed"
                                    label={t('mothersFatherBreed')}
                                    type="text"
                                />
                            </div>
                        </div>
                    </fieldset>

                    <FooterButtons
                        submitTitle={t('submit')}
                        valid={valid}
                        submitting={submitting}
                        pristine={pristine}
                        onCancel={onCancel}
                    />
                </div>
            </Form>
        </Panel>
    );
};

export const GenerateRequestForm = memo(GenerateRequestFormBase);
