import {classNames} from '@fl/cmsch-fe-library';
import React, {FC} from 'react';

import styles from './styles.sass';

interface Props {
    icon: string;
    disabled?: boolean;
    large?: boolean;
    title?: string;
    circle?: boolean;
}

export const SvgIcon: FC<Props> = props => {
    const {
        icon,
        disabled,
        large,
        title,
        circle,
    } = props;

    const classes = classNames(
        'd-inline-flex align-items-center',
        styles.svgIcon,
        large && styles.svgIconLarge,
        disabled && styles.svgIconDisabled,
        circle && styles.circleIcon,
    );

    return (
        <span
            title={title}
            className={classes}
            // eslint-disable-next-line react/no-danger, @typescript-eslint/naming-convention
            dangerouslySetInnerHTML={{__html: icon}}
        />
    );
};
