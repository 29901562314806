// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// The subject of the e-mail template.
// Example: "ČMSCH - změna stavu objednávky"

export const emailTemplateSubjectMinLength = 1;
export const emailTemplateSubjectMaxLength = 255;

export const EmailTemplateSubjectSchema = t.refinement(t.string, n => n.length >= emailTemplateSubjectMinLength && n.length <= emailTemplateSubjectMaxLength, 'EmailTemplateSubject');

export type EmailTemplateSubject = string;
