import {downloadFileFromResponse, putAll, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'translations';
import {showBeError} from 'utils/show-be-error';

import {DownloadBillingBasesAction} from '../action';

function* execute({payload: {fileKey}}: DownloadBillingBasesAction): SagaIterator {
    const response = yield* call(Api.getInvoicingDocuments, {fileKey});

    if (response.isSuccess) {
        yield* call(downloadFileFromResponse, response);
    } else {
        yield putAll(showBeError(response, t('orders/DownloadBillingBaseExport')('caption')));
    }
}

export function* downloadBillingBasesSaga(): SagaIterator {
    yield takeLatestF('order/DOWNLOAD_BILLING_BASES', execute);
}
