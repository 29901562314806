import {debounceTime} from '@fl/cmsch-fe-library';
import {debounce} from 'lodash/fp';
import React, {FC, memo, useCallback, useMemo} from 'react';
import {useDispatch} from 'react-redux';
import {Opt} from 'ts-opt';

import {chipIdRegexGen} from 'api/gen/ChipId';
import {FooterButtons} from 'common/buttons';
import {orderAction} from 'orders/model/action';
import {useOurTranslation} from 'translations';
import {formHelpers, useForm} from 'utils/forms';

import {sampleSheetFormName, SampleSheetFormValues} from './sample-sheet-form-values';
import {validate} from './validations';

const initialValues: SampleSheetFormValues = {
    chipId: null,
};

interface Props {
    onClose(): void;
    onSubmit(values: SampleSheetFormValues): void;
}

const SampleSheetFormBase: FC<Props> = props => {
    const {
        onSubmit,
        onClose,
    } = props;

    const {t} = useOurTranslation('orders/SampleSheetForm');

    const {Form, Fields, valid, pristine, submitting, asyncValidating} = useForm({
        form: sampleSheetFormName,
        initialValues,
        destroyOnUnmount: true,
        validate,
        onSubmit,
    });

    const dispatch = useDispatch();

    const validateChipIdAction = useCallback((value: string) => {
        dispatch(orderAction.validateChipId(value));
    }, [dispatch]);

    const debouncedValidateChipId = useMemo(() =>
        debounce(debounceTime, validateChipIdAction)
    , [validateChipIdAction]);

    const validateChipId = useCallback((val: Opt<string>) => {
        val.onSome(x => {
            if (chipIdRegexGen().test(x)) {
                dispatch(formHelpers.startAsyncValidation(sampleSheetFormName));
                debouncedValidateChipId(x);
            }
        });
    }, [debouncedValidateChipId, dispatch]);

    return (
        <Form>
            <div className="row">
                <div className="col-12">
                    <Fields.Input
                        type="text"
                        label={t('chipId')}
                        name="chipId"
                        placeholder={t('chipIdPlaceholder')}
                        isRequired
                        onFieldChange={validateChipId}
                    />
                </div>
            </div>

            <FooterButtons
                disabledSubmit={!valid || pristine || submitting || Boolean(asyncValidating)}
                submitTitle={t('submit')}
                onCancel={onClose}
            />
        </Form>
    );
};

export const SampleSheetForm = memo(SampleSheetFormBase);
