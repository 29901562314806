import {NullableKeys} from '@fl/cmsch-fe-library';

import {AddressFormSectionValues, initialAddressFormSectionValues} from '../../types/address-form-section-values';

export const userDetailsInitialValues: UserDetailsFormSectionValues = {
    hasSameAddresses: true,
    firstname: null,
    lastname: null,
    customerName: null,
    address: initialAddressFormSectionValues,
    billingAddress: initialAddressFormSectionValues,
    vatNumber: null,
    email: null,
    phone: null,
    cin: null,
    prefillName: null,
    id: null,
    noCin: null,
};

export type UserDetailsFormSectionValues = NullableKeys<{
    hasSameAddresses: boolean,
    firstname: string,
    lastname: string,
    customerName: string,
    address: AddressFormSectionValues,
    billingAddress: AddressFormSectionValues,
    vatNumber: string,
    email: string,
    phone: string,
    cin: string,
    prefillName: number,
    id: number,
    noCin: boolean,
}>;
