import {putAll, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {showSuccessSimple} from 'notifications';
import {t} from 'translations';
import {showBeError} from 'utils/show-be-error';

import {animalsAction, AcceptOriginInconsistency} from '../action';

const te = t('animals/OriginInconsistenciesTable');

function* execute({payload: {originInconsistencyId}}: AcceptOriginInconsistency): SagaIterator {
    yield* put(animalsAction.setInconsistenciesActionActive(originInconsistencyId, true));

    const response = yield* call(Api.acceptParentsFromESkot, {originInconsistencyId});

    if (response.isSuccess) {
        yield* put(showSuccessSimple({message: te('messages.changeAccepted')}));
        yield* put(animalsAction.getOriginInconsistencies(opt({action: 'refresh'})));
    } else {
        yield putAll(showBeError(response, te(`messages.changeError`)));
    }

    yield* put(animalsAction.setInconsistenciesActionActive(originInconsistencyId, false));
}

export function* acceptOriginInconsistencySaga(): SagaIterator {
    yield takeLatestF('animals/ACCEPT_ORIGIN_INCONSISTENCY', execute);
}
