import {extractFormErrorsFromResponse, putAll, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {put, call} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'translations';
import {formHelpers} from 'utils/forms';
import {showBeError} from 'utils/show-be-error';

import {breederInfoFormName} from '../../components/BreederInfoForm/breeder-info-form-values';
import {dialsAction, SaveBreederInfoAction} from '../action';

function* execute({payload: {values}}: SaveBreederInfoAction): SagaIterator {
    yield* put(formHelpers.startSubmit(breederInfoFormName));

    const response = yield* call(Api.updateSideBox, values);

    if (response.isSuccess) {
        yield* put(formHelpers.stopSubmit(breederInfoFormName));
        yield* put(formHelpers.reset(breederInfoFormName));
        yield* put(dialsAction.getBreederInfoConfiguration());
        yield* put(dialsAction.getBreederInfo());
    } else {
        yield* put(formHelpers.stopSubmit(breederInfoFormName, extractFormErrorsFromResponse(response)));
        yield putAll(showBeError(response, t('dials/screenList')('breederInfoCaption')));
    }
}

export function* saveBreederInfoSaga(): SagaIterator {
    yield takeLatestF('dials/SAVE_BREEDER_INFO', execute);
}
