// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { OrderUserDetails, OrderUserDetailsSchema } from 'api/gen/OrderUserDetails';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { TableFiltersExcludingOrderIds, TableFiltersExcludingOrderIdsSchema } from 'api/gen/TableFiltersExcludingOrderIds';

const requiredPart = t.interface({
  customerDetails: t.union([OrderUserDetailsSchema, t.null]),
  tableFilters: t.union([TableFiltersExcludingOrderIdsSchema, t.null]),
});

export const UpdateOrdersCustomerSchema = excess(requiredPart);

export interface UpdateOrdersCustomer extends t.TypeOf<typeof UpdateOrdersCustomerSchema> {}
