import {emailTemplateDescriptionMaxLength} from 'api/gen/EmailTemplateDescription';
import {emailTemplateSubjectMaxLength} from 'api/gen/EmailTemplateSubject';
import {t} from 'translations';
import {PaternityValidator} from 'utils/paternity-validator';
import {Errors} from 'utils/validator';

import {MailTemplateFormValues} from './mail-template-form-values';

const te = t('dials/mailTemplates');

export const validate = (values: MailTemplateFormValues): Errors<MailTemplateFormValues> => {
    const validator = new PaternityValidator(values);

    validator.nonEmpty('code', te('code'));
    validator.nonEmpty('subject', te('subject'));
    validator.maxStringLength('subject', emailTemplateSubjectMaxLength, te('subject'));
    validator.nonEmpty('description', te('description'));
    validator.maxStringLength('description', emailTemplateDescriptionMaxLength, te('description'));
    validator.nonEmpty('body', te('body'));

    return validator.generateErrorsObject();
};
