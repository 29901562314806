import {classNames, Button} from '@fl/cmsch-fe-library';
import React, {FC, memo, ReactNode, useCallback, useState} from 'react';

import styles from './styles.sass';

interface Props {
    title?: ReactNode;
    children?: ReactNode;
    collapsible?: boolean;
    initialIsCollapsed?: boolean;
    extraBottomSpace?: boolean;
    fullWidth?: boolean;
    fullHeight?: boolean;
    testId?: string;
}

/**
 * `<Panel />` can group together related content.
 */
const PanelBase: FC<Props> = props => {
    const {
        title,
        children,
        collapsible,
        initialIsCollapsed,
        extraBottomSpace,
        fullWidth,
        fullHeight,
        testId,
    } = props;

    const [isCollapsed, setIsPanelCollapsed] = useState<boolean>(Boolean(initialIsCollapsed));

    const classes = classNames(
        'card',
        styles.panel,
        extraBottomSpace && 'mb-4',
        fullWidth && 'w-100',
        fullHeight && 'h-100',
    );
    const isBodyVisible = !collapsible || !isCollapsed;

    const handleToggleClick = useCallback((): void => {
        setIsPanelCollapsed(!isCollapsed);
    }, [isCollapsed, setIsPanelCollapsed]);

    return (
        <div
            className={classes}
            data-test-id={testId}
        >
            {title && (
                <div className={styles.title}>
                    <h5 className="d-flex justify-content-between align-items-center">
                        {title}
                    </h5>
                </div>
            )}

            {collapsible && (
                <div className={styles.collapseToggle}>
                    <Button
                        icon={isCollapsed ? 'downOutlined' : 'upOutlined'}
                        tooltip="Zobrazit"
                        onClick={handleToggleClick}
                        visuals="link"
                    />
                </div>
            )}

            {isBodyVisible && (
                <div className={styles.body}>
                    {children}
                </div>
            )}
        </div>
    );
};

export const Panel = memo(PanelBase);
