// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { FirstName, FirstNameSchema } from 'api/gen/FirstName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { LastName, LastNameSchema } from 'api/gen/LastName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PlemdatEmail, PlemdatEmailSchema } from 'api/gen/PlemdatEmail';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveLong, PositiveLongSchema } from 'api/gen/PositiveLong';

const requiredPart = t.interface({
  email: PlemdatEmailSchema,
  firstName: t.union([FirstNameSchema, t.null]),
  id: PositiveLongSchema,
  lastName: t.union([LastNameSchema, t.null]),
});

export const UserSchema = excess(requiredPart);

export interface User extends t.TypeOf<typeof UserSchema> {}
