import {Errors} from 'utils/validator';

import {validateUserDetails} from '../UserDetailsFormSection/validations';
import {EditUserDetailsFormValues} from './edit-user-details-form-values';

interface ValidateParams {
    isIdRequired: boolean;
    isPhoneRequired: boolean;
}

export const validate = ({isIdRequired, isPhoneRequired}: ValidateParams) =>
    (values: EditUserDetailsFormValues): Errors<EditUserDetailsFormValues> => {
        const errors: Errors<EditUserDetailsFormValues> = {};

        if (values.userDetails) {
            errors.userDetails = validateUserDetails(values.userDetails, 'edit', isIdRequired, isPhoneRequired);
        }

        return errors;
    };
