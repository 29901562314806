import {notificationReducer} from '@fl/cmsch-fe-library';
import {AnyAction, combineReducers, Reducer, ReducersMapObject} from 'redux';
import {RouterState} from 'redux-first-history';
import {reducer as form} from 'redux-form';

import {animalsReducer, animalsFormReducer} from 'animals';
import {FormStates} from 'app/form-state';
import {appReducer} from 'app/model/reducer';
import {dialsReducer, dialsFormReducer} from 'dials';
import {fileImportReducer, FileImportState} from 'file-import';
import {genotypeExportsReducer} from 'genotypeExports';
import {orderFormReducer, orderReducer} from 'orders';
import {otherReducer} from 'other';
import {parentSearchReducer} from 'parentSearch';
import {paternityCheckReducer} from 'paternityCheck';
import {snpReducer} from 'snp-management';
import {userReducer} from 'user';
import {tablesReducer} from 'utils/tables';

import {State} from './state';

type StateWithoutRouter = Omit<State, 'router'>;

const formReducers = {
    ...orderFormReducer,
    ...animalsFormReducer,
    ...dialsFormReducer,
};

const reducers: ReducersMapObject<StateWithoutRouter, AnyAction> = {
    form: form.plugin(formReducers) as unknown as Reducer<FormStates>,
    toastr: notificationReducer<AnyAction>(),

    animals: animalsReducer,
    orders: orderReducer,
    user: userReducer,
    dials: dialsReducer,
    fileImport: fileImportReducer as Reducer<FileImportState>,
    parentSearch: parentSearchReducer,
    paternityCheck: paternityCheckReducer,
    snpManagement: snpReducer,
    other: otherReducer,
    genotypeExports: genotypeExportsReducer,
    tables: tablesReducer,
    app: appReducer,
};

export const createReducer = (routerReducer: Reducer<RouterState>): Reducer<State> =>
    combineReducers<State>({
        router: routerReducer,
        ...reducers,
    });
