import {none, Opt} from 'ts-opt';

import {ParentSearchResultDetails} from 'api/gen/ParentSearchResultDetails';

export interface ParentSearchState {
    result: Opt<Array<ParentSearchResultDetails>>;
}

export const initialParentSearchState: ParentSearchState = {
    result: none,
};
