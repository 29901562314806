import React, {FC, memo, useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {barcodeRegexGen} from 'api/gen/Barcode';
import {Container, MainPanel, PageHeading} from 'layout';
import {useOurTranslation} from 'translations';

import {FindOrderForm} from '../components/FindOrderForm';
import {orderAction} from '../model';
import {simpleOrdersSelector} from '../model/selector';

const ReceiveOrdersBase: FC = () => {
    const dispatch = useDispatch();
    const foundOrders = useSelector(simpleOrdersSelector.foundOrders);

    const {t} = useOurTranslation('orders/screenReceiveOrders');

    useEffect(() => {
        dispatch(orderAction.resetOrdersSearch());
    }, [dispatch]);

    const searchOrdersByBarcode = useCallback((barcode: string) => {
        if (barcodeRegexGen().test(barcode)) dispatch(orderAction.searchOrders(barcode));
    }, [dispatch]);

    const searchOrdersByEarTag = useCallback(
        (earTag: string) => dispatch(orderAction.searchOrders(undefined, earTag)), [dispatch],
    );

    return (
        <Container>
            <MainPanel>
                <div className="row">
                    <div className="col-12">
                        <PageHeading>
                            <h1>{t('title')}</h1>
                        </PageHeading>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <FindOrderForm
                            foundOrders={foundOrders.orNull()}
                            onSubmitBarcode={searchOrdersByBarcode}
                            onSubmitEarTag={searchOrdersByEarTag}
                        />
                    </div>
                </div>
            </MainPanel>
        </Container>
    );
};

export const ReceiveOrders = memo(ReceiveOrdersBase);
