import {Opt} from 'ts-opt';

import {AppInfo} from 'api/gen/AppInfo';
import {simpleAppSelector} from 'app/model/selector';
import {State} from 'app/state';

export const simpleOtherSelector = {
    catalogListsUpdated: ({other}: State): boolean => other.catalogListsUpdated,
    catalogListsUpdateInProgress: ({other}: State): boolean => other.catalogListsUpdateInProgress,
    animalsSychronizeStarted: ({other}: State): boolean => other.animalsSychronizeStarted,
    animalsSynchronizationInProgress: ({other}: State): boolean => other.animalsSynchronizationInProgress,
    paternityUpdateStarted: ({other}: State): boolean => other.paternityUpdateStarted,
    paternityUpdateInProgress: ({other}: State): boolean => other.paternityUpdateInProgress,
    appInfo: ({other}: State): Opt<AppInfo> => other.appInfo,
    appInfoLoading: (state: State): boolean => simpleAppSelector.localLoadingContent(state).includes('appInfo'),
};
