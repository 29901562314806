export const otherTranslations = {
    CS: {
        'other/screenOther': {
            tabName: 'Ostatní',
            updateInProgress: 'Probíhá aktualizace',
            synchronizationInProgress: 'Probíhá synchronizace',

            updateCatalogueLists: 'Aktualizovat kat. listy',
            synchronizeAnimals: 'Synchronizovat zvířata',
            synchronizeAnimalsTooltip: 'Synchronizuje data o zvířatech s databází aplikace eSkot.cz',
            updatePaternity: 'Aktualizovat paternitu',

            updateCatalogueListsTitle: 'Aktualizace katalogových listů',
            updateCatalogueListsSuccess: 'Katalogové listy byly aktualizované.',
            synchronizeAnimalsTitle: 'Synchronizace zvířat s aplikací eSkot',
            synchronizeAnimalsSuccess: 'Synchronizace byla spuštěna. Celý proces může trvat několik hodin.',
            updatePaternityTitle: 'Aktualizace paternity',
            updatePaternitySuccess: 'Aktualizace byla spuštěna. Celý proces může trvat několik hodin.',
        },
        'other/AppInfo': {
            title: 'O aplikaci',
            frontend: 'Frontend',
            backend: 'Backend',
            library: 'Knihovna',
            deployment: 'Nasazení',
            deployDate: 'Datum nasazení',
            version: 'Verze',
            dbVersion: 'Verze databáze',
            noData: 'Žádná data',
        },
        'other/messages': {
            getAppInfo: 'Načtení informací o aplikaci',
        },
    },
    EN: {
        'other/screenOther': {
            tabName: 'Others',
            updateInProgress: 'Update in progress',
            synchronizationInProgress: 'Synchronization in progress',

            updateCatalogueLists: 'Update cat. lists',
            synchronizeAnimals: 'Synchronize animals',
            synchronizeAnimalsTooltip: 'Synchronize data about animals with the eskot.cz database',
            updatePaternity: 'Update paternity',

            updateCatalogueListsTitle: 'Catalogue lists update',
            updateCatalogueListsSuccess: 'Catalogue lists were updated.',
            synchronizeAnimalsTitle: 'Animals synchronization with eSkot application',
            synchronizeAnimalsSuccess: 'Animals data from Plemdat were synchronized.',
            updatePaternityTitle: 'Paternity update',
            updatePaternitySuccess: 'Paternity update success.',
        },
        'other/AppInfo': {
            title: 'About aplication',
            frontend: 'Frontend',
            backend: 'Backend',
            library: 'Library',
            deployment: 'Deployment',
            deployDate: 'Deploy date',
            version: 'Version',
            dbVersion: 'Database version',
            noData: 'No data',
        },
        'other/messages': {
            getAppInfo: 'Load application info',
        },
    },
};
