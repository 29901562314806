import {putAll, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {none, opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {appAction} from 'app/model/action';
import {t} from 'translations';
import {showBeError} from 'utils/show-be-error';
import {
    getRequestedTablePageParams,
    tablesAction,
    simpleTableSelector,
    convertTableDataParamsToDto,
} from 'utils/tables';

import {LoadSnpAction, snpActions} from '../actions';
import {simpleSnpManagementSelector} from '../selector';

function* execute({payload: {tableDataRequest}}: LoadSnpAction): SagaIterator {
    if (tableDataRequest.isEmpty) {
        yield* put(snpActions.setSnpTableData(none));

        return yield* put(appAction.removeLoadingContent('snpManagement'));
    }
    yield* put(tablesAction.preloadTableActions('snpManagement', tableDataRequest.orCrash('No tableDataRequest')));

    const categories = yield* select(simpleSnpManagementSelector.categories);

    if (categories.isEmpty) {
        yield* put(snpActions.loadCategories());
    }

    const tableState = yield* select(simpleTableSelector.tableState('snpManagement'));
    const tableDataParams = getRequestedTablePageParams({
        tableId: 'snpManagement',
        tableDataRequest: tableDataRequest.orCrash('No tableDataRequest'),
        tableState,
    });

    const {filters, params} = convertTableDataParamsToDto({tableDataParams});

    const response = yield* call(Api.findAllSnps, filters, params);

    if (!response.isSuccess) {
        yield putAll(showBeError(response, t('snpManagement/SnpTable')(`tableHeaders.title`)));
        yield* put(appAction.removeLoadingContent('snpManagement'));
    } else {
        yield* put(snpActions.setSnpTableData(opt(response.data)));
        yield* put(tablesAction.postloadTableActions('snpManagement', tableDataParams));
    }
}

export function* loadSaga(): SagaIterator {
    yield takeLatestF('snpManagement/LOAD_SNP', execute);
}
