import {downloadFileFromResponse, putAll, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'translations';
import {showBeError} from 'utils/show-be-error';

import {animalsAction, ExportOriginInconsistencyReport} from '../action';

function* execute({payload: {originInconsistencyId}}: ExportOriginInconsistencyReport): SagaIterator {
    yield* put(animalsAction.setInconsistenciesActionActive(originInconsistencyId, true));

    const response = yield* call(Api.getPdfForRequestingOriginChanges, {originInconsistencyId});

    if (response.isSuccess) {
        yield* call(downloadFileFromResponse, response);
    } else {
        yield putAll(showBeError(response, t('animals/OriginInconsistenciesTable')('messages.reportError')));
    }

    yield* put(animalsAction.setInconsistenciesActionActive(originInconsistencyId, false));
}

export function* exportOriginInconsistencyReportSaga(): SagaIterator {
    yield takeLatestF('animals/EXPORT_ORIGIN_INCONSISTENCY_REPORT', execute);
}
