import {EmptyString, EmptyStringSchema, PResponse} from '@fl/cmsch-fe-library';
import {StatusCodes} from 'http-status-codes';
import * as t from 'io-ts';

import {apiRequester} from 'app/api';

import {ExportOrdersParams} from '../types/export-orders-params';

// TODO: use generated api when BE is ready
export const orderApi = {
    cancelOrdersBillingByFilter: async (params: ExportOrdersParams): PResponse<EmptyString> =>
        apiRequester.makeRequest({
            method: 'post',
            url: '/orders/cancel-billing',
            params,
            requestSchema: t.void,
            responseSchema: EmptyStringSchema,
            expectedStatus: StatusCodes.NO_CONTENT,
        }),
    cancelOrdersBillingByIds: async (orderIds: Array<number>, force: boolean): PResponse<EmptyString> =>
        apiRequester.makeRequest({
            method: 'post',
            url: '/orders/cancel-billing/subset',
            params: {orderIds, force},
            requestSchema: t.void,
            responseSchema: EmptyStringSchema,
            expectedStatus: StatusCodes.NO_CONTENT,
        }),
    deleteOrders: async (params: ExportOrdersParams): PResponse<EmptyString> =>
        apiRequester.makeRequest({
            method: 'delete',
            url: '/orders',
            params,
            requestSchema: t.void,
            responseSchema: EmptyStringSchema,
            expectedStatus: StatusCodes.NO_CONTENT,
        }),
    deleteOrderSubset: async (orderIds: Array<number>): PResponse<EmptyString> =>
        apiRequester.makeRequest({
            method: 'delete',
            url: '/orders/subset',
            params: {orderIds},
            requestSchema: t.void,
            responseSchema: EmptyStringSchema,
            expectedStatus: StatusCodes.NO_CONTENT,
        }),
};
