// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


const requiredPart = t.interface({
  caption: t.string,
  content: t.string,
  show: t.boolean,
});

export const SideBoxSchema = excess(requiredPart);

export interface SideBox extends t.TypeOf<typeof SideBoxSchema> {}
