import {classNames} from '@fl/cmsch-fe-library';
import {isEmpty} from 'lodash/fp';
import React, {FC} from 'react';

import {QtlsTable} from 'animals/components/QtlsTable';
import {Qtl} from 'api/gen/Qtl';
import {SideMenu} from 'layout';
import {useOurTranslation} from 'translations';

import styles from './styles.sass';

interface Props {
    animalQtls: Array<Qtl>;
    resetAnimalQtls(): void;
}

export const DetailTable: FC<Props> = props => {
    const {animalQtls, resetAnimalQtls} = props;
    const {t} = useOurTranslation('orders/DetailTable');

    return (
        <SideMenu
            title={t('title')}
            sticky
        >
            <div className="row">
                <div className={classNames('col-12', styles.table)}>
                    {!isEmpty(animalQtls)
                        ? (
                            <QtlsTable
                                qtls={animalQtls}
                                size="small"
                                resetAnimalQtls={resetAnimalQtls}
                                noScrollX
                            />
                        )
                        : t('noQtls')
                    }
                </div>
            </div>
        </SideMenu>
    );
};
