import React, {FC, memo, useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {OrderAnimalDetails} from 'api/gen/OrderAnimalDetails';
import {dialsAction} from 'dials';
import {SideMenu, Container, Panel, MainPanel, PageHeading} from 'layout';
import {newOrderFormName, newOrderInitialValues} from 'orders/components/NewForm/new-order-form-values';
import {useOurTranslation} from 'translations';
import {formHelpers} from 'utils/forms';

import {NewBulkForm} from '../components/NewBulkForm';
import {orderAction} from '../model';
import {simpleOrdersSelector} from '../model/selector';
import {transformBEAnimalDetailsToForm} from '../utils/transform-animal-details';

const NewBulkBase: FC = () => {
    const {t} = useOurTranslation('orders/screenNew');
    const dispatch = useDispatch();
    const foundAnimal = useSelector(simpleOrdersSelector.foundAnimal);
    const resetAnimal = useCallback(() => {
        dispatch(orderAction.animalsSearchSuccess(null));
        dispatch(orderAction.setIsNewOrderAnimalValid(false));
        dispatch(orderAction.setNewOrderEarNumberWarning(null));
        dispatch(orderAction.resetParentsAlreadyAnalyzed());
    }, [dispatch]);
    const setAnimalDetailsFormValues = useCallback((animal: OrderAnimalDetails) => {
        dispatch(formHelpers.change('newOrder', 'animalDetails', transformBEAnimalDetailsToForm(animal)));
    }, [dispatch]);

    useEffect(() => {
        resetAnimal();
        dispatch(orderAction.setUserDetailsFromCIN(null));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        dispatch(dialsAction.getSampleTypes());
        dispatch(orderAction.getOrdersCustomers());
        dispatch(dialsAction.getOrderTypes());
    }, [dispatch]);

    useEffect(() => {
        foundAnimal.caseOf(
            setAnimalDetailsFormValues,
            () => dispatch(formHelpers.initialize(newOrderFormName, newOrderInitialValues)));
    }, [foundAnimal, dispatch, setAnimalDetailsFormValues]);

    return (
        <Container maxLgWidth>
            <MainPanel>
                <div className="row">
                    <div className="col-12">
                        <PageHeading>
                            <h1>{t('captionBulk')}</h1>
                        </PageHeading>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-lg-9">
                        <Panel>
                            <NewBulkForm />
                        </Panel>
                    </div>
                    <div className="col-12 col-lg-3 pl-lg-2">
                        <SideMenu title={t('menuCaption')}>
                            <div>{t('description.one')}</div>
                            <div>{t('description.two')}</div>
                            <div>{t('description.three')}</div>
                            <div>{t('description.four')}</div>
                        </SideMenu>
                    </div>
                </div>
            </MainPanel>
        </Container>
    );
};

export const NewBulk = memo(NewBulkBase);
