// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


const requiredPart = t.interface({
  name: t.string,
});

export const SnpValidationSchema = excess(requiredPart);

export interface SnpValidation extends t.TypeOf<typeof SnpValidationSchema> {}
