// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { Address, AddressSchema } from 'api/gen/Address';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Cin, CinSchema } from 'api/gen/Cin';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { CustomerName, CustomerNameSchema } from 'api/gen/CustomerName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { FirstName, FirstNameSchema } from 'api/gen/FirstName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { LastName, LastNameSchema } from 'api/gen/LastName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Phone, PhoneSchema } from 'api/gen/Phone';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PlemdatEmail, PlemdatEmailSchema } from 'api/gen/PlemdatEmail';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveLong, PositiveLongSchema } from 'api/gen/PositiveLong';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { VatNumber, VatNumberSchema } from 'api/gen/VatNumber';

const requiredPart = t.interface({
  address: t.union([AddressSchema, t.null]),
  billingAddress: t.union([AddressSchema, t.null]),
  cin: t.union([CinSchema, t.null]),
  customerName: t.union([CustomerNameSchema, t.null]),
  email: PlemdatEmailSchema,
  firstName: t.union([FirstNameSchema, t.null]),
  id: t.union([PositiveLongSchema, t.null]),
  lastName: t.union([LastNameSchema, t.null]),
  phone: t.union([PhoneSchema, t.null]),
  vatNumber: t.union([VatNumberSchema, t.null]),
});

export const LastOrderUserDetailsSchema = excess(requiredPart);

export interface LastOrderUserDetails extends t.TypeOf<typeof LastOrderUserDetailsSchema> {}
