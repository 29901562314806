import {takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {put, select} from 'typed-redux-saga';

import {AddLoadingContentAction, appAction} from '../action';
import {simpleAppSelector, urlWasChanged} from '../selector';

function* execute(action: AddLoadingContentAction): SagaIterator {
    const pathWasChanged = yield* select(urlWasChanged);
    if (pathWasChanged) {
        yield* put(appAction.addLoadingContentSuccess(action.payload));
    }
    const actualUrl = yield* select(simpleAppSelector.actualRouterPath);
    yield* put(appAction.changeLoadersUrl(actualUrl));
}

export function* addLoadingContent(): SagaIterator {
    yield takeLatestF('app/ADD_LOADING_CONTENT', execute);
}
