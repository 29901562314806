import {TableDataParams} from '@fl/cmsch-fe-library';
import {Opt} from 'ts-opt';

import {OriginInconsistenciesTableRow} from 'animals/components/OriginInconsistenciesTable/table-row';
import {PagedModelAnimal} from 'api/gen/PagedModelAnimal';
import {PagedModelOriginInconsistency} from 'api/gen/PagedModelOriginInconsistency';
import {State} from 'app/state';

import {AnimalsLoadingId} from '../types/animals-loading-id';

export const simpleAnimalsSelector = {
    pageTableData: ({animals}: State): Opt<PagedModelOriginInconsistency> => animals.originInconsistenciesPage,
    animalsPage: ({animals}: State): Opt<PagedModelAnimal> => animals.animalsPage,
    selectedOriginInconsistencyIds: ({animals}: State): Array<number> => animals.selectedOriginInconsistencyIds,
    originInconsistenciesParams: ({tables}: State): TableDataParams<OriginInconsistenciesTableRow> | undefined =>
        tables.tableStates.originInconsistencies?.tableDataParams,
    loading: ({animals}: State): Array<AnimalsLoadingId> => animals.loading,
};
