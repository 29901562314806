import {NullableKeys} from '@fl/cmsch-fe-library';

import {Date} from 'api/gen/Date';

export const orderEditFormName = 'editOrder';

export type OrderEditFormValues = NullableKeys<{
    barcode: string,
    isolated: boolean,
    isolationDate: Date,
    laboratoryNote: string,
    laboratoryNumber: string,
    orderTypeIds: Array<number>,
    sampleDeliveredDate: Date,
    sampleTypeId: number,
}>;

export const orderEditInitialValues: OrderEditFormValues = {
    laboratoryNumber: null,
    barcode: null,
    sampleDeliveredDate: null,
    isolationDate: null,
    isolated: null,
    laboratoryNote: null,
    orderTypeIds: [],
    sampleTypeId: null,
};
