import React, {ReactNode} from 'react';
import {Route, RouteComponentProps} from 'react-router-dom';

import {Import} from './screens';
import {ImportScreenType} from './types/import-screen-type';

const createImportScreen = (imporType: ImportScreenType) =>
    (props: RouteComponentProps): ReactNode => (
        <Import
            {...props} // eslint-disable-line react/jsx-props-no-spreading
            importType={imporType}
        />
    );

export const fileImportRoutesWithoutFetching: Array<RegExp> = [/^\/import(.*)$/];

export const fileImportRoutes = [
    (
        <Route
            path="/import/file"
            exact
            render={createImportScreen(ImportScreenType.importChips)}
            key="file-import"
        />
    ),
    (
        <Route
            path="/import"
            exact
            render={createImportScreen(ImportScreenType.importChips)}
            key="file-import"
        />
    ),
    (
        <Route
            path="/import/hungarian"
            exact
            render={createImportScreen(ImportScreenType.hungarian)}
            key="hungarian-import"
        />
    ),
    (
        <Route
            path="/import/plemdata"
            exact
            render={createImportScreen(ImportScreenType.plemdat)}
            key="plemdata-import"
        />
    ),
    (
        <Route
            path="/import/czech"
            exact
            render={createImportScreen(ImportScreenType.czech)}
            key="czech-import"
        />
    ),
    (
        <Route
            path="/import/orders-updates"
            exact
            render={createImportScreen(ImportScreenType.ordersUpdates)}
            key="orders-updates-import"
        />
    ),
];
