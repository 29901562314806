// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.75.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.75.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-orders-components-GenerateRequestForm-___styles__generateRequestForm___dbv6i label{margin-top:5px}\n", "",{"version":3,"sources":["webpack://./src/modules/orders/components/GenerateRequestForm/styles.sass"],"names":[],"mappings":"AAAA,gGAAoC,cAAA","sourcesContent":[".generateRequestForm :global(label){margin-top:5px}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"generateRequestForm": "src-modules-orders-components-GenerateRequestForm-___styles__generateRequestForm___dbv6i"
};
export default ___CSS_LOADER_EXPORT___;
