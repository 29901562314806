import {NullableKeys} from '@fl/cmsch-fe-library';

import {t} from 'translations';
import {PaternityValidator} from 'utils/paternity-validator';
import {Errors} from 'utils/validator';

import {OrdersTypesFormValues} from './order-types-form-values';

export const validate = (values: NullableKeys<OrdersTypesFormValues>): Errors<OrdersTypesFormValues> => {
    const errors: Errors<OrdersTypesFormValues> = {};

    if (values.ordersTypes) {
        const validator = new PaternityValidator(values.ordersTypes);
        validator.nonEmpty('orderTypeIds', t('orders/OrderDetailsForm')('orderTypeIds'));
        errors.ordersTypes = validator.generateErrorsObject();
    }

    return errors;
};
