// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { AnimalName, AnimalNameSchema } from 'api/gen/AnimalName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Breed, BreedSchema } from 'api/gen/Breed';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Date, DateSchema } from 'api/gen/Date';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { GlobalEarTag, GlobalEarTagSchema } from 'api/gen/GlobalEarTag';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { LineRegistry, LineRegistrySchema } from 'api/gen/LineRegistry';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { SexType, SexTypeSchema } from 'api/gen/SexType';

const requiredPart = t.interface({
  breed: BreedSchema,
  dob: t.union([DateSchema, t.null]),
  fathersRegistry: t.union([LineRegistrySchema, t.null]),
  fathersSampleId: t.union([GlobalEarTagSchema, t.null]),
  laboratoryNote: t.union([t.string, t.null]),
  mothersSampleId: t.union([GlobalEarTagSchema, t.null]),
  name: t.union([AnimalNameSchema, t.null]),
  registry: t.union([LineRegistrySchema, t.null]),
  sampleId: GlobalEarTagSchema,
  sex: SexTypeSchema,
  twin: t.boolean,
});

export const AnimalUpdateSchema = excess(requiredPart);

export interface AnimalUpdate extends t.TypeOf<typeof AnimalUpdateSchema> {}
