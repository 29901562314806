import {putAll, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {none, opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {appAction} from 'app/model/action';
import {t} from 'translations';
import {showBeError} from 'utils/show-be-error';
import {simpleTableSelector, tablesAction, getRequestedTablePageParams} from 'utils/tables';

import {convertAnimalsTableDataParamsToDto} from '../../utils/conver-table-data-params-to-dto';
import {animalsAction, GetAnimals} from '../action';

function* execute({payload: {tableDataRequest}}: GetAnimals): SagaIterator {
    if (tableDataRequest.isEmpty) {
        yield* put(animalsAction.getAnimalsSuccess(none));

        return yield* put(appAction.removeLoadingContent('animals'));
    }
    yield* put(appAction.addLoadingContent('animals'));
    yield* put(tablesAction.preloadTableActions('animals', tableDataRequest.orCrash('No tableDataRequest')));

    const tableState = yield* select(simpleTableSelector.tableState('animals'));

    const tableDataParams = getRequestedTablePageParams({
        tableId: 'animals',
        tableDataRequest: tableDataRequest.orCrash('No tableDataRequest'),
        tableState,
    });

    const {filters, params} = convertAnimalsTableDataParamsToDto(tableDataParams);
    const response = yield* call(Api.findAllAnimals, filters, params);

    if (response.isSuccess) {
        yield* put(animalsAction.getAnimalsSuccess(opt(response.data)));
        yield* put(tablesAction.postloadTableActions('animals', tableDataParams));
    } else {
        yield putAll(showBeError(response, t('animals/screenList')('title')));
        yield* put(appAction.removeLoadingContent('animals'));
    }
}

export function* getAnimalsSaga(): SagaIterator {
    yield takeLatestF('animals/GET_ANIMALS', execute);
}
