import {FooterButtons} from '@fl/cmsch-fe-library';
import React, {FC, memo} from 'react';

import {useOurTranslation} from 'translations';
import {useForm} from 'utils/forms';

import {
    editMailTemplateFormName,
    initialMailTemplateFormValues,
    MailTemplateFormValues,
} from './mail-template-form-values';
import {validate} from './validations';

interface Props {
    isEdit?: boolean;
    onCancel?(): void;
    onSubmit(values: MailTemplateFormValues): void;
}

const MailTemplateFormBase: FC<Props> = props => {
    const {
        isEdit,
        onSubmit,
        onCancel,
    } = props;

    const {t} = useOurTranslation('dials/mailTemplates');

    const {Form, Fields, valid, pristine, submitting, renderErrors} = useForm({
        form: editMailTemplateFormName,
        initialValues: initialMailTemplateFormValues,
        destroyOnUnmount: true,
        validate,
        onSubmit,
    });

    return (
        <Form>
            {renderErrors()}

            <div className="row">
                <div className="col-12">
                    <Fields.Input
                        name="code"
                        type="text"
                        label={t('code')}
                        disabled={isEdit}
                        isRequired
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <Fields.Input
                        name="subject"
                        type="text"
                        label={t('subject')}
                        isRequired
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <Fields.Input
                        name="description"
                        type="text"
                        label={t('description')}
                        isRequired
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <Fields.Textarea
                        name="body"
                        label={t('body')}
                        rows={10}
                        isRequired
                    />
                </div>
            </div>
            <FooterButtons
                editing={isEdit}
                disabledSubmit={!valid || pristine || submitting}
                onCancel={onCancel}
            />
        </Form>
    );
};

export const MailTemplateForm = memo(MailTemplateFormBase);
