import {NullableKeys} from '@fl/cmsch-fe-library';

import {
    UserDetailsFormSectionValues,
    userDetailsInitialValues,
} from 'orders/components/UserDetailsFormSection/user-details-form-section-values';

import {
    AnimalDetailsFormSectionValues,
    animalDetailsInitialValues,
} from '../AnimalDetailsForm/animal-details-form-section-values';

export const newOrderFormName = 'newOrder';

export type NewOrderFormValues = NullableKeys<{
    animalDetails: AnimalDetailsFormSectionValues,
    barcode: string,
    sampleTypeId: number,
    orderTypeIds: Array<number>,
    customerNote: string,
    userDetails: UserDetailsFormSectionValues,
}>;

export const newOrderInitialValues: NewOrderFormValues = {
    animalDetails: animalDetailsInitialValues,
    barcode: null,
    sampleTypeId: null,
    orderTypeIds: null,
    customerNote: null,
    userDetails: userDetailsInitialValues,
};
