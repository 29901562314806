import {Nullable, Options} from '@fl/cmsch-fe-library';
import React, {Fragment, FC, memo, useCallback, useEffect, useState} from 'react';
import {Opt} from 'ts-opt';

import {LastOrderUserDetails} from 'api/gen/LastOrderUserDetails';
import {OrderCustomerDetails} from 'api/gen/OrderCustomerDetails';
import {User} from 'api/gen/User';
import {useOurTranslation} from 'translations';
import {useUser} from 'user';
import {getSexOptions} from 'utils/get-sex-options';

import {Parent} from '../../types/parent';
import {ValidateAnimal} from '../../types/validate-animal-type';
import {AnimalDetailsForm} from '../AnimalDetailsForm';
import {NewUserDetailsForm} from '../NewUserDetailsForm';
import {OrderConfirmationForm} from '../OrderConfirmationForm';
import {OrderDetailsForm} from '../OrderDetailsForm';
import {NewOrderFormValues} from './new-order-form-values';

enum Page {
    pageOne = 1,
    pageTwo,
    pageThree,
    pageFour,
    pageMax,
}

const isLastPage = (p: Page): boolean => p === Page.pageMax - 1;

interface Props {
    formValues: NewOrderFormValues;
    sampleTypes: Options<number>;
    orderTypes: Options<number>;
    hasSameAddresses: boolean;
    motherAlreadyAnalyzed: boolean;
    fatherAlreadyAnalyzed: boolean;
    customers: Array<OrderCustomerDetails>;
    showCreatedBy: boolean;
    breeders: Array<User>;
    userDetailsFromCIN: Nullable<LastOrderUserDetails>;
    noCin: boolean;
    isNewOrderAnimalValid: boolean;
    newOrderEarNumberWarning: Opt<string>;
    onValidateAnimal(sampleId: Nullable<string>, registry: Nullable<string>, type: ValidateAnimal): void;
    onGetLaboratoryNumberBySampleId(sampleId: Nullable<string>, parent: Parent): void;
    onPrefillUserDetailsFromExistingOrder(value: OrderCustomerDetails): void;
    onGetUserLastOrder(cin: Nullable<string>): void;
    onGetBreeders(): void;
    onCreateOrder(values: NewOrderFormValues): void;
    onCheckParentRegistry(registry: string): void;
}

const NewFormBase: FC<Props> = props => {
    const {
        formValues,
        sampleTypes,
        orderTypes,
        hasSameAddresses,
        motherAlreadyAnalyzed,
        fatherAlreadyAnalyzed,
        customers,
        showCreatedBy,
        breeders,
        userDetailsFromCIN,
        noCin,
        isNewOrderAnimalValid,
        newOrderEarNumberWarning,
        onValidateAnimal,
        onGetUserLastOrder,
        onGetLaboratoryNumberBySampleId,
        onPrefillUserDetailsFromExistingOrder,
        onGetBreeders,
        onCreateOrder,
        onCheckParentRegistry,
    } = props;

    const [page, setPage] = useState(Page.pageOne);

    const handleNextPageClick = useCallback(() => {
        if (!isLastPage(page)) setPage(page + 1);
    }, [page, setPage]);
    const handlePreviousPageClick = useCallback(() => {
        if (page > Page.pageOne) setPage(page - 1);
    }, [page, setPage]);

    const {isRoleBreeder} = useUser();
    const {t} = useOurTranslation('orders/UserDetailsForm');

    useEffect(() => {
        if (isRoleBreeder) onGetUserLastOrder(null);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const animalSexes = getSexOptions();

    useEffect(() => {
        if (showCreatedBy) {
            onGetBreeders();
        }
    }, [onGetBreeders, showCreatedBy]);

    return (
        <Fragment>
            {page === Page.pageOne && (
                <AnimalDetailsForm
                    onValidateAnimal={onValidateAnimal}
                    onGetLaboratoryNumberBySampleId={onGetLaboratoryNumberBySampleId}
                    onSubmit={handleNextPageClick}
                    motherAlreadyAnalyzed={motherAlreadyAnalyzed}
                    fatherAlreadyAnalyzed={fatherAlreadyAnalyzed}
                    animalSexes={animalSexes}
                    isNewOrderAnimalValid={isNewOrderAnimalValid}
                    newOrderEarNumberWarning={newOrderEarNumberWarning}
                    onCheckParentRegistry={onCheckParentRegistry}
                />
            )}

            {page === Page.pageTwo && (
                <OrderDetailsForm
                    sampleTypes={sampleTypes}
                    orderTypes={orderTypes}
                    onPreviousClick={handlePreviousPageClick}
                    onSubmit={handleNextPageClick}
                />
            )}

            {page === Page.pageThree && (
                <NewUserDetailsForm
                    onPreviousClick={handlePreviousPageClick}
                    hasSameAddresses={hasSameAddresses}
                    onSubmit={handleNextPageClick}
                    onPrefillFromExisting={onPrefillUserDetailsFromExistingOrder}
                    customers={customers}
                    showCreatedBy={showCreatedBy}
                    breeders={breeders}
                    onGetUserLastOrder={onGetUserLastOrder}
                    userDetailsFromCIN={userDetailsFromCIN}
                    noCin={noCin}
                    caption={t('stepCaption')}
                />
            )}

            {page === Page.pageFour && (
                <OrderConfirmationForm
                    formValues={formValues}
                    animalSexes={animalSexes}
                    sampleTypes={sampleTypes}
                    orderTypes={orderTypes}
                    onPreviousClick={handlePreviousPageClick}
                    onSubmit={onCreateOrder}
                />
            )}
        </Fragment>
    );
};

export const NewForm = memo(NewFormBase);
