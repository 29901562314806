// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


const requiredPart = t.interface({
  overallPercentage: t.number,
  overallPercentageDifference: t.number,
});

export const PregnancySchema = excess(requiredPart);

export interface Pregnancy extends t.TypeOf<typeof PregnancySchema> {}
