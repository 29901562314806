import {Options} from '@fl/cmsch-fe-library';

import {tCommon} from 'translations';
import {ParenthoodValues} from 'types/parenthood-values';

export const getParenthoodOptions = (): Options<ParenthoodValues> => [
    {
        value: 'MISSING',
        label: tCommon('parenthood.missing'),
    },
    {
        value: 'MATCHES',
        label: tCommon('parenthood.matches'),
    },
    {
        value: 'MISMATCHES',
        label: tCommon('parenthood.mismatches'),
    },
];
