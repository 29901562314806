// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.75.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.75.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-layout-components-panel-___styles__panel___RY2xE{position:relative;box-shadow:0 2px 6px #0003;background-color:#fff}.src-modules-layout-components-panel-___styles__title___PjW3Y{padding:.75rem 1rem;background-color:#e0f0c6}.src-modules-layout-components-panel-___styles__title___PjW3Y h1,.src-modules-layout-components-panel-___styles__title___PjW3Y h2,.src-modules-layout-components-panel-___styles__title___PjW3Y h3,.src-modules-layout-components-panel-___styles__title___PjW3Y h4,.src-modules-layout-components-panel-___styles__title___PjW3Y h5,.src-modules-layout-components-panel-___styles__title___PjW3Y h6{font-size:1rem;line-height:1.5rem;margin:0;padding:0}.src-modules-layout-components-panel-___styles__body___uTST3{padding:1rem;border-top:.125rem solid #7f9a56}.src-modules-layout-components-panel-___styles__collapseToggle___Wdl3Q button{position:absolute;top:.5rem;right:.5rem;cursor:pointer}\n", "",{"version":3,"sources":["webpack://./src/modules/layout/components/panel/styles.sass"],"names":[],"mappings":"AAAA,8DAAO,iBAAA,CAAkB,0BAAA,CAAsC,qBAAA,CAAsB,8DAArF,mBAAA,CAAgH,wBAAA,CAAyB,sYAA4D,cAAA,CAAe,kBAAA,CAApN,QAAA,CAAA,SAAA,CAA0P,6DAA1P,YAAA,CAA6Q,gCAAA,CAAiC,8EAAuB,iBAAA,CAAkB,SAAA,CAAU,WAAA,CAAY,cAAA","sourcesContent":[".panel{position:relative;box-shadow:0 2px 6px 0 rgba(0,0,0,.2);background-color:#fff}.title{padding:.75rem 1rem;background-color:#e0f0c6}.title h1,.title h2,.title h3,.title h4,.title h5,.title h6{font-size:1rem;line-height:1.5rem;margin:0;padding:0}.body{padding:1rem;border-top:.125rem solid #7f9a56}.collapseToggle button{position:absolute;top:.5rem;right:.5rem;cursor:pointer}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"panel": "src-modules-layout-components-panel-___styles__panel___RY2xE",
	"title": "src-modules-layout-components-panel-___styles__title___PjW3Y",
	"body": "src-modules-layout-components-panel-___styles__body___uTST3",
	"collapseToggle": "src-modules-layout-components-panel-___styles__collapseToggle___Wdl3Q"
};
export default ___CSS_LOADER_EXPORT___;
