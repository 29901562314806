import {beDateFormat, classNames, Options} from '@fl/cmsch-fe-library';
import {debounce} from 'lodash/fp';
import moment from 'moment';
import React, {FC, memo, useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {Opt, testRe} from 'ts-opt';

import {labNumberRegexGen} from 'api/gen/LabNumber';
import {OrderDetails} from 'api/gen/OrderDetails';
import {FooterButtons} from 'common/buttons';
import {debounceTime} from 'constants/general';
import {Panel} from 'layout';
import {useOurTranslation} from 'translations';
import {formHelpers, useForm} from 'utils/forms';

import {OrderEditFormValues, orderEditInitialValues, orderEditFormName} from './order-edit-form-values';
import {validate} from './validations';

const barcodeFieldName = 'barcode';
const sampleDeliveredDateFieldName = 'sampleDeliveredDate';

interface Props {
    orderTypes: Options<number>;
    sampleTypes: Options<number>;
    isRoleBreeder: boolean;
    order: OrderDetails;
    isRedirectFromReceiveOrders: boolean;
    onCancel(): void;
    validateLaboratoryNumber(orderId: number, laboratoryNumber: string): void;
    onSubmit(values: OrderEditFormValues): void;
}

// eslint-disable-next-line max-lines-per-function
const EditFormBase: FC<Props> = props => {
    const {
        orderTypes,
        sampleTypes,
        isRoleBreeder,
        order,
        isRedirectFromReceiveOrders,
        onCancel,
        validateLaboratoryNumber,
        onSubmit,
    } = props;

    const {t} = useOurTranslation('orders/EditForm');

    const formValues = useSelector(formHelpers.formValues(orderEditFormName));
    const sampleDeliveredDate = formValues.prop('sampleDeliveredDate').orNull();

    const {Form, Fields, valid, submitting, pristine, change} = useForm({
        form: orderEditFormName,
        initialValues: orderEditInitialValues,
        enableReinitialize: false,
        destroyOnUnmount: false,
        forceUnregisterOnUnmount: true,
        keepDirtyOnReinitialize: true,
        validate,
        onSubmit,
    });

    const onChangeLabNumberDebounced = useMemo(() =>
        debounce(debounceTime)(validateLaboratoryNumber),
    [validateLaboratoryNumber]);

    const handleChangeLaboratoryNumber = useCallback((value: Opt<string>) => {
        value
            .filter(testRe(labNumberRegexGen()))
            .onSome(x => onChangeLabNumberDebounced(order.id, x));
    }, [order.id, onChangeLabNumberDebounced]);

    const handleLaboratoryNumberPasteOrScan = useCallback(() => {
        if (isRedirectFromReceiveOrders) {
            setTimeout(() => onSubmit(formValues.orCrash('missing values')));
        } else {
            document.getElementById(barcodeFieldName)?.focus();
        }
    }, [isRedirectFromReceiveOrders, formValues, onSubmit]);

    const handleBarcodePasteOrScan = useCallback(() => {
        if (sampleDeliveredDate === null) {
            change(sampleDeliveredDateFieldName, moment().format(beDateFormat));
        }
    }, [sampleDeliveredDate, change]);

    return (
        <Panel
            title={t('title')}
            extraBottomSpace
            testId="edit-order-form-wrapper"
        >
            <Form>
                <div className="grid-form">
                    <fieldset>
                        {!isRoleBreeder && (
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <Fields.Input
                                        name="laboratoryNumber"
                                        label={t('laboratoryNumber')}
                                        type="text"
                                        autoFocus
                                        onFieldChange={handleChangeLaboratoryNumber}
                                        onPasting={handleLaboratoryNumberPasteOrScan}
                                    />
                                </div>
                                <div className="col-12 col-md-6">
                                    <Fields.Input
                                        name={barcodeFieldName}
                                        label={t('barcode')}
                                        type="text"
                                        onAction={handleBarcodePasteOrScan}
                                        actionOnPaste
                                        actionOnEnter
                                        hideActionIcon
                                    />
                                </div>
                            </div>
                        )}

                        {!isRoleBreeder && (
                            <div className="row align-items-center">
                                <div className="col-12 col-md-6">
                                    <Fields.NumberSelect
                                        name="sampleTypeId"
                                        label={t('sampleTypeId')}
                                        placeholder={t('sampleTypeIdPlaceholder')}
                                        options={sampleTypes}
                                        isRequired
                                        clearable={false}
                                        searchable={false}
                                    />
                                </div>
                                <div className="col-12 col-md-6">
                                    <Fields.DateInput
                                        name={sampleDeliveredDateFieldName}
                                        label={t('sampleDeliveredDate')}
                                        clearable
                                    />
                                </div>
                            </div>
                        )}

                        {!isRoleBreeder && (
                            <div className="row align-items-center">
                                <div className="col-12 col-md-6">
                                    <Fields.Checkbox
                                        name="isolated"
                                        label={t('isolated')}
                                    />
                                </div>
                                <div className="col-12 col-md-6">
                                    <Fields.DateInput
                                        name="isolationDate"
                                        label={t('isolationDate')}
                                        clearable
                                    />
                                </div>
                            </div>
                        )}

                        <div className="row align-items-center">
                            <div className={classNames('col-12', !isRoleBreeder && 'col-md-6')}>
                                <Fields.NumberMultiSelect
                                    name="orderTypeIds"
                                    label={t('orderTypeIds')}
                                    placeholder={t('orderTypeIdsPlaceholder')}
                                    options={orderTypes}
                                    isRequired
                                />
                            </div>

                            {!isRoleBreeder && (
                                <div className="col-12 col-md-6">
                                    <Fields.Textarea
                                        name="laboratoryNote"
                                        label={t('laboratoryNote')}
                                        rows={5}
                                    />
                                </div>
                            )}
                        </div>
                    </fieldset>

                    <FooterButtons
                        editing
                        disabledSubmit={!valid || pristine || submitting}
                        disabledCancel={pristine || submitting}
                        onCancel={onCancel}
                    />
                </div>
            </Form>
        </Panel>
    );
};

export const EditForm = memo(EditFormBase);
