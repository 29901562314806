// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.75.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.75.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-layout-components-panel-title-with-image-___styles__imageSmall___p0_cY,.src-modules-layout-components-panel-title-with-image-___styles__imageSmall___p0_cY svg{height:2em;width:2em}\n", "",{"version":3,"sources":["webpack://./src/modules/layout/components/panel-title-with-image/styles.sass"],"names":[],"mappings":"AAAA,4KAA4B,UAAA,CAAW,SAAA","sourcesContent":[".imageSmall,.imageSmall svg{height:2em;width:2em}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imageSmall": "src-modules-layout-components-panel-title-with-image-___styles__imageSmall___p0_cY"
};
export default ___CSS_LOADER_EXPORT___;
