// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


const requiredPart = t.interface({
  alleles: t.string,
  value: t.string,
});

export const HaplotypeSchema = excess(requiredPart);

export interface Haplotype extends t.TypeOf<typeof HaplotypeSchema> {}
