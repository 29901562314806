import {ErrorResponse, ErrorsFromBE, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {put, call} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {formHelpers} from 'utils/forms';
import {Errors} from 'utils/validator';

import {VerificationOutcomeFormValues} from '../../components/VerificationOutcomeForm/verification-outcome-form-values';
import {ValidateVerificationOutcomeTypeAction} from '../action';

const buildFormErrors = (response: ErrorResponse): Errors<VerificationOutcomeFormValues> => {
    const errors = response.data as ErrorsFromBE;

    return {value: errors[0].message};
};

function* execute(action: ValidateVerificationOutcomeTypeAction): SagaIterator {
    const {data} = action.payload;
    const formName = data.idToExclude === null ? 'createVerificationOutcome' : 'editVerificationOutcome';

    const response = yield* call(Api.validateVerificationOutcomeType, data);

    if (response.isSuccess) {
        yield* put(formHelpers.stopAsyncValidation(formName));
    } else {
        yield* put(formHelpers.stopAsyncValidation(formName, buildFormErrors(response)));
    }
}

export function* validateVerificationOutcomeTypeSaga(): SagaIterator {
    yield takeLatestF('dials/VALIDATE_VERIFICATION_OUTCOME_TYPE', execute);
}
