// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// Example: CS

export const LanguageSchema = t.keyof({
  CS: null,
  EN: null,
});

export type Language = t.TypeOf<typeof LanguageSchema>;
