import {NotificationState} from '@fl/cmsch-fe-library';
import {RouterState} from 'redux-first-history';

import {AnimalsState, initialAnimalsState} from 'animals/model/state';
import {FormStates} from 'app/form-state';
import {AppState, initialAppState} from 'app/model/state';
import {DialsState, initialDialsState} from 'dials/model/state';
import {FileImportState, initialFileImportState} from 'file-import/model/state';
import {GenotypeExportsState, initialGenotypeExportsState} from 'genotypeExports/model/state';
import {initialOrderState, OrderState} from 'orders/model/state';
import {OtherState, initialOtherState} from 'other/model/state';
import {initialParentSearchState, ParentSearchState} from 'parentSearch/model/state';
import {initialPaternityCheckState, PaternityCheckState} from 'paternityCheck/model/state';
import {SnpState, initialSnpState} from 'snp-management/model/state';
import {initialUserState, UserState} from 'user/model/state';
import {initialTablesState, TablesState} from 'utils/tables';

export interface State {
    router: RouterState;
    toastr: NotificationState;
    form: FormStates;
    tables: TablesState;
    animals: AnimalsState;
    orders: OrderState;
    user: UserState;
    dials: DialsState;
    fileImport: FileImportState;
    paternityCheck: PaternityCheckState;
    parentSearch: ParentSearchState;
    snpManagement: SnpState;
    other: OtherState;
    genotypeExports: GenotypeExportsState;
    app: AppState;
}

export const initialState: Partial<State> = {
    animals: initialAnimalsState,
    orders: initialOrderState,
    user: initialUserState,
    dials: initialDialsState,
    fileImport: initialFileImportState,
    paternityCheck: initialPaternityCheckState,
    parentSearch: initialParentSearchState,
    snpManagement: initialSnpState,
    other: initialOtherState,
    genotypeExports: initialGenotypeExportsState,
    tables: initialTablesState,
    app: initialAppState,
};
