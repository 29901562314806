import {showSuccess, showError} from '@fl/cmsch-fe-library';
import {Action} from 'redux';

import {tCommon} from 'translations';

interface Notification {
    title?: string;
    message: string;
}

export const showSuccessSimple = ({title, message}: Notification): Action =>
    showSuccess(title ?? tCommon('success'), message);

export const showErrorSimple = ({title, message}: Notification): Action =>
    showError(title ?? tCommon('errorOccured'), message);
