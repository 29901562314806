// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { Barcode, BarcodeSchema } from 'api/gen/Barcode';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Date, DateSchema } from 'api/gen/Date';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { LabNumber, LabNumberSchema } from 'api/gen/LabNumber';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveLong, PositiveLongSchema } from 'api/gen/PositiveLong';

const requiredPart = t.interface({
  barcode: t.union([BarcodeSchema, t.null]),
  isolated: t.union([t.boolean, t.null]),
  isolationDate: t.union([DateSchema, t.null]),
  laboratoryNote: t.union([t.string, t.null]),
  laboratoryNumber: t.union([LabNumberSchema, t.null]),
  orderTypeIds: t.array(PositiveLongSchema),
  sampleDeliveredDate: t.union([DateSchema, t.null]),
  sampleTypeId: PositiveLongSchema,
});

export const UpdateOrderDetailsSchema = excess(requiredPart);

export interface UpdateOrderDetails extends t.TypeOf<typeof UpdateOrderDetailsSchema> {}
