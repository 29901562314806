// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.75.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.75.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-file-import-components-step-icon-___styles__stepnumberPosition___rS35S{left:1.05em;top:.4em}\n", "",{"version":3,"sources":["webpack://./src/modules/file-import/components/step-icon/styles.sass"],"names":[],"mappings":"AAAA,oFAAoB,WAAA,CAAY,QAAA","sourcesContent":[".stepnumberPosition{left:1.05em;top:.4em}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stepnumberPosition": "src-modules-file-import-components-step-icon-___styles__stepnumberPosition___rS35S"
};
export default ___CSS_LOADER_EXPORT___;
