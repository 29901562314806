import {Nullable} from '@fl/cmsch-fe-library';
import React, {Fragment, FC, memo, useCallback} from 'react';

import {ActionButton} from 'common/buttons';
import {SideMenu} from 'layout';
import {useOurTranslation} from 'translations';
import {useUser} from 'user';

interface Props {
    id: number;
    protocolGenerated: boolean;
    readyForBilling: boolean;
    fullyBilled: boolean;
    laboratoryNumberIsValid: boolean;
    laboratoryNumber: Nullable<string>;
    animalId: number;
    onGoBackClick(): void;
    handleCloseForBillingBase(): void;
    handleDeleteProtocol(): void;
    goTo(path: string): void;
    onOrderDelete(): void;
}

const DetailActionsBase: FC<Props> = props => {
    const {
        id,
        protocolGenerated,
        readyForBilling,
        fullyBilled,
        laboratoryNumber,
        animalId,
        onGoBackClick,
        handleCloseForBillingBase,
        handleDeleteProtocol,
        goTo,
        onOrderDelete,
        laboratoryNumberIsValid,
    } = props;

    const {
        isRoleBreeder,
        isRoleLaborer,
        isRoleBreedingUnion,
    } = useUser();
    const {
        t,
        tCommon,
    } = useOurTranslation('orders/DetailActions');

    const goToGenerateProtocol = useCallback(() => {
        goTo(`/orders/${id}/generate-protocol`);
    }, [goTo, id]);
    const goToGenerateRequest = useCallback(() => {
        goTo(`/orders/${id}/generate-request`);
    }, [goTo, id]);

    const closeForBillingEnabled = !readyForBilling && !fullyBilled && laboratoryNumberIsValid;
    const showProtocol = !isRoleBreeder && !isRoleBreedingUnion;
    const showRequest = !isRoleBreedingUnion;
    const showCloseForBillingBase = isRoleLaborer && !isRoleBreedingUnion;
    const showAnimalDetail = isRoleLaborer || isRoleBreedingUnion;

    return (
        <SideMenu title={t('menuTitle')}>
            {showProtocol && (
                <Fragment>
                    <ActionButton
                        icon="fileTextOutlined"
                        onClick={goToGenerateProtocol}
                        disabled={!laboratoryNumber}
                        disabledTooltip={tCommon('noLaboratoryNumber')}
                        testId="generate-protocol"
                    >
                        {protocolGenerated ? t('regenerateProtocol') : t('generateProtocol')}
                    </ActionButton>

                    {protocolGenerated && (
                        <ActionButton
                            icon="fileExcelOutlined"
                            onClick={handleDeleteProtocol}
                            testId="delete-protocol"
                        >
                            {t('deleteProtocol')}
                        </ActionButton>
                    )}
                </Fragment>
            )}

            {showRequest && (
                <ActionButton
                    icon="fileOutlined"
                    onClick={goToGenerateRequest}
                >
                    {t('generateRequest')}
                </ActionButton>
            )}

            {showCloseForBillingBase && (
                <ActionButton
                    icon="checkOutlined"
                    onClick={handleCloseForBillingBase}
                    disabled={!closeForBillingEnabled || !laboratoryNumber}
                    disabledTooltip={closeForBillingEnabled ? t('disabledBilling') : undefined}
                >
                    {closeForBillingEnabled || !laboratoryNumberIsValid
                        ? t('closeOrdersForBilling') : t('orderClosedForBilling')}
                </ActionButton>
            )}

            {showAnimalDetail && (
                <ActionButton
                    icon="cowIcon"
                    linkTo={`/animals/${animalId}`}
                >
                    {t('animalDetail')}
                </ActionButton>
            )}

            {isRoleLaborer && (
                <ActionButton
                    icon="deleteOutlined"
                    onClick={onOrderDelete}
                    type="delete"
                    testId="delete-order"
                >
                    {t('deleteOrder')}
                </ActionButton>
            )}

            <ActionButton
                onClick={onGoBackClick}
                type="goBack"
                testId="back-button"
            >
                {t('back')}
            </ActionButton>
        </SideMenu>
    );
};

export const DetailActions = memo(DetailActionsBase);
