import {ActionSettings, Table, TableColumn} from '@fl/cmsch-fe-library';
import React, {Fragment, FC, memo, useCallback, useEffect, useMemo} from 'react';
import {Opt, find} from 'ts-opt';

import {EmailTemplateDetails} from 'api/gen/EmailTemplateDetails';
import {EmailTemplateUpdate} from 'api/gen/EmailTemplateUpdate';
import {DialsModals} from 'dials/types/dials-modals';
import {useOurTranslation} from 'translations';

import {MailTemplatesModal} from './mail-templates-modal';

interface Props {
    loading: boolean;
    modalsVisibility: Array<DialsModals>;
    mailTemplates: Opt<Array<EmailTemplateDetails>>;
    mailTemplateIdBeingEdited: Opt<number>;
    toggleModalVisibility(id: DialsModals): void;
    getMailTemplates(): void;
    startEditingMailTemplate(mailTemplate: EmailTemplateDetails): void;
    updateMailTemplate(id: number, mailTemplate: EmailTemplateUpdate): void;
    mailTemplateEditingCancelled(): void;
    resetMailTemplates(): void;
}

const MailTemplatesBase: FC<Props> = props => {
    const {
        loading,
        modalsVisibility,
        mailTemplates,
        mailTemplateIdBeingEdited,
        toggleModalVisibility,
        getMailTemplates,
        startEditingMailTemplate,
        updateMailTemplate,
        mailTemplateEditingCancelled,
        resetMailTemplates,
    } = props;

    useEffect(() => {
        getMailTemplates();
    }, [getMailTemplates]);

    const {t} = useOurTranslation('dials/mailTemplates');

    const toggleMailTemplateModalVisibility = useCallback(() =>
        toggleModalVisibility('mailTemplate')
    , [toggleModalVisibility]);

    const onEdit = useCallback((id: number) =>
        mailTemplates.chain(find(x => x.id === id))
            .onSome(mailTemplateToEdit => {
                startEditingMailTemplate(mailTemplateToEdit);
                toggleMailTemplateModalVisibility();
            })
    , [mailTemplates, startEditingMailTemplate, toggleMailTemplateModalVisibility]);

    const onEditCancel = useCallback(() => {
        mailTemplateEditingCancelled();
        toggleMailTemplateModalVisibility();
    }, [mailTemplateEditingCancelled, toggleMailTemplateModalVisibility]);

    const onUpdate = useCallback((data: EmailTemplateDetails) => {
        const {body, description, subject} = data;

        mailTemplateIdBeingEdited.onSome(x => updateMailTemplate(x, {body, description, subject}));
    }, [mailTemplateIdBeingEdited, updateMailTemplate]);

    const columns = useMemo((): Array<TableColumn<EmailTemplateDetails>> => [
        {
            field: 'code',
            type: 'string',
            column: t('code'),
            tooltip: t('code'),
            width: 100,
            disableFilter: true,
        },
        {
            field: 'subject',
            type: 'string',
            column: t('subject'),
            tooltip: t('subject'),
            width: 100,
            disableFilter: true,
        },
        {
            field: 'description',
            type: 'string',
            column: t('description'),
            tooltip: t('description'),
            width: 50,
            disableFilter: true,
        },
    ], [t]);

    const actionSettings = useMemo((): ActionSettings<EmailTemplateDetails> => ({
        onEdit,
    }), [onEdit]);

    return (
        <Fragment>
            <Table
                tableId="orderTypes"
                columns={columns}
                rows={mailTemplates.orElse([])}
                actionSettings={actionSettings}
                loading={loading}
                scroll={{x: true}}
                noEllipsis
                onUnmount={resetMailTemplates}
            />
            <MailTemplatesModal
                visible={modalsVisibility.includes('mailTemplate')}
                onSubmitEdit={onUpdate}
                onCancel={mailTemplateIdBeingEdited.isEmpty ? toggleMailTemplateModalVisibility : onEditCancel}
            />
        </Fragment>
    );
};

export const MailTemplates = memo(MailTemplatesBase);
