import {takeLatestF, putAll} from '@fl/cmsch-fe-library';
import {isEmpty} from 'lodash/fp';
import {SagaIterator} from 'redux-saga';
import {put, call} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {ImportedOrders} from 'api/gen/ImportedOrders';
import {showSuccessSimple} from 'notifications';
import {showBeError} from 'utils/show-be-error';

import {SendOrdersImportAction, importAction} from '../action';
import {translate} from './utils';

const composeInfoMessage = (data: ImportedOrders): string => {
    const skippedSampleIds = data.skippedSampleIds.join('\n');
    const importOrdersText = translate('importOrdersText', data);
    const importOrdersText2 = translate('importOrdersText2', {skippedSampleIds});

    return isEmpty(data.skippedSampleIds)
        ? importOrdersText
        : `${importOrdersText}\n${importOrdersText2}`;
};

function* execute({payload: {data, type}}: SendOrdersImportAction): SagaIterator {
    yield* put(importAction.setLoading(true));

    const response = yield* call(Api.importOrders, data, {type});

    yield* put(importAction.setLoading(false));

    if (response.isSuccess) {
        yield* put(showSuccessSimple({message: composeInfoMessage(response.data)}));
    } else {
        yield putAll(showBeError(response, translate('importOrders')));
    }
}

export function* sendOrdersImportSaga(): SagaIterator {
    yield takeLatestF('fileImport/SEND_ORDERS_IMPORT', execute);
}
