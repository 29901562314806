import {LinkButton} from '@fl/cmsch-fe-library';
import React, {FC, memo} from 'react';

import {Ant} from 'common/ant';
import {gutter} from 'constants/general';
import {TFunction} from 'translations';

import {StepIcon} from '../step-icon';

interface Props {
    t: TFunction<'fileImport/screenImport'>;
}

const SecondStepBase: FC<Props> = ({t}) => (
    <Ant.Row>
        <Ant.Col
            xs={24}
            md={3}
            lg={2}
        >
            <StepIcon stepNumber={2} />
        </Ant.Col>
        <Ant.Col
            xs={24}
            md={18}
            lg={20}
            className="align-self-center"
        >
            <Ant.Row gutter={[gutter, gutter]}>
                <Ant.Col
                    xs={24}
                    sm={8}
                    lg={6}
                >
                    <div className="d-flex h-100 align-items-center justify-content-center justify-content-sm-start">
                        <div>
                            {t('loadedItems')}
                        </div>
                    </div>
                </Ant.Col>
                <Ant.Col
                    xs={11}
                    sm={7}
                    lg={8}
                >
                    <LinkButton
                        to="/paternity-check"
                        block
                    >
                        {t('checkPaternity')}
                    </LinkButton>
                </Ant.Col>
                <Ant.Col
                    xs={3}
                    sm={2}
                >
                    <div className="d-flex h-100 align-items-center justify-content-center">
                        <div>
                            {t('or')}
                        </div>
                    </div>
                </Ant.Col>
                <Ant.Col
                    xs={10}
                    sm={7}
                    lg={8}
                >
                    <LinkButton
                        to="/parent-search"
                        block
                    >
                        {t('findParent')}
                    </LinkButton>
                </Ant.Col>
            </Ant.Row>
        </Ant.Col>
    </Ant.Row>
);

export const SecondStep = memo(SecondStepBase);
