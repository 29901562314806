import {Nullable} from '@fl/cmsch-fe-library';
import {isNil} from 'lodash/fp';
import React, {Fragment, FC, memo, useCallback, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {nonEmpty} from 'ts-opt';

import {cinRegexGen} from 'api/gen/Cin';
import {OrderCustomerDetails} from 'api/gen/OrderCustomerDetails';
import {OrderDetails} from 'api/gen/OrderDetails';
import {OrderUserDetails} from 'api/gen/OrderUserDetails';
import {dialsAction} from 'dials';
import {simpleDialsSelector} from 'dials/model/selector';
import {Panel} from 'layout';
import {editOrderCustomerFormValuesSelector, simpleOrdersSelector} from 'orders/model/selector';
import {useOurTranslation} from 'translations';
import {useUser} from 'user';
import {codeTableItemsToOptions} from 'utils/code-table-items-to-options';
import {formHelpers} from 'utils/forms';

import {orderAction} from '../../model';
import {OrderIdentifier} from '../../types/lasto-order-identifier-type';
import {EditUserDetailsForm} from '../EditUserDetailsForm';
import {EditUserDetailsFormValues} from '../EditUserDetailsForm/edit-user-details-form-values';
import {OrderDetailsView} from '../OrderDetailsView';

interface Props {
    order: OrderDetails;
    identifier: OrderIdentifier;
    hideDataDetails?: boolean;
}

// eslint-disable-next-line max-lines-per-function
const EditUserAndOrderViewBase: FC<Props> = props => {
    const {order, identifier, hideDataDetails} = props;

    const {isRoleLaborer} = useUser();
    const {t} = useOurTranslation('orders/screenDetail');
    const breedersOpt = useSelector(simpleOrdersSelector.breeders);
    const customersOpt = useSelector(simpleOrdersSelector.customers);
    const protocolData = useSelector(simpleOrdersSelector.protocolData);
    const sampleTypes = useSelector(simpleDialsSelector.sampleTypes);
    const orderTypes = useSelector(simpleDialsSelector.orderTypes);

    const editOrderCustomerFormValues = useSelector(editOrderCustomerFormValuesSelector);

    const dispatch = useDispatch();
    const setEditUserDetailsFormValue = useCallback((user: OrderCustomerDetails) => {
        dispatch(orderAction.setEditUserDetailsFormValue(user));
    }, [dispatch]);
    const onSubmitOfCustomer = useCallback((values: EditUserDetailsFormValues) => {
        dispatch(orderAction.updateCustomer(order.id, values));
    }, [dispatch, order.id]);
    const downloadBillingBases = useCallback((fileKey: string) => {
        dispatch(orderAction.downloadBillingBases(fileKey));
    }, [dispatch]);
    const deleteBillingBase = useCallback((orderId: number, orderTypeId: number) => {
        dispatch(orderAction.deleteBillingBase(orderId, orderTypeId));
    }, [dispatch]);
    const getUserLastOrder = useCallback((cin: Nullable<string>) => {
        if (cin && cinRegexGen().test(cin)) dispatch(orderAction.getUserLastOrder(cin, identifier));
    }, [dispatch, identifier]);
    const getOrdersCustomers = useCallback(() => {
        dispatch(orderAction.getOrdersCustomers());
    }, [dispatch]);
    const getBreeders = useCallback(() => {
        dispatch(orderAction.getBreeders());
    }, [dispatch]);
    const getSampleTypes = useCallback(() => {
        dispatch(dialsAction.getSampleTypes());
    }, [dispatch]);
    const getOrderTypes = useCallback(() => {
        dispatch(dialsAction.getOrderTypes());
    }, [dispatch]);
    const getDataForProtocol = useCallback(() => {
        dispatch(orderAction.getDataForProtocol(order.id));
    }, [dispatch, order.id]);
    const onCancel = useCallback(() => {
        dispatch(formHelpers.reset('editOrderCustomer'));
    }, [dispatch]);
    const initializeEditUserDetailsForm = useCallback((user: OrderUserDetails) => {
        const {
            address,
            billingAddress,
            customerName,
            vatNumber,
            email,
            firstname,
            cin,
            id,
            lastname,
            phone,
        } = user;

        dispatch(formHelpers.initialize('editOrderCustomer', {
            userDetails: {
                address,
                billingAddress,
                customerName,
                vatNumber,
                email,
                firstname,
                cin,
                id,
                lastname,
                phone,
                hasSameAddresses: true,
                prefillName: null,
                noCin: null,
            },
        }));
    }, [dispatch]);

    useEffect(() => {
        if (isRoleLaborer && customersOpt.isEmpty) getOrdersCustomers();
    }, [dispatch, customersOpt.isEmpty, getOrdersCustomers, isRoleLaborer]);

    useEffect(() => {
        if (isRoleLaborer && breedersOpt.isEmpty) getBreeders();
    }, [breedersOpt.isEmpty, getBreeders, isRoleLaborer]);

    useEffect(() => {
        if (isRoleLaborer && sampleTypes.isEmpty) getSampleTypes();
    }, [getSampleTypes, isRoleLaborer, sampleTypes.isEmpty]);

    useEffect(() => {
        if (isRoleLaborer && orderTypes.isEmpty) getOrderTypes();
    }, [getOrderTypes, isRoleLaborer, orderTypes.isEmpty]);

    useEffect(() => {
        if (isRoleLaborer) getDataForProtocol();
    }, [getDataForProtocol, isRoleLaborer]);

    useEffect(() => {
        initializeEditUserDetailsForm(order.customer);
    }, [dispatch, initializeEditUserDetailsForm, order]);

    const hasSameAddresses = editOrderCustomerFormValues.prop('userDetails').prop('hasSameAddresses').orNull();

    const editDataForDetailsLoaded = [sampleTypes, orderTypes, protocolData].every(nonEmpty);
    const showOrderDetails = editDataForDetailsLoaded || !isRoleLaborer;
    const {customer: {id}} = order;
    const isIdRequired = !isNil(id) && breedersOpt.orElse([]).map(x => x.id).includes(id);
    const convertedSampleTypes = useMemo(() => codeTableItemsToOptions(sampleTypes), [sampleTypes]);
    const convertedOrderTypes = useMemo(() => codeTableItemsToOptions(orderTypes), [orderTypes]);

    if (hideDataDetails) {
        return null;
    }

    return (
        <Fragment>
            {breedersOpt.zip(customersOpt).filter(_ => isRoleLaborer).map(([breeders, customers]) => (
                <Panel
                    title={t('customerDetails')}
                    extraBottomSpace
                    key="editUserFormPanel"
                    testId="edit-user-form-wrapper"
                >
                    <EditUserDetailsForm
                        customers={customers}
                        hasSameAddresses={hasSameAddresses}
                        showCreatedBy={isRoleLaborer}
                        breeders={breeders}
                        isIdRequired={isIdRequired}
                        isPhoneRequired={false}
                        onPrefillFromExisting={setEditUserDetailsFormValue}
                        onSubmit={onSubmitOfCustomer}
                        onCancel={onCancel}
                        onGetUserLastOrder={getUserLastOrder}
                    />
                </Panel>
            )).orNull()}
            <Panel
                title={t('orderDetails')}
                testId="order-details-form-wrapper"
            >
                {showOrderDetails && (
                    <OrderDetailsView
                        order={order}
                        protocolData={protocolData.orNull()}
                        sampleTypes={convertedSampleTypes}
                        orderTypes={convertedOrderTypes}
                        isRoleLaborer={isRoleLaborer}
                        downloadBillingBases={downloadBillingBases}
                        deleteBillingBase={deleteBillingBase}
                    />
                )}
            </Panel>
        </Fragment>
    );
};

export const EditUserAndOrderView = memo(EditUserAndOrderViewBase);
