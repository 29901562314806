// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// The price of the order type.
// Example: 200

export const orderTypePriceMinimum = 0.0; // inclusive
export const orderTypePriceMaximum = 32767.0; // inclusive

export const OrderTypePriceSchema = t.refinement(t.Integer, x => x >= orderTypePriceMinimum && x <= orderTypePriceMaximum, 'OrderTypePrice');

export type OrderTypePrice = t.TypeOf<typeof OrderTypePriceSchema>;
