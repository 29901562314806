import {setItem} from '@fl/cmsch-fe-library';
import * as O from 'optics-ts';
import {pipe} from 'ts-opt';

import {Action} from 'app/actions';
import {AppState, initialAppState} from 'app/model/state';

const appStateO = O.optic<AppState>();

export const appReducer = (state: AppState = initialAppState, action: Action): AppState => {
    switch (action.type) {
        case 'app/ADD_LOADING_CONTENT_SUCCESS':
            return O.modify(appStateO.prop('loadingContent'))(setItem(true)(action.payload))(state);

        case 'app/REMOVE_LOADING_CONTENT':
            return pipe(
                state,
                O.modify(appStateO.prop('loadingContent'))(setItem(false)(action.payload)),
                O.modify(appStateO.prop('localLoadingContent'))(setItem(false)(action.payload)),
            );

        case 'app/CLEAR_LOADING_CONTENT':
            return pipe(
                state,
                O.set(appStateO.prop('loadingContent'))([]),
                O.set(appStateO.prop('localLoadingContent'))([]),
            );

        case 'app/CHANGE_LOADERS_URL':
            return O.set(appStateO.prop('urlForLoader'))(action.payload)(state);

        case 'app/ADD_LOADING_CONTENT':
            return O.modify(appStateO.prop('localLoadingContent'))(setItem(true)(action.payload))(state);

        default:
            return state;
    }
};
