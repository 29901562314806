import {TableDataRequest} from '@fl/cmsch-fe-library';
import React, {FC} from 'react';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import * as routerActions from 'redux-first-history';
import {Opt} from 'ts-opt';

import {State} from 'app/state';
import {Container, MainPanel, PageHeading} from 'layout';
import {useOurTranslation} from 'translations';
import {useUser} from 'user';

import {AnimalsTable} from '../components/AnimalsTable';
import {AnimalsTableRow} from '../components/AnimalsTable/table-row';
import {animalsAction} from '../model';

interface StateProps {
    selectedAnimalIds: Array<number>;
}

interface DispatchProps {
    exportAnimal(animalId: number): void;
    exportMicrosatellites(animalIds: Array<number>): void;
    getAnimals(tableDataRequest: Opt<TableDataRequest<AnimalsTableRow>>): void;
    setSelectedAnimals(animalIds: Array<number>): void;
    goTo(path: string): void;
}

type Props = StateProps & DispatchProps;

const ListBase: FC<Props> = props => {
    const {
        selectedAnimalIds,
        getAnimals,
        setSelectedAnimals,
        goTo,
        exportMicrosatellites,
        exportAnimal,
    } = props;

    const {isRoleLaborer, isRoleBreedingUnion} = useUser();
    const {t} = useOurTranslation('animals/screenList');

    return (
        <Container maxXxlWidth>
            <MainPanel>
                <div className="row">
                    <div className="col-12">
                        <PageHeading>
                            <h1>{t('title')}</h1>
                        </PageHeading>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <AnimalsTable
                            selectedAnimalIds={selectedAnimalIds}
                            isRoleBreedingUnion={isRoleBreedingUnion}
                            isRoleLaborer={isRoleLaborer}
                            getAnimals={getAnimals}
                            setSelectedAnimals={setSelectedAnimals}
                            goTo={goTo}
                            exportAnimal={exportAnimal}
                            exportMicrosatellites={exportMicrosatellites}
                        />
                    </div>
                </div>
            </MainPanel>
        </Container>
    );
};

const mapStateToProps = (state: State): StateProps => ({
    selectedAnimalIds: state.animals.selectedAnimalIds,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    exportAnimal: (animalId: number): void => void dispatch(animalsAction.exportAnimal(animalId)),
    goTo: (path: string): void => void dispatch(routerActions.push(path)),
    setSelectedAnimals: (animalIds: Array<number>): void =>
        void dispatch(animalsAction.setSelectedAnimals(animalIds)),
    getAnimals: (tableDataRequest: Opt<TableDataRequest<AnimalsTableRow>>): void =>
        void dispatch(animalsAction.getAnimals(tableDataRequest)),
    exportMicrosatellites: (animalIds: Array<number>): void =>
        void dispatch(animalsAction.exportMicrosatellites(animalIds)),
});

export const List = connect(mapStateToProps, mapDispatchToProps)(ListBase);
