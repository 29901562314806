import {createCategorySaga} from './create-category-saga';
import {createSnpSaga} from './create-snp-saga';
import {loadCategoriesSaga} from './load-categories-saga';
import {loadSaga} from './load-saga';
import {regenerateSaga} from './regenerate-saga';
import {validateNameSaga} from './validate-snp-name-saga';

export const snpManagementSagas = {
    loadSaga,
    loadCategoriesSaga,
    regenerateSaga,
    createSnpSaga,
    createCategorySaga,
    validateNameSaga,
};
