import {downloadFileFromResponse, putAll, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'translations';
import {showBeError} from 'utils/show-be-error';

import {ExportGenotypeExportAction} from '../action';

function* execute({payload: {id}}: ExportGenotypeExportAction): SagaIterator {
    const response = yield* call(Api.downloadGenotypeExport, {genotypeExportId: id});

    if (response.isSuccess) {
        yield* call(downloadFileFromResponse, response);
    } else {
        yield putAll(showBeError(response, t('genotypeExports/screenList')('title')));
    }
}

export function* exportGenotypeExportSaga(): SagaIterator {
    yield takeLatestF('genotypesExports/EXPORT_GENOTYPE_EXPORT', execute);
}
