import {classNames, Notifications, StyleVariables, ComponentsContextProvider} from '@fl/cmsch-fe-library';
import React, {Fragment, FC} from 'react';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {Provider, useSelector} from 'react-redux';
import {Router} from 'react-router-dom';

import {appAction} from 'app/model/action';
import {loadingSelector} from 'app/model/selector';
import {logger} from 'app/sentry-logger';
import {Footer, Header, MenuAppWrapper} from 'layout';
import {t} from 'translations';
import {pathnamesSelector, simpleUserSelector} from 'user';
import {UserPermissionGuard} from 'user/components/user-permission-guard';
import {languageStore} from 'utils/language-store';
import {getTableConfig} from 'utils/table-config';
import {simpleTableSelector} from 'utils/tables';

import {store, history} from '../../store';
import {BreederInfoSidebox} from '../breeder-info-sidebox';
import {ErrorBoundary} from './error-boundary';
import {ScrollToTop} from './scroll-to-top';
import {SwitchTransitionWrapper} from './transition-wrapper';

import 'styles/styles.sass';
import 'styles/ant-design.less';
import styles from './styles.sass';
import variables from './variables.sass';

const styleVariables: StyleVariables = {
    primary: variables.primary,
    secondary: variables.secondary,
    tableSorterHoverBg: '#F1F1F1',
    tableFilterHoverBg: '#F1F1F1',
    tableHeaderFixedBg: '#FDFDFD',
    tableHeaderNameColor: 'rgba(0, 0, 0, 0.85)',
    tableHeaderSorterArrowColor: '#77AB11',
    dropdownButtonHoverColor: 'rgba(0, 0, 0, 0.8)',
    dropdownButtonFilteredColor: '#77AB11',
    tableHeaderBg: '#FAFAFA',
    tableHypertextColor: '#719041',
    tableHypertextHoverColor: '#000',
};

const language = languageStore.get();
const classes = classNames('w-100', 'position-relative', styles.appWrapper);

const AppWithoutStore: FC = () => {
    const isLoading = useSelector(loadingSelector);
    const currentUser = useSelector(simpleUserSelector.currentUser);
    const tablesState = useSelector(simpleTableSelector.tables);
    const pathNames = useSelector(pathnamesSelector);

    return (
        <Router history={history}>
            <ComponentsContextProvider
                language={language || 'CS'}
                t={t}
                styleVariables={styleVariables}
                logger={logger}
                pathNames={pathNames}
                monospaceFont={false}
                tablesState={tablesState}
                addLoadingContent={appAction.addLoadingContent}
                removeLoadingContent={appAction.removeLoadingContent}
                getTableConfig={getTableConfig}
                settingsToLocalStorage
                userId={currentUser.prop('id').orUndef()}
            >
                <DndProvider backend={HTML5Backend}>
                    <div className={classes}>
                        <Notifications spaceOnTop={110} />
                        <ScrollToTop />

                        <Header />
                        <MenuAppWrapper />

                        <br />
                        <UserPermissionGuard>
                            <SwitchTransitionWrapper />
                        </UserPermissionGuard>
                        {!isLoading && (
                            <Fragment>
                                <Footer />
                                <BreederInfoSidebox />
                            </Fragment>
                        )}
                    </div>
                </DndProvider>
            </ComponentsContextProvider>
        </Router>
    );
};

const StoreWrappedApp: FC = () => (
    <ErrorBoundary>
        <Provider store={store}>
            <AppWithoutStore />
        </Provider>
    </ErrorBoundary>
);

export const App = StoreWrappedApp;
