import {TablesState as GenericTablesState, setupTables} from '@fl/cmsch-fe-library';

import {TableFilter} from 'api/gen/TableFilter';
import {AppAction} from 'app/model/action';
import {State} from 'app/state';
import {TableRowMap} from 'types/table-row-map';

export const {
    ConnectedTable,
    getRequestedTablePageParams,
    initialTablesState,
    tablesSagas,
    tablesReducer,
    tablesAction,
    simpleTableSelector,
    convertTableDataParamsToDto,
} = setupTables<TableRowMap, State, AppAction, TableFilter>();

export type TablesState = GenericTablesState<TableRowMap>;
