export const fileImportTranslations = {
    CS: {
        'fileImport/screenImport': {
            title: 'Import čipů',
            titleHungarian: 'Import maďarských objednávek',
            titleCzech: 'Import českých objednávek',
            titlePlemdat: 'Import Plemdat objednávek',
            titleOrdersUpdates: 'Import aktualizace objednávek',
            tabName: 'Import',
            chipImportText: 'Nejprve prosím nahrajte potřebné vzorky, poté pokračujte dále výběrem jedné ze dvou možností.',
            importText: 'Nahrajte prosím potřebné objednávky.',
            loadFile: 'Nahrajte soubor přetáhnutím nebo vybráním.',
            fileFormats: 'Podporované formáty: csv',
            maxSize: 'Maximální velikost souboru: 300MB',
            loadedItems: 'S nahranými vzorky',
            checkPaternity: 'ověřit paternitu',
            findParent: 'najít rodiče',
            or: 'nebo',
            importFile: 'Soubor pro import: ',
            doImport: 'Importovat',
            rejectedTitle: 'Soubor byl zamítnut',
            rejectedMessage: 'Soubor neodpovídá parametrům, zkontrolujte typ a velikost souboru.',
            fileReadingErrorTitle: 'Chyba při čtení souboru',
            fileReadingErrorMessage: 'Při čtení souboru nastala neznámá chyba.',
            file: 'Soubor',
        },
        'fileImport/saga': {
            importText1: '{{fileName}} byl úspěšně importován. Importováno {{numOfImportedEarTags}} vzorků.',
            importText2: '{{numOfSkippedLines}} snp záznamů nebylo definovaných.',
            importOrdersText: 'Zpracováno {{numOfProcessedLines}} řádků, importováno {{numOfImportedOrders}} objednávek.',
            importOrdersText2: 'Následující zvířata nebyla importována: \n{{skippedSampleIds}}',
            importOrdersUpdatesSuccess: 'Objednávky byly úspěšně přidány.',
            importOrders: 'Import objednávek',
        },
    },
    EN: {
        'fileImport/screenImport': {
            title: 'Chip import',
            titleHungarian: 'Import of Hungarian orders',
            titleCzech: 'Import of Czech orders',
            titlePlemdat: 'Import of Plemdat orders',
            titleOrdersUpdates: 'Import order updates',
            tabName: 'Import',
            chipImportText: 'Please upload the required samples first, then proceed by selecting one of the two options.',
            importText: 'Please upload the required orders.',
            loadFile: 'Drag and drop or select to upload the file.',
            fileFormats: 'Supported formats: csv',
            maxSize: 'Maximum file size: 300MB',
            loadedItems: 'With recorded samples',
            checkPaternity: 'verify paternity',
            findParent: 'find parents',
            or: 'or',
            importFile: 'File for import: ',
            doImport: 'Import',
            rejectedTitle: 'The file was rejected',
            rejectedMessage: 'The file does not match the parameters, check the file type and size.',
            fileReadingErrorTitle: 'Error reading file',
            fileReadingErrorMessage: 'An unknown error occurred while reading the file.',
            file: 'File',
        },
        'fileImport/saga': {
            importText1: '{{fileName}} was successfully imported. Imported {{numOfImportedEarTags}} samples.',
            importText2: '{{numOfSkippedLines}} snp records were not defined.',
            importOrdersText: 'Processed {{numOfProcessedLines}} rows, imported {{numOfImportedOrders}} orders.',
            importOrdersText2: 'The following animals were not imported: \n{{skippedSampleIds}}',
            importOrdersUpdatesSuccess: 'Orders added successfully.',
            importOrders: 'Import orders',
        },
    },
};
