import {putAll, takeLatestF} from '@fl/cmsch-fe-library';
import * as routerActions from 'redux-first-history';
import {SagaIterator} from 'redux-saga';
import {put, call} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'translations';
import {showBeError} from 'utils/show-be-error';

import {orderAction, SearchOrdersAction} from '../action';

function* execute({payload: {barcode, earTag}}: SearchOrdersAction): SagaIterator {
    const response = yield* call(Api.searchOrder, {barcode: barcode || null, earTag: earTag || null});

    if (response.isSuccess) {
        const {data} = response;
        if (data.length === 1) {
            yield* put(routerActions.push({
                pathname: `/orders/${data[0].id}`,
                hash: 'receive',
            }));
        }
        yield* put(orderAction.searchOrdersSuccess(data));
    } else {
        yield putAll(showBeError(response, t('orders/FindOrderForm')('findOrder')));
        yield* put(orderAction.searchOrdersSuccess([]));
    }
}

export function* searchOrdersSaga(): SagaIterator {
    yield takeLatestF('order/SEARCH_ORDERS', execute);
}
