import {ActionSettings, mergeObjects, Options, TableDataRequest} from '@fl/cmsch-fe-library';
import React, {FC, memo, useCallback, useMemo} from 'react';
import {Opt} from 'ts-opt';

import {Order} from 'api/gen/Order';
import {simpleOrdersSelector} from 'orders/model/selector';
import {isFullyBilledOrder} from 'orders/utils/is-fully-billed';
import {isReadyForBillingOrder} from 'orders/utils/is-ready-for-billing';
import {useOurTranslation} from 'translations';
import {useUser} from 'user';
import {ConnectedTable} from 'utils/tables';

import {createColumns} from './columns';
import {OrderState} from './order-state';
import {OrderTableType} from './order-table-type';

interface OwnProps {
    orderTypes: Options<number>;
    sampleTypes: Options<number>;
    selected: Array<number>;
    getOrders(tableDataRequest: Opt<TableDataRequest<Order>>): void;
    toggleSelect(ids: Array<number>): void;
    exportOrderProtocol(id: number): void;
}

type Props = OwnProps;

const pathnamesForRefresh = [/\/orders\/\d.+/];

const isProtocolExportDisabled = (_id: number, row: Order): boolean => !row.protocolGeneratedDate;
const isCatalogueExportDisabled = (_id: number, row: Order): boolean => !row.catalogListUrl;
const goToDetail = (_id: number, row: Order): string => `/orders/${row.id}`;
const getAnimalUrl = (_id: number, row: OrderTableType): string => `/animals/${row.animalId}`;
const getCatalogueUrl = (_id: number, row: Order): string =>
    row.catalogListUrl?.replace('http://', 'https://') || '';

// eslint-disable-next-line max-lines-per-function
const OrdersTableBase: FC<Props> = props => {
    const {
        orderTypes,
        sampleTypes,
        selected,
        getOrders,
        toggleSelect,
        exportOrderProtocol,
    } = props;

    const {t: tableT, tCommon} = useOurTranslation('orders/OrdersTable');
    const {t: rowActionsT} = useOurTranslation('orders/TableRowActions');
    const {
        isRoleAssociation,
        isRoleBreeder,
        isRoleBreedingUnion,
        isRoleLaborer,
        isRoleLaborerOrBreeder,
        isHolsteinAssociation,
        isMeatAssociation,
    } = useUser();

    const areGenotypeExportColumnsVisible = isRoleLaborer || isHolsteinAssociation || isMeatAssociation;

    const rowMapper = useCallback((rowData: Order, _index: number): OrderTableType => mergeObjects(rowData, {
        data: <OrderState
            exportedByAssociation={rowData.exportedByAssociation}
            readyForBilling={isReadyForBillingOrder(rowData) && !isFullyBilledOrder(rowData)}
            fullyBilled={isFullyBilledOrder(rowData)}
            isRoleBreeder={isRoleBreeder}
            isRoleLaborer={isRoleLaborer}
            customerNote={rowData.customerNote}
            paternity={rowData.paternity}
            maternity={rowData.maternity}
            showChipCircle={isRoleLaborer || isRoleAssociation}
            laboratoryNote={rowData.laboratoryNote}
        />,
        orderTypeIds: rowData.orderTypes.map(orderType => orderType.id),
    }), [isRoleAssociation, isRoleBreeder, isRoleLaborer]);

    const exportProtocolDisabledTitle = useCallback(() => rowActionsT('protocolNotAvailable'), [rowActionsT]);
    const exportCatalogueDisabledTitle = useCallback(() => rowActionsT('catalogueListNotAvailable'), [rowActionsT]);
    const getCatalogueFileName = useMemo(() => (_id: number, row: OrderTableType): string =>
        rowActionsT('catalogueListFileName', {id: row.id})
    , [rowActionsT]);
    const isSampleIdLink = isRoleLaborer || isRoleBreedingUnion;

    const actionSettings = useMemo(
        (): ActionSettings<OrderTableType> => ({
            confirmRowRemove: true,
            selectedIds:
                isRoleLaborerOrBreeder
                || isRoleBreedingUnion
                || isRoleAssociation
                    ? selected
                    : undefined,
            onSelectedIdsChange:
                isRoleLaborerOrBreeder
                || isRoleBreedingUnion
                || isRoleAssociation
                    ? toggleSelect
                    : undefined,
            extraActions: [
                {
                    id: 'detail',
                    title: rowActionsT('detail'),
                    icon: 'infoCircleOutlined',
                    href: goToDetail,
                    hidden: !isRoleLaborerOrBreeder && !isRoleBreedingUnion,
                    testId: 'order-detail',
                },
                {
                    id: 'export',
                    title: rowActionsT('protocol'),
                    icon: 'branchesOutlined',
                    callback: exportOrderProtocol,
                    disabled: isProtocolExportDisabled,
                    disabledTitle: exportProtocolDisabledTitle,
                    testId: 'export',
                },
                {
                    id: 'catalogue',
                    title: rowActionsT('catalogueList'),
                    icon: 'fileTextOutlined',
                    href: getCatalogueUrl,
                    isDownloadLink: true,
                    fileName: getCatalogueFileName,
                    hidden: isRoleBreedingUnion,
                    disabled: isCatalogueExportDisabled,
                    disabledTitle: exportCatalogueDisabledTitle,
                    testId: 'catalogue',
                },
            ],
        }),
        [
            isRoleLaborerOrBreeder,
            isRoleBreedingUnion,
            isRoleAssociation,
            selected,
            toggleSelect,
            rowActionsT,
            exportOrderProtocol,
            exportProtocolDisabledTitle,
            getCatalogueFileName,
            exportCatalogueDisabledTitle,
        ],
    );

    return (
        <ConnectedTable
            tableId="orders"
            columns={createColumns({
                orderTypes,
                sampleTypes,
                isSampleIdLink,
                isRoleBreeder,
                isRoleLaborer,
                isRoleAssociation,
                areGenotypeExportColumnsVisible,
                t: tableT,
                tCommon,
                getAnimalUrl,
            })}
            rowDataToTableRowMapper={rowMapper}
            rowDataPageSelector={simpleOrdersSelector.ordersPage}
            onFetchTableData={getOrders}
            actionSettings={actionSettings}
            rowActionsOnRight
            paginationPosition="both"
            showFulltextFilter
            withoutVerticalScrollbar
            enableTableUserSettings
            pathnamesForRefresh={pathnamesForRefresh}
        />
    );
};

export const OrdersTable = memo(OrdersTableBase);
