import {ErrorResponse, ErrorsFromBE, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {put, call} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {formHelpers} from 'utils/forms';
import {Errors} from 'utils/validator';

import {TestResultFormValues} from '../../components/TestResultForm/test-result-form-values';
import {ValidateTestResultTypeAction} from '../action';

const buildFormErrors = (response: ErrorResponse): Errors<TestResultFormValues> => {
    const errors = response.data as ErrorsFromBE;

    return {value: errors[0].message};
};

function* execute(action: ValidateTestResultTypeAction): SagaIterator {
    const {data} = action.payload;

    const response = yield* call(Api.validateTestResultType, data);

    if (response.isSuccess) {
        yield* put(formHelpers.stopAsyncValidation('testResult'));
    } else {
        yield* put(formHelpers.stopAsyncValidation('testResult', buildFormErrors(response)));
    }
}

export function* validateTestResultTypeSaga(): SagaIterator {
    yield takeLatestF('dials/VALIDATE_TEST_RESULT_TYPE', execute);
}
