import React from 'react';
import {Route} from 'react-router-dom';

import {List, OriginInconsistencies, Detail, Edit, Comparison} from './screens';

export const animalsRoutesWithoutFetching: Array<RegExp> = [];

export const animalsRoutes = [
    (
        <Route
            path="/animals"
            component={List}
            exact
            key="animals-list"
        />
    ),
    (
        <Route
            path="/animals-list"
            component={List}
            exact
            key="animals-list"
        />
    ),
    (
        <Route
            path="/animals/origin-inconsistencies"
            component={OriginInconsistencies}
            key="origin-inconsisten"
        />
    ),
    (
        <Route
            path="/origin-inconsistencies"
            component={OriginInconsistencies}
            key="origin-inconsisten"
        />
    ),
    (
        <Route
            path="/animals/:id"
            component={Detail}
            exact
            key="animals-detail"
        />
    ),
    (
        <Route
            path="/animals/:id/edit"
            component={Edit}
            key="animals-edit"
        />
    ),
    (
        <Route
            path="/animal-comparison/:ids"
            component={Comparison}
            key="animals-comparsion"
        />
    ),
];
