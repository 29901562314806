import {classNames} from '@fl/cmsch-fe-library';
import React, {FC, memo} from 'react';

import {SvgImage} from 'images';
import {useOurTranslation} from 'translations';
import {useUser} from 'user';

import styles from './styles.sass';

import cowBodyPng from './cowBody.png';
import cowHeadPng from './cowHead.png';
import hungaryLogoPng from './hungaryLogo.png';
import logoLargePng from './logoLarge.png';
import logoSmallSvg from './logoSmall.svg';

const HeaderBase: FC = _props => {
    const {isHungarian} = useUser();

    const {t} = useOurTranslation('common');
    const headerWrapperClassNames = classNames(
        styles.headerContentWrapper,
        'w-100 d-flex justify-content-center',
    );
    const headerContentClassNames = classNames(
        'd-flex',
        'px-md-2',
        'px-0',
        'justify-content-center',
        'justify-content-lg-between',
        'flex-nowrap',
        'h-100',
    );
    const logoClassNames = classNames(
        'col-3',
        'col-sm-3',
        'col-md-2',
        'align-items-center',
        'd-flex',
        'px-md-1',
        'p-0',
        'justify-content-end',
        'd-md-flex',
    );
    const titleClassNames = classNames(
        'col-8',
        'col-md-7',
        styles.headerTitle,
        'text-center',
        'align-self-center',
        'px-md-1',
        'p-0',
        'd-flex',
        'justify-content-start',
        'pl-1',
        'pl-sm-2',
    );

    return (
        <header className={styles.header}>
            <div
                className={headerWrapperClassNames}
            >
                <div className={classNames('w-100 h-100', styles.headerContent)}>
                    <div className="position-relative h-100">
                        <div
                            className={headerContentClassNames}
                        >
                            <div
                                className={logoClassNames}
                            >
                                <img
                                    src={logoLargePng}
                                    alt={t('title')}
                                    className={classNames('hidden-sm-down', styles.logoLarge)}
                                />

                                <span className={styles.logoSmall}>
                                    <SvgImage
                                        image={logoSmallSvg}
                                    />
                                </span>
                            </div>
                            <div
                                className={titleClassNames}
                            >
                                <div>{t('title')}</div>
                            </div>
                            <div className="col-0 col-md-2 d-none d-lg-flex px-0 px-sm-3">
                                {isHungarian
                                    ? (
                                        <div className="d-md-flex align-items-center d-none">
                                            <img
                                                src={hungaryLogoPng}
                                                alt=""
                                                className={styles.hungaryLogo}
                                            />
                                        </div>
                                    )
                                    : (
                                        <div className={styles.cow}>
                                            <div className="position-relative">
                                                <div className={styles.cowHead}>
                                                    <img
                                                        src={cowHeadPng}
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                            <div className={classNames('align-self-end d-flex', styles.cowBody)}>
                                                <img
                                                    src={cowBodyPng}
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export const Header = memo(HeaderBase);
