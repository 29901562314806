// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { AnimalFullName, AnimalFullNameSchema } from 'api/gen/AnimalFullName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { AnimalName, AnimalNameSchema } from 'api/gen/AnimalName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { BreedAlpha6, BreedAlpha6Schema } from 'api/gen/BreedAlpha6';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Date, DateSchema } from 'api/gen/Date';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { EarTag, EarTagSchema } from 'api/gen/EarTag';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { GlobalEarTag, GlobalEarTagSchema } from 'api/gen/GlobalEarTag';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { LineRegistry, LineRegistrySchema } from 'api/gen/LineRegistry';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { SexType, SexTypeSchema } from 'api/gen/SexType';

const requiredPart = t.interface({
  breed: BreedAlpha6Schema,
  dob: t.union([DateSchema, t.null]),
  earTag: EarTagSchema,
  fatherEarTag: t.union([GlobalEarTagSchema, t.null]),
  fatherLinReg: t.union([LineRegistrySchema, t.null]),
  fullName: t.union([AnimalFullNameSchema, t.null]),
  lineRegistry: t.union([LineRegistrySchema, t.null]),
  motherEarTag: t.union([GlobalEarTagSchema, t.null]),
  name: t.union([AnimalNameSchema, t.null]),
  sex: SexTypeSchema,
  twin: t.boolean,
});

export const NewOrderAnimalSchema = excess(requiredPart);

export interface NewOrderAnimal extends t.TypeOf<typeof NewOrderAnimalSchema> {}
