import {Requester, ResponseError} from '@fl/cmsch-fe-library';

import {languageStore} from 'utils/language-store';

import {Config} from './config';
import {logger} from './sentry-logger';

const defaultLanguage = 'CS';

const redirectToPlemdatAuth = (): void => {
    window.location.href = `${Config.apiUrl}/../oauth2/authorization/plemdat`;
};

const onServerError = (error: ResponseError): void => {
    logger.logErrorAndConsole(error);
};

const getAcceptLanguage = (): string =>
    (languageStore.get() || defaultLanguage) === 'CS' ? 'cs-CZ' : 'en';

export const apiRequester = new Requester({
    baseUrl: Config.apiUrl,
    devMode: Config.isDevelopment,
    withCredentials: true,
    onUnauthorized: redirectToPlemdatAuth,
    onServerError,
    getAcceptLanguage,
});
