import {extractFormErrorsFromResponse, putAll, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {put, call} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'translations';
import {formHelpers} from 'utils/forms';
import {showBeError} from 'utils/show-be-error';

import {editOrderTypeFormName} from '../../components/OrderTypeForm/order-type-form-values';
import {dialsAction, UpdateOrderTypeAction} from '../action';

function* execute({payload: {id, orderType}}: UpdateOrderTypeAction): SagaIterator {
    yield* put(formHelpers.startSubmit(editOrderTypeFormName));

    const {extraCharge, price, value} = orderType;

    const response = yield* call(Api.updateOrderType, {extraCharge, price, value}, {orderTypeId: id});

    if (response.isSuccess) {
        yield* put(formHelpers.stopSubmit(editOrderTypeFormName));
        yield* put(dialsAction.getOrderTypes());
        yield* put(dialsAction.updateOrderTypeSuccess());
        yield* put(dialsAction.setModalVisibility('orderType', false));
    } else {
        yield* put(formHelpers.stopSubmit(editOrderTypeFormName, extractFormErrorsFromResponse(response)));
        yield putAll(showBeError(response, t('dials/orderTypes')('editTitle')));
    }
}

export function* updateOrderTypeSaga(): SagaIterator {
    yield takeLatestF('dials/UPDATE_ORDER_TYPE', execute);
}
