// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.75.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.75.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-paternityCheck-components-PaternityCheckResult-___styles__resultsheading___OfJuQ{width:100%;height:auto;background-color:#b0d881;box-shadow:0 0 0 1px #78a239e6;top:-1px}.src-modules-paternityCheck-components-PaternityCheckResult-___styles__resultswrapper___QyDvQ{height:auto;background-color:#fff;box-shadow:0 0 0 1px #78a239e6}.src-modules-paternityCheck-components-PaternityCheckResult-___styles__roundcornersbottom___KlEBS{border-radius:0 0 .25em .25em}.src-modules-paternityCheck-components-PaternityCheckResult-___styles__roundcornerstop___pugw3{border-radius:.25em .25em 0 0}@media (max-width: 575px){.src-modules-paternityCheck-components-PaternityCheckResult-___styles__rodictopcorners___a2GWF{border-radius:0}}@media (min-width: 768px){.src-modules-paternityCheck-components-PaternityCheckResult-___styles__rodictopcorners___a2GWF{border-radius:.25em .25em 0 0}}.src-modules-paternityCheck-components-PaternityCheckResult-___styles__font120___AgQR2{font-size:120%}\n", "",{"version":3,"sources":["webpack://./src/modules/paternityCheck/components/PaternityCheckResult/styles.sass"],"names":[],"mappings":"AAAA,8FAAgB,UAAA,CAAW,WAAA,CAAY,wBAAA,CAAyB,8BAAA,CAA+C,QAAA,CAAS,8FAAgB,WAAA,CAAY,qBAAA,CAAsB,8BAAA,CAA+C,kGAAzN,6BAAA,CAA2Q,+FAA3Q,6BAAA,CAA0T,0BAAyB,+FAAnV,eAAA,CAAA,CAA2X,0BAAyB,+FAApZ,6BAAA,CAAA,CAAoc,uFAAS,cAAA","sourcesContent":[".resultsheading{width:100%;height:auto;background-color:#b0d881;box-shadow:0px 0px 0px 1px rgba(120,162,57,.9);top:-1px}.resultswrapper{height:auto;background-color:#fff;box-shadow:0px 0px 0px 1px rgba(120,162,57,.9)}.roundcornersbottom{border-radius:0 0 .25em .25em}.roundcornerstop{border-radius:.25em .25em 0 0}@media(max-width: 575px){.rodictopcorners{border-radius:0 0 0 0}}@media(min-width: 768px){.rodictopcorners{border-radius:.25em .25em 0 0}}.font120{font-size:120%}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"resultsheading": "src-modules-paternityCheck-components-PaternityCheckResult-___styles__resultsheading___OfJuQ",
	"resultswrapper": "src-modules-paternityCheck-components-PaternityCheckResult-___styles__resultswrapper___QyDvQ",
	"roundcornersbottom": "src-modules-paternityCheck-components-PaternityCheckResult-___styles__roundcornersbottom___KlEBS",
	"roundcornerstop": "src-modules-paternityCheck-components-PaternityCheckResult-___styles__roundcornerstop___pugw3",
	"rodictopcorners": "src-modules-paternityCheck-components-PaternityCheckResult-___styles__rodictopcorners___a2GWF",
	"font120": "src-modules-paternityCheck-components-PaternityCheckResult-___styles__font120___AgQR2"
};
export default ___CSS_LOADER_EXPORT___;
