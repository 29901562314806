export const snpManagementTranslations = {
    CS: {
        'snpManagement/SnpTable': {
            columnHeaders: {
                name: 'Jméno',
                category: 'Kategorie',
            },
            tableHeaders: {
                regenerate: 'Přegenerovat',
                addSnp: 'Přidat SNP záznam',
                addCategory: 'Přidat kategorii',
                title: 'Správa SNP',
                removeSelected: 'Zrušit výběr',
                regenerateTitle: 'Opravdu chcete spustit přegenerování {{ numberOfSelected }} SNP?' +
                    '\nTato akce může trvat i desítky hodin.',
                regenerateTitleNoSelected: 'Opravdu chcete spustit přegenerování SNP?' +
                '\nTato akce může trvat i desítky hodin.',
            },
            formTranslations: {
                name: 'Název kategorie',
                createSNPRecord: 'Nový SNP záznam',
                snpName: 'Název SNP',
            },
            requestMessages: {
                dataRegenerationStarted: 'Přegenerování dat spuštěno, operace může trvat mnoho hodin',
                deletedMessage: 'SNP bylo úspěšně odstraněno. ' +
                    'Data jsou nyní v nekonzistentním stavu a bude nutné záznamy přegenerovat.',
                createdMessage: 'SNP bylo úspěšně přidáno.' +
                    ' Data jsou nyní v nekonzistentním stavu a bude nutné záznamy přegenerovat.',
                categoryCreated: 'Kategorie přidaná',
            },
        },
    },
    EN: {
        'snpManagement/SnpTable': {
            columnHeaders: {
                name: 'Name',
                category: 'Category',
            },
            tableHeaders: {
                regenerate: 'Regenerate',
                addSnp: 'Add SNP record',
                addCategory: 'Add category',
                title: 'SNP administration',
                removeSelected: 'Deselect all',
                regenerateTitle: 'Are you sure you want to perform regeneration of {{ numberOfSelected }} SNP?' +
                    '\nThis action can take tens of hours.',
                regenerateTitleNoSelected: 'Are you sure you want to perform regeneration of SNP?' +
                '\nThis action can take tens of hours.',
            },
            formTranslations: {
                name: 'Category name',
                createSNPRecord: 'New SNP record',
                snpName: 'SNP name',
            },
            requestMessages: {
                dataRegenerationStarted: 'Regeneration is started. Operation can take many hours',
                deletedMessage: 'Record deleted',
                createdMessage: 'A new record has been added',
                categoryCreated: 'Category added',
            },
        },
    },
};
