// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// Example: IGENETIKA

export const CorrectOriginSchema = t.keyof({
  IGENETIKA: null,
  ESKOT: null,
});

export type CorrectOrigin = t.TypeOf<typeof CorrectOriginSchema>;
