import {putAll, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {none, opt} from 'ts-opt';
import {put, call, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {appAction} from 'app/model/action';
import {t} from 'translations';
import {showBeError} from 'utils/show-be-error';
import {
    tablesAction,
    getRequestedTablePageParams,
    simpleTableSelector,
    convertTableDataParamsToDto,
} from 'utils/tables';

import {GetGenotypeExportsAction, genotypeExportsAction} from '../action';

function* execute({payload: {tableDataRequest}}: GetGenotypeExportsAction): SagaIterator {
    if (tableDataRequest.isEmpty) {
        yield* put(genotypeExportsAction.getGenotypeExportsSuccess(none));

        return yield* put(appAction.removeLoadingContent('genotypeExports'));
    }
    yield* put(tablesAction.preloadTableActions('genotypeExports', tableDataRequest.orCrash('No tableDataRequest')));

    const tableState = yield* select(simpleTableSelector.tableState('genotypeExports'));
    const tableDataParams = getRequestedTablePageParams({
        tableId: 'genotypeExports',
        tableDataRequest: tableDataRequest.orCrash('No tableDataRequest'),
        tableState,
    });

    const {filters, params} = convertTableDataParamsToDto({tableDataParams});

    const response = yield* call(Api.findAllGenotypeExports, filters, params);

    if (response.isSuccess) {
        yield* put(genotypeExportsAction.getGenotypeExportsSuccess(opt(response.data)));
        yield* put(tablesAction.postloadTableActions('genotypeExports', tableDataParams));
    } else {
        yield putAll(showBeError(response, t('genotypeExports/screenList')(`title`)));
        yield* put(appAction.removeLoadingContent('genotypeExports'));
    }
}

export function* getGenotypeExportsSaga(): SagaIterator {
    yield takeLatestF('genotypesExports/GET_GENOTYPE_EXPORTS', execute);
}
