import {takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {showSuccessSimple} from 'notifications';
import {t} from 'translations';

import {CreateSnpAction, snpActions} from '../actions';

const te = t('snpManagement/SnpTable');

function* execute({payload: {categoryId, name}}: CreateSnpAction): SagaIterator {
    const response = yield* call(Api.createSnp, {categoryId, name});

    if (response.isSuccess) {
        yield* put(showSuccessSimple({message: te('requestMessages.createdMessage')}));
        yield* put(snpActions.setShowCreateSnp());
        yield* put(snpActions.loadSnp(opt({action: 'refresh'})));
    }
}

export function* createSnpSaga(): SagaIterator {
    yield takeLatestF('snpManagement/CREATE_SNP', execute);
}
