import React, {FC, useEffect, useCallback} from 'react';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {Opt} from 'ts-opt';

import {ParenthoodVerification} from 'api/gen/ParenthoodVerification';
import {SampleIdCouple} from 'api/gen/SampleIdCouple';
import {Action} from 'app/actions';
import {State} from 'app/state';
import {Ant} from 'common/ant';
import {doubleGutter, gutter} from 'constants/general';
import {Container, MainPanel, PageHeading, Panel} from 'layout';
import {useOurTranslation} from 'translations';
import {formHelpers} from 'utils/forms';

import {PaternityCheckForm} from '../components/PaternityCheckForm';
import {PaternityCheckResult} from '../components/PaternityCheckResult';
import {paternityCheckAction} from '../model';

interface StateProps {
    result: Opt<ParenthoodVerification>;
}

interface DispatchProps {
    checkPaternity(data: SampleIdCouple): void;
    resetResults(): void;
    resetPaternityCheckForm(): void;
}

type Props = StateProps & DispatchProps;

const PaternityCheckBase: FC<Props> = props => {
    const {
        result,
        checkPaternity,
        resetResults,
        resetPaternityCheckForm,
    } = props;

    const {t} = useOurTranslation('paternityCheck/screenPaternityCheck');

    useEffect(() => {
        resetResults();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const resetFormAndResults = useCallback(() => {
        resetResults();
        resetPaternityCheckForm();
    }, [resetPaternityCheckForm, resetResults]);

    return (
        <Container>
            <MainPanel>
                <PageHeading>
                    <h1>{t('title')}</h1>
                </PageHeading>
                <Ant.Row gutter={[gutter, doubleGutter]}>
                    <Ant.Col xs={24}>
                        <Panel
                            title={t('formTitle')}
                        >
                            <PaternityCheckForm
                                onSubmit={checkPaternity}
                                onReset={resetFormAndResults}
                            />
                        </Panel>
                    </Ant.Col>
                    {result.map(x => (
                        <Ant.Col
                            key={x.probability}
                            xs={24}
                        >
                            <Panel
                                title={t('result')}
                            >
                                <div className="w-100">
                                    <PaternityCheckResult
                                        result={x}
                                    />
                                </div>
                            </Panel>
                        </Ant.Col>
                    )).orNull()}
                </Ant.Row>
            </MainPanel>
        </Container>
    );
};

const mapStateToProps = (state: State): StateProps => ({
    result: state.paternityCheck.result,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    checkPaternity: (data: SampleIdCouple): Action => dispatch(paternityCheckAction.checkPaternity(data)),
    resetResults: (): Action => dispatch(paternityCheckAction.resetResults()),
    resetPaternityCheckForm: (): Action => dispatch(formHelpers.reset('paternityCheck')),
});

export const PaternityCheck = connect(mapStateToProps, mapDispatchToProps)(PaternityCheckBase);
