// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


const requiredPart = t.interface({
  count: t.Integer,
  countDifference: t.Integer,
});

export const InseminationDetailsSchema = excess(requiredPart);

export interface InseminationDetails extends t.TypeOf<typeof InseminationDetailsSchema> {}
