import {takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {Errors} from 'api/gen/Errors';
import {formHelpers} from 'utils/forms';

import {ValidateChipIdAction} from '../action';

function* execute({payload: {chipId}}: ValidateChipIdAction): SagaIterator {
    const response = yield* call(Api.validateOrderData, {
        chipId,
        validateExistingOrder: true,
        earTag: null,
        laboratoryNumber: null,
        lineRegistry: null,
    });

    if (response.isSuccess) {
        yield* put(formHelpers.stopAsyncValidation('generateSampleSheet'));
    } else {
        const errors = response.data as Errors;
        yield* put(formHelpers.stopAsyncValidation('generateSampleSheet', {chipId: errors[0].message}));
    }
}

export function* validateChipIdSaga(): SagaIterator {
    yield takeLatestF('order/VALIDATE_CHIP_ID', execute);
}
