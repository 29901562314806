import {Nullable} from '@fl/cmsch-fe-library';
import React, {FC, memo, useCallback} from 'react';
import {Opt} from 'ts-opt';

import {Date} from 'api/gen/Date';
import {Modal} from 'common/layout';
import {ListActionModeType} from 'orders/types/list-action-mode-type';
import {useOurTranslation} from 'translations';

import {BillingBaseExportOptionsForm} from '../BillingBaseExportOptionsForm';
import {
    BillingBaseExportOptionsFormValues,
} from '../BillingBaseExportOptionsForm/billing-base-export-options-form-values';

interface Props {
    visible: boolean;
    exportBillingBaseMode: Opt<ListActionModeType>;
    exportBillingBaseByIds(billingDate: Date, force: boolean): void;
    exportBillingBaseByFilter(billingDate: Date, force: boolean, withoutSelected: boolean): void;
    setExportBillingBaseMode(mode: Nullable<ListActionModeType>): void;
}

const BillingBaseModalBase: FC<Props> = props => {
    const {
        visible,
        exportBillingBaseMode,
        exportBillingBaseByIds,
        exportBillingBaseByFilter,
        setExportBillingBaseMode,
    } = props;

    const {t} = useOurTranslation('orders/BillingBaseModal');

    const onCancel = useCallback(() => setExportBillingBaseMode(null), [setExportBillingBaseMode]);

    const onSubmit = useCallback((values: BillingBaseExportOptionsFormValues) => {
        exportBillingBaseMode.onSome(x => {
            if (values.billingDate) {
                switch (x) {
                    case 'selected': return exportBillingBaseByIds(values.billingDate, false);
                    case 'filter': return exportBillingBaseByFilter(values.billingDate, false, false);
                    case 'filterWithoutSelected': return exportBillingBaseByFilter(values.billingDate, false, true);
                }
            }
        });
        onCancel();
    }, [
        exportBillingBaseMode,
        onCancel,
        exportBillingBaseByFilter,
        exportBillingBaseByIds,
    ]);

    return (
        <Modal
            title={t('caption')}
            visible={visible}
            onCancel={onCancel}
            footer={null}
        >
            <BillingBaseExportOptionsForm
                onSubmit={onSubmit}
                onClose={onCancel}
            />
        </Modal>
    );
};

export const BillingBaseModal = memo(BillingBaseModalBase);
