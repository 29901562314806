import * as O from 'optics-ts';
import {opt, pipe} from 'ts-opt';

import {Action} from 'app/actions';

import {initialUserState, UserState} from './state';

const userStateO = O.optic<UserState>();

export const userReducer = (state: UserState = initialUserState, action: Action): UserState => {
    switch (action.type) {
        case 'user/GET_CURRENT_USER_SUCCESS': {
            const {user} = action.payload;

            return O.set(userStateO.prop('currentUser'))(opt(user))(state);
        }

        case 'user/SET_PREVIOUS_AND_CURRENT_PATHNAME': {
            const {current, previous} = action.payload;

            return pipe(
                state,
                O.set(userStateO.prop('previousPathname'))(previous),
                O.set(userStateO.prop('currentPathname'))(current),
            );
        }

        default:
            return state;
    }
};
