import {extractFormErrorsFromResponse, putAll, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {appAction} from 'app/model/action';
import {State} from 'app/state';
import {t} from 'translations';
import {formHelpers} from 'utils/forms';
import {showBeError} from 'utils/show-be-error';

import {animalsAction, GetMeasurements} from '../action';

const getAnimalIds = ({router}: State): Array<number> => {
    const animalIdsString: string = router.location?.pathname.split('/').pop() ?? '';

    return animalIdsString.split(',').map(x => parseInt(x, 10));
};

const getCategoryIds = (state: State): Array<number> | undefined => {
    const value = formHelpers.formValues('animalComparison')(state).orCrash('missing animalComparison values');

    return value.categories ?? undefined;
};

function* execute(_action: GetMeasurements): SagaIterator {
    yield* put(appAction.addLoadingContent('animalMeasurements'));
    yield* put(formHelpers.startSubmit('animalComparison'));

    const animalIds = yield* select(getAnimalIds);
    const categoryIds = yield* select(getCategoryIds);
    const response = yield* call(Api.getMeasurements, {animalIds, categoryIds: categoryIds || null});

    if (response.isSuccess) {
        yield* put(formHelpers.stopSubmit('animalComparison'));
        yield* put(animalsAction.getMeasurementsSuccess(response.data));
    } else {
        yield putAll(showBeError(response, t('animals/sagaMessages')('measurementsError')));
        yield* put(formHelpers.stopSubmit('animalComparison', extractFormErrorsFromResponse(response)));
    }
    yield* put(appAction.removeLoadingContent('animalMeasurements'));
}

export function* getMeasurementsSaga(): SagaIterator {
    yield takeLatestF('animals/GET_MEASUREMENTS', execute);
}
