import {Button} from '@fl/cmsch-fe-library';
import React, {FC, Fragment, memo, useCallback, useMemo} from 'react';
import {Opt} from 'ts-opt';

import {BillingBasesExport} from 'api/gen/BillingBasesExport';
import {Modal} from 'common/layout';
import {useOurTranslation} from 'translations';

interface Props {
    visible: boolean;
    billingBaseExport: Opt<BillingBasesExport>;
    onCancel(): void;
    downloadBillingBases(fileKey: string): void;
}

const DownloadBillingBaseExportModalBase: FC<Props> = props => {
    const {
        visible,
        billingBaseExport,
        onCancel,
        downloadBillingBases,
    } = props;

    const {t, tCommon} = useOurTranslation('orders/DownloadBillingBaseExport');

    const billingBaseExportPresent = billingBaseExport.orNull();

    const onCsvDownload = useCallback(() =>
        billingBaseExportPresent?.billingBasisCsvKey
            && downloadBillingBases(billingBaseExportPresent.billingBasisCsvKey)
    , [billingBaseExportPresent?.billingBasisCsvKey, downloadBillingBases]);

    const onMeatCsvDownload = useCallback(() =>
        billingBaseExportPresent?.billingBasisMeatAssociationCsvKey
        && downloadBillingBases(billingBaseExportPresent.billingBasisMeatAssociationCsvKey)
    , [billingBaseExportPresent?.billingBasisMeatAssociationCsvKey, downloadBillingBases]);

    const onZipDownload = useCallback(() =>
        billingBaseExportPresent?.billingBasisZipKey
        && downloadBillingBases(billingBaseExportPresent.billingBasisZipKey)
    , [billingBaseExportPresent?.billingBasisZipKey, downloadBillingBases]);

    const footer = useMemo(() => (
        <Fragment>
            <Button
                key="back"
                onClick={onCancel}
                testId="cancel-button"
            >
                {tCommon('cancel')}
            </Button>
        </Fragment>
    ), [onCancel, tCommon]);

    return (
        <Modal
            title={t('caption')}
            visible={visible}
            onCancel={onCancel}
            footer={footer}
        >
            <div className="row">
                <div className="col-12 d-flex">
                    {billingBaseExportPresent?.billingBasisCsvKey && (
                        <div
                            className="mr-2"
                            data-test-id="download-csv"
                        >
                            <Button
                                visuals="primary"
                                icon="downloadOutlined"
                                onClick={onCsvDownload}
                            >
                                {t(`billingBasisCsvKey`)}
                            </Button>
                        </div>
                    )}

                    {billingBaseExportPresent?.billingBasisZipKey && (
                        <div
                            className="mr-2"
                            data-test-id="download-zip"
                        >
                            <Button
                                visuals="primary"
                                icon="downloadOutlined"
                                onClick={onZipDownload}
                            >
                                {t(`billingBasisZipKey`)}
                            </Button>
                        </div>
                    )}

                    {billingBaseExportPresent?.billingBasisMeatAssociationCsvKey && (
                        <div
                            className="mr-2"
                            data-test-id="download-meat-csv"
                        >
                            <Button
                                visuals="primary"
                                icon="downloadOutlined"
                                onClick={onMeatCsvDownload}
                            >
                                {t(`billingBasisMeatAssociationCsvKey`)}
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export const DownloadBillingBaseExportModal = memo(DownloadBillingBaseExportModalBase);
