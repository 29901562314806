import {animalNameMaxLength} from 'api/gen/AnimalName';
import {breedMaxLength} from 'api/gen/Breed';
import {customerNameMaxLength} from 'api/gen/CustomerName';
import {earTagRegexGen} from 'api/gen/EarTag';
import {labNumberMaxLength, labNumberRegexGen} from 'api/gen/LabNumber';
import {lineRegistryRegexGen} from 'api/gen/LineRegistry';
import {t, tCommon} from 'translations';
import {PaternityValidator} from 'utils/paternity-validator';
import {Errors} from 'utils/validator';

import {validateAddress} from '../AddressFormSection/validations';
import {GenerateProtocolFormValues} from './generate-protocol-form-values';

const te = t('orders/GenerateProtocolForm');

export const validate = (values: GenerateProtocolFormValues): Errors<GenerateProtocolFormValues> => {
    const validator = new PaternityValidator(values);

    validator.nonEmpty('sampleId', tCommon('earTag'));
    validator.pattern('sampleId', earTagRegexGen(), tCommon('earTag'));
    validator.nonEmpty('customerName', te('customerName'));
    validator.maxStringLength('customerName', customerNameMaxLength, te('customerName'));
    validator.maxStringLength('breed', breedMaxLength, te('breed'));
    validator.maxStringLength('fathersBreed', breedMaxLength, te('fathersBreed'));
    validator.pattern('fathersLaboratoryNumber', labNumberRegexGen(), te('fathersLaboratoryNumber'));
    validator.maxStringLength('fathersLaboratoryNumber', labNumberMaxLength, te('fathersLaboratoryNumber'));
    validator.pattern('fathersRegistry', lineRegistryRegexGen(), te('fathersRegistry'));
    validator.pattern('fathersSampleId', earTagRegexGen(), te('fathersSampleId'));
    validator.maxStringLength('mothersBreed', breedMaxLength, te('mothersBreed'));
    validator.pattern('mothersLaboratoryNumber', labNumberRegexGen(), te('mothersLaboratoryNumber'));
    validator.maxStringLength('mothersLaboratoryNumber', labNumberMaxLength, te('mothersLaboratoryNumber'));
    validator.pattern('mothersSampleId', earTagRegexGen(), te('mothersSampleId'));
    validator.maxStringLength('name', animalNameMaxLength, te('name'));
    validator.pattern('registry', lineRegistryRegexGen(), te('registry'));
    validator.nonEmpty('sampleTypeId', te('sampleTypeId'));

    const errors = validator.generateErrorsObject();
    if (values.address) {
        errors.address = validateAddress(values.address);
    }

    return errors;
};
