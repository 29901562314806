import {Opt} from 'ts-opt';

import {State} from 'app/state';

import {CodeTableItem} from '../../../api/gen/CodeTableItem';
import {OrderTypeItem} from '../../../api/gen/OrderTypeItem';
import {DialsState} from './state';

export const simpleDialsSelector = {
    dials: ({dials}: State): DialsState => dials,
    sampleTypes: ({dials}: State): Opt<Array<CodeTableItem>> => dials.sampleTypes,
    orderTypes: ({dials}: State): Opt<Array<OrderTypeItem>> => dials.orderTypes,
};
