import {none, Opt} from 'ts-opt';

import {UserDetails} from 'api/gen/UserDetails';

export interface UserState {
    currentUser: Opt<UserDetails>;
    previousPathname: Opt<string>;
    currentPathname: Opt<string>;
}

export const initialUserState: UserState = {
    currentUser: none,
    previousPathname: none,
    currentPathname: none,
};
