import {NullableKeys} from '@fl/cmsch-fe-library';

import {Address} from 'api/gen/Address';

export type AddressFormSectionValues = NullableKeys<Address>;

export const initialAddressFormSectionValues: AddressFormSectionValues = {
    city: null,
    street: null,
    zip: null,
};
