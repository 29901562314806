// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// A username of a user.
// Example: "PribanovaM"

export const usernameMinLength = 1;
export const usernameMaxLength = 255;

export const UsernameSchema = t.refinement(t.string, n => n.length >= usernameMinLength && n.length <= usernameMaxLength, 'Username');

export type Username = string;
