import {Nullable} from '@fl/cmsch-fe-library';
import React, {FC, Fragment, memo, useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {simpleDialsSelector} from 'dials/model/selector';
import {useOurTranslation} from 'translations';
import {useUser} from 'user';
import {codeTableItemsToOptions} from 'utils/code-table-items-to-options';
import {formHelpers} from 'utils/forms';
import {getSexOptions} from 'utils/get-sex-options';

import {orderAction} from '../../model/action';
import {AnimalsDetailsForm} from '../AnimalsDetailsForm';
import {NewBulkUserDetailsForm} from '../NewBulkUserDetailsForm';
import {BulkOrderConfirmationForm} from '../OrderBulkConfirmationForm';
import {OrderBulkDetailsForm} from '../OrderBulkDetailsForm';
import {newBulkOrderFormName, NewBulkOrderFormValues} from './new-bulk-order-form-values';

const pageOne = 1;
const pageTwo = 2;
const pageThree = 3;
const pageFour = 4;

const NewBulkFormBase: FC = () => {
    const dispatch = useDispatch();
    const orderTypes = useSelector(simpleDialsSelector.orderTypes);
    const sampleTypes = useSelector(simpleDialsSelector.sampleTypes);
    const [page, setPage] = useState(1);
    const {t} = useOurTranslation('orders/newBulkOrder');

    const convertedSampleTypes = useMemo(() => codeTableItemsToOptions(sampleTypes), [sampleTypes]);
    const convertedOrderTypes = useMemo(() => codeTableItemsToOptions(orderTypes), [orderTypes]);

    const handleNextPageClick = useCallback(() => {
        if (page < pageFour) setPage(page + 1);
    }, [page, setPage]);
    const handlePreviousPageClick = useCallback(() => {
        if (page > pageOne) setPage(page - 1);
    }, [page, setPage]);

    const {isRoleBreeder, isRoleLaborer: showCreatedBy} = useUser();

    const getUserLastOrder = useCallback((cin: Nullable<string>) => {
        dispatch(orderAction.getUserLastOrder(cin, 'newBulkOrder'));
    }, [dispatch]);
    const createOrder = useCallback((values: NewBulkOrderFormValues) => {
        dispatch(orderAction.createBulkOrder(values));
    }, [dispatch]);

    useEffect(() => {
        if (isRoleBreeder) getUserLastOrder(null);

        return () => {
            dispatch(formHelpers.reset(newBulkOrderFormName));
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const animalSexes = getSexOptions();

    useEffect(() => {
        if (showCreatedBy) dispatch(orderAction.getBreeders());
    }, [dispatch, showCreatedBy]);

    return (
        <Fragment>
            {page === pageOne && (
                <NewBulkUserDetailsForm
                    onSubmit={handleNextPageClick}
                    showCreatedBy={showCreatedBy}
                    caption={t('stepOneCaption')}
                    onGetUserLastOrder={getUserLastOrder}
                />
            )}
            {page === pageTwo && (
                <OrderBulkDetailsForm
                    sampleTypes={convertedSampleTypes}
                    orderTypes={convertedOrderTypes}
                    onPreviousClick={handlePreviousPageClick}
                    onSubmit={handleNextPageClick}
                />
            )}
            {page === pageThree && (
                <AnimalsDetailsForm
                    onSubmit={handleNextPageClick}
                    onPreviousClick={handlePreviousPageClick}
                />
            )}
            {page === pageFour && (
                <BulkOrderConfirmationForm
                    animalSexes={animalSexes}
                    sampleTypes={convertedSampleTypes}
                    orderTypes={convertedOrderTypes}
                    onPreviousClick={handlePreviousPageClick}
                    onSubmit={createOrder}
                />
            )}
        </Fragment>
    );
};

export const NewBulkForm = memo(NewBulkFormBase);
