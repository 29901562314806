import {CodeTableItem} from 'api/gen/CodeTableItem';
import {CodeTableValidation} from 'api/gen/CodeTableValidation';
import {EmailTemplateDetails} from 'api/gen/EmailTemplateDetails';
import {EmailTemplateUpdate} from 'api/gen/EmailTemplateUpdate';
import {NewCodeTableItem} from 'api/gen/NewCodeTableItem';
import {NewOrderTypeItem} from 'api/gen/NewOrderTypeItem';
import {NewVerificationOutcome} from 'api/gen/NewVerificationOutcome';
import {OrderTypeItem} from 'api/gen/OrderTypeItem';
import {OrderTypeValidation} from 'api/gen/OrderTypeValidation';
import {SideBox} from 'api/gen/SideBox';
import {VerificationOutcome} from 'api/gen/VerificationOutcome';

import {DialsModals} from '../types/dials-modals';

export const dialsAction = {
    setModalVisibility: (id: DialsModals, visible: boolean) => ({
        type: 'dials/SET_MODAL_VISIBILITY',
        payload: {id, visible},
    }) as const,
    toggleModalVisibility: (id: DialsModals) => ({
        type: 'dials/TOGGLE_MODAL_VISIBILITY',
        payload: {id},
    }) as const,
    startEditingSampleType: (id: number) => ({
        type: 'dials/START_EDITING_SAMPLE_TYPE',
        payload: {id},
    }) as const,
    setSampleTypeFormValues: (sampleType: CodeTableItem) => ({
        type: 'dials/SET_SAMPLE_TYPE_FORM_VALUES',
        payload: {sampleType},
    }) as const,
    sampleTypeEditingCancelled: () => ({
        type: 'dials/SAMPLE_TYPE_EDITING_CANCELLED',
    }) as const,
    getSampleTypes: () => ({
        type: 'dials/GET_SAMPLE_TYPES',
    }) as const,
    getSampleTypesSuccess: (sampleTypes?: Array<CodeTableItem>) => ({
        type: 'dials/GET_SAMPLE_TYPES_SUCCESS',
        payload: {sampleTypes},
    }) as const,
    createSampleType: (sampleType: CodeTableItem) => ({
        type: 'dials/CREATE_SAMPLE_TYPE',
        payload: {sampleType},
    }) as const,
    updateSampleType: (id: number, sampleType: NewCodeTableItem) => ({
        type: 'dials/UPDATE_SAMPLE_TYPE',
        payload: {id, sampleType},
    }) as const,
    updateSampleTypeSuccess: () => ({
        type: 'dials/UPDATE_SAMPLE_TYPE_SUCCESS',
    }) as const,
    startEditingOrderType: (id: number) => ({
        type: 'dials/START_EDITING_ORDER_TYPE',
        payload: {id},
    }) as const,
    orderTypeEditingCancelled: () => ({
        type: 'dials/ORDER_TYPE_EDITING_CANCELLED',
    }) as const,
    getOrderTypes: () => ({
        type: 'dials/GET_ORDER_TYPES',
    }) as const,
    getOrderTypesSuccess: (orderTypes?: Array<OrderTypeItem>) => ({
        type: 'dials/GET_ORDER_TYPES_SUCCESS',
        payload: {orderTypes},
    }) as const,
    createOrderType: (orderType: NewOrderTypeItem) => ({
        type: 'dials/CREATE_ORDER_TYPE',
        payload: {orderType},
    }) as const,
    updateOrderType: (id: number, orderType: NewOrderTypeItem) => ({
        type: 'dials/UPDATE_ORDER_TYPE',
        payload: {id, orderType},
    }) as const,
    updateOrderTypeSuccess: () => ({
        type: 'dials/UPDATE_ORDER_TYPE_SUCCESS',
    }) as const,
    startEditingVerificationOutcome: (id: number) => ({
        type: 'dials/START_EDITING_VERIFICATION_OUTCOME',
        payload: {id},
    }) as const,
    verificationOutcomeEditingCancelled: () => ({
        type: 'dials/VERIFICATION_OUTCOME_EDITING_CANCELLED',
    }) as const,
    getVerificationOutcomes: () => ({
        type: 'dials/GET_VERIFICATION_OUTCOMES',
    }) as const,
    getVerificationOutcomesSuccess: (verificationOutcomes?: Array<VerificationOutcome>) => ({
        type: 'dials/GET_VERIFICATION_OUTCOMES_SUCCESS',
        payload: {verificationOutcomes},
    }) as const,
    createVerificationOutcome: (verificationOutcome: NewVerificationOutcome) => ({
        type: 'dials/CREATE_VERIFICATION_OUTCOME',
        payload: {verificationOutcome},
    }) as const,
    updateVerificationOutcome: (id: number, verificationOutcome: NewVerificationOutcome) => ({
        type: 'dials/UPDATE_VERIFICATION_OUTCOME',
        payload: {id, verificationOutcome},
    }) as const,
    updateVerificationOutcomeSuccess: () => ({
        type: 'dials/UPDATE_VERIFICATION_OUTCOME_SUCCESS',
    }) as const,
    startEditingTestResult: (id: number) => ({
        type: 'dials/START_EDITING_TEST_RESULT',
        payload: {id},
    }) as const,
    testResultEditingCancelled: () => ({
        type: 'dials/TEST_RESULT_EDITING_CANCELLED',
    }) as const,
    getTestResults: () => ({
        type: 'dials/GET_TEST_RESULTS',
    }) as const,
    getTestResultsSuccess: (testResults?: Array<CodeTableItem>) => ({
        type: 'dials/GET_TEST_RESULTS_SUCCESS',
        payload: {testResults},
    }) as const,
    createTestResult: (testResult: CodeTableItem) => ({
        type: 'dials/CREATE_TEST_RESULT',
        payload: {testResult},
    }) as const,
    updateTestResult: (id: number, testResult: NewCodeTableItem) => ({
        type: 'dials/UPDATE_TEST_RESULT',
        payload: {id, testResult},
    }) as const,
    updateTestResultSuccess: () => ({
        type: 'dials/UPDATE_TEST_RESULT_SUCCESS',
    }) as const,
    startEditingMailTemplate: (id: number) => ({
        type: 'dials/START_EDITING_MAIL_TEMPLATE',
        payload: {id},
    }) as const,
    mailTemplateEditingCancelled: () => ({
        type: 'dials/MAIL_TEMPLATE_EDITING_CANCELLED',
    }) as const,
    getMailTemplates: () => ({
        type: 'dials/GET_MAIL_TEMPLATES',
    }) as const,
    getMailTemplatesSuccess: (mailTemplates?: Array<EmailTemplateDetails>) => ({
        type: 'dials/GET_MAIL_TEMPLATES_SUCCESS',
        payload: {mailTemplates},
    }) as const,
    updateMailTemplate: (id: number, mailTemplate: EmailTemplateUpdate) => ({
        type: 'dials/UPDATE_MAIL_TEMPLATE',
        payload: {id, mailTemplate},
    }) as const,

    updateMailTemplateSuccess: () => ({
        type: 'dials/UPDATE_MAIL_TEMPLATE_SUCCESS',
    }) as const,
    getBreederInfo: () => ({
        type: 'dials/GET_BREEDER_INFO',
    }) as const,
    getBreederInfoSuccess: (breederInfo: SideBox) => ({
        type: 'dials/GET_BREEDER_INFO_SUCCESS',
        payload: {breederInfo},
    }) as const,
    getBreederInfoConfiguration: () => ({
        type: 'dials/GET_BREEDER_INFO_CONFIGURATION',
    }) as const,
    getBreederInfoConfigurationSuccess: (breederInfoConfiguration: SideBox) => ({
        type: 'dials/GET_BREEDER_INFO_CONFIGURATION_SUCCESS',
        payload: {breederInfoConfiguration},
    }) as const,
    saveBreederInfo: (values: SideBox) => ({
        type: 'dials/SAVE_BREEDER_INFO',
        payload: {values},
    }) as const,
    hideBreederInfo: () => ({
        type: 'dials/HIDE_BREEDER_INFO',
    }) as const,
    validateOrderType: (data: OrderTypeValidation) => ({
        type: 'dials/VALIDATE_ORDER_TYPE',
        payload: {data},
    }) as const,
    validateSampleType: (data: CodeTableValidation) => ({
        type: 'dials/VALIDATE_SAMPLE_TYPE',
        payload: {data},
    }) as const,
    validateTestResultType: (data: CodeTableValidation) => ({
        type: 'dials/VALIDATE_TEST_RESULT_TYPE',
        payload: {data},
    }) as const,
    validateVerificationOutcomeType: (data: CodeTableValidation) => ({
        type: 'dials/VALIDATE_VERIFICATION_OUTCOME_TYPE',
        payload: {data},
    }) as const,
};

export type SetModalVisibilityAction = ReturnType<typeof dialsAction.setModalVisibility>;
export type ToggleModalVisibilityAction = ReturnType<typeof dialsAction.toggleModalVisibility>;
export type StartEditingSampleTypeAction = ReturnType<typeof dialsAction.startEditingSampleType>;
export type SetSampleTypeFormValuesAction = ReturnType<typeof dialsAction.setSampleTypeFormValues>;
export type SampleTypeEditingCancelledAction = ReturnType<typeof dialsAction.sampleTypeEditingCancelled>;
export type GetSampleTypesAction = ReturnType<typeof dialsAction.getSampleTypes>;
export type GetSampleTypesSuccessAction = ReturnType<typeof dialsAction.getSampleTypesSuccess>;
export type CreateSampleTypeAction = ReturnType<typeof dialsAction.createSampleType>;
export type UpdateSampleTypeAction = ReturnType<typeof dialsAction.updateSampleType>;
export type UpdateSampleTypeSuccessAction = ReturnType<typeof dialsAction.updateSampleTypeSuccess>;
export type StartEditingOrderTypeAction = ReturnType<typeof dialsAction.startEditingOrderType>;
export type OrderTypeEditingCancelledAction = ReturnType<typeof dialsAction.orderTypeEditingCancelled>;
export type GetOrderTypesAction = ReturnType<typeof dialsAction.getOrderTypes>;
export type GetOrderTypesSuccessAction = ReturnType<typeof dialsAction.getOrderTypesSuccess>;
export type CreateOrderTypeAction = ReturnType<typeof dialsAction.createOrderType>;
export type UpdateOrderTypeAction = ReturnType<typeof dialsAction.updateOrderType>;
export type UpdateOrderTypeSuccessAction = ReturnType<typeof dialsAction.updateOrderTypeSuccess>;
export type StartEditingVerificationOutcomeAction = ReturnType<typeof dialsAction.startEditingVerificationOutcome>;
export type VerificationOutcomeEditingCancelledAction =
    ReturnType<typeof dialsAction.verificationOutcomeEditingCancelled>;
export type GetVerificationOutcomesAction = ReturnType<typeof dialsAction.getVerificationOutcomes>;
export type GetVerificationOutcomesSuccessAction = ReturnType<typeof dialsAction.getVerificationOutcomesSuccess>;
export type CreateVerificationOutcomeAction = ReturnType<typeof dialsAction.createVerificationOutcome>;
export type UpdateVerificationOutcomeAction = ReturnType<typeof dialsAction.updateVerificationOutcome>;
export type UpdateVerificationOutcomeSuccessAction = ReturnType<typeof dialsAction.updateVerificationOutcomeSuccess>;
export type StartEditingTestResultAction = ReturnType<typeof dialsAction.startEditingTestResult>;
export type TestResultEditingCancelledAction = ReturnType<typeof dialsAction.testResultEditingCancelled>;
export type GetTestResultsAction = ReturnType<typeof dialsAction.getTestResults>;
export type GetTestResultsSuccessAction = ReturnType<typeof dialsAction.getTestResultsSuccess>;
export type CreateTestResultAction = ReturnType<typeof dialsAction.createTestResult>;
export type UpdateTestResultAction = ReturnType<typeof dialsAction.updateTestResult>;
export type UpdateTestResultActionSuccess = ReturnType<typeof dialsAction.updateTestResultSuccess>;
export type StartEditingMailTemplateAction = ReturnType<typeof dialsAction.startEditingMailTemplate>;
export type MailTemplateEditingCancelledAction = ReturnType<typeof dialsAction.mailTemplateEditingCancelled>;
export type GetMailTemplatesAction = ReturnType<typeof dialsAction.getMailTemplates>;
export type GetMailTemplatesSuccessAction = ReturnType<typeof dialsAction.getMailTemplatesSuccess>;
export type UpdateMailTemplateAction = ReturnType<typeof dialsAction.updateMailTemplate>;
export type UpdateMailTemplateSuccessAction = ReturnType<typeof dialsAction.updateMailTemplateSuccess>;
export type GetBreederInfoAction = ReturnType<typeof dialsAction.getBreederInfo>;
export type GetBreederInfoSuccessAction = ReturnType<typeof dialsAction.getBreederInfoSuccess>;
export type GetBreederInfoConfigurationAction = ReturnType<typeof dialsAction.getBreederInfoConfiguration>;
export type GetBreederInfoConfigurationSuccessAction =
    ReturnType<typeof dialsAction.getBreederInfoConfigurationSuccess>;
export type SaveBreederInfoAction = ReturnType<typeof dialsAction.saveBreederInfo>;
export type HideBreederInfoAction = ReturnType<typeof dialsAction.hideBreederInfo>;
export type ValidateOrderTypeAction = ReturnType<typeof dialsAction.validateOrderType>;
export type ValidateSampleTypeAction = ReturnType<typeof dialsAction.validateSampleType>;
export type ValidateTestResultTypeAction = ReturnType<typeof dialsAction.validateTestResultType>;
export type ValidateVerificationOutcomeTypeAction = ReturnType<typeof dialsAction.validateVerificationOutcomeType>;

export type DialsAction =
    | SetModalVisibilityAction
    | ToggleModalVisibilityAction
    | StartEditingSampleTypeAction
    | SetSampleTypeFormValuesAction
    | SampleTypeEditingCancelledAction
    | GetSampleTypesAction
    | GetSampleTypesSuccessAction
    | CreateSampleTypeAction
    | UpdateSampleTypeAction
    | UpdateSampleTypeSuccessAction
    | StartEditingOrderTypeAction
    | OrderTypeEditingCancelledAction
    | GetOrderTypesAction
    | GetOrderTypesSuccessAction
    | CreateOrderTypeAction
    | UpdateOrderTypeAction
    | UpdateOrderTypeSuccessAction
    | StartEditingVerificationOutcomeAction
    | VerificationOutcomeEditingCancelledAction
    | GetVerificationOutcomesAction
    | GetVerificationOutcomesSuccessAction
    | CreateVerificationOutcomeAction
    | UpdateVerificationOutcomeAction
    | UpdateVerificationOutcomeSuccessAction
    | StartEditingTestResultAction
    | TestResultEditingCancelledAction
    | GetTestResultsAction
    | GetTestResultsSuccessAction
    | CreateTestResultAction
    | UpdateTestResultAction
    | UpdateTestResultActionSuccess
    | StartEditingMailTemplateAction
    | MailTemplateEditingCancelledAction
    | GetMailTemplatesAction
    | GetMailTemplatesSuccessAction
    | UpdateMailTemplateAction
    | UpdateMailTemplateSuccessAction
    | GetBreederInfoAction
    | GetBreederInfoSuccessAction
    | GetBreederInfoConfigurationAction
    | GetBreederInfoConfigurationSuccessAction
    | SaveBreederInfoAction
    | HideBreederInfoAction
    | ValidateOrderTypeAction
    | ValidateSampleTypeAction
    | ValidateTestResultTypeAction
    | ValidateVerificationOutcomeTypeAction
;
