import {putAll, takeLatestF, extractFormErrorsFromResponse} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {put, call} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {
    createVerificationOutcomeFormName,
} from 'dials/components/VerificationOutcomeForm/verification-outcome-form-values';
import {t} from 'translations';
import {formHelpers} from 'utils/forms';
import {showBeError} from 'utils/show-be-error';

import {CreateVerificationOutcomeAction, dialsAction} from '../action';

function* execute(action: CreateVerificationOutcomeAction): SagaIterator {
    const {verificationOutcome} = action.payload;

    yield* put(formHelpers.startSubmit(createVerificationOutcomeFormName));

    const response = yield* call(Api.createVerificationOutcomeType, verificationOutcome);

    if (response.isSuccess) {
        yield* put(dialsAction.getVerificationOutcomes());
        yield* put(dialsAction.setModalVisibility('verificationOutcome', false));
        yield* put(formHelpers.stopSubmit(createVerificationOutcomeFormName));
    } else {
        yield* put(formHelpers.stopSubmit(
            createVerificationOutcomeFormName,
            extractFormErrorsFromResponse(response),
        ));
        yield putAll(showBeError(response, t('dials/verificationOutcomes')('createNew')));
    }
}

export function* createVerificationOutcomeSaga(): SagaIterator {
    yield takeLatestF('dials/CREATE_VERIFICATION_OUTCOME', execute);
}
