import {putAll, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {put, call} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {ChipImportOutcome} from 'api/gen/ChipImportOutcome';
import {showSuccessSimple} from 'notifications';
import {t} from 'translations';
import {showBeError} from 'utils/show-be-error';

import {SendImportedFileAction, importAction} from '../action';
import {translate} from './utils';

const composeInfoMessage = (data: ChipImportOutcome): string => {
    const firstPart = translate('importText1', data);
    const secondPart = translate('importText2', data);

    return data.numOfSkippedLines === 0 ? firstPart : `${firstPart}\n${secondPart}`;
};

function* execute({payload: {data}}: SendImportedFileAction): SagaIterator {
    yield* put(importAction.setLoading(true));

    const response = yield* call(Api.importAnimals, data);

    yield* put(importAction.setLoading(false));

    if (response.isSuccess) {
        yield* put(showSuccessSimple({message: composeInfoMessage(response.data)}));
    } else {
        yield putAll(showBeError(response, t('fileImport/screenImport')('title')));
    }
}

export function* sendImportedFileSaga(): SagaIterator {
    yield takeLatestF('fileImport/SEND_IMPORTED_FILE', execute);
}
