import {Snp} from 'api/gen/Snp';
import {t} from 'translations';
import {PaternityValidator} from 'utils/paternity-validator';
import {Errors} from 'utils/validator';

const te = t('snpManagement/SnpTable');

export const validate = (values: Snp): Errors<Snp> => {
    const validator = new PaternityValidator(values);

    validator.nonEmpty('name', te('formTranslations.snpName'));
    validator.nonEmpty('categoryId', te('columnHeaders.category'));

    return validator.generateErrorsObject();
};
