import {Options} from '@fl/cmsch-fe-library';

import {SexType} from 'api/gen/SexType';
import {tCommon} from 'translations';

export const getSexOptions = (): Options<SexType> => [
    {
        value: 'MALE',
        label: tCommon('sex.male'),
    }, {
        value: 'FEMALE',
        label: tCommon('sex.female'),
    },
];
