import {downloadFileFromResponse, putAll, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'translations';
import {formHelpers} from 'utils/forms';
import {showBeError} from 'utils/show-be-error';

import {GenerateSampleSheetByIdsAction, orderAction} from '../action';
import {isActionForced} from './is-action-forced';
import {getSelectedOrders} from './selectors';

function* execute({payload: {force, chipId}}: GenerateSampleSheetByIdsAction): SagaIterator {
    yield* put(formHelpers.startSubmit('generateSampleSheet'));

    const orderIds = yield* select(getSelectedOrders);
    yield* put(orderAction.setListActionActive('generateSampleSheet', true));

    const response = yield* call(Api.generateSampleSheetSubset, {chipId, force, orderIds});

    if (response.isSuccess) {
        yield* call(downloadFileFromResponse, response);
        yield* put(orderAction.getOrders(opt({action: 'refresh'})));
    } else {
        if (force) {
            yield putAll(showBeError(response, t('orders/ListActions')('generateSampleSheet')));
        } else {
            const isForced = yield* call(isActionForced, response);

            if (isForced) {
                yield* put(orderAction.generateSampleSheetByIds(chipId, true));
            }
        }
    }
    yield* put(orderAction.setListActionActive('generateSampleSheet', false));
    yield* put(orderAction.resetDownloadWithoutForceFailedResult());
    yield* put(formHelpers.stopSubmit('generateSampleSheet'));
}

export function* generateSampleSheetByIdsSaga(): SagaIterator {
    yield takeLatestF('order/GENERATE_SAMPLE_SHEET_BY_IDS', execute);
}
