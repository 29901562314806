import {NullableKeys} from '@fl/cmsch-fe-library';

import {Date} from 'api/gen/Date';
import {SexType} from 'api/gen/SexType';

export const generateRequestFormName = 'generateRequest';

export type GenerateRequestFormValues = NullableKeys<{
    sampleId: string,
    sex: SexType,
    breed: string,
    dateOfBirth: Date,
    barcode: string,
    fathersBreed: string,
    fathersRegistry: string,
    mothersSampleId: string,
    mothersFatherRegistry: string,
    mothersFatherBreed: string,
    customerName: string,
}>;

export const generateRequestInitialValues: GenerateRequestFormValues = {
    barcode: null,
    breed: null,
    customerName: null,
    dateOfBirth: null,
    fathersBreed: null,
    fathersRegistry: null,
    mothersFatherBreed: null,
    mothersFatherRegistry: null,
    mothersSampleId: null,
    sampleId: null,
    sex: null,
};
