import {Nullable} from '@fl/cmsch-fe-library';
import React, {FC, memo} from 'react';

import {MaternityDot, PaternityDot} from 'animals/components';
import {SvgIcon} from 'icons';
import {useOurTranslation} from 'translations';
import {ParenthoodValues} from 'types/parenthood-values';

import styles from './styles.sass';

import completeInvSvg from './completeInv.svg';
import customerNoteSvg from './customerNote.svg';
import exportSvg from './export.svg';
import planInvSvg from './planInv.svg';

interface Props {
    exportedByAssociation: Nullable<boolean>;
    readyForBilling: boolean;
    fullyBilled: boolean;
    isRoleBreeder: boolean;
    isRoleLaborer: boolean;
    customerNote: Nullable<string>;
    paternity: Nullable<ParenthoodValues>;
    maternity: Nullable<ParenthoodValues>;
    showChipCircle: boolean;
    laboratoryNote: Nullable<string>;
}

const OrderStateBase: FC<Props> = props => {
    const {
        exportedByAssociation,
        readyForBilling,
        fullyBilled,
        isRoleBreeder,
        isRoleLaborer,
        customerNote,
        paternity,
        maternity,
        showChipCircle,
        laboratoryNote,
    } = props;

    const {t} = useOurTranslation('orders/OrderState');

    return (
        <span
            className="justify-content-end d-flex flex-fill"
            data-test-id="order-states"
        >
            {exportedByAssociation && (
                <span
                    data-test-id="exported-by-association"
                    className={styles.stateIcon}
                    title={t('exportedByAssociation')}
                >
                    <SvgIcon icon={exportSvg} />
                </span>
            )}
            {isRoleLaborer && customerNote && (
                <span
                    data-test-id="customer-note"
                    className={styles.stateIcon}
                    title={customerNote}
                >
                    <SvgIcon icon={customerNoteSvg} />
                </span>
            )}
            {isRoleLaborer && laboratoryNote && (
                <span
                    data-test-id="laboratory-note"
                    className={styles.laboratoryNote}
                    title={laboratoryNote}
                >
                    <SvgIcon icon={customerNoteSvg} />
                </span>
            )}
            {readyForBilling && (
                <span
                    data-test-id="state-ready-for-billing"
                    className={styles.stateIcon}
                    title={t('stateReadyForBilling')}
                >
                    <SvgIcon icon={planInvSvg} />
                </span>
            )}
            {!isRoleBreeder && fullyBilled && (
                <span
                    data-test-id="state-fully-billed"
                    className={styles.stateIcon}
                    title={t('stateFullyBilled')}
                >
                    <SvgIcon icon={completeInvSvg} />
                </span>
            )}
            {showChipCircle && <PaternityDot paternity={paternity} />}
            {showChipCircle && <MaternityDot maternity={maternity} />}
        </span>
    );
};

export const OrderState = memo(OrderStateBase);
