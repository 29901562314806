import {t} from 'translations';
import {PaternityValidator} from 'utils/paternity-validator';
import {Errors} from 'utils/validator';

import {ComparisonFormValues} from './comparison-form-values';

export const validate = (values: ComparisonFormValues): Errors<ComparisonFormValues> => {
    const validator = new PaternityValidator(values);

    validator.nonEmpty('categories', t('animals/ComparisonForm')('categories'));

    return validator.generateErrorsObject();
};
