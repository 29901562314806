import {Nullable} from '@fl/cmsch-fe-library';

import {Address} from 'api/gen/Address';

import {AddressFormSectionValues} from '../types/address-form-section-values';

export const transformFormToAddress = (address: Nullable<AddressFormSectionValues> | undefined): Address | null => {
    if (address?.city && address.zip && address.street) {
        return {
            city: address.city,
            street: address.street,
            zip: address.zip,
        };
    }

    return null;
};
