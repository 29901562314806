import {KeysOfType} from '@fl/cmsch-fe-library';

import {cinRegexGen} from 'api/gen/Cin';
import {tCommon} from 'translations';

import {KeysOfStringType, Validator} from './validator';

export class PaternityValidator<Values> extends Validator<Values> {
    public cin(fieldName: KeysOfStringType<Values>, label: string): void {
        this.patternCustom(fieldName, cinRegexGen(), tCommon('validator.cinNotValid', {label}));
    }

    public cinUserDetails(
        cinFieldName: KeysOfType<Values, string | null>,
        noCinFieldName: KeysOfType<Values, boolean | null>,
        cinLabel: string,
        noCinLabel: string,
    ): void {
        if (this.isFieldEmpty(cinFieldName) && !this.getBoolean(noCinFieldName)) {
            this.setErrorForField(cinFieldName, tCommon('validator.cinUserDetails', {cinLabel, noCinLabel}));
        }
    }

    public cinBulkEdit(
        cinFieldName: KeysOfType<Values, string | null>,
        prefillNameFieldName: KeysOfType<Values, number | null>,
        cinLabel: string,
        prefillNameLabel: string,
    ): void {
        if (this.isFieldEmpty(cinFieldName) && this.isFieldEmpty(prefillNameFieldName)) {
            this.setErrorForField(cinFieldName, tCommon('validator.cinBulkEdit', {cinLabel, prefillNameLabel}));
        }
    }
}
