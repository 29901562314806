import {putAll, showSuccess, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {t} from 'translations';
import {showBeError} from 'utils/show-be-error';

import {getExportParams} from '../../utils/get-export-params';
import {CancelOrdersBillingByFilterAction, orderAction} from '../action';
import {orderApi} from '../api';
import {isActionForced} from './is-action-forced';
import {getOrderTableParams, getSelectedOrders} from './selectors';

const te = t('orders/ListActions');

function* execute({payload: {force, withoutSelected}}: CancelOrdersBillingByFilterAction): SagaIterator {
    const tableDataParams = yield* select(getOrderTableParams);
    const excludedOrderIds = withoutSelected ? yield* select(getSelectedOrders) : undefined;
    const exportParams = getExportParams(tableDataParams, force, excludedOrderIds);
    yield* put(orderAction.setListActionActive('cancelOrdersBilling', true));

    const response = yield* call(orderApi.cancelOrdersBillingByFilter, exportParams);

    if (response.isSuccess) {
        yield* put(orderAction.getOrders(opt({action: 'refresh'})));
        yield* put(showSuccess(te('cancelOrdersBilling'), t('orders/sagas')('success')));
    } else {
        if (force) {
            yield putAll(showBeError(response, te('cancelOrdersBilling')));
        } else {
            const isForced = yield* call(isActionForced, response);

            if (isForced) {
                yield* put(orderAction.cancelOrdersBillingByFilter(true, withoutSelected));
            }
        }
    }
    yield* put(orderAction.setListActionActive('cancelOrdersBilling', false));
    yield* put(orderAction.resetDownloadWithoutForceFailedResult());
}

export function* cancelOrdersBillingByFilterSaga(): SagaIterator {
    yield takeLatestF('order/CANCEL_BILLING_BY_FILTER', execute);
}
