import React, {FC, memo} from 'react';

import {EmailTemplateDetails} from 'api/gen/EmailTemplateDetails';
import {Modal} from 'common/layout';
import {useOurTranslation} from 'translations';

import {MailTemplateForm} from '../MailTemplateForm';

interface Props {
    visible: boolean;
    onSubmitEdit(mailTemplate: EmailTemplateDetails): void;
    onCancel(): void;
}

const MailTemplatesModalBase: FC<Props> = props => {
    const {visible, onSubmitEdit, onCancel} = props;

    const {t} = useOurTranslation('dials/mailTemplates');

    return (
        <Modal
            title={t('editTitle')}
            visible={visible}
            onCancel={onCancel}
            footer={null}
        >
            <MailTemplateForm
                onSubmit={onSubmitEdit}
                onCancel={onCancel}
                isEdit
            />
        </Modal>
    );
};

export const MailTemplatesModal = memo(MailTemplatesModalBase);
