import {classNames} from '@fl/cmsch-fe-library';
import React, {FC, memo, ReactNode} from 'react';

import styles from './styles.sass';

interface Props {
    fluid?: boolean;
    maxLgWidth?: boolean;
    maxXxlWidth?: boolean;
    children: ReactNode;
    testId?: string;
}

/**
 * Use `<Container />` with `.row-s` and `.col-s` to create a grid.
 */
const ContainerBase: FC<Props> = props => {
    const {
        fluid,
        maxLgWidth,
        maxXxlWidth,
        children,
        testId,
    } = props;

    const classes = classNames(
        styles.containerMargin,
        fluid || maxXxlWidth ? 'container-fluid' : 'container',
        maxLgWidth && styles.maxLgWidth,
        maxXxlWidth && styles.maxXxlWidth,
    );

    return (
        <div
            className={classes}
            data-test-id={testId}
        >
            {children}
        </div>
    );
};

export const Container = memo(ContainerBase);
