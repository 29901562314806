import {debounce} from 'lodash/fp';
import React, {FC, memo, useCallback, useMemo} from 'react';
import {Opt} from 'ts-opt';

import {OrderTypeItem} from 'api/gen/OrderTypeItem';
import {OrderTypeValidation} from 'api/gen/OrderTypeValidation';
import {Modal} from 'common/layout';
import {debounceTime} from 'constants/general';
import {useOurTranslation} from 'translations';

import {OrderTypeForm} from '../OrderTypeForm';

interface Props {
    visible: boolean;
    orderTypeIdBeingEdited: Opt<number>;
    onSubmitEdit(orderType: OrderTypeItem): void;
    onSubmitCreate(orderType: OrderTypeItem): void;
    onCancel(): void;
    validateOrderType(data: OrderTypeValidation): void;
}

const OrderTypesModalBase: FC<Props> = props => {
    const {visible, orderTypeIdBeingEdited, onSubmitEdit, onSubmitCreate, onCancel, validateOrderType} = props;

    const {t} = useOurTranslation('dials/orderTypes');

    const validateOrderTypeDebounced = useMemo(() =>
        debounce(debounceTime)(validateOrderType)
    , [validateOrderType]);

    const validateValue = useCallback((value: Opt<string>) => {
        const idToExclude = orderTypeIdBeingEdited.orNull();
        value.onSome(x => validateOrderTypeDebounced({value: x, idToExclude, code: null}));
    }, [orderTypeIdBeingEdited, validateOrderTypeDebounced]);

    const validateCode = useCallback((code: Opt<string>) => {
        const idToExclude = orderTypeIdBeingEdited.orNull();
        code.onSome(x => validateOrderTypeDebounced({value: null, idToExclude, code: x}));
    }, [orderTypeIdBeingEdited, validateOrderTypeDebounced]);

    return (
        <Modal
            title={orderTypeIdBeingEdited.isEmpty ? t('createTitle') : t('editTitle')}
            visible={visible}
            onCancel={onCancel}
            footer={null}
        >
            {orderTypeIdBeingEdited.isEmpty
                ? (
                    <OrderTypeForm
                        onSubmit={onSubmitCreate}
                        onChangeValue={validateValue}
                        onChangeCode={validateCode}
                        onCancel={onCancel}
                    />
                )
                : (
                    <OrderTypeForm
                        onSubmit={onSubmitEdit}
                        onChangeValue={validateValue}
                        onChangeCode={validateCode}
                        onCancel={onCancel}
                        isEdit
                    />
                )}
        </Modal>
    );
};

export const OrderTypesModal = memo(OrderTypesModalBase);
