/* eslint-disable max-lines */
import {Nullable, Options, ShowField, Button, FooterButtons} from '@fl/cmsch-fe-library';
import {debounce} from 'lodash/fp';
import moment from 'moment';
import React, {FC, memo, useCallback, useEffect, useMemo} from 'react';
import {opt, Opt, testRe} from 'ts-opt';

import {earTagRegexGen} from 'api/gen/EarTag';
import {lineRegistryRegexGen} from 'api/gen/LineRegistry';
import {SampleIdTrinity} from 'api/gen/SampleIdTrinity';
import {Ant} from 'common/ant';
import {debounceTime} from 'constants/general';
import {Panel} from 'layout';
import {TFunction, useOurTranslation} from 'translations';
import {useForm} from 'utils/forms';
import {getSexOptions} from 'utils/get-sex-options';

import {AddressFormSection, AddressFormSectionOuterProps} from '../AddressFormSection';
import {
    GenerateProtocolFormValues,
    generateProtocolInitialValues,
    generateProtocolFormName,
} from './generate-protocol-form-values';
import {validate} from './validations';

interface Props {
    sampleTypes: Options<number>;
    verificationOutcomeTypes: Options<number>;
    testResultTypes: Options<number>;
    fathersVerificationOutcome: Opt<number>;
    mothersVerificationOutcome: Opt<number>;
    parentsVerificationOutcome: Opt<number>;
    parentsVerificationError: Opt<string>;
    sampleId: Nullable<string>;
    fathersSampleId: Nullable<string>;
    mothersSampleId: Nullable<string>;
    laboratoryNumber: Nullable<string>;
    fathersLaboratoryNumber: string;
    mothersLaboratoryNumber: string;
    onCancel(): void;
    onVerifyPaternity(data: SampleIdTrinity): void;
    onRefreshMother(value: string): void;
    onRefreshFather(sampleId: Nullable<string>, registry: Nullable<string>): void;
    onResetValidationOutcomes(): void;
    onSubmit(values: GenerateProtocolFormValues): void;
}

// eslint-disable-next-line no-magic-numbers
const percentageWithTwoDecimals = (value: number): string => `${(value * 100).toFixed(2)} %`;

const isEarTagValid: (x: string) => boolean = testRe(earTagRegexGen());

const getWarning = (
    sampleId: Nullable<string>,
    mothersSampleId: Nullable<string>,
    fathersSampleId: Nullable<string>,
    t: TFunction<'orders/GenerateProtocolForm'>,
): string | undefined => {
    if (!sampleId || !isEarTagValid(sampleId)) {
        return t('verifyPaternityDisabledSampleId');
    } else if (!mothersSampleId || !isEarTagValid(mothersSampleId)) {
        return t('verifyPaternityDisabledMotSampleId');
    } else if (!fathersSampleId || !isEarTagValid(fathersSampleId)) {
        return t('verifyPaternityDisabledFatSampleId');
    } else {
        return undefined;
    }
};

// eslint-disable-next-line max-lines-per-function
const GenerateProtocolFormBase: FC<Props> = props => {
    const {
        sampleTypes,
        verificationOutcomeTypes,
        testResultTypes,
        fathersVerificationOutcome,
        mothersVerificationOutcome,
        parentsVerificationOutcome,
        parentsVerificationError,
        laboratoryNumber,
        sampleId,
        mothersSampleId,
        fathersSampleId,
        mothersLaboratoryNumber,
        fathersLaboratoryNumber,
        onSubmit,
        onCancel,
        onVerifyPaternity,
        onResetValidationOutcomes,
        onRefreshFather,
        onRefreshMother,
    } = props;

    const {t, tCommon} = useOurTranslation('orders/GenerateProtocolForm');

    const {Form, Fields, submitting, renderErrors} = useForm({
        form: generateProtocolFormName,
        initialValues: generateProtocolInitialValues,
        validate,
        onSubmit,
    });

    useEffect(() => {
        onResetValidationOutcomes();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const refreshFatherDebounced = useMemo(() =>
        debounce(debounceTime)(onRefreshFather)
    , [onRefreshFather]);

    const refreshMotherDebounced = useMemo(() =>
        debounce(debounceTime)(onRefreshMother)
    , [onRefreshMother]);

    const validToVerifyPaternity = Boolean(sampleId && isEarTagValid(sampleId)
        && fathersSampleId && isEarTagValid(fathersSampleId)
        && mothersSampleId && isEarTagValid(mothersSampleId));

    const verifyPaternity = useCallback(() => {
        if (validToVerifyPaternity && sampleId && fathersSampleId && mothersSampleId) {
            onVerifyPaternity({childSampleId: sampleId, fathersSampleId, mothersSampleId});
        }
    }, [validToVerifyPaternity, onVerifyPaternity, sampleId, fathersSampleId, mothersSampleId]);

    const refreshMother = useCallback((value: Opt<string>): void => {
        value.filter(isEarTagValid).onSome(refreshMotherDebounced);
    }, [refreshMotherDebounced]);

    const refreshFatherFromSampleId = useCallback((value: Opt<string>) => {
        value.filter(isEarTagValid).onSome(y => refreshFatherDebounced(y, null));
    }, [refreshFatherDebounced]);

    const refreshFatherFromRegistry = useCallback((value: Opt<string>) => {
        value.filter(testRe(lineRegistryRegexGen())).onSome(y => refreshFatherDebounced(null, y));
    }, [refreshFatherDebounced]);

    const AddressSectionField = Fields.genSection<'address', AddressFormSectionOuterProps>();

    const parentsVerificationOutcomeExists = parentsVerificationOutcome.isFull;

    return (
        <Panel
            title={t('title')}
            extraBottomSpace
            testId="generate-protocol-form-wrapper"
        >
            <Form>
                <div className="grid-form">
                    {renderErrors()}

                    <fieldset>
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <Fields.Input
                                    name="sampleId"
                                    label={tCommon('earTag')}
                                    placeholder={tCommon('earTagPlaceholder')}
                                    type="text"
                                    disabled
                                    isRequired
                                />
                            </div>

                            <div className="col-12 col-md-6">
                                <Fields.StringSelect
                                    name="sex"
                                    label={t('sex')}
                                    options={getSexOptions()}
                                    clearable={false}
                                    searchable={false}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 col-md-6">
                                <Fields.Input
                                    name="name"
                                    label={t('name')}
                                    type="text"
                                />
                            </div>

                            <div className="col-12 col-md-6">
                                <Fields.Input
                                    name="breed"
                                    label={t('breed')}
                                    type="text"
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 col-md-6">
                                <Fields.DateInput
                                    name="dateOfBirth"
                                    label={t('dateOfBirth')}
                                    placeholder={tCommon('dateOfBirthPlaceholder')}
                                    maxDate={moment().endOf('day')}
                                    clearable
                                />
                            </div>

                            <div className="col-12 col-md-6">
                                <Fields.Input
                                    name="registry"
                                    label={t('registry')}
                                    placeholder={tCommon('registryPlaceholder')}
                                    type="text"
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 col-md-6">
                                <Fields.NumberSelect
                                    name="sampleTypeId"
                                    label={t('sampleTypeId')}
                                    placeholder={t('sampleTypeIdPlaceholder')}
                                    options={sampleTypes}
                                    isRequired
                                    clearable={false}
                                    searchable={false}
                                />
                            </div>

                            <div className="col-12 col-md-6" >
                                <Fields.Checkbox
                                    name="displayLaboratoryNote"
                                    label={t('displayLaboratoryNote')}
                                    verticallyCenter
                                />
                            </div>
                        </div>
                    </fieldset>

                    <div className="row">
                        <div className="col-12 col-md-6">
                            <legend><h5>{t('motherTitle')}</h5></legend>

                            <div className="row">
                                <div className="col-12">
                                    <Fields.Input
                                        name="mothersSampleId"
                                        label={t('mothersSampleId')}
                                        placeholder={tCommon('earTagPlaceholder')}
                                        type="text"
                                        onFieldChange={refreshMother}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <ShowField
                                        label={t('mothersLaboratoryNumber')}
                                        showType="string"
                                        value={opt(mothersLaboratoryNumber)}
                                        boldLabel
                                        lowerDefaultBottomMargin
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <Fields.Input
                                        name="mothersBreed"
                                        label={t('mothersBreed')}
                                        type="text"
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <Fields.DateInput
                                        name="mothersDateOfBirth"
                                        label={t('mothersDateOfBirth')}
                                        placeholder={tCommon('dateOfBirthPlaceholder')}
                                        maxDate={moment().endOf('day')}
                                        clearable
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-6">
                            <legend><h5>{t('fatherTitle')}</h5></legend>

                            <div className="row">
                                <div className="col-12">
                                    <Fields.Input
                                        name="fathersSampleId"
                                        label={t('fathersSampleId')}
                                        placeholder={tCommon('earTagPlaceholder')}
                                        type="text"
                                        onFieldChange={refreshFatherFromSampleId}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <ShowField
                                        label={t('fathersLaboratoryNumber')}
                                        showType="string"
                                        value={opt(fathersLaboratoryNumber)}
                                        boldLabel
                                        lowerDefaultBottomMargin
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <Fields.Input
                                        name="fathersBreed"
                                        label={t('fathersBreed')}
                                        type="text"
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <Fields.Input
                                        name="fathersRegistry"
                                        label={t('fathersRegistry')}
                                        placeholder={tCommon('registryPlaceholder')}
                                        type="text"
                                        onFieldChange={refreshFatherFromRegistry}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <Fields.DateInput
                                        name="fathersDateOfBirth"
                                        label={t('fathersDateOfBirth')}
                                        placeholder={tCommon('dateOfBirthPlaceholder')}
                                        maxDate={moment().endOf('day')}
                                        clearable
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <fieldset className="mt-3">
                        <legend><h5>{t('parents')}</h5></legend>

                        <div className="row align-items-center mb-3">
                            <div className="offset-3 col-5">
                                <Button
                                    visuals="primary"
                                    onClick={verifyPaternity}
                                    disabled={!validToVerifyPaternity}
                                    tooltip={getWarning(sampleId, mothersSampleId, fathersSampleId, t)}
                                >
                                    {t('verifyPaternity')}
                                </Button>
                            </div>
                        </div>

                        <div className="row align-items-center">
                            <div className={parentsVerificationOutcomeExists ? 'col-3' : 'col-12 col-lg-3'}>
                                <b>{t('parentsVerificationOutcome')}</b>
                            </div>
                            <div className={parentsVerificationOutcomeExists ? 'col-9' : 'col-12 col-lg-9'}>
                                <div className="d-inline-block">
                                    {fathersVerificationOutcome.map(x => (
                                        <div
                                            className="d-flex justify-content-between"
                                            key={x}
                                        >
                                            <span className="mr-2">
                                                {t('fatherVerificationOutcome')}:
                                            </span>
                                            <span>
                                                {percentageWithTwoDecimals(x)}
                                            </span>
                                        </div>
                                    )).orNull()}
                                    {mothersVerificationOutcome.map(x => (
                                        <div
                                            className="d-flex justify-content-between"
                                            key={x}
                                        >
                                            <span className="mr-2">
                                                {t('motherVerificationOutcome')}:
                                            </span>
                                            <span>
                                                {percentageWithTwoDecimals(x)}
                                            </span>
                                        </div>
                                    )).orNull()}
                                    <div className="d-flex justify-content-between">
                                        {parentsVerificationOutcomeExists && (
                                            <span className="mr-2">
                                                {`${t('bothVerificationOutcome')}: `}
                                            </span>
                                        )}
                                        <span>
                                            {parentsVerificationError
                                                .alt(parentsVerificationOutcome.map(percentageWithTwoDecimals))
                                                .orElse('⌀')}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>

                    <fieldset className="mt-3">
                        <legend><h5>{t('verificationOutcomeTypeTitle')}</h5></legend>

                        <div className="row col-12 mb-3 radio-list">
                            <Ant.Row>
                                <Ant.Col span={12}>
                                    <Fields.NumberRadio
                                        name="verificationOutcomeTypeId"
                                        radioValue={-1}
                                        label={t('unselected')}
                                    />
                                </Ant.Col>
                                {verificationOutcomeTypes.map(verificationOutcome => (
                                    <Ant.Col
                                        span={12}
                                        key={verificationOutcome.value}
                                    >
                                        <Fields.NumberRadio
                                            name="verificationOutcomeTypeId"
                                            radioValue={verificationOutcome.value}
                                            label={verificationOutcome.label}
                                        />
                                    </Ant.Col>
                                ))}
                            </Ant.Row>
                        </div>
                    </fieldset>

                    <fieldset className="mt-3">
                        <legend><h5>{t('testResultTypeTitle')}</h5></legend>

                        <div className="row col-12 mb-3">
                            <Fields.CheckboxGroup
                                options={testResultTypes}
                                colSpan={{xs: 12}}
                                everyCheckboxMarginTop
                                name="testResultTypeIds"
                            />
                        </div>
                    </fieldset>

                    <fieldset className="mt-4">
                        <legend><h5>{t('userTitle')}</h5></legend>

                        <div className="row">
                            <div className="col-12">
                                <Fields.Input
                                    name="customerName"
                                    label={t('customerName')}
                                    type="text"
                                    isRequired
                                />
                            </div>
                        </div>

                        <AddressSectionField
                            name="address"
                            Component={AddressFormSection}
                            props={{legend: 'address', disabledAddress: false}}
                        />
                    </fieldset>

                    <FooterButtons
                        submitTitle={t('submit')}
                        submitting={submitting}
                        disabledSubmit={!laboratoryNumber}
                        submitTooltip={!laboratoryNumber ? tCommon('noLaboratoryNumber') : undefined}
                        onCancel={onCancel}
                    />
                </div>
            </Form>
        </Panel>
    );
};

export const GenerateProtocolForm = memo(GenerateProtocolFormBase);
