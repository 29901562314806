import {takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {put, call, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {formHelpers} from 'utils/forms';

import {CheckParentRegistryAction, orderAction} from '../action';

function* execute(action: CheckParentRegistryAction): SagaIterator {
    const {registry} = action.payload;

    const response = yield* call(Api.findAnimal, {sampleId: null, registry});
    yield* put(orderAction.isValidating(false));

    if (response.isSuccess) {
        const {data: {sampleId}} = response;
        yield* put(formHelpers.changeValueInSection('newOrder', 'animalDetails', 'fathersSampleId', sampleId));
    } else {
        const newOrderFormValues = yield* select(formHelpers.formValues('newOrder'));
        const sampleId = newOrderFormValues.prop('animalDetails').prop('fathersSampleId').orNull();
        yield* put(orderAction.validateAnimal(sampleId, registry, 'father'));
    }
}

export function* checkParentRegistrySaga(): SagaIterator {
    yield takeLatestF('order/CHECK_PARENT_REGISTRY', execute);
}
