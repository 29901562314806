import {beDatetimeFormat, dateFormat, Options, reformatDate, Button} from '@fl/cmsch-fe-library';
import {isEmpty} from 'lodash/fp';
import React, {FC, memo, useCallback} from 'react';

import {OrderOrderType} from 'api/gen/OrderOrderType';
import {Ant} from 'common/ant';
import {useOurTranslation} from 'translations';

const formatDatetimeAsDate = (value: string): string =>
    reformatDate(beDatetimeFormat, dateFormat, false)(value).orElse('');

interface Props {
    orderType: OrderOrderType;
    orderTypes: Options<number>;
    orderId: number;
    showFileLinks: boolean;
    downloadBillingBases(fileKey: string): void;
    deleteBillingBase(orderId: number, orderTypeId: number): void;
}

const gutter = 8;
const smallGutter = 4;

const OrderTypeBase: FC<Props> = props => {
    const {
        orderType,
        orderTypes,
        orderId,
        downloadBillingBases,
        showFileLinks,
        deleteBillingBase,
    } = props;

    const {t} = useOurTranslation('orders/OrderDetails');

    const {
        billingBasisCsvKey,
        billingBasisMeatAssociationCsvKey,
        billingBasisPdfKey,
        exportDateTime,
        orderTypeId,
    } = orderType;

    const downloadCsv = useCallback(() =>
        billingBasisCsvKey && downloadBillingBases(billingBasisCsvKey)
    , [billingBasisCsvKey, downloadBillingBases]);

    const downloadPdf = useCallback(() =>
        billingBasisPdfKey && downloadBillingBases(billingBasisPdfKey)
    , [billingBasisPdfKey, downloadBillingBases]);

    const downloadMeatCsv = useCallback(() =>
        billingBasisMeatAssociationCsvKey && downloadBillingBases(billingBasisMeatAssociationCsvKey)
    , [billingBasisMeatAssociationCsvKey, downloadBillingBases]);

    const onDelete = useCallback(() =>
        deleteBillingBase(orderId, orderTypeId)
    , [deleteBillingBase, orderId, orderTypeId]);

    const showCsv = billingBasisCsvKey && showFileLinks;
    const showPdf = billingBasisPdfKey && showFileLinks;
    const showMeatCsv = billingBasisMeatAssociationCsvKey && showFileLinks;

    if (isEmpty(orderTypes)) return null;

    return (
        <div
            className="col-12 px-0 pb-3"
            key={`${orderTypeId}${exportDateTime || ''}`}
        >
            <Ant.Row gutter={[0, smallGutter]}>
                <Ant.Col xs={24}>
                    {orderTypes.find(x => x.value === orderTypeId)
                        ?.label || t('unknownOrderType')}{' '}
                    {exportDateTime
                        && t('exportDate', {date: formatDatetimeAsDate(exportDateTime)})}
                </Ant.Col>
                {exportDateTime && (
                    <Ant.Col xs={24}>
                        <Ant.Row gutter={[gutter, gutter]}>
                            {showCsv && (
                                <Ant.Col
                                    xs={12}
                                    sm={6}
                                >
                                    <Button
                                        visuals="primary"
                                        icon="downloadOutlined"
                                        onClick={downloadCsv}
                                        block
                                    >
                                        {t('downloadBillingBaseCsv')}
                                    </Button>
                                </Ant.Col>
                            )}
                            {showPdf && (
                                <Ant.Col
                                    xs={12}
                                    sm={6}
                                >
                                    <Button
                                        visuals="primary"
                                        icon="downloadOutlined"
                                        onClick={downloadPdf}
                                        block
                                    >
                                        {t('downloadBillingBasePdf')}
                                    </Button>
                                </Ant.Col>
                            )}
                            {showMeatCsv && (
                                <Ant.Col
                                    xs={12}
                                    sm={8}
                                >
                                    <Button
                                        visuals="primary"
                                        icon="downloadOutlined"
                                        onClick={downloadMeatCsv}
                                        block
                                    >
                                        {t('downloadBillingBaseMeatCsv')}
                                    </Button>
                                </Ant.Col>
                            )}
                            {showFileLinks && (
                                <Ant.Col
                                    xs={12}
                                    sm={4}
                                >
                                    <Button
                                        visuals="primary"
                                        danger
                                        icon="deleteOutlined"
                                        tooltip={t('deleteBillingBase')}
                                        onClick={onDelete}
                                        block
                                    />
                                </Ant.Col>
                            )}
                        </Ant.Row>
                    </Ant.Col>
                )}
            </Ant.Row>
        </div>
    );
};

export const OrderType = memo(OrderTypeBase);
