import {setItem, toggleItem} from '@fl/cmsch-fe-library';
import * as O from 'optics-ts';
import {none, opt, pipe} from 'ts-opt';

import {Action} from 'app/actions';

import {initialOrderState, OrderState} from './state';

const orderStateO = O.optic<OrderState>();

// eslint-disable-next-line complexity, max-lines-per-function
export const orderReducer = (state: OrderState = initialOrderState, action: Action): OrderState => {
    switch (action.type) {
        case 'order/SET_MODAL_VISIBILITY': {
            const {id, visible} = action.payload;

            return O.modify(orderStateO.prop('modalsVisibility'))(setItem(visible)(id))(state);
        }

        case 'order/TOGGLE_MODAL_VISIBILITY': {
            const {id} = action.payload;

            return O.modify(orderStateO.prop('modalsVisibility'))(toggleItem(id))(state);
        }

        case 'order/SET_LIST_ACTION_ACTIVE': {
            const {id, active} = action.payload;

            return O.modify(orderStateO.prop('listActionsActive'))(setItem(active)(id))(state);
        }

        case 'order/GET_ORDERS_SUCCESS': {
            const {ordersPage} = action.payload;

            return O.set(orderStateO.prop('ordersPage'))(ordersPage)(state);
        }

        case 'order/DESELECT_ALL': {
            return O.set(orderStateO.prop('selected'))([])(state);
        }

        case 'order/TOGGLE_SELECT': {
            const {ids} = action.payload;

            return O.set(orderStateO.prop('selected'))(ids)(state);
        }

        case 'order/SET_USER_DETAILS_FROM_CIN': {
            const {data} = action.payload;

            return pipe(
                state,
                O.set(orderStateO.prop('userDetailsFromCIN'))(opt(data)),
                O.set(orderStateO.prop('cinIsFetching'))(false),
            );
        }

        case 'order/GET_ORDER_SUCCESS': {
            const {order} = action.payload;

            return O.set(orderStateO.prop('current'))(opt(order))(state);
        }

        case 'order/GET_DATA_FOR_PROTOCOL_SUCCESS': {
            const {order} = action.payload;

            return O.set(orderStateO.prop('protocolData'))(opt(order))(state);
        }

        case 'order/VERIFY_PATERNITY_SUCCESS': {
            const {probabilityBoth, probabilityFather, probabilityMother} = action.payload;

            return pipe(
                state,
                O.set(orderStateO.prop('parentsVerificationOutcome'))(opt(probabilityBoth)),
                O.set(orderStateO.prop('fathersVerificationOutcome'))(opt(probabilityFather)),
                O.set(orderStateO.prop('mothersVerificationOutcome'))(opt(probabilityMother)),
                O.set(orderStateO.prop('parentsVerificationError'))(none),
            );
        }

        case 'order/VERIFY_PATERNITY_ERROR': {
            const {parentsVerificationError} = action.payload;

            return pipe(
                state,
                O.set(orderStateO.prop('parentsVerificationOutcome'))(none),
                O.set(orderStateO.prop('fathersVerificationOutcome'))(none),
                O.set(orderStateO.prop('mothersVerificationOutcome'))(none),
                O.set(orderStateO.prop('parentsVerificationError'))(opt(parentsVerificationError)),
            );
        }

        case 'order/RESET_VALIDATION_OUTCOMES': {
            return pipe(
                state,
                O.set(orderStateO.prop('parentsVerificationOutcome'))(none),
                O.set(orderStateO.prop('fathersVerificationOutcome'))(none),
                O.set(orderStateO.prop('mothersVerificationOutcome'))(none),
            );
        }

        case 'order/GET_LABORATORY_NUMBER_BY_SAMPLE_ID_SUCCESS': {
            const {parent, laboratoryNumber} = action.payload;

            return pipe(
                state,
                O.set(orderStateO.prop(parent === 'mother' ? 'motherAlreadyAnalyzed' : 'fatherAlreadyAnalyzed'))(
                    laboratoryNumber !== null,
                ),
            );
        }

        case 'order/RESET_PARENTS_ALREADY_ANALYZED': {
            return pipe(
                state,
                O.set(orderStateO.prop('motherAlreadyAnalyzed'))(false),
                O.set(orderStateO.prop('fatherAlreadyAnalyzed'))(false),
            );
        }

        case 'order/GET_ORDERS_CUSTOMERS_SUCCESS': {
            const {customers} = action.payload;

            return O.set(orderStateO.prop('customers'))(opt(customers))(state);
        }

        case 'order/SET_EXPORT_BILLING_BASE_MODE': {
            const {mode} = action.payload;

            return O.set(orderStateO.prop('exportBillingBaseMode'))(opt(mode))(state);
        }

        case 'order/BILLING_BASE_EXPORT_SUCCESS': {
            const {billingBaseExport} = action.payload;

            return O.set(orderStateO.prop('billingBaseExport'))(opt(billingBaseExport))(state);
        }

        case 'order/BILLING_BASE_EXPORT_CLOSE': {
            return O.set(orderStateO.prop('billingBaseExport'))(none)(state);
        }

        case 'order/GET_BREEDERS_SUCCESS': {
            const {breeders} = action.payload;

            return O.set(orderStateO.prop('breeders'))(opt(breeders))(state);
        }

        case 'order/DOWNLOAD_WITHOUT_FORCE_FAILED': {
            const {errors} = action.payload;

            return O.set(orderStateO.prop('downloadWithoutForceErrors'))(errors)(state);
        }

        case 'order/DOWNLOAD_WITHOUT_FORCE_RESOLVE': {
            const {forceDownloadConfirmed} = action.payload;

            return O.set(orderStateO.prop('forceDownloadConfirmed'))(forceDownloadConfirmed)(state);
        }

        case 'order/RESET_DOWNLOAD_WITHOUT_FORCE_RESULT': {
            return pipe(
                state,
                O.set(orderStateO.prop('downloadWithoutForceErrors'))([]),
                O.set(orderStateO.prop('forceDownloadConfirmed'))(false),
            );
        }

        case 'order/SEARCH_ORDERS_SUCCESS': {
            const {foundOrders} = action.payload;

            return O.set(orderStateO.prop('foundOrders'))(opt(foundOrders))(state);
        }

        case 'order/RESET_ORDERS_SEARCH': {
            return O.set(orderStateO.prop('foundOrders'))(none)(state);
        }

        case 'order/SET_BULK_EDIT_ORDER_IDS': {
            const {selected} = state;

            return O.set(orderStateO.prop('bulkEditOrderIds'))(selected)(state);
        }

        case 'order/RESET_BULK_EDIT_ORDER_IDS': {
            return O.set(orderStateO.prop('bulkEditOrderIds'))([])(state);
        }

        case 'order/SET_BULK_EDIT_ORDER_FILTER': {
            const {filter, withoutSelected} = action.payload;

            return pipe(
                state,
                O.set(orderStateO.prop('bulkEditOrderFilter'))(opt(filter)),
                O.set(orderStateO.prop('bulkEditOrderWithoutSelected'))(withoutSelected),
            );
        }

        case 'order/RESET_BULK_EDIT_ORDER_FILTER': {
            return pipe(
                state,
                O.set(orderStateO.prop('bulkEditOrderFilter'))(none),
                O.set(orderStateO.prop('bulkEditOrderWithoutSelected'))(false),
            );
        }

        case 'order/SET_GENERATE_SAMPLE_SHEET_MODE': {
            const {mode} = action.payload;

            return O.set(orderStateO.prop('generateSampleSheetMode'))(opt(mode))(state);
        }

        case 'order/ANIMALS_SEARCH_SUCCESS': {
            const {animal} = action.payload;

            return O.set(orderStateO.prop('foundAnimal'))(opt(animal))(state);
        }

        case 'order/SET_IS_NEW_ORDER_ANIMAL_VALID': {
            const {valid} = action.payload;

            return O.set(orderStateO.prop('isNewOrderAnimalValid'))(valid)(state);
        }

        case 'order/GET_DATA_FOR_GENOTYPING_REQUEST_SUCCESS': {
            const {data} = action.payload;

            return O.set(orderStateO.prop('requestData'))(opt(data))(state);
        }

        case 'order/SET_NEW_ORDER_EAR_NUMBER_WARNING': {
            const {warning} = action.payload;

            return O.set(orderStateO.prop('newOrderEarNumberWarning'))(opt(warning))(state);
        }

        case 'order/GET_USER_LAST_ORDER':
            return O.set(orderStateO.prop('cinIsFetching'))(true)(state);

        case 'order/GET_USER_LAST_ORDER_SUCCESS':
            return O.set(orderStateO.prop('cinIsFetching'))(false)(state);

        case 'order/CLEAR_CURRENT':
            return pipe(
                state,
                O.set(orderStateO.prop('current'))(initialOrderState.current),
                O.set(orderStateO.prop('protocolData'))(initialOrderState.protocolData),
            );

        case 'order/ADD_ANIMAL_TO_BULK_ORDER_ANIMAL_DETAILS':
        case 'order/CLEAR_BULK_ORDER_MODAL_DATA':
            return O.pipe(
                state,
                O.set(orderStateO.prop('isNewOrderAnimalValid'))(false),
                O.set(orderStateO.prop('newOrderEarNumberWarning'))(none),
            );
        case 'order/EDIT_ANIMAL_DETAILS':
            return O.pipe(
                state,
                O.set(orderStateO.prop('isNewOrderAnimalValid'))(true),
                O.set(orderStateO.prop('isAnimalDetailEditing'))(true),
            );

        case 'order/RESET_IS_EDITING':
            return O.set(orderStateO.prop('isAnimalDetailEditing'))(false)(state);

        case 'order/SET_VALIDATING':
            return O.set(orderStateO.prop('isValidating'))(action.payload.isValidating)(state);

        case 'order/SHOW_CONFIRM_DIALOG': {
            const {data} = action.payload;

            return O.set(orderStateO.prop('confirmDialog'))(opt(data))(state);
        }

        case 'order/HIDE_CONFIRM_DIALOG':
            return O.set(orderStateO.prop('confirmDialog'))(none)(state);

        default:
            return state;
    }
};
