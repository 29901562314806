import {classNames} from '@fl/cmsch-fe-library';
import React, {FC, memo, ReactNode} from 'react';

import {SvgIcon} from 'icons';

import styles from './styles.sass';

import cowHeadSvg from './cowHead.svg';

interface Props {
    title: string;
    fluidItemsLowBreakPoints?: boolean;
    horizontal?: boolean;
    children: ReactNode;
    indentBottom?: boolean;
    sticky?: boolean;
}

const SideMenuBase: FC<Props> = props => {
    const {
        title,
        children,
        fluidItemsLowBreakPoints,
        indentBottom,
        horizontal,
        sticky,
    } = props;

    const navClasses = fluidItemsLowBreakPoints ? styles.fluidLowColumnHigh : styles.singleColumn;
    const sideMenuProps = classNames(
        'card',
        'bg-secondary',
        styles.sideMenu,
        indentBottom ? 'mb-3 w-100' : '',
        sticky && [styles.sticky, 'mt-2'],
    );

    return (
        <div
            className={sideMenuProps}
            data-test-id="side-menu"
        >
            <div className="card-body p-3">
                <h5 className="card-title pb-2 d-flex justify-content-between align-items-center">
                    {title}
                    <SvgIcon icon={cowHeadSvg} />
                </h5>
                <nav className={classNames(styles.nav, 'nav', !horizontal ? navClasses : styles.gap)}>
                    {children}
                </nav>
            </div>
        </div>
    );
};

export const SideMenu = memo(SideMenuBase);
