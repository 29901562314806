// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// An e-mail address.
// Example: "john.doe@example.org"

export const plemdatEmailMinLength = 1;
export const plemdatEmailMaxLength = 255;

export const PlemdatEmailSchema = t.refinement(t.string, n => n.length >= plemdatEmailMinLength && n.length <= plemdatEmailMaxLength, 'PlemdatEmail');

export type PlemdatEmail = string;
