// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// A zip code
// Example: "25209"

export const zipNumberMinLength = 1;
export const zipNumberMaxLength = 255;

export const ZipNumberSchema = t.refinement(t.string, n => n.length >= zipNumberMinLength && n.length <= zipNumberMaxLength, 'ZipNumber');

export type ZipNumber = string;
