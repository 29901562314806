import {downloadFileFromResponse, putAll, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'translations';
import {showBeError} from 'utils/show-be-error';

import {ExportByAssociationByIdsAction, orderAction} from '../action';
import {getSelectedOrders} from './selectors';

function* execute(_action: ExportByAssociationByIdsAction): SagaIterator {
    const orderIds = yield* select(getSelectedOrders);
    yield* put(orderAction.setListActionActive('exportOrdersByAssociation', true));

    const response = yield* call(Api.markForSubsidyAndExportSubset, {orderIds});

    if (response.isSuccess) {
        yield* call(downloadFileFromResponse, response);
        yield* put(orderAction.getOrders(opt({action: 'refresh'})));
    } else {
        yield putAll(showBeError(response, t('orders/ListActions')('exportOrdersByAssociation')));
    }
    yield* put(orderAction.setListActionActive('exportOrdersByAssociation', false));
}

export function* exportByAssociationByIdsSaga(): SagaIterator {
    yield takeLatestF('order/EXPORT_BY_ASSOCIATION_BY_IDS', execute);
}
