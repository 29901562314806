import {createOrderTypeSaga} from './create-order-type-saga';
import {createSampleTypeSaga} from './create-sample-type-saga';
import {createTestResultSaga} from './create-test-result-saga';
import {createVerificationOutcomeSaga} from './create-verification-outcome-saga';
import {getBreederInfoConfigurationSaga} from './get-breeder-info-configuration-saga';
import {getBreederInfoSaga} from './get-breeder-info-saga';
import {getMailTemplatesSaga} from './get-mail-templates-saga';
import {getOrderTypesSaga} from './get-order-types-saga';
import {getSampleTypesSaga} from './get-sample-types-saga';
import {getTestResultsSaga} from './get-test-results-saga';
import {getVerificationOutcomesSaga} from './get-verification-outomes-saga';
import {hideSideBoxSaga} from './hide-breeder-side-box-saga';
import {saveBreederInfoSaga} from './save-breeder-info-saga';
import {updateMailTemplateSaga} from './update-mail-template-saga';
import {updateOrderTypeSaga} from './update-order-type-saga';
import {updateSampleTypeSaga} from './update-sample-type-saga';
import {updateTestResultSaga} from './update-test-result-saga';
import {updateVerificationOutcomeSaga} from './update-verification-outcome-saga';
import {validateOrderTypeSaga} from './validate-order-type-saga';
import {validateSampleTypeSaga} from './validate-sample-type-saga';
import {validateTestResultTypeSaga} from './validate-test-result-type-saga';
import {validateVerificationOutcomeTypeSaga} from './validate-verification-outcome-type-saga';

export const dialsSagas = [
    getSampleTypesSaga,
    updateSampleTypeSaga,
    createSampleTypeSaga,
    getOrderTypesSaga,
    updateOrderTypeSaga,
    createOrderTypeSaga,
    getVerificationOutcomesSaga,
    createVerificationOutcomeSaga,
    updateVerificationOutcomeSaga,
    getTestResultsSaga,
    createTestResultSaga,
    updateTestResultSaga,
    getMailTemplatesSaga,
    updateMailTemplateSaga,
    saveBreederInfoSaga,
    getBreederInfoSaga,
    getBreederInfoConfigurationSaga,
    hideSideBoxSaga,
    validateOrderTypeSaga,
    validateSampleTypeSaga,
    validateTestResultTypeSaga,
    validateVerificationOutcomeTypeSaga,
];
