import {Button} from '@fl/cmsch-fe-library';
import React, {Fragment, FC, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {Modal} from 'common/layout';
import {useOurTranslation} from 'translations';

import {orderAction, simpleOrdersSelector} from '../../model';

export const ConfirmDialog: FC = _ => {
    const {t} = useOurTranslation('common');
    const dialogData = useSelector(simpleOrdersSelector.confirmDialog);

    const dispatch = useDispatch();
    const handleConfirm = useCallback(() => {
        dialogData.prop('onConfirm').onFunc();
        dispatch(orderAction.hideConfirmDialog());
    }, [dispatch, dialogData]);
    const handleCancel = useCallback(() => {
        dispatch(orderAction.hideConfirmDialog());
    }, [dispatch]);

    const footer = (
        <Fragment>
            <Button onClick={handleCancel}>
                {t('cancel')}
            </Button>
            <Button
                visuals="primary"
                onClick={handleConfirm}
            >
                {t('yes')}
            </Button>
        </Fragment>
    );

    return dialogData.caseOf(
        ({title, message}) => (
            <Modal
                title={title ?? ''}
                visible
                onCancel={handleCancel}
                footer={footer}
            >
                {message}
            </Modal>
        ), () => null,
    );
};
