export const parentSearchTranslations = {
    CS: {
        'parentSearch/List': {
            title: 'Nalezení rodiče',
            results: 'Výsledky',
        },
        'parentSearch/Form': {
            secondParentData: 'Pro nalezení druhého rodiče vyplňte prosím následující data',
            unknown: 'Neznámý',
            potentialParent: 'Potenciální rodič',
            potentialSecondParents: 'Potencionální rodiče',
            atLeastOneParent: 'Musí být vyplněn alespoň 1 rodič.',
            calf: 'Tele',
            parent: 'Rodič',
        },
        'parentSearch/Table': {
            numOfProcessedGenes: 'Počet zpracovaných genů',
            probability: 'Pravděpodobnost',
            showAnimalDetail: 'Zobrazit detail zvířete',
        },
    },
    EN: {
        'parentSearch/List': {
            title: 'Parent search',
            results: 'Results',
        },
        'parentSearch/Form': {
            secondParentData: 'To find the other parent, please fill in the following data',
            unknown: 'Unknown',
            potentialParent: 'Potential parent',
            potentialSecondParents: 'Potential second parents',
            atLeastOneParent: 'At least 1 parent must be filled out.',
            calf: 'Calf',
            parent: 'Parent',
        },
        'parentSearch/Table': {
            numOfProcessedGenes: 'Počet zpracovaných genů',
            probability: 'Pravděpodobnost',
            showAnimalDetail: 'Show animal detail',
        },
    },
};
