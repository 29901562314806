import {takeLatestF, putAll, extractFormErrorsFromResponse} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {put, call} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'translations';
import {formHelpers} from 'utils/forms';
import {showBeError} from 'utils/show-be-error';

import {sampleTypeFormName} from '../../components/SampleTypeForm/sample-type-form-values';
import {CreateSampleTypeAction, dialsAction} from '../action';

function* execute(action: CreateSampleTypeAction): SagaIterator {
    const {sampleType} = action.payload;

    yield* put(formHelpers.startSubmit(sampleTypeFormName));

    const response = yield* call(Api.createSampleType, {value: sampleType.value});

    if (response.isSuccess) {
        yield* put(dialsAction.getSampleTypes());
        yield* put(dialsAction.setModalVisibility('sampleType', false));
        yield* put(formHelpers.stopSubmit(sampleTypeFormName));
    } else {
        yield* put(formHelpers.stopSubmit(sampleTypeFormName, extractFormErrorsFromResponse(response)));
        yield putAll(showBeError(response, t('dials/sampleTypes')('createNew')));
    }
}

export function* createSampleTypeSaga(): SagaIterator {
    yield takeLatestF('dials/CREATE_SAMPLE_TYPE', execute);
}
