import {ErrorResponse, ErrorsFromBE, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {put, call, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {formHelpers} from 'utils/forms';
import {Errors} from 'utils/validator';

import {OrderTypeFormValues} from '../../components/OrderTypeForm/order-type-form-values';
import {ValidateOrderTypeAction} from '../action';

function buildFormErrors(
    response: ErrorResponse,
    changedKey: keyof OrderTypeFormValues,
    oldErrors: Errors<OrderTypeFormValues>,
): Errors<OrderTypeFormValues> {
    const errors = response.data as ErrorsFromBE;

    return {
        value: oldErrors.value,
        code: oldErrors.code,
        [changedKey]: errors[0].message,
    };
}

function* execute(action: ValidateOrderTypeAction): SagaIterator {
    const {data} = action.payload;
    const {value, idToExclude} = data;
    const formName = idToExclude === null ? 'createOrderType' : 'editOrderType';

    const response = yield* call(Api.validateOrderType, data);

    const asyncErrors = yield* select(formHelpers.getAsyncErrors(formName));
    const errors: Errors<OrderTypeFormValues> = {
        value: asyncErrors.prop('value').map(String).orUndef(),
        code: asyncErrors.prop('code').map(String).orUndef(),
    };

    if (response.isSuccess) {
        yield* put(formHelpers.stopAsyncValidation(formName, errors));
    } else {
        const changedKey = value ? 'value' : 'code';
        yield* put(formHelpers.stopAsyncValidation(formName, buildFormErrors(response, changedKey, errors)));
    }
}

export function* validateOrderTypeSaga(): SagaIterator {
    yield takeLatestF('dials/VALIDATE_ORDER_TYPE', execute);
}
