import {Button} from '@fl/cmsch-fe-library';
import React, {FC, memo, ReactNode} from 'react';

import {Ant} from 'common/ant';
import {doubleGutter, gutter} from 'constants/general';
import {Panel, PanelTitleWithImage} from 'layout';
import {useOurTranslation} from 'translations';
import {useForm} from 'utils/forms';

import {PaternityCheckFormValues, paternityCheckFormName} from './paternity-check-form-values';
import {validate} from './validations';

import calfSvg from './calf.svg';
import parentSvg from './parent.svg';

const initialValues: PaternityCheckFormValues = {
    childSampleId: null,
    parentSampleId: null,
};

const calfTitle = (title: string): ReactNode => (
    <PanelTitleWithImage
        title={title}
        image={calfSvg}
    />
);
const parentTitle = (title: string): ReactNode => (
    <PanelTitleWithImage
        title={title}
        image={parentSvg}
    />
);

interface Props {
    onReset(): void;
    onSubmit(value: PaternityCheckFormValues): void;
}

const PaternityCheckFormBase: FC<Props> = ({onSubmit, onReset}) => {
    const {t, tCommon} = useOurTranslation('paternityCheck/paternityCheckForm');

    const {Form, Fields, pristine, submitting, valid} = useForm({
        form: paternityCheckFormName,
        initialValues,
        destroyOnUnmount: true,
        validate,
        onSubmit,
    });

    return (
        <Form>
            <Ant.Row
                gutter={[gutter, doubleGutter]}
                justify="end"
            >
                <Ant.Col xs={24}>
                    {t('text')}
                </Ant.Col>
                <Ant.Col
                    xs={24}
                    md={12}
                >
                    <Panel title={calfTitle(t('calfNumber'))}>
                        <Fields.Input
                            name="childSampleId"
                            label={tCommon('earTag')}
                            type="text"
                            placeholder={tCommon('earTagPlaceholder')}
                            disabled={submitting}
                            isRequired
                        />
                    </Panel>
                </Ant.Col>
                <Ant.Col
                    xs={24}
                    md={12}
                >
                    <Panel title={parentTitle(t('parentNumber'))}>
                        <Fields.Input
                            name="parentSampleId"
                            label={tCommon('earTag')}
                            type="text"
                            placeholder={tCommon('earTagPlaceholder')}
                            disabled={submitting}
                            isRequired
                        />
                    </Panel>
                </Ant.Col>
                <Ant.Col
                    xs={24}
                    md={6}
                >
                    <Button
                        disabled={!valid || pristine || submitting}
                        type="submit"
                        visuals="primary"
                        icon="searchOutlined"
                        block
                        loading={submitting}
                    >
                        {t('checkButton')}
                    </Button>
                </Ant.Col>
                <Ant.Col
                    xs={24}
                    md={6}
                >
                    <Button
                        disabled={pristine || submitting}
                        type="button"
                        visuals="default"
                        icon="deleteOutlined"
                        block
                        loading={submitting}
                        onClick={onReset}
                    >
                        {tCommon('resetButton')}
                    </Button>
                </Ant.Col>
            </Ant.Row>
        </Form>
    );
};

export const PaternityCheckForm = memo(PaternityCheckFormBase);
