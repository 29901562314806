import {Nullable} from '@fl/cmsch-fe-library';
import React, {Fragment, FC, memo} from 'react';

import {Address} from 'api/gen/Address';

interface Props {
    data: Nullable<Address>;
}

const AddressViewBase: FC<Props> = props => {
    const {data} = props;

    if (!data) {
        return null;
    }

    return (
        <Fragment>
            <div>
                {data.street}
            </div>
            <div>
                {data.city}
            </div>
            <div>
                {data.zip}
            </div>
        </Fragment>
    );
};

export const AddressView = memo(AddressViewBase);
