import {takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {put, call} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';

import {orderAction, RefreshFatherAction} from '../action';

function* execute(action: RefreshFatherAction): SagaIterator {
    const {sampleId, registry} = action.payload;

    if (!sampleId && !registry) {
        return;
    }

    const response = yield* call(Api.findAnimal, {sampleId, registry});

    if (response.isSuccess) {
        yield* put(orderAction.refreshFatherSuccess(response.data));
    }
}

export function* refreshFatherSaga(): SagaIterator {
    yield takeLatestF('order/REFRESH_FATHER', execute);
}
