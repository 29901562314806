import {classNames, Button} from '@fl/cmsch-fe-library';
import bowser from 'bowser';
import React, {PureComponent, ReactNode, MouseEvent, KeyboardEvent} from 'react';

import {SvgIcon} from 'icons';
import {TFunction} from 'translations';

import styles from './styles.sass';

import chevronsRightSvg from './chevronRight.svg';

interface Props {
    caption: string;
    text: string;

    t: TFunction<'dials/breederInfo'>;
    onHide(): void;
}

interface State {
    showSidebox: boolean;
}

export class Sidebox extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            showSidebox: false,
        };
    }

    private get isOnMobileDevice(): boolean {
        return bowser.mobile || bowser.tablet;
    }

    public componentDidMount(): void {
        if (this.isOnMobileDevice) {
            window.addEventListener('click', this.hideSidebox);
        }
    }

    public componentWillUnmount(): void {
        window.removeEventListener('click', this.hideSidebox);
    }

    public render(): ReactNode {
        const {caption, text, t, onHide} = this.props;
        const {showSidebox} = this.state;

        return (
            <div
                className={classNames(styles.sidebox, showSidebox && styles.showSidebox)}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
            >
                <div className={styles.caption}>
                    <div
                        tabIndex={-1}
                        role="button"
                        className={styles.captionRotate}
                        onClick={this.handleClick}
                        onKeyDown={this.handleKeyDown}
                    >
                        <span className="mb-2">
                            {caption}
                        </span>
                        <SvgIcon
                            icon={chevronsRightSvg}
                            large
                        />
                    </div>
                </div>
                <div className={styles.text}>
                    {/* eslint-disable-next-line react/no-danger,@typescript-eslint/naming-convention */}
                    <div dangerouslySetInnerHTML={{__html: text}} />
                    <div className="text-right">
                        <Button
                            visuals="primary"
                            size="middle"
                            onClick={onHide}
                        >
                            {t('hide')}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }

    private readonly hideSidebox = (): void => {
        this.setState({showSidebox: false});
    };

    private readonly showSidebox = (): void => {
        this.setState({showSidebox: true});
    };

    private readonly toggleSidebox = (): void => {
        const {showSidebox} = this.state;

        this.setState({showSidebox: !showSidebox});
    };

    private readonly handleClick = (event: MouseEvent): void => {
        if (this.isOnMobileDevice) {
            this.toggleSidebox();
            event.stopPropagation();
        }
    };

    private readonly handleKeyDown = (event: KeyboardEvent): void => {
        if (event.key === 'Enter' && this.isOnMobileDevice) {
            this.toggleSidebox();
            event.stopPropagation();
        }
    };

    private readonly handleMouseEnter = (): void => {
        if (!this.isOnMobileDevice) {
            this.showSidebox();
        }
    };

    private readonly handleMouseLeave = (): void => {
        if (!this.isOnMobileDevice) {
            this.hideSidebox();
        }
    };
}
