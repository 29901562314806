import {ActionSettings, Table, TableColumn} from '@fl/cmsch-fe-library';
import React, {FC, memo, useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {isEmpty} from 'ts-opt';

import {Barcode} from 'api/gen/Barcode';
import {Breed} from 'api/gen/Breed';
import {Date} from 'api/gen/Date';
import {GlobalEarTag} from 'api/gen/GlobalEarTag';
import {LineRegistry} from 'api/gen/LineRegistry';
import {useOurTranslation} from 'translations';
import {getSexOptions} from 'utils/get-sex-options';

import {orderAction} from '../../model/action';
import {animalsDetailsTableRow} from '../../model/selector';

interface Props {
    isSummary?: boolean;
    handleModalOpen?(open: boolean): void;
}

export interface AnimalInfoRow {
    id: number;
    animal: GlobalEarTag | null;
    barcode: Barcode | null;
    sex: string | null;
    breed: Breed | null;
    dob: Date | null;
    fatherLinReg: LineRegistry | null;
    motherEarTag: LineRegistry | null;
}

const SelectedAnimalsTableBase: FC<Props> = ({isSummary, handleModalOpen}) => {
    const {t} = useOurTranslation('orders/AnimalDetailsForm');
    const {t: td} = useOurTranslation('orders/newBulkOrder');
    const dispatch = useDispatch();

    const columns: Array<TableColumn<AnimalInfoRow>> = useMemo(() => [
        {
            field: 'animal',
            type: 'string',
            width: 127,
            column: td('animal'),
            tooltip: td('animalTooltip'),
            disableFilter: true,
        },
        {
            field: 'barcode',
            type: 'string',
            width: 85,
            column: td('barcode'),
            tooltip: td('barcodeTooltip'),
            disableFilter: true,
        },
        {
            field: 'sex',
            type: 'string',
            width: 80,
            options: getSexOptions(),
            column: t('sex'),
            disableFilter: true,
        },
        {
            field: 'breed',
            type: 'string',
            width: 85,
            column: td('breed'),
            tooltip: td('breedTooltip'),
            disableFilter: true,
        },
        {
            field: 'dob',
            type: 'date',
            dateFormat: 'date',
            width: 100,
            column: td('dob'),
            tooltip: td('dobTooltip'),
            disableFilter: true,
        },
        {
            field: 'fatherLinReg',
            type: 'string',
            width: 100,
            column: td('fathersRegistry'),
            tooltip: td('fathersRegistryTooltip'),
            disableFilter: true,
        },
        {
            field: 'motherEarTag',
            type: 'string',
            width: 127,
            column: td('motherSampleId'),
            tooltip: td('motherSampleIdTooltip'),
            disableFilter: true,
        },
    ], [t, td]);

    const animalsDetails = useSelector(animalsDetailsTableRow);

    const handleRowRemove = useCallback((_id: number, record: AnimalInfoRow) => {
        dispatch(orderAction.removeAnimalToBulkOrderAnimalsDetails(record));
    }, [dispatch]);

    const handleUpdateAnimal = useCallback((_rowId: number, record: AnimalInfoRow) => {
        dispatch(orderAction.activateEditAnimalDetailsForm(record));
        handleModalOpen?.(true);
    }, [dispatch, handleModalOpen]);

    const actionSettings = useMemo(
        (): ActionSettings<AnimalInfoRow> => ({
            onRowRemove: handleRowRemove,
            onEdit: handleUpdateAnimal,
            removeOnRight: true,
        }), [handleRowRemove, handleUpdateAnimal]);
    const isTableEmpty = isEmpty(animalsDetails);
    const showActionSettings = !isTableEmpty && !isSummary;

    return (
        <Table
            columns={columns}
            actionSettings={showActionSettings ? actionSettings : undefined}
            rows={animalsDetails}
            tableId="selectedAnimalsPreview"
            rowActionsOnRight={showActionSettings}
            size="small"
            scroll={{x: true}}
        />
    );
};

export const SelectedAnimalsTable = memo(SelectedAnimalsTableBase);
