import {TableDataRequest} from '@fl/cmsch-fe-library';
import {Opt} from 'ts-opt';

import {AnimalDetails} from 'api/gen/AnimalDetails';
import {FindAnimalQueryParams} from 'api/gen/Api';
import {CategoryDetails} from 'api/gen/CategoryDetails';
import {MatchedMicrosatellite} from 'api/gen/MatchedMicrosatellite';
import {Measurements} from 'api/gen/Measurements';
import {OrderAnimalDetails} from 'api/gen/OrderAnimalDetails';
import {PagedModelAnimal} from 'api/gen/PagedModelAnimal';
import {PagedModelOriginInconsistency} from 'api/gen/PagedModelOriginInconsistency';
import {Qtl} from 'api/gen/Qtl';

import {AnimalsTableRow} from '../components/AnimalsTable/table-row';
import {OriginInconsistenciesTableRow} from '../components/OriginInconsistenciesTable/table-row';
import {AnimalsLoadingId} from '../types/animals-loading-id';

export const animalsAction = {
    getAnimals: (tableDataRequest: Opt<TableDataRequest<AnimalsTableRow>>) => ({
        type: 'animals/GET_ANIMALS',
        payload: {tableDataRequest},
    }) as const,
    getAnimalsSuccess: (animalsPage: Opt<PagedModelAnimal>) => ({
        type: 'animals/GET_ANIMALS_SUCCESS',
        payload: {animalsPage},
    }) as const,
    getAnimal: (animalId: number, redirectNotFound?: boolean) => ({
        type: 'animals/GET_ANIMAL',
        payload: {animalId, redirectNotFound},
    }) as const,
    getAnimalSuccess: (animal: AnimalDetails) => ({
        type: 'animals/GET_ANIMAL_SUCCESS',
        payload: {animal},
    }) as const,
    getOriginInconsistencies: (tableDataRequest: Opt<TableDataRequest<OriginInconsistenciesTableRow>>) => ({
        type: 'animals/GET_ORIGIN_INCONSISTENCIES',
        payload: {tableDataRequest},
    }) as const,
    getOriginInconsistenciesSuccess: (originInconsistencies: Opt<PagedModelOriginInconsistency>) => ({
        type: 'animals/GET_ORIGIN_INCONSISTENCIES_SUCCESS',
        payload: {originInconsistencies},
    }) as const,
    acceptOriginInconsistency: (originInconsistencyId: number) => ({
        type: 'animals/ACCEPT_ORIGIN_INCONSISTENCY',
        payload: {originInconsistencyId},
    }) as const,
    sendOriginInconsistencyReport: (originInconsistencyId: number) => ({
        type: 'animals/SEND_ORIGIN_INCONSISTENCY_REPORT',
        payload: {originInconsistencyId},
    }) as const,
    getCategories: () => ({
        type: 'animals/GET_CATEGORIES',
    }) as const,
    getCategoriesSuccess: (categories: Array<CategoryDetails>) => ({
        type: 'animals/GET_CATEGORIES_SUCCESS',
        payload: {categories},
    }) as const,
    deleteAnimal: (animalId: number) => ({
        type: 'animals/DELETE_ANIMAL',
        payload: {animalId},
    }) as const,
    deleteAnimalSuccess: (animalId: number) => ({
        type: 'animals/DELETE_ANIMAL_SUCCESS',
        payload: {animalId},
    }) as const,
    exportAnimal: (animalId: number) => ({
        type: 'animals/EXPORT_ANIMAL',
        payload: {animalId},
    }) as const,
    exportOriginInconsistenciesToCSV: () => ({
        type: 'animals/EXPORT_ORIGIN_INCONSISTENCIES_TO_CSV',
    }) as const,
    exportOriginInconsistencyReport: (originInconsistencyId: number) => ({
        type: 'animals/EXPORT_ORIGIN_INCONSISTENCY_REPORT',
        payload: {originInconsistencyId},
    }) as const,
    getAnimalQtls: (animalId: number) => ({
        type: 'animals/GET_ANIMAL_QTLS',
        payload: {animalId},
    }) as const,
    getAnimalQtlsSuccess: (animalQtls: Array<Qtl>) => ({
        type: 'animals/GET_ANIMAL_QTLS_SUCCESS',
        payload: {animalQtls},
    }) as const,
    resetAnimalQtls: () => ({
        type: 'animals/RESET_ANIMAL_QTLS',
    }) as const,
    getMeasurements: () => ({
        type: 'animals/GET_MEASUREMENTS',
    }) as const,
    getMeasurementsSuccess: (measurements: Measurements) => ({
        type: 'animals/GET_MEASUREMENTS_SUCCESS',
        payload: {measurements},
    }) as const,
    resetMeasurementsAndCategories: () => ({
        type: 'animals/RESET_MEASUREMENTS_AND_CATEGORIES',
    }) as const,
    getMicrosatellites: (animalId: number) => ({
        type: 'animals/GET_MICROSATELLITES',
        payload: {animalId},
    }) as const,
    getMicrosatellitesSuccess: (microsatellites: Array<MatchedMicrosatellite>) => ({
        type: 'animals/GET_MICROSATELLITES_SUCCESS',
        payload: {microsatellites},
    }) as const,
    updateAnimal: (animalId: number) => ({
        type: 'animals/UPDATE_ANIMAL',
        payload: {animalId},
    }) as const,
    resetAnimal: () => ({
        type: 'animals/RESET_ANIMAL',
    }) as const,
    setSelectedAnimals: (animalIds: Array<number>) => ({
        type: 'animals/SET_SELECTED_ANIMALS',
        payload: {animalIds},
    }) as const,
    refreshFather: (params: FindAnimalQueryParams) => ({
        type: 'animals/REFRESH_FATHER',
        payload: {params},
    }) as const,
    refreshFatherSuccess: (animal: OrderAnimalDetails) => ({
        type: 'animals/REFRESH_FATHER_SUCCESS',
        payload: {animal},
    }) as const,
    exportGenotype: (animalId: number) => ({
        type: 'animals/EXPORT_GENOTYPE',
        payload: {animalId},
    }) as const,
    exportMicrosatellites: (animalIds: Array<number>) => ({
        type: 'animals/EXPORT_MICROSATELLITES',
        payload: {animalIds},
    }) as const,
    markAsInactive: (originInconsistencyId: number) => ({
        type: 'animals/MARK_AS_INACTIVE',
        payload: {originInconsistencyId},
    }) as const,
    markAsActive: (originInconsistencyId: number, earTag: string) => ({
        type: 'animals/MARK_AS_ACTIVE',
        payload: {originInconsistencyId, earTag},
    }) as const,
    setInconsistenciesActionActive: (id: number, active: boolean) => ({
        type: 'animals/SET_INCONSISTENCIES_ACTION_ACTIVE',
        payload: {id, active},
    }) as const,
    setSelectedOriginInconsistencyIds: (selectedIds: Array<number>) => ({
        type: 'animals/SET_SELECTED_ORIGIN_INCONSISTENCY_IDS',
        payload: {selectedIds},
    }) as const,
    setLoading: (id: AnimalsLoadingId, loading: boolean) => ({
        type: 'animals/SET_LOADING',
        payload: {id, loading},
    }) as const,
};

export type GetAnimals = ReturnType<typeof animalsAction.getAnimals>;
export type GetAnimalsSuccess = ReturnType<typeof animalsAction.getAnimalsSuccess>;
export type GetAnimal= ReturnType<typeof animalsAction.getAnimal>;
export type GetAnimalSuccess = ReturnType<typeof animalsAction.getAnimalSuccess>;
export type GetOriginInconsistencies = ReturnType<typeof animalsAction.getOriginInconsistencies>;
export type GetOriginInconsistenciesSuccess = ReturnType<typeof animalsAction.getOriginInconsistenciesSuccess>;
export type AcceptOriginInconsistency = ReturnType<typeof animalsAction.acceptOriginInconsistency>;
export type SendOriginInconsistencyReport = ReturnType<typeof animalsAction.sendOriginInconsistencyReport>;
export type GetCategories = ReturnType<typeof animalsAction.getCategories>;
export type GetCategoriesSuccess = ReturnType<typeof animalsAction.getCategoriesSuccess>;
export type DeleteAnimal = ReturnType<typeof animalsAction.deleteAnimal>;
export type DeleteAnimalSuccess = ReturnType<typeof animalsAction.deleteAnimalSuccess>;
export type ExportAnimal = ReturnType<typeof animalsAction.exportAnimal>;
export type ExportOriginInconsistenciesToCSV = ReturnType<typeof animalsAction.exportOriginInconsistenciesToCSV>;
export type ExportOriginInconsistencyReport = ReturnType<typeof animalsAction.exportOriginInconsistencyReport>;
export type GetAnimalQtls = ReturnType<typeof animalsAction.getAnimalQtls>;
export type GetAnimalQtlsSuccess = ReturnType<typeof animalsAction.getAnimalQtlsSuccess>;
export type ResetAnimalQtlsAction = ReturnType<typeof animalsAction.resetAnimalQtls>;
export type GetMeasurements = ReturnType<typeof animalsAction.getMeasurements>;
export type GetMeasurementsSuccess = ReturnType<typeof animalsAction.getMeasurementsSuccess>;
export type ResetMeasurementsAndCategoriesAction = ReturnType<typeof animalsAction.resetMeasurementsAndCategories>;
export type GetMicrosatellites = ReturnType<typeof animalsAction.getMicrosatellites>;
export type GetMicrosatellitesSuccess = ReturnType<typeof animalsAction.getMicrosatellitesSuccess>;
export type UpdateAnimal = ReturnType<typeof animalsAction.updateAnimal>;
export type ResetAnimal = ReturnType<typeof animalsAction.resetAnimal>;
export type SetSelctedAnimals = ReturnType<typeof animalsAction.setSelectedAnimals>;
export type RefreshFather = ReturnType<typeof animalsAction.refreshFather>;
export type RefreshFatherSuccess = ReturnType<typeof animalsAction.refreshFatherSuccess>;
export type ExportGenotype = ReturnType<typeof animalsAction.exportGenotype>;
export type ExportMicrosatellites = ReturnType<typeof animalsAction.exportMicrosatellites>;
export type MarkAsInactive = ReturnType<typeof animalsAction.markAsInactive>;
export type MarkAsActive = ReturnType<typeof animalsAction.markAsActive>;
export type SetInconsistenciesActionActiveAction = ReturnType<typeof animalsAction.setInconsistenciesActionActive>;
export type SetSelectedOriginInconsistencyIds =
    ReturnType<typeof animalsAction.setSelectedOriginInconsistencyIds>;
export type SetLoadingAction = ReturnType<typeof animalsAction.setLoading>;

export type AnimalsAction =
    | GetAnimals
    | GetAnimalsSuccess
    | GetAnimal
    | GetAnimalSuccess
    | GetOriginInconsistencies
    | GetOriginInconsistenciesSuccess
    | AcceptOriginInconsistency
    | SendOriginInconsistencyReport
    | GetCategories
    | GetCategoriesSuccess
    | DeleteAnimal
    | DeleteAnimalSuccess
    | ExportAnimal
    | ExportOriginInconsistenciesToCSV
    | ExportOriginInconsistencyReport
    | GetAnimalQtls
    | GetAnimalQtlsSuccess
    | ResetAnimalQtlsAction
    | GetMeasurements
    | GetMeasurementsSuccess
    | ResetMeasurementsAndCategoriesAction
    | GetMicrosatellites
    | GetMicrosatellitesSuccess
    | UpdateAnimal
    | ResetAnimal
    | SetSelctedAnimals
    | RefreshFather
    | RefreshFatherSuccess
    | ExportGenotype
    | ExportMicrosatellites
    | MarkAsInactive
    | MarkAsActive
    | SetInconsistenciesActionActiveAction
    | SetSelectedOriginInconsistencyIds
    | SetLoadingAction
    ;
