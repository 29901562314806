import React from 'react';
import {Route} from 'react-router-dom';

import * as Screens from './screens';

export const orderRoutesWithoutFetching: Array<RegExp> = [/^\/orders\/receive$/];

export const orderRoutes = [
    (
        <Route
            exact
            path="/orders-list"
            component={Screens.List}
            key="orders-list-home"
        />
    ),
    (
        <Route
            exact
            path="/orders"
            component={Screens.List}
            key="orders-list"
        />
    ),
    (
        <Route
            path="/orders/new"
            component={Screens.New}
            key="orders-new"
        />
    ),
    (
        <Route
            path="/orders/new-bulk"
            component={Screens.NewBulk}
            key="orders-new-bulk"
        />
    ),
    (
        <Route
            path="/orders/receive"
            component={Screens.ReceiveOrders}
            key="orders-receive"
        />
    ),
    (
        <Route
            exact
            path="/orders/:id"
            component={Screens.Detail}
            key="orders-detail"
        />
    ),
    (
        <Route
            exact
            path="/orders/:id/generate-protocol"
            component={Screens.GenerateProtocol}
            key="orders-detail-protocol"
        />
    ),
    (
        <Route
            exact
            path="/orders/:id/generate-request"
            component={Screens.GenerateRequest}
            key="orders-detail-request"
        />
    ),
];
