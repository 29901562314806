import {t} from 'translations';
import {PaternityValidator} from 'utils/paternity-validator';
import {Errors} from 'utils/validator';

import {BillingBaseExportOptionsFormValues} from './billing-base-export-options-form-values';

export const validate = (values: BillingBaseExportOptionsFormValues): Errors<BillingBaseExportOptionsFormValues> => {
    const validator = new PaternityValidator(values);

    validator.nonEmpty('billingDate', t('orders/BillingBaseExportOptionsForm')('billingDate'));

    return validator.generateErrorsObject();
};
