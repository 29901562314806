import React, {FC} from 'react';

import {HaplotypeCouple} from 'api/gen/HaplotypeCouple';
import {MatchedMicrosatellite} from 'api/gen/MatchedMicrosatellite';

import styles from './styles.sass';

interface HaplotypeCoupleProps {
    haplotypeCouple: HaplotypeCouple;
}

const HaplotypeCoupleView: FC<HaplotypeCoupleProps> = ({haplotypeCouple}) => (
    <div className={styles.haplotypeCouple}>
        <div className={styles.haplotype}>
            {haplotypeCouple.first.value}
            {' '}
            {haplotypeCouple.first.alleles}
        </div>
        <div className={styles.haplotype}>
            {haplotypeCouple.second.value}
            {' '}
            {haplotypeCouple.second.alleles}
        </div>
    </div>
);

interface Props {
    microsatellite: MatchedMicrosatellite;
}

export const MicrosatelliteView: FC<Props> = ({microsatellite}) => (
    <div className={styles.microsatelliteSection}>
        <div className={styles.microsatelliteCaption}>
            {microsatellite.microsatellite}
        </div>
        {microsatellite.haplotypeCouples?.map(haplotypeCouple => (
            <HaplotypeCoupleView
                key={`${haplotypeCouple.first.value}|${haplotypeCouple.second.value}`}
                haplotypeCouple={haplotypeCouple}
            />
        ))}
    </div>
);
