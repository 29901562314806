import React, {FC, useEffect} from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps} from 'react-router';
import {Dispatch} from 'redux';
import * as routerActions from 'redux-first-history';

import {AnimalDetails} from 'api/gen/AnimalDetails';
import {MatchedMicrosatellite} from 'api/gen/MatchedMicrosatellite';
import {Qtl} from 'api/gen/Qtl';
import {State} from 'app/state';
import {Container, MainPanel, PageHeading, Panel} from 'layout';
import {useOurTranslation} from 'translations';
import {useUser} from 'user';

import {AnimalDetail} from '../components/AnimalDetail';
import {AnimalDetailActions} from '../components/AnimalDetailActions';
import {animalsAction} from '../model';

interface StateProps {
    animal: AnimalDetails | null;
    animalQtls: Array<Qtl>;
    microsatellites: Array<MatchedMicrosatellite> | null;
    deleteAnimalInProgress: boolean;
}

interface DispatchProps {
    exportGenotypes(animalId: number): void;
    getAnimalQtls(animalId: number): void;
    getMicrosatellites(animalId: number): void;
    goBack(): void;
    getAnimal(animalId: number): void;
    goTo(path: string): void;
    deleteAnimal(animalId: number): void;
    resetAnimalQtls(): void;
    resetAnimal(): void;
}

interface Match {
    id: string;
}

type Props = StateProps & DispatchProps & RouteComponentProps<Match>;

const DetailBase: FC<Props> = props => {
    const {
        animal,
        getMicrosatellites,
        microsatellites,
        goBack,
        goTo,
        animalQtls,
        exportGenotypes,
        match,
        getAnimal,
        getAnimalQtls,
        deleteAnimal,
        resetAnimalQtls,
        deleteAnimalInProgress,
        resetAnimal,
    } = props;
    const {isRoleBreedingUnion, isRoleLaborer} = useUser();
    const showAnimalQtls = isRoleLaborer || isRoleBreedingUnion;
    const {t} = useOurTranslation('animals/screenDetail');

    useEffect(() => (): void => {
        resetAnimal();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const animalId = parseInt(match.params.id, 10);

        getAnimal(animalId);
        getMicrosatellites(animalId);
    }, [getAnimal, getMicrosatellites, match.params.id]);

    useEffect(() => {
        if (showAnimalQtls && animal) getAnimalQtls(animal.id);
    }, [animal, getAnimalQtls, showAnimalQtls]);

    return (
        <Container maxLgWidth>
            <MainPanel>
                <div className="row">
                    <div className="col-12">
                        <PageHeading>
                            <h1>{t('title')}</h1>
                        </PageHeading>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-lg-5 col-xl-4 order-lg-2">
                        {animal && (
                            <AnimalDetailActions
                                animal={animal}
                                isRoleBreedingUnion={isRoleBreedingUnion}
                                isRoleLaborer={isRoleLaborer}
                                goBack={goBack}
                                goTo={goTo}
                                exportGenotypes={exportGenotypes}
                                deleteAnimal={deleteAnimal}
                                deleteAnimalInProgress={deleteAnimalInProgress}
                            />
                        )}
                    </div>

                    <div className="col-12 col-lg-7 col-xl-8 order-lg-1">
                        <Panel title={t('animalData')}>
                            {animal && (
                                <AnimalDetail
                                    animal={animal}
                                    getMicrosatellites={getMicrosatellites}
                                    microsatellites={microsatellites}
                                    isRoleBreedingUnion={isRoleBreedingUnion}
                                    qtls={animalQtls}
                                    resetAnimalQtls={resetAnimalQtls}
                                />
                            )}
                        </Panel>
                    </div>
                </div>
            </MainPanel>
        </Container>
    );
};

const mapStateToProps = (state: State): StateProps => ({
    animal: state.animals.animal,
    microsatellites: state.animals.microsatellites,
    animalQtls: state.animals.animalQtls ?? [],
    deleteAnimalInProgress: state.animals.loading.includes('deleteAnimalInProgress'),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    goBack: (): void => void dispatch(routerActions.goBack()),
    goTo: (path: string): void => void dispatch(routerActions.push(path)),
    getAnimal: (animalId: number): void => void dispatch(animalsAction.getAnimal(animalId, true)),
    getMicrosatellites: (animalId: number): void => void dispatch(animalsAction.getMicrosatellites(animalId)),
    getAnimalQtls: (animalId: number): void => void dispatch(animalsAction.getAnimalQtls(animalId)),
    exportGenotypes: (animalId: number): void => void dispatch(animalsAction.exportGenotype(animalId)),
    deleteAnimal: (animalId: number): void => void dispatch(animalsAction.deleteAnimal(animalId)),
    resetAnimal: (): void => void dispatch(animalsAction.resetAnimal()),
    resetAnimalQtls: (): void => void dispatch(animalsAction.resetAnimalQtls()),
});

export const Detail = connect(mapStateToProps, mapDispatchToProps)(DetailBase);
