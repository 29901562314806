import {FooterButtons} from '@fl/cmsch-fe-library';
import React, {FC, memo} from 'react';
import {Opt} from 'ts-opt';

import {useOurTranslation} from 'translations';
import {useForm} from 'utils/forms';

import {
    createOrderTypeFormName,
    editOrderTypeFormName,
    initialOrderTypeFormValues,
    OrderTypeFormValues,
} from './order-type-form-values';
import {validate} from './validations';

interface Props {
    isEdit?: boolean;
    onChangeValue(value: Opt<string>): void;
    onChangeCode(value: Opt<string>): void;
    onCancel?(): void;
    onSubmit(values: OrderTypeFormValues): void;
}

const OrderTypeFormBase: FC<Props> = props => {
    const {
        isEdit,
        onCancel,
        onSubmit,
        onChangeValue,
        onChangeCode,
    } = props;

    const {t} = useOurTranslation('dials/orderTypes');

    const {Form, Fields, valid, pristine, submitting, renderErrors} = useForm({
        form: isEdit ? editOrderTypeFormName : createOrderTypeFormName,
        initialValues: initialOrderTypeFormValues,
        destroyOnUnmount: true,
        validate,
        onSubmit,
    });

    return (
        <Form>
            {renderErrors()}
            <div className="row">
                <div className="col-12">
                    <Fields.Input
                        name="value"
                        type="text"
                        label={t('description')}
                        onFieldChange={onChangeValue}
                        isRequired
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <Fields.Input
                        name="code"
                        type="text"
                        disabled={isEdit}
                        label={t('code')}
                        onFieldChange={onChangeCode}
                        isRequired
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <Fields.NumberInput
                        name="price"
                        rightLabel={t('currency')}
                        label={t('price')}
                        isRequired
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <Fields.NumberInput
                        name="extraCharge"
                        rightLabel={t('currency')}
                        label={t('extraCharge')}
                        isRequired
                    />
                </div>
            </div>
            <FooterButtons
                editing={isEdit}
                disabledSubmit={!valid || pristine || submitting}
                onCancel={onCancel}
            />
        </Form>
    );
};

export const OrderTypeForm = memo(OrderTypeFormBase);
