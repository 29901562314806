import {Button} from '@fl/cmsch-fe-library';
import React, {FC, memo, useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {Container, MainPanel, PageHeading} from 'layout';
import {useOurTranslation} from 'translations';

import {AboutApp} from '../components';
import {otherAction, simpleOtherSelector} from '../model';

const OtherBase: FC = _props => {
    const catalogListsUpdated = useSelector(simpleOtherSelector.catalogListsUpdated);
    const catalogListsUpdateInProgress = useSelector(simpleOtherSelector.catalogListsUpdateInProgress);
    const animalsSychronizeStarted = useSelector(simpleOtherSelector.animalsSychronizeStarted);
    const animalsSynchronizationInProgress = useSelector(simpleOtherSelector.animalsSynchronizationInProgress);
    const paternityUpdateStarted = useSelector(simpleOtherSelector.paternityUpdateStarted);
    const paternityUpdateInProgress = useSelector(simpleOtherSelector.paternityUpdateInProgress);
    const appInfo = useSelector(simpleOtherSelector.appInfo);
    const appInfoLoading = useSelector(simpleOtherSelector.appInfoLoading);

    const {t} = useOurTranslation('other/screenOther');
    const dispatch = useDispatch();
    const updateCatalogLists = useCallback(() => {
        dispatch(otherAction.updateCatalogLists());
    }, [dispatch]);
    const synchronizeAnimals = useCallback(() => {
        dispatch(otherAction.synchronizeAnimals());
    }, [dispatch]);
    const updatePaternity = useCallback(() => {
        dispatch(otherAction.updatePaternity());
    }, [dispatch]);
    const getAppInfo = useCallback(() => {
        dispatch(otherAction.getAppInfo());
    }, [dispatch]);

    const header = useMemo(() => (
        <div className="row">
            <div className="col-12" >
                <PageHeading>
                    <h1>{t('tabName')}</h1>
                </PageHeading>
            </div>
        </div>
    ), [t]);

    return (
        <Container>
            <MainPanel>
                {header}
                <div className="row">
                    <div className="col-12 col-lg-4 pb-3 pb-lg-0">
                        <Button
                            type="button"
                            visuals="primary"
                            block
                            loading={catalogListsUpdateInProgress}
                            onClick={updateCatalogLists}
                            disabled={catalogListsUpdated || catalogListsUpdateInProgress}
                            testId="update-catalog-lists-button"
                        >
                            {catalogListsUpdated || catalogListsUpdateInProgress
                                ? t('updateInProgress')
                                : t('updateCatalogueLists')
                            }
                        </Button>
                    </div>
                    <div className="col-12 col-lg-4 pb-3 pb-lg-0">
                        <Button
                            type="button"
                            visuals="primary"
                            block
                            loading={animalsSynchronizationInProgress}
                            onClick={synchronizeAnimals}
                            disabled={animalsSychronizeStarted || animalsSynchronizationInProgress}
                            tooltip={t('synchronizeAnimalsTooltip')}
                            testId="sync-animals-button"
                        >
                            {animalsSychronizeStarted || animalsSynchronizationInProgress
                                ? t('synchronizationInProgress')
                                : t('synchronizeAnimals')
                            }
                        </Button>
                    </div>
                    <div className="col-12 col-lg-4">
                        <Button
                            type="button"
                            visuals="primary"
                            block
                            loading={paternityUpdateInProgress}
                            onClick={updatePaternity}
                            disabled={paternityUpdateStarted || paternityUpdateInProgress}
                            testId="update-parenthood-button"
                        >
                            {paternityUpdateInProgress || paternityUpdateStarted
                                ? t('updateInProgress')
                                : t('updatePaternity')
                            }
                        </Button>
                    </div>
                </div>
            </MainPanel>
            <div className="mt-4" />
            <AboutApp
                appInfo={appInfo}
                loading={appInfoLoading}
                getAppInfo={getAppInfo}
            />
        </Container>
    );
};

export const Other = memo(OtherBase);
