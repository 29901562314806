import {includes} from 'lodash/fp';

export const laborer = 'ROLE_LABORER';
export const breeder = 'ROLE_BREEDER';
export const association = 'ROLE_ASSOCIATION';
export const breedingUnion = 'ROLE_BREEDING_UNION';
// eslint-disable-next-line import/no-unused-modules
export const meatAssociation = 'ROLE_MEAT_ASSOCIATION';

const roleNames = [
    laborer,
    breeder,
    association,
    breedingUnion,
    meatAssociation,
] as const;

export type RoleName = typeof roleNames[number];

export const isValidRoleName = (x: string): x is RoleName => includes(x, roleNames);
