// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';
import {StringPatternSchema} from '@fl/cmsch-fe-library';


// The e-mail schema.
// Example: "john.doe@example.org"

export const emailRegexGen = (): RegExp => new RegExp("^[\\w%+.-]+@[\\w.-]+\\.[A-Za-z]{2,}$");

export const emailMinLength = 1;
export const emailMaxLength = 255;

export const EmailSchema = t.refinement(StringPatternSchema<Email>(emailRegexGen()), n => n.length >= emailMinLength && n.length <= emailMaxLength, 'Email');

export type Email = string;
