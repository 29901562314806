import * as t from 'io-ts';
import { Response, EmptyString, EmptyStringSchema, ArrayBufferSchema, FormDataSchema, excess, validateSchema } from '@fl/cmsch-fe-library';

import { apiRequester } from 'app/api';


// @ts-ignore: Workaround for "is declared but its value is never read."
import { AnimalDetails, AnimalDetailsSchema } from 'api/gen/AnimalDetails';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { AnimalUpdate, AnimalUpdateSchema } from 'api/gen/AnimalUpdate';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { AppInfo, AppInfoSchema } from 'api/gen/AppInfo';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { BillingBasesExport, BillingBasesExportSchema } from 'api/gen/BillingBasesExport';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Category, CategorySchema } from 'api/gen/Category';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { CategoryDetails, CategoryDetailsSchema } from 'api/gen/CategoryDetails';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { ChipId, ChipIdSchema } from 'api/gen/ChipId';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { ChipImportOutcome, ChipImportOutcomeSchema } from 'api/gen/ChipImportOutcome';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Cin, CinSchema } from 'api/gen/Cin';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { CodeTableItem, CodeTableItemSchema } from 'api/gen/CodeTableItem';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { CodeTableValidation, CodeTableValidationSchema } from 'api/gen/CodeTableValidation';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Date, DateSchema } from 'api/gen/Date';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { EarTag, EarTagSchema } from 'api/gen/EarTag';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { EmailTemplateDetails, EmailTemplateDetailsSchema } from 'api/gen/EmailTemplateDetails';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { EmailTemplateUpdate, EmailTemplateUpdateSchema } from 'api/gen/EmailTemplateUpdate';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { FileKey, FileKeySchema } from 'api/gen/FileKey';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { GenotypeExportAnimals, GenotypeExportAnimalsSchema } from 'api/gen/GenotypeExportAnimals';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { ImportedOrders, ImportedOrdersSchema } from 'api/gen/ImportedOrders';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { LabNumber, LabNumberSchema } from 'api/gen/LabNumber';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { LastOrderUserDetails, LastOrderUserDetailsSchema } from 'api/gen/LastOrderUserDetails';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { LogoutUri, LogoutUriSchema } from 'api/gen/LogoutUri';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { MatchedMicrosatellite, MatchedMicrosatelliteSchema } from 'api/gen/MatchedMicrosatellite';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Measurements, MeasurementsSchema } from 'api/gen/Measurements';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { NewCodeTableItem, NewCodeTableItemSchema } from 'api/gen/NewCodeTableItem';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { NewOrder, NewOrderSchema } from 'api/gen/NewOrder';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { NewOrderInBulk, NewOrderInBulkSchema } from 'api/gen/NewOrderInBulk';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { NewOrderTypeItem, NewOrderTypeItemSchema } from 'api/gen/NewOrderTypeItem';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { NewProtocolDetails, NewProtocolDetailsSchema } from 'api/gen/NewProtocolDetails';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { NewVerificationOutcome, NewVerificationOutcomeSchema } from 'api/gen/NewVerificationOutcome';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { OrderAnimalDetails, OrderAnimalDetailsSchema } from 'api/gen/OrderAnimalDetails';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { OrderCustomerDetails, OrderCustomerDetailsSchema } from 'api/gen/OrderCustomerDetails';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { OrderDetails, OrderDetailsSchema } from 'api/gen/OrderDetails';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { OrderDetailsForGenotypingRequest, OrderDetailsForGenotypingRequestSchema } from 'api/gen/OrderDetailsForGenotypingRequest';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { OrderDetailsForProtocol, OrderDetailsForProtocolSchema } from 'api/gen/OrderDetailsForProtocol';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { OrderImportType, OrderImportTypeSchema } from 'api/gen/OrderImportType';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { OrderSearch, OrderSearchSchema } from 'api/gen/OrderSearch';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { OrderTypeItem, OrderTypeItemSchema } from 'api/gen/OrderTypeItem';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { OrderTypeValidation, OrderTypeValidationSchema } from 'api/gen/OrderTypeValidation';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { OrderUserDetails, OrderUserDetailsSchema } from 'api/gen/OrderUserDetails';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { OrderValidationRequest, OrderValidationRequestSchema } from 'api/gen/OrderValidationRequest';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PagedModelAnimal, PagedModelAnimalSchema } from 'api/gen/PagedModelAnimal';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PagedModelGenotypeExport, PagedModelGenotypeExportSchema } from 'api/gen/PagedModelGenotypeExport';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PagedModelOrder, PagedModelOrderSchema } from 'api/gen/PagedModelOrder';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PagedModelOriginInconsistency, PagedModelOriginInconsistencySchema } from 'api/gen/PagedModelOriginInconsistency';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PagedModelSnpView, PagedModelSnpViewSchema } from 'api/gen/PagedModelSnpView';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { ParentSearchResultDetails, ParentSearchResultDetailsSchema } from 'api/gen/ParentSearchResultDetails';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { ParenthoodVerification, ParenthoodVerificationSchema } from 'api/gen/ParenthoodVerification';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { ParenthoodVerificationResult, ParenthoodVerificationResultSchema } from 'api/gen/ParenthoodVerificationResult';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveLong, PositiveLongSchema } from 'api/gen/PositiveLong';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Qtl, QtlSchema } from 'api/gen/Qtl';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { ReproductionStatistics, ReproductionStatisticsSchema } from 'api/gen/ReproductionStatistics';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { SampleIdCouple, SampleIdCoupleSchema } from 'api/gen/SampleIdCouple';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { SampleIdTrinity, SampleIdTrinitySchema } from 'api/gen/SampleIdTrinity';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { SideBox, SideBoxSchema } from 'api/gen/SideBox';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Snp, SnpSchema } from 'api/gen/Snp';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { SnpValidation, SnpValidationSchema } from 'api/gen/SnpValidation';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { TableFilter, TableFilterSchema } from 'api/gen/TableFilter';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { TableFiltersExcludingOrderIds, TableFiltersExcludingOrderIdsSchema } from 'api/gen/TableFiltersExcludingOrderIds';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { UpdateEmail, UpdateEmailSchema } from 'api/gen/UpdateEmail';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { UpdateOrderDetails, UpdateOrderDetailsSchema } from 'api/gen/UpdateOrderDetails';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { UpdateOrderTypeItem, UpdateOrderTypeItemSchema } from 'api/gen/UpdateOrderTypeItem';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { UpdateOrdersCustomer, UpdateOrdersCustomerSchema } from 'api/gen/UpdateOrdersCustomer';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { UpdateOrdersEmail, UpdateOrdersEmailSchema } from 'api/gen/UpdateOrdersEmail';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { User, UserSchema } from 'api/gen/User';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { UserDetails, UserDetailsSchema } from 'api/gen/UserDetails';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { VerificationOutcome, VerificationOutcomeSchema } from 'api/gen/VerificationOutcome';
// imports for custom endpoints


function fileToFormData(file: File): FormData {
    const data = new FormData();
    data.append('file', file);
    return data;
}

const deleteAnimalPathParamsRequiredPart = t.interface({
  animalId: PositiveLongSchema,
});
export const DeleteAnimalPathParamsSchema = excess(deleteAnimalPathParamsRequiredPart);
export interface DeleteAnimalPathParams extends t.TypeOf<typeof DeleteAnimalPathParamsSchema> {}


const exportToInterbullPathParamsRequiredPart = t.interface({
  animalId: PositiveLongSchema,
});
export const ExportToInterbullPathParamsSchema = excess(exportToInterbullPathParamsRequiredPart);
export interface ExportToInterbullPathParams extends t.TypeOf<typeof ExportToInterbullPathParamsSchema> {}


const findAllAnimalsQueryParamsRequiredPart = t.interface({
  page: t.union([t.Integer, t.null]), // Zero-based page index (0..N)
  size: t.union([t.Integer, t.null]), // The size of the page to be returned
  // Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
  sort: t.union([t.array(t.string), t.null]),
});
export const FindAllAnimalsQueryParamsSchema = excess(findAllAnimalsQueryParamsRequiredPart);
export interface FindAllAnimalsQueryParams extends t.TypeOf<typeof FindAllAnimalsQueryParamsSchema> {}


const findAnimalQueryParamsRequiredPart = t.interface({
  sampleId: t.union([t.string, t.null]),
  registry: t.union([t.string, t.null]),
});
export const FindAnimalQueryParamsSchema = excess(findAnimalQueryParamsRequiredPart);
export interface FindAnimalQueryParams extends t.TypeOf<typeof FindAnimalQueryParamsSchema> {}


const getAnimalPathParamsRequiredPart = t.interface({
  animalId: PositiveLongSchema,
});
export const GetAnimalPathParamsSchema = excess(getAnimalPathParamsRequiredPart);
export interface GetAnimalPathParams extends t.TypeOf<typeof GetAnimalPathParamsSchema> {}


const updateAnimalPathParamsRequiredPart = t.interface({
  animalId: PositiveLongSchema,
});
export const UpdateAnimalPathParamsSchema = excess(updateAnimalPathParamsRequiredPart);
export interface UpdateAnimalPathParams extends t.TypeOf<typeof UpdateAnimalPathParamsSchema> {}


const exportCatalogListsQueryParamsRequiredPart = t.interface({
  force: t.union([t.boolean, t.null]),
});
export const ExportCatalogListsQueryParamsSchema = excess(exportCatalogListsQueryParamsRequiredPart);
export interface ExportCatalogListsQueryParams extends t.TypeOf<typeof ExportCatalogListsQueryParamsSchema> {}


const exportCatalogListsSubsetQueryParamsRequiredPart = t.interface({
  orderIds: t.array(PositiveLongSchema),
  force: t.union([t.boolean, t.null]),
});
export const ExportCatalogListsSubsetQueryParamsSchema = excess(exportCatalogListsSubsetQueryParamsRequiredPart);
export interface ExportCatalogListsSubsetQueryParams extends t.TypeOf<typeof ExportCatalogListsSubsetQueryParamsSchema> {}


const getChipIdsQueryParamsRequiredPart = t.interface({
  chipId: t.string,
});
export const GetChipIdsQueryParamsSchema = excess(getChipIdsQueryParamsRequiredPart);
export interface GetChipIdsQueryParams extends t.TypeOf<typeof GetChipIdsQueryParamsSchema> {}


const rerunImportOfAnimalsQueryParamsRequiredPart = t.interface({
  snpIds: t.union([t.array(PositiveLongSchema), t.null]),
});
export const RerunImportOfAnimalsQueryParamsSchema = excess(rerunImportOfAnimalsQueryParamsRequiredPart);
export interface RerunImportOfAnimalsQueryParams extends t.TypeOf<typeof RerunImportOfAnimalsQueryParamsSchema> {}


const updateEmailTemplatePathParamsRequiredPart = t.interface({
  emailTemplateId: PositiveLongSchema,
});
export const UpdateEmailTemplatePathParamsSchema = excess(updateEmailTemplatePathParamsRequiredPart);
export interface UpdateEmailTemplatePathParams extends t.TypeOf<typeof UpdateEmailTemplatePathParamsSchema> {}


const createGenotypeExportSubsetQueryParamsRequiredPart = t.interface({
  orderIds: t.array(PositiveLongSchema),
});
export const CreateGenotypeExportSubsetQueryParamsSchema = excess(createGenotypeExportSubsetQueryParamsRequiredPart);
export interface CreateGenotypeExportSubsetQueryParams extends t.TypeOf<typeof CreateGenotypeExportSubsetQueryParamsSchema> {}


const downloadGenotypeExportPathParamsRequiredPart = t.interface({
  genotypeExportId: PositiveLongSchema,
});
export const DownloadGenotypeExportPathParamsSchema = excess(downloadGenotypeExportPathParamsRequiredPart);
export interface DownloadGenotypeExportPathParams extends t.TypeOf<typeof DownloadGenotypeExportPathParamsSchema> {}


const findAllGenotypeExportsQueryParamsRequiredPart = t.interface({
  page: t.union([t.Integer, t.null]), // Zero-based page index (0..N)
  size: t.union([t.Integer, t.null]), // The size of the page to be returned
  // Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
  sort: t.union([t.array(t.string), t.null]),
});
export const FindAllGenotypeExportsQueryParamsSchema = excess(findAllGenotypeExportsQueryParamsRequiredPart);
export interface FindAllGenotypeExportsQueryParams extends t.TypeOf<typeof FindAllGenotypeExportsQueryParamsSchema> {}


const getComputedGenotypesQueryParamsRequiredPart = t.interface({
  animalId: PositiveLongSchema,
});
export const GetComputedGenotypesQueryParamsSchema = excess(getComputedGenotypesQueryParamsRequiredPart);
export interface GetComputedGenotypesQueryParams extends t.TypeOf<typeof GetComputedGenotypesQueryParamsSchema> {}


const getMeasurementsQueryParamsRequiredPart = t.interface({
  animalIds: t.array(PositiveLongSchema),
  categoryIds: t.union([t.array(PositiveLongSchema), t.null]),
});
export const GetMeasurementsQueryParamsSchema = excess(getMeasurementsQueryParamsRequiredPart);
export interface GetMeasurementsQueryParams extends t.TypeOf<typeof GetMeasurementsQueryParamsSchema> {}


const exportMicrosatellitesQueryParamsRequiredPart = t.interface({
  animalIds: t.array(PositiveLongSchema),
});
export const ExportMicrosatellitesQueryParamsSchema = excess(exportMicrosatellitesQueryParamsRequiredPart);
export interface ExportMicrosatellitesQueryParams extends t.TypeOf<typeof ExportMicrosatellitesQueryParamsSchema> {}


const getMicrosatellitesPathParamsRequiredPart = t.interface({
  animalId: PositiveLongSchema,
});
export const GetMicrosatellitesPathParamsSchema = excess(getMicrosatellitesPathParamsRequiredPart);
export interface GetMicrosatellitesPathParams extends t.TypeOf<typeof GetMicrosatellitesPathParamsSchema> {}


const getCustomerDetailsQueryParamsRequiredPart = t.interface({
  name: t.union([t.string, t.null]),
});
export const GetCustomerDetailsQueryParamsSchema = excess(getCustomerDetailsQueryParamsRequiredPart);
export interface GetCustomerDetailsQueryParams extends t.TypeOf<typeof GetCustomerDetailsQueryParamsSchema> {}


const updateCustomerPathParamsRequiredPart = t.interface({
  orderId: PositiveLongSchema,
});
export const UpdateCustomerPathParamsSchema = excess(updateCustomerPathParamsRequiredPart);
export interface UpdateCustomerPathParams extends t.TypeOf<typeof UpdateCustomerPathParamsSchema> {}


const updateCustomersEmailSubsetQueryParamsRequiredPart = t.interface({
  orderIds: t.array(PositiveLongSchema),
});
export const UpdateCustomersEmailSubsetQueryParamsSchema = excess(updateCustomersEmailSubsetQueryParamsRequiredPart);
export interface UpdateCustomersEmailSubsetQueryParams extends t.TypeOf<typeof UpdateCustomersEmailSubsetQueryParamsSchema> {}


const updateCustomersSubsetQueryParamsRequiredPart = t.interface({
  orderIds: t.array(PositiveLongSchema),
});
export const UpdateCustomersSubsetQueryParamsSchema = excess(updateCustomersSubsetQueryParamsRequiredPart);
export interface UpdateCustomersSubsetQueryParams extends t.TypeOf<typeof UpdateCustomersSubsetQueryParamsSchema> {}


const exportOrdersQueryParamsRequiredPart = t.interface({
  // Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
  sort: t.array(t.string),
});
export const ExportOrdersQueryParamsSchema = excess(exportOrdersQueryParamsRequiredPart);
export interface ExportOrdersQueryParams extends t.TypeOf<typeof ExportOrdersQueryParamsSchema> {}


const exportOrdersForGermanyQueryParamsRequiredPart = t.interface({
  force: t.union([t.boolean, t.null]),
});
export const ExportOrdersForGermanyQueryParamsSchema = excess(exportOrdersForGermanyQueryParamsRequiredPart);
export interface ExportOrdersForGermanyQueryParams extends t.TypeOf<typeof ExportOrdersForGermanyQueryParamsSchema> {}


const exportOrdersSubsetQueryParamsRequiredPart = t.interface({
  orderIds: t.array(PositiveLongSchema),
});
export const ExportOrdersSubsetQueryParamsSchema = excess(exportOrdersSubsetQueryParamsRequiredPart);
export interface ExportOrdersSubsetQueryParams extends t.TypeOf<typeof ExportOrdersSubsetQueryParamsSchema> {}


const exportOrdersSubsetForGermanyQueryParamsRequiredPart = t.interface({
  orderIds: t.array(PositiveLongSchema),
  force: t.union([t.boolean, t.null]),
});
export const ExportOrdersSubsetForGermanyQueryParamsSchema = excess(exportOrdersSubsetForGermanyQueryParamsRequiredPart);
export interface ExportOrdersSubsetForGermanyQueryParams extends t.TypeOf<typeof ExportOrdersSubsetForGermanyQueryParamsSchema> {}


const generateSampleSheetQueryParamsRequiredPart = t.interface({
  chipId: ChipIdSchema,
  force: t.union([t.boolean, t.null]),
});
export const GenerateSampleSheetQueryParamsSchema = excess(generateSampleSheetQueryParamsRequiredPart);
export interface GenerateSampleSheetQueryParams extends t.TypeOf<typeof GenerateSampleSheetQueryParamsSchema> {}


const generateSampleSheetSubsetQueryParamsRequiredPart = t.interface({
  orderIds: t.array(PositiveLongSchema),
  chipId: ChipIdSchema,
  force: t.union([t.boolean, t.null]),
});
export const GenerateSampleSheetSubsetQueryParamsSchema = excess(generateSampleSheetSubsetQueryParamsRequiredPart);
export interface GenerateSampleSheetSubsetQueryParams extends t.TypeOf<typeof GenerateSampleSheetSubsetQueryParamsSchema> {}


const getCDCBExportSubsetQueryParamsRequiredPart = t.interface({
  orderIds: t.array(PositiveLongSchema),
});
export const GetCDCBExportSubsetQueryParamsSchema = excess(getCDCBExportSubsetQueryParamsRequiredPart);
export interface GetCDCBExportSubsetQueryParams extends t.TypeOf<typeof GetCDCBExportSubsetQueryParamsSchema> {}


const markForSubsidyAndExportSubsetQueryParamsRequiredPart = t.interface({
  orderIds: t.array(PositiveLongSchema),
});
export const MarkForSubsidyAndExportSubsetQueryParamsSchema = excess(markForSubsidyAndExportSubsetQueryParamsRequiredPart);
export interface MarkForSubsidyAndExportSubsetQueryParams extends t.TypeOf<typeof MarkForSubsidyAndExportSubsetQueryParamsSchema> {}


const importOrdersQueryParamsRequiredPart = t.interface({
  type: OrderImportTypeSchema,
});
export const ImportOrdersQueryParamsSchema = excess(importOrdersQueryParamsRequiredPart);
export interface ImportOrdersQueryParams extends t.TypeOf<typeof ImportOrdersQueryParamsSchema> {}


const createInvoicingDocumentsQueryParamsRequiredPart = t.interface({
  billingDate: DateSchema,
  force: t.union([t.boolean, t.null]),
});
export const CreateInvoicingDocumentsQueryParamsSchema = excess(createInvoicingDocumentsQueryParamsRequiredPart);
export interface CreateInvoicingDocumentsQueryParams extends t.TypeOf<typeof CreateInvoicingDocumentsQueryParamsSchema> {}


const createInvoicingDocumentsSubsetQueryParamsRequiredPart = t.interface({
  orderIds: t.array(PositiveLongSchema),
  billingDate: DateSchema,
  force: t.union([t.boolean, t.null]),
});
export const CreateInvoicingDocumentsSubsetQueryParamsSchema = excess(createInvoicingDocumentsSubsetQueryParamsRequiredPart);
export interface CreateInvoicingDocumentsSubsetQueryParams extends t.TypeOf<typeof CreateInvoicingDocumentsSubsetQueryParamsSchema> {}


const deleteInvoicingDocumentsPathParamsRequiredPart = t.interface({
  orderId: PositiveLongSchema,
  orderTypeId: PositiveLongSchema,
});
export const DeleteInvoicingDocumentsPathParamsSchema = excess(deleteInvoicingDocumentsPathParamsRequiredPart);
export interface DeleteInvoicingDocumentsPathParams extends t.TypeOf<typeof DeleteInvoicingDocumentsPathParamsSchema> {}


const getInvoicingDocumentsPathParamsRequiredPart = t.interface({
  fileKey: FileKeySchema,
});
export const GetInvoicingDocumentsPathParamsSchema = excess(getInvoicingDocumentsPathParamsRequiredPart);
export interface GetInvoicingDocumentsPathParams extends t.TypeOf<typeof GetInvoicingDocumentsPathParamsSchema> {}


const setOrdersAsReadyForBillingQueryParamsRequiredPart = t.interface({
  force: t.union([t.boolean, t.null]),
});
export const SetOrdersAsReadyForBillingQueryParamsSchema = excess(setOrdersAsReadyForBillingQueryParamsRequiredPart);
export interface SetOrdersAsReadyForBillingQueryParams extends t.TypeOf<typeof SetOrdersAsReadyForBillingQueryParamsSchema> {}


const setSubsetOfOrdersAsReadyForBillingQueryParamsRequiredPart = t.interface({
  orderIds: t.array(PositiveLongSchema),
  force: t.union([t.boolean, t.null]),
});
export const SetSubsetOfOrdersAsReadyForBillingQueryParamsSchema = excess(setSubsetOfOrdersAsReadyForBillingQueryParamsRequiredPart);
export interface SetSubsetOfOrdersAsReadyForBillingQueryParams extends t.TypeOf<typeof SetSubsetOfOrdersAsReadyForBillingQueryParamsSchema> {}


const deleteProtocolPathParamsRequiredPart = t.interface({
  orderId: PositiveLongSchema,
});
export const DeleteProtocolPathParamsSchema = excess(deleteProtocolPathParamsRequiredPart);
export interface DeleteProtocolPathParams extends t.TypeOf<typeof DeleteProtocolPathParamsSchema> {}


const generateProtocolPathParamsRequiredPart = t.interface({
  orderId: PositiveLongSchema,
});
export const GenerateProtocolPathParamsSchema = excess(generateProtocolPathParamsRequiredPart);
export interface GenerateProtocolPathParams extends t.TypeOf<typeof GenerateProtocolPathParamsSchema> {}


const getDataForProtocolPathParamsRequiredPart = t.interface({
  orderId: PositiveLongSchema,
});
export const GetDataForProtocolPathParamsSchema = excess(getDataForProtocolPathParamsRequiredPart);
export interface GetDataForProtocolPathParams extends t.TypeOf<typeof GetDataForProtocolPathParamsSchema> {}


const getProtocolPathParamsRequiredPart = t.interface({
  orderId: PositiveLongSchema,
});
export const GetProtocolPathParamsSchema = excess(getProtocolPathParamsRequiredPart);
export interface GetProtocolPathParams extends t.TypeOf<typeof GetProtocolPathParamsSchema> {}


const getProtocolsQueryParamsRequiredPart = t.interface({
  force: t.union([t.boolean, t.null]),
});
export const GetProtocolsQueryParamsSchema = excess(getProtocolsQueryParamsRequiredPart);
export interface GetProtocolsQueryParams extends t.TypeOf<typeof GetProtocolsQueryParamsSchema> {}


const getProtocolsSubsetQueryParamsRequiredPart = t.interface({
  orderIds: t.array(PositiveLongSchema),
  force: t.union([t.boolean, t.null]),
});
export const GetProtocolsSubsetQueryParamsSchema = excess(getProtocolsSubsetQueryParamsRequiredPart);
export interface GetProtocolsSubsetQueryParams extends t.TypeOf<typeof GetProtocolsSubsetQueryParamsSchema> {}


const updateOrderTypePathParamsRequiredPart = t.interface({
  orderTypeId: PositiveLongSchema,
});
export const UpdateOrderTypePathParamsSchema = excess(updateOrderTypePathParamsRequiredPart);
export interface UpdateOrderTypePathParams extends t.TypeOf<typeof UpdateOrderTypePathParamsSchema> {}


const addOrderTypesQueryParamsRequiredPart = t.interface({
  orderTypeIds: t.array(PositiveLongSchema),
});
export const AddOrderTypesQueryParamsSchema = excess(addOrderTypesQueryParamsRequiredPart);
export interface AddOrderTypesQueryParams extends t.TypeOf<typeof AddOrderTypesQueryParamsSchema> {}


const addOrderTypesSubsetQueryParamsRequiredPart = t.interface({
  orderIds: t.array(PositiveLongSchema),
  orderTypeIds: t.array(PositiveLongSchema),
});
export const AddOrderTypesSubsetQueryParamsSchema = excess(addOrderTypesSubsetQueryParamsRequiredPart);
export interface AddOrderTypesSubsetQueryParams extends t.TypeOf<typeof AddOrderTypesSubsetQueryParamsSchema> {}


const addOrderTypesToOrderPathParamsRequiredPart = t.interface({
  orderId: PositiveLongSchema,
});
export const AddOrderTypesToOrderPathParamsSchema = excess(addOrderTypesToOrderPathParamsRequiredPart);
export interface AddOrderTypesToOrderPathParams extends t.TypeOf<typeof AddOrderTypesToOrderPathParamsSchema> {}


const cancelSubsidySubsetQueryParamsRequiredPart = t.interface({
  orderIds: t.array(PositiveLongSchema),
});
export const CancelSubsidySubsetQueryParamsSchema = excess(cancelSubsidySubsetQueryParamsRequiredPart);
export interface CancelSubsidySubsetQueryParams extends t.TypeOf<typeof CancelSubsidySubsetQueryParamsSchema> {}


const deleteOrderPathParamsRequiredPart = t.interface({
  orderId: PositiveLongSchema,
});
export const DeleteOrderPathParamsSchema = excess(deleteOrderPathParamsRequiredPart);
export interface DeleteOrderPathParams extends t.TypeOf<typeof DeleteOrderPathParamsSchema> {}


const findAllOrdersQueryParamsRequiredPart = t.interface({
  page: t.union([t.Integer, t.null]), // Zero-based page index (0..N)
  size: t.union([t.Integer, t.null]), // The size of the page to be returned
  // Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
  sort: t.union([t.array(t.string), t.null]),
});
export const FindAllOrdersQueryParamsSchema = excess(findAllOrdersQueryParamsRequiredPart);
export interface FindAllOrdersQueryParams extends t.TypeOf<typeof FindAllOrdersQueryParamsSchema> {}


const getDataForGenotypingRequestPathParamsRequiredPart = t.interface({
  orderId: PositiveLongSchema,
});
export const GetDataForGenotypingRequestPathParamsSchema = excess(getDataForGenotypingRequestPathParamsRequiredPart);
export interface GetDataForGenotypingRequestPathParams extends t.TypeOf<typeof GetDataForGenotypingRequestPathParamsSchema> {}


const getLastOrderDataQueryParamsRequiredPart = t.interface({
  cin: t.union([CinSchema, t.null]),
});
export const GetLastOrderDataQueryParamsSchema = excess(getLastOrderDataQueryParamsRequiredPart);
export interface GetLastOrderDataQueryParams extends t.TypeOf<typeof GetLastOrderDataQueryParamsSchema> {}


const getOrderDetailsPathParamsRequiredPart = t.interface({
  orderId: PositiveLongSchema,
});
export const GetOrderDetailsPathParamsSchema = excess(getOrderDetailsPathParamsRequiredPart);
export interface GetOrderDetailsPathParams extends t.TypeOf<typeof GetOrderDetailsPathParamsSchema> {}


const isolateSubsetOfOrdersQueryParamsRequiredPart = t.interface({
  orderIds: t.array(PositiveLongSchema),
});
export const IsolateSubsetOfOrdersQueryParamsSchema = excess(isolateSubsetOfOrdersQueryParamsRequiredPart);
export interface IsolateSubsetOfOrdersQueryParams extends t.TypeOf<typeof IsolateSubsetOfOrdersQueryParamsSchema> {}


const removeOrderTypesQueryParamsRequiredPart = t.interface({
  orderTypeIds: t.array(PositiveLongSchema),
});
export const RemoveOrderTypesQueryParamsSchema = excess(removeOrderTypesQueryParamsRequiredPart);
export interface RemoveOrderTypesQueryParams extends t.TypeOf<typeof RemoveOrderTypesQueryParamsSchema> {}


const removeOrderTypesForSubsetQueryParamsRequiredPart = t.interface({
  orderIds: t.array(PositiveLongSchema),
  orderTypeIds: t.array(PositiveLongSchema),
});
export const RemoveOrderTypesForSubsetQueryParamsSchema = excess(removeOrderTypesForSubsetQueryParamsRequiredPart);
export interface RemoveOrderTypesForSubsetQueryParams extends t.TypeOf<typeof RemoveOrderTypesForSubsetQueryParamsSchema> {}


const searchOrderQueryParamsRequiredPart = t.interface({
  earTag: t.union([t.string, t.null]),
  barcode: t.union([t.string, t.null]),
});
export const SearchOrderQueryParamsSchema = excess(searchOrderQueryParamsRequiredPart);
export interface SearchOrderQueryParams extends t.TypeOf<typeof SearchOrderQueryParamsSchema> {}


const updateOrderPathParamsRequiredPart = t.interface({
  orderId: PositiveLongSchema,
});
export const UpdateOrderPathParamsSchema = excess(updateOrderPathParamsRequiredPart);
export interface UpdateOrderPathParams extends t.TypeOf<typeof UpdateOrderPathParamsSchema> {}


const validateLaboratoryNumberPathParamsRequiredPart = t.interface({
  orderId: PositiveLongSchema,
  laboratoryNumber: LabNumberSchema,
});
export const ValidateLaboratoryNumberPathParamsSchema = excess(validateLaboratoryNumberPathParamsRequiredPart);
export interface ValidateLaboratoryNumberPathParams extends t.TypeOf<typeof ValidateLaboratoryNumberPathParamsSchema> {}


const acceptParentsFromESkotPathParamsRequiredPart = t.interface({
  originInconsistencyId: PositiveLongSchema,
});
export const AcceptParentsFromESkotPathParamsSchema = excess(acceptParentsFromESkotPathParamsRequiredPart);
export interface AcceptParentsFromESkotPathParams extends t.TypeOf<typeof AcceptParentsFromESkotPathParamsSchema> {}


const activateInconsistencyPathParamsRequiredPart = t.interface({
  originInconsistencyId: PositiveLongSchema,
});
export const ActivateInconsistencyPathParamsSchema = excess(activateInconsistencyPathParamsRequiredPart);
export interface ActivateInconsistencyPathParams extends t.TypeOf<typeof ActivateInconsistencyPathParamsSchema> {}


const deactivateInconsistencyPathParamsRequiredPart = t.interface({
  originInconsistencyId: PositiveLongSchema,
});
export const DeactivateInconsistencyPathParamsSchema = excess(deactivateInconsistencyPathParamsRequiredPart);
export interface DeactivateInconsistencyPathParams extends t.TypeOf<typeof DeactivateInconsistencyPathParamsSchema> {}


const findAllOriginInconsistenciesQueryParamsRequiredPart = t.interface({
  page: t.union([t.Integer, t.null]), // Zero-based page index (0..N)
  size: t.union([t.Integer, t.null]), // The size of the page to be returned
  // Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
  sort: t.union([t.array(t.string), t.null]),
});
export const FindAllOriginInconsistenciesQueryParamsSchema = excess(findAllOriginInconsistenciesQueryParamsRequiredPart);
export interface FindAllOriginInconsistenciesQueryParams extends t.TypeOf<typeof FindAllOriginInconsistenciesQueryParamsSchema> {}


const getPdfForRequestingOriginChangesPathParamsRequiredPart = t.interface({
  originInconsistencyId: PositiveLongSchema,
});
export const GetPdfForRequestingOriginChangesPathParamsSchema = excess(getPdfForRequestingOriginChangesPathParamsRequiredPart);
export interface GetPdfForRequestingOriginChangesPathParams extends t.TypeOf<typeof GetPdfForRequestingOriginChangesPathParamsSchema> {}


const setCorrectOriginToIGenetikaPathParamsRequiredPart = t.interface({
  originInconsistencyId: PositiveLongSchema,
});
export const SetCorrectOriginToIGenetikaPathParamsSchema = excess(setCorrectOriginToIGenetikaPathParamsRequiredPart);
export interface SetCorrectOriginToIGenetikaPathParams extends t.TypeOf<typeof SetCorrectOriginToIGenetikaPathParamsSchema> {}


const searchParentQueryParamsRequiredPart = t.interface({
  parentEarTags: t.union([t.array(EarTagSchema), t.null]),
});
export const SearchParentQueryParamsSchema = excess(searchParentQueryParamsRequiredPart);
export interface SearchParentQueryParams extends t.TypeOf<typeof SearchParentQueryParamsSchema> {}


const searchParentPathParamsRequiredPart = t.interface({
  earTag: EarTagSchema,
});
export const SearchParentPathParamsSchema = excess(searchParentPathParamsRequiredPart);
export interface SearchParentPathParams extends t.TypeOf<typeof SearchParentPathParamsSchema> {}


const findAllQtlsByAnimalPathParamsRequiredPart = t.interface({
  animalId: PositiveLongSchema,
});
export const FindAllQtlsByAnimalPathParamsSchema = excess(findAllQtlsByAnimalPathParamsRequiredPart);
export interface FindAllQtlsByAnimalPathParams extends t.TypeOf<typeof FindAllQtlsByAnimalPathParamsSchema> {}


const findAllSnpsQueryParamsRequiredPart = t.interface({
  page: t.union([t.Integer, t.null]), // Zero-based page index (0..N)
  size: t.union([t.Integer, t.null]), // The size of the page to be returned
  // Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
  sort: t.union([t.array(t.string), t.null]),
});
export const FindAllSnpsQueryParamsSchema = excess(findAllSnpsQueryParamsRequiredPart);
export interface FindAllSnpsQueryParams extends t.TypeOf<typeof FindAllSnpsQueryParamsSchema> {}


const updateSampleTypePathParamsRequiredPart = t.interface({
  sampleTypeId: PositiveLongSchema,
});
export const UpdateSampleTypePathParamsSchema = excess(updateSampleTypePathParamsRequiredPart);
export interface UpdateSampleTypePathParams extends t.TypeOf<typeof UpdateSampleTypePathParamsSchema> {}


const updateTestResultTypePathParamsRequiredPart = t.interface({
  testResultTypeId: PositiveLongSchema,
});
export const UpdateTestResultTypePathParamsSchema = excess(updateTestResultTypePathParamsRequiredPart);
export interface UpdateTestResultTypePathParams extends t.TypeOf<typeof UpdateTestResultTypePathParamsSchema> {}


const updateVerificationOutcomeTypePathParamsRequiredPart = t.interface({
  verificationOutcomeTypeId: PositiveLongSchema,
});
export const UpdateVerificationOutcomeTypePathParamsSchema = excess(updateVerificationOutcomeTypePathParamsRequiredPart);
export interface UpdateVerificationOutcomeTypePathParams extends t.TypeOf<typeof UpdateVerificationOutcomeTypePathParamsSchema> {}



export class Api {
  // Gets information about the application (version, deploy date etc.). (/admin/app-info)
  public static async getApplicationInfo(): Promise<Response<AppInfo>> {
    return apiRequester.makeRequest({
      url: `/admin/app-info`,
      method: 'get',
      requestSchema: t.void,
      responseSchema: AppInfoSchema,
      expectedStatus: 200,
    });
  }

  // Deletes the animal. (/animals/{animalId})
  public static async deleteAnimal(pathParams: DeleteAnimalPathParams): Promise<Response<EmptyString>> {
    validateSchema(DeleteAnimalPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/animals/${pathParams.animalId}`,
      method: 'delete',
      requestSchema: t.void,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Exports the measurements of an animal to a text file in Interbull 705 format. (/animals/{animalId}/export)
  public static async exportToInterbull(pathParams: ExportToInterbullPathParams): Promise<Response<ArrayBuffer>> {
    validateSchema(ExportToInterbullPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/animals/${pathParams.animalId}/export`,
      method: 'get',
      requestSchema: t.void,
      responseType: 'arraybuffer',
      responseSchema: ArrayBufferSchema,
      expectedStatus: 200,
    });
  }

  // Finds a page of animals that match the search criteria. (/animals/find)
  public static async findAllAnimals(data: TableFilter[], params: FindAllAnimalsQueryParams): Promise<Response<PagedModelAnimal>> {
    validateSchema(FindAllAnimalsQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/animals/find`,
      method: 'post',
      params,
      data,
      requestSchema: t.array(TableFilterSchema),
      responseSchema: PagedModelAnimalSchema,
      expectedStatus: 200,
    });
  }

  // Finds animal by the provided label. (/animals/search)
  public static async findAnimal(params: FindAnimalQueryParams): Promise<Response<OrderAnimalDetails>> {
    validateSchema(FindAnimalQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/animals/search`,
      method: 'get',
      params,
      requestSchema: t.void,
      responseSchema: OrderAnimalDetailsSchema,
      expectedStatus: 200,
    });
  }

  // Gets details about the animal. (/animals/{animalId})
  public static async getAnimal(pathParams: GetAnimalPathParams): Promise<Response<AnimalDetails>> {
    validateSchema(GetAnimalPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/animals/${pathParams.animalId}`,
      method: 'get',
      requestSchema: t.void,
      responseSchema: AnimalDetailsSchema,
      expectedStatus: 200,
    });
  }

  // Asynchronously synchronizes animals with the eSkot database. (/animals/synchronization)
  public static async synchronizeAnimals(): Promise<Response<EmptyString>> {
    return apiRequester.makeRequest({
      url: `/animals/synchronization`,
      method: 'post',
      requestSchema: t.void,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Updates the animal with the given details. (/animals/{animalId})
  public static async updateAnimal(data: AnimalUpdate, pathParams: UpdateAnimalPathParams): Promise<Response<EmptyString>> {
    validateSchema(UpdateAnimalPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/animals/${pathParams.animalId}`,
      method: 'put',
      data,
      requestSchema: AnimalUpdateSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Exports catalog lists of animals to an excel file. (/catalog-lists/export)
  public static async exportCatalogLists(data: TableFiltersExcludingOrderIds, params: ExportCatalogListsQueryParams): Promise<Response<ArrayBuffer>> {
    validateSchema(ExportCatalogListsQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/catalog-lists/export`,
      method: 'post',
      params,
      data,
      requestSchema: TableFiltersExcludingOrderIdsSchema,
      responseType: 'arraybuffer',
      responseSchema: ArrayBufferSchema,
      expectedStatus: 200,
    });
  }

  // Exports catalog lists of animals with the orders with given IDs to an excel file. (/catalog-lists/export/subset)
  public static async exportCatalogListsSubset(params: ExportCatalogListsSubsetQueryParams): Promise<Response<ArrayBuffer>> {
    validateSchema(ExportCatalogListsSubsetQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/catalog-lists/export/subset`,
      method: 'get',
      params,
      requestSchema: t.void,
      responseType: 'arraybuffer',
      responseSchema: ArrayBufferSchema,
      expectedStatus: 200,
    });
  }

  // Synchronizes the catalog lists of animals with the CMSCH's database. (/catalog-lists/synchronization)
  public static async synchronizeCatalogLists(): Promise<Response<EmptyString>> {
    return apiRequester.makeRequest({
      url: `/catalog-lists/synchronization`,
      method: 'post',
      requestSchema: t.void,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Finds all chip ids that contain the provided value. (/chips/search)
  public static async getChipIds(params: GetChipIdsQueryParams): Promise<Response<ChipId[]>> {
    validateSchema(GetChipIdsQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/chips/search`,
      method: 'get',
      params,
      requestSchema: t.void,
      responseSchema: t.array(ChipIdSchema),
      expectedStatus: 200,
    });
  }

  // Imports the measurements of animals from the given file. (/chips/import)
  public static async importAnimals(data: File): Promise<Response<ChipImportOutcome>> {
    return apiRequester.makeRequest({
      url: `/chips/import`,
      method: 'post',
      data: fileToFormData(data),
      requestSchema: FormDataSchema,
      responseSchema: ChipImportOutcomeSchema,
      expectedStatus: 200,
    });
  }

  // Re-imports the measurements from all chips and animals imports. (/chips/import/rerun-all)
  public static async rerunImportOfAnimals(params: RerunImportOfAnimalsQueryParams): Promise<Response<EmptyString>> {
    validateSchema(RerunImportOfAnimalsQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/chips/import/rerun-all`,
      method: 'post',
      params,
      requestSchema: t.void,
      responseSchema: EmptyStringSchema,
      expectedStatus: 202,
    });
  }

  // Finds all e-mail templates. (/email-templates)
  public static async getEmailTemplates(): Promise<Response<EmailTemplateDetails[]>> {
    return apiRequester.makeRequest({
      url: `/email-templates`,
      method: 'get',
      requestSchema: t.void,
      responseSchema: t.array(EmailTemplateDetailsSchema),
      expectedStatus: 200,
    });
  }

  // Updates the e-mail template. (/email-templates/{emailTemplateId})
  public static async updateEmailTemplate(data: EmailTemplateUpdate, pathParams: UpdateEmailTemplatePathParams): Promise<Response<EmptyString>> {
    validateSchema(UpdateEmailTemplatePathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/email-templates/${pathParams.emailTemplateId}`,
      method: 'put',
      data,
      requestSchema: EmailTemplateUpdateSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Creates a genotype export in a database that when processed - generates a csv with genotypes of animals that match the search criteria. (/genotype-exports/export)
  public static async createGenotypeExport(data: TableFiltersExcludingOrderIds): Promise<Response<GenotypeExportAnimals>> {
    return apiRequester.makeRequest({
      url: `/genotype-exports/export`,
      method: 'post',
      data,
      requestSchema: TableFiltersExcludingOrderIdsSchema,
      responseSchema: GenotypeExportAnimalsSchema,
      expectedStatus: 200,
    });
  }

  // Creates a genotype export in a database that when processed - generates a csv with genotypes of the animals. (/genotype-exports/export/subset)
  public static async createGenotypeExportSubset(params: CreateGenotypeExportSubsetQueryParams): Promise<Response<GenotypeExportAnimals>> {
    validateSchema(CreateGenotypeExportSubsetQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/genotype-exports/export/subset`,
      method: 'post',
      params,
      requestSchema: t.void,
      responseSchema: GenotypeExportAnimalsSchema,
      expectedStatus: 200,
    });
  }

  // Downloads the genotype export. (/genotype-exports/{genotypeExportId})
  public static async downloadGenotypeExport(pathParams: DownloadGenotypeExportPathParams): Promise<Response<ArrayBuffer>> {
    validateSchema(DownloadGenotypeExportPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/genotype-exports/${pathParams.genotypeExportId}`,
      method: 'get',
      requestSchema: t.void,
      responseType: 'arraybuffer',
      responseSchema: ArrayBufferSchema,
      expectedStatus: 200,
    });
  }

  // Finds a page of genotype exports that match the search criteria. (/genotype-exports/find)
  public static async findAllGenotypeExports(data: TableFilter[], params: FindAllGenotypeExportsQueryParams): Promise<Response<PagedModelGenotypeExport>> {
    validateSchema(FindAllGenotypeExportsQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/genotype-exports/find`,
      method: 'post',
      params,
      data,
      requestSchema: t.array(TableFilterSchema),
      responseSchema: PagedModelGenotypeExportSchema,
      expectedStatus: 200,
    });
  }

  // Starts the processing of the genotype exports. (/genotype-exports/process)
  public static async processGenotypeExports(): Promise<Response<EmptyString>> {
    return apiRequester.makeRequest({
      url: `/genotype-exports/process`,
      method: 'post',
      requestSchema: t.void,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Gets the logout URI. (/logout)
  public static async logout(): Promise<Response<LogoutUri>> {
    return apiRequester.makeRequest({
      url: `/logout`,
      method: 'post',
      requestSchema: t.void,
      responseSchema: LogoutUriSchema,
      expectedStatus: 200,
    });
  }

  // Computes genotypes for a parent animal with unknown DNA. (/measurements/computed-genotypes)
  public static async getComputedGenotypes(params: GetComputedGenotypesQueryParams): Promise<Response<ArrayBuffer>> {
    validateSchema(GetComputedGenotypesQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/measurements/computed-genotypes`,
      method: 'get',
      params,
      requestSchema: t.void,
      responseType: 'arraybuffer',
      responseSchema: ArrayBufferSchema,
      expectedStatus: 200,
    });
  }

  // Finds measurements of snps with the given categories of given animals. (/measurements)
  public static async getMeasurements(params: GetMeasurementsQueryParams): Promise<Response<Measurements>> {
    validateSchema(GetMeasurementsQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/measurements`,
      method: 'get',
      params,
      requestSchema: t.void,
      responseSchema: MeasurementsSchema,
      expectedStatus: 200,
    });
  }

  // Generates a csv with microsatellites of animals with given ids. (/microsatellites/export)
  public static async exportMicrosatellites(params: ExportMicrosatellitesQueryParams): Promise<Response<ArrayBuffer>> {
    validateSchema(ExportMicrosatellitesQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/microsatellites/export`,
      method: 'get',
      params,
      requestSchema: t.void,
      responseType: 'arraybuffer',
      responseSchema: ArrayBufferSchema,
      expectedStatus: 200,
    });
  }

  // Gets microsatellites of the animal. (/animals/{animalId}/microsatellites)
  public static async getMicrosatellites(pathParams: GetMicrosatellitesPathParams): Promise<Response<MatchedMicrosatellite[]>> {
    validateSchema(GetMicrosatellitesPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/animals/${pathParams.animalId}/microsatellites`,
      method: 'get',
      requestSchema: t.void,
      responseSchema: t.array(MatchedMicrosatelliteSchema),
      expectedStatus: 200,
    });
  }

  // Finds details about customers from the orders of logged in user. (/orders/customers)
  public static async getCustomerDetails(params: GetCustomerDetailsQueryParams): Promise<Response<OrderCustomerDetails[]>> {
    validateSchema(GetCustomerDetailsQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/orders/customers`,
      method: 'get',
      params,
      requestSchema: t.void,
      responseSchema: t.array(OrderCustomerDetailsSchema),
      expectedStatus: 200,
    });
  }

  // Updates the customer details of the order. (/orders/{orderId}/customers)
  public static async updateCustomer(data: OrderUserDetails, pathParams: UpdateCustomerPathParams): Promise<Response<EmptyString>> {
    validateSchema(UpdateCustomerPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/orders/${pathParams.orderId}/customers`,
      method: 'put',
      data,
      requestSchema: OrderUserDetailsSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Updates the customer details of the orders that match the search criteria. (/orders/customers)
  public static async updateCustomers(data: UpdateOrdersCustomer): Promise<Response<EmptyString>> {
    return apiRequester.makeRequest({
      url: `/orders/customers`,
      method: 'put',
      data,
      requestSchema: UpdateOrdersCustomerSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Updates the customers' email of the orders that match the search criteria. (/orders/customers/email)
  public static async updateCustomersEmail(data: UpdateOrdersEmail): Promise<Response<EmptyString>> {
    return apiRequester.makeRequest({
      url: `/orders/customers/email`,
      method: 'put',
      data,
      requestSchema: UpdateOrdersEmailSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Updates the customers' email of the orders. (/orders/customers/email/subset)
  public static async updateCustomersEmailSubset(data: UpdateEmail, params: UpdateCustomersEmailSubsetQueryParams): Promise<Response<EmptyString>> {
    validateSchema(UpdateCustomersEmailSubsetQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/orders/customers/email/subset`,
      method: 'put',
      params,
      data,
      requestSchema: UpdateEmailSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Updates the customer details of the orders. (/orders/customers/subset)
  public static async updateCustomersSubset(data: OrderUserDetails, params: UpdateCustomersSubsetQueryParams): Promise<Response<EmptyString>> {
    validateSchema(UpdateCustomersSubsetQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/orders/customers/subset`,
      method: 'put',
      params,
      data,
      requestSchema: OrderUserDetailsSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Exports the orders that match the search criteria to an excel file. (/orders/export)
  public static async exportOrders(data: TableFiltersExcludingOrderIds, params: ExportOrdersQueryParams): Promise<Response<ArrayBuffer>> {
    validateSchema(ExportOrdersQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/orders/export`,
      method: 'post',
      params,
      data,
      requestSchema: TableFiltersExcludingOrderIdsSchema,
      responseType: 'arraybuffer',
      responseSchema: ArrayBufferSchema,
      expectedStatus: 200,
    });
  }

  // Exports the orders that match the search criteria to a csv file in a specific format requested by the Germany. (/orders/export-for-germany)
  public static async exportOrdersForGermany(data: TableFiltersExcludingOrderIds, params: ExportOrdersForGermanyQueryParams): Promise<Response<ArrayBuffer>> {
    validateSchema(ExportOrdersForGermanyQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/orders/export-for-germany`,
      method: 'post',
      params,
      data,
      requestSchema: TableFiltersExcludingOrderIdsSchema,
      responseType: 'arraybuffer',
      responseSchema: ArrayBufferSchema,
      expectedStatus: 200,
    });
  }

  // Exports the orders to an excel file. (/orders/export/subset)
  public static async exportOrdersSubset(params: ExportOrdersSubsetQueryParams): Promise<Response<ArrayBuffer>> {
    validateSchema(ExportOrdersSubsetQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/orders/export/subset`,
      method: 'get',
      params,
      requestSchema: t.void,
      responseType: 'arraybuffer',
      responseSchema: ArrayBufferSchema,
      expectedStatus: 200,
    });
  }

  // Exports the orders to a csv file in a specific format requested by the Germany. (/orders/export-for-germany/subset)
  public static async exportOrdersSubsetForGermany(params: ExportOrdersSubsetForGermanyQueryParams): Promise<Response<ArrayBuffer>> {
    validateSchema(ExportOrdersSubsetForGermanyQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/orders/export-for-germany/subset`,
      method: 'get',
      params,
      requestSchema: t.void,
      responseType: 'arraybuffer',
      responseSchema: ArrayBufferSchema,
      expectedStatus: 200,
    });
  }

  // Generates a genotyping with the given details. (/orders/genotyping-request)
  public static async generateGenotypingRequest(data: OrderDetailsForGenotypingRequest): Promise<Response<ArrayBuffer>> {
    return apiRequester.makeRequest({
      url: `/orders/genotyping-request`,
      method: 'post',
      data,
      requestSchema: OrderDetailsForGenotypingRequestSchema,
      responseType: 'arraybuffer',
      responseSchema: ArrayBufferSchema,
      expectedStatus: 200,
    });
  }

  // Generates a sample sheet for the orders that match the search criteria. (/orders/sample-sheet)
  public static async generateSampleSheet(data: TableFiltersExcludingOrderIds, params: GenerateSampleSheetQueryParams): Promise<Response<ArrayBuffer>> {
    validateSchema(GenerateSampleSheetQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/orders/sample-sheet`,
      method: 'post',
      params,
      data,
      requestSchema: TableFiltersExcludingOrderIdsSchema,
      responseType: 'arraybuffer',
      responseSchema: ArrayBufferSchema,
      expectedStatus: 200,
    });
  }

  // Generates a sample sheet for the orders. (/orders/sample-sheet/subset)
  public static async generateSampleSheetSubset(params: GenerateSampleSheetSubsetQueryParams): Promise<Response<ArrayBuffer>> {
    validateSchema(GenerateSampleSheetSubsetQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/orders/sample-sheet/subset`,
      method: 'post',
      params,
      requestSchema: t.void,
      responseType: 'arraybuffer',
      responseSchema: ArrayBufferSchema,
      expectedStatus: 200,
    });
  }

  // Generates a CDCB export for the orders that match the search criteria. (/orders/export/cdcb)
  public static async getCDCBExport(data: TableFiltersExcludingOrderIds): Promise<Response<ArrayBuffer>> {
    return apiRequester.makeRequest({
      url: `/orders/export/cdcb`,
      method: 'post',
      data,
      requestSchema: TableFiltersExcludingOrderIdsSchema,
      responseType: 'arraybuffer',
      responseSchema: ArrayBufferSchema,
      expectedStatus: 200,
    });
  }

  // Generates a CDCB export for the orders.  (/orders/export/cdcb/subset)
  public static async getCDCBExportSubset(params: GetCDCBExportSubsetQueryParams): Promise<Response<ArrayBuffer>> {
    validateSchema(GetCDCBExportSubsetQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/orders/export/cdcb/subset`,
      method: 'get',
      params,
      requestSchema: t.void,
      responseType: 'arraybuffer',
      responseSchema: ArrayBufferSchema,
      expectedStatus: 200,
    });
  }

  // Sets subsidy for the orders that match the search criteria and exports them to an excel file. (/orders/export-by-association)
  public static async markForSubsidyAndExport(data: TableFiltersExcludingOrderIds): Promise<Response<ArrayBuffer>> {
    return apiRequester.makeRequest({
      url: `/orders/export-by-association`,
      method: 'post',
      data,
      requestSchema: TableFiltersExcludingOrderIdsSchema,
      responseType: 'arraybuffer',
      responseSchema: ArrayBufferSchema,
      expectedStatus: 200,
    });
  }

  // Sets subsidy for the orders and exports them to an excel file. (/orders/export-by-association/subset)
  public static async markForSubsidyAndExportSubset(params: MarkForSubsidyAndExportSubsetQueryParams): Promise<Response<ArrayBuffer>> {
    validateSchema(MarkForSubsidyAndExportSubsetQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/orders/export-by-association/subset`,
      method: 'post',
      params,
      requestSchema: t.void,
      responseType: 'arraybuffer',
      responseSchema: ArrayBufferSchema,
      expectedStatus: 200,
    });
  }

  // Adds order types from the csv file to the existing orders. (/orders/import/order-types)
  public static async importOrderTypes(data: File): Promise<Response<EmptyString>> {
    return apiRequester.makeRequest({
      url: `/orders/import/order-types`,
      method: 'post',
      data: fileToFormData(data),
      requestSchema: FormDataSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Imports the orders from the csv file. (/orders/import)
  public static async importOrders(data: File, params: ImportOrdersQueryParams): Promise<Response<ImportedOrders>> {
    validateSchema(ImportOrdersQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/orders/import`,
      method: 'post',
      params,
      data: fileToFormData(data),
      requestSchema: FormDataSchema,
      responseSchema: ImportedOrdersSchema,
      expectedStatus: 201,
    });
  }

  // Creates invoicing documents for the orders that match the search criteria. (/orders/billing-bases)
  public static async createInvoicingDocuments(data: TableFiltersExcludingOrderIds, params: CreateInvoicingDocumentsQueryParams): Promise<Response<BillingBasesExport>> {
    validateSchema(CreateInvoicingDocumentsQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/orders/billing-bases`,
      method: 'post',
      params,
      data,
      requestSchema: TableFiltersExcludingOrderIdsSchema,
      responseSchema: BillingBasesExportSchema,
      expectedStatus: 201,
    });
  }

  // Creates invoicing documents for the orders. (/orders/billing-bases/subset)
  public static async createInvoicingDocumentsSubset(params: CreateInvoicingDocumentsSubsetQueryParams): Promise<Response<BillingBasesExport>> {
    validateSchema(CreateInvoicingDocumentsSubsetQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/orders/billing-bases/subset`,
      method: 'post',
      params,
      requestSchema: t.void,
      responseSchema: BillingBasesExportSchema,
      expectedStatus: 201,
    });
  }

  // Deletes the invoicing documents for the order type of the order. (/orders/{orderId}/order-types/{orderTypeId}/billing-basis)
  public static async deleteInvoicingDocuments(pathParams: DeleteInvoicingDocumentsPathParams): Promise<Response<EmptyString>> {
    validateSchema(DeleteInvoicingDocumentsPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/orders/${pathParams.orderId}/order-types/${pathParams.orderTypeId}/billing-basis`,
      method: 'delete',
      requestSchema: t.void,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Gets the invoicing documents. (/orders/billing-bases/{fileKey})
  public static async getInvoicingDocuments(pathParams: GetInvoicingDocumentsPathParams): Promise<Response<ArrayBuffer>> {
    validateSchema(GetInvoicingDocumentsPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/orders/billing-bases/${pathParams.fileKey}`,
      method: 'get',
      requestSchema: t.void,
      responseType: 'arraybuffer',
      responseSchema: ArrayBufferSchema,
      expectedStatus: 200,
    });
  }

  // Sets the order types that match the search criteria as ready for billing. (/orders/ready-for-billing)
  public static async setOrdersAsReadyForBilling(data: TableFiltersExcludingOrderIds, params: SetOrdersAsReadyForBillingQueryParams): Promise<Response<EmptyString>> {
    validateSchema(SetOrdersAsReadyForBillingQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/orders/ready-for-billing`,
      method: 'post',
      params,
      data,
      requestSchema: TableFiltersExcludingOrderIdsSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Sets the order types of the orders as ready for billing. (/orders/ready-for-billing/subset)
  public static async setSubsetOfOrdersAsReadyForBilling(params: SetSubsetOfOrdersAsReadyForBillingQueryParams): Promise<Response<EmptyString>> {
    validateSchema(SetSubsetOfOrdersAsReadyForBillingQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/orders/ready-for-billing/subset`,
      method: 'post',
      params,
      requestSchema: t.void,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Deletes the protocol of the order. (/protocols/{orderId})
  public static async deleteProtocol(pathParams: DeleteProtocolPathParams): Promise<Response<EmptyString>> {
    validateSchema(DeleteProtocolPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/protocols/${pathParams.orderId}`,
      method: 'delete',
      requestSchema: t.void,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Generates Czech and/or international protocol for the given order based on its order types. (/protocols/{orderId})
  public static async generateProtocol(data: NewProtocolDetails, pathParams: GenerateProtocolPathParams): Promise<Response<ArrayBuffer>> {
    validateSchema(GenerateProtocolPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/protocols/${pathParams.orderId}`,
      method: 'post',
      data,
      requestSchema: NewProtocolDetailsSchema,
      responseType: 'arraybuffer',
      responseSchema: ArrayBufferSchema,
      expectedStatus: 200,
    });
  }

  // Gets order details required to create a protocol. (/protocols/{orderId}/data)
  public static async getDataForProtocol(pathParams: GetDataForProtocolPathParams): Promise<Response<OrderDetailsForProtocol>> {
    validateSchema(GetDataForProtocolPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/protocols/${pathParams.orderId}/data`,
      method: 'get',
      requestSchema: t.void,
      responseSchema: OrderDetailsForProtocolSchema,
      expectedStatus: 200,
    });
  }

  // Downloads either Czech PDF protocol or both Czech and international protocols compressed in ZIP. (/protocols/{orderId}/details)
  public static async getProtocol(pathParams: GetProtocolPathParams): Promise<Response<ArrayBuffer>> {
    validateSchema(GetProtocolPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/protocols/${pathParams.orderId}/details`,
      method: 'get',
      requestSchema: t.void,
      responseType: 'arraybuffer',
      responseSchema: ArrayBufferSchema,
      expectedStatus: 200,
    });
  }

  // Downloads the protocols for the orders that match the search criteria and returns them in a compressed archive. (/protocols)
  public static async getProtocols(data: TableFiltersExcludingOrderIds, params: GetProtocolsQueryParams): Promise<Response<ArrayBuffer>> {
    validateSchema(GetProtocolsQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/protocols`,
      method: 'post',
      params,
      data,
      requestSchema: TableFiltersExcludingOrderIdsSchema,
      responseType: 'arraybuffer',
      responseSchema: ArrayBufferSchema,
      expectedStatus: 200,
    });
  }

  // Downloads the protocols for the orders and returns them in a compressed archive. (/protocols/subset)
  public static async getProtocolsSubset(params: GetProtocolsSubsetQueryParams): Promise<Response<ArrayBuffer>> {
    validateSchema(GetProtocolsSubsetQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/protocols/subset`,
      method: 'get',
      params,
      requestSchema: t.void,
      responseType: 'arraybuffer',
      responseSchema: ArrayBufferSchema,
      expectedStatus: 200,
    });
  }

  // Create a new order type. (/order-types)
  public static async createOrderType(data: NewOrderTypeItem): Promise<Response<EmptyString>> {
    return apiRequester.makeRequest({
      url: `/order-types`,
      method: 'post',
      data,
      requestSchema: NewOrderTypeItemSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Finds all order types. (/order-types)
  public static async getOrderTypes(): Promise<Response<OrderTypeItem[]>> {
    return apiRequester.makeRequest({
      url: `/order-types`,
      method: 'get',
      requestSchema: t.void,
      responseSchema: t.array(OrderTypeItemSchema),
      expectedStatus: 200,
    });
  }

  // Updates the order type. (/order-types/{orderTypeId})
  public static async updateOrderType(data: UpdateOrderTypeItem, pathParams: UpdateOrderTypePathParams): Promise<Response<EmptyString>> {
    validateSchema(UpdateOrderTypePathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/order-types/${pathParams.orderTypeId}`,
      method: 'put',
      data,
      requestSchema: UpdateOrderTypeItemSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Validates the order type. (/order-types/validation)
  public static async validateOrderType(data: OrderTypeValidation): Promise<Response<EmptyString>> {
    return apiRequester.makeRequest({
      url: `/order-types/validation`,
      method: 'post',
      data,
      requestSchema: OrderTypeValidationSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Updates the order types of the orders by provided search criteria with the provided details. (/orders/order-types)
  public static async addOrderTypes(data: TableFiltersExcludingOrderIds, params: AddOrderTypesQueryParams): Promise<Response<EmptyString>> {
    validateSchema(AddOrderTypesQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/orders/order-types`,
      method: 'put',
      params,
      data,
      requestSchema: TableFiltersExcludingOrderIdsSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Updates the order types of the orders with the provided ids with the provided details. (/orders/order-types/subset)
  public static async addOrderTypesSubset(params: AddOrderTypesSubsetQueryParams): Promise<Response<EmptyString>> {
    validateSchema(AddOrderTypesSubsetQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/orders/order-types/subset`,
      method: 'put',
      params,
      requestSchema: t.void,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Adds new order types to the order. (/orders/{orderId}/order-types)
  public static async addOrderTypesToOrder(data: PositiveLong[], pathParams: AddOrderTypesToOrderPathParams): Promise<Response<EmptyString>> {
    validateSchema(AddOrderTypesToOrderPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/orders/${pathParams.orderId}/order-types`,
      method: 'put',
      data,
      requestSchema: t.array(PositiveLongSchema),
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Cancels the subsidy for the orders that match the search criteria. (/orders/cancel-subsidy)
  public static async cancelSubsidyOfOrders(data: TableFiltersExcludingOrderIds): Promise<Response<EmptyString>> {
    return apiRequester.makeRequest({
      url: `/orders/cancel-subsidy`,
      method: 'post',
      data,
      requestSchema: TableFiltersExcludingOrderIdsSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Cancels the subsidy for the orders. (/orders/cancel-subsidy/subset)
  public static async cancelSubsidySubset(params: CancelSubsidySubsetQueryParams): Promise<Response<EmptyString>> {
    validateSchema(CancelSubsidySubsetQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/orders/cancel-subsidy/subset`,
      method: 'post',
      params,
      requestSchema: t.void,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Creates new order. (/orders)
  public static async createOrder(data: NewOrder): Promise<Response<EmptyString>> {
    return apiRequester.makeRequest({
      url: `/orders`,
      method: 'post',
      data,
      requestSchema: NewOrderSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Creates new orders. (/orders/bulk)
  public static async createOrdersInBulk(data: NewOrderInBulk): Promise<Response<EmptyString>> {
    return apiRequester.makeRequest({
      url: `/orders/bulk`,
      method: 'post',
      data,
      requestSchema: NewOrderInBulkSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Deletes the order. (/orders/{orderId})
  public static async deleteOrder(pathParams: DeleteOrderPathParams): Promise<Response<EmptyString>> {
    validateSchema(DeleteOrderPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/orders/${pathParams.orderId}`,
      method: 'delete',
      requestSchema: t.void,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Finds a page of orders that match the search criteria. (/orders/find)
  public static async findAllOrders(data: TableFilter[], params: FindAllOrdersQueryParams): Promise<Response<PagedModelOrder>> {
    validateSchema(FindAllOrdersQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/orders/find`,
      method: 'post',
      params,
      data,
      requestSchema: t.array(TableFilterSchema),
      responseSchema: PagedModelOrderSchema,
      expectedStatus: 200,
    });
  }

  // Finds details about the order required for a genotyping request. (/orders/{orderId}/genotyping-request-data)
  public static async getDataForGenotypingRequest(pathParams: GetDataForGenotypingRequestPathParams): Promise<Response<OrderDetailsForGenotypingRequest>> {
    validateSchema(GetDataForGenotypingRequestPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/orders/${pathParams.orderId}/genotyping-request-data`,
      method: 'get',
      requestSchema: t.void,
      responseSchema: OrderDetailsForGenotypingRequestSchema,
      expectedStatus: 200,
    });
  }

  // Finds details about the last order of the logged in user. (/orders/order-data)
  public static async getLastOrderData(params: GetLastOrderDataQueryParams): Promise<Response<LastOrderUserDetails>> {
    validateSchema(GetLastOrderDataQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/orders/order-data`,
      method: 'get',
      params,
      requestSchema: t.void,
      responseSchema: LastOrderUserDetailsSchema,
      expectedStatus: 200,
    });
  }

  // Finds details about the order. (/orders/{orderId})
  public static async getOrderDetails(pathParams: GetOrderDetailsPathParams): Promise<Response<OrderDetails>> {
    validateSchema(GetOrderDetailsPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/orders/${pathParams.orderId}`,
      method: 'get',
      requestSchema: t.void,
      responseSchema: OrderDetailsSchema,
      expectedStatus: 200,
    });
  }

  // Sets the orders that match the search criteria as isolated. (/orders/isolation)
  public static async isolateOrders(data: TableFiltersExcludingOrderIds): Promise<Response<EmptyString>> {
    return apiRequester.makeRequest({
      url: `/orders/isolation`,
      method: 'post',
      data,
      requestSchema: TableFiltersExcludingOrderIdsSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Sets the orders as isolated. (/orders/isolation/subset)
  public static async isolateSubsetOfOrders(params: IsolateSubsetOfOrdersQueryParams): Promise<Response<EmptyString>> {
    validateSchema(IsolateSubsetOfOrdersQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/orders/isolation/subset`,
      method: 'post',
      params,
      requestSchema: t.void,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Removes the provided order types from the orders by provided search criteria. (/orders/order-types)
  public static async removeOrderTypes(data: TableFiltersExcludingOrderIds, params: RemoveOrderTypesQueryParams): Promise<Response<EmptyString>> {
    validateSchema(RemoveOrderTypesQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/orders/order-types`,
      method: 'delete',
      params,
      data,
      requestSchema: TableFiltersExcludingOrderIdsSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Removes the provided order types from the orders with the provided ids. (/orders/order-types/subset)
  public static async removeOrderTypesForSubset(params: RemoveOrderTypesForSubsetQueryParams): Promise<Response<EmptyString>> {
    validateSchema(RemoveOrderTypesForSubsetQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/orders/order-types/subset`,
      method: 'delete',
      params,
      requestSchema: t.void,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Finds details about orders by ear tag or barcode. (/orders/search)
  public static async searchOrder(params: SearchOrderQueryParams): Promise<Response<OrderSearch[]>> {
    validateSchema(SearchOrderQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/orders/search`,
      method: 'get',
      params,
      requestSchema: t.void,
      responseSchema: t.array(OrderSearchSchema),
      expectedStatus: 200,
    });
  }

  // Updates the order. (/orders/{orderId})
  public static async updateOrder(data: UpdateOrderDetails, pathParams: UpdateOrderPathParams): Promise<Response<EmptyString>> {
    validateSchema(UpdateOrderPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/orders/${pathParams.orderId}`,
      method: 'patch',
      data,
      requestSchema: UpdateOrderDetailsSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Validates that the laboratory number is unique. (/orders/{orderId}/{laboratoryNumber}/validation)
  public static async validateLaboratoryNumber(pathParams: ValidateLaboratoryNumberPathParams): Promise<Response<EmptyString>> {
    validateSchema(ValidateLaboratoryNumberPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/orders/${pathParams.orderId}/${pathParams.laboratoryNumber}/validation`,
      method: 'post',
      requestSchema: t.void,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Validates that the order data are valid. (/orders/validation)
  public static async validateOrderData(data: OrderValidationRequest): Promise<Response<EmptyString>> {
    return apiRequester.makeRequest({
      url: `/orders/validation`,
      method: 'post',
      data,
      requestSchema: OrderValidationRequestSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Accepts the parents from the eSkot. (/animals/origin-inconsistencies/{originInconsistencyId}/accept)
  public static async acceptParentsFromESkot(pathParams: AcceptParentsFromESkotPathParams): Promise<Response<EmptyString>> {
    validateSchema(AcceptParentsFromESkotPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/animals/origin-inconsistencies/${pathParams.originInconsistencyId}/accept`,
      method: 'post',
      requestSchema: t.void,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Activates the inactive origin inconsistency. (/animals/origin-inconsistencies/{originInconsistencyId}/activate)
  public static async activateInconsistency(pathParams: ActivateInconsistencyPathParams): Promise<Response<EmptyString>> {
    validateSchema(ActivateInconsistencyPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/animals/origin-inconsistencies/${pathParams.originInconsistencyId}/activate`,
      method: 'post',
      requestSchema: t.void,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Deactivates the origin inconsistency. (/animals/origin-inconsistencies/{originInconsistencyId}/deactivate)
  public static async deactivateInconsistency(pathParams: DeactivateInconsistencyPathParams): Promise<Response<EmptyString>> {
    validateSchema(DeactivateInconsistencyPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/animals/origin-inconsistencies/${pathParams.originInconsistencyId}/deactivate`,
      method: 'post',
      requestSchema: t.void,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Finds all origin inconsistencies that match the search criteria. (/animals/origin-inconsistencies/find)
  public static async findAllOriginInconsistencies(data: TableFilter[], params: FindAllOriginInconsistenciesQueryParams): Promise<Response<PagedModelOriginInconsistency>> {
    validateSchema(FindAllOriginInconsistenciesQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/animals/origin-inconsistencies/find`,
      method: 'post',
      params,
      data,
      requestSchema: t.array(TableFilterSchema),
      responseSchema: PagedModelOriginInconsistencySchema,
      expectedStatus: 200,
    });
  }

  // Generates a CSV with the origin inconsistencies that match the search criteria. (/animals/origin-inconsistencies/export)
  public static async getCsvWithOriginInconsistencies(data: TableFilter[]): Promise<Response<ArrayBuffer>> {
    return apiRequester.makeRequest({
      url: `/animals/origin-inconsistencies/export`,
      method: 'post',
      data,
      requestSchema: t.array(TableFilterSchema),
      responseType: 'arraybuffer',
      responseSchema: ArrayBufferSchema,
      expectedStatus: 200,
    });
  }

  // Generates a PDF for requesting origin changes. (/animals/origin-inconsistencies/{originInconsistencyId}/report)
  public static async getPdfForRequestingOriginChanges(pathParams: GetPdfForRequestingOriginChangesPathParams): Promise<Response<ArrayBuffer>> {
    validateSchema(GetPdfForRequestingOriginChangesPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/animals/origin-inconsistencies/${pathParams.originInconsistencyId}/report`,
      method: 'get',
      requestSchema: t.void,
      responseType: 'arraybuffer',
      responseSchema: ArrayBufferSchema,
      expectedStatus: 200,
    });
  }

  // Sets correct origin to iGenetika. (/animals/origin-inconsistencies/{originInconsistencyId}/report)
  public static async setCorrectOriginToIGenetika(pathParams: SetCorrectOriginToIGenetikaPathParams): Promise<Response<EmptyString>> {
    validateSchema(SetCorrectOriginToIGenetikaPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/animals/origin-inconsistencies/${pathParams.originInconsistencyId}/report`,
      method: 'post',
      requestSchema: t.void,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Searches for the parents of the animal. (/parent-search/{earTag})
  public static async searchParent(params: SearchParentQueryParams, pathParams: SearchParentPathParams): Promise<Response<ParentSearchResultDetails[]>> {
    validateSchema(SearchParentQueryParamsSchema, 'FE')(params);
    validateSchema(SearchParentPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/parent-search/${pathParams.earTag}`,
      method: 'get',
      params,
      requestSchema: t.void,
      responseSchema: t.array(ParentSearchResultDetailsSchema),
      expectedStatus: 200,
    });
  }

  // Starts the asynchronous parenthood synchronization for animal that do not yet have a paternity or maternity match. (/parenthood/synchronization)
  public static async synchronizeParenthood(): Promise<Response<EmptyString>> {
    return apiRequester.makeRequest({
      url: `/parenthood/synchronization`,
      method: 'post',
      requestSchema: t.void,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Verifies the paternity (or maternity) for the animal and the parent. (/parenthood/verify)
  public static async verifyParenthood(data: SampleIdCouple): Promise<Response<ParenthoodVerification>> {
    return apiRequester.makeRequest({
      url: `/parenthood/verify`,
      method: 'post',
      data,
      requestSchema: SampleIdCoupleSchema,
      responseSchema: ParenthoodVerificationSchema,
      expectedStatus: 200,
    });
  }

  // Verifies the paternity and maternity for the animal and the parents. (/parenthood/verify/both-parents)
  public static async verifyParenthoodForBothParents(data: SampleIdTrinity): Promise<Response<ParenthoodVerificationResult>> {
    return apiRequester.makeRequest({
      url: `/parenthood/verify/both-parents`,
      method: 'post',
      data,
      requestSchema: SampleIdTrinitySchema,
      responseSchema: ParenthoodVerificationResultSchema,
      expectedStatus: 200,
    });
  }

  // Finds qualitative trait locus of the animal. (/animals/{animalId}/qtls)
  public static async findAllQtlsByAnimal(pathParams: FindAllQtlsByAnimalPathParams): Promise<Response<Qtl[]>> {
    validateSchema(FindAllQtlsByAnimalPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/animals/${pathParams.animalId}/qtls`,
      method: 'get',
      requestSchema: t.void,
      responseSchema: t.array(QtlSchema),
      expectedStatus: 200,
    });
  }

  // Creates new SNP category. (/categories)
  public static async createCategory(data: Category): Promise<Response<EmptyString>> {
    return apiRequester.makeRequest({
      url: `/categories`,
      method: 'post',
      data,
      requestSchema: CategorySchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Finds all SNP categories. (/categories)
  public static async getCategories(): Promise<Response<CategoryDetails[]>> {
    return apiRequester.makeRequest({
      url: `/categories`,
      method: 'get',
      requestSchema: t.void,
      responseSchema: t.array(CategoryDetailsSchema),
      expectedStatus: 200,
    });
  }

  // Creates a new SNP. (/snps)
  public static async createSnp(data: Snp): Promise<Response<EmptyString>> {
    return apiRequester.makeRequest({
      url: `/snps`,
      method: 'post',
      data,
      requestSchema: SnpSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Finds a page of SNPs that match the search criteria. (/snps/find)
  public static async findAllSnps(data: TableFilter[], params: FindAllSnpsQueryParams): Promise<Response<PagedModelSnpView>> {
    validateSchema(FindAllSnpsQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/snps/find`,
      method: 'post',
      params,
      data,
      requestSchema: t.array(TableFilterSchema),
      responseSchema: PagedModelSnpViewSchema,
      expectedStatus: 200,
    });
  }

  // Validates the SNP name. (/snps/validation)
  public static async validateSnp(data: SnpValidation): Promise<Response<EmptyString>> {
    return apiRequester.makeRequest({
      url: `/snps/validation`,
      method: 'post',
      data,
      requestSchema: SnpValidationSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Creates a new sample type. (/sample-types)
  public static async createSampleType(data: NewCodeTableItem): Promise<Response<EmptyString>> {
    return apiRequester.makeRequest({
      url: `/sample-types`,
      method: 'post',
      data,
      requestSchema: NewCodeTableItemSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Finds all sample types. (/sample-types)
  public static async getSampleTypes(): Promise<Response<CodeTableItem[]>> {
    return apiRequester.makeRequest({
      url: `/sample-types`,
      method: 'get',
      requestSchema: t.void,
      responseSchema: t.array(CodeTableItemSchema),
      expectedStatus: 200,
    });
  }

  // Updates the sample type. (/sample-types/{sampleTypeId})
  public static async updateSampleType(data: NewCodeTableItem, pathParams: UpdateSampleTypePathParams): Promise<Response<EmptyString>> {
    validateSchema(UpdateSampleTypePathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/sample-types/${pathParams.sampleTypeId}`,
      method: 'put',
      data,
      requestSchema: NewCodeTableItemSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Validates the sample type. (/sample-types/validation)
  public static async validateSampleType(data: CodeTableValidation): Promise<Response<EmptyString>> {
    return apiRequester.makeRequest({
      url: `/sample-types/validation`,
      method: 'post',
      data,
      requestSchema: CodeTableValidationSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Gets side box for currently logged in user. (/side-box)
  public static async getSideBox(): Promise<Response<SideBox>> {
    return apiRequester.makeRequest({
      url: `/side-box`,
      method: 'get',
      requestSchema: t.void,
      responseSchema: SideBoxSchema,
      expectedStatus: 200,
    });
  }

  // Gets default side box configuration. (/side-box/configuration)
  public static async getSideBoxConfiguration(): Promise<Response<SideBox>> {
    return apiRequester.makeRequest({
      url: `/side-box/configuration`,
      method: 'get',
      requestSchema: t.void,
      responseSchema: SideBoxSchema,
      expectedStatus: 200,
    });
  }

  // Hides the side box for the currently logged in user. (/side-box/hide)
  public static async hideSideBox(): Promise<Response<EmptyString>> {
    return apiRequester.makeRequest({
      url: `/side-box/hide`,
      method: 'post',
      requestSchema: t.void,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Updates the side box with the provided details. (/side-box)
  public static async updateSideBox(data: SideBox): Promise<Response<EmptyString>> {
    return apiRequester.makeRequest({
      url: `/side-box`,
      method: 'put',
      data,
      requestSchema: SideBoxSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Creates new test result. (/test-result-types)
  public static async createTestResultType(data: NewCodeTableItem): Promise<Response<EmptyString>> {
    return apiRequester.makeRequest({
      url: `/test-result-types`,
      method: 'post',
      data,
      requestSchema: NewCodeTableItemSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Finds all test results. (/test-result-types)
  public static async getTestResultTypes(): Promise<Response<CodeTableItem[]>> {
    return apiRequester.makeRequest({
      url: `/test-result-types`,
      method: 'get',
      requestSchema: t.void,
      responseSchema: t.array(CodeTableItemSchema),
      expectedStatus: 200,
    });
  }

  // Updates the test result. (/test-result-types/{testResultTypeId})
  public static async updateTestResultType(data: NewCodeTableItem, pathParams: UpdateTestResultTypePathParams): Promise<Response<EmptyString>> {
    validateSchema(UpdateTestResultTypePathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/test-result-types/${pathParams.testResultTypeId}`,
      method: 'put',
      data,
      requestSchema: NewCodeTableItemSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Validates the test result type. (/test-result-types/validation)
  public static async validateTestResultType(data: CodeTableValidation): Promise<Response<EmptyString>> {
    return apiRequester.makeRequest({
      url: `/test-result-types/validation`,
      method: 'post',
      data,
      requestSchema: CodeTableValidationSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Finds details about all breeders. (/breeders)
  public static async getBreeders(): Promise<Response<User[]>> {
    return apiRequester.makeRequest({
      url: `/breeders`,
      method: 'get',
      requestSchema: t.void,
      responseSchema: t.array(UserSchema),
      expectedStatus: 200,
    });
  }

  // Gets details of a currently logged in user. (/user)
  public static async getUserDetails(): Promise<Response<UserDetails>> {
    return apiRequester.makeRequest({
      url: `/user`,
      method: 'get',
      requestSchema: t.void,
      responseSchema: UserDetailsSchema,
      expectedStatus: 200,
    });
  }

  // Creates new verification outcome. (/verification-outcome-types)
  public static async createVerificationOutcomeType(data: NewVerificationOutcome): Promise<Response<EmptyString>> {
    return apiRequester.makeRequest({
      url: `/verification-outcome-types`,
      method: 'post',
      data,
      requestSchema: NewVerificationOutcomeSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Finds all verification outcomes. (/verification-outcome-types)
  public static async getVerificationOutcomeTypes(): Promise<Response<VerificationOutcome[]>> {
    return apiRequester.makeRequest({
      url: `/verification-outcome-types`,
      method: 'get',
      requestSchema: t.void,
      responseSchema: t.array(VerificationOutcomeSchema),
      expectedStatus: 200,
    });
  }

  // Updates the verification outcome. (/verification-outcome-types/{verificationOutcomeTypeId})
  public static async updateVerificationOutcomeType(data: NewVerificationOutcome, pathParams: UpdateVerificationOutcomeTypePathParams): Promise<Response<EmptyString>> {
    validateSchema(UpdateVerificationOutcomeTypePathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/verification-outcome-types/${pathParams.verificationOutcomeTypeId}`,
      method: 'put',
      data,
      requestSchema: NewVerificationOutcomeSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Validates the verification outcome type. (/verification-outcome-types/validation)
  public static async validateVerificationOutcomeType(data: CodeTableValidation): Promise<Response<EmptyString>> {
    return apiRequester.makeRequest({
      url: `/verification-outcome-types/validation`,
      method: 'post',
      data,
      requestSchema: CodeTableValidationSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Finds reproduction statistics of the last month. (/api/reproduction-statistics)
  public static async findReproductionStatistics(): Promise<Response<ReproductionStatistics>> {
    return apiRequester.makeRequest({
      url: `/api/reproduction-statistics`,
      method: 'get',
      requestSchema: t.void,
      responseSchema: ReproductionStatisticsSchema,
      expectedStatus: 200,
    });
  }

// custom endpoints

}
