import React, {FC, memo} from 'react';

import {ActionButton} from 'common/buttons';
import {SideMenu} from 'layout';
import {useOurTranslation} from 'translations';

interface Props {
    onGoBackClick(): void;
}

const GenerateRequestActionsBase: FC<Props> = props => {
    const {onGoBackClick} = props;

    const {t} = useOurTranslation('orders/GenerateRequestActions');

    return (
        <SideMenu title={t('menuTitle')}>
            <ActionButton
                onClick={onGoBackClick}
                type="goBack"
            >
                {t('back')}
            </ActionButton>
        </SideMenu>
    );
};

export const GenerateRequestActions = memo(GenerateRequestActionsBase);
