// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveOrZeroInteger, PositiveOrZeroIntegerSchema } from 'api/gen/PositiveOrZeroInteger';

const requiredPart = t.interface({
  numOfProcessedGenes: PositiveOrZeroIntegerSchema,
  probability: t.number,
});

export const ParenthoodVerificationResultSchema = excess(requiredPart);

export interface ParenthoodVerificationResult extends t.TypeOf<typeof ParenthoodVerificationResultSchema> {}
