import React, {FC, memo, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {Category} from 'api/gen/Category';
import {Snp} from 'api/gen/Snp';
import {Modal} from 'common/layout';
import {snpActions} from 'snp-management/model';
import {simpleSnpManagementSelector} from 'snp-management/model/selector';
import {useOurTranslation} from 'translations';

import {CreateCategoryForm} from '../create-category-form';
import {CreateSnpForm} from '../create-snp-form';

const SnpModalBase: FC = () => {
    const isCreateCategoryVisible = useSelector(simpleSnpManagementSelector.isCreateCategoryVisible);
    const isCreateSnpVisible = useSelector(simpleSnpManagementSelector.isCreateSnpVisible);
    const categories = useSelector(simpleSnpManagementSelector.categories);

    const dispatch = useDispatch();

    const cancelForm = useCallback(() => {
        dispatch(snpActions.closeForm());
    }, [dispatch]);

    const createSnp = useCallback((snp: Snp) => {
        dispatch(snpActions.createSnp(snp));
    }, [dispatch]);

    const createCategory = useCallback((category: Category) => {
        dispatch(snpActions.createSnpCategory(category));
    }, [dispatch]);

    const {t} = useOurTranslation('snpManagement/SnpTable');
    const translationTitle = t(
        isCreateSnpVisible
            ? 'formTranslations.createSNPRecord'
            : 'tableHeaders.addCategory',
    );

    return (
        <Modal
            title={translationTitle}
            visible={isCreateSnpVisible || isCreateCategoryVisible}
            onCancel={cancelForm}
            footer={null}
        >
            {isCreateCategoryVisible
                ? (
                    <CreateCategoryForm
                        onSubmit={createCategory}
                        onCancel={cancelForm}
                    />
                )

                : (
                    <CreateSnpForm
                        onSubmit={createSnp}
                        onCancel={cancelForm}
                        categories={categories.orElse([])}
                    />
                )}
        </Modal>
    );
};

export const SnpModal = memo(SnpModalBase);
