import {useCallback} from 'react';
import {Opt, at} from 'ts-opt';

import {OrderCustomerDetails} from '../api/gen/OrderCustomerDetails';

export const usePrefillFromExisting = (
    customers: Array<OrderCustomerDetails>,
    onPrefillFromExisting: (user: OrderCustomerDetails) => void,
): ((value: Opt<number>) => void) =>
    useCallback((value: Opt<number>) => {
        value
            .map(x => at(x)(customers).orCrash('invalid customer index'))
            .onSome(onPrefillFromExisting);
    }, [customers, onPrefillFromExisting]);
