import {t} from 'translations';
import {PaternityValidator} from 'utils/paternity-validator';
import {Errors} from 'utils/validator';

import {CategoryFormValues} from './category-form-types';

export const validate = (values: CategoryFormValues): Errors<CategoryFormValues> => {
    const validator = new PaternityValidator(values);
    validator.nonEmpty('name', t('snpManagement/SnpTable')('formTranslations.name'));

    return validator.generateErrorsObject();
};
