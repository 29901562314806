import {setItem, toggleItem} from '@fl/cmsch-fe-library';
import * as O from 'optics-ts';
import {none, opt, map} from 'ts-opt';

import {SideBox} from 'api/gen/SideBox';
import {Action} from 'app/actions';

import {DialsState, initialDialsState} from './state';

const dialsStateO = O.optic<DialsState>();

// eslint-disable-next-line complexity, max-lines-per-function
export const dialsReducer = (state: DialsState = initialDialsState, action: Action): DialsState => {
    switch (action.type) {
        case 'dials/SET_MODAL_VISIBILITY': {
            const {id, visible} = action.payload;

            return O.modify(dialsStateO.prop('modalsVisibility'))(setItem(visible)(id))(state);
        }

        case 'dials/TOGGLE_MODAL_VISIBILITY': {
            const {id} = action.payload;

            return O.modify(dialsStateO.prop('modalsVisibility'))(toggleItem(id))(state);
        }

        case 'dials/GET_SAMPLE_TYPES_SUCCESS': {
            const {sampleTypes} = action.payload;

            return O.set(dialsStateO.prop('sampleTypes'))(opt(sampleTypes))(state);
        }

        case 'dials/START_EDITING_SAMPLE_TYPE': {
            const {id} = action.payload;

            return O.set(dialsStateO.prop('sampleTypeIdBeingEdited'))(opt(id))(state);
        }

        case 'dials/UPDATE_SAMPLE_TYPE_SUCCESS':
        case 'dials/SAMPLE_TYPE_EDITING_CANCELLED': {
            return O.set(dialsStateO.prop('sampleTypeIdBeingEdited'))(none)(state);
        }

        case 'dials/GET_ORDER_TYPES_SUCCESS': {
            const {orderTypes} = action.payload;

            return O.set(dialsStateO.prop('orderTypes'))(opt(orderTypes))(state);
        }

        case 'dials/START_EDITING_ORDER_TYPE': {
            const {id} = action.payload;

            return O.set(dialsStateO.prop('orderTypeIdBeingEdited'))(opt(id))(state);
        }

        case 'dials/UPDATE_ORDER_TYPE_SUCCESS':
        case 'dials/ORDER_TYPE_EDITING_CANCELLED': {
            return O.set(dialsStateO.prop('orderTypeIdBeingEdited'))(none)(state);
        }

        case 'dials/GET_VERIFICATION_OUTCOMES_SUCCESS': {
            const {verificationOutcomes} = action.payload;

            return O.set(dialsStateO.prop('verificationOutcomes'))(opt(verificationOutcomes))(state);
        }

        case 'dials/START_EDITING_VERIFICATION_OUTCOME': {
            const {id} = action.payload;

            return O.set(dialsStateO.prop('verificationOutcomeIdBeingEdited'))(opt(id))(state);
        }

        case 'dials/UPDATE_VERIFICATION_OUTCOME_SUCCESS':
        case 'dials/VERIFICATION_OUTCOME_EDITING_CANCELLED': {
            return O.set(dialsStateO.prop('verificationOutcomeIdBeingEdited'))(none)(state);
        }

        case 'dials/GET_TEST_RESULTS_SUCCESS': {
            const {testResults} = action.payload;

            return O.set(dialsStateO.prop('testResults'))(opt(testResults))(state);
        }

        case 'dials/START_EDITING_TEST_RESULT': {
            const {id} = action.payload;

            return O.set(dialsStateO.prop('testResultIdBeingEdited'))(opt(id))(state);
        }

        case 'dials/UPDATE_TEST_RESULT_SUCCESS':
        case 'dials/TEST_RESULT_EDITING_CANCELLED': {
            return O.set(dialsStateO.prop('testResultIdBeingEdited'))(none)(state);
        }

        case 'dials/GET_MAIL_TEMPLATES_SUCCESS': {
            const {mailTemplates} = action.payload;

            return O.set(dialsStateO.prop('mailTemplates'))(opt(mailTemplates))(state);
        }

        case 'dials/START_EDITING_MAIL_TEMPLATE': {
            const {id} = action.payload;

            return O.set(dialsStateO.prop('mailTemplateIdBeingEdited'))(opt(id))(state);
        }

        case 'dials/UPDATE_MAIL_TEMPLATE_SUCCESS':
        case 'dials/MAIL_TEMPLATE_EDITING_CANCELLED': {
            return O.set(dialsStateO.prop('mailTemplateIdBeingEdited'))(none)(state);
        }

        case 'dials/GET_BREEDER_INFO_SUCCESS': {
            const {breederInfo} = action.payload;

            return O.set(dialsStateO.prop('breederInfo'))(opt(breederInfo))(state);
        }

        case 'dials/GET_BREEDER_INFO_CONFIGURATION_SUCCESS': {
            const {breederInfoConfiguration} = action.payload;

            return O.set(dialsStateO.prop('breederInfoConfiguration'))(opt(breederInfoConfiguration))(state);
        }

        case 'dials/HIDE_BREEDER_INFO': {
            return O.modify(dialsStateO.prop('breederInfo'))(
                map(({caption, content}: SideBox): SideBox => ({caption, content, show: false})),
            )(state);
        }

        default:
            return state;
    }
};
