// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { FileKey, FileKeySchema } from 'api/gen/FileKey';

const requiredPart = t.interface({
  billingBasisCsvKey: t.union([FileKeySchema, t.null]),
  billingBasisMeatAssociationCsvKey: t.union([FileKeySchema, t.null]),
  billingBasisZipKey: t.union([FileKeySchema, t.null]),
});

export const BillingBasesExportSchema = excess(requiredPart);

export interface BillingBasesExport extends t.TypeOf<typeof BillingBasesExportSchema> {}
