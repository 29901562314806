import * as O from 'optics-ts';
import {none, opt} from 'ts-opt';

import {Action} from 'app/actions';

import {initialPaternityCheckState, PaternityCheckState} from './state';

const paternityCheckStateO = O.optic<PaternityCheckState>();

export const paternityCheckReducer = (
    state: PaternityCheckState = initialPaternityCheckState,
    action: Action,
): PaternityCheckState => {
    switch (action.type) {
        case 'paternityCheck/CHECK_PATERNITY_SUCCESS': {
            const {result} = action.payload;

            return O.set(paternityCheckStateO.prop('result'))(opt(result))(state);
        }

        case 'paternityCheck/RESET_RESULTS': {
            return O.set(paternityCheckStateO.prop('result'))(none)(state);
        }

        default:
            return state;
    }
};
