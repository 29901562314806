import {Nullable} from '@fl/cmsch-fe-library';
import React, {FC, memo} from 'react';

import {SvgIcon} from 'icons';

import styles from './styles.sass';

import customerNoteSvg from 'orders/components/OrdersTable/customerNote.svg';

interface Props {
    laboratoryNote: Nullable<string>;
}

const DataCellBase: FC<Props> = props => {
    const {laboratoryNote} = props;

    return (
        <span className="justify-content-center d-flex flex-fill">
            {laboratoryNote && (
                <span
                    className={styles.laboratoryNote}
                    title={laboratoryNote}
                >
                    <SvgIcon icon={customerNoteSvg} />
                </span>
            )}
        </span>
    );
};

export const DataCell = memo(DataCellBase);
