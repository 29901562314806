import {extractFormErrorsFromResponse, putAll, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {put, call} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'translations';
import {formHelpers} from 'utils/forms';
import {showBeError} from 'utils/show-be-error';

import {sampleTypeFormName} from '../../components/SampleTypeForm/sample-type-form-values';
import {dialsAction, UpdateSampleTypeAction} from '../action';

function* execute({payload: {id, sampleType}}: UpdateSampleTypeAction): SagaIterator {
    const response = yield* call(Api.updateSampleType, sampleType, {sampleTypeId: id});

    if (response.isSuccess) {
        yield* put(formHelpers.stopSubmit(sampleTypeFormName));
        yield* put(dialsAction.updateSampleTypeSuccess());
        yield* put(dialsAction.getSampleTypes());
        yield* put(dialsAction.setModalVisibility('sampleType', false));
    } else {
        yield* put(formHelpers.stopSubmit(sampleTypeFormName, extractFormErrorsFromResponse(response)));
        yield putAll(showBeError(response, t('dials/sampleTypes')('editTitle')));
    }
}

export function* updateSampleTypeSaga(): SagaIterator {
    yield takeLatestF('dials/UPDATE_SAMPLE_TYPE', execute);
}
