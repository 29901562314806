import {classNames, formatDate, Nullable, Options} from '@fl/cmsch-fe-library';
import React, {FC, memo} from 'react';

import {OrderDetails} from 'api/gen/OrderDetails';
import {OrderDetailsForProtocol} from 'api/gen/OrderDetailsForProtocol';
import {useOurTranslation} from 'translations';
import {translateYesNo} from 'utils/translate-yes-no';

import {OrderType} from './order-type';
import {UserDetails} from './user-details';

import styles from '../DetailTable/styles.sass';
import orderDetailsStyles from './styles.sass';

export interface Props {
    order: OrderDetails;
    protocolData: Nullable<OrderDetailsForProtocol>;
    sampleTypes: Options<number>;
    orderTypes: Options<number>;
    isRoleLaborer: boolean;
    downloadBillingBases(fileKey: string): void;
    deleteBillingBase(orderId: number, orderTypeId: number): void;
}

const formatOrderDetailsDate = (dateString: Nullable<string>): Nullable<string> =>
    dateString && formatDate(dateString).orNull();

const OrderDetailsViewBase: FC<Props> = props => {
    const {
        sampleTypes, orderTypes, order, protocolData, isRoleLaborer, downloadBillingBases, deleteBillingBase,
    } = props;
    const {creationDate, sampleTypeId, processingDate, customerNote, twin, dob, id} = order;

    const {t, tCommon} = useOurTranslation('orders/OrderDetails');

    if (!protocolData && isRoleLaborer) {
        throw new Error('Missing protocol data.');
    }

    const {
        sampleId,
        breed,
        name,
        fathersSampleId,
        mothersSampleId,
        sex,
        registry,
        fathersRegistry,
    } = protocolData || order;
    const dateOfBirth = protocolData?.dateOfBirth || dob;

    const noTopBorder = isRoleLaborer && orderDetailsStyles.noTopBorder;

    return (
        <table className={classNames('table', [noTopBorder, styles.bottomMarginTbody])}>
            <thead />
            <tbody>
                <tr>
                    <th>{t('creationDate')}</th>
                    <td>{formatOrderDetailsDate(creationDate)}</td>
                </tr>

                <tr>
                    <th>{t('sampleType')}</th>
                    <td>{sampleTypes.find(x => x.value === sampleTypeId)?.label}</td>
                </tr>

                <tr>
                    <th>{t('orderType')}</th>
                    <td>
                        {order.orderTypes?.map(x => (
                            <OrderType
                                key={x.orderTypeId}
                                orderType={x}
                                orderTypes={orderTypes}
                                orderId={id}
                                downloadBillingBases={downloadBillingBases}
                                showFileLinks={isRoleLaborer}
                                deleteBillingBase={deleteBillingBase}
                            />
                        ))}
                    </td>
                </tr>

                <tr>
                    <th>{t('processingDate')}</th>
                    <td>{formatOrderDetailsDate(processingDate)}</td>
                </tr>

                <tr>
                    <th>{t('customerNote')}</th>
                    <td>{customerNote}</td>
                </tr>
            </tbody>

            <thead className="thead-light">
                <tr>
                    <th colSpan={2}>{t('animalData')}</th>
                </tr>
            </thead>

            <tbody>
                <tr>
                    <th>{tCommon('earTag')}</th>
                    <td>{sampleId}</td>
                </tr>

                <tr>
                    <th>{t('breed')}</th>
                    <td>{breed}</td>
                </tr>

                <tr>
                    <th>{t('name')}</th>
                    <td>{name}</td>
                </tr>

                <tr>
                    <th>{t('dateOfBirth')}</th>
                    <td>{formatOrderDetailsDate(dateOfBirth)}</td>
                </tr>

                <tr>
                    <th>{t('fathersSampleId')}</th>
                    <td>{fathersSampleId}</td>
                </tr>

                <tr>
                    <th>{t('mothersSampleId')}</th>
                    <td>{mothersSampleId}</td>
                </tr>

                <tr>
                    <th>{t('sex')}</th>
                    <td>{sex && tCommon(sex === 'MALE' ? 'sex.male' : 'sex.female')}</td>
                </tr>

                <tr>
                    <th>{t('registry')}</th>
                    <td>{registry}</td>
                </tr>

                <tr>
                    <th>{t('fathersRegistry')}</th>
                    <td>{fathersRegistry}</td>
                </tr>

                <tr>
                    <th>{t('twin')}</th>
                    <td>{translateYesNo(twin)}</td>
                </tr>

            </tbody>

            {!isRoleLaborer && (
                <UserDetails
                    order={order}
                />
            )}
        </table>
    );
};

export const OrderDetailsView = memo(OrderDetailsViewBase);
