import {t} from 'translations';
import {PaternityValidator} from 'utils/paternity-validator';
import {Errors} from 'utils/validator';

import {SampleTypeFormValues} from './sample-type-form-values';

export const validate = (values: SampleTypeFormValues): Errors<SampleTypeFormValues> => {
    const validator = new PaternityValidator(values);

    validator.nonEmpty('value', t('dials/sampleTypes')('value'));

    return validator.generateErrorsObject();
};
