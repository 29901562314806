import {putAll, takeLatestF, showSuccess} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'translations';
import {showBeError} from 'utils/show-be-error';

import {getOrderTableParamsDto} from '../../utils/get-order-table-params-dto';
import {CreateGenotypeExportByFilterAction, orderAction} from '../action';
import {getSelectedOrders} from './selectors';

function* execute({payload: {withoutSelected}}: CreateGenotypeExportByFilterAction): SagaIterator {
    yield* put(orderAction.setListActionActive('exportGenotypes', true));

    const {filters} = yield* call(getOrderTableParamsDto);
    const excludedOrderIds = withoutSelected ? yield* select(getSelectedOrders) : null;
    const response = yield* call(Api.createGenotypeExport, {filters, excludedOrderIds});

    if (response.isSuccess) {
        yield* put(showSuccess(
            t('orders/ListActions')('exportGenotypes'),
            t('orders/ListActions')('exportGenotypesStart'),
        ));
    } else {
        yield putAll(showBeError(response, t('orders/ListActions')('exportGenotypes')));
    }
    yield* put(orderAction.setListActionActive('exportGenotypes', false));
}

export function* createGenotypeExportsByFilterSaga(): SagaIterator {
    yield takeLatestF('order/CREATE_GENOTYPE_EXPORT_BY_FILTER', execute);
}
