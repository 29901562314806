// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';
import {StringPatternSchema} from '@fl/cmsch-fe-library';


// A file key for unique identification of a file
// Example: "genotype_2019-03-07_09-43-42.zip"

export const fileKeyRegexGen = (): RegExp => new RegExp("^[\\w\\Q!-.*'()\\E]+$");

export const fileKeyMinLength = 1;
export const fileKeyMaxLength = 255;

export const FileKeySchema = t.refinement(StringPatternSchema<FileKey>(fileKeyRegexGen()), n => n.length >= fileKeyMinLength && n.length <= fileKeyMaxLength, 'FileKey');

export type FileKey = string;
