import React, {FC} from 'react';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {pipe} from 'ts-opt';

import {State} from 'app/state';
import {Ant} from 'common/ant';
import {Container, MainPanel, PageHeading} from 'layout';
import {showErrorSimple} from 'notifications';
import {useOurTranslation} from 'translations';

import {FirstStep} from '../components/first-step';
import {ImportDropzone} from '../components/import-dropzone';
import {SecondStep} from '../components/second-step';
import {importAction, FileImportAction} from '../model';
import {ImportScreenType} from '../types/import-screen-type';

type ImportScreenTitle = 'title' | 'titleCzech' | 'titleHungarian' | 'titlePlemdat' | 'titleOrdersUpdates';

const getTitle = (importType: ImportScreenType): ImportScreenTitle => {
    switch (importType) {
        case 'importChips':
            return 'title';

        case 'czech':
            return 'titleCzech';

        case 'hungarian':
            return 'titleHungarian';

        case 'plemdat':
            return 'titlePlemdat';

        case 'ordersUpdates':
            return 'titleOrdersUpdates';

        default:
            return 'title';
    }
};

interface OuterProps {
    importType: ImportScreenType;
}

interface StateProps {
    isLoading: boolean;
}

interface DispatchProps {
    sendImport(data: File, importType: ImportScreenType): void;
    showErrorMessage(message: string): void;
}

type Props = OuterProps & StateProps & DispatchProps;

const ImportScreen: FC<Props> = props => {
    const {
        sendImport,
        showErrorMessage,
        isLoading,
        importType,
    } = props;
    const {t} = useOurTranslation('fileImport/screenImport');

    return (
        <Container maxLgWidth>
            <Ant.Row justify="center">
                <Ant.Col
                    xs={24}
                    md={20}
                    xl={16}
                >
                    <MainPanel>
                        <PageHeading>
                            <h1>{t(getTitle(importType))}</h1>
                        </PageHeading>

                        <FirstStep
                            t={t}
                            importType={importType}
                        />
                        <ImportDropzone
                            sendImport={sendImport}
                            showErrorMessage={showErrorMessage}
                            t={t}
                            isLoading={isLoading}
                            importType={importType}
                        />
                        {importType === ImportScreenType.importChips && <SecondStep t={t} />}
                    </MainPanel>
                </Ant.Col>
            </Ant.Row>
        </Container>
    );
};

const createSendImportAction = (data: File, importType: ImportScreenType): FileImportAction => {
    switch (importType) {
        case ImportScreenType.ordersUpdates: return importAction.sendOrdersUpdates(data);
        case ImportScreenType.czech: return importAction.sendOrdersImport(data, 'CZECH');
        case ImportScreenType.hungarian: return importAction.sendOrdersImport(data, 'HUNGARIAN');
        case ImportScreenType.plemdat: return importAction.sendOrdersImport(data, 'PLEMDAT');
        case ImportScreenType.importChips: return importAction.sendImportedFile(data);
    }
};

const mapStateToProps = (state: State): StateProps => ({
    isLoading: state.fileImport.isLoading,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    sendImport: (data: File, importType: ImportScreenType): void =>
        void dispatch(createSendImportAction(data, importType)),
    showErrorMessage: (message: string): void => void dispatch(showErrorSimple({message})),
});

export const Import = pipe(
    ImportScreen,
    connect(mapStateToProps, mapDispatchToProps),
);
