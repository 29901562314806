// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';
import {StringPatternSchema} from '@fl/cmsch-fe-library';


// A breed in the alpha 6 format.
// Example: "C100"

export const breedAlpha6RegexGen = (): RegExp => new RegExp("^[A-Z0-9]{1,6}$");

export const BreedAlpha6Schema = StringPatternSchema<BreedAlpha6>(breedAlpha6RegexGen());

export type BreedAlpha6 = string;
