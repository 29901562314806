// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveInteger, PositiveIntegerSchema } from 'api/gen/PositiveInteger';

const requiredPart = t.interface({
  numOfAnimals: PositiveIntegerSchema,
  numOfAnimalsToExport: PositiveIntegerSchema,
});

export const GenotypeExportAnimalsSchema = excess(requiredPart);

export interface GenotypeExportAnimals extends t.TypeOf<typeof GenotypeExportAnimalsSchema> {}
