import {downloadFileFromResponse, extractFormErrorsFromResponse, takeLatestF} from '@fl/cmsch-fe-library';
import * as routerActions from 'redux-first-history';
import {SagaIterator} from 'redux-saga';
import {put, call} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {formHelpers} from 'utils/forms';

import {GenerateGenotypingRequestAction} from '../action';

// eslint-disable-next-line max-lines-per-function
function* execute(action: GenerateGenotypingRequestAction): SagaIterator {
    const {data, orderId} = action.payload;

    const {
        barcode,
        breed,
        customerName,
        dateOfBirth,
        fathersBreed,
        fathersRegistry,
        mothersFatherBreed,
        mothersFatherRegistry,
        mothersSampleId,
        sampleId,
        sex,
    } = data;

    if (!customerName || !sampleId) {
        return;
    }

    yield* put(formHelpers.startSubmit('generateRequest'));

    const response = yield* call(Api.generateGenotypingRequest, {
        barcode,
        breed,
        customerName,
        dateOfBirth,
        fathersBreed,
        fathersRegistry,
        mothersFatherBreed,
        mothersFatherRegistry,
        mothersSampleId,
        sampleId,
        sex,
    });

    if (response.isSuccess) {
        yield* call(downloadFileFromResponse, response);
        yield* put(routerActions.push(`/orders/${orderId}`));
        yield* put(formHelpers.reset('generateRequest'));
        yield* put(formHelpers.stopSubmit('generateRequest'));
    } else {
        yield* put(formHelpers.stopSubmit('generateRequest', extractFormErrorsFromResponse(response)));
    }
}

export function* generateGenotypingRequestSaga(): SagaIterator {
    yield takeLatestF('order/GENERATE_GENOTYPING_REQUEST', execute);
}
