// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { TableFilter, TableFilterSchema } from 'api/gen/TableFilter';

const requiredPart = t.interface({
  excludedOrderIds: t.union([t.array(t.Integer), t.null]),
  filters: t.union([t.array(TableFilterSchema), t.null]),
});

export const TableFiltersExcludingOrderIdsSchema = excess(requiredPart);

export interface TableFiltersExcludingOrderIds extends t.TypeOf<typeof TableFiltersExcludingOrderIdsSchema> {}
