import {takeLatestF, putAll} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {put, call} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {appAction} from 'app/model/action';
import {t} from 'translations';
import {showBeError} from 'utils/show-be-error';

import {dialsAction, GetBreederInfoAction} from '../action';

function* execute(_action: GetBreederInfoAction): SagaIterator {
    yield* put(appAction.addLoadingContent('getBreederInfo'));
    const response = yield* call(Api.getSideBox);

    if (response.isSuccess) {
        yield* put(dialsAction.getBreederInfoSuccess(response.data));
    } else {
        yield putAll(showBeError(response, t('dials/screenList')('breederInfoCaption')));
    }
    yield* put(appAction.removeLoadingContent('getBreederInfo'));
}

export function* getBreederInfoSaga(): SagaIterator {
    yield takeLatestF('dials/GET_BREEDER_INFO', execute);
}
