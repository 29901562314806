import {Options} from '@fl/cmsch-fe-library';
import React, {FC, memo} from 'react';

import {FooterButtons} from 'common/buttons';
import {useOurTranslation} from 'translations';
import {useForm} from 'utils/forms';

import {
    newBulkOrderInitialValues,
    newBulkOrderFormName,
    NewBulkOrderFormValues,
} from '../NewBulkForm/new-bulk-order-form-values';
import {validate} from '../NewBulkForm/validations';

interface Props {
    sampleTypes: Options<number>;
    orderTypes: Options<number>;
    onPreviousClick(): void;
    onSubmit(values: NewBulkOrderFormValues): void;
}

const OrderBulkDetailsFormBase: FC<Props> = props => {
    const {
        sampleTypes,
        orderTypes,
        onPreviousClick,
        onSubmit,
    } = props;

    const {t} = useOurTranslation('orders/OrderDetailsForm');

    const {Form, Fields, renderErrors} = useForm({
        form: newBulkOrderFormName,
        destroyOnUnmount: false,
        forceUnregisterOnUnmount: true,
        keepDirtyOnReinitialize: true,
        initialValues: newBulkOrderInitialValues,
        validate,
        onSubmit,
    });

    return (
        <Form>
            {renderErrors()}

            <fieldset>
                <legend>{t('caption')}</legend>

                <div className="row">
                    <div className="col-12 col-md-6">
                        <Fields.NumberSelect
                            name="sampleTypeId"
                            label={t('sampleTypeId')}
                            placeholder={t('sampleTypeIdPlaceholder')}
                            options={sampleTypes}
                            isRequired
                            clearable={false}
                            searchable={false}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-md-6">
                        <Fields.NumberMultiSelect
                            name="orderTypeIds"
                            label={t('orderTypeIds')}
                            placeholder={t('orderTypeIdsPlaceholder')}
                            options={orderTypes}
                            isRequired
                        />
                    </div>
                </div>

            </fieldset>

            <FooterButtons
                cancelTitle={t('back')}
                onCancel={onPreviousClick}
                submitTitle={t('submit')}
            />
        </Form>
    );
};

export const OrderBulkDetailsForm = memo(OrderBulkDetailsFormBase);
