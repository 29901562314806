import {takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {put, call} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';

import {orderAction, RefreshMotherAction} from '../action';

function* execute({payload: {sampleId}}: RefreshMotherAction): SagaIterator {
    const response = yield* call(Api.findAnimal, {sampleId, registry: null});

    if (response.isSuccess) {
        yield* put(orderAction.refreshMotherSuccess(response.data));
    }
}

export function* refreshMotherSaga(): SagaIterator {
    yield takeLatestF('order/REFRESH_MOTHER', execute);
}
