import {putAll, showInfo, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {showSuccessSimple} from 'notifications';
import {t} from 'translations';
import {showBeError} from 'utils/show-be-error';

import {animalsAction, MarkAsActive} from '../action';

const te = t('animals/OriginInconsistenciesTable');

function* execute({payload: {originInconsistencyId, earTag}}: MarkAsActive): SagaIterator {
    yield* put(animalsAction.setInconsistenciesActionActive(originInconsistencyId, true));

    const response = yield* call(Api.activateInconsistency, {originInconsistencyId});

    if (response.isSuccess) {
        yield* put(showSuccessSimple({message: te('messages.inactiveSuccess')}));
        yield* put(animalsAction.getOriginInconsistencies(opt({action: 'refresh'})));
    } else if (response.isConflict) {
        yield* put(showInfo(te('messages.inactiveError'), te('messages.reactivationFailed', {UZ: earTag})));
    } else {
        yield putAll(showBeError(response, te('messages.inactiveError')));
    }

    yield* put(animalsAction.setInconsistenciesActionActive(originInconsistencyId, false));
}

export function* markAsActiveSaga(): SagaIterator {
    yield takeLatestF('animals/MARK_AS_ACTIVE', execute);
}
