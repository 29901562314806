import React from 'react';
import {Route} from 'react-router-dom';

import {List} from './screens';

export const genotypeExportsRoutesWithoutFetching: Array<RegExp> = [];

export const exportGenotypesRoutes = [
    (
        <Route
            exact
            path="/genotype-exports"
            component={List}
            key="genotype-exports"
        />
    ),
    (
        <Route
            exact
            path="/administration/genotype-exports"
            component={List}
            key="genotype-exports"
        />
    ),
];
