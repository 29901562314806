import {classNames, Options, SelectType} from '@fl/cmsch-fe-library';
import React, {Fragment, ReactNode} from 'react';

import {OrderTypeInOrder} from 'api/gen/OrderTypeInOrder';
import {t} from 'translations';

import {OrderTableType} from './order-table-type';

import styles from './styles.sass';

const findOrderType = (orderTypeId: number, orderTypesOptions?: Options<number>): string =>
    orderTypesOptions?.find(x => x.value === orderTypeId)?.label || '';

export const formatOrderTypesField = (
    _value: Array<SelectType>,
    row: OrderTableType,
    options?: Options<number>,
): ReactNode =>
    row.orderTypes.map((ot: OrderTypeInOrder, index: number, otArray: Array<OrderTypeInOrder>): ReactNode => {
        const orderTypeClassNames = classNames(
            styles.orderType,
            ot.exportDate ? styles.orderTypeBilled : styles.orderTypeNotBilled,
            index > 0 && otArray[index - 1].exportDate && ot.exportDate && styles.spaceBetweenBilledOrderTypes,
        );

        return (
            <Fragment key={ot.id}>
                {index > 0 && !otArray[index - 1].exportDate && !ot.exportDate && (
                    <span className={styles.orderTypeSep}>|</span>
                )}
                <span
                    className={orderTypeClassNames}
                    title={`${findOrderType(ot.id, options)} - ${t('orders/OrdersTable')(
                        ot.exportDate ? 'stateFullyBilled' : 'unbilled',
                    )}`}
                >
                    {ot.id}
                </span>
            </Fragment>
        );
    });
