import React, {FC, useCallback, useEffect} from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps, withRouter} from 'react-router';
import {Dispatch} from 'redux';
import * as routerActions from 'redux-first-history';
import {Opt, pipe} from 'ts-opt';

import {CodeTableItem} from 'api/gen/CodeTableItem';
import {OrderDetails} from 'api/gen/OrderDetails';
import {OrderTypeItem} from 'api/gen/OrderTypeItem';
import {Action} from 'app/actions';
import {State} from 'app/state';
import {Container, MainPanel, PageHeading} from 'layout';
import {isFullyBilledOrderDetails} from 'orders/utils/is-fully-billed';
import {isReadyForBillingOrderDetails} from 'orders/utils/is-ready-for-billing';
import {useOurTranslation} from 'translations';
import {useUser} from 'user';
import {codeTableItemsToOptions} from 'utils/code-table-items-to-options';
import {formHelpers} from 'utils/forms';

import {DetailActions} from '../components/DetailActions';
import {EditForm} from '../components/EditForm';
import {orderEditFormName, OrderEditFormValues} from '../components/EditForm/order-edit-form-values';
import {EditUserAndOrderView} from '../components/EditUserAndOrderView';
import {orderAction} from '../model';
import {getOrderTypesOptions} from '../utils/get-order-type-options';

interface StateProps {
    order: Opt<OrderDetails>;
    orderTypes: Opt<Array<OrderTypeItem>>;
    sampleTypes: Opt<Array<CodeTableItem>>;
    laboratoryNumberIsValid: boolean;
}

interface DispatchProps {
    getOrder(orderId: number, setSampleDeliveredDate?: boolean): void;
    updateOrder(orderId: number, order: OrderEditFormValues, nextUrl?: string): void;
    goTo(path: string): void;
    goBack(): void;
    closeOrdersForBillingByIds(orderId?: number): void;
    deleteProtocol(orderId: number): void;
    validateLaboratoryNumber(orderId: number, laboratoryNumber: string): void;
    deleteOrder(orderId: number): void;
    resetEditOrderForm(): void;
}

interface Match {
    id: string;
}

type Props = StateProps & DispatchProps & RouteComponentProps<Match>;

const DetailBase: FC<Props> = props => {
    const {
        order,
        orderTypes,
        sampleTypes,
        match: {params: {id}},
        location: {hash},
        getOrder,
        updateOrder,
        goTo,
        goBack,
        closeOrdersForBillingByIds,
        deleteProtocol,
        validateLaboratoryNumber,
        deleteOrder,
        resetEditOrderForm,
        laboratoryNumberIsValid,
    } = props;

    const {isRoleBreeder, isRoleBreedingUnion} = useUser();
    const {t} = useOurTranslation('orders/screenDetail');
    const orderId = Number(id);
    const isRedirectFromReceiveOrders = hash === '#receive';

    useEffect(() => {
        getOrder(orderId, isRedirectFromReceiveOrders);
    }, [getOrder, isRedirectFromReceiveOrders, orderId]);

    const onCloseForBillingBase = useCallback(() => {
        closeOrdersForBillingByIds(orderId);
    }, [closeOrdersForBillingByIds, orderId]);

    const onUpdateOrder = useCallback((values: OrderEditFormValues) => {
        updateOrder(orderId, values, isRedirectFromReceiveOrders ? '/orders/receive' : undefined);
    }, [isRedirectFromReceiveOrders, orderId, updateOrder]);

    const onProtocolDelete = useCallback(() => {
        deleteProtocol(orderId);
    }, [deleteProtocol, orderId]);

    const onOrderDelete = useCallback(() => {
        deleteOrder(orderId);
    }, [deleteOrder, orderId]);

    return (
        <Container>
            {order.map(x => (
                <MainPanel key={x.id}>
                    <div className="row">
                        <div className="col-12">
                            <PageHeading>
                                <h1>{t('title')} {x.sampleId}{x.breed && `, ${x.breed}`}</h1>
                            </PageHeading>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-lg-5 col-xl-4 order-lg-2">
                            <DetailActions
                                id={x.id}
                                protocolGenerated={x.protocolGenerated}
                                readyForBilling={isReadyForBillingOrderDetails(x)}
                                fullyBilled={isFullyBilledOrderDetails(x)}
                                laboratoryNumberIsValid={laboratoryNumberIsValid}
                                laboratoryNumber={x.laboratoryNumber}
                                onGoBackClick={goBack}
                                handleCloseForBillingBase={onCloseForBillingBase}
                                handleDeleteProtocol={onProtocolDelete}
                                goTo={goTo}
                                onOrderDelete={onOrderDelete}
                                animalId={x.animalId}
                            />
                        </div>

                        <div className="col-12 col-lg-7 col-xl-8 order-lg-1">
                            <div data-testid="detail-edit-form">
                                {!isRoleBreedingUnion && orderTypes.nonEmpty && sampleTypes.nonEmpty && (
                                    <EditForm
                                        orderTypes={getOrderTypesOptions(orderTypes, isRoleBreeder, x)}
                                        sampleTypes={codeTableItemsToOptions(sampleTypes)}
                                        isRoleBreeder={isRoleBreeder}
                                        order={x}
                                        isRedirectFromReceiveOrders={isRedirectFromReceiveOrders}
                                        onSubmit={onUpdateOrder}
                                        onCancel={resetEditOrderForm}
                                        validateLaboratoryNumber={validateLaboratoryNumber}
                                    />
                                )}
                            </div>

                            <EditUserAndOrderView
                                order={x}
                                identifier="editOrder"
                            />
                        </div>
                    </div>

                </MainPanel>
            )).orNull()}
        </Container>
    );
};

const mapStateToProps = (state: State): StateProps => ({
    order: state.orders.current,
    orderTypes: state.dials.orderTypes,
    sampleTypes: state.dials.sampleTypes,
    laboratoryNumberIsValid: formHelpers.getSyncErrors(orderEditFormName)(state).prop('laboratoryNumber').isEmpty
        && formHelpers.getAsyncErrors(orderEditFormName)(state).prop('laboratoryNumber').isEmpty
    ,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    getOrder: (orderId: number, setSampleDeliveredDate?: boolean): Action =>
        dispatch(orderAction.getOrder(orderId, {setSampleDeliveredDate, redirectNotFound: true})),
    updateOrder: (orderId: number, order: OrderEditFormValues, nextUrl?: string): Action =>
        dispatch(orderAction.updateOrder(orderId, order, nextUrl)),
    goTo: (path: string): void => void dispatch(routerActions.push(path)),
    goBack: (): void => void dispatch(routerActions.goBack()),
    closeOrdersForBillingByIds: (orderId?: number): Action =>
        dispatch(orderAction.closeOrdersForBillingByIds(false, orderId)),
    deleteProtocol: (orderId: number): Action => dispatch(orderAction.deleteProtocol(orderId)),
    validateLaboratoryNumber: (orderId: number, laboratoryNumber: string): Action =>
        dispatch(orderAction.validateLaboratoryNumber(orderId, laboratoryNumber)),
    deleteOrder: (orderId: number): Action => dispatch(orderAction.deleteOrder(orderId)),
    resetEditOrderForm: (): Action => dispatch(formHelpers.reset('editOrder')),
});

export const Detail = pipe(
    DetailBase,
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
);
