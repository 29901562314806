/* eslint-disable max-lines-per-function, max-lines */
import {GenericFormState, Nullable} from '@fl/cmsch-fe-library';
import {isEqual, isNull} from 'lodash/fp';
import * as O from 'optics-ts';
import {flow, opt, pipe} from 'ts-opt';

import {LastOrderUserDetails} from 'api/gen/LastOrderUserDetails';
import {OrderCustomerDetails} from 'api/gen/OrderCustomerDetails';
import {Action} from 'app/actions';
import {CFormReducer} from 'app/form-state';

import {
    AnimalDetailsFormSectionValues,
    animalDetailsInitialValues,
} from '../components/AnimalDetailsForm/animal-details-form-section-values';
import {BulkEditOrderFormValues} from '../components/BulkEditOrderForm/bulk-edit-order-form-values';
import {OrderEditFormValues, orderEditInitialValues} from '../components/EditForm/order-edit-form-values';
import {EditUserDetailsFormValues} from '../components/EditUserDetailsForm/edit-user-details-form-values';
import {
    GenerateProtocolFormValues,
    generateProtocolInitialValues,
} from '../components/GenerateProtocolForm/generate-protocol-form-values';
import {
    GenerateRequestFormValues,
    generateRequestInitialValues,
} from '../components/GenerateRequestForm/generate-request-form-values';
import {
    AnimalsDetailsTableType,
    NewBulkOrderFormValues,
    newBulkOrderInitialValues,
} from '../components/NewBulkForm/new-bulk-order-form-values';
import {newOrderInitialValues, NewOrderFormValues} from '../components/NewForm/new-order-form-values';
import {
    UserDetailsFormSectionValues,
    userDetailsInitialValues,
} from '../components/UserDetailsFormSection/user-details-form-section-values';

type BulkEditOrderFormState = GenericFormState<BulkEditOrderFormValues>;
type NewOrderFormState = GenericFormState<NewOrderFormValues>;
type NewBulkOrderFormState = GenericFormState<NewBulkOrderFormValues>;
type GenerateProtocolFormState = GenericFormState<GenerateProtocolFormValues>;
type EditUserDetailsFormState = GenericFormState<EditUserDetailsFormValues>;
type GenerateRequestFormState = GenericFormState<GenerateRequestFormValues>;
type OrderEditFormValuesState = GenericFormState<OrderEditFormValues>;

const bulkEditOrderValuesO = O.optic<BulkEditOrderFormState>()
    .valueOr<BulkEditOrderFormState>({registeredFields: [], values: {userDetails: userDetailsInitialValues}})
    .prop('values');
const bulkEditOrderUserDetailsO = bulkEditOrderValuesO
    .valueOr<BulkEditOrderFormValues>({userDetails: userDetailsInitialValues})
    .prop('userDetails');

const newOrderValuesO = O.optic<NewOrderFormState>()
    .valueOr<NewOrderFormState>({
        registeredFields: [],
        values: {
            animalDetails: null,
            barcode: null,
            customerNote: null,
            orderTypeIds: null,
            sampleTypeId: null,
            userDetails: null,
        },
    })
    .prop('values')
    .valueOr<NewOrderFormValues>(newOrderInitialValues);
const newOrderUserDetailsO = newOrderValuesO
    .prop('userDetails');
const newOrderAnimalDetailsO = newOrderValuesO
    .prop('animalDetails');
const newOrderOrderTypeIdsO = newOrderValuesO.prop('orderTypeIds');
const newOrderSampleTypeIdO = newOrderValuesO.prop('sampleTypeId');

const newBulkOrderValuesO = O.optic<NewBulkOrderFormState>()
    .valueOr<NewBulkOrderFormState>({
        registeredFields: [],
        values: {
            animalsDetails: [],
            orderTypeIds: null,
            sampleTypeId: null,
            userDetails: null,
        },
    })
    .prop('values')
    .valueOr<NewBulkOrderFormValues>(newBulkOrderInitialValues);
const newBulkOrderUserDetailsO = newBulkOrderValuesO
    .prop('userDetails');
const newBulkOrderAnimalsDetailsO = newBulkOrderValuesO
    .prop('animalsDetails');
const animalsDetailsTableTypeO = O.optic<AnimalsDetailsTableType>();

const generateProtocolValuesO = O.optic<GenerateProtocolFormState>()
    .prop('values')
    .valueOr<GenerateProtocolFormValues>(generateProtocolInitialValues);
const generateProtocolMothersBreedO = generateProtocolValuesO.prop('mothersBreed');
const generateProtocolMothersDateOfBirthO = generateProtocolValuesO.prop('mothersDateOfBirth');
const generateProtocolFathersSampleIdO = generateProtocolValuesO.prop('fathersSampleId');
const generateProtocolFathersBreedO = generateProtocolValuesO.prop('fathersBreed');
const generateProtocolFathersDateOfBirthO = generateProtocolValuesO.prop('fathersDateOfBirth');
const generateProtocolFathersRegistryO = generateProtocolValuesO.prop('fathersRegistry');
const generateProtocolFathersLaboratoryNumberO = generateProtocolValuesO.prop('fathersLaboratoryNumber');
const generateProtocolMothersLaboratoryNumberO = generateProtocolValuesO.prop('mothersLaboratoryNumber');

const editOrderCustomerValuesO = O.optic<EditUserDetailsFormState>()
    .valueOr<EditUserDetailsFormState>({registeredFields: [], values: {userDetails: userDetailsInitialValues}})
    .prop('values');
const editOrderCustomerUserDetailsO = editOrderCustomerValuesO
    .valueOr<EditUserDetailsFormValues>({userDetails: userDetailsInitialValues})
    .prop('userDetails');

const editSampleDeliveredDateO = O.optic<OrderEditFormValuesState>()
    .prop('values')
    .valueOr<OrderEditFormValues>(orderEditInitialValues)
    .prop('sampleDeliveredDate');

const generateRequestFormValuesO = O.optic<GenerateRequestFormState>()
    .valueOr<GenerateRequestFormState>({registeredFields: [], values: generateRequestInitialValues})
    .prop('values');

const userDetailsO = O.optic<UserDetailsFormSectionValues>();
const addressO = userDetailsO.prop('address');
const billingAddressO = userDetailsO.prop('billingAddress');
const customerNameO = userDetailsO.prop('customerName');
const vatNumberO = userDetailsO.prop('vatNumber');
const emailO = userDetailsO.prop('email');
const firstnameO = userDetailsO.prop('firstname');
const hasSameAddressesO = userDetailsO.prop('hasSameAddresses');
const cinO = userDetailsO.prop('cin');
const noCinO = userDetailsO.prop('noCin');
const idO = userDetailsO.prop('id');
const lastnameO = userDetailsO.prop('lastname');
const phoneO = userDetailsO.prop('phone');

const animalDetailsO = O.optic<AnimalDetailsFormSectionValues>();
const sampleIdO = animalDetailsO.prop('sampleId');

const processUserId = (id: number | null, breederIds: Array<number>): number | null =>
    id && !breederIds.includes(id) ? null : id;

const processUserDetailsLastOrder = (user: LastOrderUserDetails, hasReqIco: boolean, breederIds: Array<number>) => (
    oldUserDetails?: Nullable<UserDetailsFormSectionValues>,
): UserDetailsFormSectionValues => {
    const {
        address,
        customerName,
        vatNumber,
        email,
        firstName,
        cin,
        lastName,
        phone,
        billingAddress,
    } = user;

    const id = processUserId(user.id, breederIds);
    const userDetails: UserDetailsFormSectionValues = oldUserDetails || userDetailsInitialValues;
    const hasSameAddresses = hasReqIco || isEqual(address, billingAddress);

    return pipe( // pipe accepts up to 10 functions as parameters, flow used here to work around this
        userDetails,
        flow(
            O.set(addressO)(address),
            O.set(customerNameO)(customerName),
            O.set(vatNumberO)(vatNumber),
            O.set(emailO)(email),
            O.set(firstnameO)(firstName),
            O.set(cinO)(cin),
            O.set(lastnameO)(lastName),
            O.set(phoneO)(phone),
            O.set(billingAddressO)(billingAddress),
            O.modify(idO)(oldId => isNull(id) ? oldId : id),
        ),
        O.set(hasSameAddressesO)(hasSameAddresses),
    );
};

const processUserDetails = (user: OrderCustomerDetails) => (
    oldUserDetails?: Nullable<UserDetailsFormSectionValues>,
): UserDetailsFormSectionValues => {
    const {address, customerName, vatNumber, email, firstname, cin, lastname, phone} = user;

    const userDetails: UserDetailsFormSectionValues = oldUserDetails || userDetailsInitialValues;

    return pipe(
        userDetails,
        O.set(addressO)(address),
        O.set(customerNameO)(customerName),
        O.set(vatNumberO)(vatNumber),
        O.set(emailO)(email),
        O.set(firstnameO)(firstname),
        O.set(cinO)(cin),
        O.set(noCinO)(!cin),
        O.set(lastnameO)(lastname),
        O.set(phoneO)(phone),
    );
};

export const orderFormReducer: CFormReducer = {
    bulkEditOrder: (state: BulkEditOrderFormState, action: Action): BulkEditOrderFormState => {
        switch (action.type) {
            case 'order/GET_USER_LAST_ORDER_SUCCESS': {
                const {data, requestCin, breederIds, identifier} = action.payload;
                if (identifier !== 'bulkEditOrder') return state;

                return O.modify(bulkEditOrderUserDetailsO)(
                    processUserDetailsLastOrder(
                        data,
                        Boolean(requestCin),
                        breederIds,
                    ),
                )(state);
            }

            case 'order/RESET_BULK_EDIT_ORDER_IDS':
            case 'order/RESET_BULK_EDIT_ORDER_FILTER': {
                return O.set(bulkEditOrderUserDetailsO)(userDetailsInitialValues)(state);
            }

            case 'order/SET_BULK_EDIT_ORDER_USER_DETAILS_FORM_VALUE': {
                const {user} = action.payload;

                return O.modify(bulkEditOrderUserDetailsO)(processUserDetails(user))(state);
            }

            default: {
                return state;
            }
        }
    },
    newOrder: (state: NewOrderFormState, action: Action): NewOrderFormState => {
        switch (action.type) {
            case 'order/GET_USER_LAST_ORDER_SUCCESS': {
                const {data, requestCin, breederIds, identifier} = action.payload;
                if (identifier !== 'newOrder') return state;

                return O.modify(newOrderUserDetailsO)(
                    processUserDetailsLastOrder(
                        data,
                        Boolean(requestCin),
                        breederIds,
                    ),
                )(state);
            }

            case 'order/SET_USER_DETAILS_FORM_VALUE': {
                const {user} = action.payload;

                return O.modify(newOrderUserDetailsO)(processUserDetails(user))(state);
            }

            case 'order/PREFILL_BY_HOLSTEIN': {
                const {orderTypeIds, sampleTypeId} = action.payload;

                return pipe(
                    state,
                    O.set(newOrderOrderTypeIdsO)(orderTypeIds),
                    O.set(newOrderSampleTypeIdO)(sampleTypeId),
                );
            }

            case 'order/SET_NEW_ORDER_FORM_SAMPLE_ID': {
                const {sampleId} = action.payload;

                const animalDetails: AnimalDetailsFormSectionValues =
                    state.values?.animalDetails || animalDetailsInitialValues;

                return O.set(newOrderAnimalDetailsO)(
                    O.set(sampleIdO)(sampleId)(animalDetails),
                )(state);
            }
            case 'order/ADD_ANIMAL_TO_BULK_ORDER_ANIMAL_DETAILS':
            case 'order/CLEAR_BULK_ORDER_MODAL_DATA':
                return O.pipe(
                    state,
                    O.set(newOrderAnimalDetailsO)(null),
                    O.set(newOrderValuesO.prop('barcode'))(null),
                );

            default:
                return state;
        }
    },
    newBulkOrder: (state: NewBulkOrderFormState, action: Action): NewBulkOrderFormState => {
        switch (action.type) {
            case 'order/GET_USER_LAST_ORDER_SUCCESS': {
                const {data, requestCin, breederIds, identifier} = action.payload;
                if (identifier !== 'newBulkOrder') return state;

                return O.modify(newBulkOrderUserDetailsO)(
                    processUserDetailsLastOrder(
                        data,
                        Boolean(requestCin),
                        breederIds,
                    ),
                )(state);
            }
            case 'order/SET_USER_DETAILS_BULK_FORM_VALUE': {
                const {user} = action.payload;

                return O.modify(newBulkOrderUserDetailsO)(processUserDetails(user))(state);
            }
            case 'order/ADD_ANIMAL_TO_BULK_ORDER_ANIMAL_DETAILS':
                return O.modify(newBulkOrderAnimalsDetailsO)(
                    xs => [...xs?.filter(x => !x.isEditing) || [], action.payload.animalDetails],
                )(state);

            case 'order/RESET_IS_EDITING':
                return O.modify(newBulkOrderAnimalsDetailsO)(
                    xs => opt(xs).orElse([]).map(O.set(animalsDetailsTableTypeO.prop('isEditing'))(false)),
                )(state);

            case 'order/REMOVE_ANIMAL_TO_BULK_ORDER_ANIMAL_DETAILS': {
                const {animal} = action.payload.animalDetails;
                const newDetails = opt(state.values?.animalsDetails).orElse([]).filter(x => x.earTag !== animal);

                return O.set(newBulkOrderAnimalsDetailsO)(newDetails)(state);
            }

            default:
                return state;
        }
    },
    generateProtocol: (state: GenerateProtocolFormState, action: Action): GenerateProtocolFormState => {
        switch (action.type) {
            case 'order/REFRESH_MOTHER_SUCCESS': {
                const {animal} = action.payload;
                const {breed, dob, laboratoryNumber} = animal;

                return pipe(
                    state,
                    O.set(generateProtocolMothersBreedO)(breed),
                    O.set(generateProtocolMothersDateOfBirthO)(dob),
                    O.set(generateProtocolMothersLaboratoryNumberO)(laboratoryNumber),
                );
            }

            case 'order/REFRESH_FATHER_SUCCESS': {
                const {animal} = action.payload;
                const {breed, dob, registry, sampleId, laboratoryNumber} = animal;

                return pipe(
                    state,
                    O.set(generateProtocolFathersBreedO)(breed),
                    O.set(generateProtocolFathersDateOfBirthO)(dob),
                    O.set(generateProtocolFathersRegistryO)(registry),
                    O.set(generateProtocolFathersSampleIdO)(sampleId),
                    O.set(generateProtocolFathersLaboratoryNumberO)(laboratoryNumber),
                );
            }

            default:
                return state;
        }
    },
    editOrderCustomer: (state: EditUserDetailsFormState, action: Action): EditUserDetailsFormState => {
        switch (action.type) {
            case 'order/SET_EDIT_USER_DETAILS_FORM_VALUE': {
                const {user} = action.payload;

                return O.modify(editOrderCustomerUserDetailsO)(processUserDetails(user))(state);
            }

            case 'order/GET_USER_LAST_ORDER_SUCCESS': {
                const {data, requestCin, breederIds, identifier} = action.payload;
                if (identifier !== 'editOrder') return state;

                return O.modify(editOrderCustomerUserDetailsO)(
                    processUserDetailsLastOrder(
                        data,
                        Boolean(requestCin),
                        breederIds,
                    ),
                )(state);
            }

            default:
                return state;
        }
    },
    generateRequest: (state: GenerateRequestFormState, action: Action): GenerateRequestFormState => {
        switch (action.type) {
            case 'order/GET_DATA_FOR_GENOTYPING_REQUEST_SUCCESS': {
                const {data} = action.payload;

                return O.set(generateRequestFormValuesO)(data)(state);
            }

            default:
                return state;
        }
    },
    editOrder: (state: OrderEditFormValuesState, action: Action): OrderEditFormValuesState => {
        switch (action.type) {
            case 'order/SET_RECIVED_ORDER_DELIVERED_DATE':
                return O.set(editSampleDeliveredDateO)(action.payload)(state);

            default:
                return state;
        }
    },
};
