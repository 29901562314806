// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveLong, PositiveLongSchema } from 'api/gen/PositiveLong';

const requiredPart = t.interface({
  categoryId: PositiveLongSchema,
  name: t.string,
});

export const SnpSchema = excess(requiredPart);

export interface Snp extends t.TypeOf<typeof SnpSchema> {}
