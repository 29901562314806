import {extractFormErrorsFromResponse, putAll, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {put, call, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'translations';
import {formHelpers} from 'utils/forms';
import {showBeError} from 'utils/show-be-error';

import {getOrderTableParamsDto} from '../../utils/get-order-table-params-dto';
import {parseBulkEditOrderFormValues} from '../../utils/parse-bulk-edit-order-form-values';
import {orderAction, UpdateOrdersCustomersByFilterAction} from '../action';
import {getSelectedOrders} from './selectors';

function* execute({payload: {values, withoutSelected}}: UpdateOrdersCustomersByFilterAction): SagaIterator {
    const updateCustomerDetails = parseBulkEditOrderFormValues(values);

    if (!updateCustomerDetails) {
        return;
    }

    yield* put(formHelpers.startSubmit('bulkEditOrder'));
    yield* put(orderAction.setListActionActive('bulkUpdateOrder', true));

    const {filters} = yield* call(getOrderTableParamsDto);
    const excludedOrderIds = yield* select(getSelectedOrders);

    const response = yield* call(Api.updateCustomers, {
        customerDetails: updateCustomerDetails,
        tableFilters: {
            filters,
            excludedOrderIds: withoutSelected ? excludedOrderIds : [],
        },
    });

    if (response.isSuccess) {
        yield* put(formHelpers.stopSubmit('bulkEditOrder'));
        yield* put(orderAction.resetBulkEditOrderFilter());
        yield* put(orderAction.setUserDetailsFromCIN(null));
    } else {
        yield putAll(showBeError(response, t('orders/ListActions')('bulkUpdateOrder')));
        yield* put(formHelpers.stopSubmit('bulkEditOrder', extractFormErrorsFromResponse(response)));
    }
    yield* put(orderAction.setListActionActive('bulkUpdateOrder', false));
    yield* put(orderAction.getOrders(opt({action: 'refresh'})));
}

export function* updateOrderCustomersByFilterSaga(): SagaIterator {
    yield takeLatestF('order/UPDATE_ORDER_CUSTOMERS_BY_FILTER', execute);
}
