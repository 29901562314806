import {putAll, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {none, opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {appAction} from 'app/model/action';
import {t} from 'translations';
import {showBeError} from 'utils/show-be-error';
import {tablesAction, getRequestedTablePageParams, simpleTableSelector} from 'utils/tables';

import {convertOrderTableDataParamsToDto} from '../../utils/get-order-table-params-dto';
import {GetOrdersAction, orderAction} from '../action';

function* execute({payload: {tableDataRequest}}: GetOrdersAction): SagaIterator {
    if (tableDataRequest.isEmpty) {
        yield* put(orderAction.getOrdersSuccess(none));

        return yield* put(appAction.removeLoadingContent('orders'));
    }

    yield* put(tablesAction.preloadTableActions('orders', tableDataRequest.orCrash('No tableDataRequest')));

    const tableState = yield* select(simpleTableSelector.tableState('orders'));

    const tableDataParams = getRequestedTablePageParams({
        tableId: 'orders',
        tableDataRequest: tableDataRequest.orCrash('No tableDataRequest'),
        tableState,
    });

    const {filters, params} = convertOrderTableDataParamsToDto(tableDataParams, tableState);

    const response = yield* call(Api.findAllOrders, filters, params);

    if (response.isSuccess) {
        yield* put(orderAction.getOrdersSuccess(opt(response.data)));
        yield* put(tablesAction.postloadTableActions('orders', tableDataParams));
    } else {
        yield putAll(showBeError(response, t('orders/screenList')('title')));
        yield* put(appAction.removeLoadingContent('orders'));
    }
}

export function* getOrdersSaga(): SagaIterator {
    yield takeLatestF('order/GET_ORDERS', execute);
}
