export const dialsTranslations = {
    CS: {
        'dials/screenList': {
            tabName: 'Číselníky',
            sampleTypesCaption: 'Druhy vzorků',
            orderTypesCaption: 'Druhy objednávek',
            verificationOutcomesCaption: 'Výsledky shody s rodiči',
            testResultsCaption: 'Výsledky testu',
            mailTemplatesCaption: 'E-mailové šablony',
            breederInfoCaption: 'Informace pro chovatele',
        },
        'dials/verificationOutcomes': {
            name: 'Název',
            maternitySettings: 'Shoda s matkou',
            paternitySettings: 'Shoda s otcem',
            createNew: 'Nový typ výsledku shody',
            createTitle: 'Vytvořit nový typ výsledku shody',
            editTitle: 'Editovat typ výsledku shody',
            paternityValues: {
                MISSING: 'Netestován',
                MATCHES: 'Souhlasí',
                MISMATCHES: 'Nesouhlasí',
            },
            maternityValues: {
                MISSING: 'Netestována',
                MATCHES: 'Souhlasí',
                MISMATCHES: 'Nesouhlasí',
            },
        },
        'dials/testResults': {
            value: 'Název',
            createNew: 'Nový typ výsledku testu',
            createTitle: 'Vytvořit nový typ výsledku testu',
            editTitle: 'Editovat typ výsledku testu',
        },
        'dials/verificationOutcomeForm': {
            namePlaceholder: 'Název',
        },
        'dials/breederInfoForm': {
            caption: 'Nadpis',
            content: 'Text',
            show: 'Zobrazit',
            save: 'Uložit',
        },
        'dials/breederInfo': {
            hide: 'Přečteno',
        },
        'dials/sampleTypes': {
            createNew: 'Nový druh vzorku',
            value: 'Jméno druhu vzorku',
            createTitle: 'Vytvořit nový druh vzorku',
            editTitle: 'Editovat druh vzorku',
        },
        'dials/orderTypes': {
            createNew: 'Nový druh objednávky',
            price: 'Cena',
            extraCharge: 'Doplatek',
            code: 'Kód',
            description: 'Popis',
            createTitle: 'Vytvořit nový druh objednávky',
            editTitle: 'Editovat druh objednávky',
            currency: 'Kč',
        },
        'dials/mailTemplates': {
            code: 'Kód',
            subject: 'Předmět',
            description: 'Popis',
            body: 'Tělo e-mailu',
            editTitle: 'Editovat E-mailovou šablonu',
        },
    },
    EN: {
        'dials/screenList': {
            tabName: 'Dials',
            sampleTypesCaption: 'Sample types',
            orderTypesCaption: 'Order types',
            verificationOutcomesCaption: 'Parenthood match types',
            testResultsCaption: 'Result test types',
            mailTemplatesCaption: 'Mail templates',
            breederInfoCaption: 'Information for breeders',
        },
        'dials/verificationOutcomes': {
            name: 'Name',
            maternitySettings: 'Maternity match',
            paternitySettings: 'Paternity match',
            createNew: 'New type of parenthood match',
            createTitle: 'Create new type of parenthood match',
            editTitle: 'Edit type of parenthood match',
            paternityValues: {
                MISSING: 'Missing',
                MATCHES: 'Matches',
                MISMATCHES: 'Mismatches',
            },
            maternityValues: {
                MISSING: 'Missing',
                MATCHES: 'Matches',
                MISMATCHES: 'Mismatches',
            },
        },
        'dials/testResults': {
            value: 'Name',
            createNew: 'New type of test result',
            createTitle: 'Create new type of test result',
            editTitle: 'Edit type of test result',
        },
        'dials/verificationOutcomeForm': {
            namePlaceholder: 'Name',
        },
        'dials/breederInfoForm': {
            caption: 'Caption',
            content: 'Text',
            show: 'Show',
            save: 'Save',
        },
        'dials/breederInfo': {
            hide: 'Read',
        },
        'dials/sampleTypes': {
            createNew: 'New sample type',
            value: 'Sample type name',
            createTitle: 'Create new sample type',
            editTitle: 'Edit sample type',
        },
        'dials/orderTypes': {
            createNew: 'New order type',
            price: 'Price',
            extraCharge: 'Extra charge',
            code: 'Code',
            description: 'Description',
            createTitle: 'Create new order type',
            editTitle: 'Edit order type',
            currency: 'Kč',
        },
        'dials/mailTemplates': {
            code: 'Code',
            subject: 'Subject',
            description: 'Description',
            body: 'Body',
            editTitle: 'Edit e-mail template',
        },
    },
};
