import {putAll, showSuccess, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {t} from 'translations';
import {showBeError} from 'utils/show-be-error';

import {CancelOrdersBillingByIdsAction, orderAction} from '../action';
import {orderApi} from '../api';
import {isActionForced} from './is-action-forced';
import {getSelectedOrders} from './selectors';

const te = t('orders/ListActions');

function* execute({payload: {force}}: CancelOrdersBillingByIdsAction): SagaIterator {
    const orderIds = yield* select(getSelectedOrders);
    yield* put(orderAction.setListActionActive('cancelOrdersBilling', true));

    const response = yield* call(orderApi.cancelOrdersBillingByIds, orderIds, force);

    if (response.isSuccess) {
        yield* put(orderAction.getOrders(opt({action: 'refresh'})));
        yield* put(showSuccess(te('cancelOrdersBilling'), t('orders/sagas')('success')));
    } else {
        if (force) {
            yield putAll(showBeError(response, te('closeOrdersForBilling')));
        } else {
            const isForced = yield* call(isActionForced, response);

            if (isForced) {
                yield* put(orderAction.cancelOrdersBillingByIds(true));
            }
        }
    }
    yield* put(orderAction.setListActionActive('cancelOrdersBilling', false));
    yield* put(orderAction.resetDownloadWithoutForceFailedResult());
}

export function* cancelOrdersBillingByIdsSaga(): SagaIterator {
    yield takeLatestF('order/CANCEL_BILLING_BY_IDS', execute);
}
