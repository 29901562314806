// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { OrderTypeExtraCharge, OrderTypeExtraChargeSchema } from 'api/gen/OrderTypeExtraCharge';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { OrderTypePrice, OrderTypePriceSchema } from 'api/gen/OrderTypePrice';

const requiredPart = t.interface({
  extraCharge: OrderTypeExtraChargeSchema,
  price: OrderTypePriceSchema,
  value: t.string,
});

export const UpdateOrderTypeItemSchema = excess(requiredPart);

export interface UpdateOrderTypeItem extends t.TypeOf<typeof UpdateOrderTypeItemSchema> {}
