/* eslint-disable max-lines */
import {Nullable, Options, TableDataParams, TableDataRequest} from '@fl/cmsch-fe-library';
import {isEmpty} from 'lodash/fp';
import React, {Fragment, FC, useCallback, useEffect} from 'react';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {Opt} from 'ts-opt';

import {BillingBasesExport} from 'api/gen/BillingBasesExport';
import {CodeTableItem} from 'api/gen/CodeTableItem';
import {Date} from 'api/gen/Date';
import {LastOrderUserDetails} from 'api/gen/LastOrderUserDetails';
import {Order} from 'api/gen/Order';
import {OrderCustomerDetails} from 'api/gen/OrderCustomerDetails';
import {OrderTypeItem} from 'api/gen/OrderTypeItem';
import {UserDetails} from 'api/gen/UserDetails';
import {Action} from 'app/actions';
import {State} from 'app/state';
import {dialsAction} from 'dials';
import {Container, MainPanel, PageHeading} from 'layout';
import {useOurTranslation} from 'translations';
import {useUser} from 'user';
import {formHelpers} from 'utils/forms';

import {BillingBaseModal} from '../components/BillingBaseModal';
import {BulkEditOrderFormValues} from '../components/BulkEditOrderForm/bulk-edit-order-form-values';
import {DownloadBillingBaseExportModal} from '../components/DownloadBillingBaseExportModal';
import {EditOrdersCustomersModal} from '../components/EditOrdersCustomersModal';
import {ForceDownloadModal} from '../components/ForceDownloadModal';
import {GenerateSampleSheetModal} from '../components/GenerateSampleSheetModal';
import {OrdersTypesFormValues} from '../components/OrderTypesForm/order-types-form-values';
import {OrdersTable} from '../components/OrdersTable';
import {OrderTableType} from '../components/OrdersTable/order-table-type';
import {ConfirmDialog} from '../components/confirm-dialog';
import {ListActions} from '../components/list-actions';
import {orderAction} from '../model';
import {ExportOrdersParams} from '../types/export-orders-params';
import {ListActionModeType} from '../types/list-action-mode-type';
import {OrderListActions} from '../types/order-list-actions';
import {OrderModals} from '../types/order-modals';
import {getExportParams} from '../utils/get-export-params';

interface StateProps {
    modalsVisibility: Array<OrderModals>;
    listActionsActive: Array<OrderListActions>;
    customers: Opt<Array<OrderCustomerDetails>>;
    total: number;
    selected: Array<number>;
    currentUser: Opt<UserDetails>;
    sampleTypes: Opt<Array<CodeTableItem>>;
    orderTypes: Opt<Array<OrderTypeItem>>;
    billingBaseExport: Opt<BillingBasesExport>;
    bulkEditOrderIds: Array<number>;
    bulkEditOrderFilter: Opt<ExportOrdersParams>;
    downloadWithoutForceErrors: Array<string>;
    exportBillingBaseMode: Opt<ListActionModeType>;
    generateSampleSheetMode: Opt<ListActionModeType>;
    hasSameAddresses: boolean;
    userDetailsFromCIN: Opt<LastOrderUserDetails>;
    tableDataParams?: TableDataParams<OrderTableType>;
    bulkEditOrderWithoutSelected: boolean;
}

interface DispatchProps {
    getOrders(tableDataRequest: Opt<TableDataRequest<Order>>): void;
    getSampleTypes(): void;
    getOrderTypes(): void;
    cancelSubsidiesByIds(): void;
    cancelSubsidiesByFilter(withoutSelected: boolean): void;
    isolateByIds(): void;
    isolateByFilter(withoutSelected: boolean): void;
    createGenotypeExportByIds(): void;
    createGenotypeExportByFilter(withoutSelected: boolean): void;
    setExportBillingBaseMode(mode: ListActionModeType): void;
    exportBillingBaseByIds(billingDate: Date, force: boolean): void;
    exportBillingBaseByFilter(billingDate: Date, force: boolean, withoutSelected: boolean): void;
    downloadBillingBases(fileKey: string): void;
    downloadBillingBaseExportClose(): void;
    deselectAll(): void;
    closeOrdersForBillingByIds(): void;
    closeOrdersForBillingByFilter(withoutSelected: boolean): void;
    downloadWithoutForceResolve(forceDownloadConfirmed: boolean): void;
    resetDownloadWithoutForceFailedResult(): void;
    updateOrdersCustomersByIds(orderIds: Array<number>, values: BulkEditOrderFormValues): void;
    updateOrdersCustomersByFilter(values: BulkEditOrderFormValues, withoutSelected: boolean): void;
    setBulkEditOrderFilter(filter: ExportOrdersParams, withoutSelected: boolean): void;
    resetBulkEditOrderFilter(): void;
    resetBulkEditOrderIds(): void;
    setBulkEditOrderIds(): void;
    getUserLastOrder(cin: Nullable<string>): void;
    getOrdersCustomers(): void;
    prefillUserDetailsFromExistingOrder(userDetails: OrderCustomerDetails): void;
    toggleSelect(orderIds: Array<number>): void;
    exportOrderProtocol(orderId: number): void;
    exportOrdersByIds(): void;
    exportOrdersByFilter(withoutSelected: boolean): void;
    exportByAssociationByIds(): void;
    exportByAssociationByFilter(withoutSelected: boolean): void;
    exportForGermanyByIds(): void;
    exportForGermanyByFilter(withoutSelected: boolean): void;
    exportCatalogueListsByIds(): void;
    exportCatalogueListsByFilter(withoutSelected: boolean): void;
    exportProtocolsByIds(): void;
    exportProtocolsByFilter(withoutSelected: boolean): void;
    exportCdcbByIds(): void;
    exportCdcbByFilter(withoutSelected: boolean): void;
    generateSampleSheetByIds(chipId: string): void;
    generateSampleSheetByFilter(chipId: string, withoutSelected: boolean): void;
    setGenerateSampleSheetMode(mode: ListActionModeType): void;
    cancelOrdersBillingByIds(): void;
    cancelOrdersBillingByFilter(withoutSelected: boolean): void;
    clearCurrent(): void;
    updateOrdersTypesByIds(orderIds: Array<number>, values: OrdersTypesFormValues): void;
    updateOrdersTypesByFilters(values: OrdersTypesFormValues, withoutSelected: boolean): void;
    deleteOrdersByIds(): void;
    deleteOrdersByFilter(withoutSelected: boolean): void;
    updateCustomersEmailByIds(selectedOrders: Array<number>, email: string): void;
    updateCustomersEmailByFilter(selectedOrders: Array<number>, email: string, withoutSelected: boolean): void;
}

type Props = StateProps & DispatchProps;

// eslint-disable-next-line max-lines-per-function
const ListBase: FC<Props> = props => {
    const {
        billingBaseExport,
        exportBillingBaseMode,
        generateSampleSheetMode,
        downloadWithoutForceErrors,
        selected,
        total,
        orderTypes,
        sampleTypes,
        bulkEditOrderIds,
        bulkEditOrderFilter,
        hasSameAddresses,
        customers,
        userDetailsFromCIN,
        tableDataParams,
        modalsVisibility,
        listActionsActive,
        bulkEditOrderWithoutSelected,
        getOrders,
        exportBillingBaseByIds,
        exportBillingBaseByFilter,
        downloadBillingBaseExportClose,
        setExportBillingBaseMode,
        downloadBillingBases,
        downloadWithoutForceResolve,
        resetDownloadWithoutForceFailedResult,
        isolateByIds,
        isolateByFilter,
        createGenotypeExportByIds,
        createGenotypeExportByFilter,
        deselectAll,
        closeOrdersForBillingByIds,
        closeOrdersForBillingByFilter,
        cancelSubsidiesByIds,
        cancelSubsidiesByFilter,
        setBulkEditOrderIds,
        updateOrdersCustomersByIds,
        setBulkEditOrderFilter,
        resetBulkEditOrderFilter,
        updateOrdersCustomersByFilter,
        resetBulkEditOrderIds,
        getUserLastOrder,
        prefillUserDetailsFromExistingOrder,
        toggleSelect,
        exportOrderProtocol,
        getSampleTypes,
        getOrderTypes,
        getOrdersCustomers,
        exportOrdersByIds,
        exportOrdersByFilter,
        exportByAssociationByIds,
        exportByAssociationByFilter,
        exportForGermanyByIds,
        exportForGermanyByFilter,
        exportCatalogueListsByIds,
        exportCatalogueListsByFilter,
        exportProtocolsByIds,
        exportProtocolsByFilter,
        exportCdcbByIds,
        exportCdcbByFilter,
        generateSampleSheetByIds,
        generateSampleSheetByFilter,
        setGenerateSampleSheetMode,
        cancelOrdersBillingByIds,
        cancelOrdersBillingByFilter,
        clearCurrent,
        updateOrdersTypesByIds,
        updateOrdersTypesByFilters,
        deleteOrdersByFilter,
        deleteOrdersByIds,
        updateCustomersEmailByFilter,
        updateCustomersEmailByIds,
    } = props;

    const {isRoleLaborer, currentUser} = useUser();

    const {t} = useOurTranslation('orders/screenList');

    useEffect(() => {
        clearCurrent();
    }, [clearCurrent]);
    useEffect(() => {
        downloadBillingBaseExportClose();
        resetDownloadWithoutForceFailedResult();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        getSampleTypes();
    }, [getSampleTypes]);
    useEffect(() => {
        getOrderTypes();
    }, [getOrderTypes]);
    useEffect(() => {
        if (isRoleLaborer) getOrdersCustomers();
    }, [getOrdersCustomers, isRoleLaborer]);

    const onSetBulkEditOrderFilter = useCallback((withoutSelected: boolean) => {
        if (!tableDataParams) return;

        const excludedOrderIds = withoutSelected ? selected : undefined;
        const params = getExportParams(tableDataParams, undefined, excludedOrderIds);

        setBulkEditOrderFilter(params, withoutSelected);
    }, [tableDataParams, selected, setBulkEditOrderFilter]);

    const closeBulkEditOrder = useCallback(() => {
        resetBulkEditOrderFilter();
        resetBulkEditOrderIds();
    }, [resetBulkEditOrderFilter, resetBulkEditOrderIds]);

    const orderTypesOptions: Options<number> = orderTypes.mapIn(x => ({value: x.id, label: x.value})).orElse([]);
    const sampleTypesOptions: Options<number> = sampleTypes.mapIn(x => ({value: x.id, label: x.value})).orElse([]);

    if (!currentUser) return null;

    return (
        <Fragment>
            <Container fluid>
                <MainPanel>
                    <div className="row">
                        <div className="col-12">
                            <PageHeading>
                                <h1>{t('title')}</h1>
                            </PageHeading>
                        </div>
                    </div>
                    <div
                        className="mb-4 w-100"
                        data-test-id="table-actions"
                    >
                        <ListActions
                            selected={selected}
                            total={total}
                            listActionsActive={listActionsActive}
                            isolateByIds={isolateByIds}
                            isolateByFilter={isolateByFilter}
                            createGenotypeExportByIds={createGenotypeExportByIds}
                            createGenotypeExportByFilter={createGenotypeExportByFilter}
                            setExportBillingBaseMode={setExportBillingBaseMode}
                            deselectAll={deselectAll}
                            closeOrdersForBillingByIds={closeOrdersForBillingByIds}
                            closeOrdersForBillingByFilter={closeOrdersForBillingByFilter}
                            cancelSubsidiesByIds={cancelSubsidiesByIds}
                            cancelSubsidiesByFilter={cancelSubsidiesByFilter}
                            setBulkEditOrderIds={setBulkEditOrderIds}
                            setBulkEditOrderFilter={onSetBulkEditOrderFilter}
                            exportOrdersByIds={exportOrdersByIds}
                            exportOrdersByFilter={exportOrdersByFilter}
                            exportByAssociationByIds={exportByAssociationByIds}
                            exportByAssociationByFilter={exportByAssociationByFilter}
                            exportForGermanyByIds={exportForGermanyByIds}
                            exportForGermanyByFilter={exportForGermanyByFilter}
                            exportCatalogueListsByIds={exportCatalogueListsByIds}
                            exportCatalogueListsByFilter={exportCatalogueListsByFilter}
                            exportProtocolsByIds={exportProtocolsByIds}
                            exportProtocolsByFilter={exportProtocolsByFilter}
                            exportCdcbByIds={exportCdcbByIds}
                            exportCdcbByFilter={exportCdcbByFilter}
                            setGenerateSampleSheetMode={setGenerateSampleSheetMode}
                            cancelOrdersBillingByIds={cancelOrdersBillingByIds}
                            cancelOrdersBillingByFilter={cancelOrdersBillingByFilter}
                            deleteOrdersByFilter={deleteOrdersByFilter}
                            deleteOrdersByIds={deleteOrdersByIds}
                        />
                    </div>

                    <div className="mw-100">
                        <OrdersTable
                            orderTypes={orderTypesOptions}
                            sampleTypes={sampleTypesOptions}
                            selected={selected}
                            getOrders={getOrders}
                            toggleSelect={toggleSelect}
                            exportOrderProtocol={exportOrderProtocol}
                        />
                    </div>
                </MainPanel>
            </Container>
            <BillingBaseModal
                exportBillingBaseMode={exportBillingBaseMode}
                exportBillingBaseByFilter={exportBillingBaseByFilter}
                exportBillingBaseByIds={exportBillingBaseByIds}
                setExportBillingBaseMode={setExportBillingBaseMode}
                visible={modalsVisibility.includes('billingBase')}
            />
            <ForceDownloadModal
                errors={downloadWithoutForceErrors}
                visible={modalsVisibility.includes('forceConfirm')}
                downloadWithoutForceResolve={downloadWithoutForceResolve}
            />
            <DownloadBillingBaseExportModal
                billingBaseExport={billingBaseExport}
                onCancel={downloadBillingBaseExportClose}
                visible={modalsVisibility.includes('downloadBillingBase')}
                downloadBillingBases={downloadBillingBases}
            />
            <EditOrdersCustomersModal
                customers={customers.orElse([])}
                hasSameAddresses={hasSameAddresses}
                userDetailsFromCIN={userDetailsFromCIN}
                disableAddress={!isRoleLaborer}
                bulkEditOrderFilter={bulkEditOrderFilter}
                bulkEditOrderIds={bulkEditOrderIds}
                visible={!isEmpty(bulkEditOrderIds) || bulkEditOrderFilter.nonEmpty}
                withoutSelected={bulkEditOrderWithoutSelected}
                onPrefillFromExisting={prefillUserDetailsFromExistingOrder}
                getUserLastOrder={getUserLastOrder}
                onClose={closeBulkEditOrder}
                updateOrdersCustomersByFilter={updateOrdersCustomersByFilter}
                updateOrdersCustomersByIds={updateOrdersCustomersByIds}
                orderTypes={orderTypes}
                selected={selected}
                updateOrdersTypesByIds={updateOrdersTypesByIds}
                updateOrdersTypesByFilters={updateOrdersTypesByFilters}
                updateCustomersEmailByFilter={updateCustomersEmailByFilter}
                updateCustomersEmailByIds={updateCustomersEmailByIds}
            />
            <GenerateSampleSheetModal
                generateSampleSheetMode={generateSampleSheetMode}
                generateSampleSheetByFilter={generateSampleSheetByFilter}
                generateSampleSheetByIds={generateSampleSheetByIds}
                setGenerateSampleSheetMode={setGenerateSampleSheetMode}
                visible={modalsVisibility.includes('generateSampleSheet')}
            />
            <ConfirmDialog />
        </Fragment>
    );
};

const mapStateToProps = (state: State): StateProps => ({
    modalsVisibility: state.orders.modalsVisibility,
    listActionsActive: state.orders.listActionsActive,
    customers: state.orders.customers,
    total: state.orders.ordersPage.prop('page').prop('totalElements').orElse(0),
    selected: state.orders.selected,
    currentUser: state.user.currentUser,
    sampleTypes: state.dials.sampleTypes,
    orderTypes: state.dials.orderTypes,
    billingBaseExport: state.orders.billingBaseExport,
    bulkEditOrderIds: state.orders.bulkEditOrderIds,
    bulkEditOrderFilter: state.orders.bulkEditOrderFilter,
    downloadWithoutForceErrors: state.orders.downloadWithoutForceErrors,
    exportBillingBaseMode: state.orders.exportBillingBaseMode,
    generateSampleSheetMode: state.orders.generateSampleSheetMode,
    hasSameAddresses: formHelpers.formValues('bulkEditOrder')(state)
        .prop('userDetails').prop('hasSameAddresses').orFalse(),
    userDetailsFromCIN: state.orders.userDetailsFromCIN,
    tableDataParams: state.tables.tableStates.orders?.tableDataParams,
    bulkEditOrderWithoutSelected: state.orders.bulkEditOrderWithoutSelected,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    getOrders: (tableDataRequest: Opt<TableDataRequest<Order>>): Action =>
        dispatch(orderAction.getOrders(tableDataRequest)),
    getSampleTypes: (): Action => dispatch(dialsAction.getSampleTypes()),
    getOrderTypes: (): Action => dispatch(dialsAction.getOrderTypes()),
    cancelSubsidiesByIds: (): Action => dispatch(orderAction.cancelSubsidiesByIds()),
    cancelSubsidiesByFilter: (withoutSelected: boolean): Action =>
        dispatch(orderAction.cancelSubsidiesByFilter(withoutSelected)),
    isolateByIds: (): Action => dispatch(orderAction.isolateByIds()),
    isolateByFilter: (withoutSelected: boolean): Action => dispatch(orderAction.isolateByFilter(withoutSelected)),
    createGenotypeExportByIds: (): Action => dispatch(orderAction.createGenotypeExportByIds()),
    createGenotypeExportByFilter: (withoutSelected: boolean): Action =>
        dispatch(orderAction.createGenotypeExportByFilter(withoutSelected)),
    setExportBillingBaseMode: (mode: Nullable<ListActionModeType>): void => {
        dispatch(orderAction.setExportBillingBaseMode(mode));
        dispatch(orderAction.setModalVisibility('billingBase', Boolean(mode)));
    },
    exportBillingBaseByIds: (billingDate: Date, force: boolean): Action =>
        dispatch(orderAction.exportBillingBaseByIds(billingDate, force)),
    exportBillingBaseByFilter: (billingDate: Date, force: boolean, withoutSelected: boolean): Action =>
        dispatch(orderAction.exportBillingBaseByFilter(billingDate, force, withoutSelected)),
    downloadBillingBases: (fileKey: string): Action => dispatch(orderAction.downloadBillingBases(fileKey)),
    downloadBillingBaseExportClose: (): void => {
        dispatch(orderAction.billingBaseExportClose());
        dispatch(orderAction.setModalVisibility('downloadBillingBase', false));
    },
    deselectAll: (): Action => dispatch(orderAction.deselectAll()),
    closeOrdersForBillingByIds: (): Action => dispatch(orderAction.closeOrdersForBillingByIds(false)),
    closeOrdersForBillingByFilter: (withoutSelected: boolean): Action =>
        dispatch(orderAction.closeOrdersForBillingByFilter(false, withoutSelected)),
    downloadWithoutForceResolve: (forceDownloadConfirmed: boolean): Action =>
        dispatch(orderAction.downloadWithoutForceResolve(forceDownloadConfirmed)),
    resetDownloadWithoutForceFailedResult: (): Action => dispatch(orderAction.resetDownloadWithoutForceFailedResult()),
    updateOrdersCustomersByIds: (orderIds: Array<number>, values: BulkEditOrderFormValues): Action =>
        dispatch(orderAction.updateOrdersCustomersByIds(orderIds, values)),
    updateOrdersCustomersByFilter: (
        values: BulkEditOrderFormValues,
        withoutSelected: boolean,
    ): Action =>
        dispatch(orderAction.updateOrdersCustomersByFilter(values, withoutSelected)),
    setBulkEditOrderFilter: (filter: ExportOrdersParams, withoutSelected: boolean): Action =>
        dispatch(orderAction.setBulkEditOrderFilter(filter, withoutSelected)),
    resetBulkEditOrderFilter: (): void => {
        dispatch(orderAction.resetBulkEditOrderFilter());
        dispatch(orderAction.setUserDetailsFromCIN(null));
    },
    resetBulkEditOrderIds: (): Action => dispatch(orderAction.resetBulkEditOrderIds()),
    setBulkEditOrderIds: (): Action => dispatch(orderAction.setBulkEditOrderIds()),
    getUserLastOrder: (cin: Nullable<string>): Action => dispatch(orderAction.getUserLastOrder(cin, 'editOrder')),
    getOrdersCustomers: (): Action => dispatch(orderAction.getOrdersCustomers()),
    prefillUserDetailsFromExistingOrder: (userDetails: OrderCustomerDetails): void => {
        const {address, cin, customerName, email, firstname, lastname, phone, vatNumber} = userDetails;
        dispatch(orderAction.setBulkEditOrderUserDetailsFormValue(userDetails));
        dispatch(orderAction.setUserDetailsFromCIN({
            address,
            billingAddress: null,
            cin,
            customerName,
            email,
            firstName: firstname,
            id: null,
            lastName: lastname,
            phone,
            vatNumber,
        }));
    },
    toggleSelect: (orderIds: Array<number>): Action => dispatch(orderAction.toggleSelect(orderIds)),
    exportOrderProtocol: (orderId: number): Action => dispatch(orderAction.exportOrderProtocol(orderId)),
    exportOrdersByIds: (): Action => dispatch(orderAction.exportOrdersByIds()),
    exportOrdersByFilter: (withoutSelected: boolean): Action =>
        dispatch(orderAction.exportOrdersByFilter(withoutSelected)),
    exportByAssociationByIds: (): Action => dispatch(orderAction.exportByAssociationByIds()),
    exportByAssociationByFilter: (withoutSelected: boolean): Action =>
        dispatch(orderAction.exportByAssociationByFilter(withoutSelected)),
    exportForGermanyByIds: (): Action => dispatch(orderAction.exportForGermanyByIds(false)),
    exportForGermanyByFilter: (withoutSelected: boolean): Action =>
        dispatch(orderAction.exportForGermanyByFilter(false, withoutSelected)),
    exportCatalogueListsByIds: (): Action => dispatch(orderAction.exportCatalogueListsByIds(false)),
    exportCatalogueListsByFilter: (withoutSelected: boolean): Action =>
        dispatch(orderAction.exportCatalogueListsByFilter(false, withoutSelected)),
    exportProtocolsByIds: (): Action => dispatch(orderAction.exportProtocolsByIds(false)),
    exportProtocolsByFilter: (withoutSelected: boolean): Action =>
        dispatch(orderAction.exportProtocolsByFilter(false, withoutSelected)),
    exportCdcbByIds: (): Action => dispatch(orderAction.exportCdcbByIds()),
    exportCdcbByFilter: (withoutSelected: boolean): Action => dispatch(orderAction.exportCdcbByFilter(withoutSelected)),
    generateSampleSheetByIds: (chipId: string): Action => dispatch(orderAction.generateSampleSheetByIds(chipId, false)),
    generateSampleSheetByFilter: (chipId: string, withoutSelected: boolean): Action =>
        dispatch(orderAction.generateSampleSheetByFilter(chipId, false, withoutSelected)),
    setGenerateSampleSheetMode: (mode: Nullable<ListActionModeType>): void => {
        dispatch(orderAction.setGenerateSampleSheetMode(mode));
        dispatch(orderAction.setModalVisibility('generateSampleSheet', Boolean(mode)));
    },
    cancelOrdersBillingByIds: (): void => void dispatch(orderAction.cancelOrdersBillingByIds(false)),
    cancelOrdersBillingByFilter: (withoutSelected: boolean): void =>
        void dispatch(orderAction.cancelOrdersBillingByFilter(false, withoutSelected)),
    clearCurrent: (): void => void dispatch(orderAction.clearCurrent()),
    updateOrdersTypesByIds: (orderIds: Array<number>, values: OrdersTypesFormValues): void =>
        void dispatch(orderAction.updateOrdersTypesByIds(orderIds, values)),
    updateOrdersTypesByFilters: (values: OrdersTypesFormValues, withoutSelected: boolean): void =>
        void dispatch(orderAction.updateOrdersTypesByFilter(values, withoutSelected)),
    deleteOrdersByIds: (): void => void dispatch(orderAction.deleteOrdersByIds()),
    deleteOrdersByFilter: (withoutSelected: boolean): void =>
        void dispatch(orderAction.deleteOrdersByFilter(withoutSelected)),
    updateCustomersEmailByIds: (selectedOrders: Array<number>, email: string) =>
        void dispatch(orderAction.updateCustomersEmailByIds(selectedOrders, email)),
    updateCustomersEmailByFilter: (selectedOrders: Array<number>, email: string, withoutSelected: boolean) =>
        void dispatch(orderAction.updateCustomersEmailByFilter(selectedOrders, email, withoutSelected)),
});

export const List = connect(mapStateToProps, mapDispatchToProps)(ListBase);
