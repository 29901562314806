import {
    downloadFileFromResponse,
    extractFormErrorsFromResponse,
    putAll,
    showBeError,
    takeLatestF,
} from '@fl/cmsch-fe-library';
import * as routerActions from 'redux-first-history';
import {SagaIterator} from 'redux-saga';
import {put, call} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {NewProtocolDetails} from 'api/gen/NewProtocolDetails';
import {transformFormToAddress} from 'orders/utils/transform-address';
import {t} from 'translations';
import {formHelpers} from 'utils/forms';

import {GenerateProtocolAction, orderAction} from '../action';

const NULL = -1;

// eslint-disable-next-line max-lines-per-function
function* execute(action: GenerateProtocolAction): SagaIterator {
    const {data, orderId} = action.payload;

    const {
        address,
        breed,
        customerName,
        dateOfBirth,
        displayLaboratoryNote,
        fathersBreed,
        fathersDateOfBirth,
        fathersLaboratoryNumber,
        fathersRegistry,
        fathersSampleId,
        mothersBreed,
        mothersDateOfBirth,
        mothersLaboratoryNumber,
        mothersSampleId,
        name,
        registry,
        sampleTypeId,
        sex,
        testResultTypeIds,
        verificationOutcomeTypeId,
    } = data;

    const parsedAddress = transformFormToAddress(address);

    if (!parsedAddress || !customerName || displayLaboratoryNote === null || sampleTypeId === null) {
        return;
    }
    yield* put(formHelpers.startSubmit('generateProtocol'));
    const dataForApi: NewProtocolDetails = {
        address: parsedAddress,
        breed,
        customerName,
        dateOfBirth,
        displayLaboratoryNote,
        fathersBreed,
        fathersDateOfBirth,
        fathersLaboratoryNumber,
        fathersRegistry,
        fathersSampleId,
        mothersBreed,
        mothersDateOfBirth,
        mothersLaboratoryNumber,
        mothersSampleId,
        name,
        registry,
        sampleTypeId,
        sex,
        testResultTypeIds,
        verificationOutcomeTypeId: verificationOutcomeTypeId === NULL ? null : verificationOutcomeTypeId,
    };

    const response = yield* call(Api.generateProtocol, dataForApi, {orderId});

    if (response.isSuccess) {
        yield* call(downloadFileFromResponse, response);
        yield* put(orderAction.getDataForProtocol(orderId));
        yield* put(routerActions.push(`/orders/${orderId}`));
        yield* put(formHelpers.reset('generateProtocol'));
        yield* put(formHelpers.stopSubmit('generateProtocol'));
        yield* put(orderAction.getOrder(orderId));
    } else {
        yield putAll(showBeError(response, t('orders/sagas')('generateProtocol')));
        yield* put(formHelpers.stopSubmit('generateProtocol', extractFormErrorsFromResponse(response)));
    }
}

export function* generateProtocolSaga(): SagaIterator {
    yield takeLatestF('order/GENERATE_PROTOCOL', execute);
}
