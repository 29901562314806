import {Errors} from 'utils/validator';

import {validateUserDetails} from '../UserDetailsFormSection/validations';
import {BulkEditOrderFormValues} from './bulk-edit-order-form-values';

export const validate = (values: BulkEditOrderFormValues): Errors<BulkEditOrderFormValues> => {
    const errors: Errors<BulkEditOrderFormValues> = {};
    if (values.userDetails) {
        errors.userDetails = validateUserDetails(values.userDetails, 'bulk', true, false);
    }

    return errors;
};
