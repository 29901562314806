import {putAll, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {put, call} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {appAction} from 'app/model/action';
import {t} from 'translations';
import {showBeError} from 'utils/show-be-error';

import {GetDataForProtocolAction, orderAction} from '../action';

function* execute({payload: {orderId}}: GetDataForProtocolAction): SagaIterator {
    yield* put(appAction.addLoadingContent('getDataForProtocol'));
    const response = yield* call(Api.getDataForProtocol, {orderId});

    if (response.isSuccess) {
        yield* put(orderAction.getDataForProtocolSuccess(response.data));
    } else {
        yield putAll(showBeError(response, t('orders/sagas')('getDataForProtocol')));
    }
    yield* put(appAction.removeLoadingContent('getDataForProtocol'));
}

export function* getDataForProtocolSaga(): SagaIterator {
    yield takeLatestF('order/GET_DATA_FOR_PROTOCOL', execute);
}
