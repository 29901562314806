import {CustomWindow} from '@fl/cmsch-fe-library';
import {createBrowserHistory} from 'history';
import {legacy_createStore, applyMiddleware, compose, PreloadedState, AnyAction} from 'redux';
import {createReduxHistoryContext} from 'redux-first-history';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import createSagaMiddleware from 'redux-saga';

import {Config} from './config';
import {createReducer} from './reducer';
import sagas from './sagas';
import {sentryReduxEnhancer} from './sentry-logger';
import {State, initialState} from './state';

type Compose = typeof compose;

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = ((window as CustomWindow).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose) as Compose;

const sagaMiddleware = createSagaMiddleware();
const reduxHistoryContext = createReduxHistoryContext({history: createBrowserHistory()});
const {createReduxHistory, routerMiddleware, routerReducer} = reduxHistoryContext;

const middlewares = [
    sagaMiddleware,
    routerMiddleware,
];

if (Config.isDevelopment && !Config.disableImmutabilityCheck) {
    middlewares.push(reduxImmutableStateInvariant());
}

const reducer = createReducer(routerReducer);
const enhancer = composeEnhancers(compose(applyMiddleware(...middlewares), sentryReduxEnhancer));
const store = legacy_createStore<State, AnyAction, unknown, unknown>(
    reducer,
    initialState as unknown as PreloadedState<State>,
    enhancer,
);

const history = createReduxHistory(store);

sagaMiddleware.run(sagas);

export {store, history};
