export const orderTranslations = {
    CS: {
        'orders/screenReceiveOrders': {
            title: 'Příjem objednávek',
        },
        'orders/screenDetail': {
            title: 'Detail objednávky',
            customerDetails: 'Údaje o zákazníkovi',
            orderDetails: 'Údaje o objednávce',
        },
        'orders/screenList': {
            title: 'Přehled objednávek',
        },
        'orders/BillingBaseModal': {
            caption: 'Parametry fakturace',
        },
        'orders/EditOrdersCustomersModal': {
            caption: 'Hromadná úprava objednávek',
            customerData: 'Údaje o objednateli',
            ordersTypes: 'Typy objednávek',
        },
        'orders/BillingBaseExportOptionsForm': {
            billingDate: 'Datum',
            submit: 'Pokračovat',
        },
        'orders/ForceDownloadModal': {
            caption: 'Chyba při zpracování',
            forceDownload: 'Ignorovat chyby a pokračovat',
        },
        'orders/ListActions': {
            menuTitle: 'Správa objednávek',
            filter: 'Filtr ({{total}})',
            filterWithoutSelected: 'Filtr bez vybraných ({{total}})',
            selected: 'Vybrané ({{total}})',
            noSelected: 'Vyberte alespoň jednu objednávku',
            noFiltered: 'Vyfiltrujte alespoň jednu objednávku',
            maxRecords: 'Akci nelze provést, vyberte prosím méně než 2 000 objednávek nebo kontaktujte správce systému.',
            createNew: 'Vytvořit novou objednávku',
            exportOrders: 'Export objednávek',
            exportOrdersByAssociation: 'Označit pro dotaci',
            exportOrdersForGermany: 'Export pro Německo',
            exportCatalogueLists: 'Export katalogových listů',
            generateSampleSheet: 'Generovat sample sheet',
            protocols: 'Export protokolů',
            massIsolation: 'Izolovat',
            billingBase: 'Fakturovat',
            cancelSubsidy: 'Zrušit dotace',
            exportGenotypes: 'Export čipů',
            exportGenotypesStart: 'Žádost na generování čipů byla zaznamenána.',
            deselectAll: 'Zrušit výběr ({{total}})',
            bulkUpdateOrder: 'Upravit objednávky',
            deleteBillingBase: 'Zrušit fakturaci',
            closeOrdersForBilling: 'Připravit pro fakturaci',
            cancelOrdersBilling: 'Zrušit fakturaci',
            openCloudnaGenomics: 'ClouDNA Genomika',
            exportCdcb: 'Export CDCB',
            deleteOrders: 'Smazat objednávky',
            confirmLargeExport: 'Chystáte se exportovat více než 10 000 objednávek. Tato akce může trvat i několik minut, opravdu chcete pokračovat?',
            confirmLargeIsolation: 'Chystáte se izolovat více než 10 000 objednávek. Tato akce může trvat i několik minut, opravdu chcete pokračovat?',
            confirmLargeSampleSheet: 'Chystáte se generovat sample sheet pro více než 10 000 objednávek. Tato akce může trvat i několik minut, opravdu chcete pokračovat?',
            confirmLargeCloseForBilling: 'Chystáte se připravit k fakturaci více než 10 000 objednávek. Tato akce může trvat i několik minut, opravdu chcete pokračovat?',
            confirmLargeBilling: 'Chystáte se fakturovat více než 10 000 objednávek. Tato akce může trvat i několik minut, opravdu chcete pokračovat?',
            confirmLargeUpdate: 'Chystáte se upravit více než 10 000 objednávek. Tato akce může trvat i několik minut, opravdu chcete pokračovat?',
        },
        'orders/DownloadBillingBaseExport': {
            caption: 'Výsledek fakturace',
            billingBasisCsvKey: 'CSV',
            billingBasisZipKey: 'ZIP',
            billingBasisMeatAssociationCsvKey: 'CSV pro masný svaz',
            close: 'Zavřít',
        },
        'orders/OrdersTable': {
            stateFullyBilled: 'Vyfakturováno',
            unbilled: 'Nefakturováno',
            columnHeaders: {
                sampleId: 'Ušní z.',
                createdTs: 'Dat. vytvoř.',
                protocolGeneratedDate: 'Dat. prot.',
                sampleType: 'Vzorek',
                isolationDate: 'Datum izolace',
                isolated: 'Izolováno',
                laboratoryNumber: 'Laboratorní č.',
                breed: 'Plemeno',
                sex: 'Pohlaví',
                chipId: 'ID čipu',
                customerName: 'Chovatel',
                orderTypes: 'Typy obj.',
                sampleDeliveredDate: 'Dat. doruč.',
                catalogList: 'Katalogový list',
                maternity: 'Matka',
                paternity: 'Otec',
                genotypeExportGeneratedDate: 'Dat. exportu',
            },
            columnHeadersTooltip: {
                sampleId: 'Ušní známka',
                createdTs: 'Datum vytvoření objednávky',
                protocolGeneratedDate: 'Datum vygenerování protokolu',
                sampleType: 'Vzorek',
                isolationDate: 'Datum izolace',
                isolated: 'Izolováno',
                laboratoryNumber: 'Laboratorní číslo',
                breed: 'Plemeno',
                sex: 'Pohlaví',
                chipId: 'ID čipu',
                customerName: 'Chovatel',
                orderTypes: 'Typy objednávek',
                sampleDeliveredDate: 'Datum doručení vzorku',
                catalogList: 'Katalogový list',
                genotypeExportGeneratedDate: 'Datum exportu čipu',
                email: 'E-mail zákazníka',
            },
        },
        'orders/OrderState': {
            stateFullyBilled: 'Vyfakturováno',
            stateReadyForBilling: 'Připraveno pro fakturaci',
            exportedByAssociation: 'Odesláno pro dotaci',
        },
        'orders/TableRowActions': {
            catalogueList: 'Stáhnout katalogový list',
            catalogueListNotAvailable: 'Katalogový list není k dispozici',
            catalogueListFileName: 'Katalogovy-list-{{id}}.pdf',
            detail: 'Zobrazit detail objednávky',
            protocol: 'Stáhnout protokol',
            protocolNotAvailable: 'Protokol není k dispozici',
        },
        'orders/DetailActions': {
            menuTitle: 'Správa objednávek',
            regenerateProtocol: 'Přegenerovat protokol',
            generateProtocol: 'Generovat protokol',
            deleteProtocol: 'Zrušit protokol',
            generateRequest: 'Generovat žádost',
            regenerateRequest: 'Přegenerovat žádost',
            closeOrdersForBilling: 'Připravit pro fakturaci',
            orderClosedForBilling: 'Připraveno k fakturaci',
            deleteOrder: 'Odstranit objednávku',
            animalDetail: 'Zobrazit detail zvířete',
            back: 'Zpět',
            disabledBilling: 'Objednávka musí mít laboratorní číslo.',
        },
        'orders/GenerateRequestActions': {
            menuTitle: 'Správa objednávek',
            back: 'Zpět',
        },
        'orders/GenerateProtocolActions': {
            menuTitle: 'Správa objednávek',
            back: 'Zpět',
        },
        'orders/EditForm': {
            title: 'Upravit objednávku',
            laboratoryNumber: 'Laboratorní čislo',
            barcode: 'Čárový kód',
            sampleDeliveredDate: 'Datum doručení vzorku',
            isolationDate: 'Datum izolování vzorku',
            isolated: 'Izolováno?',
            laboratoryNote: 'Poznámka',
            orderTypeIds: 'Typ objednávky',
            orderTypeIdsPlaceholder: 'Vyberte typ objednávky',
            sampleTypeId: 'Druh vzorku',
            sampleTypeIdPlaceholder: 'Vyberte druh vzorku',
        },
        'orders/FindOrderForm': {
            barcode: 'Čárový kód',
            sampleId: 'Ušní čislo',
            findOrder: 'Najít objednávku',
            foundMultipleOrders: 'Nalezeno více objednávek',
        },
        'orders/GenerateRequestForm': {
            title: 'Generovat žádost',
            sex: 'Pohlaví',
            breed: 'Plemeno',
            dateOfBirth: 'Datum narození',
            barcode: 'Čárový kód',
            fathersBreed: 'Plemeno otce',
            fathersRegistry: 'Registr otce',
            mothersSampleId: 'Ušní číslo matky',
            mothersFatherRegistry: 'Registr otce matky',
            mothersFatherBreed: 'Plemeno otce matky',
            submit: 'Vygenerovat',
        },
        'orders/GenerateProtocolForm': {
            title: 'Generovat protokol',
            sex: 'Pohlaví',
            displayLaboratoryNote: 'Vytisknout poznámku laborantky',
            parentsVerificationOutcome: 'Vypočtená shoda',
            parents: 'Paternita',
            fatherVerificationOutcome: 'Otec',
            motherVerificationOutcome: 'Matka',
            bothVerificationOutcome: 'Celková',
            fathersLaboratoryNumber: 'Laboratorní číslo otce',
            mothersLaboratoryNumber: 'Laboratorní číslo matky',
            name: 'Jméno',
            breed: 'Plemeno',
            dateOfBirth: 'Datum narození',
            registry: 'Registr',
            sampleTypeId: 'Druh vzorku',
            sampleTypeIdPlaceholder: 'Vyberte druh vzorku',
            motherTitle: 'Matka',
            mothersSampleId: 'Ušní číslo matky',
            mothersBreed: 'Plemeno matky',
            mothersDateOfBirth: 'Datum narození matky',
            fatherTitle: 'Otec',
            fathersSampleId: 'Ušní číslo otce',
            fathersBreed: 'Plemeno otce',
            fathersRegistry: 'Registr otce',
            fathersDateOfBirth: 'Datum narození otce',
            verifyPaternity: 'Oveřit paternitu',
            verificationOutcomeTypeTitle: 'Výsledek shody s rodiči',
            unselected: 'Nevybráno',
            testResultTypeTitle: 'Výsledky testu',
            userTitle: 'Uživatel',
            customerName: 'Objednavatel',
            street: 'Ulice',
            city: 'Město',
            zip: 'PSČ',
            submit: 'Vygenerovat',
            verifyPaternityDisabledSampleId: 'Nelze ověřit paternitu - ušní známka není validní.',
            verifyPaternityDisabledMotSampleId: 'Nelze ověřit paternitu - ušní známka matky není validní.',
            verifyPaternityDisabledFatSampleId: 'Nelze ověřit paternitu - ušní známka otce není validní.',
        },
        'orders/newBulkOrder': {
            stepOneCaption: '1. Osobní údaje',
            barcode: 'Čár. kód',
            animal: 'UZ',
            animalTooltip: 'Ušní známka',
            stepThreeCaption: '3. Údaje o zvířatech',
            addOrders: 'Přidat zvíře',
            back: 'Předchozí',
            barcodeTooltip: 'Čárový kód',
            dob: 'Dat. nar.',
            fathersRegistry: 'Reg. otce',
            fathersRegistryTooltip: 'Registr otce',
            dobTooltip: 'Datum narození',
            motherSampleId: 'UZ matky',
            breed: 'Plem.',
            breedTooltip: 'Plemeno',
            motherSampleIdTooltip: 'Ušní číslo matky',
            edit: 'Upravit',
            editErrTitle: 'Editace zvířete',
            editEarTagErrMsg: 'Zvíře se stejnou ušní známkou již bylo přidáno',
            editRegistryErrMsg: 'Zvíře se stejným registrem již bylo přidáno',
            animalsDataErrMsg: 'Nebylo přidáno žádné zvíře. Přidejte alespoň jedno zvíře.',
        },
        'orders/UserDetailsForm': {
            stepCaption: '3. Osobní údaje',
            prefillName: 'Vyplnit formulář z předešlé objednávky',
            prefillNamePlaceholder: 'Objednatel',
            id: 'Uživatel',
            cin: 'IČO',
            cinPlaceholder: 'Např.: 277 00 000',
            noCin: 'Nemám IČO',
            vatNumber: 'DIČ',
            vatNumberPlaceholder: 'Např.: CZ 277 00 000',
            customerName: 'Objednatel',
            customerNamePlaceholder: 'Jméno / název firmy',
            firstname: 'Jméno',
            lastname: 'Příjmení',
            email: 'Email',
            phone: 'Telefon',
            address: 'Adresa',
            hasSameAddresses: 'Adresa a fakturační adresa jsou stejné',
            billingAddress: 'Fakturační adresa',
            next: 'Další',
            previous: 'Předchozí',
        },
        'orders/OrderDetails': {
            deleteBillingBase: 'Zrušit fakturaci',
            unknownOrderType: 'Neznámý typ objednávky',
            downloadBillingBaseCsv: 'CSV',
            downloadBillingBasePdf: 'PDF',
            downloadBillingBaseMeatCsv: 'CSV (masný svaz)',
            orderData: 'Údaje o objednávce',
            creationDate: 'Datum vytvoření:',
            sampleType: 'Druh vzorku:',
            orderType: 'Typ objednávky:',
            processingDate: 'Datum zpracování:',
            customerNote: 'Poznámka:',
            animalData: 'Údaje o zvířeti',
            breed: 'Plemeno:',
            name: 'Jméno:',
            dateOfBirth: 'Datum narození:',
            fathersSampleId: 'Ušní číslo otce:',
            mothersSampleId: 'Ušní číslo matky:',
            sex: 'Pohlaví:',
            registry: 'Registr:',
            fathersRegistry: 'Registr otce:',
            twin: 'Je z dvojčat:',
            customerData: 'Údaje o uživateli',
            customerName: 'Název:',
            email: 'E-mail:',
            phone: 'Telefon:',
            cinVatNumber: 'IČO, DIČ:',
            firstAndLastName: 'Jméno a příjmení:',
            address: 'Adresa:',
            billingAddress: 'Fakturační adresa:',
            exportDate: '(exportováno dne {{date}})',
        },
        'orders/screenNew': {
            caption: 'Nová objednávka',
            captionBulk: 'Nová hromadná objednávka',
            menuCaption: 'Informace',
            description: {
                one: 'Dodací lhůta zpracování zakázky je zhruba 3 týdny.',
                two: 'Proces zpracování na SNP technologii nelze urychlit.',
                three: 'Délka zpracování je závislá na jednotlivých technologických časech.',
                four: 'Nelze přednostně zpracovat konkrétní zvíře.',
            },
        },
        'orders/AnimalDetailsForm': {
            caption: '1. Údaje o zvířeti',
            simpleCaption: 'Údaje o zvířeti',
            animalNotFound: 'Zvíře nebylo v centrální databázi nalezeno, opravdu ho chcete přidat?',
            earTagHelp: 'Po vyplnění se načtou údaje o zvířeti.',
            examplesOfEarNumberFormats: 'Příklady podporovaných formátů:',
            name: 'Jméno',
            sex: 'Pohlaví',
            sexPlaceholder: 'Vyberte pohlaví',
            breed: 'Plemeno',
            registry: 'Registr zvířete',
            dob: 'Datum narození',
            dobPlaceholder: '01.02.2008',
            fathersSampleId: 'Ušní číslo otce',
            fatherAlreadyAnalyzed: 'Otec už byl testován.',
            fathersRegistry: 'Registr otce',
            mothersSampleId: 'Ušní číslo matky',
            motherAlreadyAnalyzed: 'Matka už byla testována.',
            twin: 'Je z dvojčat?',
            submit: 'Další',
            findOrigin: 'Vyhledat původ',
            add: 'Přidat',
            submitDisabled: 'Validuji...',
        },
        'orders/OrderDetailsForm': {
            caption: '2. Údaje o objednávce',
            barcode: 'Čárový kód',
            sampleTypeId: 'Druh vzorku',
            sampleTypeIdPlaceholder: 'Vyberte druh vzorku',
            orderTypeIds: 'Typ objednávky',
            orderTypeIdsPlaceholder: 'Vyberte typ objednávky',
            customerNote: 'Poznámka',
            customerNotePlaceholder: 'Vaše poznámka...',
            back: 'Předchozí',
            submit: 'Další',
        },
        'orders/OrderConfirmationForm': {
            caption: '4. Potvrzení obednávky',
            animalData: 'Údaje o zvířeti',
            animalsData: 'Údaje o zvířatech',
            orderData: 'Údaje o objednávce',
            customerData: 'Osobní údaje',
            name: 'Jméno:',
            sex: 'Pohlaví:',
            breed: 'Plemeno:',
            registry: 'Registr:',
            fathersSampleId: 'Ušní číslo otce:',
            fathersRegistry: 'Registr otce:',
            mothersSampleId: 'Ušní číslo matky:',
            twin: 'Je z dvojčat?:',
            barcode: 'Čárový kód:',
            sampleType: 'Druh vzorku:',
            orderTypes: 'Typ objednávky:',
            note: 'Poznámka:',
            customerName: 'Objednatel:',
            firstAndLastName: 'Jméno:',
            back: 'Předchozí',
            submit: 'Objednat',
        },
        'orders/GenerateSampleSheetModal': {
            captionSelected: 'Generování sample sheet z vybraných položek',
            captionFilter: 'Generování sample sheet podle filtru',
            captionFilterWithoutSelected: 'Generování sample sheet podle filtru bez vybraných',
        },
        'orders/SampleSheetForm': {
            chipId: 'ID čipu',
            chipIdPlaceholder: 'ID čipu (název Sample sheet)',
            submit: 'Generovat',
        },
        'orders/DetailTable': {
            title: 'Výpočet QTL',
            columnHeaders: {
                snpName: 'SNP',
                type: 'Typ',
                result: 'Výsledek',
            },
            noQtls: 'Nejsou k dispozici žádné výpočty.',
        },
        'orders/sagas': {
            getUserLastOrder: 'Načtení poslední objednávky uživatele',
            getOrdersCustomers: 'Načtení uživatelů u objednávek přihlášeného uživatele',
            getBreeders: 'Načtení chovatelů',
            animalsSearch: 'Vyhledání zvířat',
            success: 'Operace úspěšně dokončena.',
            getOrder: 'Načtení objednávky',
            getDataForProtocol: 'Načtení dat pro protokol',
            getDataForGenotypingRequest: 'Načtení dat pro žádost ogenotypizaci',
            verifyPaternity: 'Ověření paternity',
            updateCustomer: 'Aktualizace zákazníka',
            missingDataTitle: 'Nevyplněny údaje',
            missingData: 'Některé potřebné údaje nebyly vyplněny',
            updateCustomersEmail: 'Úprava emailu zákazníků',
            generateProtocol: 'Generovat protokol',
        },
    },
    EN: {
        'orders/screenReceiveOrders': {
            title: 'Receive orders',
        },
        'orders/screenDetail': {
            title: 'Order Details',
            customerDetails: 'Customer details',
            orderDetails: 'Order details',
        },
        'orders/screenList': {
            title: 'Orders overview',
        },
        'orders/BillingBaseModal': {
            caption: 'Invoice parameters',
        },
        'orders/EditOrdersCustomersModal': {
            caption: 'Bulk edit orders',
            customerData: 'Customer data',
            ordersTypes: 'Orders types',
        },
        'orders/BillingBaseExportOptionsForm': {
            billingDate: 'Invoice date',
            submit: 'Continue',
        },
        'orders/ForceDownloadModal': {
            caption: 'Error during processing',
            forceDownload: 'Ignore errors and continue',
        },
        'orders/ListActions': {
            menuTitle: 'Administration of orders',
            filter: 'Filter ({{total}})',
            filterWithoutSelected: 'Filter without selected ({{total}})',
            selected: 'Selected ({{total}})',
            noSelected: 'Select at least one order',
            noFiltered: 'Filter at least one order',
            maxRecords: 'The action cannot be completed. Please select fewer than 2,000 orders, or contact the system administrator.',
            createNew: 'Create new order',
            exportOrders: 'Export orders',
            exportOrdersByAssociation: 'Mark for subsidy',
            exportOrdersForGermany: 'Export for Germany',
            exportCatalogueLists: 'Export of catalogue lists',
            generateSampleSheet: 'Generate sample sheet',
            protocols: 'Export protocols',
            massIsolation: 'Isolate',
            billingBase: 'Invoice',
            cancelSubsidy: 'Cancel subsidy',
            exportGenotypes: 'Export genotypes',
            exportGenotypesStart: 'Request for genotypes export was noted.',
            deselectAll: 'Deselect all ({{total}})',
            bulkUpdateOrder: 'Update orders',
            deleteBillingBase: 'Delete invoice',
            closeOrdersForBilling: 'Prepare invoice',
            cancelOrdersBilling: 'Cancel invoice',
            openCloudnaGenomics: 'ClouDNA Genomics',
            exportCdcb: 'Export CDCB',
            deleteOrders: 'Delete orders',
            confirmLargeExport: 'You want to export more than 10 000 orders. This action make take several minutes, do you really want to continue?',
            confirmLargeIsolation: 'You want to isolate more than 10 000 orders. This action make take several minutes, do you really want to continue?',
            confirmLargeSampleSheet: 'You want to generate sample sheet for more than 10 000 orders. This action make take several minutes, do you really want to continue?',
            confirmLargeCloseForBilling: 'You want to prepare invoice for more than 10 000 orders. This action make take several minutes, do you really want to continue?',
            confirmLargeBilling: 'You want to invoice more than 10 000 orders. This action make take several minutes, do you really want to continue?',
            confirmLargeUpdate: 'You want to update more than 10 000 orders. This action make take several minutes, do you really want to continue?',
        },
        'orders/DownloadBillingBaseExport': {
            caption: 'Invoice results',
            billingBasisCsvKey: 'CSV',
            billingBasisZipKey: 'ZIP',
            billingBasisMeatAssociationCsvKey: 'CSV for Beef Cattle Breeders’ Association',
            close: 'Close',
        },
        'orders/OrdersTable': {
            stateFullyBilled: 'Invoice complete',
            unbilled: 'Invoice incomplete (unbilled)',
            columnHeaders: {
                sampleId: 'Eartag n.',
                createdTs: 'Date of cr.',
                protocolGeneratedDate: 'Date prot.',
                sampleType: 'Sample',
                isolationDate: 'Date of isolation',
                isolated: 'Isolated',
                laboratoryNumber: 'Laboratory n.',
                breed: 'Breed',
                sex: 'Sex',
                chipId: 'Chip ID',
                customerName: 'Breeder',
                orderTypes: 'Order t.',
                sampleDeliveredDate: 'Date deliv.',
                catalogList: 'Catalogue list',
                maternity: 'Mother',
                paternity: 'Father',
                genotypeExportGeneratedDate: 'Export date',
            },
            columnHeadersTooltip: {
                sampleId: 'Eartag number',
                protocolGeneratedDate: 'Date protocol generated',
                createdTs: 'Date of creation',
                sampleType: 'Sample',
                isolationDate: 'Date of isolation',
                isolated: 'Isolated',
                laboratoryNumber: 'Laboratory number',
                breed: 'Breed',
                sex: 'Sex',
                chipId: 'Chip ID',
                customerName: 'Breeder',
                orderTypes: 'Order types',
                sampleDeliveredDate: 'Date of sample delivery',
                catalogList: 'Catalogue list',
                genotypeExportGeneratedDate: 'Chip export date',
                email: 'Customer E-mail',
            },
        },
        'orders/OrderState': {
            stateFullyBilled: 'Invoiced',
            stateReadyForBilling: 'Ready for invoicing',
            exportedByAssociation: 'Send for subsidy',
        },
        'orders/TableRowActions': {
            catalogueList: 'Download catalogue list',
            catalogueListNotAvailable: 'Catalogue list is unavailable',
            catalogueListFileName: 'Catalogue-list-{{id}}.pdf',
            detail: 'Display order details',
            protocol: 'Download protocol',
            protocolNotAvailable: 'Protocol is unavailable',
        },
        'orders/DetailActions': {
            menuTitle: 'Order administration',
            regenerateProtocol: 'Regenerate protocol',
            generateProtocol: 'Generate protocol',
            deleteProtocol: 'Delete protocol',
            generateRequest: 'Generate request',
            regenerateRequest: 'Regenerate request',
            closeOrdersForBilling: 'Prepare invoice',
            orderClosedForBilling: 'Ready for invoicing',
            deleteOrder: 'Delete order',
            animalDetail: 'Show Animal detail',
            back: 'Back',
            disabledBilling: 'The order must have a laboratory number.',
        },
        'orders/GenerateRequestActions': {
            menuTitle: 'Order administration',
            back: 'Back',
        },
        'orders/GenerateProtocolActions': {
            menuTitle: 'Order administration',
            back: 'Back',
        },
        'orders/EditForm': {
            title: 'Edit order',
            laboratoryNumber: 'Laboratory number',
            barcode: 'Barcode',
            sampleDeliveredDate: 'Date of sample delivery',
            isolationDate: 'Date of sample isolation',
            isolated: 'Isolated?',
            laboratoryNote: 'Note',
            orderTypeIds: 'Type of order',
            orderTypeIdsPlaceholder: 'Select order type',
            sampleTypeId: 'Sample type',
            sampleTypeIdPlaceholder: ' Select sample type',
        },
        'orders/FindOrderForm': {
            barcode: 'Barcode',
            sampleId: 'Ear number',
            findOrder: 'Find order',
            foundMultipleOrders: 'Found multiple orders',
        },
        'orders/GenerateRequestForm': {
            title: 'Generate request',
            sex: 'Sex',
            breed: 'Breed',
            dateOfBirth: 'Date of birth',
            barcode: 'Barcode',
            fathersBreed: 'Breed of father',
            fathersRegistry: 'Register of father',
            mothersSampleId: 'Eartag number of mother',
            mothersFatherRegistry: 'Registration number of mother’s father',
            mothersFatherBreed: 'Breed of mother’s father',
            submit: 'Generate',
        },
        'orders/GenerateProtocolForm': {
            title: 'Generate protocol',
            sex: 'Sex',
            displayLaboratoryNote: 'Print notes from laboratory staff',
            parentsVerificationOutcome: 'Calculate parent verification correspondence',
            parents: 'Paternity',
            fatherVerificationOutcome: 'Father',
            motherVerificationOutcome: 'Mother',
            bothVerificationOutcome: 'Total',
            fathersLaboratoryNumber: 'Father laboratory number',
            mothersLaboratoryNumber: 'Mother laboratory number',
            name: 'Name',
            breed: 'Breed',
            dateOfBirth: 'Date of birth',
            registry: 'Register',
            sampleTypeId: 'Sample type',
            sampleTypeIdPlaceholder: 'Select sample type',
            motherTitle: 'Mother',
            mothersSampleId: 'Ear number of mother',
            mothersBreed: 'Breed of mother',
            mothersDateOfBirth: 'Date of birth of mother',
            fatherTitle: 'Father',
            fathersSampleId: 'Eartag number of father',
            fathersBreed: 'Breed of father',
            fathersRegistry: 'Register of father',
            fathersDateOfBirth: 'Date of birth of father',
            verifyPaternity: 'Verify paternity',
            verificationOutcomeTypeTitle: 'Comparison verification',
            unselected: 'Not selected',
            testResultTypeTitle: 'Test results',
            userTitle: 'User',
            customerName: 'Customer',
            street: 'Street',
            city: 'City',
            zip: 'Postal code',
            submit: 'Generate',
            verifyPaternityDisabledSampleId: 'Unable to verify paternity - ear tag is not valid.',
            verifyPaternityDisabledMotSampleId: 'Unable to verify paternity - mothers ear tag is not valid.',
            verifyPaternityDisabledFatSampleId: 'Unable to verify paternity - fathers ear tag is not valid.',
        },
        'orders/newBulkOrder': {
            stepOneCaption: '1. Personal details',
            barcode: 'Barcode',
            animal: 'Ear tag',
            animalTooltip: 'Ear tag',
            stepThreeCaption: '3. Animals data',
            addOrders: 'Add animal',
            back: 'Back',
            barcodeTooltip: 'barcode',
            dob: 'Birth date.',
            fathersRegistry: 'Reg. otce',
            fathersRegistryTooltip: 'Registr otce',
            dobTooltip: 'Birth date',
            motherSampleId: 'Mother ear tag',
            motherSampleIdTooltip: 'Mother ear tag',
            breed: 'Breed',
            breedTooltip: 'Breed',
            edit: 'Upravit',
            editErrTitle: 'Edit animal',
            editEarTagErrMsg: 'Animal with same ear tag was already added',
            editRegistryErrMsg: 'Animal with same registry was already added',
            animalsDataErrMsg: 'No animal was added. Please add at least one animal.',
        },
        'orders/UserDetailsForm': {
            stepCaption: '3. Personal details',
            prefillName: 'Fill in the previous order form',
            prefillNamePlaceholder: 'Customer',
            id: 'User',
            cin: 'Company registration number',
            cinPlaceholder: 'For instance: 277 00 000',
            noCin: 'I don’t have a company registration number',
            vatNumber: 'VAT number',
            vatNumberPlaceholder: 'For instance: CZ 277 00 000',
            customerName: 'Customer',
            customerNamePlaceholder: 'Name/company',
            firstname: 'Name',
            lastname: 'Surname',
            email: 'Email',
            phone: 'Phone number',
            address: 'Address',
            hasSameAddresses: 'Address and billing address are not the same',
            billingAddress: 'Billing address',
            next: 'Next',
            previous: 'Previous',
        },
        'orders/OrderDetails': {
            deleteBillingBase: 'Delete invoice',
            unknownOrderType: 'Unknown order type',
            downloadBillingBaseCsv: 'CSV',
            downloadBillingBasePdf: 'PDF',
            downloadBillingBaseMeatCsv: 'CSV for Beef Cattle Breeders’ Association',
            orderData: 'Order details',
            creationDate: 'Date of creation:',
            sampleType: 'Sample type:',
            orderType: 'Order type:',
            processingDate: 'Date of processing:',
            customerNote: 'Note:',
            animalData: 'Animal details',
            breed: 'Breed:',
            name: 'Name:',
            dateOfBirth: 'Date of birth:',
            fathersSampleId: 'Ear number of father:',
            mothersSampleId: 'Ear number of mother:',
            sex: 'Sex:',
            registry: 'Register:',
            fathersRegistry: 'Registration number of father:',
            twin: 'Animal is a twin:',
            customerData: 'Customer details',
            customerName: 'Name:',
            email: 'E-mail:',
            phone: 'Phone number:',
            cinVatNumber: 'Company registration number, VAT number:',
            firstAndLastName: 'First name and surname:',
            address: 'Address:',
            billingAddress: 'Billing address:',
            exportDate: '(exported at {{date}})',
        },
        'orders/screenNew': {
            caption: 'New order',
            captionBulk: 'New bulk order',
            menuCaption: 'Information',
            description: {
                one: 'Delivery time is approximately 3 weeks.',
                two: 'SNP processing can’t be expedited.',
                three: 'Processing times vary according to the technology used.',
                four: 'Animals are processed in the order selected only.',
            },
        },
        'orders/AnimalDetailsForm': {
            caption: '1. Animal data',
            simpleCaption: 'Animal data',
            animalNotFound: 'That animal was not found in our central database. Are you sure you would like to add this animal?',
            earTagHelp: 'Animal data will be loaded after filling.',
            examplesOfEarNumberFormats: 'Examples of supported formats:',
            name: 'Name',
            sex: 'Sex',
            sexPlaceholder: 'Choose sex',
            breed: 'Breed',
            registry: 'Register of animal',
            dob: 'Date of birth',
            dobPlaceholder: '01.02.2008',
            fathersSampleId: 'Ear number of father',
            fatherAlreadyAnalyzed: 'Father already tested.',
            fathersRegistry: 'Register of father',
            mothersSampleId: 'Ear number of mother',
            motherAlreadyAnalyzed: 'Mother already tested.',
            twin: 'A twin?',
            submit: 'Next',
            findOrigin: 'FindOrigin',
            add: 'Add',
            submitDisabled: 'Validating...',
        },
        'orders/OrderDetailsForm': {
            caption: '2. Order data',
            barcode: 'Barcode',
            sampleTypeId: 'Sample type',
            sampleTypeIdPlaceholder: 'Select sample type',
            orderTypeIds: 'Order type',
            orderTypeIdsPlaceholder: 'Select order type',
            customerNote: 'Your note',
            customerNotePlaceholder: 'Your note...',
            back: 'Back',
            submit: 'Next',
        },
        'orders/OrderConfirmationForm': {
            caption: '4. Order confirmation',
            animalData: 'Animal data',
            orderData: 'Order data',
            customerData: 'Personal data',
            name: 'Name:',
            sex: 'Sex:',
            breed: 'Breed:',
            registry: 'Register:',
            fathersSampleId: 'Ear number number of father:',
            fathersRegistry: 'Register of father:',
            mothersSampleId: 'Ear number of mother:',
            twin: 'A twin?',
            barcode: 'Barcode:',
            sampleType: 'Sample type:',
            orderTypes: 'Order type:',
            note: 'Your note:',
            customerName: 'Customer:',
            firstAndLastName: 'Name:',
            back: 'Previous',
            submit: 'New order',
            animalsData: 'Animals data',
        },
        'orders/GenerateSampleSheetModal': {
            captionSelected: 'Generate sample sheet from selected items',
            captionFilter: 'Generate sample sheet using filters',
            captionFilterWithoutSelected: 'Generate sample sheet using filter without selected',
        },
        'orders/SampleSheetForm': {
            chipId: 'Chip ID',
            chipIdPlaceholder: 'Chip ID (Sample sheet)',
            submit: 'Generate',
        },
        'orders/DetailTable': {
            title: 'QTL calculation',
            columnHeaders: {
                snpName: 'SNP',
                type: 'Type',
                result: 'Result',
            },
            noQtls: 'No calculations are available.',
        },
        'orders/sagas': {
            getUserLastOrder: 'Loading the user\'s last order',
            getOrdersCustomers: 'Loading customers of user\'s orders',
            getBreeders: 'Loading breeders',
            animalsSearch: 'Animals search',
            success: 'Operation completed successfully.',
            getOrder: 'Loading order',
            getDataForProtocol: 'Loading data for protocol',
            getDataForGenotypingRequest: 'Loading data for genotyping request',
            verifyPaternity: 'Verify paternity',
            updateCustomer: 'Update of customer',
            missingDataTitle: 'Unfilled data',
            missingData: 'Some required data was not filled in',
            updateCustomersEmail: 'Update customers email',
            generateProtocol: 'Generate protocol',
        },
    },
};
