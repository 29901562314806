import {putAll, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {put, call} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {appAction} from 'app/model/action';
import {t} from 'translations';
import {showBeError} from 'utils/show-be-error';

import {GetBreedersAction, orderAction} from '../action';

function* execute(_action: GetBreedersAction): SagaIterator {
    yield* put(appAction.addLoadingContent('getBreeders'));
    const response = yield* call(Api.getBreeders);

    if (response.isSuccess) {
        yield* put(orderAction.getBreedersSuccess(response.data));
    } else {
        yield putAll(showBeError(response, t('orders/sagas')('getBreeders')));
    }
    yield* put(appAction.removeLoadingContent('getBreeders'));
}

export function* getBreedersSaga(): SagaIterator {
    yield takeLatestF('order/GET_BREEDERS', execute);
}
