import {putAll, showSuccess, takeLatestF} from '@fl/cmsch-fe-library';
import * as routerActions from 'redux-first-history';
import {SagaIterator} from 'redux-saga';
import {put, call} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'translations';
import {showBeError} from 'utils/show-be-error';

import {DeleteAnimal, animalsAction} from '../action';

function* execute({payload: {animalId}}: DeleteAnimal): SagaIterator {
    yield* put(animalsAction.setLoading('deleteAnimalInProgress', true));

    const response = yield* call(Api.deleteAnimal, {animalId});

    if (response.isSuccess) {
        yield* put(routerActions.push('/animals'));
        yield* put(showSuccess(
            t('animals/AnimalsTable')('messages.deleteTitle'),
            t('animals/AnimalsTable')('messages.deleteSuccess'),
        ));
    } else {
        yield putAll(showBeError(response, t('animals/AnimalsTable')('messages.deleteError')));
    }

    yield* put(animalsAction.setLoading('deleteAnimalInProgress', false));
}

export function* deleteAnimalSaga(): SagaIterator {
    yield takeLatestF('animals/DELETE_ANIMAL', execute);
}
