// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// The extra charge price of the order type.
// Example: 50

export const orderTypeExtraChargeMinimum = 0.0; // inclusive
export const orderTypeExtraChargeMaximum = 32767.0; // inclusive

export const OrderTypeExtraChargeSchema = t.refinement(t.Integer, x => x >= orderTypeExtraChargeMinimum && x <= orderTypeExtraChargeMaximum, 'OrderTypeExtraCharge');

export type OrderTypeExtraCharge = t.TypeOf<typeof OrderTypeExtraChargeSchema>;
