// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// A street name
// Example: "Benešovská 123"

export const streetMinLength = 1;
export const streetMaxLength = 255;

export const StreetSchema = t.refinement(t.string, n => n.length >= streetMinLength && n.length <= streetMaxLength, 'Street');

export type Street = string;
