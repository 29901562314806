import {putAll, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {put, call} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {appAction} from 'app/model/action';
import {t} from 'translations';
import {showBeError} from 'utils/show-be-error';

import {animalsAction, GetAnimalQtls} from '../action';

function* execute({payload: {animalId}}: GetAnimalQtls): SagaIterator {
    yield* put(appAction.addLoadingContent('findAllQtlsByAnimal'));
    const response = yield* call(Api.findAllQtlsByAnimal, {animalId});
    if (response.isSuccess) {
        yield* put(animalsAction.getAnimalQtlsSuccess(response.data));
    } else {
        yield putAll(showBeError(response, t('animals/sagaMessages')('qtlsError')));
    }
    yield* put(appAction.removeLoadingContent('findAllQtlsByAnimal'));
}

export function* getAnimalQtlsSaga(): SagaIterator {
    yield takeLatestF('animals/GET_ANIMAL_QTLS', execute);
}
