export const paternityCheckTranslations = {
    CS: {
        'paternityCheck/screenPaternityCheck': {
            formTitle: 'Údaje o zvířatech',
            title: 'Ověření paternity',
            result: 'Výsledek',
        },
        'paternityCheck/paternityCheckForm': {
            text: 'Pro určení paternity vyplňte prosím následující data: ',
            checkButton: 'Ověřit',
            calfNumber: 'Tele',
            parentNumber: 'Rodič',
        },
        'paternityCheck/PaternityCheckResult': {
            accord: 'Shoda',
            parentNumber: 'Rodič',
            processedGenesNumber: 'Porovnaných genů',
            differences: 'Neshodující se informace',
            errors: {
                wrongFormat: 'Číslo není vyplněno správně',
                emptyMessage: 'Prosím, vyplňte toto pole',
            },
        },
        'paternityCheck/Table': {
            snpName: 'Název SNP',
            childAlleles: 'Alely dítěte',
            parentAlleles: 'Alely rodiče',
        },
    },
    EN: {
        'paternityCheck/screenPaternityCheck': {
            formTitle: 'Animal data',
            title: 'Paternity verification',
            result: 'Result',
        },
        'paternityCheck/paternityCheckForm': {
            text: 'To determine paternity, please fill in the following data: ',
            checkButton: 'Check',
            calfNumber: 'Calf',
            parentNumber: 'Parent',
        },
        'paternityCheck/PaternityCheckResult': {
            accord: 'Conformity',
            parentNumber: 'Parent',
            processedGenesNumber: 'Compared genes',
            differences: 'Inconsistent information',
            errors: {
                wrongFormat: 'The number is not filled in correctly',
                emptyMessage: 'Please fill in this field',
            },
        },
        'paternityCheck/Table': {
            snpName: 'SNP name',
            childAlleles: 'Child alleles',
            parentAlleles: 'Parent alleles',
        },
    },
};
