// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// A name of the customer
// Example: "Českomoravská společnost chovatelů, a.s."

export const customerNameMinLength = 1;
export const customerNameMaxLength = 255;

export const CustomerNameSchema = t.refinement(t.string, n => n.length >= customerNameMinLength && n.length <= customerNameMaxLength, 'CustomerName');

export type CustomerName = string;
