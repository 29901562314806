// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { AssociationType, AssociationTypeSchema } from 'api/gen/AssociationType';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { FirstName, FirstNameSchema } from 'api/gen/FirstName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Language, LanguageSchema } from 'api/gen/Language';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { LastName, LastNameSchema } from 'api/gen/LastName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Phone, PhoneSchema } from 'api/gen/Phone';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PlemdatEmail, PlemdatEmailSchema } from 'api/gen/PlemdatEmail';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveLong, PositiveLongSchema } from 'api/gen/PositiveLong';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { UserRole, UserRoleSchema } from 'api/gen/UserRole';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Username, UsernameSchema } from 'api/gen/Username';

const requiredPart = t.interface({
  associationType: t.union([AssociationTypeSchema, t.null]),
  email: PlemdatEmailSchema,
  firstName: t.union([FirstNameSchema, t.null]),
  hasFleckviehAnimals: t.boolean,
  hasHolsteinAnimals: t.boolean,
  id: PositiveLongSchema,
  language: LanguageSchema,
  lastName: t.union([LastNameSchema, t.null]),
  phone: t.union([PhoneSchema, t.null]),
  role: UserRoleSchema,
  username: UsernameSchema,
});

export const UserDetailsSchema = excess(requiredPart);

export interface UserDetails extends t.TypeOf<typeof UserDetailsSchema> {}
