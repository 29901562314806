export const userTranslations = {
    CS: {
        'user': {
            loadingUser: 'Načítání uživatele…',
            notLoggedIn: 'Nejste přihlášen.',
            login: 'Přihlásit se',
            noPermissionMessage: 'Nemáte oprávnění pro zobrazení této stránky.',
            loginAsAnother: 'Přihlásit se za jiného uživatele',
        },
        'user/sagas': {
            getCurrentUser: 'Načtení aktuálního uživatele',
        },
    },
    EN: {
        'user': {
            loadingUser: 'User loading…',
            notLoggedIn: 'You\'re not logged in.',
            login: 'Log in',
            noPermissionMessage: 'You don\'t have permission to view this page.',
            loginAsAnother: 'Log in as another user',
        },
        'user/sagas': {
            getCurrentUser: 'Load current user',
        },
    },
};
