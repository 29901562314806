import {extractFormErrorsFromResponse, takeLatestF, putAll} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {put, call} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'translations';
import {formHelpers} from 'utils/forms';
import {showBeError} from 'utils/show-be-error';

import {breederInfoFormName} from '../../components/BreederInfoForm/breeder-info-form-values';
import {dialsAction, GetBreederInfoConfigurationAction} from '../action';

function* execute(_action: GetBreederInfoConfigurationAction): SagaIterator {
    yield* put(formHelpers.startSubmit(breederInfoFormName));
    yield* put(formHelpers.reset(breederInfoFormName));

    const response = yield* call(Api.getSideBoxConfiguration);

    if (response.isSuccess) {
        yield* put(dialsAction.getBreederInfoConfigurationSuccess(response.data));
    } else {
        yield* put(formHelpers.stopSubmit(breederInfoFormName, extractFormErrorsFromResponse(response)));
        yield putAll(showBeError(response, t('dials/screenList')('breederInfoCaption')));
    }
}

export function* getBreederInfoConfigurationSaga(): SagaIterator {
    yield takeLatestF('dials/GET_BREEDER_INFO_CONFIGURATION', execute);
}
