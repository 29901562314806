// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// A first and last name of a person.
// Example: "John Doe"

export const WholeNameSchema = t.string;

export type WholeName = string;
