import {Options, Button} from '@fl/cmsch-fe-library';
import React, {FC, useEffect, useMemo} from 'react';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import * as routerActions from 'redux-first-history';

import {CategoryDetails} from 'api/gen/CategoryDetails';
import {Measurements} from 'api/gen/Measurements';
import {State} from 'app/state';
import {Container, MainPanel, PageHeading} from 'layout';
import {useOurTranslation} from 'translations';
import {formHelpers} from 'utils/forms';

import {ComparisonForm} from '../components/ComparisonForm';
import {ComparisonResult} from '../components/ComparisonResult';
import {animalsAction} from '../model';

const getCategoryOptions = (categories: Array<CategoryDetails> | null): Options<number> =>
    (categories ?? []).map(x => ({label: x.name, value: x.id}));

const getCategoryIds = (categories: Array<CategoryDetails>): Array<number> =>
    categories.map(x => x.id);

interface StateProps {
    categories: Array<CategoryDetails> | null;
    measurements: Measurements | null;
}

interface DispatchProps {
    loadCategories(): void;
    selectCategories(categories: Array<number>): void;
    loadMeasurements(): void;
    goBack(): void;
    resetMeasurementsAndCategories(): void;
}

type Props = StateProps & DispatchProps;

const ComparisonBase: FC<Props> = props => {
    const {
        categories,
        selectCategories,
        measurements,
        loadMeasurements,
        goBack,
        loadCategories,
        resetMeasurementsAndCategories,
    } = props;

    const {t} = useOurTranslation('animals/screenComparison');
    const categoryOptions = useMemo(() => getCategoryOptions(categories), [categories]);

    useEffect(() => {
        loadCategories();
    }, [loadCategories]);

    useEffect(() => {
        if (categories && !measurements) {
            selectCategories(getCategoryIds(categories));
            loadMeasurements();
        }
    }, [categories, measurements, selectCategories, loadMeasurements]);

    return (
        <Container>
            <MainPanel>
                <div className="row">
                    <div className="col-12">
                        <PageHeading>
                            <h1>{t('title')}</h1>
                        </PageHeading>
                    </div>
                </div>

                {categories && (
                    <ComparisonForm
                        categoryOptions={categoryOptions}
                        onSubmit={loadMeasurements}
                        onSelectCategories={selectCategories}
                    />
                )}
            </MainPanel>

            {measurements && (
                <div className="mt-4">
                    <MainPanel>
                        <div className="row">
                            <div className="col-12">
                                <PageHeading>
                                    <h1>{t('resultsTitle')}</h1>
                                </PageHeading>
                            </div>
                        </div>

                        <ComparisonResult
                            measurements={measurements}
                            resetMeasurementsAndCategories={resetMeasurementsAndCategories}
                        />

                        <div className="mt-2">
                            <Button
                                visuals="primary"
                                onClick={goBack}
                            >
                                {t('back')}
                            </Button>
                        </div>
                    </MainPanel>
                </div>
            )}
        </Container>
    );
};

const mapStateToProps = (state: State): StateProps => ({
    categories: state.animals.categories,
    measurements: state.animals.measurements,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    loadCategories: (): void => void dispatch(animalsAction.getCategories()),
    selectCategories: (categories: Array<number>): void =>
        void dispatch(formHelpers.change('animalComparison', 'categories', categories)),
    loadMeasurements: (): void => void dispatch(animalsAction.getMeasurements()),
    goBack: (): void => void dispatch(routerActions.push('/animals')),
    resetMeasurementsAndCategories: (): void => void dispatch(animalsAction.resetMeasurementsAndCategories()),
});

export const Comparison = connect(mapStateToProps, mapDispatchToProps)(ComparisonBase);
