import {putAll, takeLatestF, showSuccess} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'translations';
import {showBeError} from 'utils/show-be-error';

import {CreateGenotypeExportByIdsAction, orderAction} from '../action';
import {getSelectedOrders} from './selectors';

function* execute(_action: CreateGenotypeExportByIdsAction): SagaIterator {
    const orderIds = yield* select(getSelectedOrders);
    yield* put(orderAction.setListActionActive('exportGenotypes', true));

    const response = yield* call(Api.createGenotypeExportSubset, {orderIds});

    if (response.isSuccess) {
        yield* put(showSuccess(
            t('orders/ListActions')('exportGenotypes'),
            t('orders/ListActions')('exportGenotypesStart'),
        ));
        yield* put(orderAction.getOrders(opt({action: 'refresh'})));
    } else {
        yield putAll(showBeError(response, t('orders/ListActions')('exportGenotypes')));
    }
    yield* put(orderAction.setListActionActive('exportGenotypes', false));
}

export function* createGenotypeExportByIdsSaga(): SagaIterator {
    yield takeLatestF('order/CREATE_GENOTYPE_EXPORT_BY_IDS', execute);
}
