import {ErrorResponse, ErrorsFromBE, putAll, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'translations';
import {showBeError} from 'utils/show-be-error';

import {orderAction, VerifyPaternityAction} from '../action';

const getErrorFromResponse = (response: ErrorResponse): string => {
    const errors = response.data as ErrorsFromBE;

    return errors[0].message;
};

function* handleErrorResponse(response: ErrorResponse): SagaIterator {
    if (response.isNotFound) {
        return yield* put(orderAction.verifyPaternityError(getErrorFromResponse(response)));
    } else {
        return yield putAll(showBeError(response, t('orders/sagas')('verifyPaternity')));
    }
}

function* execute({payload: {data}}: VerifyPaternityAction): SagaIterator {
    const {childSampleId, fathersSampleId, mothersSampleId} = data;

    const responseBoth = yield* call(
        Api.verifyParenthoodForBothParents,
        {childSampleId, fathersSampleId, mothersSampleId},
    );

    if (!responseBoth.isSuccess) {
        return yield* call(handleErrorResponse, responseBoth);
    }

    const responseFather = yield* call(
        Api.verifyParenthood,
        {childSampleId, parentSampleId: fathersSampleId},
    );

    if (!responseFather.isSuccess) {
        return yield* call(handleErrorResponse, responseFather);
    }

    const responseMother = yield* call(
        Api.verifyParenthood,
        {childSampleId, parentSampleId: mothersSampleId},
    );

    if (!responseMother.isSuccess) {
        return yield* call(handleErrorResponse, responseMother);
    }

    yield* put(orderAction.verifyPaternitySuccess(
        responseBoth.data.probability,
        responseFather.data.probability,
        responseMother.data.probability,
    ));
}

export function* verifyPaternitySaga(): SagaIterator {
    yield takeLatestF('order/VERIFY_PATERNITY', execute);
}
