import {classNames, useWindowDimensions, LG} from '@fl/cmsch-fe-library';
import {ItemType} from 'antd/lib/menu/hooks/useItems';
import React, {FC, memo, useCallback, useMemo} from 'react';
import {useDispatch} from 'react-redux';
import {useLocation} from 'react-router';
import {opt} from 'ts-opt';

import {appAction} from 'app/model/action';
import {AntMenu} from 'common/ant';
import {SvgImage} from 'images';
import {useOurTranslation} from 'translations';
import {useUser} from 'user';
import {isValidRoleName} from 'user/types/role-names';

import {buildMenuItems} from './build-menu-items';
import {buildUserSubMenu} from './build-user';
import {getMenuItems} from './menu-items';

import styles from './styles.sass';

import slopeLeftSvg from './slopeLeft.svg';
import slopeRightSvg from './slopeRight.svg';

interface Props {
    isSideMenu?: boolean;
    isCollapsed?: boolean;
    testId?: string;
    onMenuItemClick?(): void;
    goTo(path: string): void;
}

const MainMenuBase: FC<Props> = props => {
    const {
        isSideMenu,
        isCollapsed,
        testId,
        onMenuItemClick,
        goTo,
    } = props;

    const {pathname} = useLocation();

    const {t} = useOurTranslation('layout/mainMenu');
    const {t: tUser} = useOurTranslation('user');
    const {width} = useWindowDimensions();
    const {currentUser, isHolsteinAssociation, isMeatAssociation} = useUser();
    const dispatch = useDispatch();

    const onSubmenuClick = useCallback((path: string) => {
        dispatch(appAction.clearLoadingContent());
        if (!isSideMenu) goTo(path);
    }, [dispatch, goTo, isSideMenu]);

    const handleMenuItemClick = useCallback(() => {
        dispatch(appAction.clearLoadingContent());
        onMenuItemClick?.();
    }, [dispatch, onMenuItemClick]);

    const {firstName, lastName} = currentUser || {};
    const fullName = [firstName, lastName].join(' ');

    const allMenuItems = useMemo((): Array<ItemType> => {
        if (!currentUser) return [];
        const roleName = currentUser.role.name;
        if (!isValidRoleName(roleName)) return [];

        return [
            ...buildMenuItems(
                getMenuItems(opt(currentUser), isMeatAssociation, isHolsteinAssociation, t), roleName, onSubmenuClick,
            ),
            buildUserSubMenu(fullName),
        ];
    }, [currentUser, isMeatAssociation, isHolsteinAssociation, t, onSubmenuClick, fullName]);

    const menuClasses = classNames(
        'd-sm-flex justify-content-center',
        !isSideMenu && styles.antMenuHorizontal,
    );

    return (
        <div
            className={classNames('d-lg-flex justify-content-center position-relative', styles.menu)}
            data-test-id={testId}
        >
            <div className="d-none d-lg-inline-block">
                <span className={classNames(styles.slopeLeft, 'position-static')}>
                    <SvgImage
                        image={slopeLeftSvg}
                    />
                </span>
            </div>
            <div
                className={classNames('d-lg-inline-block')}
                data-test-id="main-menu"
            >
                {currentUser && isValidRoleName(currentUser.role.name)
                    ? (
                        <AntMenu
                            mode={isSideMenu ? 'inline' : 'horizontal'}
                            theme="dark"
                            selectedKeys={[pathname, `${pathname}/`]}
                            onClick={handleMenuItemClick}
                            disabledOverflow={width >= LG}
                            openKeys={isCollapsed ? [] : undefined}
                            // eslint-disable-next-line react/forbid-component-props
                            className={menuClasses}
                            rootClassName={styles.antMenuRoot}
                            items={allMenuItems}
                            _internalDisableMenuItemTitleTooltip
                        />
                    )

                    : (
                        <div className={styles.loaderPlaceholder}>
                            <div>
                                {tUser('loadingUser')}
                            </div>
                        </div>
                    )
                }
            </div>
            <div className="d-none d-lg-inline-block">
                <div className="d-none d-lg-inline-block">
                    <span className={classNames(styles.slopeRight, 'position-static')}>
                        <SvgImage
                            image={slopeRightSvg}
                        />
                    </span>
                </div>
            </div>
        </div>
    );
};

export const MainMenu = memo(MainMenuBase);
