import React, {FC, memo, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Redirect, Route, useLocation} from 'react-router';
import {Switch} from 'react-router-dom';
import {SwitchTransition, CSSTransition} from 'react-transition-group';

import {animalsRoutes, animalsRoutesWithoutFetching} from 'animals';
import {appAction} from 'app/model/action';
import {loadingSelector, urlWasChanged} from 'app/model/selector';
import {dialsRoutes, dialsRoutesWithoutFetching} from 'dials';
import {fileImportRoutes, fileImportRoutesWithoutFetching} from 'file-import';
import {exportGenotypesRoutes, genotypeExportsRoutesWithoutFetching} from 'genotypeExports';
import {orderRoutes, orderRoutesWithoutFetching} from 'orders';
import {otherRoutes, otherRoutesWithoutFetching} from 'other';
import {parentSearchRoutes, parentSearchRoutesWithoutFetching} from 'parentSearch';
import {paternityCheckRoutes, paternityCheckRoutesWithoutFetching} from 'paternityCheck';
import {snpManagementRoutes, snpManagementRoutesWithoutFetching} from 'snp-management';
import {userRoutes, userRoutesWithoutFetching, useUser} from 'user';

import {NotFound} from '../../screens';
import {SitesWrapper} from './sites-wrapper';

import styles from './styles.sass';

const routesWithoutFetching = [
    ...animalsRoutesWithoutFetching,
    ...orderRoutesWithoutFetching,
    ...dialsRoutesWithoutFetching,
    ...otherRoutesWithoutFetching,
    ...fileImportRoutesWithoutFetching,
    ...parentSearchRoutesWithoutFetching,
    ...paternityCheckRoutesWithoutFetching,
    ...snpManagementRoutesWithoutFetching,
    ...userRoutesWithoutFetching,
    ...genotypeExportsRoutesWithoutFetching,
];

const addEndListener = (node: HTMLElement, done: () => void): void =>
    node.addEventListener('transitionend', done, false);

const SwitchTransitionWrapperBase: FC = () => {
    const location = useLocation();
    const {pathname} = location;
    const isLoading = useSelector(loadingSelector);
    const isUrlChanged = useSelector(urlWasChanged);
    const {isRoleLaborer} = useUser();
    const dispatch = useDispatch();
    const changeLoadersUrl = useCallback(() => {
        dispatch(appAction.changeLoadersUrl(pathname));
    }, [dispatch, pathname]);

    const redirectToHomePage = useCallback(() => {
        if (isRoleLaborer) {
            return <Redirect to="/orders" />;
        } else {
            return <Redirect to="/orders-list" />;
        }
    }, [isRoleLaborer]);

    const transitionClasses = {
        enter: styles.fadeEnter,
        enterActive: styles.fadeEnterActive,
        exit: styles.fadeExit,
        exitActive: styles.fadeExitActive,
    };

    return (
        <SwitchTransition>
            <CSSTransition
                key={pathname}
                addEndListener={addEndListener}
                classNames={transitionClasses}
                unmountOnExit
                mountOnEnter
                in
            >
                <SitesWrapper
                    routesWithoutFetching={routesWithoutFetching}
                    isLoading={isLoading}
                    changeLoadersUrl={changeLoadersUrl}
                    isUrlChanged={isUrlChanged}
                >
                    <Switch location={location}>
                        {animalsRoutes}
                        {orderRoutes}
                        {dialsRoutes}
                        {otherRoutes}
                        {fileImportRoutes}
                        {paternityCheckRoutes}
                        {parentSearchRoutes}
                        {snpManagementRoutes}
                        {userRoutes}
                        {exportGenotypesRoutes}
                        <Route
                            path="/"
                            exact
                            render={redirectToHomePage}
                        />
                        <Route
                            path=""
                            component={NotFound}
                        />
                    </Switch>
                </SitesWrapper>
            </CSSTransition>
        </SwitchTransition>
    );
};

export const SwitchTransitionWrapper = memo(SwitchTransitionWrapperBase);
