import {Options} from '@fl/cmsch-fe-library';
import React, {FC, memo} from 'react';

import {FooterButtons} from 'common/buttons';
import {useOurTranslation} from 'translations';
import {useForm} from 'utils/forms';

import {newOrderInitialValues, NewOrderFormValues, newOrderFormName} from '../NewForm/new-order-form-values';
import {validate} from '../NewForm/validations';

interface Props {
    sampleTypes: Options<number>;
    orderTypes: Options<number>;
    onPreviousClick(): void;
    onSubmit(values: NewOrderFormValues): void;
}

const OrderDetailsFormBase: FC<Props> = props => {
    const {
        sampleTypes,
        orderTypes,
        onPreviousClick,
        onSubmit,
    } = props;

    const {t} = useOurTranslation('orders/OrderDetailsForm');

    const {Form, Fields, renderErrors} = useForm({
        form: newOrderFormName,
        destroyOnUnmount: false,
        forceUnregisterOnUnmount: true,
        keepDirtyOnReinitialize: true,
        initialValues: newOrderInitialValues,
        validate,
        onSubmit,
    });

    return (
        <div data-test-id="new-orders-form-2">
            <Form>
                {renderErrors()}

                <fieldset>
                    <legend data-test-id="legend">{t('caption')}</legend>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <Fields.Input
                                name="barcode"
                                label={t('barcode')}
                                type="text"
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-md-6">
                            <Fields.NumberSelect
                                name="sampleTypeId"
                                label={t('sampleTypeId')}
                                placeholder={t('sampleTypeIdPlaceholder')}
                                options={sampleTypes}
                                isRequired
                                clearable={false}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-md-6">
                            <Fields.NumberMultiSelect
                                name="orderTypeIds"
                                label={t('orderTypeIds')}
                                placeholder={t('orderTypeIdsPlaceholder')}
                                options={orderTypes}
                                isRequired
                                clearable={false}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-md-8">
                            <Fields.Textarea
                                name="customerNote"
                                label={t('customerNote')}
                                rows={8}
                                placeholder={t('customerNotePlaceholder')}
                            />
                        </div>
                    </div>
                </fieldset>

                <FooterButtons
                    cancelTitle={t('back')}
                    onCancel={onPreviousClick}
                    submitTitle={t('submit')}
                />
            </Form>
        </div>
    );
};

export const OrderDetailsForm = memo(OrderDetailsFormBase);
