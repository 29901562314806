import {putAll, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {none, opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {appAction} from 'app/model/action';
import {t} from 'translations';
import {showBeError} from 'utils/show-be-error';
import {tablesAction, getRequestedTablePageParams, simpleTableSelector} from 'utils/tables';

import {convertOriginInconsisitenciesTableDataParamsToDto} from '../../utils/conver-table-data-params-to-dto';
import {animalsAction, GetOriginInconsistencies} from '../action';

function* execute({payload: {tableDataRequest}}: GetOriginInconsistencies): SagaIterator {
    if (tableDataRequest.isEmpty) {
        yield* put(animalsAction.getOriginInconsistenciesSuccess(none));

        return yield* put(appAction.removeLoadingContent('originInconsistencies'));
    }
    yield* put(tablesAction.preloadTableActions(
        'originInconsistencies',
        tableDataRequest.orCrash('No tableDataRequest'),
    ));

    const tableState = yield* select(simpleTableSelector.tableState('originInconsistencies'));

    const tableDataParams = getRequestedTablePageParams({
        tableId: 'originInconsistencies',
        tableDataRequest: tableDataRequest.orCrash('No tableDataRequest'),
        tableState,
    });

    const {filters, params} = convertOriginInconsisitenciesTableDataParamsToDto(tableDataParams);
    const response = yield* call(Api.findAllOriginInconsistencies, filters, params);

    if (response.isSuccess) {
        yield* put(animalsAction.getOriginInconsistenciesSuccess(opt(response.data)));
        yield* put(tablesAction.postloadTableActions('originInconsistencies', tableDataParams));
    } else {
        yield putAll(showBeError(response, t('animals/OriginInconsistenciesTable')(`messages.tableError`)));
        yield* put(appAction.removeLoadingContent('originInconsistencies'));
    }
}

export function* getOriginInconsistenciesSaga(): SagaIterator {
    yield takeLatestF('animals/GET_ORIGIN_INCONSISTENCIES', execute);
}
