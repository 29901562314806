import {extractFormErrorsFromResponse, takeLatestF, putAll} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {put, call} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'translations';
import {formHelpers} from 'utils/forms';
import {showBeError} from 'utils/show-be-error';

import {createOrderTypeFormName} from '../../components/OrderTypeForm/order-type-form-values';
import {CreateOrderTypeAction, dialsAction} from '../action';

function* execute(action: CreateOrderTypeAction): SagaIterator {
    const {orderType} = action.payload;

    yield* put(formHelpers.startSubmit(createOrderTypeFormName));

    const response = yield* call(Api.createOrderType, orderType);

    if (response.isSuccess) {
        yield* put(formHelpers.reset(createOrderTypeFormName));
        yield* put(dialsAction.getOrderTypes());
        yield* put(dialsAction.setModalVisibility('orderType', false));
        yield* put(formHelpers.stopSubmit(createOrderTypeFormName));
    } else {
        yield* put(formHelpers.stopSubmit(createOrderTypeFormName, extractFormErrorsFromResponse(response)));
        yield putAll(showBeError(response, t('dials/orderTypes')('createNew')));
    }
}

export function* createOrderTypeSaga(): SagaIterator {
    yield takeLatestF('dials/CREATE_ORDER_TYPE', execute);
}
