import {NullableKeys} from '@fl/cmsch-fe-library';

import {AnimalName} from 'api/gen/AnimalName';
import {Barcode} from 'api/gen/Barcode';
import {BreedAlpha6} from 'api/gen/BreedAlpha6';
import {Date} from 'api/gen/Date';
import {EarTag} from 'api/gen/EarTag';
import {GlobalEarTag} from 'api/gen/GlobalEarTag';
import {LineRegistry} from 'api/gen/LineRegistry';
import {SexType} from 'api/gen/SexType';

import {
    UserDetailsFormSectionValues,
    userDetailsInitialValues,
} from '../UserDetailsFormSection/user-details-form-section-values';

export const newBulkOrderFormName = 'newBulkOrder';

export interface NewBulkOrderFormValues {
    animalsDetails: Array<AnimalsDetailsTableType>;
    sampleTypeId: number | null;
    orderTypeIds: Array<number> | null;
    userDetails: UserDetailsFormSectionValues | null;
}
export type AnimalsDetailsTableType = NullableKeys<{
    id: number,
    barcode: Barcode,
    breed: BreedAlpha6,
    dob: Date,
    earTag: GlobalEarTag,
    fatherEarTag: EarTag,
    fatherLinReg: LineRegistry,
    lineRegistry: LineRegistry,
    motherEarTag: EarTag,
    name: AnimalName,
    sex: SexType,
    twin: boolean,
    isEditing: boolean,
    fullName: string,
}>;

export const newBulkOrderInitialValues: NewBulkOrderFormValues = {
    animalsDetails: [],
    orderTypeIds: null,
    sampleTypeId: null,
    userDetails: userDetailsInitialValues,
};
