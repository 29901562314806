import {TableApiParams, TableDataParams, TableState} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {select} from 'typed-redux-saga';

import {TableFilter} from 'api/gen/TableFilter';
import {convertTableDataParamsToDto, simpleTableSelector} from 'utils/tables';

import {OrderTableType} from '../components/OrdersTable/order-table-type';

const fulltextFilterFields: Array<keyof OrderTableType> = [
    'earTag',
    'breed',
    'laboratoryNumber',
    'customerName',
    'chipId',
];

export const convertOrderTableDataParamsToDto = (
    tableDataParams: TableDataParams<OrderTableType>,
    tableState?: TableState<OrderTableType>,
): TableApiParams<TableFilter> =>
    convertTableDataParamsToDto({
        tableDataParams,
        columnSettings: tableState?.columnSettings,
        fulltextFilterFields,
    });

export function* getOrderTableParamsDto(): SagaIterator<TableApiParams<TableFilter>> {
    const tableState = yield* select(simpleTableSelector.tableState('orders'));
    const tableDataParams = opt(tableState).propOrCrash('tableDataParams');

    return convertTableDataParamsToDto({
        tableDataParams,
        columnSettings: tableState?.columnSettings,
        fulltextFilterFields,
    });
}
