// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.75.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.75.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-orders-components-AnimalDetailsFormSection-___styles__sampleIdExample___th8d5{background-color:#b0d881;padding:2px 3px 0;border-radius:3px}.src-modules-orders-components-AnimalDetailsFormSection-___styles__animalNotFoundWarning___Bl1ZK{background-color:#dc3545;color:#fff}@media (max-width: 480px){.src-modules-orders-components-AnimalDetailsFormSection-___styles__helpTextHelper___QnoCP label{width:100%}.src-modules-orders-components-AnimalDetailsFormSection-___styles__helpTextHelper___QnoCP small{float:left!important}}@media (max-width: 575px){.src-modules-orders-components-AnimalDetailsFormSection-___styles__helpTextHelper___QnoCP label{width:100%}.src-modules-orders-components-AnimalDetailsFormSection-___styles__helpTextHelper___QnoCP small{float:left!important}}\n", "",{"version":3,"sources":["webpack://./src/modules/orders/components/AnimalDetailsFormSection/styles.sass"],"names":[],"mappings":"AAAA,2FAAiB,wBAAA,CAAjB,iBAAA,CAAA,iBAAA,CAAkF,iGAAuB,wBAAA,CAAyB,UAAA,CAAW,0BAAyB,gGAAsB,UAAA,CAAW,gGAAsB,oBAAA,CAAA,CAAuB,0BAAyB,gGAAsB,UAAA,CAAW,gGAAsB,oBAAA,CAAA","sourcesContent":[".sampleIdExample{background-color:#b0d881;padding:2px 3px 0 3px;border-radius:3px}.animalNotFoundWarning{background-color:#dc3545;color:#fff}@media(max-width: 480px){.helpTextHelper label{width:100%}.helpTextHelper small{float:left !important}}@media(max-width: 575px){.helpTextHelper label{width:100%}.helpTextHelper small{float:left !important}}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sampleIdExample": "src-modules-orders-components-AnimalDetailsFormSection-___styles__sampleIdExample___th8d5",
	"animalNotFoundWarning": "src-modules-orders-components-AnimalDetailsFormSection-___styles__animalNotFoundWarning___Bl1ZK",
	"helpTextHelper": "src-modules-orders-components-AnimalDetailsFormSection-___styles__helpTextHelper___QnoCP"
};
export default ___CSS_LOADER_EXPORT___;
