// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


const requiredPart = t.interface({
  number: t.union([t.Integer, t.null]),
  size: t.union([t.Integer, t.null]),
  totalElements: t.union([t.Integer, t.null]),
  totalPages: t.union([t.Integer, t.null]),
});

export const PageMetadataSchema = excess(requiredPart);

export interface PageMetadata extends t.TypeOf<typeof PageMetadataSchema> {}
