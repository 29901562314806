import * as O from 'optics-ts';
import {opt, pipe} from 'ts-opt';

import {Action} from 'app/actions';

import {SnpState, initialSnpState} from './state';

const snpStateO = O.optic<SnpState>();

export const snpReducer = (state: SnpState = initialSnpState, action: Action): SnpState => {
    switch (action.type) {
        case 'snpManagements/TOGGLE_SHOW_CREATE_CATEGORY':
            return O.set(snpStateO.prop('isCreateCategoryVisible'))(!state.isCreateCategoryVisible)(state);

        case 'snpManagements/TOGGLE_SHOW_CREATE_SNP':
            return O.set(snpStateO.prop('isCreateSnpVisible'))(!state.isCreateSnpVisible)(state);

        case 'snpManagements/SET_TABLE_DATA':
            return O.set(snpStateO.prop('pageTableData'))(action.payload.tableData)(state);

        case 'snpManagement/CATEGORIES_SUCCESS':
            return O.set(snpStateO.prop('categories'))(opt(action.payload.category))(state);

        case 'snpManagement/CLOSE_FORM':
            return pipe(state,
                O.set(snpStateO.prop('isCreateCategoryVisible'))(false),
                O.set(snpStateO.prop('isCreateSnpVisible'))(false),
            );

        case 'snpManagement/CHANGE_SELECTED_IDS':
            return O.set(snpStateO.prop('selected'))(action.payload.ids)(state);

        case 'snpManagement/DESELECT_ALL':
            return O.set(snpStateO.prop('selected'))([])(state);

        default:
            return state;
    }
};
