/* eslint-disable @fl/relative-imports-in-index-files-only */
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {loadingUserSelector} from 'app/model/selector';
import {userAction} from 'user/model';
import {simpleUserSelector} from 'user/model/selector';
import {association, breeder, breedingUnion, laborer} from 'user/types/role-names';
import {WithUserProps} from 'user/types/with-user-props';
import {languageEnum} from 'user/utils/language-enum';

// eslint-disable-next-line max-lines-per-function
export function useUser(): WithUserProps {
    const dispatch = useDispatch();

    const {currentUser} = useSelector(simpleUserSelector.userState);
    const loading = useSelector(loadingUserSelector);

    useEffect(() => {
        if (!loading) dispatch(userAction.getCurrentUser());
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const currentUserPresent = currentUser.orNull();
    const role = currentUserPresent?.role.name;
    const isRoleLaborer = role === laborer;
    const isRoleBreeder = role === breeder;
    const isRoleAssociation = role === association;
    const isRoleBreedingUnion = role === breedingUnion;
    const isHungarian = currentUserPresent?.language === languageEnum.en;
    const isMeatAssociation = currentUserPresent?.associationType === 'MEAT';
    const isHolsteinAssociation = currentUserPresent?.associationType === 'HOLSTEIN';
    const isFleckviehAssociation = currentUserPresent?.associationType === 'CZECH_FLECKVIEH';

    return {
        currentUser: currentUserPresent,
        loadingUser: loading,
        isRoleLaborer,
        isRoleBreeder,
        isRoleAssociation,
        isRoleBreedingUnion,
        isRoleLaborerOrBreeder: isRoleLaborer || isRoleBreeder,
        isRoleAssociationOrBreeder: isRoleAssociation || isRoleBreeder,
        isHungarian,
        isMeatAssociation,
        isHolsteinAssociation,
        isFleckviehAssociation,
    };
}
