import React from 'react';
import {Route} from 'react-router-dom';

import {SnpManagement} from './screens';

export const snpManagementRoutesWithoutFetching: Array<RegExp> = [];

export const snpManagementRoutes = [
    (
        <Route
            path="/administration"
            component={SnpManagement}
            exact
            key="snp-management"
        />
    ),
    (
        <Route
            path="/administration/snp-management"
            component={SnpManagement}
            exact
            key="snp-management"
        />
    ),
];
