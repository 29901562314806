// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// The code of the order type.
// Example: "G001"

export const orderTypeCodeMinLength = 1;
export const orderTypeCodeMaxLength = 255;

export const OrderTypeCodeSchema = t.refinement(t.string, n => n.length >= orderTypeCodeMinLength && n.length <= orderTypeCodeMaxLength, 'OrderTypeCode');

export type OrderTypeCode = string;
