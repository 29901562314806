/* eslint-disable max-lines-per-function */
import {
    extractFormErrorsFromResponse,
    Nullable,
    putAll,
    showSuccess,
    showError,
    takeLatestF,
    omit,
} from '@fl/cmsch-fe-library';
import {isNull} from 'lodash/fp';
import * as routerActions from 'redux-first-history';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {NewOrderAnimalInBulk, NewOrderAnimalInBulkSchema} from 'api/gen/NewOrderAnimalInBulk';
import {NewOrderInBulk} from 'api/gen/NewOrderInBulk';
import {logger} from 'app/sentry-logger';
import {transformFormToAddress} from 'orders/utils/transform-address';
import {t} from 'translations';
import {formHelpers} from 'utils/forms';
import {showBeError} from 'utils/show-be-error';

import {AnimalsDetailsTableType, NewBulkOrderFormValues} from '../../components/NewBulkForm/new-bulk-order-form-values';
import {CreateBulkOrderAction, orderAction} from '../action';

export const animalsDetailsDoesNotContainRequiredNull =
    (xs: Array<Omit<AnimalsDetailsTableType, 'id' | 'isEditing'>>): xs is Array<NewOrderAnimalInBulk> =>
        xs.every(NewOrderAnimalInBulkSchema.is);
export const convertAnimalDetails = (
    animalsDetails: Array<AnimalsDetailsTableType>,
): Array<Omit<AnimalsDetailsTableType, 'id' | 'isEditing'>> => animalsDetails.map(a => omit(a, ['id', 'isEditing']));

export const convertFormValuesToBe = (values: NewBulkOrderFormValues): Nullable<NewOrderInBulk> => {
    const {animalsDetails, orderTypeIds, sampleTypeId, userDetails} = values;
    const convertedAnimalsDetails = convertAnimalDetails(animalsDetails);
    if (!isNull(orderTypeIds) && !isNull(sampleTypeId) && userDetails) {
        const {
            address,
            billingAddress,
            customerName,
            vatNumber,
            email,
            firstname,
            id,
            lastname,
            phone,
            cin,
            hasSameAddresses,
        } = userDetails;
        const parsedAddress = transformFormToAddress(address);
        const billingAddressParsed = billingAddress && !hasSameAddresses
            ? transformFormToAddress(billingAddress)
            : parsedAddress;
        if (
            customerName
            && email
            && firstname
            && lastname
            && phone
            && parsedAddress
            && billingAddressParsed
            && animalsDetailsDoesNotContainRequiredNull(convertedAnimalsDetails)
        ) {
            return {
                animals: convertedAnimalsDetails,
                orderTypeIds,
                sampleTypeId,
                userDetails: {
                    address: parsedAddress,
                    billingAddress: billingAddressParsed,
                    customerName,
                    cin,
                    email,
                    firstName: firstname,
                    id,
                    lastName: lastname,
                    phone,
                    vatNumber,
                },
            };
        }
    }

    return null;
};

const te = t('orders/ListActions');

function* execute(action: CreateBulkOrderAction): SagaIterator {
    const {values} = action.payload;

    const order = convertFormValuesToBe(values);
    if (!order) {
        yield* put(showError(
            t('orders/sagas')('missingDataTitle'),
            t('orders/sagas')('missingData'),
        ));

        return;
    }

    yield* put(formHelpers.startSubmit('newBulkOrder'));
    const response = yield* call(Api.createOrdersInBulk, order);

    if (response.isSuccess) {
        yield* put(showSuccess(te('createNew'), t('orders/sagas')('success')));
        yield* put(formHelpers.stopSubmit('newBulkOrder'));
        yield* put(orderAction.setUserDetailsFromCIN(null));
        yield* put(formHelpers.reset('newBulkOrder'));
        yield* put(routerActions.push('/'));
    } else {
        logger.logError({name: 'Bulk order bad request', message: ''});
        yield putAll(showBeError(response, te('createNew')));
        yield* put(formHelpers.stopSubmit('newBulkOrder', extractFormErrorsFromResponse(response)));
    }
}

export function* createBulkOrderSaga(): SagaIterator {
    yield takeLatestF('order/CREATE_BULK_ORDER', execute);
}
