import {emailRegexGen} from 'api/gen/Email';
import {tCommon} from 'translations';
import {PaternityValidator} from 'utils/paternity-validator';
import {Errors} from 'utils/validator';

import {BulkEditOrderEmailFormValues} from './values';

export const validate = (values: BulkEditOrderEmailFormValues): Errors<BulkEditOrderEmailFormValues> => {
    const validator = new PaternityValidator(values);

    validator.nonEmpty('email', tCommon('email'));
    validator.pattern('email', emailRegexGen(), tCommon('email'));

    return validator.generateErrorsObject();
};
