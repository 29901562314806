import {FooterButtons} from '@fl/cmsch-fe-library';
import React, {FC, memo} from 'react';
import {Opt} from 'ts-opt';

import {useOurTranslation} from 'translations';
import {useForm} from 'utils/forms';

import {SampleTypeFormValues, sampleTypeFormName} from './sample-type-form-values';
import {validate} from './validations';

const initialValues: SampleTypeFormValues = {
    value: null,
};

interface Props {
    isEdit: boolean;
    onCancel?(): void;
    onChangeValue(value: Opt<string>): void;
    onSubmit(values: SampleTypeFormValues): void;
}

const SampleTypeFormBase: FC<Props> = props => {
    const {isEdit, onCancel, onSubmit, onChangeValue} = props;

    const {t} = useOurTranslation('dials/sampleTypes');

    const {Form, Fields, valid, pristine} = useForm({
        form: sampleTypeFormName,
        initialValues,
        destroyOnUnmount: true,
        validate,
        onSubmit,
    });

    return (
        <Form>
            <div className="row">
                <div className="col-12">
                    <Fields.Input
                        name="value"
                        type="text"
                        label={t('value')}
                        onFieldChange={onChangeValue}
                        isRequired
                    />
                </div>
            </div>

            <FooterButtons
                editing={isEdit}
                disabledSubmit={!valid || pristine}
                onCancel={onCancel}
            />
        </Form>
    );
};

export const SampleTypeForm = memo(SampleTypeFormBase);
