import {Nullable} from '@fl/cmsch-fe-library';

import {Date} from 'api/gen/Date';
import {SexType} from 'api/gen/SexType';

export const animalEditFormName = 'animalEdit';

export const initialAnimalEditFormValues: AnimalEditFormValues = {
    breed: null,
    dob: null,
    fathersRegistry: null,
    fathersSampleId: null,
    mothersSampleId: null,
    name: null,
    registry: null,
    sampleId: '',
    sex: null,
    twin: false,
    laboratoryNote: null,
};

export interface AnimalEditFormValues {
    sampleId: string;
    dob: Nullable<Date>;
    name: Nullable<string>;
    sex: Nullable<SexType>;
    breed: Nullable<string>;
    registry: Nullable<string>;
    fathersSampleId: Nullable<string>;
    fathersRegistry: Nullable<string>;
    mothersSampleId: Nullable<string>;
    twin: boolean;
    laboratoryNote: Nullable<string>;
}
