import {barcodeMaxLength, barcodeRegexGen} from 'api/gen/Barcode';
import {breedMaxLength} from 'api/gen/Breed';
import {earTagRegexGen} from 'api/gen/EarTag';
import {lineRegistryRegexGen} from 'api/gen/LineRegistry';
import {t} from 'translations';
import {PaternityValidator} from 'utils/paternity-validator';
import {Errors} from 'utils/validator';

import {GenerateRequestFormValues} from './generate-request-form-values';

const te = t('orders/GenerateRequestForm');

export const validate = (values: GenerateRequestFormValues): Errors<GenerateRequestFormValues> => {
    const validator = new PaternityValidator(values);

    validator.pattern('barcode', barcodeRegexGen(), te('barcode'));
    validator.maxStringLength('barcode', barcodeMaxLength, te('barcode'));
    validator.maxStringLength('breed', breedMaxLength, te('breed'));
    validator.maxStringLength('fathersBreed', breedMaxLength, te('fathersBreed'));
    validator.pattern('fathersRegistry', lineRegistryRegexGen(), te('fathersRegistry'));
    validator.pattern('mothersFatherRegistry', lineRegistryRegexGen(), te('mothersFatherRegistry'));
    validator.pattern('mothersSampleId', earTagRegexGen(), te('mothersSampleId'));

    return validator.generateErrorsObject();
};
