import {Options, Button} from '@fl/cmsch-fe-library';
import React, {FC, memo, useCallback} from 'react';

import {useOurTranslation} from 'translations';
import {useForm} from 'utils/forms';

import {ComparisonFormValues, comparisonFormName} from './comparison-form-values';
import {validate} from './validations';

const initialValues: ComparisonFormValues = {
    categories: null,
};

interface Props {
    categoryOptions: Options<number>;
    onSelectCategories(categories: Array<number>): void;
    onSubmit(values: ComparisonFormValues): void;
}

const ComparisonFormBase: FC<Props> = props => {
    const {
        categoryOptions,
        onSelectCategories,
        onSubmit,
    } = props;
    const {t} = useOurTranslation('animals/ComparisonForm');

    const {Form, Fields, pristine, valid, submitting} = useForm({
        form: comparisonFormName,
        initialValues,
        validate,
        onSubmit,
    });

    const selectAllCategories = useCallback(() =>
        onSelectCategories(categoryOptions.map(x => x.value)),
    [categoryOptions, onSelectCategories]);

    return (
        <Form>
            <div className="row pl-1">
                <div className="col-12">
                    <Fields.NumberMultiSelect
                        name="categories"
                        placeholder={t('selectCategory')}
                        label={t('categories')}
                        options={categoryOptions}
                    />
                </div>
            </div>

            <div className="justify-content-between d-flex">
                <Button
                    visuals="link"
                    onClick={selectAllCategories}
                    type="button"
                >
                    {t('allCategories')}
                </Button>
                <Button
                    visuals="primary"
                    disabled={!valid || pristine || submitting}
                    type="submit"
                >
                    {t('filterCategories')}
                </Button>
            </div>
        </Form>
    );
};

export const ComparisonForm = memo(ComparisonFormBase);
