// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// The full name of an animal
// Example: "PARINGA IRON ORE E27 RED"

export const animalFullNameMinLength = 1;
export const animalFullNameMaxLength = 30;

export const AnimalFullNameSchema = t.refinement(t.string, n => n.length >= animalFullNameMinLength && n.length <= animalFullNameMaxLength, 'AnimalFullName');

export type AnimalFullName = string;
