import {Options, TypedFormSectionInjectedPropsUnsafe} from '@fl/cmsch-fe-library';
import React, {FC, memo} from 'react';

import {useOurTranslation} from 'translations';

import {OrderTypeSectionValues} from './order-types-form-values';

export interface OrderTypesFormSectionOuterProps {
    orderTypes: Options<number>;
}

type Props = OrderTypesFormSectionOuterProps & TypedFormSectionInjectedPropsUnsafe<OrderTypeSectionValues>;

const OrderTypesFormSectionBase: FC<Props> = props => {
    const {
        Fields,
        orderTypes,
    } = props;

    const {t} = useOurTranslation('orders/OrderDetailsForm');

    return (
        <fieldset>
            <div className="row">
                <div className="col-12">
                    <Fields.NumberMultiSelect
                        name="orderTypeIds"
                        label={t('orderTypeIds')}
                        placeholder={t('orderTypeIdsPlaceholder')}
                        options={orderTypes}
                        isRequired
                    />
                </div>
            </div>
        </fieldset>
    );
};

export const OrderTypesFormSection = memo(OrderTypesFormSectionBase);
