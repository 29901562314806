import {extractFormErrorsFromResponse, putAll, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {put, call, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'translations';
import {formHelpers} from 'utils/forms';
import {showBeError} from 'utils/show-be-error';

import {getOrderTableParamsDto} from '../../utils/get-order-table-params-dto';
import {orderAction, UpdateOrdersTypesByFilterAction} from '../action';
import {getSelectedOrders} from './selectors';

function* execute({payload: {values, withoutSelected}}: UpdateOrdersTypesByFilterAction): SagaIterator {
    yield* put(formHelpers.startSubmit('bulkEditOrdersTypes'));

    const {filters} = yield* call(getOrderTableParamsDto);
    const excludedOrderIds = withoutSelected ? yield* select(getSelectedOrders) : null;
    const {ordersTypes: {orderTypeIds}, editType} = values;
    const response = yield* call(
        editType === 'add' ? Api.addOrderTypes : Api.removeOrderTypes,
        {excludedOrderIds, filters},
        {orderTypeIds},
    );

    if (response.isSuccess) {
        yield* put(formHelpers.stopSubmit('bulkEditOrdersTypes'));
        yield* put(orderAction.resetBulkEditOrderFilter());
    } else {
        yield putAll(showBeError(response, t('orders/ListActions')('bulkUpdateOrder')));
        yield* put(formHelpers.stopSubmit('bulkEditOrdersTypes', extractFormErrorsFromResponse(response)));
    }
    yield* put(orderAction.setListActionActive('bulkUpdateOrder', false));
    yield* put(orderAction.getOrders(opt({action: 'refresh'})));
}

export function* updateOrdersTypesByFilterSaga(): SagaIterator {
    yield takeLatestF('order/UPDATE_ORDERS_TYPES_BY_FILTER', execute);
}
