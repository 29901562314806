import React, {FC, memo} from 'react';

import {Container, MainPanel, PageHeading} from 'layout';
import {useOurTranslation} from 'translations';
import {useUser} from 'user';

import {OriginInconsistenciesTable} from '../components/OriginInconsistenciesTable';

const OriginInconsistenciesBase: FC = () => {
    const {isRoleLaborer, isRoleAssociation, isFleckviehAssociation} = useUser();
    const {t} = useOurTranslation('animals/OriginInconsistencies');

    return (
        <Container fluid>
            <MainPanel>
                <div className="row">
                    <div className="col-12">
                        <PageHeading>
                            <h1>{t('title')}</h1>
                        </PageHeading>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <OriginInconsistenciesTable
                            isRoleLaborer={isRoleLaborer}
                            isRoleAssociation={isRoleAssociation}
                            isFleckviehAssociation={isFleckviehAssociation}
                        />
                    </div>
                </div>
            </MainPanel>
        </Container>
    );
};

export const OriginInconsistencies = memo(OriginInconsistenciesBase);
