import {createSelector} from 'reselect';
import {Opt} from 'ts-opt';

import {UserDetails} from 'api/gen/UserDetails';
import {State} from 'app/state';
import {UserState} from 'user/model/state';

interface Pathnames {
    previous: Opt<string>;
    current: Opt<string>;
}

export const simpleUserSelector = {
    userState: ({user}: State): UserState => user,
    currentUser: ({user}: State): Opt<UserDetails> => user.currentUser,
};

export const pathnamesSelector = createSelector(
    (state: State) => simpleUserSelector.userState(state).previousPathname,
    (state: State) => simpleUserSelector.userState(state).currentPathname,
    (previous, current): Pathnames => ({
        previous,
        current,
    }),
);
