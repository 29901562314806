import {putAll, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'translations';
import {showBeError} from 'utils/show-be-error';

import {CategoriesAction, snpActions} from '../actions';

function* execute(_action: CategoriesAction): SagaIterator {
    const response = yield* call(Api.getCategories);

    if (response.isSuccess) {
        yield* put(snpActions.loadCategoriesSuccess(response.data));
    } else {
        yield putAll(showBeError(response, t('snpManagement/SnpTable')(`tableHeaders.title`)));
    }
}

export function* loadCategoriesSaga(): SagaIterator {
    yield takeLatestF('snpManagement/LOAD_CATEGORIES', execute);
}
