import {TableDataRequest} from '@fl/cmsch-fe-library';
import {Opt} from 'ts-opt';

import {GenotypeExport} from 'api/gen/GenotypeExport';
import {PagedModelGenotypeExport} from 'api/gen/PagedModelGenotypeExport';

export const genotypeExportsAction = {
    getGenotypeExports: (tableDataRequest: Opt<TableDataRequest<GenotypeExport>>) => ({
        type: 'genotypesExports/GET_GENOTYPE_EXPORTS',
        payload: {tableDataRequest},
    }) as const,
    getGenotypeExportsSuccess: (pageGenotypeExports: Opt<PagedModelGenotypeExport>) => ({
        type: 'genotypesExports/GET_GENOTYPE_EXPORTS_SUCCESS',
        payload: {pageGenotypeExports},
    }) as const,
    processGenotypeExports: () => ({
        type: 'genotypesExports/PROCESS_GENOTYPE_EXPORTS',
    }) as const,
    exportGenotypeExport: (id: number) => ({
        type: 'genotypesExports/EXPORT_GENOTYPE_EXPORT',
        payload: {id},
    }) as const,
};

export type GetGenotypeExportsAction = ReturnType<typeof genotypeExportsAction.getGenotypeExports>;
export type GetGenotypeExportsSuccessAction = ReturnType<typeof genotypeExportsAction.getGenotypeExportsSuccess>;
export type ProcessGenotypeExportsAction = ReturnType<typeof genotypeExportsAction.processGenotypeExports>;
export type ExportGenotypeExportAction = ReturnType<typeof genotypeExportsAction.exportGenotypeExport>;

export type GenotypeExportsAction =
    | GetGenotypeExportsAction
    | GetGenotypeExportsSuccessAction
    | ProcessGenotypeExportsAction
    | ExportGenotypeExportAction
;
