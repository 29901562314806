import {formatNumberCs, formatNumberEn} from '@fl/cmsch-fe-library';

import {languageStore} from './language-store';

export const formatNumberByCurrentLanguage: () => (decimalPlaces: number) => (num: number) => string = () => {
    const language = languageStore.get() || 'CS';
    const numberFormatterFunction = language === 'CS' ? formatNumberCs : formatNumberEn;

    return numberFormatterFunction;
};
