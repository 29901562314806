import {Nullable} from '@fl/cmsch-fe-library';
import React, {FC, memo, useCallback} from 'react';
import {Opt} from 'ts-opt';

import {CodeTableItem} from 'api/gen/CodeTableItem';
import {LastOrderUserDetails} from 'api/gen/LastOrderUserDetails';
import {OrderCustomerDetails} from 'api/gen/OrderCustomerDetails';
import {Tabs} from 'common/ant';
import {Modal} from 'common/layout';
import {useOurTranslation} from 'translations';
import {codeTableItemsToOptions} from 'utils/code-table-items-to-options';

import {ExportOrdersParams} from '../../types/export-orders-params';
import {BulkEditOrderEmailForm} from '../BulkEditOrderEmailForm';
import {BulkEditOrderEmailFormValues} from '../BulkEditOrderEmailForm/values';
import {BulkEditOrderForm} from '../BulkEditOrderForm';
import {BulkEditOrderFormValues} from '../BulkEditOrderForm/bulk-edit-order-form-values';
import {BulkOrderTypesForm} from '../OrderTypesForm';
import {OrdersTypesFormValues} from '../OrderTypesForm/order-types-form-values';

interface Props {
    customers: Array<OrderCustomerDetails>;
    hasSameAddresses: boolean;
    userDetailsFromCIN: Opt<LastOrderUserDetails>;
    disableAddress: boolean;
    bulkEditOrderFilter: Opt<ExportOrdersParams>;
    bulkEditOrderIds: Array<number>;
    visible: boolean;
    orderTypes: Opt<Array<CodeTableItem>>;
    selected: Array<number>;
    withoutSelected: boolean;
    onPrefillFromExisting(userDetails: OrderCustomerDetails): void;
    updateOrdersCustomersByFilter(values: BulkEditOrderFormValues, withoutSelected: boolean): void;
    updateOrdersCustomersByIds(orderIds: Array<number>, values: BulkEditOrderFormValues): void;
    updateOrdersTypesByIds(orderIds: Array<number>, values: OrdersTypesFormValues): void;
    updateOrdersTypesByFilters(values: OrdersTypesFormValues, withoutSelected: boolean): void;
    getUserLastOrder(cin: Nullable<string>): void;
    updateCustomersEmailByIds(selectedOrders: Array<number>, email: string): void;
    updateCustomersEmailByFilter(selectedOrders: Array<number>, email: string, withoutSelected: boolean): void;
    onClose(): void;
}

const EditOrdersCustomersModalBase: FC<Props> = props => {
    const {
        customers,
        hasSameAddresses,
        userDetailsFromCIN,
        disableAddress,
        bulkEditOrderFilter,
        bulkEditOrderIds,
        visible,
        orderTypes,
        selected,
        withoutSelected,
        getUserLastOrder,
        onClose,
        onPrefillFromExisting,
        updateOrdersCustomersByFilter,
        updateOrdersCustomersByIds,
        updateOrdersTypesByFilters,
        updateOrdersTypesByIds,
        updateCustomersEmailByFilter,
        updateCustomersEmailByIds,
    } = props;

    const {t, tCommon} = useOurTranslation('orders/EditOrdersCustomersModal');

    const submitBulkEditForm = useCallback((values: BulkEditOrderFormValues): void => {
        if (bulkEditOrderFilter.nonEmpty) {
            updateOrdersCustomersByFilter(values, withoutSelected);
        } else {
            updateOrdersCustomersByIds(bulkEditOrderIds, values);
        }
    }, [
        bulkEditOrderFilter,
        bulkEditOrderIds,
        updateOrdersCustomersByFilter,
        updateOrdersCustomersByIds,
        withoutSelected,
    ]);

    const submitBulkOrderTypesForm = useCallback((values: OrdersTypesFormValues): void => {
        if (bulkEditOrderFilter.nonEmpty) {
            updateOrdersTypesByFilters(values, withoutSelected);
        } else {
            updateOrdersTypesByIds(bulkEditOrderIds, values);
        }
    }, [bulkEditOrderFilter, bulkEditOrderIds, updateOrdersTypesByFilters, updateOrdersTypesByIds, withoutSelected]);

    const submitBulkEditOrderEmailForm = useCallback(({email}: BulkEditOrderEmailFormValues): void => {
        if (!email) return;
        if (bulkEditOrderFilter.nonEmpty) {
            updateCustomersEmailByFilter(selected, email, withoutSelected);
        } else {
            updateCustomersEmailByIds(selected, email);
        }
    }, [bulkEditOrderFilter, selected, updateCustomersEmailByFilter, updateCustomersEmailByIds, withoutSelected]);

    return (
        <Modal
            title={t('caption')}
            visible={visible}
            onCancel={onClose}
            footer={null}
            width={800}
        >
            <Tabs >
                <Tabs.TabPane
                    key="customerSelection"
                    tab={t('customerData')}
                >
                    <BulkEditOrderForm
                        customers={customers}
                        onSubmit={submitBulkEditForm}
                        hasSameAddresses={hasSameAddresses}
                        onGetUserLastOrder={getUserLastOrder}
                        onPrefillFromExisting={onPrefillFromExisting}
                        onClose={onClose}
                        userDetailsFromCIN={userDetailsFromCIN.orNull()}
                        disableAddress={disableAddress}
                    />
                </Tabs.TabPane>
                <Tabs.TabPane
                    key="email"
                    tab={tCommon('email')}
                >
                    <BulkEditOrderEmailForm
                        onClose={onClose}
                        onSubmit={submitBulkEditOrderEmailForm}
                    />
                </Tabs.TabPane>
                <Tabs.TabPane
                    key="orderTypeSelection"
                    tab={t('ordersTypes')}
                >
                    <BulkOrderTypesForm
                        orderTypes={codeTableItemsToOptions(orderTypes)}
                        onClose={onClose}
                        onSubmit={submitBulkOrderTypesForm}
                    />
                </Tabs.TabPane>
            </Tabs>
        </Modal>
    );
};

export const EditOrdersCustomersModal = memo(EditOrdersCustomersModalBase);
