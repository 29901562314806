import {Nullable} from '@fl/cmsch-fe-library';
import React, {FC, memo, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {OrderCustomerDetails} from 'api/gen/OrderCustomerDetails';
import {FooterButtons} from 'common/buttons';
import {useOnCinClick} from 'hooks/use-on-cin-click';
import {usePrefillFromExisting} from 'hooks/use-prefill-from-existing';
import {useOurTranslation} from 'translations';
import {useForm} from 'utils/forms';

import {orderAction, simpleFormSelector, simpleOrdersSelector} from '../../model';
import {
    newBulkOrderInitialValues,
    newBulkOrderFormName,
    NewBulkOrderFormValues,
} from '../NewBulkForm/new-bulk-order-form-values';
import {validate} from '../NewBulkForm/validations';
import {UserDetailsFormSection, UserDetailsFormSectionOuterProps} from '../UserDetailsFormSection';

interface Props {
    showCreatedBy: boolean;
    caption: string;
    onGetUserLastOrder(cin: Nullable<string>): void;
    onSubmit(values: NewBulkOrderFormValues): void;
}

const NewBulkUserDetailsFormBase: FC<Props> = props => {
    const {
        onGetUserLastOrder,
        showCreatedBy,
        onSubmit,
        caption,
    } = props;

    const {Form, Fields} = useForm({
        form: newBulkOrderFormName,
        enableReinitialize: true,
        destroyOnUnmount: false,
        forceUnregisterOnUnmount: true,
        keepDirtyOnReinitialize: true,
        initialValues: newBulkOrderInitialValues,
        validate,
        onSubmit,
    });

    const dispatch = useDispatch();

    const onPrefillFromExisting = useCallback((user: OrderCustomerDetails): void => {
        const {address, cin, customerName, email, firstname, lastname, phone, vatNumber} = user;
        dispatch(orderAction.setUserDetailsBulkFormValue(user));
        dispatch(orderAction.setUserDetailsFromCIN({
            address,
            billingAddress: null,
            cin,
            customerName,
            email,
            firstName: firstname,
            id: null,
            lastName: lastname,
            phone,
            vatNumber,
        }));
    }, [dispatch]);

    const {t} = useOurTranslation('orders/UserDetailsForm');
    const customers = useSelector(simpleOrdersSelector.customers).orElse([]);
    const breeders = useSelector(simpleOrdersSelector.breeders);
    const userDetailsFromCIN = useSelector(simpleOrdersSelector.userDetailsFromCIN);

    const formValues = useSelector(simpleFormSelector.bulkOrderFormValues);

    const hasSameAddresses = formValues.prop('userDetails').prop('hasSameAddresses').orFalse();
    const noCin = formValues.prop('userDetails').prop('noCin').orFalse();

    const UserDetailSectionField = Fields.genSection<'userDetails', UserDetailsFormSectionOuterProps>();
    const userDetailsProps = {
        customers,
        showCreatedBy,
        breeders: breeders.orElse([]),
        userDetailsFromCIN: userDetailsFromCIN.orNull(),
        noCin,
        inWizard: true,
        showAllInputs: false,
        disableAddress: false,
        hasSameAddresses,
        onCinClick: useOnCinClick(onGetUserLastOrder),
        prefillFromExisting: usePrefillFromExisting(customers, onPrefillFromExisting),
        caption,
    };

    return (
        <Form>
            <UserDetailSectionField
                name="userDetails"
                Component={UserDetailsFormSection}
                props={userDetailsProps}
            />

            <FooterButtons
                submitTitle={t('next')}
                withoutCancel
            />
        </Form>
    );
};

export const NewBulkUserDetailsForm = memo(NewBulkUserDetailsFormBase);
