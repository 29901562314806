import {extractFormErrorsFromResponse, putAll, showBeError, showSuccess, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'translations';
import {formHelpers} from 'utils/forms';

import {orderAction, UpdateCustomersEmailByIdsAction} from '../action';

const te = t('orders/sagas');

function* execute(action: UpdateCustomersEmailByIdsAction): SagaIterator {
    const {email, selectedOrders} = action.payload;

    const response = yield* call(Api.updateCustomersEmailSubset, {email}, {orderIds: selectedOrders});

    if (response.isSuccess) {
        yield* put(formHelpers.stopSubmit('bulkEditOrderEmail'));
        yield* put(orderAction.resetBulkEditOrderIds());
        yield* put(orderAction.deselectAll());
        yield* put(orderAction.getOrders(opt({action: 'refresh'})));
        yield* put(showSuccess(te('updateCustomersEmail'), te('success')));
    } else {
        yield putAll(showBeError(response, te('updateCustomersEmail')));
        yield* put(formHelpers.stopSubmit('bulkEditOrderEmail', extractFormErrorsFromResponse(response)));
    }
}

export function* updateCustomersEmailByIdsSaga(): SagaIterator {
    yield takeLatestF('order/UPDATE_CUSTOMERS_EMAIL_BY_IDS', execute);
}
