import {classNames} from '@fl/cmsch-fe-library';
import React, {FC, memo, ReactNode} from 'react';

import styles from './styles.sass';

interface Props {
    children?: ReactNode;
}

/**
 * `<MainPanel />` should contain the main content of a page. It's design to work well with
 * `<PageHeading />` and `<Panel />` components.
 */
const MainPanelBase: FC<Props> = props => {
    const {children} = props;

    return (
        <div
            className={classNames('bg-light', styles.mainPanel)}
            data-test-id="main-panel"
        >
            {children}
        </div>
    );
};

export const MainPanel = memo(MainPanelBase);
