import {classNames, formatDate, MD, useWindowDimensions, Button} from '@fl/cmsch-fe-library';
import React, {useEffect, Fragment, FC, useCallback, useState} from 'react';
import {opt, isArray, isEmpty} from 'ts-opt';

import {AnimalDetails} from 'api/gen/AnimalDetails';
import {MatchedMicrosatellite} from 'api/gen/MatchedMicrosatellite';
import {Qtl} from 'api/gen/Qtl';
import {Ant} from 'common/ant';
import {gutter} from 'constants/general';
import {useOurTranslation} from 'translations';

import {QtlsTable} from '../QtlsTable';
import {downloadMicrosatellites} from './download-microsatellites';
import {MicrosatelliteView} from './microsatellite-view';

import styles from './styles.sass';

const buildMicrosatellitesFilename = (animal: AnimalDetails): string =>
    `animal-microsatellites-${animal.id}-${animal.sampleId}.txt`;

interface Props {
    animal: AnimalDetails;
    microsatellites: Array<MatchedMicrosatellite> | null;
    qtls: Array<Qtl> | null;
    isRoleBreedingUnion: boolean;
    getMicrosatellites(animalId: number): void;
    resetAnimalQtls(): void;
}

// eslint-disable-next-line max-lines-per-function
export const AnimalDetail: FC<Props> = props => {
    const {
        animal,
        resetAnimalQtls,
        microsatellites,
        isRoleBreedingUnion,
        qtls,
    } = props;
    const {t, tCommon} = useOurTranslation('animals/AnimalDetail');
    const [isDisabledMicrosatellites, setIsDisabledMicrosatellites] = useState(false);
    const [showMicrosatellites, setShowMicrosatellites] = useState(false);

    const onSearchMicrosatellites = useCallback(() => {
        setShowMicrosatellites(true);
    }, []);

    const onSaveMicrosatellites = useCallback(() =>
        downloadMicrosatellites(microsatellites, buildMicrosatellitesFilename(animal)),
    [microsatellites, animal]);

    const {width} = useWindowDimensions();

    useEffect(() => {
        if (isArray(microsatellites) && isEmpty(microsatellites)) {
            setIsDisabledMicrosatellites(true);
        }
    }, [microsatellites]);

    return (
        <Fragment>
            <table className={classNames('table', styles.standardTable)}>
                <tbody>
                    <tr>
                        <th>{tCommon('earTag')}:</th>
                        <td>{animal.sampleId}</td>
                    </tr>

                    <tr>
                        <th>{t('laboratoryNumber')}:</th>
                        <td>{animal.laboratoryNumber}</td>
                    </tr>

                    <tr>
                        <th>{t('mothersLaboratoryNumber')}:</th>
                        <td>{animal.mothersLaboratoryNumber}</td>
                    </tr>

                    <tr>
                        <th>{t('fathersLaboratoryNumber')}:</th>
                        <td>{animal.fathersLaboratoryNumber}</td>
                    </tr>

                    <tr>
                        <th>{t('dob')}:</th>
                        <td>{opt(animal.dob).chain(formatDate).orElse('')}</td>
                    </tr>

                    <tr>
                        <th>{t('breed')}:</th>
                        <td>{animal.breed}</td>
                    </tr>

                    <tr>
                        <th>{t('sex')}:</th>
                        <td>{animal.sex ? tCommon(animal.sex === 'MALE' ? 'sex.male' : 'sex.female') : ''}</td>
                    </tr>

                    <tr>
                        <th>{t('name')}:</th>
                        <td>{animal.name}</td>
                    </tr>

                    <tr>
                        <th>{t('registry')}:</th>
                        <td>{animal.registry}</td>
                    </tr>

                    <tr>
                        <th>{t('chipId')}:</th>
                        <td>{animal.chipId}</td>
                    </tr>

                    <tr>
                        <th>{t('twin')}:</th>
                        <td>{animal.twin ? 'Ano' : 'Ne'}</td>
                    </tr>

                    <tr>
                        <th>{tCommon('note')}:</th>
                        <td>{animal.laboratoryNote}</td>
                    </tr>
                </tbody>
            </table>

            <table className={classNames('table', styles.standardTable)}>
                <thead className="thead-light">
                    <tr>
                        <th colSpan={2}>{t('fatherData')}</th>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <th>{tCommon('earTag')}:</th>
                        <td>{animal.fathersSampleId}</td>
                    </tr>

                    <tr>
                        <th>{t('fathersRegistry')}:</th>
                        <td>{animal.fathersRegistry}</td>
                    </tr>
                </tbody>
            </table>

            <table className={classNames('table', styles.standardTable)}>
                <thead className="thead-light">
                    <tr>
                        <th colSpan={2}>{t('motherData')}</th>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <th>{tCommon('earTag')}:</th>
                        <td>{animal.mothersSampleId}</td>
                    </tr>
                </tbody>
            </table>

            {qtls && !isEmpty(qtls) && (
                <div className="mb-4">
                    <table className="table">
                        <thead className="thead-light">
                            <tr>
                                <th>Qtl</th>
                            </tr>
                        </thead>
                    </table>
                    <div className="px-3">
                        <QtlsTable
                            qtls={qtls}
                            noScrollX={width > MD}
                            resetAnimalQtls={resetAnimalQtls}
                        />
                    </div>
                </div>
            )}

            {!isRoleBreedingUnion && (
                <Fragment>
                    <table className={classNames('table', styles.standardTable)}>
                        <thead className="thead-light">
                            <tr>
                                <th colSpan={2}>{t('microsatellites')}</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td className="px-0">
                                    <Ant.Row gutter={[gutter, gutter]}>
                                        <Ant.Col
                                            xs={24}
                                            sm={12}
                                        >
                                            <Button
                                                block
                                                disabled={isDisabledMicrosatellites}
                                                onClick={onSearchMicrosatellites}
                                                visuals="primary"
                                            >
                                                {t('searchMicrosatellites')}
                                            </Button>
                                        </Ant.Col>
                                        {microsatellites && (
                                            <Ant.Col
                                                xs={24}
                                                sm={12}
                                            >
                                                <Button
                                                    block
                                                    disabled={isDisabledMicrosatellites}
                                                    onClick={onSaveMicrosatellites}
                                                    visuals="primary"
                                                >
                                                    {t('saveMicrosatellites')}
                                                </Button>
                                            </Ant.Col>
                                        )}
                                    </Ant.Row>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    {showMicrosatellites ? (
                        <div>
                            {microsatellites?.map(microsatellite => (
                                <MicrosatelliteView
                                    key={microsatellite.microsatellite}
                                    microsatellite={microsatellite}
                                />
                            ))}
                        </div>
                    ) : ''}
                </Fragment>
            )}
        </Fragment>
    );
};
