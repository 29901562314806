import {earTagRegexGen} from 'api/gen/EarTag';
import {tCommon} from 'translations';
import {PaternityValidator} from 'utils/paternity-validator';
import {Errors} from 'utils/validator';

import {PaternityCheckFormValues} from './paternity-check-form-values';

export const validate = (values: PaternityCheckFormValues): Errors<PaternityCheckFormValues> => {
    const validator = new PaternityValidator(values);

    validator.nonEmpty('childSampleId', tCommon('earTag'));
    validator.pattern('childSampleId', earTagRegexGen(), tCommon('earTag'));
    validator.nonEmpty('parentSampleId', tCommon('earTag'));
    validator.pattern('parentSampleId', earTagRegexGen(), tCommon('earTag'));

    return validator.generateErrorsObject();
};
