import {none, Opt} from 'ts-opt';

import {CodeTableItem} from 'api/gen/CodeTableItem';
import {EmailTemplateDetails} from 'api/gen/EmailTemplateDetails';
import {OrderTypeItem} from 'api/gen/OrderTypeItem';
import {SideBox} from 'api/gen/SideBox';
import {VerificationOutcome} from 'api/gen/VerificationOutcome';

import {DialsModals} from '../types/dials-modals';

export interface DialsState {
    modalsVisibility: Array<DialsModals>;
    sampleTypes: Opt<Array<CodeTableItem>>;
    sampleTypeIdBeingEdited: Opt<number>;
    orderTypes: Opt<Array<OrderTypeItem>>;
    orderTypeIdBeingEdited: Opt<number>;
    verificationOutcomes: Opt<Array<VerificationOutcome>>;
    verificationOutcomeIdBeingEdited: Opt<number>;
    testResults: Opt<Array<CodeTableItem>>;
    testResultIdBeingEdited: Opt<number>;
    mailTemplates: Opt<Array<EmailTemplateDetails>>;
    mailTemplateIdBeingEdited: Opt<number>;
    breederInfo: Opt<SideBox>;
    breederInfoConfiguration: Opt<SideBox>;
}

export const initialDialsState: DialsState = {
    modalsVisibility: [],
    sampleTypes: none,
    sampleTypeIdBeingEdited: none,
    orderTypes: none,
    orderTypeIdBeingEdited: none,
    verificationOutcomes: none,
    verificationOutcomeIdBeingEdited: none,
    testResults: none,
    testResultIdBeingEdited: none,
    mailTemplates: none,
    mailTemplateIdBeingEdited: none,
    breederInfo: none,
    breederInfoConfiguration: none,
};
