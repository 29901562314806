import {takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {select, put} from 'typed-redux-saga';

import {formHelpers} from 'utils/forms';

import {setAnimalIsEditing, transformAnimalDetailsToForm} from '../../utils/transform-animal-details';
import {ActivateEditAnimalDetailsFormAction, orderAction} from '../action';
import {simpleFormSelector} from '../selector';

function* execute({payload: {animalDetails}}: ActivateEditAnimalDetailsFormAction): SagaIterator {
    const animalsDetails = yield* select(simpleFormSelector.newOrderBulkAnimalsDetails);
    const record = opt(animalsDetails).filterIn(x => x.id === animalDetails.id).headIn().orUndef();
    if (record) {
        const newAnimalsDetails = opt(animalsDetails)
            .mapIn(animal => animal.id === animalDetails.id ? setAnimalIsEditing(record) : animal)
            .orElse([]);

        const convertedAnimal = transformAnimalDetailsToForm(record);
        yield* put(formHelpers.change('newOrder', 'animalDetails', convertedAnimal));
        yield* put(formHelpers.change('newBulkOrder', 'animalsDetails', newAnimalsDetails));
        yield* put(formHelpers.change('newOrder', 'animalDetails', convertedAnimal));
        yield* put(formHelpers.change('newOrder', 'barcode', record.barcode));

        yield* put(orderAction.editAnimalDetails(convertedAnimal));
    }
}

export function* activateNewBulkOrderEditFormSaga(): SagaIterator {
    yield takeLatestF('order/ACTIVATE_EDIT_ANIMAL_DETAILS', execute);
}
