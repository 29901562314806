export const layoutTranslations = {
    CS: {
        'layout/mainMenu': {
            logout: 'Odhlásit se',
            orders: 'Objednávky',
            ordersList: 'Seznam objednávek',
            ordersReceive: 'Příjem objednávek',
            newOrder: 'Nová objednávka',
            newBulkOrder: 'Nová hromadná objednávka',
            paternityCheck: 'Ověření paternity',
            parentSearch: 'Nalezení rodiče',
            importItem: 'Import',
            fileImport: 'Čipů',
            hungarianImport: 'Maďarských objednávek',
            czechImport: 'Českých objednávek',
            plemdataImport: 'Plemdat objednávek',
            ordersUpdatesImport: 'Aktualizací objednávek',
            animals: 'Zvířata',
            animalManagement: 'Správa zvířat',
            administration: 'Administrace',
            snpManagement: 'Správa SNP',
            dials: 'Číselníky',
            textManagement: 'Správa textů',
            menu: 'Menu',
            other: 'Ostatní',
            genotypeExports: 'Export čipů',
            inconsistentOrigins: 'Nekonzistentní původy',
        },
        'layout/footer': {
            text: 'Českomoravská společnost chovatelů, a.s. cmsch@cmsch.cz Benešovská 123\
, 252 09 Hradištko tel: +420 257 896 444 fax: +420 257 740 491',
        },
    },
    EN: {
        'layout/mainMenu': {
            logout: 'Logout',
            orders: 'Orders',
            ordersList: 'List of orders',
            ordersReceive: 'Receive orders',
            newOrder: 'New order',
            newBulkOrder: 'New bulk order',
            paternityCheck: 'Paternity CHECK',
            parentSearch: 'Search for parent',
            importItem: 'Import',
            fileImport: 'Samples',
            hungarianImport: 'Hungarian orders',
            czechImport: 'Czech orders',
            plemdataImport: 'Plemdat orders',
            ordersUpdatesImport: 'Order updates',
            animals: 'Zvířata',
            animalManagement: 'Administration of animals',
            administration: 'Administration',
            snpManagement: 'Administration of SNP',
            dials: 'List of numerical codes',
            textManagement: 'Administration of texts',
            menu: 'Menu',
            other: 'Other',
            genotypeExports: 'Genotype exports',
            inconsistentOrigins: 'Inconsistent origins',
        },
        'layout/footer': {
            text: 'Czech Moravian Breeders’ Corporation, Inc. cmsch@cmsch.cz Benesovska 123, 252 09 Hradistko Tel: +420 257 896 444 Fax: +420 257 740 491',
        },
    },
};
