// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { Barcode, BarcodeSchema } from 'api/gen/Barcode';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { NewOrderAnimal, NewOrderAnimalSchema } from 'api/gen/NewOrderAnimal';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { NewOrderUserDetails, NewOrderUserDetailsSchema } from 'api/gen/NewOrderUserDetails';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveLong, PositiveLongSchema } from 'api/gen/PositiveLong';

const requiredPart = t.interface({
  animalDetails: NewOrderAnimalSchema,
  barcode: t.union([BarcodeSchema, t.null]),
  customerNote: t.union([t.string, t.null]),
  orderTypeIds: t.array(PositiveLongSchema),
  sampleTypeId: PositiveLongSchema,
  userDetails: NewOrderUserDetailsSchema,
});

export const NewOrderSchema = excess(requiredPart);

export interface NewOrder extends t.TypeOf<typeof NewOrderSchema> {}
