import {match} from 'fp-ts/Either';
import {pipe} from 'fp-ts/function';
import Cookies, {CookieSetOptions} from 'universal-cookie';

import {Language, LanguageSchema} from 'api/gen/Language';
import {i18n} from 'translations/i18n';

const languageCookieName = 'language';
const cookieOptions: CookieSetOptions = {
    path: '/',
};

/**
 * Language cookie stores current language setting across sessions without the need for login.
 * Is source of truth during app initialization and for 'Accept-Language' header in communication with our BE.
 * Should be synchronized with 'locale_' cookie set on Legacy BE.
 */
class LanguageStore {
    private readonly cookies = new Cookies();

    public get(): Language | undefined {
        const cookieValue = this.cookies.get(languageCookieName);

        return pipe(
            LanguageSchema.decode(cookieValue),
            match(
                () => undefined,
                language => language,
            ),
        );
    }

    public set(value: Language): void {
        this.cookies.set(languageCookieName, value, cookieOptions);
        i18n.changeLanguage(value)
            .catch(err => {
                // eslint-disable-next-line no-console
                console.log(err);
            });
    }
}

export const languageStore = new LanguageStore();
