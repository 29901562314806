/* eslint-disable max-lines */
import {intercalateElem} from '@fl/cmsch-fe-library';
import {flow, map} from 'lodash/fp';
import React, {FC, ReactNode} from 'react';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {Opt} from 'ts-opt';

import {CodeTableItem} from 'api/gen/CodeTableItem';
import {CodeTableValidation} from 'api/gen/CodeTableValidation';
import {EmailTemplateDetails} from 'api/gen/EmailTemplateDetails';
import {EmailTemplateUpdate} from 'api/gen/EmailTemplateUpdate';
import {NewCodeTableItem} from 'api/gen/NewCodeTableItem';
import {NewOrderTypeItem} from 'api/gen/NewOrderTypeItem';
import {NewVerificationOutcome} from 'api/gen/NewVerificationOutcome';
import {OrderTypeItem} from 'api/gen/OrderTypeItem';
import {OrderTypeValidation} from 'api/gen/OrderTypeValidation';
import {SideBox} from 'api/gen/SideBox';
import {VerificationOutcome} from 'api/gen/VerificationOutcome';
import {Action} from 'app/actions';
import {State} from 'app/state';
import {LoadingType} from 'app/types/loading-type';
import {Container, MainPanel, PageHeading} from 'layout';
import {useOurTranslation} from 'translations';
import {formHelpers} from 'utils/forms';

import {BreederInfoForm} from '../components/BreederInfoForm';
import {editMailTemplateFormName} from '../components/MailTemplateForm/mail-template-form-values';
import {MailTemplates} from '../components/MailTemplates';
import {editOrderTypeFormName} from '../components/OrderTypeForm/order-type-form-values';
import {OrderTypes} from '../components/OrderTypes';
import {sampleTypeFormName} from '../components/SampleTypeForm/sample-type-form-values';
import {SampleTypes} from '../components/SampleTypes';
import {testResultFormName} from '../components/TestResultForm/test-result-form-values';
import {TestResults} from '../components/TestResults';
import {editVerificationOutcomeFormName} from '../components/VerificationOutcomeForm/verification-outcome-form-values';
import {VerificationOutcomes} from '../components/VerificationOutcomes';
import {dialsAction} from '../model/action';
import {DialsModals} from '../types/dials-modals';

interface StateProps {
    loading: Array<LoadingType>;
    modalsVisibility: Array<DialsModals>;
    sampleTypes: Opt<Array<CodeTableItem>>;
    sampleTypeIdBeingEdited: Opt<number>;
    orderTypes: Opt<Array<OrderTypeItem>>;
    orderTypeIdBeingEdited: Opt<number>;
    verificationOutcomes: Opt<Array<VerificationOutcome>>;
    verificationOutcomeIdBeingEdited: Opt<number>;
    testResults: Opt<Array<CodeTableItem>>;
    testResultIdBeingEdited: Opt<number>;
    mailTemplates: Opt<Array<EmailTemplateDetails>>;
    mailTemplateIdBeingEdited: Opt<number>;
    breederInfoLoaded: boolean;
}

interface DispatchProps {
    toggleModalVisibility(id: DialsModals): void;
    getSampleTypes(): void;
    resetSampleTypes(): void;
    startEditingSampleType(sampleType: CodeTableItem): void;
    updateSampleType(id: number, sampleType: NewCodeTableItem): void;
    sampleTypeEditingCancelled(): void;
    createSampleType(sampleType: CodeTableItem): void;
    getOrderTypes(): void;
    resetOrderTypes(): void;
    startEditingOrderType(orderType: OrderTypeItem): void;
    updateOrderType(id: number, orderType: NewOrderTypeItem): void;
    orderTypeEditingCancelled(): void;
    createOrderType(orderType: NewOrderTypeItem): void;
    getVerificationOutcomes(): void;
    resetVerificationOutcomes(): void;
    startEditingVerificationOutcome(verificationOutcome: VerificationOutcome): void;
    updateVerificationOutcome(id: number, verificationOutcome: NewVerificationOutcome): void;
    verificationOutcomeEditingCancelled(): void;
    createVerificationOutcome(verificationOutcome: NewVerificationOutcome): void;
    getTestResults(): void;
    resetTestResults(): void;
    startEditingTestResult(testResult: CodeTableItem): void;
    updateTestResult(id: number, testResult: NewCodeTableItem): void;
    testResultEditingCancelled(): void;
    createTestResult(testResult: CodeTableItem): void;
    getMailTemplates(): void;
    resetMailTemplates(): void;
    startEditingMailTemplate(mailTemplate: EmailTemplateDetails): void;
    updateMailTemplate(id: number, mailTemplate: EmailTemplateUpdate): void;
    mailTemplateEditingCancelled(): void;
    saveBreederInfo(values: SideBox): void;
    getBreederInfoConfiguration(): void;
    validateOrderType(data: OrderTypeValidation): void;
    validateSampleType(data: CodeTableValidation): void;
    validateTestResultType(data: CodeTableValidation): void;
    validateVerificationOutcomeType(data: CodeTableValidation): void;
}

type Props = StateProps & DispatchProps;

// eslint-disable-next-line max-lines-per-function
const ListBase: FC<Props> = props => {
    const {
        loading,
        modalsVisibility,
        sampleTypes,
        sampleTypeIdBeingEdited,
        orderTypes,
        orderTypeIdBeingEdited,
        verificationOutcomes,
        verificationOutcomeIdBeingEdited,
        testResults,
        testResultIdBeingEdited,
        mailTemplates,
        mailTemplateIdBeingEdited,
        breederInfoLoaded,
        toggleModalVisibility,
        getSampleTypes,
        resetSampleTypes,
        startEditingSampleType,
        updateSampleType,
        sampleTypeEditingCancelled,
        createSampleType,
        getOrderTypes,
        resetOrderTypes,
        startEditingOrderType,
        updateOrderType,
        orderTypeEditingCancelled,
        createOrderType,
        getVerificationOutcomes,
        resetVerificationOutcomes,
        startEditingVerificationOutcome,
        updateVerificationOutcome,
        verificationOutcomeEditingCancelled,
        createVerificationOutcome,
        getTestResults,
        resetTestResults,
        startEditingTestResult,
        updateTestResult,
        testResultEditingCancelled,
        createTestResult,
        getMailTemplates,
        resetMailTemplates,
        startEditingMailTemplate,
        updateMailTemplate,
        mailTemplateEditingCancelled,
        saveBreederInfo,
        getBreederInfoConfiguration,
        validateOrderType,
        validateSampleType,
        validateTestResultType,
        validateVerificationOutcomeType,
    } = props;

    const {t} = useOurTranslation('dials/screenList');

    const nav: Array<ReactNode> = flow([
        map(([id, caption]: [string, string]) => (
            <a href={`#${id}`}>
                {caption}
            </a>
        )),
        intercalateElem((<span> • </span>)),
    ])([
        ['sampleTypes', t('sampleTypesCaption')],
        ['orderTypes', t('orderTypesCaption')],
        ['verificationOutcomes', t('verificationOutcomesCaption')],
        ['testResults', t('testResultsCaption')],
        ['breederInfo', t('breederInfoCaption')],
    ]);

    return (
        <Container>
            <MainPanel>
                <div className="row">
                    <div className="col-12">
                        <PageHeading>
                            <h1>{t('tabName')}</h1>
                        </PageHeading>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 text-center">
                        {nav}
                    </div>
                </div>
            </MainPanel>

            <div className="mt-4" />

            <MainPanel>
                <div className="row">
                    <div
                        className="col-12"
                        id="sampleTypes"
                    >
                        <PageHeading>
                            <h1>{t('sampleTypesCaption')}</h1>
                        </PageHeading>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <SampleTypes
                            loading={loading.includes('sampleType')}
                            modalsVisibility={modalsVisibility}
                            sampleTypes={sampleTypes}
                            sampleTypeIdBeingEdited={sampleTypeIdBeingEdited}
                            toggleModalVisibility={toggleModalVisibility}
                            getSampleTypes={getSampleTypes}
                            startEditingSampleType={startEditingSampleType}
                            updateSampleType={updateSampleType}
                            sampleTypeEditingCancelled={sampleTypeEditingCancelled}
                            createSampleType={createSampleType}
                            validateSampleType={validateSampleType}
                            resetSampleTypes={resetSampleTypes}
                        />
                    </div>
                </div>
            </MainPanel>

            <div className="mt-4" />

            <MainPanel>
                <div className="row">
                    <div
                        className="col-12"
                        id="orderTypes"
                    >
                        <PageHeading>
                            <h1>{t('orderTypesCaption')}</h1>
                        </PageHeading>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <OrderTypes
                            loading={loading.includes('orderType')}
                            modalsVisibility={modalsVisibility}
                            orderTypes={orderTypes}
                            orderTypeIdBeingEdited={orderTypeIdBeingEdited}
                            toggleModalVisibility={toggleModalVisibility}
                            getOrderTypes={getOrderTypes}
                            startEditingOrderType={startEditingOrderType}
                            updateOrderType={updateOrderType}
                            orderTypeEditingCancelled={orderTypeEditingCancelled}
                            createOrderType={createOrderType}
                            validateOrderType={validateOrderType}
                            resetOrderTypes={resetOrderTypes}
                        />
                    </div>
                </div>
            </MainPanel>

            <div className="mt-4" />

            <MainPanel>
                <div className="row">
                    <div
                        className="col-12"
                        id="verificationOutcomes"
                    >
                        <PageHeading>
                            <h1>{t('verificationOutcomesCaption')}</h1>
                        </PageHeading>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <VerificationOutcomes
                            loading={loading.includes('verificationOutcome')}
                            modalsVisibility={modalsVisibility}
                            verificationOutcomes={verificationOutcomes}
                            verificationOutcomeIdBeingEdited={verificationOutcomeIdBeingEdited}
                            toggleModalVisibility={toggleModalVisibility}
                            getVerificationOutcomes={getVerificationOutcomes}
                            startEditingVerificationOutcome={startEditingVerificationOutcome}
                            updateVerificationOutcome={updateVerificationOutcome}
                            verificationOutcomeEditingCancelled={verificationOutcomeEditingCancelled}
                            createVerificationOutcome={createVerificationOutcome}
                            validateVerificationOutcomeType={validateVerificationOutcomeType}
                            resetVerificationOutcomes={resetVerificationOutcomes}
                        />
                    </div>
                </div>
            </MainPanel>

            <div className="mt-4" />

            <MainPanel>
                <div className="row">
                    <div
                        className="col-12"
                        id="testResults"
                    >
                        <PageHeading>
                            <h1>{t('testResultsCaption')}</h1>
                        </PageHeading>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <TestResults
                            loading={loading.includes('testResult')}
                            modalsVisibility={modalsVisibility}
                            testResults={testResults}
                            testResultIdBeingEdited={testResultIdBeingEdited}
                            toggleModalVisibility={toggleModalVisibility}
                            getTestResults={getTestResults}
                            startEditingTestResult={startEditingTestResult}
                            updateTestResult={updateTestResult}
                            testResultEditingCancelled={testResultEditingCancelled}
                            createTestResult={createTestResult}
                            validateTestResultType={validateTestResultType}
                            resetTestResults={resetTestResults}
                        />
                    </div>
                </div>
            </MainPanel>

            <div className="mt-4" />

            <MainPanel>
                <div className="row">
                    <div
                        className="col-12"
                        id="mailTemplates"
                    >
                        <PageHeading>
                            <h1>{t('mailTemplatesCaption')}</h1>
                        </PageHeading>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <MailTemplates
                            loading={loading.includes('mailTemplate')}
                            modalsVisibility={modalsVisibility}
                            mailTemplates={mailTemplates}
                            mailTemplateIdBeingEdited={mailTemplateIdBeingEdited}
                            toggleModalVisibility={toggleModalVisibility}
                            getMailTemplates={getMailTemplates}
                            startEditingMailTemplate={startEditingMailTemplate}
                            updateMailTemplate={updateMailTemplate}
                            mailTemplateEditingCancelled={mailTemplateEditingCancelled}
                            resetMailTemplates={resetMailTemplates}
                        />
                    </div>
                </div>
            </MainPanel>

            <div className="mt-4" />

            <MainPanel>
                <div className="row">
                    <div
                        className="col-12"
                        id="breederInfo"
                    >
                        <PageHeading>
                            <h1>{t('breederInfoCaption')}</h1>
                        </PageHeading>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <BreederInfoForm
                            onSubmit={saveBreederInfo}
                            loading={breederInfoLoaded || loading.includes('getBreederInfo')}
                            onGetBreederInfoConfiguration={getBreederInfoConfiguration}
                        />
                    </div>
                </div>
            </MainPanel>

        </Container>
    );
};

const mapStateToProps = (state: State): StateProps => ({
    loading: state.app.localLoadingContent,
    modalsVisibility: state.dials.modalsVisibility,
    sampleTypes: state.dials.sampleTypes,
    sampleTypeIdBeingEdited: state.dials.sampleTypeIdBeingEdited,
    orderTypes: state.dials.orderTypes,
    orderTypeIdBeingEdited: state.dials.orderTypeIdBeingEdited,
    verificationOutcomes: state.dials.verificationOutcomes,
    verificationOutcomeIdBeingEdited: state.dials.verificationOutcomeIdBeingEdited,
    testResults: state.dials.testResults,
    testResultIdBeingEdited: state.dials.testResultIdBeingEdited,
    mailTemplates: state.dials.mailTemplates,
    mailTemplateIdBeingEdited: state.dials.mailTemplateIdBeingEdited,
    breederInfoLoaded: !state.dials.breederInfoConfiguration,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    toggleModalVisibility: (id: DialsModals): Action => dispatch(dialsAction.toggleModalVisibility(id)),
    getSampleTypes: (): Action => dispatch(dialsAction.getSampleTypes()),
    resetSampleTypes: (): Action => dispatch(dialsAction.getSampleTypesSuccess()),
    startEditingSampleType: (sampleType: CodeTableItem): void => {
        const {id, value} = sampleType;
        dispatch(dialsAction.startEditingSampleType(id));
        dispatch(formHelpers.initialize(sampleTypeFormName, {value}));
    },
    updateSampleType: (id: number, sampleType: NewCodeTableItem): Action =>
        dispatch(dialsAction.updateSampleType(id, sampleType)),
    sampleTypeEditingCancelled: (): Action => dispatch(dialsAction.sampleTypeEditingCancelled()),
    createSampleType: (sampleType: CodeTableItem): Action => dispatch(dialsAction.createSampleType(sampleType)),
    getOrderTypes: (): Action => dispatch(dialsAction.getOrderTypes()),
    resetOrderTypes: (): Action => dispatch(dialsAction.getOrderTypesSuccess()),
    startEditingOrderType: (orderType: OrderTypeItem): void => {
        const {id, code, extraCharge, price, value} = orderType;
        dispatch(dialsAction.startEditingOrderType(id));
        dispatch(formHelpers.initialize(editOrderTypeFormName, {code, extraCharge, price, value}));
    },
    updateOrderType: (id: number, orderType: NewOrderTypeItem): Action =>
        dispatch(dialsAction.updateOrderType(id, orderType)),
    orderTypeEditingCancelled: (): Action => dispatch(dialsAction.orderTypeEditingCancelled()),
    createOrderType: (orderType: NewOrderTypeItem): Action => dispatch(dialsAction.createOrderType(orderType)),
    getVerificationOutcomes: (): Action => dispatch(dialsAction.getVerificationOutcomes()),
    resetVerificationOutcomes: (): Action => dispatch(dialsAction.getVerificationOutcomesSuccess()),
    startEditingVerificationOutcome: (verificationOutcome: VerificationOutcome): void => {
        const {id, maternitySettings, paternitySettings, value} = verificationOutcome;
        dispatch(dialsAction.startEditingVerificationOutcome(id));
        dispatch(formHelpers.initialize(
            editVerificationOutcomeFormName,
            {maternitySettings, paternitySettings, value},
        ));
    },
    updateVerificationOutcome: (id: number, verificationOutcome: NewVerificationOutcome): Action =>
        dispatch(dialsAction.updateVerificationOutcome(id, verificationOutcome)),
    verificationOutcomeEditingCancelled: (): Action =>
        dispatch(dialsAction.verificationOutcomeEditingCancelled()),
    createVerificationOutcome: (verificationOutcome: NewVerificationOutcome): Action =>
        dispatch(dialsAction.createVerificationOutcome(verificationOutcome)),
    getTestResults: (): Action => dispatch(dialsAction.getTestResults()),
    resetTestResults: (): Action => dispatch(dialsAction.getTestResultsSuccess()),
    startEditingTestResult: (testResult: CodeTableItem): void => {
        const {id, value} = testResult;
        dispatch(dialsAction.startEditingTestResult(id));
        dispatch(formHelpers.initialize(testResultFormName, {value}));
    },
    updateTestResult: (id: number, testResult: NewCodeTableItem): Action =>
        dispatch(dialsAction.updateTestResult(id, testResult)),
    testResultEditingCancelled: (): Action => dispatch(dialsAction.testResultEditingCancelled()),
    createTestResult: (testResult: CodeTableItem): Action => dispatch(dialsAction.createTestResult(testResult)),
    getMailTemplates: (): Action => dispatch(dialsAction.getMailTemplates()),
    resetMailTemplates: (): Action => dispatch(dialsAction.getMailTemplatesSuccess()),
    startEditingMailTemplate: (mailTemplate: EmailTemplateDetails): void => {
        const {body, code, description, id, subject} = mailTemplate;
        dispatch(dialsAction.startEditingMailTemplate(id));
        dispatch(formHelpers.initialize(editMailTemplateFormName, {
            code, description, subject, body,
        }));
    },
    updateMailTemplate: (id: number, mailTemplate: EmailTemplateUpdate): Action =>
        dispatch(dialsAction.updateMailTemplate(id, mailTemplate)),
    mailTemplateEditingCancelled: (): Action => dispatch(dialsAction.mailTemplateEditingCancelled()),
    saveBreederInfo: (values: SideBox): Action => dispatch(dialsAction.saveBreederInfo(values)),
    getBreederInfoConfiguration: (): Action => dispatch(dialsAction.getBreederInfoConfiguration()),
    validateOrderType: (data: OrderTypeValidation): Action => dispatch(dialsAction.validateOrderType(data)),
    validateSampleType: (data: CodeTableValidation): Action => dispatch(dialsAction.validateSampleType(data)),
    validateTestResultType: (data: CodeTableValidation): Action => dispatch(dialsAction.validateTestResultType(data)),
    validateVerificationOutcomeType: (data: CodeTableValidation): Action =>
        dispatch(dialsAction.validateVerificationOutcomeType(data)),
});

export const List = connect(mapStateToProps, mapDispatchToProps)(ListBase);
