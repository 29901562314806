// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { AnimalName, AnimalNameSchema } from 'api/gen/AnimalName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Barcode, BarcodeSchema } from 'api/gen/Barcode';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Breed, BreedSchema } from 'api/gen/Breed';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { ChipId, ChipIdSchema } from 'api/gen/ChipId';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Date, DateSchema } from 'api/gen/Date';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { GlobalEarTag, GlobalEarTagSchema } from 'api/gen/GlobalEarTag';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { LabNumber, LabNumberSchema } from 'api/gen/LabNumber';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { LineRegistry, LineRegistrySchema } from 'api/gen/LineRegistry';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { OrderOrderType, OrderOrderTypeSchema } from 'api/gen/OrderOrderType';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { OrderUserDetails, OrderUserDetailsSchema } from 'api/gen/OrderUserDetails';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { ParentMatch, ParentMatchSchema } from 'api/gen/ParentMatch';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveLong, PositiveLongSchema } from 'api/gen/PositiveLong';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { SexType, SexTypeSchema } from 'api/gen/SexType';

const requiredPart = t.interface({
  animalId: PositiveLongSchema,
  barcode: t.union([BarcodeSchema, t.null]),
  breed: t.union([BreedSchema, t.null]),
  catalogListUrl: t.union([t.string, t.null]),
  chipId: t.union([ChipIdSchema, t.null]),
  creationDate: t.union([DateSchema, t.null]),
  customer: OrderUserDetailsSchema,
  customerNote: t.union([t.string, t.null]),
  dob: t.union([DateSchema, t.null]),
  exportedByAssociation: t.boolean,
  fathersRegistry: t.union([LineRegistrySchema, t.null]),
  fathersSampleId: t.union([GlobalEarTagSchema, t.null]),
  id: PositiveLongSchema,
  isolated: t.union([t.boolean, t.null]),
  isolationDate: t.union([DateSchema, t.null]),
  laboratoryNote: t.union([t.string, t.null]),
  laboratoryNumber: t.union([LabNumberSchema, t.null]),
  maternity: t.union([ParentMatchSchema, t.null]),
  mothersSampleId: t.union([GlobalEarTagSchema, t.null]),
  name: t.union([AnimalNameSchema, t.null]),
  orderTypes: t.union([t.array(OrderOrderTypeSchema), t.null]),
  paternity: t.union([ParentMatchSchema, t.null]),
  processingDate: t.union([DateSchema, t.null]),
  protocolGenerated: t.boolean,
  protocolGeneratedDate: t.union([DateSchema, t.null]),
  registry: t.union([LineRegistrySchema, t.null]),
  sampleDeliveredDate: t.union([DateSchema, t.null]),
  sampleId: t.union([GlobalEarTagSchema, t.null]),
  sampleTypeId: PositiveLongSchema,
  sex: t.union([SexTypeSchema, t.null]),
  testResultTypeCodes: t.union([t.array(t.string), t.null]),
  twin: t.boolean,
  verificationOutcomeTypeCode: t.union([t.string, t.null]),
});

export const OrderDetailsSchema = excess(requiredPart);

export interface OrderDetails extends t.TypeOf<typeof OrderDetailsSchema> {}
