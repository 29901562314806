// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { CorrectOrigin, CorrectOriginSchema } from 'api/gen/CorrectOrigin';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { CustomerName, CustomerNameSchema } from 'api/gen/CustomerName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Date, DateSchema } from 'api/gen/Date';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { GlobalEarTag, GlobalEarTagSchema } from 'api/gen/GlobalEarTag';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { LabNumber, LabNumberSchema } from 'api/gen/LabNumber';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { LineRegistry, LineRegistrySchema } from 'api/gen/LineRegistry';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { ParentMatch, ParentMatchSchema } from 'api/gen/ParentMatch';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveLong, PositiveLongSchema } from 'api/gen/PositiveLong';

const requiredPart = t.interface({
  active: t.boolean,
  animalId: PositiveLongSchema,
  correctOrigin: t.union([CorrectOriginSchema, t.null]),
  created: DateSchema,
  customerName: t.union([CustomerNameSchema, t.null]),
  dismissedFromIAR: t.union([t.boolean, t.null]),
  earTag: GlobalEarTagSchema,
  eskotFatherEarTag: t.union([GlobalEarTagSchema, t.null]),
  eskotFatherLinReg: t.union([LineRegistrySchema, t.null]),
  eskotMotherEarTag: t.union([GlobalEarTagSchema, t.null]),
  fatherEarTag: t.union([GlobalEarTagSchema, t.null]),
  fatherLinReg: t.union([LineRegistrySchema, t.null]),
  hasProtocol: t.boolean,
  id: PositiveLongSchema,
  laboratoryNote: t.union([t.string, t.null]),
  laboratoryNumber: t.union([LabNumberSchema, t.null]),
  maternity: t.union([ParentMatchSchema, t.null]),
  motherEarTag: t.union([GlobalEarTagSchema, t.null]),
  paternity: t.union([ParentMatchSchema, t.null]),
  reported: t.union([DateSchema, t.null]),
});

export const OriginInconsistencySchema = excess(requiredPart);

export interface OriginInconsistency extends t.TypeOf<typeof OriginInconsistencySchema> {}
