// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { EmailTemplateCode, EmailTemplateCodeSchema } from 'api/gen/EmailTemplateCode';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { EmailTemplateDescription, EmailTemplateDescriptionSchema } from 'api/gen/EmailTemplateDescription';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { EmailTemplateSubject, EmailTemplateSubjectSchema } from 'api/gen/EmailTemplateSubject';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveLong, PositiveLongSchema } from 'api/gen/PositiveLong';

const requiredPart = t.interface({
  body: t.string,
  code: EmailTemplateCodeSchema,
  description: EmailTemplateDescriptionSchema,
  id: PositiveLongSchema,
  subject: EmailTemplateSubjectSchema,
});

export const EmailTemplateDetailsSchema = excess(requiredPart);

export interface EmailTemplateDetails extends t.TypeOf<typeof EmailTemplateDetailsSchema> {}
