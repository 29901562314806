import {NullableKeys} from '@fl/cmsch-fe-library';

export const editMailTemplateFormName = 'editMailTemplate';

export type MailTemplateFormValues = NullableKeys<{
    code: string,
    subject: string,
    description: string,
    body: string,
}>;

export const initialMailTemplateFormValues: MailTemplateFormValues = {
    body: null,
    code: null,
    description: null,
    subject: null,
};
