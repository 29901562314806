import {Nullable} from '@fl/cmsch-fe-library';
import React, {FC, memo, useCallback} from 'react';
import {opt, Opt} from 'ts-opt';

import {Modal} from 'common/layout';
import {ListActionModeType} from 'orders/types/list-action-mode-type';
import {useOurTranslation} from 'translations';

import {SampleSheetForm} from '../SampleSheetForm';
import {SampleSheetFormValues} from '../SampleSheetForm/sample-sheet-form-values';

type ListActionCaption = 'captionSelected' | 'captionFilter' | 'captionFilterWithoutSelected';

const getTitleKey = (mode: ListActionModeType): ListActionCaption => {
    switch (mode) {
        case 'selected': return 'captionSelected';
        case 'filter': return 'captionFilter';
        case 'filterWithoutSelected': return 'captionFilterWithoutSelected';
    }
};

interface Props {
    visible: boolean;
    generateSampleSheetMode: Opt<ListActionModeType>;
    generateSampleSheetByIds(chipId: string): void;
    generateSampleSheetByFilter(chipId: string, withoutSelected: boolean): void;
    setGenerateSampleSheetMode(mode: Nullable<ListActionModeType>): void;
}

const GenerateSampleSheetModalBase: FC<Props> = props => {
    const {
        visible,
        generateSampleSheetMode,
        generateSampleSheetByIds,
        generateSampleSheetByFilter,
        setGenerateSampleSheetMode,
    } = props;

    const {t} = useOurTranslation('orders/GenerateSampleSheetModal');

    const onCancel = useCallback(() => setGenerateSampleSheetMode(null), [setGenerateSampleSheetMode]);

    const onSubmit = useCallback((values: SampleSheetFormValues) => {
        generateSampleSheetMode.zip(opt(values.chipId))
            .onSome(([mode, chipId]) => {
                switch (mode) {
                    case 'selected': {
                        generateSampleSheetByIds(chipId);
                        break;
                    }
                    case 'filter': {
                        generateSampleSheetByFilter(chipId, false);
                        break;
                    }
                    case 'filterWithoutSelected': {
                        generateSampleSheetByFilter(chipId, true);
                        break;
                    }
                }
            });
        onCancel();
    }, [generateSampleSheetByFilter, generateSampleSheetByIds, generateSampleSheetMode, onCancel]);

    return (
        <Modal
            title={t(getTitleKey(generateSampleSheetMode.orElse('filter')))}
            visible={visible}
            onCancel={onCancel}
            footer={null}
        >
            <SampleSheetForm
                onSubmit={onSubmit}
                onClose={onCancel}
            />
        </Modal>
    );
};

export const GenerateSampleSheetModal = memo(GenerateSampleSheetModalBase);
