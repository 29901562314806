import {beErrorsTranslations, componentsTranslations} from '@fl/cmsch-fe-library';
import merge from 'ts-deepmerge';

import {animalsTranslations} from 'animals/translations';
import {dialsTranslations} from 'dials/translations';
import {fileImportTranslations} from 'file-import/translations';
import {exportGenotypesTranslations} from 'genotypeExports/translations';
import {layoutTranslations} from 'layout/translations';
import {orderTranslations} from 'orders/translations';
import {otherTranslations} from 'other/translations';
import {parentSearchTranslations} from 'parentSearch/translations';
import {paternityCheckTranslations} from 'paternityCheck/translations';
import {snpManagementTranslations} from 'snp-management/translations';
import {userTranslations} from 'user/translations';

import {commonTranslations} from './common-translations';

export const appTranslations = merge(
    commonTranslations,
    layoutTranslations,
    dialsTranslations,
    paternityCheckTranslations,
    parentSearchTranslations,
    otherTranslations,
    fileImportTranslations,
    animalsTranslations,
    orderTranslations,
    snpManagementTranslations,
    exportGenotypesTranslations,
    userTranslations,
);

export const translations = merge(
    componentsTranslations,
    beErrorsTranslations,
    appTranslations,
);
