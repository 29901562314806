// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.75.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.75.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-orders-components-OrderDetailsView-___styles__orderTypeList___YVlnh{margin:0;padding:0}.src-modules-orders-components-OrderDetailsView-___styles__noTopBorder___ZhwrH tr:first-of-type td,.src-modules-orders-components-OrderDetailsView-___styles__noTopBorder___ZhwrH tr:first-of-type th{border-top:0}\n", "",{"version":3,"sources":["webpack://./src/modules/orders/components/OrderDetailsView/styles.sass"],"names":[],"mappings":"AAAA,iFAAA,QAAA,CAAA,SAAA,CAAkC,sMAAkE,YAAA","sourcesContent":[".orderTypeList{margin:0;padding:0}.noTopBorder tr:first-of-type td,.noTopBorder tr:first-of-type th{border-top:0}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"orderTypeList": "src-modules-orders-components-OrderDetailsView-___styles__orderTypeList___YVlnh",
	"noTopBorder": "src-modules-orders-components-OrderDetailsView-___styles__noTopBorder___ZhwrH"
};
export default ___CSS_LOADER_EXPORT___;
