export const exportGenotypesTranslations = {
    CS: {
        'genotypeExports/screenList': {
            title: 'Export čipů',
        },
        'genotypeExports/GenotypesTable': {
            export: 'Stáhnout',
            exportDisabled: 'Výsledky se generují...',
            processButton: 'Spustit generování',
            processSuccess: 'Generování bylo úspěšně spuštěno',
            columnHeaders: {
                created: 'Datum vytvoření',
                createdBy: 'Zadavatel',
                fileSize: 'Velikost',
                fileKey: 'Jméno souboru',
                animalsCount: 'Počet zvířat',
                association: 'Svaz',
            },
            columnHeadersTooltip: {
                created: 'Datum vytvoření',
                createdBy: 'Zadavatel',
                fileSize: 'Velikost',
                fileKey: 'Jméno souboru',
                animalsCount: 'Počet zvířat',
                association: 'Svaz',
            },
            associationTypes: {
                czechFleckvieh: 'Český strakatý',
                holstein: 'Holštýnský',
                meat: 'Masný',
                diary: 'Mléčný',
                norman: 'Normandský',
                other: 'Ostatní',
                undefined: 'Neuvedeno',
            },
        },
    },
    EN: {
        'genotypeExports/screenList': {
            title: 'Chip export',
        },
        'genotypeExports/GenotypesTable': {
            export: 'Download',
            exportDisabled: 'The results are being generated...',
            processButton: 'Start generation',
            processSuccess: 'Generation started successfully',
            columnHeaders: {
                created: 'Date created',
                createdBy: 'Created by',
                fileSize: 'Filesize',
                fileKey: 'Filename',
                animalsCount: 'Animals count',
                association: 'Association',
            },
            columnHeadersTooltip: {
                created: 'Date created',
                createdBy: 'Created by',
                fileSize: 'Filesize',
                fileKey: 'Filename',
                animalsCount: 'Animals count',
                association: 'Association',
            },
            associationTypes: {
                czechFleckvieh: 'Czech fleckvieh',
                holstein: 'Holstein',
                meat: 'Meat',
                diary: 'Diary',
                norman: 'Norman',
                other: 'Other',
                undefined: 'Undefined',
            },
        },
    },
};
