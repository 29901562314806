import {putAll, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {appAction} from 'app/model/action';
import {t} from 'translations';
import {showBeError} from 'utils/show-be-error';

import {GetOrdersCustomersAction, orderAction} from '../action';

function* execute(_action: GetOrdersCustomersAction): SagaIterator {
    yield* put(appAction.addLoadingContent('getOrdersCustomers'));
    const response = yield* call(Api.getCustomerDetails, {name: null});

    if (response.isSuccess) {
        yield* put(orderAction.getOrdersCustomersSuccess(response.data));
    } else {
        yield putAll(showBeError(response, t('orders/sagas')('getOrdersCustomers')));
    }
    yield* put(appAction.removeLoadingContent('getOrdersCustomers'));
}

export function* getOrdersCustomersSaga(): SagaIterator {
    yield takeLatestF('order/GET_ORDERS_CUSTOMERS', execute);
}
