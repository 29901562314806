import {extractFormErrorsFromResponse, putAll, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {put, call} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {testResultFormName} from 'dials/components/TestResultForm/test-result-form-values';
import {t} from 'translations';
import {formHelpers} from 'utils/forms';
import {showBeError} from 'utils/show-be-error';

import {CreateTestResultAction, dialsAction} from '../action';

function* execute(action: CreateTestResultAction): SagaIterator {
    const {testResult} = action.payload;

    yield* put(formHelpers.startSubmit(testResultFormName));

    const response = yield* call(Api.createTestResultType, testResult);

    if (response.isSuccess) {
        yield* put(dialsAction.getTestResults());
        yield* put(dialsAction.setModalVisibility('testResult', false));
        yield* put(formHelpers.stopSubmit(testResultFormName));
    } else {
        yield* put(formHelpers.stopSubmit(testResultFormName, extractFormErrorsFromResponse(response)));
        yield putAll(showBeError(response, t('dials/testResults')('createNew')));
    }
}

export function* createTestResultSaga(): SagaIterator {
    yield takeLatestF('dials/CREATE_TEST_RESULT', execute);
}
