import {NullableKeys} from '@fl/cmsch-fe-library';

import {ParenthoodValues} from 'types/parenthood-values';

export const createVerificationOutcomeFormName = 'createVerificationOutcome';
export const editVerificationOutcomeFormName = 'editVerificationOutcome';

export type VerificationOutcomeFormValues = NullableKeys<{
    value: string,
    paternitySettings: ParenthoodValues,
    maternitySettings: ParenthoodValues,
}>;

export const initialVerificationOutcomeFormValues: VerificationOutcomeFormValues = {
    maternitySettings: null,
    paternitySettings: null,
    value: null,
};
