import {Address} from 'api/gen/Address';
import {cityMaxLength, cityMinLength} from 'api/gen/City';
import {streetMaxLength, streetMinLength} from 'api/gen/Street';
import {zipNumberMaxLength, zipNumberMinLength} from 'api/gen/ZipNumber';
import {tCommon} from 'translations';
import {PaternityValidator} from 'utils/paternity-validator';
import {Errors} from 'utils/validator';

import {AddressFormSectionValues} from '../../types/address-form-section-values';

export const validateAddress = (
    values: AddressFormSectionValues,
): Errors<Address> => {
    const validator = new PaternityValidator(values);

    validator.nonEmpty('street', tCommon('address.street'));
    validator.minStringLength('street', streetMinLength, tCommon('address.street'));
    validator.maxStringLength('street', streetMaxLength, tCommon('address.street'));
    validator.nonEmpty('zip', tCommon('address.zip'));
    validator.minStringLength('zip', zipNumberMinLength, tCommon('address.zip'));
    validator.maxStringLength('zip', zipNumberMaxLength, tCommon('address.zip'));
    validator.nonEmpty('city', tCommon('address.city'));
    validator.minStringLength('city', cityMinLength, tCommon('address.city'));
    validator.maxStringLength('city', cityMaxLength, tCommon('address.city'));

    return validator.generateErrorsObject();
};
