import {classNames, intercalateElem, Options, TypedFormSectionInjectedProps} from '@fl/cmsch-fe-library';
import moment from 'moment';
import React, {Component, ReactNode} from 'react';
import {Opt, map, pipe} from 'ts-opt';

import {SexType} from 'api/gen/SexType';
import {AntAlert} from 'common/ant';
import {TFunction} from 'translations';

import {NewOrderFormValues} from '../NewForm/new-order-form-values';

import styles from './styles.sass';

const sampleIdFormats = pipe(
    ['CZ000123456779', 'DE000123456789'],
    map((x: string) => (
        <code className={styles.sampleIdExample}>{x}</code>
    )),
    intercalateElem(<span>, </span>),
);

export interface AnimalDetailsFormSectionProps {
    animalSexes: Options<SexType>;
    motherAlreadyAnalyzed?: boolean;
    fatherAlreadyAnalyzed?: boolean;
    isNewOrderAnimalValid: boolean;
    newOrderEarNumberWarning: Opt<string>;
    t: TFunction<'orders/AnimalDetailsForm'>;
    tCommon: TFunction<'common'>;
    onSampleIdAction(value: Opt<string>): void;
    onRegistryChange(registry: Opt<string>): void;
    onFathersSampleIdChange(value: Opt<string>): void;
    onMothersSampleIdChange(value: Opt<string>): void;
    onFathersRegistryChange(value: Opt<string>): void;
}

export class AnimalDetailsFormSection extends Component<
    AnimalDetailsFormSectionProps &
    TypedFormSectionInjectedProps<NewOrderFormValues, 'animalDetails'>
> {
    // eslint-disable-next-line max-lines-per-function
    public render(): ReactNode {
        const {
            Fields,
            animalSexes,
            motherAlreadyAnalyzed,
            fatherAlreadyAnalyzed,
            isNewOrderAnimalValid,
            newOrderEarNumberWarning,
            t,
            tCommon,
            onSampleIdAction,
            onRegistryChange,
            onFathersSampleIdChange,
            onMothersSampleIdChange,
            onFathersRegistryChange,
        } = this.props;

        const formRowClassnames = classNames(
            'row',
            !isNewOrderAnimalValid && 'd-none',
        );

        return (
            <fieldset>
                <div className="row">
                    <div className="col-12 col-md-6 align-items-end mb-5">
                        <Fields.Input
                            name="sampleId"
                            label={tCommon('earTag')}
                            type="text"
                            placeholder={tCommon('earTagPlaceholder')}
                            helpText={t('earTagHelp')}
                            onFieldChange={onSampleIdAction}
                            actionOnEnter
                            isRequired
                        />
                        {newOrderEarNumberWarning.map(x => (
                            <AntAlert
                                message={x}
                                type="warning"
                                key={x}
                            />
                        )).orNull()}
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                        {t('examplesOfEarNumberFormats')}
                        <br />
                        {sampleIdFormats}
                    </div>
                </div>

                <div className={formRowClassnames}>
                    <div className="col-12 col-md-6">
                        <Fields.Input
                            name="name"
                            label={t('name')}
                            type="text"
                        />
                    </div>

                    <div className="col-12 col-md-6">
                        <Fields.StringSelect
                            name="sex"
                            label={t('sex')}
                            placeholder={t('sexPlaceholder')}
                            options={animalSexes}
                            isRequired
                            clearable={false}
                            searchable={false}
                        />
                    </div>
                </div>

                <div className={formRowClassnames}>
                    <div className="col-12 col-md-6">
                        <Fields.Input
                            name="breed"
                            label={t('breed')}
                            type="text"
                            isRequired
                        />
                    </div>

                    <div className="col-12 col-md-6">
                        <Fields.Input
                            name="registry"
                            label={t('registry')}
                            type="text"
                            placeholder={tCommon('registryPlaceholder')}
                            onFieldChange={onRegistryChange}
                        />
                    </div>
                </div>

                <div className={formRowClassnames}>
                    <div className="col-12 col-md-6">
                        <Fields.DateInput
                            name="dob"
                            label={t('dob')}
                            placeholder={tCommon('dateOfBirthPlaceholder')}
                            maxDate={moment().endOf('day')}
                            dontIncludeFuture
                            clearable
                        />
                    </div>
                </div>

                <div className={formRowClassnames}>
                    <div className="col-12 col-md-6">
                        <Fields.Input
                            name="fathersSampleId"
                            label={t('fathersSampleId')}
                            type="text"
                            placeholder={tCommon('earTagPlaceholder')}
                            onFieldChange={onFathersSampleIdChange}
                            helpText={fatherAlreadyAnalyzed
                                ? t('fatherAlreadyAnalyzed')
                                : ''}
                        />
                    </div>

                    <div className="col-12 col-md-6">
                        <Fields.Input
                            name="fathersRegistry"
                            label={t('fathersRegistry')}
                            type="text"
                            placeholder={tCommon('registryPlaceholder')}
                            onFieldChange={onFathersRegistryChange}
                        />
                    </div>
                </div>

                <div className={formRowClassnames}>
                    <div className="col-12 col-md-6">
                        <Fields.Input
                            name="mothersSampleId"
                            label={t('mothersSampleId')}
                            type="text"
                            placeholder={tCommon('earTagPlaceholder')}
                            onFieldChange={onMothersSampleIdChange}
                            helpText={motherAlreadyAnalyzed
                                ? t('motherAlreadyAnalyzed')
                                : ''}
                        />
                    </div>
                </div>

                <div className={formRowClassnames}>
                    <div className="col-12 col-md-6">
                        <Fields.Checkbox
                            name="twin"
                            label={t('twin')}
                        />
                    </div>
                </div>
            </fieldset>
        );
    }
}
